import React, { useEffect, useMemo, useState } from "react";
import { DataConveter } from "../../../utilities/utilities";

import {
  FlexChartLegend,
  FlexPie,
  FlexPieDataLabel,
} from "@grapecity/wijmo.react.chart";
import { FlexChartAnimation } from "@grapecity/wijmo.react.chart.animation";

import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";

import { useDisclosure } from "@mantine/hooks";
import DataService from "../../../service/data-service";
import { GET_DATA_BY_QUERY } from "../../../utilities/constants";
import LoadingComponents from "../../common/loading";
import ErrorWidget from "../error/error-widget";
import PropTypes from "prop-types";

export default function PieChart(props) {
  const { i, datasources, width, height, chartInitalized } = props;

  // Loading State handlers
  const [isLoading, loadingHandlers] = useDisclosure(true);
  const [datasource, setDatasource] = useState([]);
  const [isError, setIsError] = useState(false);
  const [serverData, setServerData] = useState([]);
  const [palette] = useState([
    "rgba(42,159,214,1)",
    "rgba(119,179,0,1)",
    "rgba(153,51,204,1)",
    "rgba(255,136,0,1)",
    "rgba(204,0,0,1)",
    "rgba(0,204,163,1)",
    "rgba(61,109,204,1)",
    "rgba(82,82,82,1)",
    "rgba(0,0,0,1)",
  ]);

  const memoizedDatasources = useMemo(() => datasources, [datasources]);

  useEffect(() => {
    const path = GET_DATA_BY_QUERY;
    const requestObject = {
      query: i.query,
      table_name: i.tableName,
    };
    // SID002 Get Data by queries
    DataService(path, requestObject).then(({ data, error }) => {
      if (error) {
        setIsError(true);
        loadingHandlers.close();
      } else {
        setIsError(false);
        setServerData(DataConveter(data.metadata));
        setDatasource(datasources);
        loadingHandlers.close();
      }
    });
    return () => {
      setServerData([]);
      setDatasource([]);
      loadingHandlers.open();
    };
  }, [memoizedDatasources]);

  if (isLoading)
    return (
      <div className="container-fluid">
        <LoadingComponents />
      </div>
    );

  if (isError) {
    return <ErrorWidget />;
  }

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <FlexPie
        initialized={chartInitalized}
        style={{ width: width, height: height }}
        header={datasource.header}
        bindingName={datasource.configure.category}
        binding={datasource.configure.size}
        innerRadius={datasource.settings.innerRadius}
        itemsSource={serverData}
        palette={palette}
        isAnimated={true}
        selectionMode={datasource.settings.selectionMode ? "Point" : "None"}
        selectedItemPosition="Top"
        datasource={datasource.settings.selectionMode === true ? 0.2 : 0}
        tooltipContent={"{name} {value}"}
      >
        <FlexChartLegend position={datasource.settings.legendPosition} />
        <FlexPieDataLabel
          position={datasource.settings.dataLabelPosition}
          content={"{name} {value}"}
        />
        <FlexChartAnimation animationMode="Point" />
      </FlexPie>
    </div>
  );
}

PieChart.propTypes = {
  i: PropTypes.any,
  datasources: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  chartInitalized: PropTypes.any,
};
