import { CACHE_UPDATE, TOKEN } from "../../constants/api.path";

export default function cacheUpdate($salesPlanYear, $salesPlanMonth) {
  fetch(CACHE_UPDATE + "/salesPlanYear/" + $salesPlanYear + "/salesPlanMonth/" + $salesPlanMonth, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${TOKEN}`,
    },
  });
}
