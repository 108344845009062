import React from "react";
import { AiOutlineReload } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import SuccessModal from "../../../../modalPopups/successModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";

// api
import { FINANCIAL_DATA_CREATE, TOKEN } from "../../../../constants/api.path";

const FinancialDataImport = () => {
  const [error, setError] = React.useState(false);
  const [flex, setFlex] = React.useState(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [resultMessage, setResultMessage] = React.useState("");

  function loadFile() {
    let file = document.getElementById("financial-data-input");
    if (flex && file.files[0]) {
      flex.loadAsync(file.files[0]);
    } else {
      setError(true);
      setErrorMessage("There is no upload file, please choose a file!");
    }
  }

  function getFileData() {
    let currentYear = "";
    let totalData = [];
    for (let r = 2; r < flex.rows.length; r++) {
      let primaryKey = flex
        .getCellValue(r, 0)
        .toLowerCase()
        .replace("%", "percent")
        .replace("-", "_")
        .replace(".", "");
      primaryKey = primaryKey.split(" ").join("_");
      let data = { [primaryKey]: {} };
      for (let c = 1; c < flex.columns.length; c++) {
        let i = c;
        let currentQuarter = flex.getCellValue(1, c);
        while (flex.getCellValue(0, i) === "" && c > 0) {
          i--;
        }
        currentYear = flex.getCellValue(0, i);
        if (data[primaryKey][currentYear] === undefined) {
          data[primaryKey][currentYear] = {};
        }
        data[primaryKey][currentYear][currentQuarter] = flex.getCellValue(r, c);
      }
      totalData.push(data);
    }
    sendData({ data: { totalData: totalData } });
  }

  async function sendData(data) {
    let url = FINANCIAL_DATA_CREATE;
    await fetch(url, {
      method: "POST",
      headers: { Authorization: `Bearer ${TOKEN}` },
      body: JSON.stringify(data),
    })
      .then(async (response) => response.json())
      .then((result) => {
        setResultMessage(result.message);
        setShowSuccess(true);
      });
  }

  function flexInitialized(flex) {
    setFlex(flex);
  }

  return (
    <div>
      <div className="container-fluid">
        <div className="row justify-content-between" style={{ margin: "0" }}>
          <div className="col-md-8">
            <div className="row">
              <div
                className="form-inline col-md-4"
                style={{ paddingLeft: "0.3vw", paddingRight: "0" }}
              >
                <input
                  type="file"
                  className="form-control"
                  id="financial-data-input"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                />
              </div>
              <div
                className="form-inline col-md-auto"
                style={{ paddingLeft: "5px", paddingRight: "0" }}
              >
                <button
                  className="actionIconOutline load bg-blue"
                  onClick={loadFile}
                  id="loadButton"
                  data-tooltip-content="Load"
                >
                  <AiOutlineReload />
                  <ReactTooltip place="right" anchorId="loadButton" />
                </button>
              </div>
              <div className="col-md-auto" style={{ paddingLeft: "5px" }}>
                <button
                  className="actionIconOutline save bg-blue"
                  id="saveButton"
                  data-tooltip-content="Save"
                  onClick={getFileData}
                >
                  <BiSave />
                  <ReactTooltip place="right" anchorId="saveButton" />
                </button>
              </div>
            </div>
          </div>
          <SuccessModal
            show={showSuccess}
            title={resultMessage}
            onSubmit={() => {
              setShowSuccess(false);
            }}
            onHide={() => {
              setShowSuccess(false);
            }}
          />
        </div>
        <div style={{ marginLeft: "10px" }}>
          {error && <span className="error">{errorMessage}</span>}
        </div>

        <wjGridSheet.FlexSheet
          initialized={flexInitialized.bind(this)}
          style={{
            height: "540px",
          }}
          id="budgetSheet"
        >
          <wjGridSheet.Sheet name="New Sheet" />
        </wjGridSheet.FlexSheet>
      </div>
    </div>
  );
};

export default FinancialDataImport;
