export function changeLabelPosition(list, blocks) {
  var elements = document.querySelectorAll(".wj-data-label[y]");
  if (!blocks)
    Array.prototype.forEach.call(elements, function (el) {
      var total = el.innerHTML;
      let val = parseInt(el.getAttribute("y")) - 15;
      list.forEach((list) => {
        if (list.total && parseInt(total) === list.total) {
          el.setAttribute("y", val);
        } else if (list.sales && parseInt(total) === list.sales) {
          val -= 5;
          el.setAttribute("y", val);
        } else if (
          list.operatingIncome &&
          parseInt(total) === list.operatingIncome
        ) {
          val += 5;
          let xVal = parseInt(el.getAttribute("x")) + 15;
          el.setAttribute("x", xVal);
          el.setAttribute("y", val);
          el.style.fill = "red";
          el.style.fontWeight = "bold";
        }
      });
    });
  else {
    for (let i = 0; i < blocks.length; i++) {
      let targetName = ["sales", "operatingIncome"];
      for (let k = 0; k < targetName.length; k++) {
        if (blocks[i] === targetName[k]) {
          for (
            let j = elements.length - list.length * (blocks.length - i);
            j <
            elements.length - list.length * (blocks.length - i) + list.length;
            j++
          ) {
            elements[j]?.setAttribute(
              "y",
              parseInt(elements[j].getAttribute("y")) - 17
            );
            if (blocks[i] === "operatingIncome") {
              elements[j].style.fill = "red";
              elements[j].style.fontWeight = "bold";
            }
          }
        }
      }
    }
  }
}
