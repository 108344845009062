import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  MultiSelect,
  Tabs,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { IconBinaryTree2, IconSettings } from "@tabler/icons-react";
import React, { useEffect } from "react";
import LoadingComponents from "../../common/loading";
import PropTypes from "prop-types";

const FlexSheetEditor = (props) => {
  const { widget, metacolumn, oldDatasource, handleWidgetSettingClose } = props;

  // Provider Items
  const { column_names } = metacolumn;
  // Loading State handlers
  const [loading, loadingHandlers] = useDisclosure(true);
  // Flex sheet row names

  // Flex sheet column names
  const form = useForm({
    initialValues: {
      title: oldDatasource?.title || "",
      header: oldDatasource?.header || null,

      columns: oldDatasource?.configure?.columns || [],

      sheetName: oldDatasource?.settings?.sheetName || "",
    },

    // functions will be used to validate values at corresponding key
    validate: {
      title: isNotEmpty("Widget title cannot be empty"),
      columns: (value) =>
        value === 0 ? "Columns should have at least 1 data" : null,
      sheetName: isNotEmpty("Sheet name cannot be empty"),
    },
  });

  useEffect(() => {
    loadingHandlers.close();
  }, []);

  const handleOnSubmit = (values) => {
    const newDatasource = {
      title: values.title,
      header: null,
      type: widget.type,
      configure: {
        columns: values.columns,
      },
      settings: {
        sheetName: values.sheetName,
      },
    };
    form.reset();
    handleWidgetSettingClose(newDatasource);
  };

  if (loading)
    return (
      <Box miw={220} mx="auto">
        <LoadingComponents />
      </Box>
    );

  return (
    <Box miw={220} mx="auto">
      <form
        onSubmit={form.onSubmit((values) => {
          handleOnSubmit(values);
        })}
      >
        <Grid align="center" gutter="xs">
          <Grid.Col span={4}>
            <Text weight={500} size="sm">
              Widget Title
            </Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              placeholder="Widget Title"
              {...form.getInputProps("title")}
            />
          </Grid.Col>
        </Grid>

        <Tabs radius="xs" defaultValue="configure">
          <Tabs.List>
            <Tabs.Tab
              value="configure"
              icon={<IconBinaryTree2 size="0.8rem" />}
            >
              Configure
            </Tabs.Tab>
            <Tabs.Tab value="settings" icon={<IconSettings size="0.8rem" />}>
              Settings
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel
            value="configure"
            pt="xs"
            h={"calc(70vh - 40px)"}
            style={{ overflow: "hidden" }}
          >
            <Grid align="center" gutter="xs">
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  Columns
                </Text>
              </Grid.Col>

              <Grid.Col span={8}>
                <MultiSelect
                  withAsterisk
                  nothingFound="Nothing found"
                  placeholder="Choose Columns"
                  data={column_names}
                  {...form.getInputProps("columns")}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>

          <Tabs.Panel value="settings" pt="xs" h={"calc(70vh - 40px)"}>
            <Grid align="center" gutter="xs">
              <Grid.Col span={12}>
                <Title order={6}>Display</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  Sheet Name
                </Text>
              </Grid.Col>

              <Grid.Col span={8}>
                <TextInput
                  withAsterisk
                  placeholder="Enter Sheet Name"
                  {...form.getInputProps("sheetName")}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>
        </Tabs>
        <Divider my={5} variant="dotted" />
        <Flex
          mih={"auto"}
          gap="md"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button type="submit" color="indigo">
            {oldDatasource ? "Update" : "Add"}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default FlexSheetEditor;

FlexSheetEditor.propTypes = {
  widget: PropTypes.any,
  metacolumn: PropTypes.any,
  oldDatasource: PropTypes.any,
  handleWidgetSettingClose: PropTypes.any,
};
