import { ActionIcon, Group, Table, Text, Tooltip } from "@mantine/core";
import { IconAlertTriangle, IconEdit, IconTrash } from "@tabler/icons-react";
import { Alert } from "bootstrap";
import { modals } from "@mantine/modals";

import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

export default function QueryListTable(props) {
  const { headerStyle, items, header, onDelete, onUpdate } = props;

  if (items.length === 0) return;

  const openDeleteModal = (item) =>
    modals.openConfirmModal({
      title: "Delete query",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete {item.query_name}?
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => onDelete(item.query_id),
    });

  <Alert
    icon={<IconAlertTriangle size="1rem" />}
    title="Warning!"
    color="red"
    variant="outline"
  >
    No Query Lists found. Please create query !!!.
  </Alert>;

  return (
    <Table border="1px solid black">
      <thead>
        <tr style={{ backgroundColor: "#2aafab" }}>
          <th style={headerStyle}>{header}</th>
          <th style={headerStyle}>Created By</th>
          <th style={headerStyle}>Created At</th>
          <th style={headerStyle}>Updated By</th>
          <th style={headerStyle}>Updated At</th>
          <th style={headerStyle}>Actions</th>
        </tr>
      </thead>
      <tbody style={{ textAlign: "center" }}>
        {items.map((item, index) => (
          <tr key={index}>
            <td>{item.query_name}</td>
            <td>{item.created_by}</td>
            <td>{moment(item.created_at).format("MMMM/DD/YYYY h:mm:ss A")}</td>
            <td>{item.updated_by}</td>
            <td>{moment(item.updated_at).format("MMMM/DD/YYYY h:mm:ss A")}</td>
            <td
              style={{
                width: 90,
                borderRight: "1px solid black",
              }}
            >
              <Group spacing="xs">
                {" "}
                <Tooltip label="Edit">
                  <ActionIcon
                    variant="filled"
                    color="yellow"
                    onClick={() => onUpdate(item.query_id)}
                  >
                    <IconEdit size="1rem" />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Delete">
                  <ActionIcon
                    variant="filled"
                    color="pink"
                    onClick={() => openDeleteModal(item)}
                  >
                    <IconTrash size="1rem" />
                  </ActionIcon>
                </Tooltip>
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

QueryListTable.propTypes = {
  headerStyle: PropTypes.any,
  items: PropTypes.any,
  header: PropTypes.any,
  onDelete: PropTypes.any,
  onUpdate: PropTypes.any,
};
