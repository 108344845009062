import { Select } from "@mantine/core";
import React from "react";
import PropTypes from "prop-types";

const PinQuery = ({
  handlePinChange,
  pinData,
  pinValue,
  pinTarget,
  planceholder,
}) => {
  return (
    <Select
      searchable
      size="xs"
      placeholder={planceholder}
      nothingFound="Nothing found"
      maxDropdownHeight={400}
      value={pinValue}
      onChange={(value) => {
        handlePinChange(value, pinTarget);
      }}
      data={pinData}
    />
  );
};

export default PinQuery;

PinQuery.propTypes = {
  handlePinChange: PropTypes.any,
  pinData: PropTypes.any,
  pinValue: PropTypes.any,
  pinTarget: PropTypes.string,
  planceholder: PropTypes.string,
};
