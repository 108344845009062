import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  MultiSelect,
  NumberInput,
  Select,
  Tabs,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { IconBinaryTree2, IconSettings } from "@tabler/icons-react";
import React from "react";
import PropTypes from "prop-types";

const SimpleTableEditor = (props) => {
  const { widget, metacolumn, oldDatasource, handleWidgetSettingClose } = props;
  // Provider Items
  const { column_names } = metacolumn;
  const form = useForm({
    initialValues: {
      title: oldDatasource?.title || "",
      header: oldDatasource?.header || null,
      columns: oldDatasource?.configure?.columns || [],
      count: oldDatasource?.settings?.count || 10,
      sortingBy: oldDatasource?.settings?.sortingBy || null,
      allowSorting: oldDatasource?.settings?.allowSorting || "None",
      frozenRows: oldDatasource?.settings?.frozenRows || 0,
      frozenColumns: oldDatasource?.settings?.frozenColumns || 0,
    },

    // functions will be used to validate values at corresponding key
    validate: {
      title: isNotEmpty("Widget title cannot be empty"),
      columns: (value) =>
        value === 0 ? "Columns should have at least 1 data" : null,
    },
  });

  const handleOnSubmit = (values) => {
    const newDatasource = {
      title: values.title,
      header: null,
      type: widget.type,
      configure: {
        columns: values.columns,
      },
      settings: {
        count: values.count,
        frozenRows: values.frozenRows,
        frozenColumns: values.frozenColumns,
        sortingBy: values.sortingBy,
        allowSorting: values.allowSorting,
      },
    };
    form.reset();
    handleWidgetSettingClose(newDatasource);
  };

  return (
    <Box miw={220} mx="auto">
      <form
        onSubmit={form.onSubmit((values) => {
          handleOnSubmit(values);
        })}
      >
        <Grid align="center" gutter="xs">
          <Grid.Col span={4}>
            <Text weight={500} size="sm">
              Widget Title
            </Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              placeholder="Widget Title"
              {...form.getInputProps("title")}
            />
          </Grid.Col>
        </Grid>

        <Tabs radius="xs" defaultValue="configure">
          <Tabs.List>
            <Tabs.Tab
              value="configure"
              icon={<IconBinaryTree2 size="0.8rem" />}
            >
              Configure
            </Tabs.Tab>
            <Tabs.Tab value="settings" icon={<IconSettings size="0.8rem" />}>
              Settings
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel
            value="configure"
            pt="xs"
            h={"calc(70vh - 40px)"}
            style={{ overflow: "hidden" }}
          >
            <Grid align="center" gutter="xs">
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Columns
                </Text>
              </Grid.Col>

              <Grid.Col span={8}>
                <MultiSelect
                  withAsterisk
                  nothingFound="Nothing found"
                  placeholder="Choose Columns"
                  data={column_names}
                  {...form.getInputProps("columns")}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>

          <Tabs.Panel value="settings" pt="xs" h={"calc(70vh - 40px)"}>
            <Grid align="center" gutter="xs">
              <Grid.Col span={12}>
                <Title order={6}>Display</Title>
              </Grid.Col>

              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Count
                </Text>
              </Grid.Col>

              <Grid.Col span={8}>
                <NumberInput
                  withAsterisk
                  placeholder="Enter Count"
                  {...form.getInputProps("count")}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Freeze Rows
                </Text>
              </Grid.Col>

              <Grid.Col span={8}>
                <NumberInput
                  withAsterisk
                  placeholder="Enter Freeze Rows"
                  {...form.getInputProps("frozenRows")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Freeze Columns
                </Text>
              </Grid.Col>

              <Grid.Col span={8}>
                <NumberInput
                  withAsterisk
                  placeholder="Enter Columns"
                  {...form.getInputProps("frozenColumns")}
                />
              </Grid.Col>

              {/* <Grid.Col span={12}>
                <Title order={6}>Filter</Title>
              </Grid.Col> */}

              {/* <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Show Filter
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Checkbox
                  checked={form.values.isFilter}
                  {...form.getInputProps('isFilter', { type: 'checkbox' })}
                />
              </Grid.Col> */}

              <Grid.Col span={12}>
                <Title order={6}>Sort</Title>
              </Grid.Col>

              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Sorting Type
                </Text>
              </Grid.Col>

              <Grid.Col span={8}>
                <Select
                  withAsterisk
                  nothingFound="Nothing found"
                  placeholder="Choose Allow Sorting"
                  data={["None", "SingleColumn", "MultiColumn"]}
                  {...form.getInputProps("allowSorting")}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Sorting By
                </Text>
              </Grid.Col>

              <Grid.Col span={8}>
                <Select
                  withAsterisk
                  nothingFound="Nothing found"
                  placeholder="Choose Column"
                  data={column_names}
                  {...form.getInputProps("sortingBy")}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>
        </Tabs>
        <Divider my={5} variant="dotted" />
        <Flex
          mih={"auto"}
          gap="md"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button type="submit" color="indigo">
            Update
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default SimpleTableEditor;

SimpleTableEditor.propTypes = {
  widget: PropTypes.any,
  metacolumn: PropTypes.any,
  oldDatasource: PropTypes.any,
  handleWidgetSettingClose: PropTypes.any,
};
