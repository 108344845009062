import React from "react";
// import TabsMenu from "./components/tabs";
import {
  ActionIcon,
  Flex,
  Tabs,
  Drawer,
  Title,
  Divider,
  Grid,
  TextInput,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Tooltip } from "react-tooltip";
import { TiTabsOutline } from "react-icons/ti";
import { MdWidgets } from "react-icons/md";
import { BiEdit, BiSave } from "react-icons/bi";
import { useAtom } from "jotai";
import {
  chartItemsAtom,
  metacolumnAtom,
  metadataAtom,
} from "./state/chart-item-atom";
import { Responsive, WidthProvider } from "react-grid-layout";
import WidgetsFrame from "./components/widget-frame";

import { BiArrowBack } from "react-icons/bi";
import WidgetsContent from "./components/widget-content";
import PropTypes from "prop-types";
import DynamicDashboardAddName from "../../../modalPopups/dynamicDashboardAddName";

// json
import widgetsList from "./assets/widgets.json";

// api
import {
  DYNAMIC_DASHBOARD_DATA,
  GET_DYNAMIC_DASHBOARD_ONE,
  UPDATE_DYNAMIC_DASHBOARD,
  TOKEN,
  CREATE_DYNAMIC_DASHBOARD,
} from "../../../constants/api.path";
import { DataConveter } from "./utilities/utilities";
import TabEditator from "./components/tab-editor";
import WidgetEditor from "./components/widget-editor";
import { useNavigate } from "react-router-dom";
import { Notifications, notifications } from "@mantine/notifications";

// components
const ResponsiveGridLayout = WidthProvider(Responsive);

// css
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { Spinner } from "reactstrap";

const getInitialData = async (id) => {
  if (id !== "null") {
    const request = await fetch(GET_DYNAMIC_DASHBOARD_ONE + "/" + id, {
      method: "GET",
      headers: { Authorization: `Bearer ${TOKEN}` },
    });
    const response = await request.json();
    return response.data;
  } else {
    return {
      name: "",
      json: {
        cols: 12,
        sections: [
          {
            sectionValue: "dashboard",
            sectionName: "Dashboard",
            items: [],
            layouts: {},
          },
        ],
      },
    };
  }
};

const EditILiveBoard = () => {
  // variables

  const [state, setState] = React.useState({
    activeTab: "dashboard",
    isEditing: true,
    alertOpen: false,
    selectedWidget: null,
    oldDataSource: null,
  });

  const [itemState, setItemState] = useAtom(chartItemsAtom);

  const [metadataState, setMetadata] = useAtom(metadataAtom);

  const [isTabDrawer, tabDrawerHandlers] = useDisclosure(false);

  const [metacolumnState, setmetaColumnState] = useAtom(metacolumnAtom);

  const [isEditorDrawer, editorDrawerHandler] = useDisclosure(false);

  const mode = new URL(window.location).searchParams.get("edit") === "true";

  const dashboardId = new URL(window.location).searchParams.get("dashboard");

  const widgets = widgetsList;

  const navigate = useNavigate();

  const [tempLayouts, setTempLayouts] = React.useState({});

  const [dashboardName, setDashboardName] = React.useState("");

  const [showSetDashboardName, setShowSetSahboardName] = React.useState(false);

  const [changed, setChanged] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  // functions
  const onTabChange = (value) => {
    setState((prev) => ({ ...prev, activeTab: value }));
  };

  const editToggle = () => {
    setState((prev) => ({ ...prev, isEditing: !state.isEditing }));
  };

  const handleDrawer = (state) => {
    if (state === "tab") tabDrawerHandlers.toggle();
    else editorDrawerHandler.toggle();
  };

  const handleAddWidget = (widget) => {
    setState((prev) => ({
      ...prev,
      selectedWidget: widget,
      oldDataSource: null,
      targetId: 0,
    }));
  };

  const handleSave = async () => {
    // if (dashboardName === "") {
    //   setShowSetSahboardName((prev) => !prev);
    // } else {
    //   saveLayout();
    // }
    if (dashboardName === "")
      await setShowSetSahboardName((prev) => !prev).then(() => saveLayout());
    else saveLayout();
  };

  const saveLayout = async () => {
    const updatedSections = itemState.sections.map((section) => {
      if (section.sectionValue === state.activeTab) {
        return {
          ...section,
          layouts: tempLayouts,
        };
      }
      return section;
    });
    let id = dashboardId,
      body = {
        name: dashboardName,
        json: {
          cols: itemState.cols || 12,
          sections: updatedSections,
        },
      };
    let url =
      id !== "null"
        ? UPDATE_DYNAMIC_DASHBOARD + "/" + id
        : CREATE_DYNAMIC_DASHBOARD;
    const req = await fetch(url, {
      method: "POST",
      headers: { Authorization: `Bearer ${TOKEN}` },
      body: JSON.stringify(body),
    });
    const res = await req.json();

    notifications.show({
      title: res.message,
      message: res.result ? "Layout is saved" : "Unexpected error occur!",
      autoClose: 2000,
      withCloseButton: false,
    });
    if (id === "null") {
      setLoading(true);
      setTimeout(() => {
        window.location.href = "/dashboard?tab=3";
      }, 2000);
    }
  };

  const handleEditWidget = (widget, datasource, index) => {
    setState((prev) => ({
      ...prev,
      selectedWidget: widget,
      oldDataSource: datasource,
      targetId: index,
    }));
    editorDrawerHandler.open();
  };

  // Layout Change
  const onLayoutChange = (layout, layouts) => {
    setTempLayouts(layouts);
    setChanged(true);
  };

  // Break point add
  const onBreakpointChange = (breakpoint, cols) => {
    setItemState((prev) => ({
      ...prev,
      breakpoint: breakpoint,
      cols: cols,
    }));
  };

  // get data
  const getMetaData = async () => {
    const req = await fetch(
      DYNAMIC_DASHBOARD_DATA + "/salesPlanYear/8/salesPlanMonth/4",
      { method: "GET", headers: { Authorization: `Bearer ${TOKEN}` } }
    );
    const result = await req.json();
    let data = await result.metadata[0];
    return data;
  };

  // useEffect
  React.useEffect(() => {
    getMetaData().then((data) => {
      setMetadata(DataConveter(data));
      setmetaColumnState(data.column_names);
    });
    getInitialData(dashboardId).then((res) => {
      setDashboardName(res.name);
      setItemState(res.json);
    });
  }, []);

  React.useEffect(() => {
    if (changed) {
      window.onbeforeunload = () => {
        // getInitialData(dashboardId).then((res) => console.log(res));
        // console.log(itemState, tempLayouts);
        // e.returnValue = "";
        // e.preventDefault();
      };
    } else {
      window.onbeforeinput = null;
    }
  }, [itemState, changed]);

  // alert unsave changes
  // const alert = (e) => {
  //   console.log("unload");
  //   console.log(itemState);
  //   e.preventDefault();
  // };

  React.useEffect(() => {
    if (!isEditorDrawer)
      setState((prev) => ({ ...prev, selectedWidget: null }));
  }, [isEditorDrawer]);

  React.useEffect(() => {}, [metadataState, metacolumnState]);

  return (
    <div style={{ position: "relative" }}>
      {loading && (
        <div className="loader">
          <Spinner color="white" />
        </div>
      )}
      <BiArrowBack
        style={{
          fontSize: "20px",
          width: "34px",
          paddingLeft: "5px",
          paddingRight: "5px",
          height: "35px",
          position: "absolute",
          zIndex: 300,
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(-1);
        }}
      />
      {/* icons actions */}
      {mode && (
        <Flex
          mih={36}
          gap="xs"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
          style={{ position: "absolute", right: 0, top: 0, zIndex: "100" }}
        >
          <Tooltip place="bottom" anchorId="setting" />
          <Tooltip place="bottom" anchorId="widget" />
          <Tooltip place="bottom" anchorId="save" />
          <Tooltip place="bottom" anchorId="edit" />
          <ActionIcon
            disabled={!state.isEditing}
            variant="outline"
            color="cyan"
            id="setting"
            data-tooltip-content="Tab Setting"
            onClick={() => {
              handleDrawer("tab");
            }}
          >
            <TiTabsOutline />
          </ActionIcon>
          <ActionIcon
            disabled={!state.isEditing}
            variant="outline"
            color="cyan"
            id="widget"
            data-tooltip-content="Widget Config"
            onClick={() => {
              handleDrawer("editor");
            }}
          >
            <MdWidgets />
          </ActionIcon>
          <ActionIcon
            disabled={false}
            variant="filled"
            color="teal"
            id="save"
            data-tooltip-content="Save Layout"
            onClick={handleSave}
          >
            <BiSave />
          </ActionIcon>
          <ActionIcon
            disabled={false}
            variant="filled"
            color="cyan"
            id="edit"
            data-tooltip-content="Edit Mode"
            onClick={editToggle}
          >
            <BiEdit />
          </ActionIcon>
        </Flex>
      )}
      {/* Tab menus */}
      <Tabs
        value={state.activeTab}
        variant="outline"
        onTabChange={(value) => onTabChange(value)}
      >
        {/* tabs list */}
        <Tabs.List>
          <Tabs.Tab value="holder" disabled>
            {" "}
          </Tabs.Tab>
          {itemState?.sections?.map((section) => (
            <Tabs.Tab
              value={section.sectionValue}
              key={`tabs-${section.sectionValue}`}
            >
              {section.sectionName}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {/* tabs contents */}
        {itemState?.sections?.map((section) => (
          <Tabs.Panel
            value={section.sectionValue}
            key={`tabs-panel-${section.sectionValue}`}
          >
            <ResponsiveGridLayout
              className="layout"
              style={{
                marginTop: 0,
                maxHeight: "85vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              breakpoints={{ md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ md: 12, sm: 8, xs: 4, xxs: 2 }}
              // layouts={section.layouts}
              layouts={JSON.parse(localStorage.getItem("layouts")) || {}}
              rowHeight={50}
              isResizable={state.isEditable}
              isDraggable={state.isEditable}
              onLayoutChange={(layout, layouts) =>
                onLayoutChange(layout, layouts, section.sectionValue)
              }
              onBreakpointChange={onBreakpointChange}
            >
              {section.items?.map(({ i, x, y, w, h }, index) => (
                <div key={i} data-grid={{ x, y, w, h }}>
                  <WidgetsFrame
                    isEdit={!(mode && state.isEditing)}
                    isEditable={true}
                    key={`Widget-${i}`}
                    index={i}
                    activeSection={section.sectionValue}
                    item={section.items[index]}
                    editWidget={handleEditWidget}
                  />
                </div>
              ))}
            </ResponsiveGridLayout>
          </Tabs.Panel>
        ))}
      </Tabs>
      {/* Drawers */}
      <Drawer.Root
        opened={isTabDrawer}
        onClose={() => handleDrawer("tab")}
        position="right"
        size={"23%"}
      >
        <Drawer.Overlay />
        <Drawer.Content>
          <Drawer.Header style={{ padding: "7px" }}>
            <Title order={5} color="indigo">
              Edit Tab
            </Title>
            <Drawer.CloseButton />
          </Drawer.Header>
          <Drawer.Body style={{ padding: "0 7px" }}>
            <Divider />
            <label htmlFor="dashboard-name">
              <b>Dashboard Name</b>
            </label>
            <TextInput
              id="dashboard-name"
              placeholder="Dashboard Name"
              value={dashboardName}
              onChange={(e) => {
                setDashboardName(e.target.value);
              }}
              style={{ margin: "5px 0" }}
            />
            <Divider />
            <TabEditator sections={itemState?.sections} />
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
      <Drawer.Root
        opened={isEditorDrawer}
        onClose={() => {
          handleDrawer();
        }}
        position="right"
        size="35%"
      >
        <Drawer.Overlay />
        <Drawer.Content>
          {/* Drawer Header */}
          <Drawer.Header style={{ padding: "7px" }}>
            <Title order={5} color="indigo">
              {state.selectedWidget ? "Widget Setting" : "Widgets"}
            </Title>
            {state.selectedWidget && state.oldDataSource === null && (
              <ActionIcon
                color="indigo"
                ml="xs"
                onClick={() => {
                  setState((prev) => ({ ...prev, selectedWidget: null }));
                }}
              >
                <BiArrowBack />
              </ActionIcon>
            )}
            <Drawer.CloseButton />
          </Drawer.Header>
          {/* Drawer Body */}
          <Drawer.Body style={{ padding: "0 7px" }}>
            <Divider mb={7} />
            {state.selectedWidget ? (
              <WidgetEditor
                metacolumn={metacolumnState}
                widget={state.selectedWidget}
                oldDatasource={state.oldDataSource}
                targetId={state.targetId}
                activeTab={state.activeTab}
                handleEditorClose={() => {
                  handleDrawer("editor");
                }}
                tempLayouts={tempLayouts}
              />
            ) : (
              <Grid gutter={10}>
                {widgets.map((widget, index) => (
                  <Grid.Col span={6} key={index}>
                    <WidgetsContent
                      widget={widget}
                      handleAddWidget={handleAddWidget}
                    />
                  </Grid.Col>
                ))}
              </Grid>
            )}
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
      <Notifications position="bottom-right" />
      <DynamicDashboardAddName
        dashboardName={dashboardName}
        setDashboardName={setDashboardName}
        onHide={() => {
          setShowSetSahboardName((prev) => !prev);
        }}
        show={showSetDashboardName}
        onSubmit={saveLayout}
      />
      {/* <Prompt when={true} message="You have unsaved changes" /> */}
    </div>
  );
};

export default EditILiveBoard;

EditILiveBoard.propTypes = {
  selectedCanvas: PropTypes.string,
  mode: PropTypes.string,
  changeLocation: PropTypes.func,
};
