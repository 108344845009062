import React from "react";
import PropTypes from "prop-types";
// wijmo
import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";

export default function DashboardSalesByRanks({ data }) {
  return (
    <div>
      <wjChart.FlexPie
        bindingName="name"
        binding="count"
        itemsSource={data}
        selectionMode="Point"
        selectedItemPosition="Top"
        isAnimated={true}
        startAngle={90}
        style={{ height: "250px" }}
        innerRadius={0.5}
        palette={["#32CD32", "#88BDE6", "#FEF250", "#808080", "#ff5349"]}
      >
        <wjChart.FlexPieDataLabel
          content={(ht) => {
            return `${ht.value}, ${(
              (ht.value /
                data
                  .map((rank) => rank.count)
                  .reduce((sum, cur) => sum + cur)) *
              100
            ).toFixed(0)}%`;
          }}
        ></wjChart.FlexPieDataLabel>
        <wjChartAnimate.FlexChartAnimation />
      </wjChart.FlexPie>
    </div>
  );
}

DashboardSalesByRanks.propTypes = {
  data: PropTypes.isRequired,
};
