import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { LOGOUT_URL } from "../constants/api.path";
import InfoModal from "../modalPopups/infoModal";
import PropTypes from "prop-types";

var isAuthenticated = localStorage.getItem("isLoggedIn")
  ? localStorage.getItem("isLoggedIn")
  : sessionStorage.getItem("isLoggedIn");

export default function SessionManagement(props) {
  const events = ["click", "load", "scroll"];
  const [showInfoModel, setShowInfoModel] = useState(false);

  var infoModelTitle = "This session is expired. Please login again.";
  var warningInactiveInterval = useRef(),
    startTimerInterval = useRef();

  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      let storedTimeStamp = sessionStorage.getItem("lastTimeStamp");
      warningInactive(storedTimeStamp);
    }, 6000);
  };

  let warningInactive = (timeString) => {
    clearTimeout(startTimerInterval.current);
    warningInactiveInterval.current = setInterval(() => {
      const maxTime = 120;
      const diff = moment.duration(
        moment().diff(moment(new Date(timeString).toISOString()))
      );
      const minPast = diff.minutes();
      if (minPast === maxTime) {
        clearInterval(warningInactiveInterval.current);
        sessionStorage.removeItem("lastTimeStamp");
        setShowInfoModel(true);
      }
    }, 1000);
  };

  let resetTimer = (isAuthenticated) => {
    if (isAuthenticated) {
      let timestamp = moment();
      localStorage.setItem("lastTimeStamp", timestamp);
      sessionStorage.setItem("lastTimeStamp", timestamp);
    } else {
      sessionStorage.clear();
    }
    timeChecker();
  };

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, () => resetTimer(isAuthenticated));
    });
  });

  async function userLogout() {
    await fetch(LOGOUT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then(async () => {
      isAuthenticated = false;
      props.remember ? localStorage.clear() : sessionStorage.clear();
      window.location.href = "/login";
    });
  }
  return (
    <div>
      <InfoModal
        show={showInfoModel}
        title={infoModelTitle}
        onSubmit={() => {
          userLogout();
        }}
        onHide={() => {
          userLogout();
        }}
      />
    </div>
  );
}

SessionManagement.propTypes = {
  remember: PropTypes.bool.isRequired,
};
