import {
  Box,
  Button,
  ColorInput,
  Divider,
  Flex,
  Grid,
  NumberInput,
  Select,
  Tabs,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
// import { IconBinaryTree2, IconSettings } from "@tabler/icons-react";
import { TbBinaryTree2 } from "react-icons/tb";
import { GoSettings } from "react-icons/go";
import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { chartItemsAtom, metadataAtom } from "../../state/chart-item-atom";
import PropTypes from "prop-types";
import { CURRENCY, UNIT, UNITCATEGORY } from "../../utilities/constants";

/**
 * Filters array by attributes,
 * Remove duplicate attribute.
 *
 * @param {Array} data - The array of objects to be filtered.
 * @param {Array} attributes - The attributes to filter by.
 * @returns {Object} - Object with attribute key and unique array values.
 */
function dataColumnsFiltering(data, attributes) {
  if (data.length === 0) return [];

  let result = {};
  attributes.forEach((attribute) => {
    result[attribute] = [];
    data.forEach((item) => {
      if (
        item[attribute] !== undefined &&
        !result[attribute].includes(item[attribute])
      ) {
        result[attribute].push(item[attribute]);
      }
    });
  });
  return result;
}

function attributeFiltering(data) {
  if (data.length === 0) return [];
  const obj = data[0];
  let keysArray = [];
  for (var key in obj) {
    if (typeof obj[key] === "string") {
      keysArray.push(key);
    }
  }
  return keysArray;
}

const ProgressCardEditor = ({
  widget,
  oldDatasource,
  targetId,
  activeTab,
  handleEditorClose,
  tempLayouts,
}) => {
  // Provider Items
  const [itemsState, setItemState] = useAtom(chartItemsAtom);
  const [metadataState] = useAtom(metadataAtom);
  // Searchable state
  const [searchable, setSearchable] = useState("");
  const [searchableBy, setSearchableBy] = useState("");

  useEffect(() => {
    setSearchable(oldDatasource?.configure.searchable || "");
    setSearchableBy(oldDatasource?.configure.searchableBy || "");
  }, []);

  // filter attributes
  const attributeFiltered = attributeFiltering(metadataState);

  // filter data columns
  const dataColumnsFiltered = dataColumnsFiltering(
    metadataState,
    attributeFiltered
  );

  const searableByFiltered = searchable ? dataColumnsFiltered[searchable] : [];

  const form = useForm({
    initialValues: {
      title: oldDatasource?.title || "",
      header: oldDatasource?.header || null,
      category: oldDatasource?.configure.category || "",
      unitCategory: oldDatasource?.settings.unitCategory || "number",
      unit: oldDatasource?.settings.unit || UNIT[0].value,
      currency: oldDatasource?.settings.currency || CURRENCY[0].value,
      decimal: oldDatasource?.settings.decimal || 0,
      bgColor: oldDatasource?.settings.bgColor || "#ffffff",
      textColor: oldDatasource?.settings.textColor || "#000000",
    },

    // functions will be used to validate values at corresponding key
    validate: {
      title: isNotEmpty("Widget title cannot be empty"),
      category: isNotEmpty("Category cannot be empty"),
      bgColor: isNotEmpty("Background color cannot be empty"),
      textColor: isNotEmpty("Text color cannot be empty"),
    },

    transformValues: (values) => ({
      title: values.title,
      header: values.header,
      configure: {
        category: values.category,
      },
      settings: {
        unitCategory: values.unitCategory,
        unit: values.unit,
        currency: values.currency,
        decimal: values.decimal,
        bgColor: values.bgColor,
        textColor: values.textColor,
      },
    }),
  });

  let transformValues = oldDatasource?.settings.unitCategory || "number";

  if (form.isDirty("unitCategory")) {
    let fromValues = form.getTransformedValues();
    transformValues = fromValues.settings.unitCategory;
  }

  const handleSearchableChange = (value) => {
    setSearchable(value);
    setSearchableBy("");
  };

  const handleSearchableByChange = (value) => {
    setSearchableBy(value);
  };

  // Handle Submit
  const handleOnSubmit = (values) => {
    if (oldDatasource) {
      handleEdit(values);
    } else {
      handleNew(values);
    }
  };

  // Add New Widgets
  const handleNew = (value) => {
    // Creating new datasource
    // Get data from user input
    const newDatasource = {
      title: value.title,
      header: null,
      type: widget.type,
      configure: {
        category: value.configure.category,
        searchable: searchable,
        searchableBy: searchableBy,
      },
      settings: {
        unitCategory: value.settings.unitCategory,
        unit: value.settings.unit,
        currency: value.settings.currency,
        decimal: value.settings.decimal,
        bgColor: value.settings.bgColor,
        textColor: value.settings.textColor,
      },
    };

    // calculate new item position
    let x = 0,
      y = 0,
      defaultWidth = 3,
      defaultHeight = 3,
      highestX = 0,
      highestY = 0,
      currentX = 0,
      currentY = 0,
      whitespaceYStart = 0;
    let cols = [
      { count: 12, name: "md" },
      { count: 6, name: "sm" },
      { count: 4, name: "xs" },
      { count: 2, name: "xxs" },
    ];
    let breakpoint = "";
    for (let i = 0; i < cols.length; i++) {
      if (cols[i].count === itemsState.cols) {
        breakpoint = cols[i].name;
        break;
      }
    }
    let layout = tempLayouts[breakpoint] || [];
    if (layout.length) {
      for (let i = 0; i < layout.length; i++) {
        currentX = layout[i].x + layout[i].w;
        currentY = layout[i].y + layout[i].h;
        if (currentX + defaultWidth > itemsState.cols) {
          highestX = 0;
        } else if (
          currentX > highestX ||
          currentX + defaultWidth <= itemsState.cols
        ) {
          highestX = currentX;
        }
        if (currentY >= highestY) {
          highestY = currentY;
          whitespaceYStart = highestY;
        } else if (currentY < highestY) {
          whitespaceYStart = currentY;
          highestX -= layout[i].w;
        }
      }
    }
    y = whitespaceYStart;
    x = highestX;

    // create new item
    const newItems = {
      i: self.crypto.randomUUID(),
      x: x,
      y: y,
      w: defaultWidth,
      h: defaultHeight,
      export: "export",
      datasources: newDatasource,
    };
    // find active section
    let activeSection = itemsState.sections.filter(
      (state) => state.sectionValue === activeTab
    );
    // get items from active section
    let localStorageItems = activeSection[0].items || [];
    // push new item to items
    localStorageItems.push(newItems);
    // update section
    const updatedSections = itemsState.sections.map((section) => {
      if (section.sectionValue === activeTab) {
        return {
          ...section,
          items: localStorageItems,
        };
      }
      return section;
    });
    // update state
    setItemState({
      ...itemsState,
      sections: updatedSections,
    });
    form.reset();
    handleEditorClose();
  };

  const handleEdit = (value) => {
    // Creating new datasource
    // Get data from user input
    const newDatasource = {
      title: value.title,
      header: null,
      type: widget.type,
      configure: {
        category: value.configure.category,
        searchable: searchable,
        searchableBy: searchableBy,
      },
      settings: {
        unitCategory: value.settings.unitCategory,
        unit: value.settings.unit,
        currency: value.settings.currency,
        decimal: value.settings.decimal,
        bgColor: value.settings.bgColor,
        textColor: value.settings.textColor,
      },
    };
    // find active section
    let activeSection = itemsState.sections.filter(
      (state) => state.sectionValue === activeTab
    );
    // get items from active section
    let localStorageItems = activeSection[0].items || [];
    // find item index
    let itemIndex = localStorageItems.findIndex((item) => item.i === targetId);
    // update item
    localStorageItems[itemIndex].datasources = newDatasource;
    // update section
    const updatedSections = itemsState.sections.map((section) => {
      if (section.sectionValue === activeTab) {
        return {
          ...section,
          items: localStorageItems,
        };
      }
      return section;
    });
    // update state
    setItemState({
      ...itemsState,
      sections: updatedSections,
    });
    form.reset();
    handleEditorClose();
  };

  return (
    <Box miw={220} mx="auto">
      <form
        onSubmit={form.onSubmit((values) => {
          handleOnSubmit(values);
        })}
      >
        <Grid align="center" gutter="xs">
          <Grid.Col span={4}>
            <Text weight={400} size="sm">
              Widget Title
            </Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              withAsterisk
              placeholder="Widget Title"
              {...form.getInputProps("title")}
            />
          </Grid.Col>
        </Grid>
        <Tabs radius="xs" defaultValue="configure">
          <Tabs.List>
            <Tabs.Tab value="configure" icon={<TbBinaryTree2 />}>
              Configure
            </Tabs.Tab>
            <Tabs.Tab value="settings" icon={<GoSettings />}>
              Settings
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel
            value="configure"
            pt="xs"
            h={"calc(70vh - 40px)"}
            style={{ overflow: "hidden" }}
          >
            <Grid align="center" gutter="xs">
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Searchable
                </Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Select
                  searchable
                  withAsterisk
                  nothingFound="Nothing found"
                  placeholder="Choose Searchable"
                  data={attributeFiltered.slice(0, 2)}
                  value={searchable}
                  onChange={handleSearchableChange}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Select
                  searchable
                  withAsterisk
                  nothingFound="Nothing found"
                  placeholder="Choose Searchable By"
                  data={searableByFiltered}
                  value={searchableBy}
                  onChange={handleSearchableByChange}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Category
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  searchable
                  withAsterisk
                  nothingFound="No options"
                  placeholder="Choose Category..."
                  data={dataColumnsFiltered[attributeFiltered[2]]}
                  {...form.getInputProps("category")}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>

          <Tabs.Panel value="settings" pt="xs" h={"calc(70vh - 40px)"}>
            <Grid align="center" gutter="xs">
              <Grid.Col span={12}>
                <Title order={6}>Display</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Background Color
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <ColorInput {...form.getInputProps("bgColor")} />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Text Color
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <ColorInput {...form.getInputProps("textColor")} />
              </Grid.Col>
              <Grid.Col span={12}>
                <Title order={6}>Format</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Category
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  searchable
                  nothingFound="No options"
                  placeholder="Choose Unit Category..."
                  data={UNITCATEGORY}
                  {...form.getInputProps("unitCategory")}
                />
              </Grid.Col>
              {transformValues === "number" ? (
                <React.Fragment>
                  <Grid.Col span={4}>
                    <Text weight={400} size="sm">
                      Unit
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <Select
                      searchable
                      nothingFound="No options"
                      placeholder="Choose Unit..."
                      data={UNIT}
                      {...form.getInputProps("unit")}
                    />
                  </Grid.Col>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid.Col span={4}>
                    <Text weight={400} size="sm">
                      Currency
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <Select
                      searchable
                      nothingFound="No options"
                      placeholder="Choose Currency..."
                      data={CURRENCY}
                      {...form.getInputProps("currency")}
                    />
                  </Grid.Col>
                </React.Fragment>
              )}
              <React.Fragment>
                <Grid.Col span={4}>
                  <Text weight={400} size="sm">
                    Decimal
                  </Text>
                </Grid.Col>
                <Grid.Col span={8}>
                  <NumberInput {...form.getInputProps("decimal")} />
                </Grid.Col>
              </React.Fragment>
            </Grid>
          </Tabs.Panel>
        </Tabs>
        <Divider my={5} variant="dotted" />
        <Flex
          mih={"auto"}
          gap="md"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button type="submit" color="indigo">
            {oldDatasource ? "Update" : "Add"}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default ProgressCardEditor;

ProgressCardEditor.propTypes = {
  widget: PropTypes.object,
  metacolumn: PropTypes.array,
  oldDatasource: PropTypes.object,
  targetId: PropTypes.number,
  activeTab: PropTypes.string,
  handleEditorClose: PropTypes.func,
  tempLayouts: PropTypes.array,
};
