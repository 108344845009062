import * as React from "react";
import { useEffect, useState } from "react";

import { SortDescription } from "@grapecity/wijmo";
import { FlexGrid, FlexGridColumn } from "@grapecity/wijmo.react.grid";
import "@grapecity/wijmo.styles/wijmo.css";

import { useAtom } from "jotai";
import { metadataAtom } from "../state/chart-item-atom";
import PropTypes from "prop-types";

const SimpleTable = (props) => {
  const { datasources, width, height, chartInitalized } = props;
  const [metadataState] = useAtom(metadataAtom);
  const [flex] = useState(null);

  const serverData = metadataState;
  useEffect(() => {
    if (flex) {
      const sd = new SortDescription(datasources.settings.sort, true);
      flex.collectionView.sortDescriptions.push(sd);
    }
  }, [flex]);

  return (
    <div className="container-fluid">
      <FlexGrid
        initialized={chartInitalized}
        itemsSource={serverData}
        style={{ width: width, height: height }}
      >
        {datasources.configure.bindingY.map((item) => (
          <FlexGridColumn
            header={item}
            binding={item}
            key={`table-row-${item}`}
            width="*"
          />
        ))}
      </FlexGrid>
    </div>
  );
};

export default SimpleTable;
SimpleTable.propTypes = {
  datasources: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.number,
  chartInitalized: PropTypes.func,
};
