import ProgressCardEditor from "../editor/cards/progress-card-editor";
import AreaChartEditor from "../editor/charts/area-chart-editor";
import BarChartEditor from "../editor/charts/bar-chart-editor";
import BarChartEditorLine from "../editor/charts/bar-chart-editor-line";
import BubbleChartEditor from "../editor/charts/bubble-chart-editor";
import LineChartEditor from "../editor/charts/line-chart-editor";
import PieChartEditor from "../editor/charts/pie-chart-editor";
import FlexSheetEditor from "../editor/tables/flex-sheet-editor";
import SimpleTableEditor from "../editor/tables/simple-table-editor";
import React from "react";
import PropTypes from "prop-types";

// Pre-define Dynamic Editor Components
const components = {
  pie_chart: PieChartEditor,
  line_chart: LineChartEditor,
  bar_chart: BarChartEditor,
  bar_chart_line: BarChartEditorLine,
  progress_card: ProgressCardEditor,
  simple_table: SimpleTableEditor,
  area_chart: AreaChartEditor,
  bubble_chart: BubbleChartEditor,
  flex_sheet: FlexSheetEditor,
};

const WidgetEditor = (props) => {
  const {
    widget,
    metacolumn,
    oldDatasource,
    targetId,
    activeTab,
    handleEditatorClose,
    handleWidgetSettingClose,
  } = props;
  // Dynamic Compments Create
  const DynamicComponent = components[widget.type];
  return (
    <DynamicComponent
      widget={widget}
      metacolumn={metacolumn}
      oldDatasource={oldDatasource}
      targetId={targetId}
      activeTab={activeTab}
      handleEditatorClose={handleEditatorClose}
      handleWidgetSettingClose={handleWidgetSettingClose}
    />
  );
};

export default WidgetEditor;

WidgetEditor.propTypes = {
  widget: PropTypes.any,
  metacolumn: PropTypes.any,
  oldDatasource: PropTypes.any,
  targetId: PropTypes.any,
  activeTab: PropTypes.any,
  handleEditatorClose: PropTypes.any,
  handleWidgetSettingClose: PropTypes.any,
};
