import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Tooltip as ReactTooltip } from "react-tooltip";

function BusinessSectorAddEditModal(props) {
  return (
    <div>
      <Modal size="md" show={props.show} onHide={props.onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className=" col-md-auto" onSubmit={props.handleSubmit}>
            <div className="row col-md-12 mt-1 justify-content-center">
              <label className=" col-md-4 col-form-label">Sector Name</label>
              <div className="g-0 col-md-8">
                <input
                  type="text"
                  onChange={props.handleChange}
                  name="sectorName"
                  value={props.sector.sectorName}
                  className="form-control"
                  placeholder="Enter Sector Name"
                  required
                />
              </div>
            </div>
            <div className="row col-md-12 mt-1" style={{ paddingRight: "0" }}>
              <label className=" col-md-4 col-form-label ">
                Sector Full Name
              </label>
              <div className="g-0 col-md-8">
                <input
                  type="text"
                  onChange={props.handleChange}
                  name="sectorFullName"
                  value={props.sector.sectorFullName}
                  className="form-control"
                  placeholder="Enter Sector Full Name"
                  required
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <form onSubmit={props.handleSubmit}>
            <button
              className="popUpBtn save bg-blue"
              id={props.editing ? "Update" : "Add"}
              data-tooltip-content={props.editing ? "Update" : "Add"}
              onClick={(e) => {
                props.getValue(e);
                props.setAddEditModal(false);
              }}
              style={{}}
            >
              {props.editing ? (
                <span id="Update">Update</span>
              ) : (
                <span id="Add">Save</span>
              )}

              <ReactTooltip
                place="bottom"
                anchorId={props.editing ? "Update" : "Add"}
              />
            </button>
          </form>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default BusinessSectorAddEditModal;

BusinessSectorAddEditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  sector: PropTypes.object.isRequired,
  editing: PropTypes.bool.isRequired,
  getValue: PropTypes.func.isRequired,
  setAddEditModal: PropTypes.func.isRequired,
};
