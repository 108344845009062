import "bootstrap/dist/css/bootstrap.css";
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjcCore from "@grapecity/wijmo";
import * as React from "react";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";

import PropTypes from "prop-types";
import "../../assets/css/dashboard.css";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

export default class FinancialDataQuarterSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flex: null,
      isFrozen: false,
      isLoading: true,
      hideRows: [],
      hideColumns: [],
      data: [],
      year: undefined,
      columns: [
        { id: 0, name: "FY16" },
        { id: 1, name: "FY17" },
        { id: 2, name: "FY18" },
        { id: 3, name: "FY19" },
        { id: 4, name: "FY20" },
        { id: 5, name: "FY21" },
        { id: 6, name: "FY22" },
      ],
      subColumnsHeaders: ["Q1", "Q2", "Q3", "Q4"],
      rows: [
        { id: 0, name: "Order Received" },
        { id: 1, name: "Sales" },
        { id: 2, name: "Myanmar" },
        { id: 3, name: "Japan" },
        { id: 4, name: "ICT-Mng" },
        { id: 5, name: "Facilities" },
        { id: 6, name: "Fiber" },
        { id: 7, name: "ICT Biz." },
        { id: 8, name: "Software&BPO" },
        { id: 9, name: "JP Biz." },
        { id: 10, name: "Cost of sales" },
        { id: 11, name: "GP" },
        { id: 12, name: "GP %" },
        { id: 13, name: "SGA" },
        { id: 14, name: "SGA %" },
        { id: 15, name: "Operating income" },
        { id: 16, name: "Operating income %" },
        { id: 17, name: "Non-operating income" },
        { id: 18, name: "Non-operating expenses" },
        { id: 19, name: "Ordinary Profit" },
        { id: 20, name: "Ordinary Profit %" },
        { id: 21, name: "Net income before tax" },
        { id: 22, name: "CT" },
        { id: 23, name: "Net income" },
      ],
    };
  }

  componentDidMount() {}
  componentDidUpdate() {
    this._generateBasicOperatorsSheet(this.state.flex);
    this.mergeCells(this.state.flex);
    this.dataBinding(this.state.flex);
  }

  render() {
    return (
      <div>
        <div
          className="row justify-content-start"
          style={{ marginLeft: "0px" }}
        ></div>

        <wjGridSheet.FlexSheet
          initialized={this.initializeFlexSheet.bind(this)}
          itemsSourceChanged={this.initializeFlexSheet.bind(this)}
          isReadOnly={true}
          style={{
            maxWidth: "100%",
            height: `${(this.state.rows.length + 3.3) * 20}px`,
          }}
          id="finicialDataSheet"
          className="financial_quarter_data"
        >
          <wjGridSheet.Sheet
            name="Financial Quarterly Report"
            columnCount={this.state.subColumnsHeaders.length + 3}
            rowCount={this.state.rows.length + 2}
          ></wjGridSheet.Sheet>
        </wjGridSheet.FlexSheet>
      </div>
    );
  }
  initializeFlexSheet(flex) {
    flex.deferUpdate(() => {
      this._generateBasicOperatorsSheet(flex);
      let colCnt = flex.rowHeaders.columns.length;
      flex.rowHeaders.columns.removeAt(colCnt - 1);

      let rowCnt = flex.columnHeaders.rows.length;
      flex.columnHeaders.rows.removeAt(rowCnt - 1);
    });
    flex.frozenColumns = 3;
    flex.frozenRows = 2;
    this.setState({ flex: flex });
  }

  _generateBasicOperatorsSheet(flexSheet) {
    flexSheet.setCellData(0, 0, "Unit: M JPY");
    this.mergeCells(flexSheet);
    flexSheet.setCellData(0, 3, this.props.year);
    for (let i = 3; i < this.state.subColumnsHeaders.length + 3; i++) {
      flexSheet.setCellData(1, i, this.state.subColumnsHeaders[i - 3]);
    }

    let rows = 0;
    //rows
    for (let r = 2; r < this.state.rows.length + 2; r++) {
      flexSheet.setCellData(r, 0, this.state.rows[rows].name);
      rows++;
    }

    for (let i = 0; i < this.props.data.totalData.length; i++) {
      let key = Object.keys(this.props.data.totalData[i]);
      let subKeys = Object.keys(
        this.props.data.totalData[i][key[0]][this.props.year]
      );
      for (let k = 0; k < subKeys.length; k++) {
        flexSheet.setCellData(
          i + 2,
          3 + k,
          this.props.data.totalData[i][key[0]][this.props.year][subKeys[k]]
        );
      }
    }

    flexSheet.applyCellsStyle(
      {
        textAlign: "right",
      },
      [new wjcGrid.CellRange(2, 2, flexSheet.rows.length, 7)]
    );

    flexSheet.applyCellsStyle(
      {
        textAlign: "center",
      },
      [new wjcGrid.CellRange(0, 0, flexSheet.rows.length, 2)]
    );
    flexSheet.applyCellsStyle(
      {
        textAlign: "center",
      },
      [new wjcGrid.CellRange(0, 3, 1, 7)]
    );
    flexSheet.applyCellsStyle(
      {
        backgroundColor: "#ddebf7",
      },
      [new wjcGrid.CellRange(0, 3, 0, 7)]
    );
    flexSheet.applyCellsStyle(
      {
        backgroundColor: "#ffffcc",
      },
      [new wjcGrid.CellRange(13, 0, 14, 7)]
    );
    flexSheet.applyCellsStyle(
      {
        backgroundColor: "#ffffcc",
      },
      [new wjcGrid.CellRange(17, 0, 18, 7)]
    );
    flexSheet.applyCellsStyle(
      {
        backgroundColor: "#ffffcc",
      },
      [new wjcGrid.CellRange(21, 0, 23, 7)]
    );

    flexSheet.applyCellsStyle(
      {
        backgroundColor: "#ffccff",
      },
      [new wjcGrid.CellRange(25, 0, 25, 7)]
    );

    flexSheet.applyCellsStyle(
      {
        backgroundColor: "#ededed",
      },
      [new wjcGrid.CellRange(5, 0, 10, 7)]
    );

    flexSheet.applyCellsStyle(
      {
        fontSize: "10px",
        padding: "0px",
        paddingTop: "3px",
        paddingLeft: "6px",
        paddingRight: "6px",
        paddingBottom: "3px",
      },
      [
        new wjcGrid.CellRange(
          0,
          0,
          this.state.rows.length,
          this.state.columns.length * this.state.subColumnsHeaders.length + 3
        ),
      ]
    );

    for (let col = 0; col < 7; col++) {
      flexSheet.columns[col].width = "*";
      flexSheet.columns[col].minWidth = 40;
    }
    for (let row = 0; row < this.state.rows.length + 1; row++) {
      flexSheet.rows[row].height = 20;
    }
  }
  mergeCells(flexSheet) {
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 0, 1, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 3, 0, 7));
    for (let i = 2; i < this.state.rows.length + 2; i++) {
      flexSheet.mergeRange(new wjcGrid.CellRange(i, 0, i, 2));
    }
  }
  dataBinding(flexSheet) {
    for (let i = 0; i < this.props.data.totalData.length; i++) {
      let key = Object.keys(this.props.data.totalData[i]);
      let subKeys = Object.keys(
        this.props.data.totalData[i][key[0]][this.props.year]
          ? this.props.data.totalData[i][key[0]][this.props.year]
          : 0
      );
      for (let k = 0; k < subKeys.length; k++) {
        let val = 0;
        if (key[0].includes("%")) {
          val = this.props.data.totalData[i][key[0]][this.props.year][subKeys[k]] + "%";
        } else {
          for (let m = k; m >= 0; m--) {
            val += this.props.data.totalData[i][key[0]][this.props.year][subKeys[m]];
          }
        }
        flexSheet.setCellData(i + 2, 3 + k, val);
      }
    }
  }
}

FinancialDataQuarterSummary.propTypes = {
  year: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
};
