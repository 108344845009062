import React, { useState } from "react";
import { LOGIN_URL } from "../../constants/api.path";
import "../../assets/css/styles.css";
import moment from "moment";
import ForgetPasswordModal from "../../modalPopups/forgetPasswordModal";
import PropTypes from "prop-types";

function Login(props) {
  let { setCollapsed, changeLocation } = props;
  const [email, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");

  const [showForgetPasswordModal, setShowForgetPasswordModal] =
    React.useState(false);

  function toggleForgetPasswordModal() {
    setShowForgetPasswordModal(!showForgetPasswordModal);
  }

  async function userLogin(e) {
    e.preventDefault();
    let myData = { email, password };
    if (email !== "" && password !== "") {
      await fetch(LOGIN_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(myData),
      }).then(async (response) => {
        if (!response.ok) {
          const res = await response.json();
          localStorage.setItem("isLoggedIn", false);
          sessionStorage.setItem("isLoggedIn", false);
          setErrors(res.message);
        } else {
          const resultInJson = await response.json();
          if (props.remember) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("token", resultInJson.token);
            localStorage.setItem("userName", resultInJson.name);
          } else if (!props.remember) {
            sessionStorage.setItem("isLoggedIn", true);
            sessionStorage.setItem("token", resultInJson.token);
            sessionStorage.setItem("userName", resultInJson.name);
          }
          window.location.href = "/dashboard";
          sessionStorage.setItem("lastTimeStamp", moment());
          setCollapsed(false);
          changeLocation("Dashboard");
        }
      });
    }
  }
  return (
    <div className="row justify-content-center main--body" id="mainbody">
      <div className="col-md-8 col-lg-6">
        <div className="card">
          <div className="card-header">Login</div>
          <div className="card-body">
            <form onSubmit={userLogin}>
              <div className="row mb-3 justify-content-center">
                <label className="col-md-4 col-form-label">Email Address</label>
                <div className="col-md-6">
                  <input
                    id="emailAddress"
                    type="email"
                    className="form-control"
                    value={email}
                    required
                    autoFocus
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mb-3 justify-content-center">
                <label className="col-md-4 col-form-label">Password</label>
                <div className="col-md-6">
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mb-3 justify-content-center">
                <div className="col-md-6 offset-md-4">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="remember"
                      id="remember"
                      checked={props.remember}
                      onChange={() => {
                        props.setRemember(!props.remember);
                      }}
                    />
                    <label className="form-check-label" htmlFor="remember">
                      Remember Me
                    </label>
                  </div>
                  {errors && <span className="error">{errors}</span>}
                </div>
              </div>
              <div className="row mb-0 justify-content-center">
                <div className="col-md-7 offset-md-5">
                  <button type="submit" className="btn btn-primary">
                    Login
                  </button>
                  {/* <Link
                    to="/login/forget-password"
                    style={{
                      height: "36px",
                      color: "blue",
                      textDecoration: "none",
                      lineHeight: "36px",
                      fontSize: "12px",
                      paddingLeft: "10px",
                      paddingRight: "20px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Forgot Your Password?
                  </Link> */}
                  <span
                    className=""
                    style={{
                      height: "36px",
                      color: "blue",
                      textDecoration: "none",
                      lineHeight: "36px",
                      fontSize: "12px",
                      paddingLeft: "10px",
                      paddingRight: "20px",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                    onClick={toggleForgetPasswordModal}
                  >
                    Forget Your Password?
                  </span>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ForgetPasswordModal
        email={email}
        setEmail={setEmailAddress}
        show={showForgetPasswordModal}
        onHide={toggleForgetPasswordModal}
        onSubmit={toggleForgetPasswordModal}
      />
    </div>
  );
}
export default Login;

Login.propTypes = {
  setCollapsed: PropTypes.func.isRequired,
  changeLocation: PropTypes.func.isRequired,
  remember: PropTypes.bool.isRequired,
  setRemember: PropTypes.func.isRequired,
};
