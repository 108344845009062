import React from "react";
import { RESET_PASSWORD_URL } from "../../constants/api.path";
import SuccessModal from "../../modalPopups/successModal";
import InfoModal from "../../modalPopups/infoModal";

export default function ResetPassword() {
  let token = new URL(window.location).searchParams.get("token");
  const [password, setPassword] = React.useState("");
  const [confirm, setConfirm] = React.useState("");
  const [error, setError] = React.useState("");
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);

  function updatePassword() {
    if (!password && !confirm) {
      setError("Please fill all fields.");
    }
    if (password !== confirm) {
      setError("Passwords are not match.");
    } else {
      fetch(RESET_PASSWORD_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accpet: "application/json",
        },
        body: JSON.stringify({ token: token, password: password }),
      })
        .then(async (response) => response.json())
        .then((result) => {
          if (result.result) {
            setShowSuccessModal(true);
          } else {
            setShowErrorModal(true);
          }
        });
    }
  }

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-6">
        <div className="card">
          <div className="card-header">Reset Password</div>
          <div className="card-body">
            <div className="row mb-3 justify-content-center">
              <label className="col-4 col-form-label" htmlFor="password">
                New Password
              </label>
              <div className="col-8 col-md-6">
                <input
                  id="password"
                  className="form-control"
                  type="password"
                  required
                  autoFocus
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    if (confirm && e.target.value !== confirm) {
                      setError("Passwords are not match.");
                    } else {
                      setError("");
                    }
                  }}
                ></input>
              </div>
            </div>
            <div className="row mb-3 justify-content-center">
              <label
                className="col-4 col-form-label"
                htmlFor="password-confirm"
              >
                Confirm New Password
              </label>
              <div className="col-8 col-md-6">
                <input
                  id="password-confirm"
                  className="form-control"
                  type="password"
                  required
                  autoFocus
                  value={confirm}
                  onChange={(e) => {
                    setConfirm(e.target.value);
                    if (password && e.target.value !== password) {
                      setError("Passwords are not match");
                    } else {
                      setError("");
                    }
                  }}
                ></input>
              </div>
            </div>
            <div className="row mb-3 justify-content-center">
              <span
                style={{ color: "red" }}
                className="col-8 col-md-6 offset-4 offset-md-4"
              >
                {error}
              </span>
            </div>
            <div className="row mb-3 justify-content-center">
              <div className="col-8 col-md-6 offset-4 offset-md-4">
                <button className="btn btn-primary" onClick={updatePassword}>
                  Reset Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SuccessModal
        show={showSuccessModal}
        title="Password Updated Successfully."
        onSubmit={() => {
          setShowSuccessModal(false);
          window.location.href = "/login";
        }}
        onHide={() => {
          setShowSuccessModal(false);
          window.location.href = "/login";
        }}
      />
      <InfoModal
        show={showErrorModal}
        title="Invalid Token."
        onSubmit={() => {
          setShowErrorModal(false);
          window.location.href = "/login";
        }}
        onHide={() => {
          setShowErrorModal(false);
          window.location.href = "/login";
        }}
      />
    </div>
  );
}
