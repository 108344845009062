import React, { useEffect, useMemo, useState } from "react";

import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";

import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";

import DataService from "../../../service/data-service";
import { GET_DATA_BY_QUERY } from "../../../utilities/constants";
import { DataConveter } from "../../../utilities/utilities";
import ErrorWidget from "../error/error-widget";
import PropTypes from "prop-types";
import LoadingComponents from "../../common/loading";
import { useDisclosure } from "@mantine/hooks";

const LineChart = (props) => {
  const { i, datasources, width, height, chartInitalized } = props;
  const [datasource, setDatasource] = useState([]);
  const [isLoading, loadingHandlers] = useDisclosure(true);
  const [isError, setIsError] = useState(false);
  const [serverData, setServerData] = useState([]);
  const [palette] = useState([
    "rgba(42,159,214,1)",
    "rgba(119,179,0,1)",
    "rgba(153,51,204,1)",
    "rgba(255,136,0,1)",
    "rgba(204,0,0,1)",
    "rgba(0,204,163,1)",
    "rgba(61,109,204,1)",
    "rgba(82,82,82,1)",
    "rgba(0,0,0,1)",
  ]);

  const memoizedDatasources = useMemo(() => datasources, [datasources]);

  useEffect(() => {
    const path = GET_DATA_BY_QUERY;
    const requestObject = {
      query: i.query,
      table_name: i.tableName,
    };
    // SID002 Get Data by queries
    DataService(path, requestObject).then(({ data, error }) => {
      if (error) {
        setIsError(true);
        loadingHandlers.close();
      } else {
        setIsError(false);
        setServerData(DataConveter(data.metadata));
        setDatasource(datasources);
        loadingHandlers.close();
      }
    });
    return () => {
      setServerData([]);
      setDatasource([]);
      loadingHandlers.open();
    };
  }, [memoizedDatasources]);

  if (isLoading)
    return (
      <div className="container-fluid">
        <LoadingComponents />
      </div>
    );

  function getChartType(chart_type) {
    switch (chart_type) {
      case "spline":
        return "SplineSymbols";
      case "line":
        return "Line";

      default:
        return "Line";
    }
  }

  if (isError) {
    return <ErrorWidget />;
  }

  return (
    <div className="container-fluid">
      <wjChart.FlexChart
        initialized={chartInitalized}
        style={{ width: width, height: height }}
        itemsSource={serverData}
        bindingX={datasource.configure.bindingX}
        chartType={getChartType(datasource.settings.lineChartType)}
        header={datasource.header}
        legendToggle={datasource.settings.legendToggle}
        palette={palette}
      >
        {datasource.settings.legendPosition && (
          <wjChart.FlexChartLegend
            position={datasource.settings.legendPosition}
          />
        )}
        <wjChart.FlexChartDataLabel
          position={datasource.settings.dataLabelPosition}
          content="{y}"
        />
        <wjChart.FlexChartAxis
          wjProperty={"axisX"}
          title={datasource.settings.xAxisTitle}
        ></wjChart.FlexChartAxis>
        <wjChart.FlexChartAxis
          wjProperty={"axisY"}
          title={datasource.settings.yAxisTitle}
        ></wjChart.FlexChartAxis>
        {datasource.configure.bindingY.map((item) => (
          <wjChart.FlexChartSeries
            name={item}
            binding={item}
            key={`bar-chart-${item}`}
          />
        ))}
        <wjChartAnimate.FlexChartAnimation animationMode="Point"></wjChartAnimate.FlexChartAnimation>
      </wjChart.FlexChart>
    </div>
  );
};

export default LineChart;

LineChart.propTypes = {
  i: PropTypes.any,
  datasources: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  chartInitalized: PropTypes.any,
};
