import * as React from "react";
import { useEffect, useState } from "react";

import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";

import { useDisclosure } from "@mantine/hooks";

import { DataConveter } from "../../../utilities/utilities";
import LoadingComponents from "../../common/loading";
import ErrorWidget from "../error/error-widget";
import axios from "axios";
import PropTypes from "prop-types";

// function dataFiltering(rows, columns, by, rowKey, dataRows) {
//   let result = [];
//   let rowMap = {};
//   for (let row of rows) {
//     let rowObj = {};
//     rowObj[rowKey] = row;
//     for (let column of columns) {
//       rowObj[column] = null;
//     }
//     rowMap[row] = rowObj;
//   }
//   // for (let dataRow of dataRows) {
//   //   let rowType = dataRow[0];
//   //   let fiscalYear = dataRow[1];
//   //   let quarter = dataRow[2];
//   //   let value = dataRow[3];

//   //   if (rowMap[rowType] !== undefined && quarter === by) {
//   //     if (columns.includes(fiscalYear)) {
//   //       rowMap[rowType][fiscalYear] = value;
//   //     }
//   //   }
//   // }

//   // Add row objects to result array.
//   for (let row of rows) {
//     result.push(rowMap[row]);
//   }

//   return result;
// }

// function dataFilteringByMultiQuartar(rows, columns, by, rowKey, dataRows) {
//   let result = [];
//   let rowMap = {};

//   // Create initial mapping of row types to row objects.
//   for (let row of rows) {
//     let rowObj = {};
//     rowObj[rowKey] = row;
//     for (let column of columns) {
//       for (let b of by) {
//         rowObj[column + " " + b] = null;
//       }
//     }
//     rowMap[row] = rowObj;
//   }

//   // Populate row objects with values from data rows.
//   for (let dataRow of dataRows) {
//     let rowType = dataRow[0];
//     let fiscalYear = dataRow[1];
//     let quarter = dataRow[2];
//     let value = dataRow[3];

//     if (rowMap[rowType] !== undefined && by.includes(quarter)) {
//       let key = fiscalYear + " " + quarter;
//       if (Object.keys(rowMap[rowType]).includes(key)) {
//         rowMap[rowType][key] = value;
//       }
//     }
//   }

//   // Add row objects to result array.
//   for (let row of rows) {
//     result.push(rowMap[row]);
//   }

//   return result;
// }

const FlexSheet = (props) => {
  const { i, datasources, width, height } = props;
  // const [metadataState] = useAtom({});
  const [serverData, setServerData] = useState([]);
  const [loading, loadingHandlers] = useDisclosure(true);
  const [isError, setIsError] = useState(false);
  const [flex, setFlex] = useState(null);
  const fetchData = async (path) => {
    // const columns = datasources.configure.columns;
    // const newMetaState = { ...metadataState, column_names: columns };
    try {
      const response = await axios.get(`json/${path}.json`);
      const dataConverted = DataConveter(response.data.metadata);
      for (let index = 0; index < response.column_names.length; index++) {
        // const column = columns[index];
        // const metaColumn = response.column_names[index];

        for (const key in dataConverted) {
          delete dataConverted[key][undefined];
        }
      }
      setServerData(dataConverted);
      loadingHandlers.close();
    } catch (error) {
      setIsError(true);
      loadingHandlers.close();
    }
  };

  const memoizedDatasources = React.useMemo(() => datasources, [datasources]);

  useEffect(() => {
    fetchData(i);
    if (flex) {
      // flexInitialized(flex);
    }
    // const columns = datasources.configure.columns;
    // const rowKey = "sector-data";
    loadingHandlers.close();
  }, [memoizedDatasources]);

  function initializeFlexSheet(flex) {
    setFlex(flex);
  }
  if (isError) {
    return <ErrorWidget />;
  }
  return (
    <div className="container-fluid">
      {loading ? (
        <LoadingComponents />
      ) : (
        <wjGridSheet.FlexSheet
          id="hFlexGrid"
          initialized={initializeFlexSheet}
          style={{ width: width, height: height }}
          headersVisibility={false}
        >
          <wjGridSheet.Sheet
            name={datasources.settings.sheetName}
            itemsSource={serverData}
          ></wjGridSheet.Sheet>
        </wjGridSheet.FlexSheet>
      )}
    </div>
  );
};

export default FlexSheet;

FlexSheet.propTypes = {
  i: PropTypes.any,
  datasources: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
};
