import React from "react";

// wijmo
import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";
import PropTypes from "prop-types";

export default function DashboardBarChart({ data }) {
  return (
    <div>
      <wjChart.FlexChart
        bindingX="name"
        itemsSource={data}
        chartType="Bar"
        selectionMode="Series"
        palette={["#32CD32", "#FFFF00"]}
      >
        <wjChart.FlexChartLegend position="Bottom" reversed={true} />
        <wjChart.FlexChartSeries name="実績" binding="actual" />
        <wjChart.FlexChartSeries name="予算" binding="plan" />
        <wjChart.FlexChartAxis
          wjProperty="axisY"
          reversed={true}
        ></wjChart.FlexChartAxis>
        <wjChart.FlexChartDataLabel content="{y}" position="Left" />
        <wjChartAnimate.FlexChartAnimation />
      </wjChart.FlexChart>
    </div>
  );
}

DashboardBarChart.propTypes = {
  data: PropTypes.isRequired,
};
