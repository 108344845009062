import React from "react";
import {
  BUSINESS_SECTOR_LIST_URL,
  FISCAL_YEAR_LIST_URL,
  TOKEN,
  REPORTCOST_IMPORT_URL,
} from "../../constants/api.path";
import * as wjcCore from "@grapecity/wijmo";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import SuccessModal from "../../modalPopups/successModal";
import { BiSave } from "react-icons/bi";
import { AiOutlineReload } from "react-icons/ai";
import { Tooltip as ReactTooltip } from "react-tooltip";
import cacheUpdate from "../cacheUpdate/cacheUpdate";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

export default class CostImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      flex: null,
      error: false,
      fiscalList: [],
      salesPlanYear: null,
      month: null,
      sheetName: null,
      sectName: [],
      showSuccessModal: "",
    };
    this.months = [
      { id: 1, name: "Jan" },
      { id: 2, name: "Feb" },
      { id: 3, name: "Mar" },
      { id: 4, name: "Apr" },
      { id: 5, name: "May" },
      { id: 6, name: "Jun" },
      { id: 7, name: "Jul" },
      { id: 8, name: "Aug" },
      { id: 9, name: "Sep" },
      { id: 10, name: "Oct" },
      { id: 11, name: "Nov" },
      { id: 12, name: "Dec" },
    ];
    this.sheets = [{ id: 1, name: "ReportCost" }];
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ fiscalList: result.data.fiscalYearList });
        let yearName = [];
        result.data.fiscalYearList.forEach(function (fYear) {
          yearName.push(fYear.fiscalYear);
        });
        this.setState({ fYName: yearName });
      });

    fetch(BUSINESS_SECTOR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ sectorList: result.data.sectorList });
        let sectorName = [];
        result.data.sectorList.forEach(function (sector) {
          sectorName.push(sector.sectorName);
        });
        this.setState({ sectName: sectorName });
      });
  }

  handleChange(e) {
    const fieldValue = e.target.value;
    this.setState({ salesPlanYear: fieldValue });
  }

  handleMonthChange(e) {
    const fieldValue = e.target.value;
    this.setState({ month: fieldValue });
  }

  getFileName() {
    let fileName = document.getElementById("costImport").files[0].name;
    this.setState({
      fileName: fileName,
    });
  }

  getSheetName() {
    let sheetName = document.getElementById("sheetName").value;
    this.setState({ sheetName: sheetName });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row justify-content-between" style={{ margin: "0" }}>
          <div className="col-md-6">
            <div className="row">
              <div
                className="form-inline col-md-6"
                style={{ paddingLeft: "0.3vw", paddingRight: "0" }}
              >
                <input
                  type="file"
                  className="form-control"
                  id="costImport"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={this.getFileName.bind(this)}
                />
              </div>
              <div
                className="form-inline col-md-1"
                style={{ paddingLeft: "5px" }}
              >
                <button
                  className="actionIconOutline load bg-blue"
                  onClick={this.load.bind(this)}
                  id="loadButton"
                  data-tooltip-content="Load"
                >
                  <AiOutlineReload />
                  <ReactTooltip place="right" anchorId="loadButton" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row justify-content-end">
              <div className="col-md-3" style={{ paddingRight: "4px" }}>
                <select
                  name="fiscalYear"
                  className="form-select"
                  onChange={this.handleChange}
                >
                  <option value="" hidden>
                    Fiscal Year
                  </option>
                  {this.state.fiscalList.map((item, index) => (
                    <option key={index + 1} value={item.fiscalYearId}>
                      {item.fiscalYear}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3" style={{}}>
                <select
                  name="month"
                  className="form-select"
                  onChange={(e) => this.handleMonthChange(e)}
                >
                  <option value="" hidden>
                    Month
                  </option>
                  {this.months.map((month, index) => (
                    <option key={index} value={month.id}>
                      {month.name}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="col-md-3"
                style={{ paddingLeft: "4px", paddingRight: "0" }}
              >
                <select
                  name="sheetname"
                  className="form-select"
                  id="sheetName"
                  onChange={() => this.getSheetName()}
                >
                  <option value="" hidden>
                    Sheet Name
                  </option>
                  {this.sheets.map((sheet) => (
                    <option key={sheet.id} value={sheet.name}>
                      {sheet.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-1" style={{ paddingLeft: "5px" }}>
                <button
                  className="actionIconOutline save bg-blue"
                  id="saveButton"
                  data-tooltip-content="Save"
                  onClick={() => this.getFileData(this.state.salesPlanYear)}
                >
                  <BiSave />
                  <ReactTooltip place="right" anchorId="saveButton" />
                </button>
              </div>
              <SuccessModal
                show={this.state.showSuccessModal}
                title={this.state.resultMessage}
                onSubmit={() => {
                  this.setState({ showSuccessModal: false }, () => {});
                }}
                onHide={() => {
                  this.setState({ showSuccessModal: false }, () => {});
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "10px" }}>
          {this.state.error && (
            <span className="error">{this.errorMessage}</span>
          )}
        </div>

        <wjGridSheet.FlexSheet
          initialized={this.initializeFlexSheet.bind(this)}
          style={{
            height: "540px",
          }}
          id="budgetSheet"
        >
          <wjGridSheet.Sheet name="New Sheet" />
        </wjGridSheet.FlexSheet>
      </div>
    );
  }

  initializeFlexSheet(flex) {
    this.setState({
      flex: flex,
    });
  }

  load() {
    this.setState({ error: false });
    let file = document.getElementById("costImport");
    if (this.state.flex && file.files[0]) {
      this.state.flex.loadAsync(file.files[0]);
    } else {
      this.setState({ error: true });
      this.errorMessage = "There is no upload file, please choose a file!";
    }
  }

  getFileData(salesPlanYear) {
    let costList = [];
    const sheet = this.state.flex.selectedSheet;
    this.setState({ error: false });
    let fileInput = document.getElementById("costImport").files;
    if (!fileInput[0]) {
      this.setState({ error: true });
      this.errorMessage = "There is no upload file!";
    } else if (salesPlanYear === null) {
      this.setState({ error: true });
      this.errorMessage = "Please select fiscal year!";
    } else if (this.state.month === null) {
      this.setState({ error: true });
      this.errorMessage = "Please select month!";
    } else if (this.state.sheetName === null) {
      this.setState({ error: true });
      this.errorMessage = "Please select sheet name!";
    } else if (this.state.sheetName !== sheet.name) {
      this.setState({ error: true });
      this.errorMessage = "Please select " + this.state.sheetName + " sheet!";
    } else {
      for (let r = 1; r < this.state.flex.rows.length; r++) {
        let columnsHeaders = [
          { header: "Order Number", value: "" },
          { header: "Project Name", value: "" },
          { header: "Department", value: "" },
          { header: "Sales Month", value: "" },
          { header: "Sales Rank", value: "" },
          { header: "NESIC/Others", value: "" },
          { header: "Debit Cost", value: "" },
          { header: "Credit Cost", value: "" },
          { header: "Balance", value: "" },
          { header: "Currency", value: "" },
        ];
        for (let c = 2; c < this.state.flex.columns.length; c++) {
          if (
            this.state.flex.getCellValue(0, c) === columnsHeaders[c - 2].header
          ) {
            columnsHeaders[c - 2].value = this.state.flex.getCellValue(r, c);
            if (
              this.state.flex.getCellValue(0, c) === columnsHeaders[2].header
            ) {
              this.state.sectorList.forEach((sector) => {
                if (this.state.flex.getCellValue(r, c).includes(sector.sectorName)) {
                  columnsHeaders[c - 2].value = sector.sectorId;
                }
              });
            }
            if (this.state.flex.getCellValue(0, c) === columnsHeaders[4].header) {
              if (columnsHeaders[c - 2].value === 'A') {
                columnsHeaders[c - 2].value = 1;
              } else if (columnsHeaders[c - 2].value === 'B') {
                columnsHeaders[c - 2].value = 2;
              } else if (columnsHeaders[c - 2].value === 'C') {
                columnsHeaders[c - 2].value = 3;
              } else if (columnsHeaders[c - 2].value === 'D') {
                columnsHeaders[c - 2].value = 4;
              } else {
                columnsHeaders[c - 2].value = 5;
              }
            }
            if (this.state.flex.getCellValue(0, c) === columnsHeaders[5].header) {
              columnsHeaders[c - 2].value = columnsHeaders[c - 2].includes("NESIC") ? 1 : columnsHeaders[c - 2].includes("Other") && 2;
            }
          }
        }
        costList.push({
          orderNumber: columnsHeaders[0].value,
          projectName: columnsHeaders[1].value,
          sectorId: columnsHeaders[2].value,
          salesMonth: columnsHeaders[3].value,
          salesRank: columnsHeaders[4].value,
          customerGroup: columnsHeaders[5].value,
          debitCost: columnsHeaders[6].value,
          creditCost: columnsHeaders[7].value,
          balance: columnsHeaders[8].value,
          currency: columnsHeaders[9].value,
        });
      }
      this.savetoDB({ costList: costList });
    }
  }

  savetoDB(added) {
    let url = `${REPORTCOST_IMPORT_URL}/salesPlanYear/${this.state.salesPlanYear}/salesPlanMonth/${this.state.month}`;
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify(added),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.result) {
          this.setState(
            {
              resultMessage: response.message,
              showSuccessModal: true,
            },
            () => {
              cacheUpdate(
                this.state.salesPlanYear, this.state.month
              );
            }
          );
        }
      });
  }
}
