import "bootstrap/dist/css/bootstrap.css";
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjcCore from "@grapecity/wijmo";
import * as React from "react";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import dashboardSheet from "./dashboardHideCol.json";
import "../../assets/css/dashboard.css";
import moment from "moment/moment";
import {
  BC_ANNUAL_YEN_LIST,
  FISCAL_YEAR_LIST_URL,
  TOKEN,
} from "../../constants/api.path";
import { Spinner } from "reactstrap";
import {
  hideRowDefault,
  hideColumnDefault,
  showColumnDefault,
} from "./dashboardHideRowCol";
import PropTypes from "prop-types";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

class DashboardSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flex: null,
      isFrozen: false,
      isLoading: true,
      hideRows: [],
      hideColumns: [],
      data: [],
      active: "",
      sheetName: "",
      compName: "",
      lastDate: "",
      currentYearId: [],
      sectorList: [],
      fiscalList: [],
      selectedArray: { salesPlanMonth: new Date().getMonth() + 1 },
      salesPlanYearName: [],
      salesPlanMonthName: [],
      collapsed: props.collapsed,
      buttons: [
        {
          id: 4,
          name: "APR",
          activeName: "April",
          dataName: dashboardSheet.april,
        },
        { id: 5, name: "MAY", activeName: "May", dataName: dashboardSheet.may },
        {
          id: 6,
          name: "JUN",
          activeName: "June",
          dataName: dashboardSheet.june,
        },
        {
          id: 7,
          name: "JUL",
          activeName: "July",
          dataName: dashboardSheet.july,
        },
        {
          id: 8,
          name: "AUG",
          activeName: "August",
          dataName: dashboardSheet.august,
        },
        {
          id: 9,
          name: "SEP",
          activeName: "September",
          dataName: dashboardSheet.september,
        },
        {
          id: 10,
          name: "OCT",
          activeName: "October",
          dataName: dashboardSheet.october,
        },
        {
          id: 11,
          name: "NOV",
          activeName: "November",
          dataName: dashboardSheet.november,
        },
        {
          id: 12,
          name: "DEC",
          activeName: "December",
          dataName: dashboardSheet.december,
        },
        {
          id: 1,
          name: "JAN",
          activeName: "January",
          dataName: dashboardSheet.january,
        },
        {
          id: 2,
          name: "FEB",
          activeName: "February",
          dataName: dashboardSheet.february,
        },
        {
          id: 3,
          name: "MAR",
          activeName: "March",
          dataName: dashboardSheet.march,
        },
      ],
    };
  }

  componentDidMount() {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState(
          {
            selectedArray: {
              salesPlanYear: result.data.currentYearId,
              ...this.state.selectedArray,
            },
            fiscalList: result.data.fiscalYearList,
            currentYearId: result.data.currentYearId,
          },
          () => {
            this.getYearMonthName();
            this.getDashboardSheetData();
          }
        );
      });

    let currentSheet = [
      { id: 0, name: "january" },
      { id: 1, name: "february" },
      { id: 2, name: "march" },
      { id: 3, name: "april" },
      { id: 4, name: "may" },
      { id: 5, name: "june" },
      { id: 6, name: "july" },
      { id: 7, name: "august" },
      { id: 8, name: "september" },
      { id: 9, name: "october" },
      { id: 10, name: "november" },
      { id: 11, name: "december" },
    ];
    this.setState(
      {
        active:
          currentSheet[new Date().getMonth()].name[0].toUpperCase() +
          currentSheet[new Date().getMonth()].name.slice(1),
        data: dashboardSheet[currentSheet[new Date().getMonth()].name],
        sheetName:
          currentSheet[new Date().getMonth()].name[0].toUpperCase() +
          currentSheet[new Date().getMonth()].name.slice(1),
      },
      () => {
        hideColumnDefault(this.state.flex, this.state.data);
      }
    );
  }

  getDashboardSheetData() {
    fetch(
      BC_ANNUAL_YEN_LIST +
        "/salesPlanYear/" +
        this.state.selectedArray.salesPlanYear +
        "/salesPlanMonth/" +
        this.state.selectedArray.salesPlanMonth,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    )
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ isLoading: false });
        if (result.result) {
          this.setState({
            lastDate: result.lastDate,
            sectorList: result.data.businessSector,
          });
          this.setState({ compName: result.data.bcAnnualList[0].companyName });
          this.bcAnnualDataBinding(
            result.lastDate,
            result.data.bcAnnualList,
            result.data.businessSector
          );
        }
      });
    this.mergeSectorCell();
  }

  mergeSectorCell() {
    let contentStartRow = 26;
    for (let row = 0; row < this.state.sectorList.length / 23; row++) {
      this.setBCContent(this.state.flex, contentStartRow);
      contentStartRow += 23;
    }
  }

  bcAnnualDataBinding(lastDate, bcAnnualList, sectorList) {
    let flex = this.state.flex;
    flex.selectedSheetIndex = 0;

    flex.setCellData(1, 9, "vs (" + lastDate + ")");
    flex.setCellData(1, 18, "vs (" + lastDate + ")");
    flex.setCellData(1, 27, "vs (" + lastDate + ")");
    flex.setCellData(1, 36, "vs (" + lastDate + ")");
    flex.setCellData(1, 45, "vs (" + lastDate + ")");
    flex.setCellData(1, 54, "vs (" + lastDate + ")");
    flex.setCellData(1, 63, "vs (" + lastDate + ")");

    let r = 3;
    for (let row = 0; row < bcAnnualList.length; row++) {
      if (
        (row % 10 === 0 && row / 10 === 1) ||
        (row % 15 === 0 && row / 15 === 1) ||
        (row % 19 === 0 && row / 19 === 1)
      ) {
        flex.setCellData(r, 0, "Total of " + bcAnnualList[row].companyName);
        flex.setCellData(
          r,
          3,
          (bcAnnualList[row].draftBottom1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          4,
          (bcAnnualList[row].draftStretch1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          5,
          (bcAnnualList[row].draftTotal1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          6,
          (bcAnnualList[row].budBudget1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          7,
          (bcAnnualList[row].budVariance1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          8,
          (bcAnnualList[row].preBudget1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          9,
          (bcAnnualList[row].preVariance1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          10,
          (bcAnnualList[row].lastYearActual1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          11,
          (bcAnnualList[row].lastYearVariance1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          12,
          (bcAnnualList[row].draftBottom2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          13,
          (bcAnnualList[row].draftStretch2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          14,
          (bcAnnualList[row].draftTotal2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          15,
          (bcAnnualList[row].budBudget2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          16,
          (bcAnnualList[row].budVariance2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          17,
          (bcAnnualList[row].preBudget2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          18,
          (bcAnnualList[row].preVariance2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          19,
          (bcAnnualList[row].lastYearActual2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          20,
          (bcAnnualList[row].lastYearVariance2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          21,
          (bcAnnualList[row].draftBottom1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          22,
          (bcAnnualList[row].draftStretch1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          23,
          (bcAnnualList[row].draftTotal1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          24,
          (bcAnnualList[row].budBudget1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          25,
          (bcAnnualList[row].budVariance1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          26,
          (bcAnnualList[row].preBudget1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          27,
          (bcAnnualList[row].preVariance1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          28,
          (bcAnnualList[row].lastYearActual1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          29,
          (bcAnnualList[row].lastYearVariance1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          30,
          (bcAnnualList[row].draftBottom3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          31,
          (bcAnnualList[row].draftStretch3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          32,
          (bcAnnualList[row].draftTotal3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          33,
          (bcAnnualList[row].budBudget3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          34,
          (bcAnnualList[row].budVariance3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          35,
          (bcAnnualList[row].preBudget3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          36,
          (bcAnnualList[row].preVariance3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          37,
          (bcAnnualList[row].lastYearActual3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          38,
          (bcAnnualList[row].lastYearVariance3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          39,
          (bcAnnualList[row].draftBottom4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          40,
          (bcAnnualList[row].draftStretch4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          41,
          (bcAnnualList[row].draftTotal4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          42,
          (bcAnnualList[row].budBudget4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          43,
          (bcAnnualList[row].budVariance4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          44,
          (bcAnnualList[row].preBudget4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          45,
          (bcAnnualList[row].preVariance4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          46,
          (bcAnnualList[row].lastYearActual4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          47,
          (bcAnnualList[row].lastYearVariance4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          48,
          (bcAnnualList[row].draftBottom2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          49,
          (bcAnnualList[row].draftStretch2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          50,
          (bcAnnualList[row].draftTotal2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          51,
          (bcAnnualList[row].budBudget2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          52,
          (bcAnnualList[row].budVariance2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          53,
          (bcAnnualList[row].preBudget2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          54,
          (bcAnnualList[row].preVariance2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          55,
          (bcAnnualList[row].lastYearActual2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          56,
          (bcAnnualList[row].lastYearVariance2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          57,
          (bcAnnualList[row].draftBottomAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          58,
          (bcAnnualList[row].draftStretchAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          59,
          (bcAnnualList[row].draftTotalAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          60,
          (bcAnnualList[row].budBudgetAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          61,
          (bcAnnualList[row].budVarianceAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          62,
          (bcAnnualList[row].preBudgetAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          63,
          (bcAnnualList[row].preVarianceAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          64,
          (bcAnnualList[row].lastYearActualAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          65,
          (bcAnnualList[row].lastYearVarianceAnnual * 100).toFixed(1) + "%"
        );
      } else {
        flex.setCellData(r, 0, "Total of " + bcAnnualList[row].companyName);
        flex.setCellData(r, 3, bcAnnualList[row].draftBottom1Q.toFixed(0));
        flex.setCellData(r, 4, bcAnnualList[row].draftStretch1Q.toFixed(0));
        flex.setCellData(r, 5, bcAnnualList[row].draftTotal1Q.toFixed(0));
        flex.setCellData(r, 6, bcAnnualList[row].budBudget1Q.toFixed(0));
        flex.setCellData(r, 7, bcAnnualList[row].budVariance1Q.toFixed(0));
        flex.setCellData(r, 8, bcAnnualList[row].preBudget1Q.toFixed(0));
        flex.setCellData(r, 9, bcAnnualList[row].preVariance1Q.toFixed(0));
        flex.setCellData(r, 10, bcAnnualList[row].lastYearActual1Q.toFixed(0));
        flex.setCellData(
          r,
          11,
          bcAnnualList[row].lastYearVariance1Q.toFixed(0)
        );
        flex.setCellData(r, 12, bcAnnualList[row].draftBottom2Q.toFixed(0));
        flex.setCellData(r, 13, bcAnnualList[row].draftStretch2Q.toFixed(0));
        flex.setCellData(r, 14, bcAnnualList[row].draftTotal2Q.toFixed(0));
        flex.setCellData(r, 15, bcAnnualList[row].budBudget2Q.toFixed(0));
        flex.setCellData(r, 16, bcAnnualList[row].budVariance2Q.toFixed(0));
        flex.setCellData(r, 17, bcAnnualList[row].preBudget2Q.toFixed(0));
        flex.setCellData(r, 18, bcAnnualList[row].preVariance2Q.toFixed(0));
        flex.setCellData(r, 19, bcAnnualList[row].lastYearActual2Q.toFixed(0));
        flex.setCellData(
          r,
          20,
          bcAnnualList[row].lastYearVariance2Q.toFixed(0)
        );
        flex.setCellData(r, 21, bcAnnualList[row].draftBottom1H.toFixed(0));
        flex.setCellData(r, 22, bcAnnualList[row].draftStretch1H.toFixed(0));
        flex.setCellData(r, 23, bcAnnualList[row].draftTotal1H.toFixed(0));
        flex.setCellData(r, 24, bcAnnualList[row].budBudget1H.toFixed(0));
        flex.setCellData(r, 25, bcAnnualList[row].budVariance1H.toFixed(0));
        flex.setCellData(r, 26, bcAnnualList[row].preBudget1H.toFixed(0));
        flex.setCellData(r, 27, bcAnnualList[row].preVariance1H.toFixed(0));
        flex.setCellData(r, 28, bcAnnualList[row].lastYearActual1H.toFixed(0));
        flex.setCellData(
          r,
          29,
          bcAnnualList[row].lastYearVariance1H.toFixed(0)
        );
        flex.setCellData(r, 30, bcAnnualList[row].draftBottom3Q.toFixed(0));
        flex.setCellData(r, 31, bcAnnualList[row].draftStretch3Q.toFixed(0));
        flex.setCellData(r, 32, bcAnnualList[row].draftTotal3Q.toFixed(0));
        flex.setCellData(r, 33, bcAnnualList[row].budBudget3Q.toFixed(0));
        flex.setCellData(r, 34, bcAnnualList[row].budVariance3Q.toFixed(0));
        flex.setCellData(r, 35, bcAnnualList[row].preBudget3Q.toFixed(0));
        flex.setCellData(r, 36, bcAnnualList[row].preVariance3Q.toFixed(0));
        flex.setCellData(r, 37, bcAnnualList[row].lastYearActual3Q.toFixed(0));
        flex.setCellData(
          r,
          38,
          bcAnnualList[row].lastYearVariance3Q.toFixed(0)
        );
        flex.setCellData(r, 39, bcAnnualList[row].draftBottom4Q.toFixed(0));
        flex.setCellData(r, 40, bcAnnualList[row].draftStretch4Q.toFixed(0));
        flex.setCellData(r, 41, bcAnnualList[row].draftTotal4Q.toFixed(0));
        flex.setCellData(r, 42, bcAnnualList[row].budBudget4Q.toFixed(0));
        flex.setCellData(r, 43, bcAnnualList[row].budVariance4Q.toFixed(0));
        flex.setCellData(r, 44, bcAnnualList[row].preBudget4Q.toFixed(0));
        flex.setCellData(r, 45, bcAnnualList[row].preVariance4Q.toFixed(0));
        flex.setCellData(r, 46, bcAnnualList[row].lastYearActual4Q.toFixed(0));
        flex.setCellData(
          r,
          47,
          bcAnnualList[row].lastYearVariance4Q.toFixed(0)
        );
        flex.setCellData(r, 48, bcAnnualList[row].draftBottom2H.toFixed(0));
        flex.setCellData(r, 49, bcAnnualList[row].draftStretch2H.toFixed(0));
        flex.setCellData(r, 50, bcAnnualList[row].draftTotal2H.toFixed(0));
        flex.setCellData(r, 51, bcAnnualList[row].budBudget2H.toFixed(0));
        flex.setCellData(r, 52, bcAnnualList[row].budVariance2H.toFixed(0));
        flex.setCellData(r, 53, bcAnnualList[row].preBudget2H.toFixed(0));
        flex.setCellData(r, 54, bcAnnualList[row].preVariance2H.toFixed(0));
        flex.setCellData(r, 55, bcAnnualList[row].lastYearActual2H.toFixed(0));
        flex.setCellData(
          r,
          56,
          bcAnnualList[row].lastYearVariance2H.toFixed(0)
        );
        flex.setCellData(r, 57, bcAnnualList[row].draftBottomAnnual.toFixed(0));
        flex.setCellData(
          r,
          58,
          bcAnnualList[row].draftStretchAnnual.toFixed(0)
        );
        flex.setCellData(r, 59, bcAnnualList[row].draftTotalAnnual.toFixed(0));
        flex.setCellData(r, 60, bcAnnualList[row].budBudgetAnnual.toFixed(0));
        flex.setCellData(r, 61, bcAnnualList[row].budVarianceAnnual.toFixed(0));
        flex.setCellData(r, 62, bcAnnualList[row].preBudgetAnnual.toFixed(0));
        flex.setCellData(r, 63, bcAnnualList[row].preVarianceAnnual.toFixed(0));
        flex.setCellData(
          r,
          64,
          bcAnnualList[row].lastYearActualAnnual.toFixed(0)
        );
        flex.setCellData(
          r,
          65,
          bcAnnualList[row].lastYearVarianceAnnual.toFixed(0)
        );
      }

      r++;
    }
    let b = 26;
    let contentStartRow = 26;
    for (let row = 0; row < sectorList.length / 23; row++) {
      this.setBCContent(flex, contentStartRow);
      contentStartRow += 23;
    }
    for (let row = 0; row < sectorList.length; row++) {
      if (
        ((row % 23) % 10 === 0 && (row % 23) / 10 === 1) ||
        ((row % 23) % 15 === 0 && (row % 23) / 15 === 1) ||
        ((row % 23) % 19 === 0 && (row % 23) / 19 === 1)
      ) {
        flex.setCellData(b, 0, sectorList[row].sectorName);
        flex.setCellData(
          b,
          3,
          (sectorList[row].draftBottom1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          4,
          (sectorList[row].draftStretch1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          5,
          (sectorList[row].draftTotal1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          6,
          (sectorList[row].budBudget1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          7,
          (sectorList[row].budVariance1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          8,
          (sectorList[row].preBudget1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          9,
          (sectorList[row].preVariance1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          10,
          (sectorList[row].lastYearActual1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          11,
          (sectorList[row].lastYearVariance1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          12,
          (sectorList[row].draftBottom2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          13,
          (sectorList[row].draftStretch2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          14,
          (sectorList[row].draftTotal2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          15,
          (sectorList[row].budBudget2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          16,
          (sectorList[row].budVariance2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          17,
          (sectorList[row].preBudget2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          18,
          (sectorList[row].preVariance2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          19,
          (sectorList[row].lastYearActual2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          20,
          (sectorList[row].lastYearVariance2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          21,
          (sectorList[row].draftBottom1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          22,
          (sectorList[row].draftStretch1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          23,
          (sectorList[row].draftTotal1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          24,
          (sectorList[row].budBudget1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          25,
          (sectorList[row].budVariance1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          26,
          (sectorList[row].preBudget1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          27,
          (sectorList[row].preVariance1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          28,
          (sectorList[row].lastYearActual1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          29,
          (sectorList[row].lastYearVariance1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          30,
          (sectorList[row].draftBottom3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          31,
          (sectorList[row].draftStretch3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          32,
          (sectorList[row].draftTotal3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          33,
          (sectorList[row].budBudget3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          34,
          (sectorList[row].budVariance3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          35,
          (sectorList[row].preBudget3Q8100 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          36,
          (sectorList[row].preVariance3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          37,
          (sectorList[row].lastYearActual3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          38,
          (sectorList[row].lastYearVariance3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          39,
          (sectorList[row].draftBottom4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          40,
          (sectorList[row].draftStretch4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          41,
          (sectorList[row].draftTotal4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          42,
          (sectorList[row].budBudget4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          43,
          (sectorList[row].budVariance4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          44,
          (sectorList[row].preBudget4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          45,
          (sectorList[row].preVariance4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          46,
          (sectorList[row].lastYearActual4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          47,
          (sectorList[row].lastYearVariance4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          48,
          (sectorList[row].draftBottom2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          49,
          (sectorList[row].draftStretch2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          50,
          (sectorList[row].draftTotal2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          51,
          (sectorList[row].budBudget2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          52,
          (sectorList[row].budVariance2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          53,
          (sectorList[row].preBudget2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          54,
          (sectorList[row].preVariance2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          55,
          (sectorList[row].lastYearActual2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          56,
          (sectorList[row].lastYearVariance2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          57,
          (sectorList[row].draftBottomAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          58,
          (sectorList[row].draftStretchAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          59,
          (sectorList[row].draftTotalAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          60,
          (sectorList[row].budBudgetAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          61,
          (sectorList[row].budVarianceAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          62,
          (sectorList[row].preBudgetAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          63,
          (sectorList[row].preVarianceAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          64,
          (sectorList[row].lastYearActualAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          65,
          (sectorList[row].lastYearVarianceAnnual * 100).toFixed(1) + "%"
        );
      } else {
        flex.setCellData(b, 0, sectorList[row].sectorName);
        flex.setCellData(b, 3, sectorList[row].draftBottom1Q.toFixed(0));
        flex.setCellData(b, 4, sectorList[row].draftStretch1Q.toFixed(0));
        flex.setCellData(b, 5, sectorList[row].draftTotal1Q.toFixed(0));
        flex.setCellData(b, 6, sectorList[row].budBudget1Q.toFixed(0));
        flex.setCellData(b, 7, sectorList[row].budVariance1Q.toFixed(0));
        flex.setCellData(b, 8, sectorList[row].preBudget1Q.toFixed(0));
        flex.setCellData(b, 9, sectorList[row].preVariance1Q.toFixed(0));
        flex.setCellData(b, 10, sectorList[row].lastYearActual1Q.toFixed(0));
        flex.setCellData(b, 11, sectorList[row].lastYearVariance1Q.toFixed(0));
        flex.setCellData(b, 12, sectorList[row].draftBottom2Q.toFixed(0));
        flex.setCellData(b, 13, sectorList[row].draftStretch2Q.toFixed(0));
        flex.setCellData(b, 14, sectorList[row].draftTotal2Q.toFixed(0));
        flex.setCellData(b, 15, sectorList[row].budBudget2Q.toFixed(0));
        flex.setCellData(b, 16, sectorList[row].budVariance2Q.toFixed(0));
        flex.setCellData(b, 17, sectorList[row].preBudget2Q.toFixed(0));
        flex.setCellData(b, 18, sectorList[row].preVariance2Q.toFixed(0));
        flex.setCellData(b, 19, sectorList[row].lastYearActual2Q.toFixed(0));
        flex.setCellData(b, 20, sectorList[row].lastYearVariance2Q.toFixed(0));
        flex.setCellData(b, 21, sectorList[row].draftBottom1H.toFixed(0));
        flex.setCellData(b, 22, sectorList[row].draftStretch1H.toFixed(0));
        flex.setCellData(b, 23, sectorList[row].draftTotal1H.toFixed(0));
        flex.setCellData(b, 24, sectorList[row].budBudget1H.toFixed(0));
        flex.setCellData(b, 25, sectorList[row].budVariance1H.toFixed(0));
        flex.setCellData(b, 26, sectorList[row].preBudget1H.toFixed(0));
        flex.setCellData(b, 27, sectorList[row].preVariance1H.toFixed(0));
        flex.setCellData(b, 28, sectorList[row].lastYearActual1H.toFixed(0));
        flex.setCellData(b, 29, sectorList[row].lastYearVariance1H.toFixed(0));
        flex.setCellData(b, 30, sectorList[row].draftBottom3Q.toFixed(0));
        flex.setCellData(b, 31, sectorList[row].draftStretch3Q.toFixed(0));
        flex.setCellData(b, 32, sectorList[row].draftTotal3Q.toFixed(0));
        flex.setCellData(b, 33, sectorList[row].budBudget3Q.toFixed(0));
        flex.setCellData(b, 34, sectorList[row].budVariance3Q.toFixed(0));
        flex.setCellData(b, 35, sectorList[row].preBudget3Q.toFixed(0));
        flex.setCellData(b, 36, sectorList[row].preVariance3Q.toFixed(0));
        flex.setCellData(b, 37, sectorList[row].lastYearActual3Q.toFixed(0));
        flex.setCellData(b, 38, sectorList[row].lastYearVariance3Q.toFixed(0));
        flex.setCellData(b, 39, sectorList[row].draftBottom4Q.toFixed(0));
        flex.setCellData(b, 40, sectorList[row].draftStretch4Q.toFixed(0));
        flex.setCellData(b, 41, sectorList[row].draftTotal4Q.toFixed(0));
        flex.setCellData(b, 42, sectorList[row].budBudget4Q.toFixed(0));
        flex.setCellData(b, 43, sectorList[row].budVariance4Q.toFixed(0));
        flex.setCellData(b, 44, sectorList[row].preBudget4Q.toFixed(0));
        flex.setCellData(b, 45, sectorList[row].preVariance4Q.toFixed(0));
        flex.setCellData(b, 46, sectorList[row].lastYearActual4Q.toFixed(0));
        flex.setCellData(b, 47, sectorList[row].lastYearVariance4Q.toFixed(0));
        flex.setCellData(b, 48, sectorList[row].draftBottom2H.toFixed(0));
        flex.setCellData(b, 49, sectorList[row].draftStretch2H.toFixed(0));
        flex.setCellData(b, 50, sectorList[row].draftTotal2H.toFixed(0));
        flex.setCellData(b, 51, sectorList[row].budBudget2H.toFixed(0));
        flex.setCellData(b, 52, sectorList[row].budVariance2H.toFixed(0));
        flex.setCellData(b, 53, sectorList[row].preBudget2H.toFixed(0));
        flex.setCellData(b, 54, sectorList[row].preVariance2H.toFixed(0));
        flex.setCellData(b, 55, sectorList[row].lastYearActual2H.toFixed(0));
        flex.setCellData(b, 56, sectorList[row].lastYearVariance2H.toFixed(0));
        flex.setCellData(b, 57, sectorList[row].draftBottomAnnual.toFixed(0));
        flex.setCellData(b, 58, sectorList[row].draftStretchAnnual.toFixed(0));
        flex.setCellData(b, 59, sectorList[row].draftTotalAnnual.toFixed(0));
        flex.setCellData(b, 60, sectorList[row].budBudgetAnnual.toFixed(0));
        flex.setCellData(b, 61, sectorList[row].budVarianceAnnual.toFixed(0));
        flex.setCellData(b, 62, sectorList[row].preBudgetAnnual.toFixed(0));
        flex.setCellData(b, 63, sectorList[row].preVarianceAnnual.toFixed(0));
        flex.setCellData(
          b,
          64,
          sectorList[row].lastYearActualAnnual.toFixed(0)
        );
        flex.setCellData(
          b,
          65,
          sectorList[row].lastYearVarianceAnnual.toFixed(0)
        );
      }
      b++;
    }

    // hide rows by default
    let initialHideRows = hideRowDefault(bcAnnualList, sectorList, flex);
    this.setState({
      hideRows: [...this.state.hideRows, ...initialHideRows],
    });

    this.setState({ isLoading: false });
  }

  handleChange(e) {
    const fieldValue = e.target.value;
    this.setState(
      {
        selectedArray: {
          salesPlanYear: fieldValue,
          salesPlanMonth: 3,
        },
        isLoading: true,
      },
      () => {
        this.getYearMonthName();
        this.getDashboardSheetData();
      }
    );
    showColumnDefault(this.state.flex, this.state.data);

    this.setState({
      active: "March",
      data: dashboardSheet.march,
      sheetName: "March",
    });
    hideColumnDefault(this.state.flex, dashboardSheet.march);
  }

  getYearMonthName() {
    this.state.fiscalList.forEach((fiscalList) => {
      if (this.state.selectedArray.salesPlanYear == fiscalList.fiscalYearId) {
        this.setState({
          salesPlanYearName: fiscalList.fiscalYear,
          salesPlanMonthName: moment()
            .month(this.state.selectedArray.salesPlanMonth - 1)
            .format("MMM"),
        });
      }
    });
  }

  render() {
    return (
      <div
        className="dashboard-sheet-container"
        style={{ maxHeight: "calc(100vh - 38px)", overflow: "hidden" }}
      >
        {this.state.isLoading && (
          <div className="loader">
            <Spinner color="white" />
          </div>
        )}

        <div className="row" style={{ paddingLeft: "12px" }}>
          <div className="col-auto" style={{ padding: "1.5px" }}>
            <select
              name="salesPlanYear"
              id="salesPlanYear"
              data-tooltip-content="Fiscal Year"
              className="form-select"
              onChange={(e) => {
                this.handleChange(e);
                this.setState({ isLoading: true });
              }}
              value={this.state.selectedArray.salesPlanYear}
            >
              <option key={0} value="" hidden>
                Fiscal Year
              </option>
              {this.state.fiscalList.map((item) => (
                <option key={item.fiscalYearId} value={item.fiscalYearId}>
                  {item.fiscalYear}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div
          className="row justify-content-start"
          style={{ marginLeft: "0px" }}
        >
          {this.state.buttons.map((button) => (
            <div
              key={button.id}
              className="col-1 actionTextBtn2-parent"
              style={{ padding: "0.5px" }}
            >
              <button
                type="button"
                className={`actionTextBtn2 ${
                  this.state.active === button.activeName &&
                  "dashboardActiveBtn"
                }`}
                onClick={() => {
                  showColumnDefault(this.state.flex, this.state.data);
                  let month = button.id;
                  this.setState(
                    {
                      selectedArray: {
                        salesPlanYear: this.state.selectedArray.salesPlanYear,
                        salesPlanMonth: month,
                      },
                      isLoading: true,
                    },
                    () => {
                      this.getYearMonthName();
                      this.getDashboardSheetData();
                    }
                  );
                  this.setState({
                    active: button.activeName,
                    data: button.dataName,
                    sheetName: button.activeName,
                  });
                  hideColumnDefault(this.state.flex, button.dataName);
                }}
              >
                {button.name}
              </button>
            </div>
          ))}
        </div>

        <div className="row" style={{ marginTop: "10px", marginLeft: "0px" }}>
          <div className="col-1" style={{ padding: "0.5px" }}>
            <button
              type="button"
              value="Show"
              onClick={() => this.showColumns(this.state.flex)}
              style={{
                width: "100%",
                height: "fit-content",
                border: "0",
                padding: "3px",
                borderRadius: "5px",
                backgroundColor: "#E49B0F",
                color: "white",
              }}
            >
              Show Columns
            </button>
          </div>
          <div className="col-1" style={{ padding: "0.5px" }}>
            <button
              type="button"
              value="Hide"
              onClick={() => this.hideColumns(this.state.flex)}
              style={{
                width: "100%",
                height: "fit-content",
                border: "0",
                padding: "3px",
                borderRadius: "5px",
                backgroundColor: "#E49B0F",
                color: "white",
              }}
            >
              Hide Columns
            </button>
          </div>
          {/* <div className="col-auto" style={{ padding: "0.5px" }}>
            <button
              type="button"
              value="Hide"
              onClick={() => this.hideColumns(this.state.flex)}
              style={{
                width: "100%",
                height: "fit-content",
                border: "0",
                padding: "3px",
                borderRadius: "5px",
                backgroundColor: "#E49B0F",
                color: "white",
              }}
            >
              {this.state.isLoading ? (
                <p
                  style={{
                    margin: "0",
                    paddingLeft: "7px",
                    paddingRight: "7px",
                  }}
                >
                  {this.state.salesPlanYearName} {this.state.salesPlanMonthName}{" "}
                  {" -> "} Data Reloading
                </p>
              ) : (
                <p
                  style={{
                    margin: "0",
                    paddingLeft: "7px",
                    paddingRight: "7px",
                  }}
                >
                  {this.state.salesPlanYearName} {this.state.salesPlanMonthName}{" "}
                  {" -> "} Data Reloaded
                </p>
              )}{" "}
            </button>
          </div> */}
          <div
            className="col"
            style={{
              minWidth: "fit-content",
              padding: "0",
              textAlign: "right",
            }}
          >
            <b>Unit: M JPY</b>
          </div>
        </div>

        <wjGridSheet.FlexSheet
          initialized={this.initializeFlexSheet.bind(this)}
          itemsSourceChanged={this.initializeFlexSheet.bind(this)}
          isReadOnly={true}
          style={{
            marginLeft: "0px",
            maxWidth: "100%",
          }}
          id="dashboardSheet"
        >
          <wjGridSheet.Sheet
            name={this.state.sheetName}
            columnCount={66}
            rowCount={163}
          ></wjGridSheet.Sheet>
        </wjGridSheet.FlexSheet>
      </div>
    );
  }
  initializeFlexSheet(flex) {
    flex.deferUpdate(() => {
      this._generateBasicOperatorsSheet(flex);
      let colCnt = flex.rowHeaders.columns.length;
      flex.rowHeaders.columns.removeAt(colCnt - 1);

      let rowCnt = flex.columnHeaders.rows.length;
      flex.columnHeaders.rows.removeAt(rowCnt - 1);
    });
  }

  _generateBasicOperatorsSheet(flexSheet) {
    flexSheet.setCellData(0, 0, " ");

    flexSheet.setCellData(0, 3, "1Q (4月-6月)");
    flexSheet.setCellData(1, 3, "Draft Budget");
    flexSheet.setCellData(1, 6, "vs Budget");
    flexSheet.setCellData(1, 10, "vs Last Year");
    flexSheet.setCellData(2, 3, "積上");
    flexSheet.setCellData(2, 4, "ストレッチ");
    flexSheet.setCellData(2, 5, "予算案");
    flexSheet.setCellData(2, 6, "予算");
    flexSheet.setCellData(2, 7, "予算差");
    flexSheet.setCellData(2, 8, "前回");
    flexSheet.setCellData(2, 9, "前回差");
    flexSheet.setCellData(2, 10, "実績");
    flexSheet.setCellData(2, 11, "前年差");

    flexSheet.setCellData(0, 12, "2Q (7月-9月)");
    flexSheet.setCellData(1, 12, "Draft Budget");
    flexSheet.setCellData(1, 15, "vs Budget");
    flexSheet.setCellData(1, 19, "vs Last Year");
    flexSheet.setCellData(2, 12, "積上");
    flexSheet.setCellData(2, 13, "ストレッチ");
    flexSheet.setCellData(2, 14, "予算案");
    flexSheet.setCellData(2, 15, "予算");
    flexSheet.setCellData(2, 16, "予算差");
    flexSheet.setCellData(2, 17, "前回");
    flexSheet.setCellData(2, 18, "前回差");
    flexSheet.setCellData(2, 19, "実績");
    flexSheet.setCellData(2, 20, "前年差");

    flexSheet.setCellData(0, 21, "上期 (4月-9月)");
    flexSheet.setCellData(1, 21, "Draft Budget");
    flexSheet.setCellData(1, 24, "vs Budget");
    flexSheet.setCellData(1, 28, "vs Last Year");
    flexSheet.setCellData(2, 21, "積上");
    flexSheet.setCellData(2, 22, "ストレッチ");
    flexSheet.setCellData(2, 23, "予算案");
    flexSheet.setCellData(2, 24, "予算");
    flexSheet.setCellData(2, 25, "予算差");
    flexSheet.setCellData(2, 26, "前回");
    flexSheet.setCellData(2, 27, "前回差");
    flexSheet.setCellData(2, 28, "実績");
    flexSheet.setCellData(2, 29, "前年差");

    flexSheet.setCellData(0, 30, "3Q (10月-12月)");
    flexSheet.setCellData(1, 30, "Draft Budget");
    flexSheet.setCellData(1, 33, "vs Budget");
    flexSheet.setCellData(1, 37, "vs Last Year");
    flexSheet.setCellData(2, 30, "積上");
    flexSheet.setCellData(2, 31, "ストレッチ");
    flexSheet.setCellData(2, 32, "予算案");
    flexSheet.setCellData(2, 33, "予算");
    flexSheet.setCellData(2, 34, "予算差");
    flexSheet.setCellData(2, 35, "前回");
    flexSheet.setCellData(2, 36, "前回差");
    flexSheet.setCellData(2, 37, "実績");
    flexSheet.setCellData(2, 38, "前年差");

    flexSheet.setCellData(0, 39, "4Q (01月-03月)");
    flexSheet.setCellData(1, 39, "Draft Budget");
    flexSheet.setCellData(1, 42, "vs Budget");
    flexSheet.setCellData(1, 46, "vs Last Year");
    flexSheet.setCellData(2, 39, "積上");
    flexSheet.setCellData(2, 40, "ストレッチ");
    flexSheet.setCellData(2, 41, "予算案");
    flexSheet.setCellData(2, 42, "予算");
    flexSheet.setCellData(2, 43, "予算差");
    flexSheet.setCellData(2, 44, "前回");
    flexSheet.setCellData(2, 45, "前回差");
    flexSheet.setCellData(2, 46, "実績");
    flexSheet.setCellData(2, 47, "前年差");

    flexSheet.setCellData(0, 48, "下期 (10月-3月)");
    flexSheet.setCellData(1, 48, "Draft Budget");
    flexSheet.setCellData(1, 51, "vs Budget");
    flexSheet.setCellData(1, 55, "vs Last Year");
    flexSheet.setCellData(2, 48, "積上");
    flexSheet.setCellData(2, 49, "ストレッチ");
    flexSheet.setCellData(2, 50, "予算案");
    flexSheet.setCellData(2, 51, "予算");
    flexSheet.setCellData(2, 52, "予算差");
    flexSheet.setCellData(2, 53, "前回");
    flexSheet.setCellData(2, 54, "前回差");
    flexSheet.setCellData(2, 55, "実績");
    flexSheet.setCellData(2, 56, "前年差");

    flexSheet.setCellData(0, 57, "年間 (4月ー3月)");
    flexSheet.setCellData(1, 57, "Draft Budget");
    flexSheet.setCellData(1, 60, "vs Budget");
    flexSheet.setCellData(1, 64, "vs Last Year");
    flexSheet.setCellData(2, 57, "積上");
    flexSheet.setCellData(2, 58, "ストレッチ");
    flexSheet.setCellData(2, 59, "予算案");
    flexSheet.setCellData(2, 60, "予算");
    flexSheet.setCellData(2, 61, "予算差");
    flexSheet.setCellData(2, 62, "前回");
    flexSheet.setCellData(2, 63, "前回差");
    flexSheet.setCellData(2, 64, "実績");
    flexSheet.setCellData(2, 65, "前年差");

    this.setBCContent(flexSheet, 3);

    flexSheet.applyCellsStyle(
      {
        background: "#00ff00",
      },
      [new wjcGrid.CellRange(0, 3, 0, 11), new wjcGrid.CellRange(0, 30, 0, 38)]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#ff99cc",
      },
      [new wjcGrid.CellRange(0, 12, 0, 20), new wjcGrid.CellRange(0, 39, 0, 47)]
    );

    flexSheet.applyCellsStyle(
      {
        color: "white",
        background: "#0000ff",
      },
      [new wjcGrid.CellRange(0, 21, 0, 29), new wjcGrid.CellRange(0, 48, 0, 56)]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#ffc000",
      },
      [new wjcGrid.CellRange(0, 57, 0, 65)]
    );

    flexSheet.applyCellsStyle({ minWidth: "fit-content" }, [
      new wjcGrid.CellRange(1, 6),
    ]);

    flexSheet.mergeRange(new wjcGrid.CellRange(0, 0, 2, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 3, 0, 11));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 12, 0, 20));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 21, 0, 29));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 30, 0, 38));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 39, 0, 47));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 48, 0, 56));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 57, 0, 65));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 3, 1, 5));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 6, 1, 7));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 8, 1, 9));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 10, 1, 11));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 12, 1, 14));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 15, 1, 16));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 17, 1, 18));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 19, 1, 20));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 21, 1, 23));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 24, 1, 25));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 26, 1, 27));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 28, 1, 29));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 30, 1, 32));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 33, 1, 34));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 35, 1, 36));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 37, 1, 38));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 39, 1, 41));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 42, 1, 43));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 44, 1, 45));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 46, 1, 47));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 48, 1, 50));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 51, 1, 52));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 53, 1, 54));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 55, 1, 56));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 57, 1, 59));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 60, 1, 61));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 62, 1, 63));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 64, 1, 65));

    this.setState({
      flex: flexSheet,
    });

    for (let col = 0; col < 66; col++) {
      flexSheet.columns[col].width = "*";
      flexSheet.columns[col].minWidth = 62.2;
    }
    for (let row = 0; row < 163; row++) {
      flexSheet.rows[row].height = 20;
    }
  }
  setBCContent(flexSheet, r) {
    flexSheet.mergeRange(new wjcGrid.CellRange(r, 0, r + 22, 0));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 2, 1, r + 2, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 5, 1, r + 5, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 8, 1, r + 8, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 9, 1, r + 9, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 10, 1, r + 10, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 13, 1, r + 13, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 14, 1, r + 14, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 15, 1, r + 15, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 16, 1, r + 16, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 17, 1, r + 17, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 18, 1, r + 18, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 19, 1, r + 19, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 20, 1, r + 20, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 21, 1, r + 21, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 22, 1, r + 22, 2));

    flexSheet.setCellData(r, 2, "NESIC Tokyo");
    flexSheet.setCellData(r + 1, 2, "Other");
    flexSheet.setCellData(r + 2, 1, "受注");
    flexSheet.setCellData(r + 3, 2, "NESIC Tokyo");
    flexSheet.setCellData(r + 4, 2, "Other");
    flexSheet.setCellData(r + 5, 1, "売上");
    flexSheet.setCellData(r + 6, 2, "NESIC Tokyo");
    flexSheet.setCellData(r + 7, 2, "Other");
    flexSheet.setCellData(r + 8, 1, "原価");
    flexSheet.setCellData(r + 9, 1, "売上総利益");
    flexSheet.setCellData(r + 10, 1, "原価率");
    flexSheet.setCellData(r + 11, 2, "Sales Expenses");
    flexSheet.setCellData(r + 12, 2, "GA Expenses");
    flexSheet.setCellData(r + 13, 1, "販管費");
    flexSheet.setCellData(r + 14, 1, "営業利益");
    flexSheet.setCellData(r + 15, 1, "営業利益率");
    flexSheet.setCellData(r + 16, 1, "営業外収益");
    flexSheet.setCellData(r + 17, 1, "営業外費用");
    flexSheet.setCellData(r + 18, 1, "経常利益");
    flexSheet.setCellData(r + 19, 1, "経常利益率");
    flexSheet.setCellData(r + 20, 1, "Purchase to NESIC-G");
    flexSheet.setCellData(r + 21, 1, "Consolidated OR");
    flexSheet.setCellData(r + 22, 1, "Consolidated Sales");

    flexSheet.applyCellsStyle(
      {
        fontSize: "12px",
        padding: "0px",
        textAlign: "center",
      },
      [
        new wjcGrid.CellRange(0, 0, 2, 65),
        new wjcGrid.CellRange(r, 0, r + 22, 2),
      ]
    );

    flexSheet.applyCellsStyle(
      {
        fontSize: "11px",
        textAlign: "right",
        padding: "0px",
        paddingRight: "3px",
        paddingTop: "2px",
      },
      [new wjcGrid.CellRange(r, 3, r + 22, 65)]
    );

    flexSheet.applyCellsStyle(
      {
        textAlign: "center",
      },
      [new wjcGrid.CellRange(0, 0, 200, 0)]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#99ff99",
      },
      [
        new wjcGrid.CellRange(1, 3, 1, 5),
        new wjcGrid.CellRange(1, 12, 1, 14),
        new wjcGrid.CellRange(1, 21, 1, 23),
        new wjcGrid.CellRange(1, 30, 1, 32),
        new wjcGrid.CellRange(1, 39, 1, 41),
        new wjcGrid.CellRange(1, 48, 1, 50),
        new wjcGrid.CellRange(1, 57, 1, 59),
        new wjcGrid.CellRange(2, 5, r + 22, 5),
        new wjcGrid.CellRange(2, 14, r + 22, 14),
        new wjcGrid.CellRange(2, 23, r + 22, 23),
        new wjcGrid.CellRange(2, 32, r + 22, 32),
        new wjcGrid.CellRange(2, 41, r + 22, 41),
        new wjcGrid.CellRange(2, 50, r + 22, 50),
        new wjcGrid.CellRange(2, 59, r + 22, 59),
      ]
    );
  }

  showColumns(flex) {
    var selectionStart = flex.selection.col2;
    if (selectionStart === 0) {
      this.state.hideColumns.map(
        (column) => (flex.columns[column.index].visible = true)
      );
      this.setState({ hideColumns: [] });
      showColumnDefault(flex, this.state.data);
    } else {
      var selectionEnd = flex.selection.col;
      for (let i = selectionStart; i <= selectionEnd; i++) {
        flex.columns[i].visible = true;
      }
    }
  }

  // hide selected column by button
  hideColumns(flex) {
    var selectionStart = flex.selection.col2;
    var selectionEnd = flex.selection.col;
    var hideColumns = [];
    for (let i = selectionStart; i <= selectionEnd; i++) {
      flex.columns[i].visible = false;
      hideColumns = [...hideColumns, flex.columns[i]];
    }
    this.setState({ hideColumns: [...this.state.hideColumns, ...hideColumns] });
  }
}
export default DashboardSheet;

DashboardSheet.propTypes = {
  collapsed: PropTypes.bool,
};
