import {
  Badge,
  Box,
  Button,
  Card,
  Group,
  Image,
  Text,
  Tooltip,
} from "@mantine/core";
import React from "react";
import PropTypes from "prop-types";

const WidgetContent = (props) => {
  const { widget, handleAddWidget } = props;

  // Editator Open
  const handleOpenEditator = (widget) => {
    handleAddWidget(widget);
  };

  return (
    <Card
      shadow="sm"
      padding="sm"
      radius="md"
      withBorder
      style={{ minWidth: "100%" }}
    >
      <Card.Section>
        <Image
          src={widget.img}
          maw={220}
          height={100}
          mx="auto"
          radius="md"
          alt={widget.title}
        />
      </Card.Section>

      <Group position="apart" my="xs" mih={70} spacing="xs">
        <Box w={"60%"}>
          <Tooltip label={widget.title} position="bottom" withArrow>
            <Text weight={500} truncate>
              {widget.title}
            </Text>
          </Tooltip>
        </Box>
        <Badge color="teal" variant="light">
          {widget.category}
        </Badge>
      </Group>
      <Box mih={40}>
        <Text c="dimmed" fz="xs" lineClamp={2}>
          {widget.description}
        </Text>
      </Box>

      <Button
        variant="light"
        color="blue"
        fullWidth
        mt="md"
        radius="md"
        onClick={() => handleOpenEditator(widget)}
      >
        Open
      </Button>
    </Card>
  );
};

export default WidgetContent;
WidgetContent.propTypes = {
  widget: PropTypes.any,
  handleAddWidget: PropTypes.func,
};
