// import React, { useEffect, useState } from "react";
// import * as wjChart from "@grapecity/wijmo.react.chart";
// import { getData } from "../../mocks/scatter-chart-mock";
// import PropTypes from "prop-types";

// export default function ScatterChart(props) {
//   const { datasources, width, height, chartInitalized } = props;

//   const [dataChart, setDataChart] = useState([]);
//   const [dataLabel, setDataLabel] = useState(datasources.configure.dataLabel);
//   const chartInitialized = (flex) => {
//     chartInitalized(flex);
//   };
//   console.log(datasources.data);
//   useEffect(() => {
//     setDataChart(
//       datasources.data.map((element) => {
//         return {
//           title: element.title,
//           data: getData(element.ctn, element.a, element.b),
//         };
//       })
//     );
//   }, []);
//   useEffect(() => {
//     console.log(datasources.configure.dataLabel);
//     setDataLabel(() => datasources.configure.dataLabel);
//   }, [datasources.configure.dataLabel]);
//   // console.log(dataChart);

//   return (
//     <div>
//       <wjChart.FlexChart
//         style={{ width: width, height: height }}
//         chartType="Scatter"
//         header={datasources.header}
//         bindingX="x"
//         initialized={chartInitialized}
//         legendToggle={datasources.configure.legendToggle}
//       >
//         <wjChart.FlexChartLegend
//           position={datasources.configure.legendPosition}
//         />

//         {dataLabel === "Top" ? (
//           <wjChart.FlexChartDataLabel position="Top" content="{y}" />
//         ) : null}
//         <wjChart.FlexChartAxis
//           wjProperty="axisY"
//           axisLine={true}
//         ></wjChart.FlexChartAxis>
//         {dataChart.map((d) => (
//           <wjChart.FlexChartSeries
//             key={`${d},crypto.getRandomValues()`}
//             name={d.title}
//             itemsSource={d.data}
//             bindingX="x"
//             binding="y"
//           ></wjChart.FlexChartSeries>
//         ))}
//       </wjChart.FlexChart>
//     </div>
//   );
// }

// ScatterChart.propTypes = {
//   datasources: PropTypes.object,
//   width: PropTypes.string,
//   height: PropTypes.number,
//   chartInitalized: PropTypes.func,
// };

import React from "react";

const ScatterChart = () => {
  return <div>ScatterChart</div>;
};

export default ScatterChart;
