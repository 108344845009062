import * as wjcGrid from "@grapecity/wijmo.grid";

export function hideRowDefault(bcAnnualList, sectorList, flex) {
  const rowNums = [26, 28, 49, 51, 72, 74, 95, 97, 118, 120, 141, 143];
  let curRow = 0;
  flex.formatItem.addHandler(function (s, e) {
    for (let i = 0; i < rowNums.length; i++) {
      curRow = rowNums[i];
      if (e.row === curRow) {
        e.cell.classList.add("sectorStart");
      }
    }
  });

  // add cell color when value is minus
  for (let col = 0; col < 67; col++) {
    if (flex.getCellData(2, col) === "ストレッチ") {
      flex.applyCellsStyle(
        {
          fontWeight: "bold",
        },
        [new wjcGrid.CellRange(2, col, 2, col)]
      );
    }
    if (
      flex.getCellData(2, col) === "前回差" ||
      flex.getCellData(2, col) === "予算差"
    ) {
      for (let row = 3; row < 160; row++) {
        if (
          flex.getCellData(row, col) < 0 ||
          (isNaN(flex.getCellData(row, col)) &&
            parseFloat(flex.getCellData(row, col).split("%")[0]) < 0)
        ) {
          flex.applyCellsStyle(
            {
              color: "red",
            },
            [new wjcGrid.CellRange(row, col, row, col)]
          );
        } else {
          flex.applyCellsStyle(
            {
              color: "blue",
            },
            [
              new wjcGrid.CellRange(row, col, row, col),
              new wjcGrid.CellRange(row, col, row, col),
            ]
          );
        }
      }
    }

    if (flex.getCellData(2, col) === "積上") {
      for (let row = 2; row < 160; row++) {
        flex.applyCellsStyle(
          {
            backgroundColor: "#daeef3",
          },
          [new wjcGrid.CellRange(row, col, row, col)]
        );
      }
    }

    if (flex.getCellData(2, col) === "ストレッチ") {
      for (let row = 2; row < 160; row++) {
        flex.applyCellsStyle(
          {
            backgroundColor: "#dbdbdb",
          },
          [new wjcGrid.CellRange(row, col, row, col)]
        );
      }
    }
  }

  var initialHideRows = [];
  for (let row = 0; row < bcAnnualList.length; row++) {
    let row1 = flex.getCellData(row, 1);
    if (flex.getCellData(row, 3) === "Draft Budget") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (flex.getCellData(row, 2) === "NESIC Tokyo") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (flex.getCellData(row, 2) === "Other") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (flex.getCellData(row, 1) === "原価") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (flex.getCellData(row, 2) === "Sales Expenses") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (flex.getCellData(row, 2) === "GA Expenses") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (row1 === "営業利益率") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (row1 === "営業外収益") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }
    if (row1 === "営業外費用") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }
    if (row1 === "経常利益") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }
    if (row1 === "経常利益率") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }
    if (row1 === "Purchase to NESIC-G") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }
    if (row1 === "Consolidated OR") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }
    if (row1 === "Consolidated Sales") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }
  }

  for (let row = 23; row < sectorList.length + 30; row++) {
    var row0 = flex.getCellData(row, 0);
    let row1 = flex.getCellData(row, 1);
    let row2 = flex.getCellData(row, 2);

    if (flex.getCellData(row, 0) === "SGA") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (row0 !== "JP Business") {
      if (flex.getCellData(row, 2) === "NESIC Tokyo") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (flex.getCellData(row, 2) === "Other") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (flex.getCellData(row, 1) === "原価") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row2 === "Sales Expenses") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row2 === "GA Expenses") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "営業外収益") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "営業外費用") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "経常利益") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "経常利益率") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "Purchase to NESIC-G") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "Consolidated OR") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "Consolidated Sales") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "販管費") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "営業利益") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "営業利益率") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }
    } else {
      if (flex.getCellData(row, 2) === "NESIC Tokyo") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (flex.getCellData(row, 2) === "Other") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (flex.getCellData(row, 1) === "原価") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row2 === "Sales Expenses") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row2 === "GA Expenses") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "営業外収益") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "営業外費用") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "経常利益") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "経常利益率") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "Purchase to NESIC-G") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "Consolidated OR") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "Consolidated Sales") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "営業利益率") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }
    }
  }

  //add icon instead of minus
  for (let col = 4; col < 66; col++) {
    for (let row = 3; row < bcAnnualList.length + 3; row++) {
      let value = flex.getCellData(row, col);
      let value1;
      if (value != null) {
        value1 = parseFloat(value.split("%")[0]);
      }
      if (isNaN(value) && value1 < 0) {
        flex.setCellData(row, col, "▲" + value1 * -1 + "%");
      } else if (value < 0 || value === `-0`) {
        flex.setCellData(row, col, "▲" + value * -1);
      }
    }
  }
  for (let col = 4; col < 66; col++) {
    for (let row = 26; row < sectorList.length + 26; row++) {
      let value = flex.getCellData(row, col);
      let value1;
      if (value !== null) {
        value1 = parseFloat(value.split("%")[0]);
      }
      if (isNaN(value) && value1 < 0) {
        flex.setCellData(row, col, "▲" + value1 * -1 + "%");
      } else if (value < 0 || value === `-0`) {
        flex.setCellData(row, col, "▲" + value * -1);
      }
    }
  }

  return initialHideRows;
}

export function hideColumnDefault(flex, data) {
  if (flex)
    data.map((data) => {
      data.toHideColumn.map((column) => {
        flex.columns[column].visible = false;
        return 0;
      });
      return 0;
    });
}

export function showColumnDefault(flex, data) {
  data.map((data) => {
    data.toHideColumn.map((column) => (flex.columns[column].visible = true));
    return 0;
  });
}
