import { ActionIcon, Flex, Group, Select, Text } from "@mantine/core";
import { IconPlus, IconSquareRoundedX } from "@tabler/icons-react";
import React from "react";
import {
  SELECTITEMCOMPONENT,
  SELECTITEMLIST,
} from "../../../../utilities/utilities";
import PropTypes from "prop-types";

const GroupByList = (props) => {
  const {
    index,
    group,
    filteredColumnList,
    handleGroupChange,
    handleRemoveGroup,
  } = props;
  return (
    <Group mt={"xs"}>
      <Select
        placeholder="Choose"
        searchable
        itemComponent={SELECTITEMCOMPONENT}
        maxDropdownHeight={200}
        data={filteredColumnList}
        filter={(value, item) =>
          item.label.toLowerCase().includes(value.toLowerCase().trim())
        }
        value={group}
        onChange={(value) => handleGroupChange(index, value)}
      />
      <ActionIcon
        variant="filled"
        color="red"
        onClick={() => handleRemoveGroup(index, "groups", index)}
      >
        <IconSquareRoundedX size="1rem" />
      </ActionIcon>
    </Group>
  );
};

const GroupBy = (props) => {
  const {
    groups,
    tableName,
    tableList,
    handleGroupChange,
    handleRemoveGroup,
    handleAddGroup,
  } = props;

  let filteredColumnList = [];

  if (tableName) {
    filteredColumnList = SELECTITEMLIST(tableList, tableName);
  }

  return (
    <Flex
      mih={50}
      p={5}
      bg="#eee"
      justify="flex-start"
      align="flex-start"
      direction="column"
      wrap="wrap"
    >
      <Text size="sm" weight={500}>
        Group by column
      </Text>
      <Group>
        {groups?.map((group, i) => (
          <GroupByList
            key={i}
            group={group}
            index={i}
            tableName={tableName}
            filteredColumnList={filteredColumnList}
            handleRemoveGroup={handleRemoveGroup}
            handleGroupChange={handleGroupChange}
          />
        ))}
        <ActionIcon
          variant="filled"
          color="indigo"
          onClick={handleAddGroup}
          sx={{ marginTop: groups.length > 0 ? "10px" : 0 }}
        >
          <IconPlus size="1rem" />
        </ActionIcon>
      </Group>
    </Flex>
  );
};

export default GroupBy;

GroupBy.propTypes = {
  groups: PropTypes.any,
  tableName: PropTypes.any,
  tableList: PropTypes.any,
  handleGroupChange: PropTypes.any,
  handleRemoveGroup: PropTypes.any,
  handleAddGroup: PropTypes.any,
};
GroupByList.propTypes = {
  index: PropTypes.any,
  group: PropTypes.any,
  filteredColumnList: PropTypes.any,
  handleGroupChange: PropTypes.any,
  handleRemoveGroup: PropTypes.any,
};
