import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import {
  // BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./assets/css/commonMenu.css";
import SideBar from "./layouts/sideBar";
import TopBar from "./layouts/topBar";
import SalesAndGp from "./pages/Analysis/salesAndGp";
import BusinessSector from "./pages/Configuration/businessSector";
import Company from "./pages/Configuration/company";
import ExchangeRate from "./pages/Configuration/exchangeRate";
import FiscalYear from "./pages/Configuration/fiscalYear";
import DashboardFinal from "./pages/Dashboard/dashboard-final/dashboard";
import Login from "./pages/Login/login";
import Register from "./pages/Login/register";
import BCAnnualMMK from "./pages/Report/bcAnnualMmk";
import BCAnnualYEN from "./pages/Report/bcAnnualYen";
import BCQuarter1MMK from "./pages/Report/bcQuarter1Mmk";
import BCQuarter1YEN from "./pages/Report/bcQuarter1Yen";
import BCQuarter2MMK from "./pages/Report/bcQuarter2Mmk";
import BCQuarter2YEN from "./pages/Report/bcQuarter2Yen";
import BCQuarter3MMK from "./pages/Report/bcQuarter3Mmk";
import BCQuarter3YEN from "./pages/Report/bcQuarter3Yen";
import BCQuarter4MMK from "./pages/Report/bcQuarter4Mmk";
import BCQuarter4YEN from "./pages/Report/bcQuarter4Yen";
import BudgetImport from "./pages/Report/budgetImport";
import ReportFilter from "./pages/Report/reportFilter";
import ReportCostImport from "./pages/Report/costImport";
import SGAInDirectCosts from "./pages/Report/sgaInDirectCosts";
import Import from "./pages/SalesPlan/import";
import SalesPlan from "./pages/SalesPlan/salesPlan";
import ProtectedRoute from "./routes/protectedRoute";
import ForgetPassword from "./pages/forgetPassword/forgetPassword";
import SessionManagement from "./sessionManagement/sessionManagement";

import { AiOutlineNotification } from "react-icons/ai";
import { BiAnalyse } from "react-icons/bi";
import { IoBarChartOutline, IoSettingsOutline } from "react-icons/io5";
import { RiDashboardLine, RiDatabaseLine } from "react-icons/ri";
import { AiOutlineDollarCircle } from "react-icons/ai";
import BudgetStatusSheet from "./pages/Dashboard/budgetStatusSheet";
import DashboardChart from "./pages/Dashboard/dashboard-chart";
import DashboardSheet from "./pages/Dashboard/dashboardSheet";
import DynamicDashboard from "./pages/Dashboard/dynamic-dashboard/dynamicDashboard";
import PivotGrid from "./pages/Dashboard/pivotGrid/pivotGrid";
import ResetPassword from "./pages/forgetPassword/resetPassword";
import Costs from "./pages/costs/costs";
import SGAInDirectCostsRegister from "./pages/Report/sgaRegister";
import FinancialDataImport from "./pages/Dashboard/dashboard-final/financial-data-summary/financialDataImport";
import ILiveBoard from "./pages/Dashboard/iliveboard/view-canvas";
import DashboardList from "./pages/Dashboard/iliveboard/dashboard_list";
import EditILiveBoard from "./pages/Dashboard/iliveboard/edit-canvas";
import { useAtom } from "jotai";
import { currentlocation } from "./state";
import Main from "./pages/query-builder/components/pages/main/main-page";
import QueryDashboardPage from "./pages/query-builder/components/pages/query-dashboard/query-dashboard-page";
import DashboardPage from "./pages/query-builder/components/pages/dashboard/iliveboard";

function App() {
  const name = localStorage.getItem("userName")
    ? localStorage.getItem("userName")
    : sessionStorage.getItem("userName");
  const isLoggedIn = localStorage.getItem("isLoggedIn")
    ? localStorage.getItem("isLoggedIn")
    : sessionStorage.getItem("isLoggedIn");
  const [innerWidth, setInnerWidth] = React.useState(window.innerWidth);
  const [collapsed, setCollapsed] = React.useState(
    isLoggedIn && innerWidth > 768 ? false : true
  );
  // const [curLocation, setCurLocation] = React.useState(
  //   isLoggedIn ? getCurLocation() : "Login"
  // );
  const [selectedSummary, setSelectedSummary] = React.useState("Summary");
  const [remember, setRemember] = React.useState(false);
  const [location, setLocation] = useAtom(currentlocation);
  const curLocation = useLocation();
  const styles = {
    contentDiv: {},
    contentMargin:
      innerWidth > 768
        ? !collapsed
          ? {
              width: "calc(100% - 170px)",
              height: "100%",
              minHeight: "100vh",
              transform: "translateX(170px)",
              transition: "300ms",
            }
          : {
              width: "100%",
              height: "100%",
              minHeight: "100vh",
              transform: "translate(0px)",
              transition: "300ms",
            }
        : {
            width: "100%",
            height: "100%",
            minHeight: "100vh",
            transform: "translate(0px)",
            transition: "300ms",
          },
    icons: {
      color: "black",
      width: "20px",
      height: "20px",
      marginLeft: "15px",
      marginRight: "15px",
    },
    menu: {
      display: "flex",
      positon: "relative",
      minWidth: "150px",
      height: "36px",
      fontSize: "14px",
      alignItems: "center",
      cursor: "pointer",
      transition: "300ms",
    },
    expendIcon: {
      position: "absolute",
      right: "15px",
      fontWeight: "bold",
    },
  };
  function changeLocation(path) {
    // setCurLocation(path);
    setLocation(path);
  }
  const menuList = [
    {
      id: "1",
      name: "Dashboard",
      hasSub: false,
      icon: <RiDashboardLine style={styles.icons} />,
      link: "/dashboard",
      location: "Dashboard",
    },
    {
      id: "2",
      name: "Sales Plan",
      hasSub: false,
      icon: <RiDatabaseLine style={styles.icons} />,
      link: "/salesplan",
      location: "Sales Plan",
    },
    {
      id: "3",
      name: "Analysis",
      hasSub: true,
      icon: <IoBarChartOutline style={styles.icons} />,
      link: "/analysis",
      location: "Analysis",
      subList: [
        {
          id: "sub-1",
          name: "Summary",
          hasSub: false,
          link: "/analysis/summary",
          location: "Analysis | Summary",
        },
        {
          id: "sub-2",
          name: "Budget Status",
          hasSub: false,
          link: "/analysis/budgetstatus",
          location: "Analysis | Budget Status",
        },
        {
          id: "sub-3",
          name: "Report",
          hasSub: false,
          link: "/analysis/report",
          location: "Analysis | Report",
        },
      ],
    },
    {
      id: "4",
      name: "Reports",
      hasSub: true,
      icon: <AiOutlineNotification style={styles.icons} />,
      location: "Report |",
      subList: [
        {
          id: "sub-1",
          name: "Budget Import",
          hasSub: false,
          link: "/report/import/budget",
          location: "Report | Budget Import",
        },
        {
          id: "sub-2",
          name: "BC Annual",
          hasSub: true,
          location: "Report | BC Annual |",
          subList: [
            {
              id: "sub-sub-1",
              name: "Japan YEN",
              hasSub: false,
              link: "/report/bcannual/yen",
              location: "Report | BC Annual | YEN",
            },
            {
              id: "sub-sub-2",
              name: "Myanmar MMK",
              hasSub: false,
              link: "/report/bcannual/mmk",
              location: "Report | BC Annual | MMK",
            },
          ],
        },
        {
          id: "sub-3",
          name: "BC Q1",
          hasSub: true,
          location: "Report | BC Q1 |",
          subList: [
            {
              id: "sub-sub-1",
              name: "Japan YEN",
              hasSub: false,
              link: "/report/bcq1/yen",
              location: "Report | BC Q1 | YEN",
            },
            {
              id: "sub-sub-2",
              name: "Myanmar MMK",
              hasSub: false,
              link: "/report/bcq1/mmk",
              location: "Report | BC Q1 | MMK",
            },
          ],
        },
        {
          id: "sub-4",
          name: "BC Q2",
          hasSub: true,
          location: "Report | BC Q2 |",
          subList: [
            {
              id: "sub-sub-1",
              name: "Japan YEN",
              hasSub: false,
              link: "/report/bcq2/yen",
              location: "Report | BC Q2 | YEN",
            },
            {
              id: "sub-sub-2",
              name: "Myanmar MMK",
              hasSub: false,
              link: "/report/bcq2/mmk",
              location: "Report | BC Q2 | MMK",
            },
          ],
        },
        {
          id: "sub-5",
          name: "BC Q3",
          hasSub: true,
          location: "Report | BC Q3 |",
          subList: [
            {
              id: "sub-sub-1",
              name: "Japan YEN",
              hasSub: false,
              link: "/report/bcq3/yen",
              location: "Report | BC Q3 | YEN",
            },
            {
              id: "sub-sub-2",
              name: "Myanmar MMK",
              hasSub: false,
              link: "/report/bcq3/mmk",
              location: "Report | BC Q3 | MMK",
            },
          ],
        },
        {
          id: "sub-6",
          name: "BC Q4",
          hasSub: true,
          location: "Report | BC Q4 |",
          subList: [
            {
              id: "sub-sub-1",
              name: "Japan YEN",
              hasSub: false,
              link: "/report/bcq4/yen",
              location: "Report | BC Q4 | YEN",
            },
            {
              id: "sub-sub-2",
              name: "Myanmar MMK",
              hasSub: false,
              link: "/report/bcq4/mmk",
              location: "Report | BC Q4 | MMK",
            },
          ],
        },
        {
          id: "sub-7",
          name: "Report Filter",
          hasSub: false,
          link: "/report/reportfilter",
          location: "Report | Report Filter",
        },
      ],
    },
    {
      id: "5",
      name: "SGA",
      hasSub: false,
      icon: <BiAnalyse style={styles.icons} />,
      link: "/sga",
      location: "SGA",
    },
    {
      id: "6",
      name: "Costs",
      hasSub: false,
      icon: <AiOutlineDollarCircle style={styles.icons} />,
      link: "/costs",
      location: "Costs",
    },
    {
      id: "7",
      name: "Configuration",
      hasSub: true,
      icon: <IoSettingsOutline style={styles.icons} />,
      location: "Configuration |",
      subList: [
        {
          id: "sub-1",
          name: "Company",
          hasSub: false,
          link: "/configuration/company",
          location: "Configuration | Company",
        },
        {
          id: "sub-2",
          name: "Business Sector",
          hasSub: false,
          link: "/configuration/business-sector",
          location: "Configuration | Business Sector",
        },
        {
          id: "sub-3",
          name: "Fiscal Year",
          hasSub: false,
          link: "/configuration/fiscal-year",
          location: "Configuration | Fiscal Year",
        },
        {
          id: "sub-4",
          name: "Exchange Rate",
          hasSub: false,
          link: "/configuration/exchange-rate",
          location: "Configuration | Exchange Rate",
        },
      ],
    },
  ];

  React.useEffect(() => {
    setLocation(getCurLocation());
  }, [curLocation]);

  function getCurLocation() {
    let cur = window.location.href.split("/").slice(3);
    let last_items = cur[cur.length - 1].split("?");
    let params = {};
    if (last_items.length > 1) {
      cur.pop();
      for (let i of last_items) {
        cur.push(i);
      }
      last_items = cur[cur.length - 1].split("&");
      cur.pop();
      for (let i of last_items) {
        params[i.split("=")[0]] = i.split("=")[1];
      }
    }
    let samelink = false;
    let value = "";
    let lists = [
      { id: 1, value: "Dashboard", link: ["dashboard"] },
      { id: 2, value: "Sales Plan", link: ["salesplan"] },
      {
        id: 3,
        value: "Report | Budget Import",
        link: ["report", "import", "budget"],
      },
      {
        id: 4,
        value: "Report | BC Annual | YEN",
        link: ["report", "bcannual", "yen"],
      },
      {
        id: 5,
        value: "Report | BC Annual | MMK",
        link: ["report", "bcannual", "mmk"],
      },
      { id: 6, value: "Report | BC Q1 | YEN", link: ["report", "bcq1", "yen"] },
      { id: 7, value: "Report | BC Q1 | MMK", link: ["report", "bcq1", "mmk"] },
      { id: 8, value: "Report | BC Q2 | YEN", link: ["report", "bcq2", "yen"] },
      { id: 9, value: "Report | BC Q2 | MMK", link: ["report", "bcq2", "mmk"] },
      {
        id: 10,
        value: "Report | BC Q3 | YEN",
        link: ["report", "bcq3", "yen"],
      },
      {
        id: 11,
        value: "Report | BC Q3 | MMK",
        link: ["report", "bcq3", "mmk"],
      },
      {
        id: 12,
        value: "Report | BC Q4 | Yen",
        link: ["report", "bcq4", "yen"],
      },
      {
        id: 13,
        value: "Report | BC Q4 | MMK",
        link: ["report", "bcq4", "mmk"],
      },
      {
        id: 14,
        value: "Report | Report Filter",
        link: ["report", "reportfilter"],
      },
      {
        id: 15,
        value: "SGA",
        link: ["sga"],
      },
      {
        id: 16,
        value: "Configuration | Company",
        link: ["configuration", "company"],
      },
      {
        id: 17,
        value: "Configuration | Business Sector",
        link: ["configuration", "business-sector"],
      },
      {
        id: 18,
        value: "Configuration | Fiscal Year",
        link: ["configuration", "fiscal-year"],
      },
      {
        id: 19,
        value: "Configuration | Exchange Rate",
        link: ["configuration", "exchange-rate"],
      },
      {
        id: 20,
        value: "Login",
        link: ["login"],
      },
      {
        id: 21,
        value: "Register",
        link: ["register"],
      },
      {
        id: 22,
        value: "Analysis | Budget Status",
        link: ["analysis", "budgetstatus"],
      },
      { id: 23, value: "Analysis | Summary", link: ["analysis", "summary"] },
      {
        id: 24,
        value: "Analysis | Report",
        link: ["analysis", "report"],
      },
      {
        id: 25,
        value: "Sales Plan | Import",
        link: ["salesplan", "import"],
      },
      {
        id: 26,
        value: "Costs | ReportCost Import",
        link: ["costs", "import", "reportcost"],
      },
      { id: 27, value: "Costs", link: ["costs"] },
      { id: 28, value: "SGA | Register", link: ["sga", "register"] },
      {
        id: 29,
        value: `Dashboard | Dashboard List | ${
          params.dashboard !== "null" ? "Edit" : "Create"
        }`,
        link: ["dashboard", "iliveboard", "editcanvas"],
      },
    ];
    for (let i = 0; i < lists.length; i++) {
      if (cur.length === lists[i].link.length)
        if (cur[0] === lists[i].link[0]) {
          if (cur.length > 1 && lists[i].link.length > 1) {
            for (let j = 0; j < lists[i].link.length; j++) {
              if (lists[i].link[j] === cur[j]) {
                if (j === lists[i].link.length - 1) {
                  samelink = true;
                  value = lists[i].value;
                }
              } else {
                break;
              }
            }
          } else {
            value = lists[i].value;
          }
          if (samelink) {
            break;
          }
        }
    }
    if (value === "" && isLoggedIn) {
      value = "Dashboard";
    }
    if (!isLoggedIn) {
      value = "Login";
    }
    return value;
  }

  React.useEffect(() => {
    setLocation(isLoggedIn ? getCurLocation() : "Login");
  }, []);

  React.useEffect(() => {
    const handleWindowResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [window.innerWidth]);

  return (
    <div>
      {/* <BrowserRouter> */}
      {isLoggedIn && (
        <TopBar
          userName={name}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          curLocation={location}
          changeLocation={changeLocation}
          remember={remember}
        />
      )}
      <div className="body">
        <div style={styles.contentDiv}>
          {isLoggedIn && (
            <SideBar
              collapsed={collapsed}
              changeLocation={changeLocation}
              curLocation={location}
              menuList={menuList}
              styles={styles}
              mainClass="side-bar"
            />
          )}
          <div style={styles.contentMargin} id="mainContent">
            <div
              className="row justify-content-center h-100"
              style={
                innerWidth > 768
                  ? {
                      padding: "38px 0% 0% 60px",
                      height: "auto",
                      minHeight: "100vh",
                    }
                  : {
                      padding: "38px 0 0 10px",
                      height: "auto",
                      minHeight: "100vh",
                    }
              }
            >
              <Routes>
                {/* <Route exact path="/login" component={() => isAuthenticated ? <Login /> : <Dashboard />} /> */}
                <Route
                  path="/"
                  element={<Navigate replace to="/dashboard" />}
                />
                <Route path="/register" element={<Register />} />
                <Route
                  path="/login"
                  element={
                    isLoggedIn ? (
                      <Navigate replace to="/dashboard" />
                    ) : (
                      <Login
                        setCollapsed={setCollapsed}
                        changeLocation={changeLocation}
                        remember={remember}
                        setRemember={setRemember}
                      />
                    )
                  }
                />
                <Route
                  path="/login/forget-password"
                  element={<ForgetPassword />}
                />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route exact path="/" element={<ProtectedRoute />}>
                  <Route
                    path="/dashboard"
                    element={
                      <DashboardFinal
                        changeLocation={changeLocation}
                        setSelectedSummary={setSelectedSummary}
                      />
                    }
                  />
                  {/* <Route path="/analysis/dashboard" element={<Dashboard />} /> */}
                  <Route
                    path="/salesplan"
                    element={<SalesPlan changeLocation={changeLocation} />}
                  />
                  <Route
                    path="/report/import/budget"
                    element={<BudgetImport />}
                  />
                  <Route
                    path="/costs/import/reportcost"
                    element={<ReportCostImport />}
                  />
                  <Route
                    path="/report/bcannual/mmk"
                    element={<BCAnnualMMK />}
                  />
                  <Route
                    path="/report/bcannual/yen"
                    element={<BCAnnualYEN />}
                  />
                  <Route path="/report/bcq1/mmk" element={<BCQuarter1MMK />} />
                  <Route path="/report/bcq1/yen" element={<BCQuarter1YEN />} />
                  <Route path="/report/bcq2/mmk" element={<BCQuarter2MMK />} />
                  <Route path="/report/bcq2/yen" element={<BCQuarter2YEN />} />
                  <Route path="/report/bcq3/mmk" element={<BCQuarter3MMK />} />
                  <Route path="/report/bcq3/yen" element={<BCQuarter3YEN />} />
                  <Route path="/report/bcq4/mmk" element={<BCQuarter4MMK />} />
                  <Route path="/report/bcq4/yen" element={<BCQuarter4YEN />} />
                  <Route
                    path="/report/reportfilter"
                    element={<ReportFilter />}
                  />
                  <Route path="/analysis/salesandgp" element={<SalesAndGp />} />
                  <Route path="/analysis/pobysectors" element={<></>} />
                  <Route path="/analysis/monthestimate" element={<></>} />
                  <Route path="/analysis/pobudgetestimate" element={<></>} />
                  <Route path="/pivotgrid" element={<PivotGrid />} />

                  <Route path="/analysis/salesbudgetestimate" element={<></>} />
                  <Route path="/analysis/sectorssummarize" element={<></>} />
                  <Route
                    path="/sga"
                    element={
                      <SGAInDirectCosts changeLocation={changeLocation} />
                    }
                  />
                  <Route path="/configuration/company" element={<Company />} />
                  <Route
                    path="/configuration/business-sector"
                    element={<BusinessSector />}
                  />
                  <Route
                    path="/configuration/fiscal-year"
                    element={<FiscalYear />}
                  />
                  <Route
                    path="/configuration/exchange-rate"
                    element={<ExchangeRate />}
                  />
                  <Route path="/salesplan/import" element={<Import />} />
                  <Route
                    path="/analysis/budgetstatus"
                    element={<BudgetStatusSheet />}
                  />
                  <Route
                    path="/analysis/summary"
                    element={
                      <DashboardChart
                        selectedSummary={selectedSummary}
                        setSelectedSummary={setSelectedSummary}
                      />
                    }
                  />
                  <Route
                    path="/analysis/report"
                    element={<DashboardSheet collapsed={collapsed} />}
                  />
                  <Route path="/test" element={<DynamicDashboard />} />
                  <Route
                    path="/costs"
                    element={<Costs changeLocation={changeLocation} />}
                  />
                  <Route
                    path="/sga/register"
                    element={<SGAInDirectCostsRegister />}
                  />
                  <Route
                    path="/dashboard/financialdata/import"
                    element={<FinancialDataImport />}
                  />
                  <Route
                    path="/dashboard/dynamicdashboard"
                    element={<DashboardList changeLocation={changeLocation} />}
                  />
                  <Route
                    path="/dashboard/dynamicdashboard/editcanvas"
                    element={<EditILiveBoard changeLocation={changeLocation} />}
                  />
                  <Route
                    path="/dashboard/dynamicdashboard/canvas"
                    element={<ILiveBoard changeLocation={changeLocation} />}
                  />
                  <Route path="/dashboard/query" element={<Main />} />
                  <Route
                    path="/dashboard/query/query-dashboard"
                    element={<QueryDashboardPage />}
                  />
                  <Route
                    path="/dashboard/query/dashboard-page"
                    element={<DashboardPage />}
                  />
                </Route>
              </Routes>
            </div>
          </div>
        </div>
        {/* <div className="main--body" id="mainbody" /> */}
      </div>
      {isLoggedIn && <SessionManagement remember={remember} />}
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;
