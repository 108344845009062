import React, { useEffect, useMemo, useState } from "react";

import { DataConveter } from "../../../utilities/utilities";

import "@grapecity/wijmo.chart.render";
import {
  FlexChart,
  FlexChartAxis,
  FlexChartDataLabel,
  FlexChartLegend,
  FlexChartSeries,
} from "@grapecity/wijmo.react.chart";
import { FlexChartAnimation } from "@grapecity/wijmo.react.chart.animation";
import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";

import DataService from "../../../service/data-service";
import { GET_DATA_BY_QUERY } from "../../../utilities/constants";
import { useDisclosure } from "@mantine/hooks";
import LoadingComponents from "../../common/loading";
import ErrorWidget from "../error/error-widget";
import PropTypes from "prop-types";

const BarChartLine = (props) => {
  const { i, datasources, width, height, chartInitalized } = props;
  // Provider Items
  const [datasource, setDatasource] = useState([]);
  const [isLoading, loadingHandlers] = useDisclosure(true);
  const [isError, setIsError] = useState(false);
  const [serverData, setServerData] = useState([]);

  const [palette] = useState([
    "rgba(70,107,176,1)",
    "rgba(200,180,34,1)",
    "rgba(162, 4, 4, 0.53)",
    "rgba(162, 4, 4, 0.53)",
  ]);

  const memoizedDatasources = useMemo(() => datasources, [datasources]);

  useEffect(() => {
    const path = GET_DATA_BY_QUERY;
    const requestObject = {
      query: i.query,
      table_name: i.tableName,
    };
    // SID002 Get Data by queries
    DataService(path, requestObject).then(({ data, error }) => {
      if (error) {
        setIsError(true);
        loadingHandlers.close();
      } else {
        setIsError(false);
        setServerData(DataConveter(data.metadata));
        setDatasource(datasources);
        loadingHandlers.close();
      }
    });
    return () => {
      setServerData([]);
      setDatasource([]);
      loadingHandlers.open();
    };
  }, [memoizedDatasources]);

  if (isLoading)
    return (
      <div className="container-fluid">
        <LoadingComponents />
      </div>
    );

  if (isError) {
    return <ErrorWidget />;
  }

  return (
    <div className="container-fluid">
      <FlexChart
        initialized={chartInitalized}
        header={datasource.header}
        bindingX={datasource.configure.bindingX}
        itemsSource={serverData}
        stacking={datasource.settings.stacked}
        palette={palette}
        isAnimated={true}
        legendToggle={datasource.settings.legendToggle}
        style={{ height: height, width: width }}
      >
        {datasource.configure.bindingY.map((item) => (
          <FlexChartSeries
            name={item}
            binding={item}
            key={`bar-chart-line-${item}`}
          />
        ))}
        <FlexChartSeries
          name={datasource.configure.bindingLine}
          binding={datasource.configure.bindingLine}
          chartType="LineSymbols"
          style={{ stroke: "red", strokeWidth: 2 }}
          symbolStyle={{ fill: "red", stroke: "red", strokeWidth: 1 }}
        >
          <FlexChartAxis
            title={datasource.settings.rightAxisTitle}
            wjProperty="axisY"
            position="Right"
            axisLine={true}
            min={0}
          />
        </FlexChartSeries>
        <FlexChartAxis
          title={datasource.settings.leftAxisTitle}
          wjProperty="axisY"
          axisLine={true}
          origin={-10}
        />
        <FlexChartLegend position={datasource.settings.legendPosition} />
        <FlexChartDataLabel
          position={datasource.settings.dataLabelPosition}
          content="{y}"
        />
        <FlexChartAnimation />
      </FlexChart>
    </div>
  );
};

export default BarChartLine;

BarChartLine.propTypes = {
  i: PropTypes.any,
  datasources: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  chartInitalized: PropTypes.any,
};
