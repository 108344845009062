import React, { useState } from "react";

import "bootstrap/dist/css/bootstrap.css";
import "../../assets/css/styles.css";

import { MdDeleteOutline } from "react-icons/md";
import {
  FISCAL_YEAR_LIST_URL,
  FISCAL_YEAR_UPDATE_URL,
  FISCAL_YEAR_DELETE_URL,
  FISCAL_YEAR_CREATE_URL,
  TOKEN,
} from "../../constants/api.path";
import ConfirmModal from "../../modalPopups/confirmModal";
import InfoModal from "../../modalPopups/infoModal";
import SuccessModal from "../../modalPopups/successModal";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BiPlusCircle } from "react-icons/bi";
import FiscalYearAddEditModal from "../../modalPopups/fiscalYearAddEditModal";
import PropTypes from "prop-types";
var input,
  startYear,
  endYear,
  startMonth,
  endMonth,
  startDate,
  endDate,
  yearName,
  editingId,
  message,
  result;

function DisplayFiscalYear(props) {
  return (
    <tr className={props.selectedId === props.id ? "bg-tr" : ""}>
      <td className="w-25 text-center">{props.yearName}</td>
      <td className="w-25 " align="right">
        {props.startDate}
      </td>
      <td className="w-25 " align="right">
        {props.endDate}
      </td>
      <td className="w-25 text-center">
        <button
          onClick={(e) => {
            props.delete(props.id, props.yearName, e);
            props.deleteValue(e);
          }}
          className="actionIcon delete"
        >
          <MdDeleteOutline />
        </button>
      </td>
    </tr>
  );
}

export default function FiscalYear() {
  const inputRef = React.useRef(null);
  const startRef = React.useRef(null);
  const endRef = React.useRef(null);
  const [title, setTitle] = React.useState("");
  const [showAddEditModal, setAddEditModal] = useState(false);

  const [editing, setEditing] = React.useState(false);

  const [selectedId, setSelectedId] = React.useState(undefined);
  const [deleteId, setDeleteId] = React.useState();

  const [fiscalYear, setFiscalYear] = React.useState([]);
  const [display, setDisplay] = React.useState({
    fiscalYear: "",
    fyStartDate: "",
    fyEndDate: "",
  });

  const [showConfirmModal, setConfirmModal] = useState(false);
  const [showInfoModal, setInfoModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [resConfirm, setResConfirm] = useState(false);
  const [resultMessage, setResultMessage] = useState();
  const [value, setValue] = useState();

  var updateMessage = "Are you sure to want to update?";
  var deleteMessage = "Are you sure to want to delete?";

  function getData() {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setFiscalYear(data.data.fiscalYearList);
        setDisplay({
          fiscalYear: "",
          fyStartDate: "",
          fyEndDate: "",
        });
        setEditing(false);
        setSelectedId(undefined);
      });
  }

  React.useEffect(() => {
    getData();
  }, []);

  function handleChange() {
    input = parseInt(inputRef.current.value.toUpperCase().split("FY")[1]);

    if (
      inputRef.current.value.length >= 2 &&
      inputRef.current.value.toUpperCase().split("FY")[0] !== ""
    ) {
      message = "Please enter fiscal year.";
    } else {
      message = "";
    }

    if (isNaN(input) || input < 0) {
      setDisplay((prevData) => {
        return {
          ...prevData,
          fiscalYear: inputRef.current.value,
        };
      });
      return;
    } else if (input < 9) {
      startYear = `200${input}`;
      endYear = `200${input + 1}`;
    } else if (input === 9) {
      startYear = `200${input}`;
      endYear = `20${input + 1}`;
    } else if (input > 9 && input < 99) {
      startYear = `20${input}`;
      endYear = `20${input + 1}`;
    } else if (input === 99) {
      startYear = `20${input}`;
      endYear = `2${input + 1}`;
    } else if (input > 99) {
      return;
    }

    if (input < 10) {
      yearName = `FY0${input}`;
    } else {
      yearName = `FY${input}`;
    }

    setDisplay({
      fiscalYear: yearName,
      fyStartDate: `${startYear}-04-01`,
      fyEndDate: `${endYear}-03-31`,
    });
  }

  function handleStartChange() {
    startYear = parseInt(new Date(startRef.current.value).getFullYear());
    startMonth = parseInt(new Date(startRef.current.value).getMonth() + 1);
    startDate = parseInt(new Date(startRef.current.value).getDate());

    if (startMonth === 1) {
      endMonth = 12;
      endYear = startYear;
    } else {
      endMonth = startMonth - 1;
      endYear = startYear + 1;
    }

    if (startMonth === 1) {
      startMonth = `0${startMonth}`;
    } else if (startMonth < 10) {
      startMonth = `0${startMonth}`;
      endMonth = `0${endMonth}`;
    } else if (startMonth === 10) {
      endMonth = `0${endMonth}`;
    }

    if (startDate === 1) {
      endDate = new Date(endYear, endMonth, 0).getDate();
    } else if (startDate > 1) {
      endDate = startDate - 1;
      endMonth = startMonth;
    }

    if (startDate === 1) {
      startDate = `0${startDate}`;
    } else if (startDate < 10) {
      startDate = `0${startDate}`;
      endDate = `0${endDate}`;
    } else if (startDate === 10) {
      endDate = `0${endDate}`;
    }

    yearName =
      startYear > 2000 && startYear < 2010
        ? `FY0${startYear - 2000}`
        : startYear > 2010 && `FY${startYear - 2000}`;

    setDisplay({
      fiscalYear: yearName,
      fyStartDate: `${startYear}-${startMonth}-${startDate}`,
      fyEndDate: `${endYear}-${endMonth}-${endDate}`,
    });
  }

  function handleEndChange() {
    endYear = parseInt(new Date(endRef.current.value).getFullYear());
    endMonth = parseInt(new Date(endRef.current.value).getMonth() + 1);
    endDate = parseInt(new Date(endRef.current.value).getDate());

    if (endMonth === 12) {
      startMonth = 1;
      startYear = endYear;
    } else {
      startMonth = endMonth + 1;
      startYear = endYear - 1;
    }

    if (startMonth === 1) {
      startMonth = `0${startMonth}`;
    } else if (startMonth < 10) {
      startMonth = `0${startMonth}`;
      endMonth = `0${endMonth}`;
    } else if (startMonth === 10) {
      endMonth = `0${endMonth}`;
    }

    if (endDate === new Date(endYear, endMonth, 0).getDate()) {
      startDate = 1;
    } else if (endDate < new Date(endYear, endMonth, 0).getDate()) {
      startDate = endDate + 1;
      startMonth = endMonth;
    }

    if (startDate === 1) {
      startDate = `0${startDate}`;
    } else if (startDate < 10) {
      startDate = `0${startDate}`;
      endDate = `0${endDate}`;
    } else if (startDate === 10) {
      endDate = `0${endDate}`;
    }

    yearName =
      startYear > 2000 && startYear < 2010
        ? `FY0${startYear - 2000}`
        : startYear > 2010 && `FY${startYear - 2000}`;

    setDisplay({
      fiscalYear: yearName,
      fyStartDate: `${startYear}-${startMonth}-${startDate}`,
      fyEndDate: `${endYear}-${endMonth}-${endDate}`,
    });
  }

  function handleSubmit(event) {
    event.preventDefault();

    if (display.fiscalYear !== "") {
      if (editing) {
        setConfirmModal(true);
        setResConfirm(true);
        if (resConfirm) {
          fetch(FISCAL_YEAR_UPDATE_URL + editingId, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${TOKEN}`,
            },
            body: JSON.stringify({ ...display }),
          }).then(async (response) => {
            if (response.ok) {
              const res = await response.json();
              setConfirmModal(false);
              if (res.result) {
                getData();
                result = res.result;
              } else {
                result = res.result;
                setResultMessage(res.message);
                setInfoModal(true);
              }
              setResConfirm(false);
            }
          });
        }
      } else {
        fetch(FISCAL_YEAR_CREATE_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${TOKEN}`,
          },
          body: JSON.stringify(display),
        }).then(async (response) => {
          if (response.ok) {
            const res = await response.json();
            if (res.result) {
              getData();
              result = res.result;
            } else {
              getData();
              result = res.result;
              setResultMessage(res.message);
              setInfoModal(true);
            }
          }
          setResConfirm(false);
        });
      }
    }
  }

  function catchFunction(e) {
    if (value === "Update") {
      handleSubmit(e);
    } else {
      deleteFiscalYear(deleteId);
    }
  }

  const getValue = (e) => {
    setValue(e.target.value);
  };

  return (
    <div className="container-fluid main--body">
      <div className="row justify-content-center d-flex">
        <div className="col-md-12 mt-3 justify-content-end d-flex">
          <button
            className="actionIconOutline save bg-blue"
            id="add"
            data-tooltip-content="add"
            onClick={(e) => {
              getValue(e);
              setAddEditModal(true);
              setTitle("Register Fiscal Year");
            }}
          >
            <BiPlusCircle />
            <ReactTooltip place="bottom" anchorId="add" />
          </button>
        </div>
        <ConfirmModal
          show={showConfirmModal}
          title={value === "Update" ? updateMessage : deleteMessage}
          onSubmit={(e) => {
            setConfirmModal(false);
            catchFunction(e);
          }}
          onCancel={() => {
            setConfirmModal(false);
            setResConfirm(false);
          }}
          onHide={() => {
            setConfirmModal(false);
            setResConfirm(false);
          }}
        />
        <InfoModal
          show={showInfoModal}
          title={resultMessage}
          onSubmit={() => setInfoModal(false)}
          onHide={() => {
            setInfoModal(false);
            setResConfirm(false);
          }}
        />
        <SuccessModal
          show={showSuccessModal}
          title={resultMessage}
          onSubmit={() => setSuccessModal(false)}
          onHide={() => {
            setSuccessModal(false);
            setResConfirm(false);
          }}
        />

        <div className="col-md-12 mt-2 overflow-auto">
          <table className="table table-hover table-bordered">
            <thead>
              <tr>
                <th className="w-25 text-center">Fiscal Year</th>
                <th className="w-25 text-center">Start Date</th>
                <th className="w-25 text-center">End Date</th>
                <th className="w-25 text-center">Action</th>
              </tr>
            </thead>

            <tbody className="h-75 overflow-auto">
              {fiscalYear.map((data) => (
                <DisplayFiscalYear
                  key={data.fiscalYear}
                  id={data.fiscalYearId}
                  startDate={data.fyStartDate}
                  endDate={data.fyEndDate}
                  yearName={data.fiscalYear}
                  edit={editFiscalYear}
                  delete={deleteFiscalYear}
                  deleteValue={getValue}
                  selectedId={selectedId}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <FiscalYearAddEditModal
        show={showAddEditModal}
        title={title}
        onSubmit={() => setAddEditModal(false)}
        onHide={() => {
          setAddEditModal(false);
          setEditing(false);
        }}
        getValue={getValue}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        display={display}
        inputRef={inputRef}
        result={result}
        message={message}
        handleStartChange={handleStartChange}
        startRef={startRef}
        handleEndChange={handleEndChange}
        endRef={endRef}
        editing={editing}
        setAddEditModal={setAddEditModal}
      />
    </div>
  );

  function editFiscalYear(id) {
    setEditing(true);
    if (selectedId === id) {
      setSelectedId(undefined);
      setDisplay({
        fiscalYear: "",
        fyStartDate: "",
        fyEndDate: "",
      });
      setEditing(false);
    } else {
      setSelectedId(id);
      for (let i = 0; i < fiscalYear.length; i++) {
        if (fiscalYear[i].fiscalYearId === id) {
          setDisplay({
            fiscalYear: fiscalYear[i].fiscalYear,
            fyStartDate: fiscalYear[i].fyStartDate,
            fyEndDate: fiscalYear[i].fyEndDate,
          });
          editingId = id;
        }
      }
    }
  }
  function deleteFiscalYear(id) {
    setDeleteId(id);
    setConfirmModal(true);
    setResConfirm(true);
    if (resConfirm) {
      fetch(FISCAL_YEAR_DELETE_URL + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
      }).then(async (response) => {
        if (response.ok) {
          const res = await response.json();
          setConfirmModal(false);
          if (res.result) {
            getData();
            setResultMessage(res.message);
            setSuccessModal(true);
          } else {
            setResultMessage(res.message);
            setInfoModal(true);
          }
          setResConfirm(false);
        }
      });
    }
  }
}

DisplayFiscalYear.propTypes = {
  selectedId: PropTypes.number,
  id: PropTypes.number.isRequired,
  yearName: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  delete: PropTypes.func.isRequired,
  deleteValue: PropTypes.func.isRequired,
};
