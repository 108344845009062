import { LoadingOverlay } from "@mantine/core";
import React from "react";

const LoadingComponents = () => {
  return (
    <LoadingOverlay
      loaderProps={{ size: "sm", variant: "bars" }}
      overlayOpacity={0.3}
      overlayColor="#c5c5c5"
      visible
    />
  );
};

export default LoadingComponents;
