// Converts array of data rows into array of objects.
export function DataConveter(data) {
  // For each row...
  const transformedData = data.data_rows.map((row) => {
    let result = {};
    // Map each value to a key from column_names.
    row.forEach((value, index) => {
      result[data.column_names[index]] = value;
    });
    return result; // Return object a row.
  });
  return transformedData; // Return array of objects.
}

export function DataConveterBy(data, key1, key2) {
  let combinedData = {};
  data.forEach((item) => {
    if (combinedData[item[key1]]) {
      key2.forEach((k) => (combinedData[item[key1]][k] += item[k]));
    } else {
      let newEntry = {};
      newEntry[key1] = item[key1];
      key2.forEach((k) => (newEntry[k] = item[k]));
      combinedData[item[key1]] = newEntry;
    }
  });
  return Object.values(combinedData);
}

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function TotalProgressCalculate(total, budget) {
  if (total === 0 || budget === 0) return 0;
  return ((total / budget) * 100).toFixed(0);
}

export function Financial(value, decimal, categoryUnit) {
  let result = `${Number.parseFloat(value).toFixed(decimal)}${categoryUnit}`;
  return result;
}

export function ExportChartImage(flex, title, format) {
  flex.saveImageToFile(`${title}.${format}`);
}

export function ExportChartPDF(pdf, flex, chart, wTitle, header) {
  let doc = new pdf.PdfDocument({
    ended: (sender, args) => pdf.saveBlob(args.blob, `${wTitle}.pdf`),
  });

  //
  flex.saveImageToDataUrl(chart.ImageFormat.Png, (url) => {
    doc.drawText(header);
    doc.drawImage(url);
    doc.end();
  });
}

export function RetriveCSVFormat(wjGrid, flex) {
  return flex.getClipString(
    new wjGrid.CellRange(0, 0, flex.rows.length - 1, flex.columns.length - 1),
    true,
    true
  );
}
export function ExportGridExcel(gridXlsx, title, flex) {
  gridXlsx.FlexGridXlsxConverter.saveAsync(
    flex,
    {},
    `${title}.xlsx`,
    null,
    null,
    true
  );
}
export function ExportGridCSV(csv, fileName) {
  let fileType = "txt/csv;charset=utf-8";
  if (navigator.msSaveBlob) {
    // IE
    navigator.msSaveBlob(
      new Blob([csv], {
        type: fileType,
      }),
      fileName
    );
  } else {
    let e = document.createElement("a");
    e.setAttribute("href", "data:" + fileType + "," + encodeURIComponent(csv));
    e.setAttribute("download", fileName);
    e.style.display = "none";
    document.body.appendChild(e);
    e.click();
    document.body.removeChild(e);
  }
}

export function ExportGridPDF(gridPdf, flex, title) {
  gridPdf.FlexGridPdfConverter.export(flex, `${title}.pdf`, {
    maxPages: 20,
    scaleMode: gridPdf.ScaleMode.PageWidth,
    documentOptions: {
      compress: true,
      header: { declarative: { text: "\t&[Page] of &[Pages]" } },
      footer: { declarative: { text: "\t&[Page] of &[Pages]" } },
      info: { author: "iLiveBoard Team", title: title },
    },
    styles: {
      cellStyle: { backgroundColor: "#ffffff", borderColor: "#c6c6c6" },
      altCellStyle: { backgroundColor: "#f9f9f9" },
      groupCellStyle: { backgroundColor: "#dddddd" },
      headerCellStyle: { backgroundColor: "#eaeaea" },
    },
  });
}

/**
 * Filters array by attributes,
 * Remove duplicate attribute.
 *
 * @param {Array} data - The array of objects to be filtered.
 * @param {Array} attributes - The attributes to filter by.
 * @returns {Object} - Object with attribute key and unique array values.
 */
export function DataColumnsFiltering(data, attributes) {
  if (data.length === 0) return [];

  let result = {};
  attributes.forEach((attribute) => {
    result[attribute] = [];
    data.forEach((item) => {
      if (
        item[attribute] !== undefined &&
        !result[attribute].includes(item[attribute])
      ) {
        result[attribute].push(item[attribute]);
      }
    });
  });
  return result;
}

export function AttributeFiltering(data) {
  if (data.length === 0) return [];
  const obj = data[0];
  let keysArray = [];
  for (var key in obj) {
    if (typeof obj[key] === "string") {
      keysArray.push(key);
    }
  }
  return keysArray;
}

export function DataColumnsFilteringBy(data, attributes) {
  if (data.length === 0) return [];

  let result = {};
  attributes.forEach((attribute, index) => {
    result[attribute] = [];
    data.forEach((item) => {
      if (!result[attribute].includes(item[index])) {
        result[attribute].push(item[index]);
      }
    });
  });
  return result;
}

export function AttributeFilteringBy(data, columns) {
  if (data.length === 0) return [];
  let keysArray = [];
  let dataArray = data[0];
  columns.forEach((names, index) => {
    if (typeof dataArray[index] === "string") keysArray.push(names);
  });
  return keysArray;
}

export function FinancialDataFiltering(data_rows, years, quarters, bindingY) {
  const result = [];

  if (years.length > 1 && quarters.length > 1) {
    years.forEach((year) => {
      quarters.forEach((quarter) => {
        const obj = { bindingX: year.slice(2) + " " + quarter };
        bindingY.forEach((binding) => {
          const row = data_rows.find(
            (row) => row[0] === binding && row[1] === year && row[2] === quarter
          );
          obj[binding] = row ? row[3] : 0;
        });
        result.push(obj);
      });
    });
  } else if (years.length > 1 && quarters.length === 1) {
    years.forEach((year) => {
      const obj = { bindingX: year };
      bindingY.forEach((binding) => {
        const row = data_rows.find(
          (row) =>
            row[0] === binding && row[1] === year && row[2] === quarters[0]
        );
        obj[binding] = row ? row[3] : 0;
      });
      result.push(obj);
    });
  } else if (quarters.length > 1 && years.length === 1) {
    quarters.forEach((quarters) => {
      const obj = { bindingX: quarters };
      bindingY.forEach((binding) => {
        const row = data_rows.find(
          (row) =>
            row[0] === binding && row[1] === years[0] && row[2] === quarters
        );
        obj[binding] = row ? row[3] : 0;
      });
      result.push(obj);
    });
  }

  return result;
}

// export function DatasourceColumnObject(answer, metadata) {
//   let resultObj = {};
//   answer.forEach((item) => {
//     const { label, value } = item;
//     if (!resultObj[value]) {
//       const getMetadata = metadata[value];
//       resultObj[value] = ColumnsNameByGroup(
//         getMetadata.data_rows,
//         getMetadata.column_names
//       );
//     }
//   });

//   return resultObj;
// }

// Columns key and value formatting by group
export function ColumnsNameByGroup(data_rows, column_names) {
  let results = [];
  let uniqueValues = {};

  column_names.forEach((col, index) => {
    uniqueValues[col] = new Set();
    data_rows.forEach((row) => {
      if (typeof row[index] === "string") {
        uniqueValues[col].add(row[index]);
      }
    });
  });

  Object.keys(uniqueValues).forEach((col) => {
    uniqueValues[col].forEach((value) => {
      results.push({
        value: value,
        label: value,
        group: col,
      });
    });
  });
  return results;
}

export function FindFirstIndex(data_rows, target) {
  for (let i = 0; i < data_rows.length; i++) {
    if (data_rows[i].includes(target)) {
      return i;
    }
  }
  return -1;
}
