import { Button, Group, Modal, TextInput } from "@mantine/core";
import React from "react";
import PropTypes from "prop-types";

// SID010 --> CREATE DASHBOARD
export default function DashboardCreateModal(props) {
  const { open, form, onSubmit, close } = props;
  return (
    <Modal opened={open} onClose={close} title="Create Dashboard" centered>
      <form onSubmit={form.onSubmit((values) => onSubmit(values))}>
        <TextInput
          label="Dashboard Name"
          placeholder=""
          {...form.getInputProps("dashboardName")}
        />
        <Group position="right" mt="md">
          <Button type="submit">Submit</Button>
        </Group>
      </form>
    </Modal>
  );
}

DashboardCreateModal.propTypes = {
  open: PropTypes.bool,
  form: PropTypes.object,
  onSubmit: PropTypes.func,
  close: PropTypes.func,
};
