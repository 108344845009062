import { Menu, ThemeIcon, rem } from "@mantine/core";
import { IconHome } from "@tabler/icons-react";
import React from "react";
import PropTypes from "prop-types";

const SettingsMenu = (props) => {
  const { children, handleMenu } = props;
  return (
    <Menu
      shadow="md"
      width={200}
      trigger="hover"
      openDelay={100}
      closeDelay={100}
    >
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        <Menu.Label>Settings</Menu.Label>
        <Menu.Item
          icon={
            <ThemeIcon variant="filled" size={rem(18)}>
              <IconHome size={rem(12)} />
            </ThemeIcon>
          }
          onClick={() => handleMenu("/dashboard/query")}
        >
          Home
        </Menu.Item>
        {/* <Menu.Item
          color="red"
          icon={<IconLayoutCollage size={14} />}
          onClick={() => handleMenu("wjimo")}
        >
          Wjimo Components
        </Menu.Item> */}
        {/* <Menu.Item
          color="red"
          icon={<IconLayoutCollage size={14} />}
          onClick={() => handleMenu("queryBuilder")}
        >
          Query Builder
        </Menu.Item> */}
        {/* <Menu.Item
          color="blue"
          icon={<IconLayoutCollage size={14} />}
          onClick={() => handleMenu("grafana")}
        >
          Query Selector
        </Menu.Item> */}
      </Menu.Dropdown>
    </Menu>
  );
};

export default SettingsMenu;
SettingsMenu.propTypes = {
  children: PropTypes.any,
  handleMenu: PropTypes.func,
};
