export function salesAndGPHeaderCell(
  panel,
  fiscalYear,
  quarter1List,
  quarter2List,
  quarter3List,
  quarter4List,
  dataList
) {
  for (let colIndex = 27; colIndex <= 31; colIndex++) {
    panel.setCellData(1, colIndex, "1Q20" + fiscalYear.slice(-2));
  }

  for (let colIndex = 32; colIndex <= 36; colIndex++) {
    panel.setCellData(1, colIndex, "2Q20" + fiscalYear.slice(-2));
  }

  for (let colIndex = 37; colIndex <= 41; colIndex++) {
    panel.setCellData(1, colIndex, "3Q20" + fiscalYear.slice(-2));
  }

  for (let colIndex = 42; colIndex <= 46; colIndex++) {
    panel.setCellData(1, colIndex, "4Q20" + fiscalYear.slice(-2));
  }

  panel.setCellData(2, 24, "Sales");
  panel.setCellData(2, 25, "GP%");
  panel.setCellData(2, 26, "GP");
  panel.setCellData(2, 27, quarter1List[0].month1);
  panel.setCellData(2, 28, quarter1List[0].month2);
  panel.setCellData(2, 29, quarter1List[0].month3);
  panel.setCellData(2, 30, "GP%");
  panel.setCellData(2, 31, "GP");
  panel.setCellData(2, 32, quarter2List[0].month1);
  panel.setCellData(2, 33, quarter2List[0].month2);
  panel.setCellData(2, 34, quarter2List[0].month3);
  panel.setCellData(2, 35, "GP%");
  panel.setCellData(2, 36, "GP");
  panel.setCellData(2, 37, quarter3List[0].month1);
  panel.setCellData(2, 38, quarter3List[0].month2);
  panel.setCellData(2, 39, quarter3List[0].month3);
  panel.setCellData(2, 40, "GP%");
  panel.setCellData(2, 41, "GP");
  panel.setCellData(2, 42, quarter4List[0].month1);
  panel.setCellData(2, 43, quarter4List[0].month2);
  panel.setCellData(2, 44, quarter4List[0].month3);
  panel.setCellData(2, 45, "GP%");
  panel.setCellData(2, 46, "GP");

  let month1Sales = 0,
    month2Sales = 0,
    month3Sales = 0;
  let month4Sales = 0,
    month5Sales = 0,
    month6Sales = 0;
  let month7Sales = 0,
    month8Sales = 0,
    month9Sales = 0;
  let month10Sales = 0,
    month11Sales = 0,
    month12Sales = 0;
  let colGPTotal1 = 0,
    colGPTotal2 = 0,
    colGPTotal3 = 0,
    colGPTotal4 = 0;
  let totalSales = 0,
    totalGP = 0;
  if (dataList.length > 0) {
    dataList.forEach(function (data) {
      if (data.month1 != null && data.month1 !== "") {
        month1Sales += parseFloat(data.month1);
      }
      if (data.month2 != null && data.month2 !== "") {
        month2Sales += parseFloat(data.month2);
      }
      if (data.month3 != null && data.month3 !== "") {
        month3Sales += parseFloat(data.month3);
      }
      if (data.gp1 != null && data.gp1 !== undefined) {
        colGPTotal1 += parseFloat(data.gp1);
      }

      if (data.month4 != null && data.month4 !== "") {
        month4Sales += parseFloat(data.month4);
      }
      if (data.month5 != null && data.month5 !== "") {
        month5Sales += parseFloat(data.month5);
      }
      if (data.month6 != null && data.month6 !== "") {
        month6Sales += parseFloat(data.month6);
      }
      if (data.gp2 != null && data.gp2 !== undefined) {
        colGPTotal2 += parseFloat(data.gp2);
      }

      if (data.month7 != null && data.month7 !== "") {
        month7Sales += parseFloat(data.month7);
      }
      if (data.month8 != null && data.month8 !== "") {
        month8Sales += parseFloat(data.month8);
      }
      if (data.month9 != null && data.month9 !== "") {
        month9Sales += parseFloat(data.month9);
      }
      if (data.gp3 != null && data.gp3 !== undefined) {
        colGPTotal3 += parseFloat(data.gp3);
      }

      if (data.month10 != null && data.month10 !== "") {
        month10Sales += parseFloat(data.month10);
      }
      if (data.month11 != null && data.month11 !== "") {
        month11Sales += parseFloat(data.month11);
      }
      if (data.month12 != null && data.month12 !== "") {
        month12Sales += parseFloat(data.month12);
      }
      if (data.gp4 != null && data.gp4 !== undefined) {
        colGPTotal4 += parseFloat(data.gp4);
      }

      if (data.totalRowSales != null) {
        totalSales += parseFloat(data.totalRowSales);
      }
      if (data.totalRowGP != null) {
        totalGP += parseFloat(data.totalRowGP);
      }
    });
  }

  // Total Sales, GP% and GP
  panel.setCellData(3, 24, parseFloat(totalSales).toFixed(2));
  calColTotalGP(panel, totalSales, totalGP);
  panel.setCellData(3, 26, parseFloat(totalGP).toFixed(2));

  // Quarter 1
  panel.setCellData(3, 27, parseFloat(month1Sales).toFixed(2));
  panel.setCellData(3, 28, parseFloat(month2Sales).toFixed(2));
  panel.setCellData(3, 29, parseFloat(month3Sales).toFixed(2));
  calGPPercent(panel, 30, colGPTotal1, month1Sales, month2Sales, month3Sales);
  panel.setCellData(3, 31, colGPTotal1.toFixed(2));

  // Quarter 2
  panel.setCellData(3, 32, parseFloat(month4Sales).toFixed(2));
  panel.setCellData(3, 33, parseFloat(month5Sales).toFixed(2));
  panel.setCellData(3, 34, parseFloat(month6Sales).toFixed(2));
  calGPPercent(panel, 35, colGPTotal2, month4Sales, month5Sales, month6Sales);
  panel.setCellData(3, 36, colGPTotal2.toFixed(2));

  // Quarter 3
  panel.setCellData(3, 37, parseFloat(month7Sales).toFixed(2));
  panel.setCellData(3, 38, parseFloat(month8Sales).toFixed(2));
  panel.setCellData(3, 39, parseFloat(month9Sales).toFixed(2));
  calGPPercent(panel, 40, colGPTotal3, month7Sales, month8Sales, month9Sales);
  panel.setCellData(3, 41, colGPTotal3.toFixed(4));

  // Quarter 4
  panel.setCellData(3, 42, parseFloat(month10Sales).toFixed(2));
  panel.setCellData(3, 43, parseFloat(month11Sales).toFixed(2));
  panel.setCellData(3, 44, parseFloat(month12Sales).toFixed(2));
  calGPPercent(
    panel,
    45,
    colGPTotal4,
    month10Sales,
    month11Sales,
    month12Sales
  );
  panel.setCellData(3, 46, colGPTotal4.toFixed(2));
}

export function calAmountCell(binding, panel, s, e) {
  if (
    binding === "salesMonth" ||
    binding === "salesAmount" ||
    binding === "salesCurrencyAmount" ||
    binding === "salesExchangeRate"
  ) {
    let item = s.rows[e.row].dataItem;
    let month1Sales = 0,
      month2Sales = 0,
      month3Sales = 0,
      month4Sales = 0,
      month5Sales = 0,
      month6Sales = 0,
      month7Sales = 0,
      month8Sales = 0,
      month9Sales = 0,
      month10Sales = 0,
      month11Sales = 0,
      month12Sales = 0;

    if (item.salesMonth === "4") {
      s.rows[e.row].dataItem.month1 = parseFloat(item.salesAmount);
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month1 !== null &&
          s.rows[i].dataItem.month1 !== ""
        ) {
          month1Sales += parseFloat(item.month1);
        }
      }
      panel.setCellData(3, 27, parseFloat(month1Sales).toFixed(2));
      calGP1(s, e, panel);
      calTotalRowSales(s, e, panel);
    } else {
      s.rows[e.row].dataItem.month1 = "";
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month1 !== null &&
          s.rows[i].dataItem.month1 !== ""
        ) {
          month1Sales += parseFloat(item.month1);
        }
      }
      panel.setCellData(3, 27, parseFloat(month1Sales).toFixed(2));
      if (item.salesMonth === "5" || item.salesMonth === "6") {
        calGP1(s, e, panel);
        calTotalRowSales(s, e, panel);
      } else {
        s.rows[e.row].dataItem.gp1 = 0;
        s.rows[e.row].dataItem.gpPercent1 = 0;
        calGP1(s, e, panel);
        calTotalRowSales(s, e, panel);
      }
    }

    if (item.salesMonth === "5") {
      s.rows[e.row].dataItem.month2 = parseFloat(item.salesAmount);
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month2 !== null &&
          s.rows[i].dataItem.month2 !== ""
        ) {
          month2Sales += parseFloat(item.month2);
        }
      }
      panel.setCellData(3, 28, parseFloat(month2Sales).toFixed(2));
      calGP1(s, e, panel);
      calTotalRowSales(s, e, panel);
    } else {
      s.rows[e.row].dataItem.month2 = "";
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month2 !== null &&
          s.rows[i].dataItem.month2 !== ""
        ) {
          month2Sales += parseFloat(item.month2);
        }
      }
      panel.setCellData(3, 28, parseFloat(month2Sales).toFixed(2));
      if (item.salesMonth === "4" || item.salesMonth === "6") {
        calGP1(s, e, panel);
        calTotalRowSales(s, e, panel);
      } else {
        s.rows[e.row].dataItem.gp1 = 0;
        s.rows[e.row].dataItem.gpPercent1 = 0;
        calGP1(s, e, panel);
        calTotalRowSales(s, e, panel);
      }
    }

    if (item.salesMonth === "6") {
      s.rows[e.row].dataItem.month3 = parseFloat(item.salesAmount);
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month3 !== null &&
          s.rows[i].dataItem.month3 !== ""
        ) {
          month3Sales += parseFloat(item.month3);
        }
      }
      panel.setCellData(3, 29, parseFloat(month3Sales).toFixed(2));
      calGP1(s, e, panel);
      calTotalRowSales(s, e, panel);
    } else {
      s.rows[e.row].dataItem.month3 = "";
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month3 !== null &&
          s.rows[i].dataItem.month3 !== ""
        ) {
          month3Sales += parseFloat(item.month3);
        }
      }
      panel.setCellData(3, 29, parseFloat(month3Sales).toFixed(2));
      if (item.salesMonth === "4" || item.salesMonth === "5") {
        calGP1(s, e, panel);
        calTotalRowSales(s, e, panel);
      } else {
        s.rows[e.row].dataItem.gp1 = 0;
        s.rows[e.row].dataItem.gpPercent1 = 0;
        calGP1(s, e, panel);
        calTotalRowSales(s, e, panel);
      }
    }

    if (item.salesMonth === "7") {
      s.rows[e.row].dataItem.month4 = parseFloat(item.salesAmount);
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month4 !== null &&
          s.rows[i].dataItem.month4 !== ""
        ) {
          month4Sales += parseFloat(item.month4);
        }
      }
      panel.setCellData(3, 32, parseFloat(month4Sales).toFixed(2));
      calGP2(s, e, panel);
      calTotalRowSales(s, e, panel);
    } else {
      s.rows[e.row].dataItem.month4 = "";
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month4 !== null &&
          s.rows[i].dataItem.month4 !== ""
        ) {
          month4Sales += parseFloat(item.month4);
        }
      }
      panel.setCellData(3, 32, parseFloat(month4Sales).toFixed(2));
      if (item.salesMonth === "8" || item.salesMonth === "9") {
        calGP2(s, e, panel);
        calTotalRowSales(s, e, panel);
      } else {
        s.rows[e.row].dataItem.gp2 = 0;
        s.rows[e.row].dataItem.gpPercent2 = 0;
        calGP2(s, e, panel);
        calTotalRowSales(s, e, panel);
      }
    }

    if (item.salesMonth === "8") {
      s.rows[e.row].dataItem.month5 = parseFloat(item.salesAmount);
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month5 !== null &&
          s.rows[i].dataItem.month5 !== ""
        ) {
          month5Sales += parseFloat(item.month5);
        }
      }
      panel.setCellData(3, 33, parseFloat(month5Sales).toFixed(2));
      calGP2(s, e, panel);
      calTotalRowSales(s, e, panel);
    } else {
      s.rows[e.row].dataItem.month5 = "";
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month5 !== null &&
          s.rows[i].dataItem.month5 !== ""
        ) {
          month5Sales += parseFloat(item.month5);
        }
      }
      panel.setCellData(3, 33, parseFloat(month5Sales).toFixed(2));
      if (item.salesMonth === "7" || item.salesMonth === "9") {
        calGP2(s, e, panel);
        calTotalRowSales(s, e, panel);
      } else {
        s.rows[e.row].dataItem.gp2 = 0;
        s.rows[e.row].dataItem.gpPercent2 = 0;
        calGP2(s, e, panel);
        calTotalRowSales(s, e, panel);
      }
    }

    if (item.salesMonth === "9") {
      s.rows[e.row].dataItem.month6 = parseFloat(item.salesAmount);
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month6 !== null &&
          s.rows[i].dataItem.month6 !== ""
        ) {
          month6Sales += parseFloat(item.month6);
        }
      }
      panel.setCellData(3, 34, parseFloat(month6Sales).toFixed(2));
      calGP2(s, e, panel);
      calTotalRowSales(s, e, panel);
    } else {
      s.rows[e.row].dataItem.month6 = "";
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month6 !== null &&
          s.rows[i].dataItem.month6 !== ""
        ) {
          month6Sales += parseFloat(item.month6);
        }
      }
      panel.setCellData(3, 34, parseFloat(month6Sales).toFixed(2));
      if (item.salesMonth === "7" || item.salesMonth === "8") {
        calGP2(s, e, panel);
        calTotalRowSales(s, e, panel);
      } else {
        s.rows[e.row].dataItem.gp2 = 0;
        s.rows[e.row].dataItem.gpPercent2 = 0;
        calGP2(s, e, panel);
        calTotalRowSales(s, e, panel);
      }
    }

    if (item.salesMonth === "10") {
      s.rows[e.row].dataItem.month7 = parseFloat(item.salesAmount);

      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month7 !== null &&
          s.rows[i].dataItem.month7 !== ""
        ) {
          month7Sales += parseFloat(item.month7);
        }
      }
      panel.setCellData(3, 37, parseFloat(month7Sales).toFixed(2));
      calGP3(s, e, panel);
      calTotalRowSales(s, e, panel);
    } else {
      s.rows[e.row].dataItem.month7 = "";
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month7 !== null &&
          s.rows[i].dataItem.month7 !== ""
        ) {
          month7Sales += parseFloat(item.month7);
        }
      }
      panel.setCellData(3, 37, parseFloat(month7Sales).toFixed(2));
      if (item.salesMonth === "11" || item.salesMonth === "12") {
        calGP3(s, e, panel);
        calTotalRowSales(s, e, panel);
      } else {
        s.rows[e.row].dataItem.gp3 = 0;
        s.rows[e.row].dataItem.gpPercent3 = 0;
        calGP3(s, e, panel);
        calTotalRowSales(s, e, panel);
      }
    }

    if (item.salesMonth === "11") {
      s.rows[e.row].dataItem.month8 = parseFloat(item.salesAmount);
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month8 !== null &&
          s.rows[i].dataItem.month8 !== ""
        ) {
          month8Sales += parseFloat(item.month8);
        }
      }
      panel.setCellData(3, 38, parseFloat(month8Sales).toFixed(2));
      calGP3(s, e, panel);
      calTotalRowSales(s, e, panel);
    } else {
      s.rows[e.row].dataItem.month8 = "";
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month8 !== null &&
          s.rows[i].dataItem.month8 !== ""
        ) {
          month8Sales += parseFloat(item.month8);
        }
      }
      panel.setCellData(3, 38, parseFloat(month8Sales).toFixed(2));
      if (item.salesMonth === "10" || item.salesMonth === "12") {
        calGP3(s, e, panel);
        calTotalRowSales(s, e, panel);
      } else {
        s.rows[e.row].dataItem.gp3 = 0;
        s.rows[e.row].dataItem.gpPercent3 = 0;
        calGP3(s, e, panel);
        calTotalRowSales(s, e, panel);
      }
    }

    if (item.salesMonth === "12") {
      s.rows[e.row].dataItem.month9 = parseFloat(item.salesAmount);
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month9 !== null &&
          s.rows[i].dataItem.month9 !== ""
        ) {
          month9Sales += parseFloat(item.month9);
        }
      }
      panel.setCellData(3, 39, parseFloat(month9Sales).toFixed(2));
      calGP3(s, e, panel);
      calTotalRowSales(s, e, panel);
    } else {
      s.rows[e.row].dataItem.month9 = "";
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month9 !== null &&
          s.rows[i].dataItem.month9 !== ""
        ) {
          month9Sales += parseFloat(item.month9);
        }
      }
      panel.setCellData(3, 39, parseFloat(month9Sales).toFixed(2));
      if (item.salesMonth === "10" || item.salesMonth === "11") {
        calGP3(s, e, panel);
        calTotalRowSales(s, e, panel);
      } else {
        s.rows[e.row].dataItem.gp3 = 0;
        s.rows[e.row].dataItem.gpPercent3 = 0;
        calGP3(s, e, panel);
        calTotalRowSales(s, e, panel);
      }
    }

    if (item.salesMonth === "1") {
      s.rows[e.row].dataItem.month10 = parseFloat(item.salesAmount);
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month10 !== null &&
          s.rows[i].dataItem.month10 !== ""
        ) {
          month10Sales += parseFloat(item.month10);
        }
      }
      panel.setCellData(3, 42, parseFloat(month10Sales).toFixed(2));
      calGP4(s, e, panel);
      calTotalRowSales(s, e, panel);
    } else {
      s.rows[e.row].dataItem.month10 = "";
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month10 !== null &&
          s.rows[i].dataItem.month10 !== ""
        ) {
          month10Sales += parseFloat(item.month10);
        }
      }
      panel.setCellData(3, 42, parseFloat(month10Sales).toFixed(2));
      if (item.salesMonth === "2" || item.salesMonth === "3") {
        calGP4(s, e, panel);
        calTotalRowSales(s, e, panel);
      } else {
        s.rows[e.row].dataItem.gp4 = 0;
        s.rows[e.row].dataItem.gpPercent4 = 0;
        calGP4(s, e, panel);
        calTotalRowSales(s, e, panel);
      }
    }

    if (item.salesMonth === "2") {
      s.rows[e.row].dataItem.month11 = parseFloat(item.salesAmount);
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month11 !== null &&
          s.rows[i].dataItem.month11 !== ""
        ) {
          month11Sales += parseFloat(item.month11);
        }
      }
      panel.setCellData(3, 43, parseFloat(month11Sales).toFixed(2));
      calGP4(s, e, panel);
      calTotalRowSales(s, e, panel);
    } else {
      s.rows[e.row].dataItem.month11 = "";
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month11 !== null &&
          s.rows[i].dataItem.month11 !== ""
        ) {
          month11Sales += parseFloat(item.month11);
        }
      }
      panel.setCellData(3, 43, parseFloat(month11Sales).toFixed(2));
      if (item.salesMonth === "1" || item.salesMonth === "3") {
        calGP4(s, e, panel);
        calTotalRowSales(s, e, panel);
      } else {
        s.rows[e.row].dataItem.gp4 = 0;
        s.rows[e.row].dataItem.gpPercent4 = 0;
        calGP4(s, e, panel);
        calTotalRowSales(s, e, panel);
      }
    }

    if (item.salesMonth === "3") {
      s.rows[e.row].dataItem.month12 = parseFloat(item.salesAmount);
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month12 !== null &&
          s.rows[i].dataItem.month12 !== ""
        ) {
          month12Sales += parseFloat(item.month12);
        }
      }
      panel.setCellData(3, 44, parseFloat(month12Sales).toFixed(2));
      calGP4(s, e, panel);
      calTotalRowSales(s, e, panel);
    } else {
      s.rows[e.row].dataItem.month12 = "";
      for (let i = 0; i < s.rows.length - 1; i++) {
        let item = s.rows[i].dataItem;
        if (
          s.rows[i].dataItem.month12 !== null &&
          s.rows[i].dataItem.month12 !== ""
        ) {
          month12Sales += parseFloat(item.month12);
        }
      }
      panel.setCellData(3, 44, parseFloat(month12Sales).toFixed(2));
      if (item.salesMonth === "1" || item.salesMonth === "2") {
        calGP4(s, e, panel);
        calTotalRowSales(s, e, panel);
      } else {
        s.rows[e.row].dataItem.gp4 = 0;
        s.rows[e.row].dataItem.gpPercent4 = 0;
        calGP4(s, e, panel);
        calTotalRowSales(s, e, panel);
      }
    }
  }

  if (binding === "state") {
    let item = s.rows[e.row].dataItem;
    if (item.salesMonth === null) {
      s.rows[e.row].dataItem.gp1 = 0;
      s.rows[e.row].dataItem.gp2 = 0;
      s.rows[e.row].dataItem.gp3 = 0;
      s.rows[e.row].dataItem.gp4 = 0;
      s.rows[e.row].dataItem.gpPercent1 = 0;
      s.rows[e.row].dataItem.gpPercent2 = 0;
      s.rows[e.row].dataItem.gpPercent3 = 0;
      s.rows[e.row].dataItem.gpPercent4 = 0;
    } else {
      if (
        item.salesMonth === "4" ||
        item.salesMonth === "5" ||
        item.salesMonth === "6"
      ) {
        calGP1(s, e, panel);
      } else if (
        item.salesMonth === "7" ||
        item.salesMonth === "8" ||
        item.salesMonth === "9"
      ) {
        calGP2(s, e, panel);
      } else if (
        item.salesMonth === "10" ||
        item.salesMonth === "11" ||
        item.salesMonth === "12"
      ) {
        calGP3(s, e, panel);
      } else {
        calGP4(s, e, panel);
      }
      calTotalRowSales(s, e, panel);
    }
  }

  if (binding === "costPercent" || binding === "salesAmount") {
    let planCost = 0;
    let item = s.rows[e.row].dataItem;
    if (
      item.costPercent !== null &&
      item.costPercent !== "" &&
      item.salesAmount !== null &&
      item.salesAmount !== ""
    ) {
      planCost = item.salesAmount * item.costPercent;
      s.rows[e.row].dataItem.planCost = planCost;
    }
    if (item.salesMonth === null) {
      s.rows[e.row].dataItem.gp1 = 0;
      s.rows[e.row].dataItem.gp2 = 0;
      s.rows[e.row].dataItem.gp3 = 0;
      s.rows[e.row].dataItem.gp4 = 0;
      s.rows[e.row].dataItem.gpPercent1 = 0;
      s.rows[e.row].dataItem.gpPercent2 = 0;
      s.rows[e.row].dataItem.gpPercent3 = 0;
      s.rows[e.row].dataItem.gpPercent4 = 0;
    } else {
      if (
        item.salesMonth === "4" ||
        item.salesMonth === "5" ||
        item.salesMonth === "6"
      ) {
        calGP1(s, e, panel);
      } else if (
        item.salesMonth === "7" ||
        item.salesMonth === "8" ||
        item.salesMonth === "9"
      ) {
        calGP2(s, e, panel);
      } else if (
        item.salesMonth === "10" ||
        item.salesMonth === "11" ||
        item.salesMonth === "12"
      ) {
        calGP3(s, e, panel);
      } else {
        calGP4(s, e, panel);
      }
      calTotalRowSales(s, e, panel);
    }
  }

  // Quarter 1
  if (binding === "month1") {
    let month1Sales = 0;
    for (let i = 0; i < s.rows.length - 1; i++) {
      let item = s.rows[i].dataItem;
      if (
        s.rows[i].dataItem.month1 !== null &&
        s.rows[i].dataItem.month1 !== ""
      ) {
        month1Sales += parseFloat(item.month1);
      }
    }
    panel.setCellData(3, 27, parseFloat(month1Sales).toFixed(0));
    calGP1(s, e, panel);
    calTotalRowSales(s, e, panel);
  }

  if (binding === "month2") {
    let month2Sales = 0;
    for (let i = 0; i < s.rows.length - 1; i++) {
      let item = s.rows[i].dataItem;
      if (
        s.rows[i].dataItem.month2 !== null &&
        s.rows[i].dataItem.month2 !== ""
      ) {
        month2Sales += parseFloat(item.month2);
      }
    }
    panel.setCellData(3, 28, parseFloat(month2Sales).toFixed(0));
    calGP1(s, e, panel);
    calTotalRowSales(s, e, panel);
  }

  if (binding === "month3") {
    let month3Sales = 0;
    for (let i = 0; i < s.rows.length - 1; i++) {
      let item = s.rows[i].dataItem;
      if (
        s.rows[i].dataItem.month3 !== null &&
        s.rows[i].dataItem.month3 !== ""
      ) {
        month3Sales += parseFloat(item.month3);
      }
    }
    panel.setCellData(3, 29, parseFloat(month3Sales).toFixed(0));
    calGP1(s, e, panel);
    calTotalRowSales(s, e, panel);
  }

  // Quarter 2
  if (binding === "month4") {
    let month4Sales = 0;
    for (let i = 0; i < s.rows.length - 1; i++) {
      let item = s.rows[i].dataItem;
      if (
        s.rows[i].dataItem.month4 !== null &&
        s.rows[i].dataItem.month4 !== ""
      ) {
        month4Sales += parseFloat(item.month4);
      }
    }
    panel.setCellData(3, 32, parseFloat(month4Sales).toFixed(0));
    calGP2(s, e, panel);
    calTotalRowSales(s, e, panel);
  }

  if (binding === "month5") {
    let month5Sales = 0;
    for (let i = 0; i < s.rows.length - 1; i++) {
      let item = s.rows[i].dataItem;
      if (
        s.rows[i].dataItem.month5 !== null &&
        s.rows[i].dataItem.month5 !== ""
      ) {
        month5Sales += parseFloat(item.month5);
      }
    }
    panel.setCellData(3, 33, parseFloat(month5Sales).toFixed(0));
    calGP2(s, e, panel);
    calTotalRowSales(s, e, panel);
  }

  if (binding === "month6") {
    let month6Sales = 0;
    for (let i = 0; i < s.rows.length - 1; i++) {
      let item = s.rows[i].dataItem;
      if (
        s.rows[i].dataItem.month6 !== null &&
        s.rows[i].dataItem.month6 !== ""
      ) {
        month6Sales += parseFloat(item.month6);
      }
    }
    panel.setCellData(3, 34, parseFloat(month6Sales).toFixed(0));
    calGP2(s, e, panel);
    calTotalRowSales(s, e, panel);
  }

  // Quarter 3
  if (binding === "month7") {
    let month7Sales = 0;
    for (let i = 0; i < s.rows.length - 1; i++) {
      let item = s.rows[i].dataItem;
      if (
        s.rows[i].dataItem.month7 !== null &&
        s.rows[i].dataItem.month7 !== ""
      ) {
        month7Sales += parseFloat(item.month7);
      }
    }
    panel.setCellData(3, 37, parseFloat(month7Sales).toFixed(0));
    calGP3(s, e, panel);
    calTotalRowSales(s, e, panel);
  }

  if (binding === "month8") {
    let month8Sales = 0;
    for (let i = 0; i < s.rows.length - 1; i++) {
      let item = s.rows[i].dataItem;
      if (
        s.rows[i].dataItem.month8 !== null &&
        s.rows[i].dataItem.month8 !== ""
      ) {
        month8Sales += parseFloat(item.month8);
      }
    }
    panel.setCellData(3, 38, parseFloat(month8Sales).toFixed(0));
    calGP3(s, e, panel);
    calTotalRowSales(s, e, panel);
  }

  if (binding === "month9") {
    let month9Sales = 0;
    for (let i = 0; i < s.rows.length - 1; i++) {
      let item = s.rows[i].dataItem;
      if (
        s.rows[i].dataItem.month9 !== null &&
        s.rows[i].dataItem.month9 !== ""
      ) {
        month9Sales += parseFloat(item.month9);
      }
    }
    panel.setCellData(3, 39, parseFloat(month9Sales).toFixed(0));
    calGP3(s, e, panel);
    calTotalRowSales(s, e, panel);
  }

  // Quarter 4
  if (binding === "month10") {
    let month10Sales = 0;
    for (let i = 0; i < s.rows.length - 1; i++) {
      let item = s.rows[i].dataItem;
      if (
        s.rows[i].dataItem.month10 !== null &&
        s.rows[i].dataItem.month10 !== ""
      ) {
        month10Sales += parseFloat(item.month10);
      }
    }
    panel.setCellData(3, 42, parseFloat(month10Sales).toFixed(0));
    calGP4(s, e, panel);
    calTotalRowSales(s, e, panel);
  }

  if (binding === "month11") {
    let month11Sales = 0;
    for (let i = 0; i < s.rows.length - 1; i++) {
      let item = s.rows[i].dataItem;
      if (
        s.rows[i].dataItem.month11 !== null &&
        s.rows[i].dataItem.month11 !== ""
      ) {
        month11Sales += parseFloat(item.month11);
      }
    }
    panel.setCellData(3, 43, parseFloat(month11Sales).toFixed(0));
    calGP4(s, e, panel);
    calTotalRowSales(s, e, panel);
  }

  if (binding === "month12") {
    let month12Sales = 0;
    for (let i = 0; i < s.rows.length - 1; i++) {
      let item = s.rows[i].dataItem;
      if (
        s.rows[i].dataItem.month12 !== null &&
        s.rows[i].dataItem.month12 !== ""
      ) {
        month12Sales += parseFloat(item.month12);
      }
    }
    panel.setCellData(3, 44, parseFloat(month12Sales).toFixed(0));
    calGP4(s, e, panel);
    calTotalRowSales(s, e, panel);
  }
}

export function calGP1(s, e, panel) {
  let item = s.rows[e.row].dataItem;
  let month1 = 0,
    month2 = 0,
    month3 = 0;
  if (item.month1 !== null && item.month1 !== "" && item.month1 !== undefined) {
    month1 = parseFloat(item.month1);
  } else {
    month1 = 0;
  }
  if (item.month2 !== null && item.month2 !== "" && item.month2 !== undefined) {
    month2 = parseFloat(item.month2);
  } else {
    month2 = 0;
  }
  if (item.month3 !== null && item.month3 !== "" && item.month3 !== undefined) {
    month3 = parseFloat(item.month3);
  } else {
    month3 = 0;
  }
  let cost =
    item.state === 1 || item.state === 2 ? item.planCost : item.actualCost;
  let gp1 = 0;
  let gpPercent1 = 0;

  if (month1 !== 0 || month2 !== 0 || month3 !== 0) {
    gp1 = month1 + month2 + month3 - cost;
    gpPercent1 = gp1 / (month1 + month2 + month3);
  }
  s.rows[e.row].dataItem.gp1 = parseFloat(gp1);
  s.rows[e.row].dataItem.gpPercent1 = gpPercent1 !== 0 ? gpPercent1 : "0%";
  sumAllGP1Row(s, panel);
}

export function sumAllGP1Row(s, panel) {
  let gp1Total = 0;
  for (var i = 0; i < s.rows.length - 1; i++) {
    let item = s.rows[i].dataItem;
    if (s.rows[i].dataItem.gp1 !== null) {
      gp1Total += parseFloat(item.gp1);
    }
  }
  panel.setCellData(3, 31, parseFloat(gp1Total).toFixed(2));
  calGPPercent(
    panel,
    30,
    gp1Total,
    panel.getCellData(3, 27),
    panel.getCellData(3, 28),
    panel.getCellData(3, 29)
  );
}

export function calGP2(s, e, panel) {
  let item = s.rows[e.row].dataItem;
  let month4 = 0,
    month5 = 0,
    month6 = 0;
  if (item.month4 !== null && item.month4 !== "" && item.month4 !== undefined) {
    month4 = parseFloat(item.month4);
  } else {
    month4 = 0;
  }
  if (item.month5 !== null && item.month5 !== "" && item.month5 !== undefined) {
    month5 = parseFloat(item.month5);
  } else {
    month5 = 0;
  }
  if (item.month6 !== null && item.month6 !== "" && item.month6 !== undefined) {
    month6 = parseFloat(item.month6);
  } else {
    month6 = 0;
  }

  let cost =
    item.state === 1 || item.state === 2 ? item.planCost : item.actualCost;
  let gp2 = 0;
  let gpPercent2 = 0;
  if (month4 !== 0 || month5 !== 0 || month6 !== 0) {
    gp2 = month4 + month5 + month6 - cost;
    gpPercent2 = gp2 / (month4 + month5 + month6);
  }
  s.rows[e.row].dataItem.gp2 = parseFloat(gp2);
  s.rows[e.row].dataItem.gpPercent2 = gpPercent2 !== 0 ? gpPercent2 : "0%";
  sumAllGP2Row(s, panel);
}

export function sumAllGP2Row(s, panel) {
  let gp2Total = 0;
  for (var i = 0; i < s.rows.length - 1; i++) {
    let item = s.rows[i].dataItem;
    if (s.rows[i].dataItem.gp2 !== null) {
      gp2Total += parseFloat(item.gp2);
    }
  }
  panel.setCellData(3, 36, parseFloat(gp2Total).toFixed(2));
  calGPPercent(
    panel,
    35,
    gp2Total,
    panel.getCellData(3, 32),
    panel.getCellData(3, 33),
    panel.getCellData(3, 34)
  );
}

export function calGP3(s, e, panel) {
  let item = s.rows[e.row].dataItem;
  let month7 = 0,
    month8 = 0,
    month9 = 0;
  if (item.month7 !== null && item.month7 !== "" && item.month7 !== undefined) {
    month7 = parseFloat(item.month7);
  } else {
    month7 = 0;
  }
  if (item.month8 !== null && item.month8 !== "" && item.month8 !== undefined) {
    month8 = parseFloat(item.month8);
  } else {
    month8 = 0;
  }
  if (item.month9 !== null && item.month9 !== "" && item.month9 !== undefined) {
    month9 = parseFloat(item.month9);
  } else {
    month9 = 0;
  }

  let cost =
    item.state === 1 || item.state === 2 ? item.planCost : item.actualCost;
  let gp3 = 0;
  let gpPercent3 = 0;
  if (month7 !== 0 || month8 !== 0 || month9 !== 0) {
    gp3 = month7 + month8 + month9 - cost;
    gpPercent3 = gp3 / (month7 + month8 + month9);
  }
  s.rows[e.row].dataItem.gp3 = parseFloat(gp3);
  s.rows[e.row].dataItem.gpPercent3 = gpPercent3 !== 0 ? gpPercent3 : "0%";
  sumAllGP3Row(s, panel);
}

export function sumAllGP3Row(s, panel) {
  let gp3Total = 0;
  for (var i = 0; i < s.rows.length - 1; i++) {
    let item = s.rows[i].dataItem;
    if (s.rows[i].dataItem.gp3 !== null) {
      gp3Total += parseFloat(item.gp3);
    }
  }
  panel.setCellData(3, 41, parseFloat(gp3Total).toFixed(2));
  calGPPercent(
    panel,
    40,
    gp3Total,
    panel.getCellData(3, 37),
    panel.getCellData(3, 38),
    panel.getCellData(3, 39)
  );
}

export function calGP4(s, e, panel) {
  let item = s.rows[e.row].dataItem;
  let month10 = 0,
    month11 = 0,
    month12 = 0;
  if (
    item.month10 !== null &&
    item.month10 !== "" &&
    item.month10 !== undefined
  ) {
    month10 = parseFloat(item.month10);
  } else {
    month10 = 0;
  }
  if (
    item.month11 !== null &&
    item.month11 !== "" &&
    item.month11 !== undefined
  ) {
    month11 = parseFloat(item.month11);
  } else {
    month11 = 0;
  }
  if (
    item.month12 !== null &&
    item.month12 !== "" &&
    item.month12 !== undefined
  ) {
    month12 = parseFloat(item.month12);
  } else {
    month12 = 0;
  }

  let cost =
    item.state === 1 || item.state === 2 ? item.planCost : item.actualCost;
  let gp4 = 0;
  let gpPercent4 = 0;
  if (month10 !== 0 || month11 !== 0 || month12 !== 0) {
    gp4 = month10 + month11 + month12 - cost;
    gpPercent4 = gp4 / (month10 + month11 + month12);
  }
  s.rows[e.row].dataItem.gp4 = parseFloat(gp4);
  s.rows[e.row].dataItem.gpPercent4 = gpPercent4 !== 0 ? gpPercent4 : "0%";
  sumAllGP4Row(s, panel);
}

export function sumAllGP4Row(s, panel) {
  let gp4Total = 0;
  for (var i = 0; i < s.rows.length - 1; i++) {
    let item = s.rows[i].dataItem;
    if (s.rows[i].dataItem.gp4 !== null) {
      gp4Total += parseFloat(item.gp4);
    }
  }
  panel.setCellData(3, 46, parseFloat(gp4Total).toFixed(2));
  calGPPercent(
    panel,
    45,
    gp4Total,
    panel.getCellData(3, 42),
    panel.getCellData(3, 43),
    panel.getCellData(3, 44)
  );
}

export function calColTotalGP(panel, totalSales, totalGP) {
  let totalGPPer = (parseFloat(totalGP) / parseFloat(totalSales)) * 100;
  if (!isNaN(totalGPPer)) {
    panel.setCellData(3, 25, parseFloat(totalGPPer).toFixed(2) + "%");
  } else {
    panel.setCellData(3, 25, 0 + "%");
  }
}

export function calGPPercent(
  panel,
  col,
  colGPTotal,
  month1Sales,
  month2Sales,
  month3Sales
) {
  let colGPPerTotal =
    (Math.abs(colGPTotal) /
      (parseFloat(month1Sales) +
        parseFloat(month2Sales) +
        parseFloat(month3Sales))) *
    100;
  if (colGPPerTotal !== null && !isNaN(colGPPerTotal)) {
    panel.setCellData(3, col, parseFloat(colGPPerTotal).toFixed(2) + "%");
  } else {
    panel.setCellData(3, col, 0 + "%");
  }
}

export function calTotalRowSales(s, e, panel) {
  let item = s.rows[e.row].dataItem;
  let month1 = 0,
    month2 = 0,
    month3 = 0;
  let month4 = 0,
    month5 = 0,
    month6 = 0;
  let month7 = 0,
    month8 = 0,
    month9 = 0;
  let month10 = 0,
    month11 = 0,
    month12 = 0;
  if (item.month1 !== null && item.month1 !== "" && item.month1 !== undefined) {
    month1 = parseFloat(item.month1);
  }
  if (item.month2 !== null && item.month2 !== "" && item.month2 !== undefined) {
    month2 = parseFloat(item.month2);
  }
  if (item.month3 !== null && item.month3 !== "" && item.month3 !== undefined) {
    month3 = parseFloat(item.month3);
  }
  if (item.month4 !== null && item.month4 !== "" && item.month4 !== undefined) {
    month4 = parseFloat(item.month4);
  }
  if (item.month5 !== null && item.month5 !== "" && item.month5 !== undefined) {
    month5 = parseFloat(item.month5);
  }
  if (item.month6 !== null && item.month6 !== "" && item.month6 !== undefined) {
    month6 = parseFloat(item.month6);
  }
  if (item.month7 !== null && item.month7 !== "" && item.month7 !== undefined) {
    month7 = parseFloat(item.month7);
  }
  if (item.month8 !== null && item.month8 !== "" && item.month8 !== undefined) {
    month8 = parseFloat(item.month8);
  }
  if (item.month9 !== null && item.month9 !== "" && item.month9 !== undefined) {
    month9 = parseFloat(item.month9);
  }
  if (
    item.month10 !== null &&
    item.month10 !== "" &&
    item.month10 !== undefined
  ) {
    month10 = parseFloat(item.month10);
  }
  if (
    item.month11 !== null &&
    item.month11 !== "" &&
    item.month11 !== undefined
  ) {
    month11 = parseFloat(item.month11);
  }
  if (
    item.month12 !== null &&
    item.month12 !== "" &&
    item.month12 !== undefined
  ) {
    month12 = parseFloat(item.month12);
  }
  let totalSales =
    month1 +
    month2 +
    month3 +
    month4 +
    month5 +
    month6 +
    month7 +
    month8 +
    month9 +
    month10 +
    month11 +
    month12;
  s.rows[e.row].dataItem.totalRowSales = parseFloat(totalSales);

  let colTotalSales = 0;
  for (let i = 0; i < s.rows.length - 1; i++) {
    let item = s.rows[i].dataItem;
    if (s.rows[i].dataItem.totalRowSales !== null) {
      colTotalSales += parseFloat(item.totalRowSales);
    }
  }
  panel.setCellData(3, 24, parseFloat(colTotalSales).toFixed(2));
  calTotalRowGp(s, e, panel, colTotalSales);
}

export function calTotalRowGp(s, e, panel, colTotalSales) {
  let item = s.rows[e.row].dataItem;
  let gp1 = 0,
    gp2 = 0,
    gp3 = 0,
    gp4 = 0;
  if (item.gp1 != null) {
    gp1 = parseFloat(item.gp1);
  }

  if (item.gp2 != null) {
    gp2 = parseFloat(item.gp2);
  }

  if (item.gp3 != null) {
    gp3 = parseFloat(item.gp3);
  }

  if (item.gp4 != null) {
    gp4 = parseFloat(item.gp4);
  }
  let totalGP = gp1 + gp2 + gp3 + gp4;
  s.rows[e.row].dataItem.totalRowGP = parseFloat(totalGP);

  let colTotalGP = 0;
  for (let i = 0; i < s.rows.length - 1; i++) {
    let item = s.rows[i].dataItem;
    if (s.rows[i].dataItem.totalRowGP !== null) {
      colTotalGP += parseFloat(item.totalRowGP);
    }
  }
  panel.setCellData(3, 26, parseFloat(colTotalGP).toFixed(2));
  calTotalRowGPPer(s, e);
  calColTotalGP(panel, colTotalSales, colTotalGP);
}

export function calTotalRowGPPer(s, e) {
  let item = s.rows[e.row].dataItem;
  let totalGPPer = parseFloat(item.totalRowGP) / parseFloat(item.totalRowSales);
  if (!isNaN(totalGPPer)) {
    s.rows[e.row].dataItem.totalRowGPPercent = parseFloat(totalGPPer);
  } else {
    s.rows[e.row].dataItem.totalRowGPPercent = 0;
  }
}
