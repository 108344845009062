import {
  Flex,
  Group,
  NumberInput,
  SegmentedControl,
  Select,
} from "@mantine/core";
import React from "react";
import { ORDERLIST } from "../../../../utilities/constants";
import {
  SELECTITEMCOMPONENT,
  SELECTITEMLIST,
} from "../../../../utilities/utilities";
import PropTypes from "prop-types";

const OrderBy = (props) => {
  const { orders, tableName, tableList, handleOrdersChange } = props;

  let filteredColumnList = [];

  if (tableName) {
    filteredColumnList = SELECTITEMLIST(tableList, tableName);
  }

  return (
    <Flex
      mih={50}
      p={5}
      bg="#eee"
      justify="space-between"
      align="flex-start"
      direction="row"
      wrap="wrap"
    >
      <Group>
        <Select
          placeholder="Choose"
          label="Order By"
          searchable
          itemComponent={SELECTITEMCOMPONENT}
          maxDropdownHeight={200}
          data={[
            ...filteredColumnList,
            { label: "*", value: "*", type: "text" },
            { label: "COUNT(*)", value: "COUNT(*)", type: "text" },
          ]}
          filter={(value, item) =>
            item.label.toLowerCase().includes(value.toLowerCase().trim())
          }
          value={orders.orderBy}
          onChange={(value) => handleOrdersChange("orderBy", value)}
        />
        <SegmentedControl
          sx={{ alignSelf: "flex-end" }}
          color="indigo"
          size="sm"
          disabled={orders.orderBy === ""}
          value={orders.ordering}
          data={ORDERLIST}
          onChange={(value) => handleOrdersChange("ordering", value)}
        />
        <NumberInput
          label="Limit - optional"
          value={orders.limit}
          onChange={(value) => handleOrdersChange("limit", value)}
        />
      </Group>
    </Flex>
  );
};

export default OrderBy;

OrderBy.propTypes = {
  orders: PropTypes.any,
  tableName: PropTypes.any,
  tableList: PropTypes.any,
  handleOrdersChange: PropTypes.any,
};
