import "bootstrap/dist/css/bootstrap.css";
import "@grapecity/wijmo.styles/wijmo.css";
import React from "react";
import * as wjcCore from "@grapecity/wijmo";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import { BiDownload } from "react-icons/bi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import moment from "moment/moment";
import {
  BC_REPORT_Ver_LIST,
  BC_REPORT_Lock_File_Filter,
  BC_REPORT_FINAL_File_Filter,
  BC_REPORT_FinalVer_LIST,
  TOKEN,
  SALES_PLAN_LOCK_CONFIRM,
  SALES_PLAN_FINALLOCK_CONFIRM,
} from "../../constants/api.path";
import { Spinner } from "reactstrap";
import InfoModal from "../../modalPopups/infoModal";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

class ReportFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flex: null,
      versionList: [],
      folderList: [
        { id: 1, name: "Lock" },
        { id: 2, name: "Final Lock" },
      ],
      folderName: "Lock",
      monthList: [
        { id: 1, name: "Jan" },
        { id: 2, name: "Feb" },
        { id: 3, name: "Mar" },
        { id: 4, name: "Apr" },
        { id: 5, name: "May" },
        { id: 6, name: "Jun" },
        { id: 7, name: "Jul" },
        { id: 8, name: "Aug" },
        { id: 9, name: "Sep" },
        { id: 10, name: "Oct" },
        { id: 11, name: "Nov" },
        { id: 12, name: "Dec" },
      ],
      monthName: moment().month(new Date().getMonth()).format("MMM"),
      yearMonth:
        new Date().getFullYear() +
        "-" +
        ("0" + (new Date().getMonth() + 1)).slice(-2),
      versionName: "",
      verDisable: false,
      monthDisable: false,
      downDisable: true,
      isLoading: false,
      blobFile: "",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.getVersionList(
      this.state.monthName,
      this.state.folderName,
      this.state.yearMonth
    );
  }

  getVersionList(monthName, folderName, yearMonth) {
    let url;
    if (folderName === "Lock") {
      url = BC_REPORT_Ver_LIST;
    } else {
      url = BC_REPORT_FinalVer_LIST;
    }
    fetch(url + "/monthFolder/" + yearMonth + "/monthName/" + monthName, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((response) => {
        if (response.result) {
          this.setState({ verDisable: false });
          this.setState({ versionList: response.data.versionList });
        } else {
          this.setState({ versionList: null });
          this.setState({ verDisable: true });
          this.setState({ resultMessage: response.message }, () => {
            this.setState({ showInfoModal: true }, () => {});
          });
        }
      });
  }

  getReportFile(yearMonth, folderName, monthName, versionName) {
    this.setState({ isLoading: true });
    if (
      yearMonth !== undefined &&
      folderName !== null &&
      monthName !== undefined &&
      versionName !== undefined
    ) {
      let url;
      if (folderName === "Lock") {
        url = BC_REPORT_Lock_File_Filter;
      } else {
        url = BC_REPORT_FINAL_File_Filter;
      }
      fetch(
        url +
          "/yearMonth/" +
          yearMonth +
          "/monthName/" +
          monthName +
          "/versionName/" +
          versionName,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      )
        .then(async (response) => {
          return response.blob();
        })
        .then((response) => {
          this.setState({ blobFile: response });
          this.state.flex.loadAsync(response);
          this.setState({ downDisable: false });
          this.setState({ isLoading: false });
        });
    }
  }

  componentDidUpdate() {}

  handleChange(e) {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;

    const selectedValue = { ...this.state.selectedArray };
    selectedValue[fieldName] = fieldValue;

    if (selectedValue.folderName !== undefined) {
      this.setState({ folderName: selectedValue.folderName });
      this.getVersionList(
        this.state.monthName,
        selectedValue.folderName,
        this.state.yearMonth
      );
    }

    if (selectedValue.monthName !== undefined) {
      this.setState({ monthName: selectedValue.monthName });
      this.getVersionList(
        selectedValue.monthName,
        this.state.folderName,
        this.state.yearMonth
      );
    }

    if (selectedValue.yearMonth !== undefined) {
      this.setState({ yearMonth: selectedValue.yearMonth });
      this.getVersionList(
        this.state.monthName,
        this.state.folderName,
        selectedValue.yearMonth
      );
    }

    if (selectedValue.versionName !== undefined) {
      this.setState({ versionName: selectedValue.versionName });
      this.getReportFile(
        this.state.yearMonth,
        this.state.folderName,
        this.state.monthName,
        selectedValue.versionName
      );
    }
  }

  render() {
    return (
      <div className="container-fluid">
        {this.state.isLoading && (
          <div className="loader">
            <Spinner color="white" />
          </div>
        )}
        <div className="row" style={{ margin: "0" }}>
          <div
            className="col-md-auto "
            style={{ paddingLeft: "0.3vw", paddingRight: "0" }}
          >
            <input
              type="month"
              name="yearMonth"
              className="form-control"
              min="2010-04"
              value={this.state.yearMonth}
              onChange={this.handleChange}
              required
            />
          </div>
          <div className="col-md-auto " style={{ paddingRight: "0" }}>
            <select
              name="folderName"
              className="form-select"
              onChange={this.handleChange}
              required
            >
              {this.state.folderList !== null &&
                this.state.folderList.map((item) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-auto " style={{ paddingRight: "0" }}>
            <select
              name="monthName"
              className="form-select"
              onChange={this.handleChange}
              required
              value={this.state.monthName}
            >
              {/* <option key={0} value="" hidden>
                Select Sales Month
              </option> */}
              {this.state.monthList !== null &&
                this.state.monthList.map((item) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-auto" style={{ paddingRight: "0" }}>
            <select
              name="versionName"
              className="form-select"
              onChange={this.handleChange}
              required
              disabled={this.state.verDisable}
            >
              <option key={0} value="" hidden>
                Select Version
              </option>
              {this.state.versionList !== null &&
                this.state.versionList.map((item) => (
                  <option key={item.verName} value={item.verName}>
                    {item.verName}
                  </option>
                ))}
            </select>
          </div>
          <div className="col-md-auto" style={{ paddingLeft: "0.3vw" }}>
            <button
              className="actionIconOutline bg-blue"
              disabled={this.state.downDisable}
              onClick={this.download.bind(this)}
            >
              <BiDownload id="download" data-tooltip-content="Download" />
              <ReactTooltip place="right" anchorId="download" />
            </button>
          </div>
          <InfoModal
            show={this.state.showInfoModal}
            title={this.state.resultMessage}
            onSubmit={() => {
              this.setState({ showInfoModal: false }, () => {});
            }}
            onHide={() => {
              this.setState({ showInfoModal: false }, () => {});
              this.setState({ resConfirm: false }, () => {});
            }}
          />
        </div>

        <wjGridSheet.FlexSheet
          initialized={this.initializeFlexSheet.bind(this)}
          style={{
            height: "75vh",
            marginTop: "5px",
            marginLeft: "3px",
          }}
          id="reportFilter"
        >
          <wjGridSheet.Sheet name="Report Filter" />
        </wjGridSheet.FlexSheet>
      </div>
    );
  }

  initializeFlexSheet(flex) {
    this.setState({
      flex: flex,
    });
  }

  download() {
    let clickedBtn = this.state.folderName;
    let fileName;
    // let selectedMonth = new Date(this.state.yearMonth).getMonth();
    let selectedMonth;

    this.state.monthList.map((month) => {
      if (month.name === this.state.monthName) {
        selectedMonth = month.id;
      }
    });
    if (clickedBtn === "Lock") {
      fetch(SALES_PLAN_LOCK_CONFIRM + "/salesPlanMonth/" + selectedMonth, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
        .then(async (response) => {
          return response.json();
        })
        .then((result) => {
          fileName =
            result.fiscalYr +
            moment()
              .month(selectedMonth - 1)
              .format("MMM") +
            " Budget & Sale Plan_" +
            this.state.yearMonth.replace("-", "") +
            moment().format("DD") +
            "_" +
            this.state.versionName;
          if (fileName !== null && fileName !== undefined) {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(this.state.blobFile);
            link.download = fileName;
            link.click();
          }
        });
    } else {
      fetch(SALES_PLAN_FINALLOCK_CONFIRM + "/salesPlanMonth/" + selectedMonth, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
        .then(async (response) => {
          return response.json();
        })
        .then((result) => {
          fileName =
            result.fiscalYr +
            moment()
              .month(selectedMonth - 1)
              .format("MMM") +
            " Budget & Sale Plan_" +
            this.state.yearMonth.replace("-", "") +
            moment().format("DD") +
            "(Final_ver)_" +
            this.state.versionName;
          if (fileName !== null && fileName !== undefined) {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(this.state.blobFile);
            link.download = fileName;
            link.click();
          }
        });
    }
  }
}

export default ReportFilter;
