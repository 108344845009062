import "bootstrap/dist/css/bootstrap.css";
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjcCore from "@grapecity/wijmo";
import * as React from "react";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import { BUSINESS_SECTOR_LIST_URL, TOKEN } from "../../constants/api.path";
import data from "./SID007_RP.json";
import "../../assets/css/salesAndGp.css";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

class SalesAndGp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flex: null,
      sectorList: [],
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    fetch(BUSINESS_SECTOR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ sectorList: result.data.sectorList });
      });
  }

  handleChange(e) {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;

    const selectedValue = { ...this.state.selectedArray };
    selectedValue[fieldName] = fieldValue;

    this.setState({ selectedArray: selectedValue });
  }

  render() {
    return (
      <div className="container-fluid salesAndGp">
        <div className="row">
          <div className="col-md-3">
            <select
              name="sectorId"
              className="form-select"
              onChange={this.handleChange}
            >
              <option key={0} value="" hidden>
                Business Sector
              </option>
              <option key={"all"} value="all">
                All
              </option>
              {this.state.sectorList.map((item) => (
                <option key={item.sectorId} value={item.sectorId}>
                  {item.sectorName}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-1">
            <button type="button" className="btn btn-primary" value="Save">
              Save
            </button>
          </div>
        </div>
        <wjGridSheet.FlexSheet
          initialized={this.initializeFlexSheet.bind(this)}
        >
          <wjGridSheet.Sheet
            name="Sales and Gp Distribution"
            rowCount={20}
            columnCount={25}
          ></wjGridSheet.Sheet>
        </wjGridSheet.FlexSheet>
      </div>
    );
  }
  initializeFlexSheet(flex) {
    flex.deferUpdate(() => {
      this._generateBasicOperatorsSheet(flex);

      flex.selectedSheetIndex = 0;

      flex.columnHeaders.rows.removeAt(-1);
      flex.rowHeaders.columns.removeAt(-1);

      flex.setCellData(3, 2, data.data.SalesGpTotal[0].totalSales);
      flex.setCellData(3, 3, data.data.SalesGpTotal[0].totalGPPercent);
      flex.setCellData(3, 4, data.data.SalesGpTotal[0].totalGP);
      flex.setCellData(3, 5, data.data.SalesGpTotal[0].q1Apr);
      flex.setCellData(3, 6, data.data.SalesGpTotal[0].q1May);
      flex.setCellData(3, 7, data.data.SalesGpTotal[0].q1Jun);
      flex.setCellData(3, 8, data.data.SalesGpTotal[0].q1GPPercent);
      flex.setCellData(3, 9, data.data.SalesGpTotal[0].q1GP);
      flex.setCellData(3, 10, data.data.SalesGpTotal[0].q2Jul);
      flex.setCellData(3, 11, data.data.SalesGpTotal[0].q2Aug);
      flex.setCellData(3, 12, data.data.SalesGpTotal[0].q2Sep);
      flex.setCellData(3, 13, data.data.SalesGpTotal[0].q2GPPercent);
      flex.setCellData(3, 14, data.data.SalesGpTotal[0].q2GP);
      flex.setCellData(3, 15, data.data.SalesGpTotal[0].q3Oct);
      flex.setCellData(3, 16, data.data.SalesGpTotal[0].q3Nov);
      flex.setCellData(3, 17, data.data.SalesGpTotal[0].q3Dec);
      flex.setCellData(3, 18, data.data.SalesGpTotal[0].q3GPPercent);
      flex.setCellData(3, 19, data.data.SalesGpTotal[0].q3GP);
      flex.setCellData(3, 20, data.data.SalesGpTotal[0].q4Jan);
      flex.setCellData(3, 21, data.data.SalesGpTotal[0].q4Feb);
      flex.setCellData(3, 22, data.data.SalesGpTotal[0].q4Mar);
      flex.setCellData(3, 23, data.data.SalesGpTotal[0].q4GPPercent);
      flex.setCellData(3, 24, data.data.SalesGpTotal[0].q4GP);

      let r = 4;
      //let len = parseInt(data.data.SalesGpLists.length) + 5;

      for (let row = 0; row < data.data.SalesGpLists.length; row++) {
        flex.setCellData(r, 0, data.data.SalesGpLists[row].businessSector);
        flex.setCellData(r, 1, data.data.SalesGpLists[row].projectName);
        flex.setCellData(r, 2, data.data.SalesGpLists[row].totalSales);
        flex.setCellData(r, 3, data.data.SalesGpLists[row].totalGPPercent);
        flex.setCellData(r, 4, data.data.SalesGpLists[row].totalGP);
        flex.setCellData(r, 5, data.data.SalesGpLists[row].q1Apr);
        flex.setCellData(r, 6, data.data.SalesGpLists[row].q1May);
        flex.setCellData(r, 7, data.data.SalesGpLists[row].q1Jun);
        flex.setCellData(r, 8, data.data.SalesGpLists[row].q1GPPercent);
        flex.setCellData(r, 9, data.data.SalesGpLists[row].q1GP);
        flex.setCellData(r, 10, data.data.SalesGpLists[row].q2Jul);
        flex.setCellData(r, 11, data.data.SalesGpLists[row].q2Aug);
        flex.setCellData(r, 12, data.data.SalesGpLists[row].q2Sep);
        flex.setCellData(r, 13, data.data.SalesGpLists[row].q2GPPercent);
        flex.setCellData(r, 14, data.data.SalesGpLists[row].q2GP);
        flex.setCellData(r, 15, data.data.SalesGpLists[row].q3Oct);
        flex.setCellData(r, 16, data.data.SalesGpLists[row].q3Nov);
        flex.setCellData(r, 17, data.data.SalesGpLists[row].q3Dec);
        flex.setCellData(r, 18, data.data.SalesGpLists[row].q3GPPercent);
        flex.setCellData(r, 19, data.data.SalesGpLists[row].q3GP);
        flex.setCellData(r, 20, data.data.SalesGpLists[row].q4Jan);
        flex.setCellData(r, 21, data.data.SalesGpLists[row].q4Feb);
        flex.setCellData(r, 22, data.data.SalesGpLists[row].q4Mar);
        flex.setCellData(r, 23, data.data.SalesGpLists[row].q4GPPercent);
        flex.setCellData(r, 24, data.data.SalesGpLists[row].q4GP);

        r++;
      }
    });
  }

  _generateBasicOperatorsSheet(flexSheet) {
    flexSheet.setCellData(0, 0, "Business Sectors");
    flexSheet.setCellData(0, 1, "Project Name");
    flexSheet.setCellData(0, 2, "FY22 Total(MMK)");
    flexSheet.setCellData(0, 5, "1Q2022");
    flexSheet.setCellData(0, 10, "2Q2022");
    flexSheet.setCellData(0, 15, "3Q2022");
    flexSheet.setCellData(0, 20, "4Q2022");
    flexSheet.setCellData(2, 1, "Total");
    flexSheet.setCellData(2, 2, "Sales");
    flexSheet.setCellData(2, 3, "Gp%");
    flexSheet.setCellData(2, 4, "Gp");
    flexSheet.setCellData(2, 5, "April");
    flexSheet.setCellData(2, 6, "May");
    flexSheet.setCellData(2, 7, "June");
    flexSheet.setCellData(2, 8, "GP%");
    flexSheet.setCellData(2, 9, "Gp");
    flexSheet.setCellData(2, 10, "July");
    flexSheet.setCellData(2, 11, "Agust");
    flexSheet.setCellData(2, 12, "September");
    flexSheet.setCellData(2, 13, "GP%");
    flexSheet.setCellData(2, 14, "Gp");
    flexSheet.setCellData(2, 15, "October");
    flexSheet.setCellData(2, 16, "November");
    flexSheet.setCellData(2, 17, "December");
    flexSheet.setCellData(2, 18, "GP%");
    flexSheet.setCellData(2, 19, "Gp");
    flexSheet.setCellData(2, 20, "January");
    flexSheet.setCellData(2, 21, "February");
    flexSheet.setCellData(2, 22, "March");
    flexSheet.setCellData(2, 23, "GP%");
    flexSheet.setCellData(2, 24, "Gp");

    flexSheet.applyCellsStyle(
      {
        fontWeight: "bold",
      },
      [new wjcGrid.CellRange(0, 0, 3, 25)]
    );

    flexSheet.mergeRange(new wjcGrid.CellRange(0, 0, 3, 0));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 1, 1, 1));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 2, 1, 4));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 5, 1, 9));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 10, 1, 14));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 15, 1, 19));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 20, 1, 24));
    flexSheet.mergeRange(new wjcGrid.CellRange(2, 1, 3, 1));

    flexSheet.columns[0].width = 200;
  }
}
export default SalesAndGp;
