import React from "react";

export default function ForgetPassword() {
  const [email, setEmail] = React.useState("");
  return (
    <div className="row justify-content-center main--body" id="main-body">
      <div className="col-12 col-md-6">
        <div className="card">
          <div className="card-header">Forget Password</div>
          <div className="card-body">
            <div className="row mb-3">
              <label className="col-4 col-form-label">Email Address</label>
              <div className="col-8 col-md-6">
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  required
                  autoFocus
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="row mb-3 justify-content-end">
              <div className="col-3">
                <button className="btn btn-primary">Get Reset Link</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
