import React, { useEffect, useState } from "react";

import { isArray } from "@grapecity/wijmo";
import { Palettes } from "@grapecity/wijmo.chart";
import "@grapecity/wijmo.chart.render";
import {
  FlexChart,
  FlexChartAxis,
  FlexChartDataLabel,
  FlexChartLegend,
  FlexChartSeries,
} from "@grapecity/wijmo.react.chart";

import { FlexChartAnimation } from "@grapecity/wijmo.react.chart.animation";
import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";

import { DataConveter } from "../../../utilities/utilities";
import ErrorWidget from "../error/error-widget";
import { useMemo } from "react";
import PropTypes from "prop-types";
import { useDisclosure } from "@mantine/hooks";
import LoadingComponents from "../../common/loading";
import axios from "axios";

function GetRandomPalette() {
  let palettes = Object.keys(Palettes).filter((prop) =>
    isArray(Palettes[prop])
  );
  let rand = Math.floor(Math.random() * palettes.length);
  return Palettes[palettes[rand]];
}

export default function AreaChart(props) {
  const { i, datasources, width, height, chartInitalized } = props;
  const [palette] = useState(GetRandomPalette());
  const [datasource, setDatasource] = useState([]);
  const [isLoading, loadingHandlers] = useDisclosure(true);
  const [isError, setIsError] = useState(false);
  const [serverData, setServerData] = useState([]);

  const memoizedDatasources = useMemo(() => datasources, [datasources]);
  const fetchData = async (path) => {
    try {
      const response = await axios.get(`json/${path}.json`);
      setServerData(DataConveter(response.data.metadata));
      setDatasource(datasources);
      loadingHandlers.close();
    } catch (error) {
      setIsError(true);
      loadingHandlers.close();
    }
  };

  useEffect(() => {
    fetchData(i).then(({ data, error }) => {
      if (error) {
        setIsError(true);
        loadingHandlers.close();
      } else {
        setServerData(DataConveter(data.metadata));
        setDatasource(datasources);
        loadingHandlers.close();
      }
    });
  }, [memoizedDatasources]);

  if (isLoading)
    return (
      <div className="container-fluid">
        <LoadingComponents />
      </div>
    );

  if (isError) {
    return <ErrorWidget />;
  }

  return (
    <div className="container-fluid">
      <FlexChart
        initialized={chartInitalized}
        chartType="Area"
        header={datasource.header}
        bindingX={datasource.configure.bindingX}
        itemsSource={serverData}
        palette={palette}
        isAnimated={true}
        legendToggle={datasource.settings.legendToggle}
        stacking={datasource.settings.stacked}
        style={{ width: width, height: height }}
      >
        {datasource.configure.bindingY.map((item, index) => (
          <FlexChartSeries
            name={item}
            binding={item}
            key={`area-chart-${item}-${index}`}
          ></FlexChartSeries>
        ))}
        <FlexChartAxis
          wjProperty="axisX"
          labelAngle={datasource.settings.labelAngle}
        ></FlexChartAxis>
        <FlexChartAxis
          title={datasource.settings.bindingYLabel}
          wjProperty="axisY"
        />
        <FlexChartLegend position={datasource.settings.legendPosition} />
        <FlexChartDataLabel
          position={datasource.settings.dataLabelPosition}
          content="{y}"
        />
        <FlexChartAnimation animationMode="Point" />
      </FlexChart>
    </div>
  );
}

AreaChart.propTypes = {
  i: PropTypes.any,
  datasources: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  chartInitalized: PropTypes.any,
};
