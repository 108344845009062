import "bootstrap/dist/css/bootstrap.css";
import "@grapecity/wijmo.styles/wijmo.css";

import React from "react";
import * as wjcCore from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import {
  BUSINESS_SECTOR_LIST_URL,
  FISCAL_YEAR_LIST_URL,
  QUARTER_LIST,
  SALES_PLAN_BULK_UPDATE_URL,
  TOKEN,
} from "../../constants/api.path";
import SuccessModal from "../../modalPopups/successModal";
import { BiSave } from "react-icons/bi";
import { MdDownload } from "react-icons/md";
import { Tooltip as ReactTooltip } from "react-tooltip";
import cacheUpdate from "../cacheUpdate/cacheUpdate";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

export default class SalesPlanUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      flex: null,
      error: false,
      fiscalList: [],
      showSuccessModal: "",
      resultMessage: "",
      selectedArray: [],
      tabs: [
        { id: 1, name: "Sales Plan New Upload" },
        {
          id: 2,
          name: "Sales Plan Bulk Update",
        },
      ],
      active: "Sales Plan New Upload",
      columns: [
        { name: "Order Number", binding: "orderNumber", value: true },
        { name: "Project Name", binding: "projectName", value: true },
        { name: "Sales Month", binding: "salesMonth", value: true },
        { name: "Status", binding: "status" },
        { name: "Customer Group", binding: "customerGroup" },
        { name: "Business Sector", binding: "sectorId" },
        { name: "State", binding: "state" },
        { name: "PO Currency", binding: "poCurrencyAmount" },
        { name: "PO Exchange Rate", binding: "poExchangeRate" },
        { name: "PO Amount", binding: "poAmount" },
        { name: "Sales Currency", binding: "salesCurrencyAmount" },
        { name: "Sales Exchange Rate", binding: "salesExchangeRate" },
        { name: "Sales Amount", binding: "salesAmount" },
        { name: "Cost %", binding: "costPercent" },
      ],
      customerList: [
        { id: 1, name: "NESIC" },
        { id: 2, name: "Others" },
      ],
      rankList: [
        { id: 1, name: "A" },
        { id: 2, name: "B" },
        { id: 3, name: "C" },
        { id: 4, name: "D" },
        { id: 5, name: "E" },
      ],
    };
    this.style = {
      display: "none",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;

    const selectedValue = { ...this.state.selectedArray };
    selectedValue[fieldName] = fieldValue;

    this.setState({ selectedArray: selectedValue }, () => {});
  }

  componentDidMount() {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ fiscalList: result.data.fiscalYearList });
        let yearName = [];
        result.data.fiscalYearList.forEach(function (fYear) {
          yearName.push(fYear.fiscalYear);
        });
        this.setState({ fYName: yearName });
      });

    fetch(BUSINESS_SECTOR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ sectorList: result.data.sectorList });
        let sectorName = [];
        result.data.sectorList.forEach(function (sector) {
          sectorName.push(sector.sectorName);
        });
        this.setState({ sectName: sectorName });
      });

    fetch(QUARTER_LIST, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ quarterList: result.data.quarterList });
        let quarterName = [];
        result.data.quarterList.forEach(function (quarter) {
          quarterName.push(quarter.quarterName);
        });
        this.setState({ quartName: quarterName });
      });
  }

  componentDidUpdate() {}

  render() {
    return (
      <div className="container-fluid " style={{ padding: "0" }}>
        <div className="row justify-content-end" style={{ margin: "0" }}>
          <div className="col-auto">
            <div className="row">
              <div className="col-md-auto" style={{ padding: "0" }}>
                <select
                  name="fiscalYear"
                  className="form-select"
                  onChange={this.handleChange}
                >
                  <option value="" hidden>
                    Sales Plan Year
                  </option>
                  {this.state.fiscalList.map((item, index) => (
                    <option key={index + 1} value={item.fiscalYearId}>
                      {item.fiscalYear}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="col-md-auto"
                style={{ paddingLeft: "0.4vw", paddingRight: "0" }}
              >
                <select
                  name="salesPlanMonth"
                  className="form-select"
                  onChange={this.handleChange}
                >
                  <option value="" hidden>
                    Sales Plan Month
                  </option>
                  <option key={1} value={4}>
                    Apr
                  </option>
                  <option key={2} value={5}>
                    May
                  </option>
                  <option key={3} value={6}>
                    Jun
                  </option>
                  <option key={4} value={7}>
                    Jul
                  </option>
                  <option key={5} value={8}>
                    Aug
                  </option>
                  <option key={6} value={9}>
                    Sep
                  </option>
                  <option key={7} value={10}>
                    Oct
                  </option>
                  <option key={8} value={11}>
                    Nov
                  </option>
                  <option key={9} value={12}>
                    Dec
                  </option>
                  <option key={10} value={1}>
                    Jan
                  </option>
                  <option key={11} value={2}>
                    Feb
                  </option>
                  <option key={12} value={3}>
                    Mar
                  </option>
                </select>
              </div>
              <div
                className="col-md-auto"
                style={{ paddingLeft: "0.4vw", paddingRight: "0" }}
              >
                <button
                  className="actionIconOutline save bg-blue"
                  id="saveButton"
                  data-tooltip-content="Save"
                  onClick={() => {
                    this.getUpdateData();
                  }}
                >
                  <BiSave />
                  <ReactTooltip place="left" anchorId="saveButton" />
                </button>
              </div>
              <div className="col-md-auto" style={{ padding: "0" }}>
                <button
                  className="actionIconOutline download bg-blue"
                  onClick={this.download.bind(this)}
                  id="downloadButton"
                  data-tooltip-content="Download"
                >
                  <MdDownload />
                  <ReactTooltip place="left" anchorId="downloadButton" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-end">
          <div className="col-3" style={{ marginRight: "70px" }}>
            {this.state.error && (
              <span className="error">{this.errorMessage}</span>
            )}
          </div>
        </div>
        <wjGridSheet.FlexSheet
          initialized={this.initializeFlexSheetUpdate.bind(this)}
          style={{
            height: "540px",
          }}
          id="salesPlanSheet"
        >
          <wjGridSheet.Sheet
            name="New Sheet"
            columnCount={this.state.columns.length}
          />
        </wjGridSheet.FlexSheet>
        <SuccessModal
          show={this.state.showSuccessModal}
          title={this.state.resultMessage}
          onSubmit={() => {
            this.setState({ showSuccessModal: false }, () => {});
          }}
          onHide={() => {
            this.setState({ showSuccessModal: false }, () => {});
          }}
        />
      </div>
    );
  }

  download() {
    let fileName;
    if (this.state.fileName) {
      fileName = this.state.fileName;
    } else {
      fileName = "FlexSheet.xlsx";
    }
    this.state.flex.saveAsync(fileName);
  }

  getUpdateData() {
    let updateRow = [],
      error = false;
    if (
      !this.state.selectedArray.fiscalYear &&
      !this.state.selectedArray.salesPlanMonth
    ) {
      this.setState({ error: true });
      error = true;
      this.errorMessage =
        "Please select both sales plan year and sales plan month.";
    } else if (!this.state.selectedArray.fiscalYear) {
      this.setState({ error: true });
      error = true;
      this.errorMessage = "Please select sales plan year.";
    } else if (!this.state.selectedArray.salesPlanMonth) {
      this.setState({ error: true });
      error = true;
      this.errorMessage = "Please select sales plan month.";
    } else {
      this.setState({ error: false });
      error = false;
      this.errorMessage = "";
    }
    if (!error) {
      for (let r = 2; r < this.state.flex.rows.length; r++) {
        let data = {};
        for (let c = 0; c < this.state.columns.length; c++) {
          if (
            this.state.flex.getCellData(1, c) === this.state.columns[c].name
          ) {
            if (
              this.state.columns[c].value &&
              this.state.flex.getCellData(r, c) === null
            ) {
              break;
            }

            data[this.state.columns[c].binding] = this.state.flex.getCellData(
              r,
              c
            );

            data.salesPlanYear = this.state.selectedArray.fiscalYear;
            data.salesPlanMonth = this.state.selectedArray.salesPlanMonth;
          }
        }

        // check status
        if (data.status === undefined) {
          data.status = "";
        }
        // check customer group
        if (data.customerGroup !== undefined) {
          let customerName = data.customerGroup.split(" ")[0];
          this.state.customerList.forEach((customer) => {
            if (customer.name == customerName) {
              data.customerGroup = customer.id;
            }
          });
        } else {
          data.customerGroup = "";
        }

        // check sector id
        if (data.sectorId !== undefined) {
          let sect = data.sectorId.split(" ")[0];

          this.state.sectorList.forEach((sector) => {
            if (sector.sectorName == sect) {
              data.sectorId = sector.sectorId;
            }
          });
        } else {
          data.sectorId = "";
        }

        // check state
        if (data.state !== undefined) {
          if (data.state === "Forecasting") {
            data.state = 1;
          } else if (data.state === "Ongoing") {
            data.state = 2;
          } else if (data.state === "Completed") {
            data.state = 3;
          }
        } else {
          data.state = "";
        }

        //check po currency amount
        if (data.poCurrencyAmount === undefined) {
          data.poCurrencyAmount = "";
        }

        //check po exchange rate
        if (data.poExchangeRate === undefined) {
          data.poExchangeRate = "";
        }

        // check po amount
        if (data.poAmount === undefined) {
          data.poAmount = "";
        }

        //check po currency amount
        if (data.salesCurrencyAmount === undefined) {
          data.salesCurrencyAmount = "";
        }

        //check po exchange rate
        if (data.salesExchangeRate === undefined) {
          data.salesExchangeRate = "";
        }

        // check sales amount
        if (data.salesAmount === undefined) {
          data.salesAmount = "";
        }

        //check cost percent
        if (data.costPercent === undefined) {
          data.costPercent = "";
        }

        if (
          data.orderNumber !== undefined &&
          data.orderNumber !== "" &&
          data.salesMonth !== undefined &&
          data.salesMonth !== "" &&
          data.projectName !== undefined &&
          data.projectName !== ""
        ) {
          updateRow.push(data);
        }
      }
      updateRow.length > 0 && this.saveToDB(updateRow);
    }
  }

  saveToDB(added) {
    fetch(SALES_PLAN_BULK_UPDATE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify({ salesPlanList: added }),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.result) {
          this.setState({ resultMessage: "Data updated successfully!" }, () => {
            this.setState({ showSuccessModal: true }, () => {});
          });
          cacheUpdate(
            this.state.selectedArray.fiscalYear,
            this.state.selectedArray.salesPlanMonth
          );
        }
      });
  }

  initializeFlexSheetUpdate(flex) {
    this.setState({
      flex: flex,
    });
    flex.deferUpdate(() => {
      this._generateBasicOperatorsSheet(flex);
    });
  }

  _generateBasicOperatorsSheet(flexSheet) {
    flexSheet.setCellData(0, 0, "Mandatory Fields");
    flexSheet.setCellData(0, 3, "Optional Fields (Data will be updated)");
    for (let c = 0; c < this.state.columns.length; c++) {
      flexSheet.setCellData(1, c, this.state.columns[c].name);
    }

    flexSheet.applyCellsStyle(
      {
        color: "white",
        textAlign: "center",
      },
      [new wjcGrid.CellRange(0, 0, 1, this.state.columns.length - 1)]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#ff3333",
      },
      [new wjcGrid.CellRange(0, 0, 0, 2)]
    );
    flexSheet.applyCellsStyle(
      {
        background: "#ff4d4d",
      },
      [new wjcGrid.CellRange(1, 0, 1, 2)]
    );
    flexSheet.applyCellsStyle(
      {
        background: "#4da6ff",
      },
      [new wjcGrid.CellRange(0, 3, 0, this.state.columns.length - 1)]
    );
    flexSheet.applyCellsStyle(
      {
        background: "#80bfff",
      },
      [new wjcGrid.CellRange(1, 3, 1, this.state.columns.length - 1)]
    );
    flexSheet.applyCellsStyle(
      {
        textAlign: "center",
      },
      [new wjcGrid.CellRange(2, 0, flexSheet.rows.length, 1)]
    );
    flexSheet.applyCellsStyle(
      {
        textAlign: "center",
      },
      [new wjcGrid.CellRange(2, 3, flexSheet.rows.length, 6)]
    );
    flexSheet.applyCellsStyle(
      {
        textAlign: "right",
      },
      [new wjcGrid.CellRange(2, 2, flexSheet.rows.length, 2)]
    );
    flexSheet.applyCellsStyle(
      {
        textAlign: "right",
      },
      [
        new wjcGrid.CellRange(
          2,
          7,
          flexSheet.rows.length,
          this.state.columns.length - 1
        ),
      ]
    );

    flexSheet.mergeRange(new wjcGrid.CellRange(0, 0, 0, 2));
    flexSheet.mergeRange(
      new wjcGrid.CellRange(0, 3, 0, this.state.columns.length - 1)
    );

    for (let col = 0; col < flexSheet.columns.length; col++) {
      flexSheet.columns[col].width = "*";
    }

    //format cost percent
    flexSheet.cellEditEnded.addHandler((s, e) => {
      let col = s.columns[e.col];
      if (col.index === 13) {
        let getData = s.getCellData(s.rows[e.row].index, 13);
        flexSheet.applyCellsStyle({ format: "p0" });
        s.setCellData(s.rows[e.row].index, 13, getData / 100);
      }
    });

    //read only rows
    flexSheet.beginningEdit.addHandler((s, e) => {
      if (e.row === 0 || e.row === 1) {
        e.cancel = true;
      }
    });
  }
}
