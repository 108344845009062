import { ActionIcon } from "@mantine/core";
import { IconExclamationMark } from "@tabler/icons-react";
import React from "react";

export default function ErrorWidget() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "120px",
      }}
    >
      <ActionIcon
        size="xl"
        sx={{ background: "#777e8b", borderRadius: "50%", color: "white" }}
      >
        <IconExclamationMark size="5rem" />
      </ActionIcon>
      <h3 style={{ marginLeft: "10px" }}>
        Error in loading data. Can not Fetch Data to display.
      </h3>
    </div>
  );
}
