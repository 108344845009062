import React from "react";
import PropTypes from "prop-types";

// wijmo
import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";

export default function DashboardSalesRank({ data }) {
  return (
    <div>
      <wjChart.FlexPie
        bindingName="name"
        binding="count"
        itemsSource={data}
        selectionMode="Point"
        selectedItemPosition="Top"
        isAnimated={true}
        startAngle={90}
        palette={["#32CD32", "#FEF250"]}
        selectedItemOffset={0.1}
      >
        <wjChart.FlexChartLegend position="Bottom" />
        <wjChart.FlexPieDataLabel
          content={(ht) => {
            return `${ht.name}, ${ht.value}, ${(
              (ht.value /
                data
                  .map((rank) => rank.count)
                  .reduce((sum, cur) => sum + cur)) *
              100
            ).toFixed(0)}%`;
          }}
          position="Outside"
        ></wjChart.FlexPieDataLabel>
        <wjChartAnimate.FlexChartAnimation />
      </wjChart.FlexPie>
    </div>
  );
}

DashboardSalesRank.propTypes = {
  data: PropTypes,
};
