import { Box, Container, Group, Notification, ScrollArea } from "@mantine/core";
import { IconCalculator } from "@tabler/icons-react";
import React from "react";
import ProgressCard from "../../../widgets/cards/progress-card";
import AreaChart from "../../../widgets/charts/area-chart";
import BarChart from "../../../widgets/charts/bar-chart";
import BarChartLine from "../../../widgets/charts/bar-chart-line";
import BubbleChart from "../../../widgets/charts/bubble-chart";
import LineChart from "../../../widgets/charts/line-chart";
import PieChart from "../../../widgets/charts/pie-chart";
import ScatterChart from "../../../widgets/charts/scatter-chart";
import FlexSheet from "../../../widgets/tables/flex-sheet";
import SimpleTable from "../../../widgets/tables/simple-table";
import PropTypes from "prop-types";

// Pre-define Dynamic Components
const components = {
  pie_chart: PieChart,
  line_chart: LineChart,
  bar_chart: BarChart,
  bar_chart_line: BarChartLine,
  progress_card: ProgressCard,
  simple_table: SimpleTable,
  area_chart: AreaChart,
  bubble_chart: BubbleChart,
  scatter_chart: ScatterChart,
  flex_sheet: FlexSheet,
};

const PreviewWidget = (props) => {
  const { count, item } = props;
  console.log(item.datasources.type);
  // const [flex, setFlex] = useState({});
  // Dynamic Compments Create
  const DynamicComponent = components[item.datasources.type];

  function chartInitalized() {
    // setFlex(flex);
  }

  return (
    <Container miw={"100%"} p={0}>
      <Box min="100%">
        <Group position="left" miw="100%" spacing={5}>
          <Notification
            withBorder
            withCloseButton={false}
            icon={<IconCalculator size="1rem" />}
            color="indigo"
            p={5}
          >
            Total Data : {count}
          </Notification>
        </Group>
      </Box>

      <ScrollArea h={420} w={"100%"} scrollbarSize={8} pt={5}>
        <Box>
          <DynamicComponent
            {...item}
            height={350}
            width={"99.5%"}
            chartInitalized={chartInitalized}
          />
        </Box>
      </ScrollArea>
    </Container>
  );
};

export default PreviewWidget;

PreviewWidget.propTypes = {
  count: PropTypes.any,
  item: PropTypes.any,
};
