import React, { useEffect, useState } from "react";
import "../../assets/css/styles.css";
import moment from "moment";
import { BiEditAlt, BiPlusCircle, BiSearchAlt2 } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import {
  FISCAL_YEAR_LIST_URL,
  MONTHLY_EXCHANGE_RATE_CREATE_URL,
  MONTHLY_EXCHANGE_RATE_LIST_URL,
  TOKEN,
  MONTHLY_EXCHANGE_RATE_UPDATE_URL,
  MONTHLY_EXCHANGE_RATE_DELETE_URL,
} from "../../constants/api.path";
import ConfirmModal from "../../modalPopups/confirmModal";
import InfoModal from "../../modalPopups/infoModal";
import SuccessModal from "../../modalPopups/successModal";
import AddEditModal from "../../modalPopups/exchangeRateAddEditModal";
import cacheUpdate from "../cacheUpdate/cacheUpdate";
import { Tooltip as ReactTooltip } from "react-tooltip";

function MonthlyExchangeRate() {
  const monthName = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [title, setTitle] = React.useState("");
  const [editing, setEditing] = React.useState(false);
  const [baseCurrency, setBaseCurrency] = React.useState("");
  const [activeId, setActiveId] = useState({
    activeId: "",
  });
  const [fiscalYearOption, setFiscalYearOption] = useState([{}]);
  const [exchangeRate, setExchangeRate] = useState([]);
  const [currentFilter, setCurrentFilter] = React.useState({
    year: 0,
  });
  const [months, setMonths] = React.useState([]);
  const [subExcRate, setSubExcRate] = useState({
    fiscalYearId: "",
    salesMonth: "",
    exchangeRate: "",
    currentCurrency: "",
  });

  const [showConfirmModal, setConfirmModal] = useState(false);
  const [showInfoModal, setInfoModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [showAddEditModal, setAddEditModal] = useState(false);
  const [resConfirm, setResConfirm] = useState(false);
  const [resultMessage, setResultMessage] = useState();
  const [value, setValue] = useState();
  const [exchangeRateId, setExchangeRateId] = useState();
  const [popUpType, setPopupType] = useState("");

  var updateMessage = "Are you sure to want to update?";
  var deleteMessage = "Are you sure to want to delete?";
  const [isDisabled, dropdownDisable] = useState(false);

  let header = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${TOKEN}`,
  };

  const handleFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newErRate = { ...subExcRate };
    newErRate[fieldName] = fieldValue;

    setSubExcRate(newErRate);
  };

  async function handleFormSubmit(event) {
    event.preventDefault();
    if (
      subExcRate.fiscalYearId !== "" &&
      subExcRate.salesMonth !== "" &&
      subExcRate.currentCurrency !== "" &&
      subExcRate.exchangeRate !== ""
    ) {
      if (!editing) {
        await fetch(MONTHLY_EXCHANGE_RATE_CREATE_URL, {
          method: "POST",
          headers: header,
          body: JSON.stringify(subExcRate),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.result) {
              getExchangeRate();
              cacheUpdate(subExcRate.fiscalYearId, new Date().getMonth() + 1);
            } else {
              setResultMessage(res.message);
              setInfoModal(true);
            }
          });
      } else {
        let editExcRate = {
          fiscalYearId: subExcRate.fiscalYearId,
          salesMonth: subExcRate.salesMonth,
          currentCurrency: subExcRate.currentCurrency,
          exchangeRate: subExcRate.exchangeRate,
        };
        setConfirmModal(true);
        setResConfirm(true);
        if (resConfirm) {
          fetch(MONTHLY_EXCHANGE_RATE_UPDATE_URL + `${subExcRate.exchangeId}`, {
            method: "PUT",
            headers: header,
            body: JSON.stringify(editExcRate),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              setConfirmModal(false);
              if (res.result) {
                getExchangeRate();
                cacheUpdate(subExcRate.fiscalYearId, new Date().getMonth() + 1);
              } else {
                setResultMessage(res.message);
                setInfoModal(true);
              }
              setResConfirm(false);
            });
        }
      }
    }
  }

  function getExchangeRate() {
    fetch(
      MONTHLY_EXCHANGE_RATE_LIST_URL + "/fiscalYear/" + currentFilter.year,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setExchangeRate(data.data.exchangeList);
        setBaseCurrency(data.data.companyMainCurrency);
      });

    // clear all input text
    setSubExcRate({
      fiscalYearId: "",
      salesMonth: "",
      exchangeRate: "",
      currentCurrency: "",
    });
    setEditing(false);
    setActiveId("");
    dropdownDisable(false);
  }

  useEffect(() => {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFiscalYearOption(data.data.fiscalYearList);
        setCurrentFilter({ year: data.data.currentYearId });
        setMonths(getMonths());
      });
  }, []);

  useEffect(() => {
    getExchangeRate();
  }, [fiscalYearOption]);

  function getMonths() {
    let data = [];
    for (let i = 4; i < 16; i++) {
      data.push({
        id: i - 3,
        name: moment(i <= 12 ? i : i - 12, "MM").format("MMM"),
        value: i <= 12 ? i : i - 12,
      });
    }
    return data;
  }

  function editExchangeRate(excRate) {
    setEditing(true);
    setSubExcRate({ ...excRate });
  }

  function deleteExchangeRate(delExcId) {
    fetch(MONTHLY_EXCHANGE_RATE_DELETE_URL + `${delExcId}`, {
      method: "DELETE",
      headers: header,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.result) {
          setResultMessage(res.message);
          setSuccessModal(true);
          getExchangeRate();
        } else {
          setResultMessage(res.message);
          setInfoModal(true);
        }
      });
  }

  function catchFunction(e) {
    if (value === "Update") {
      handleFormSubmit(e);
    } else {
      deleteExchangeRate(exchangeRateId);
    }
  }

  const getValue = (e) => {
    setValue(e.target.id);
  };

  return (
    <div className="main--body">
      <div className="row">
        <div className="col-md-2" style={{ paddingRight: "0px" }}>
          <select
            className="form-select"
            id="fiscal_year"
            value={currentFilter.year}
            onChange={(e) => {
              setCurrentFilter({
                ...currentFilter,
                year: parseInt(e.target.value),
              });
            }}
          >
            <option value="" hidden>
              Fiscal Year
            </option>
            {fiscalYearOption.map((fiscalYear) => (
              <option
                key={fiscalYear.fiscalYearId}
                value={fiscalYear.fiscalYearId}
              >
                {fiscalYear.fiscalYear}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-1">
          <button
            id="search"
            data-tooltip-content="Search"
            type="button"
            style={{ margin: "0", padding: "0px" }}
            className="actionIconOutline search bg-blue  "
            onClick={() => {
              getExchangeRate();
            }}
          >
            <BiSearchAlt2 />
            <ReactTooltip place="bottom" anchorId="search" />
          </button>
        </div>
      </div>
      <div className="row justify-content-center d-flex">
        <div className="col-md-12 justify-content-end d-flex">
          <button
            className="actionIconOutline save bg-blue"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
            value="Add"
            id="Add"
            data-tooltip-content="Add"
            onClick={(e) => {
              getValue(e);
              setAddEditModal(true);
              setPopupType("add");
              setTitle("Register Monthly Exchange Rate");
              setSubExcRate({
                fiscalYearId: "",
                salesMonth: "",
                exchangeRate: "",
                currentCurrency: "",
              });
            }}
          >
            <BiPlusCircle style={{ pointerEvents: "none" }} />
            <ReactTooltip place="bottom" anchorId="Add" />
          </button>
        </div>
        <div className="col-md-12 mt-2 ">
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th className="text-center">Fiscal Year</th>
                <th className="text-center">Sales Month</th>
                <th className="text-center">Base Currency</th>
                <th className="text-center">Exchange Rate</th>
                <th className="text-center">Currency</th>
                <th className="text-center">Updated Date</th>
                <th className="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {exchangeRate.map((erRate) => (
                <tr
                  key={erRate.exchangeId}
                  id={erRate.exchangeId}
                  className={
                    erRate.exchangeId === activeId.activeId ? "bg-tr" : ""
                  }
                >
                  {fiscalYearOption.map(
                    (fy) =>
                      fy.fiscalYearId === erRate.fiscalYearId && (
                        <td align="center" key={fy.fiscalYear}>
                          {fy.fiscalYear}
                        </td>
                      )
                  )}
                  <td align="center">{monthName[erRate.salesMonth - 1]}</td>
                  <td align="center">{baseCurrency}</td>
                  <td align="right">{erRate.exchangeRate}</td>
                  <td align="center">{erRate.currentCurrency}</td>
                  <td align="right">{erRate.updateDate}</td>
                  <td className="text-center">
                    <button
                      onClick={(e) => {
                        setAddEditModal(true);
                        setPopupType("update");
                        dropdownDisable(true);
                        editExchangeRate(erRate);
                        getValue(e);
                        setTitle("Update Monthly Exchange Rate");
                      }}
                      className="actionIcon edit"
                      id="Update"
                    >
                      <BiEditAlt style={{ pointerEvents: "none" }} />
                    </button>
                    <button
                      className="actionIcon delete"
                      onClick={(e) => {
                        setExchangeRateId(erRate.exchangeId);
                        setConfirmModal(true);
                        getValue(e);
                      }}
                    >
                      <MdDeleteOutline />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmModal
        show={showConfirmModal}
        title={value === "Update" ? updateMessage : deleteMessage}
        onSubmit={(e) => {
          setConfirmModal(false);
          catchFunction(e);
        }}
        onCancel={() => {
          setConfirmModal(false);
          setResConfirm(false);
        }}
        onHide={() => setConfirmModal(false)}
      />
      <InfoModal
        show={showInfoModal}
        title={resultMessage}
        onSubmit={() => setInfoModal(false)}
        onHide={() => setInfoModal(false)}
      />
      <SuccessModal
        show={showSuccessModal}
        title={resultMessage}
        onSubmit={() => setSuccessModal(false)}
        onHide={() => setSuccessModal(false)}
      />
      <AddEditModal
        show={showAddEditModal}
        title={title}
        onSubmit={() => setAddEditModal(false)}
        onHide={() => {
          setAddEditModal(false);
          setEditing(false);
        }}
        getValue={getValue}
        handleFormChange={handleFormChange}
        handleFormSubmit={handleFormSubmit}
        subExcRate={subExcRate}
        editing={editing}
        isDisabled={isDisabled}
        fiscalYearOption={fiscalYearOption}
        setAddEditModal={setAddEditModal}
        tab={"Monthly"}
        months={months}
        popUpType={popUpType}
      ></AddEditModal>
    </div>
  );
}

export default MonthlyExchangeRate;
