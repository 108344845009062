import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

function FiscalYearAddEditModal(props) {
  return (
    <div>
      <Modal {...props} size="md" onHide={props.onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form
            onSubmit={props.handleSubmit}
            method="POST"
            className="row col-md-auto justify-content-center d-flex"
          >
            <div className="row col-md-12 mt-1 justify-content-center d-flex">
              <label className="col-md-3 col-form-label">Fiscal Year</label>
              <div className="col-md-7  g-0">
                <input
                  type="text"
                  placeholder="Enter Fiscal Year(FY22)"
                  name="fiscalYearName"
                  maxLength={5}
                  minLength={2}
                  onChange={props.handleChange}
                  value={props.display.fiscalYear}
                  ref={props.inputRef}
                  required={true}
                  className="form-control"
                />
              </div>
              {!props.result ? (
                <span className="alert-warning position-absolute mx-auto w-75 ms-3 text-center rounded">
                  {props.message !== "" && props.message}
                </span>
              ) : (
                <span className="alert-success position-absolute mx-auto w-75 ms-3 text-center rounded">
                  {props.message !== "" && props.message}
                </span>
              )}
            </div>
            <div className="row col-md-12 mt-1 justify-content-center d-flex">
              <label className="col-md-3 col-sm-5 col-form-label">
                Start Date
              </label>
              <div className="col-md-7  g-0">
                <input
                  type="date"
                  className="form-control"
                  value={props.display.fyStartDate}
                  onChange={props.handleStartChange}
                  required={true}
                  ref={props.startRef}
                />
              </div>
            </div>
            <div className="row col-md-12 mt-1 justify-content-center d-flex">
              <span className="col-md-3 col-sm-5 col-form-label">End Date</span>
              <div className="col-md-7 g-0">
                <input
                  type="date"
                  className="form-control"
                  value={props.display.fyEndDate}
                  onChange={props.handleEndChange}
                  required={true}
                  ref={props.endRef}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <form onSubmit={props.handleSubmit}>
            <button
              className="popUpBtn save bg-blue"
              id="Add"
              data-tooltip-content="Add"
              onClick={(e) => {
                props.getValue(e);
                props.setAddEditModal(false);
              }}
            >
              <span id="Add">Save</span>
            </button>
          </form>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FiscalYearAddEditModal;

FiscalYearAddEditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  display: PropTypes.object.isRequired,
  inputRef: PropTypes.object,
  result: PropTypes.object,
  message: PropTypes.string,
  handleStartChange: PropTypes.func.isRequired,
  startRef: PropTypes.object,
  handleEndChange: PropTypes.func.isRequired,
  endRef: PropTypes.object,
  editing: PropTypes.bool,
  setAddEditModal: PropTypes.func.isRequired,
};
