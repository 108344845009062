import React, { useEffect, useState } from "react";
import * as wjChart from "@grapecity/wijmo.react.chart";
import PropTypes from "prop-types";
import { DataConveterBy } from "../utilities/utilities";
import { useAtom } from "jotai";
import { metadataAtom } from "../state/chart-item-atom";

export default function BubbleChart(props) {
  const { datasources, width, height } = props;
  const [metadataState] = useAtom(metadataAtom);
  const [data] = useState(
    DataConveterBy(
      metadataState,
      datasources.configure.bindingX,
      datasources.configure.bindingY
    )
  );
  const [axis, setAxis] = useState({
    xAxis: datasources.configure.bindingX,
    yAxis: datasources.configure.bindingY,
  });
  const initChart = () => {
    // chartInitalized(flex);
  };
  const yAxis = datasources.configure.bindingY.join(",");
  useEffect(() => {
    setAxis({
      xAxis: datasources.configure.bindingX,
      yAxis: datasources.configure.bindingY,
    });
  }, [datasources.configure]);
  return (
    <div className="container-fluid">
      <wjChart.FlexChart
        style={{ width: width, height: height }}
        chartType="Bubble"
        bindingX={datasources.configure.bindingX}
        header={datasources.header}
        itemsSource={data}
        initialized={initChart}
      >
        <wjChart.FlexChartDataLabel
          position={datasources.settings.dataLabel}
          content="{y}"
        />
        <wjChart.FlexChartLegend position="Bottom" />
        {axis.xAxis.length > 0 && (
          <wjChart.FlexChartAxis
            wjProperty="axisY"
            title={datasources.settings.leftAxisTitle}
            min={0}
          ></wjChart.FlexChartAxis>
        )}
        {axis.yAxis.length > 0 && (
          <wjChart.FlexChartAxis
            wjProperty="axisX"
            title={datasources.settings.bottomAxisTitle}
            axisLine={true}
            min={0}
          ></wjChart.FlexChartAxis>
        )}

        <wjChart.FlexChartSeries binding={yAxis} />
      </wjChart.FlexChart>
    </div>
  );
}

BubbleChart.propTypes = {
  datasources: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.number,
  chartInitalized: PropTypes.func,
};
