import React, { useState } from "react";

import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";

import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";

import { useAtom } from "jotai";
import { DataConveterBy } from "../utilities/utilities";
import { metadataAtom } from "../state/chart-item-atom";

import PropTypes from "prop-types";

const LineChart = (props) => {
  const { datasources, width, height, chartInitalized } = props;
  const [metadataState] = useAtom(metadataAtom);

  const [palette] = useState([
    "rgba(42,159,214,1)",
    "rgba(119,179,0,1)",
    "rgba(153,51,204,1)",
    "rgba(255,136,0,1)",
    "rgba(204,0,0,1)",
    "rgba(0,204,163,1)",
    "rgba(61,109,204,1)",
    "rgba(82,82,82,1)",
    "rgba(0,0,0,1)",
  ]);
  const serverData = DataConveterBy(
    metadataState,
    datasources.configure.bindingX,
    datasources.configure.bindingY
  );

  function getChartType(chart_type) {
    switch (chart_type) {
      case "spline":
        return "SplineSymbols";
      case "line":
        return "Line";

      default:
        return "Line";
    }
  }

  return (
    <div>
      <wjChart.FlexChart
        initialized={chartInitalized}
        style={{ width: width, height: height }}
        itemsSource={serverData}
        bindingX={datasources.configure.bindingX}
        chartType={getChartType(datasources.settings.lineChartType)}
        header={datasources.header}
        legendToggle={datasources.settings.legendToggle}
        palette={palette}
      >
        {datasources.settings.legendPosition && (
          <wjChart.FlexChartLegend
            position={datasources.settings.legendPosition}
          />
        )}
        <wjChart.FlexChartDataLabel
          position={datasources.settings.dataLabelPosition}
          content="{y}"
        />
        <wjChart.FlexChartAxis
          wjProperty={"axisX"}
          title={datasources.settings.xAxisTitle}
        ></wjChart.FlexChartAxis>
        <wjChart.FlexChartAxis
          wjProperty={"axisY"}
          title={datasources.settings.yAxisTitle}
        ></wjChart.FlexChartAxis>
        {datasources.configure.bindingY.map((item) => (
          <wjChart.FlexChartSeries
            name={item}
            binding={item}
            key={`bar-chart-${item}`}
          />
        ))}
        <wjChartAnimate.FlexChartAnimation animationMode="Point"></wjChartAnimate.FlexChartAnimation>
      </wjChart.FlexChart>
    </div>
  );
};

export default LineChart;

LineChart.propTypes = {
  datasources: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.number,
  chartInitalized: PropTypes.func,
};
