import React from "react";
import { propTypes } from "react-bootstrap/esm/Image";

// wijmo
export default function DashboardTable({ data }) {
  return (
    <div className="table">
      <table className="dashboard-table table table-bordered table-striped m-0 dashboard-table">
        <thead>
          <tr>
            <td></td>
            <td className="table-title-number">予算</td>
            <td className="table-title-number">実績</td>
            <td className="table-title-number">予算差</td>
          </tr>
        </thead>
        <tbody className="table-bordered">
          {data.map((data) => (
            <tr key={data.id}>
              <td className="table-name">
                <b>{data.name === "O.R" ? data.name : data.name}</b>
              </td>
              <td className="table-number">
                <b>{Math.round(data.plan)}</b>
              </td>
              <td className="table-number">
                <b>{Math.round(data.actual)}</b>
              </td>
              <td
                className="table-number"
                style={{
                  color: data.actual - data.plan > 0 ? "blue" : "red",
                }}
              >
                <b>{Math.round(data.actual - data.plan)}</b>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

DashboardTable.propTypes = {
  data: propTypes,
};
