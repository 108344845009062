import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

class CloneConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onHide: props.onHide,
      show: props.show,
      onSubmit: props.onSubmit,
      onCancel: props.onCancel,
      salesPlanYearName: props.salesPlanYearName,
      salesPlanMonthName: props.salesPlanMonthName,
    };
  }

  render() {
    return (
      <div>
        <Modal size="md" onHide={this.state.onHide} show={this.props.show}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure to clone for {this.props.salesPlanYearName}{" "}
            {this.props.salesPlanMonthName} ?
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onSubmit}>Confirm</Button>
            <Button onClick={this.state.onCancel}>Cancel</Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default CloneConfirmModal;

CloneConfirmModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  salesPlanYearName: PropTypes.array.isRequired,
  salesPlanMonthName: PropTypes.array.isRequired,
};
