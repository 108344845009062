import React from "react";
import { ActionIcon, Button, Group, Stack, TextInput } from "@mantine/core";
// import {
//   IconCirclePlus,
//   IconGripVertical,
//   IconTrash,
// } from "@tabler/icons-react";
import { useAtom } from "jotai";
import { useRef, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import PropTypes from "prop-types";
import { BiGridVertical } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { chartItemsAtom } from "../state/chart-item-atom";

const style = {
  display: "flex",
  alignItems: "center",
  borderRadius: "7px",
  border: `#ced4da 1px solid`,
  padding: `7px`,
  backgroundColor: "white",
};

const dropStyle = {
  display: "flex",
  alignItems: "center",
  borderRadius: "7px",
  border: `rgb(76, 110, 245) 1px dashed`,
  padding: `7px`,
  backgroundColor: "white",
};

const TabEditorList = (props) => {
  const { section, remove, onUpdate, index, moveCard } = props;
  // Provider Items
  const [itemsState, setItemState] = useAtom(chartItemsAtom);
  const handleRemove = (targetId) => {
    const updatedSections = itemsState.sections.filter(
      (section) => section.sectionValue !== targetId
    );

    setItemState({
      ...itemsState,
      sections: updatedSections,
    });
    remove(targetId);
  };

  // Update Tab Name
  const handleChange = (event) => {
    const updatedSections = itemsState.sections.map((oldSection) => {
      if (oldSection.sectionValue === section.sectionValue) {
        return {
          ...oldSection,
          sectionName: event.target.value,
        };
      }
      return oldSection;
    });

    setItemState({
      ...itemsState,
      sections: updatedSections,
    });
    onUpdate(updatedSections);
  };

  const ref = useRef(null);
  const [{ handlerId, isActive }, drop] = useDrop({
    accept: "CARD",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
        isActive: monitor.canDrop() && monitor.isOver(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: "CARD",
    item: () => {
      return { id: section.sectionValue, index };
    },
    collect: (monitor) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <Group
      ref={preview}
      style={isActive ? { opacity, ...dropStyle } : { ...style, opacity }}
    >
      <div ref={ref} data-handler-id={handlerId}>
        {/* <IconGripVertical
          size="1.05rem"
          stroke={1.5}
          style={{ cursor: "grab" }}
        /> */}
        <BiGridVertical />
      </div>

      <TextInput
        placeholder="Tab Name"
        value={section.sectionName}
        onChange={handleChange}
      />
      <ActionIcon
        color="red"
        disabled={section.sectionValue === "main"}
        onClick={() => handleRemove(section.sectionValue)}
      >
        <FiTrash2 />
      </ActionIcon>
    </Group>
  );
};

const TabEditator = (props) => {
  const { sections } = props;
  // Provider Items
  const [itemsState, setItemState] = useAtom(chartItemsAtom);
  const [newSection, setNewSection] = useState(sections);
  // Add New Tab
  const handleAdd = () => {
    const newSection = {
      sectionName: `Default-${itemsState.sections.length + 1}`,
      sectionValue: self.crypto.randomUUID(),
      items: [],
    };
    const newSections = [...itemsState.sections, newSection];
    setItemState((prev) => ({ ...prev, sections: newSections }));
    setNewSection(newSections);
  };

  const handleRemoveSection = (id) => {
    setNewSection((prev) => prev.filter((p) => p.sectionValue !== id));
  };

  const moveCard = (dragIndex, hoverIndex) => {
    const newSections = [...newSection];
    const draggedCard = newSections[dragIndex]; // get the card that is being dragged
    newSections.splice(dragIndex, 1); // remove the card at the dragIndex
    newSections.splice(hoverIndex, 0, draggedCard); // insert the draggedCard at the hoverIndex
    setItemState((prev) => ({ ...prev, sections: newSections }));
    setNewSection(newSections);
  };

  function handleUpdate(nSection) {
    setNewSection(nSection);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <DndProvider backend={HTML5Backend}>
        <Stack spacing={5} py={5}>
          {newSection.map((section, index) => (
            <TabEditorList
              key={index}
              index={index}
              section={section}
              moveCard={moveCard}
              remove={handleRemoveSection}
              onUpdate={handleUpdate}
            />
          ))}
        </Stack>
      </DndProvider>
      <Button leftIcon={<AiOutlinePlusCircle />} onClick={handleAdd}>
        Add
      </Button>
    </div>
  );
};

export default TabEditator;

TabEditorList.propTypes = {
  section: PropTypes.object,
  remove: PropTypes.func,
  onUpdate: PropTypes.func,
  index: PropTypes.number,
  moveCard: PropTypes.func,
};

TabEditator.propTypes = {
  sections: PropTypes.array,
};
