// Mantine Icons
import {
  IconAppsFilled,
  IconArrowBack,
  IconCode,
  IconEyeCheck,
  IconEyeOff,
} from "@tabler/icons-react";

// Mantine core
import {
  ActionIcon,
  Alert,
  Box,
  Container,
  Divider,
  Drawer,
  Flex,
  Grid,
  Group,
  ThemeIcon,
  Title,
  Tooltip,
} from "@mantine/core";
import { randomId, useDisclosure } from "@mantine/hooks";

// React
import React, { useEffect, useState } from "react";

// Router Dom
import { useLocation, useNavigate } from "react-router-dom";

// Axios
import axios from "axios";

// Mock
import WidgtesMock from "../../../mocks/widgets-mock.json";

// Components
import { notifications } from "@mantine/notifications";
import {
  IconAlertTriangle,
  IconDeviceFloppy,
  IconPinned,
} from "@tabler/icons-react";
import {
  GET_DATA_BY_QUERY,
  SAVE_DASHBOARD,
  SAVE_QUERY,
} from "../../../utilities/constants";
import {
  findDashboardByI,
  updateItemByQueryId,
} from "../../../utilities/utilities";
import LoadingComponents from "../../common/loading";
import WidgetContent from "../../common/widget-content";
import WidgetEditor from "../../common/widget-editor";
import PinQuery from "./pin-query/pin-query";
import PreviewWidget from "./preview-widget/preview-widget";
import SearchQueryDashboard from "./search-query/search-query-dashboard";
import { TOKEN } from "../../../../../constants/api.path";

function pinnedDataProcessing(
  dashboardList,
  pinQueryState,
  transition,
  newItem,
  currentPinnedTab
) {
  if (transition === "before") {
    // ********* These Function is required when connected to Backend *********
    // ********* Open Commend  Line from  81 to 120  *********
    // let layout = JSON.parse(
    //   JSON.parse(
    //     dashboardList.find((dashboard) => {
    //       return dashboard.id === pinQueryState.pinDashboard;
    //     }).dashboard_layout
    //   ).json
    // ).sections;
    let layout = JSON.parse(
      JSON.parse(
        dashboardList.find(
          (dashboard) => dashboard.id === pinQueryState.pinDashboard
        ).dashboard_layout
      ).json
    );
    // let dashboard_layout = [];
    for (let i = 0; i < layout.sections.length; i++) {
      if (layout.sections[i].sectionValue === pinQueryState.pinTab) {
        layout.sections[i] = currentPinnedTab;
      }
    }
    const requestBody = {
      dashboard_Id: pinQueryState.pinDashboard,
      dashboard_name: dashboardList.filter((dashboard) => {
        return dashboard.id === pinQueryState.pinDashboard;
      })[0].dashboard_name,
      dashboard_layout: JSON.stringify(layout),
    };
    // SID004 Save Dashboard Data
    axios
      .post(SAVE_DASHBOARD, requestBody, {
        headers: { Authorization: `Bearer ${TOKEN}` },
      })
      .then(() => {
        notifications.show({
          title:
            transition === "before"
              ? "Pinned Dashboard Saved!"
              : "Updated Widget!",
          message:
            transition === "before"
              ? "Pinned Dashboard is saved successfully!"
              : "Widget is updated successfully!",
          autoClose: 2000,
          color: "green",
          withCloseButton: false,
        });
      })
      .catch((error) => {
        const message =
          transition === "before"
            ? "Pinned Dashboard is not saved!"
            : "Widget is not updated!";
        notifications.show({
          title: `Error occured! Error Code: ${error.status}`,
          message: error.message || message,
          autoClose: 2000,
          color: "red",
          withCloseButton: false,
        });
      });
  } else {
    // Update the item by its query Id in the dashboardList
    dashboardList = dashboardList.map((dashboard) => {
      if (dashboard.dashboard_Id === pinQueryState.pinDashboard) {
        return updateItemByQueryId(dashboard, newItem.i, newItem);
      } else {
        return dashboard;
      }
    });
  }
  // localStorage.setItem("dashboard", JSON.stringify(dashboardList));
  notifications.show({
    title:
      transition === "before" ? "Pinned Dashboard Saved!" : "Updated Widget!",
    message:
      transition === "before"
        ? "Pinned Dashboard is saved successfully!"
        : "Widget is updated successfully!",
    autoClose: 2000,
    color: "green",
    withCloseButton: false,
  });
}

const QueryDashboardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const widgets = WidgtesMock;

  const [controlsState, setControlsState] = useState({
    isSearchQuery: false,
    isEditorDrawer: false,
    isWidgetSettingDrawer: false,
    isRunQuery: false,
  });

  const [state, setState] = useState({
    selectedWidget: null,
    datasources: null,
    queryForm: null,
    queryObject: {},
    dashboardlayout: null,
    schema: null,
    metadata: {},
    count: 0,
  });

  const [pinQueryState, setPinQueryState] = useState({
    pinDashboard: "",
    pinTab: "",
    pinDashboardData: [],
    pinTabData: [],
    isPinned: false,
  });

  // ********* These State is not required when connected to Backend *********
  // const [tempQueryObject, setTempQueryObject] = useState({});

  // Loading State handlers
  const [isLoading, loadingHandlers] = useDisclosure(true);

  // Get metadata from query run
  const handleQueryData = (data, queryObject) => {
    const { transition } = location.state;
    let newDatasource = {
      title: "",
      header: null,
      type: "simple_table",
      configure: {
        columns: data.metadata[0].column_names,
      },
      settings: {
        count: data.metadata.available_data_row_count,
        frozenRows: 0,
        frozenColumns: 0,
        sortingBy: null,
        allowSorting: "None",
      },
    };

    const newItem = {
      i: randomId(),
      query: queryObject.query,
      tableName: queryObject.table_name,
      queryName: queryObject.queryName,
      queryForm: JSON.stringify(queryObject.query_form),
      datasources: transition === "before" ? newDatasource : state.datasources,
    };

    setState((prev) => ({
      ...prev,
      metadata: data.metadata,
      item: newItem,
      queryObject: queryObject,
      queryForm: queryObject.query_form,
      datasources: state.datasources || newDatasource,
      count: data.metadata.available_data_row_count,
    }));

    setControlsState((prev) => ({
      ...prev,
      isRunQuery: true,
    }));
    // ********* These State is not required when connected to Backend *********
    // setTempQueryObject(queryObject);
  };

  const handleDrawer = () => {
    setControlsState((prev) => ({
      ...prev,
      isEditorDrawer: true,
    }));
  };

  const handlePinChange = (value, field) => {
    // Check if pin dashboard change set pin tab first array value and new pin tab data set
    if (Object.keys(pinQueryState)[0] === field) {
      const newDashboard = pinQueryState.pinDashboardData.find(
        (dashboard) => dashboard.value === value
      );
      const dashboardLayout = newDashboard.description;
      // Get Current Query Pin Dashboard's tab
      const currentPinTab = dashboardLayout[0]?.value;
      setPinQueryState((prev) => {
        let updatedState = Object.assign({}, prev);
        updatedState[field] = value;
        updatedState[Object.keys(prev)[1]] = currentPinTab;
        updatedState.pinTabData = dashboardLayout;
        return updatedState;
      });
    } else {
      setPinQueryState((prevState) => {
        return Object.assign({}, prevState, { [field]: value });
      });
    }
  };

  const handleSaveQuery = () => {
    const { transition, dashboardList } = location.state;
    console.log(transition);
    if (transition === "before") {
      const query_data_config = {
        // i: randomId(),
        query: state.queryObject.query,
        tableName: state.queryObject.table_name,
        queryName: state.queryObject.query_name,
        queryForm: JSON.stringify(state.queryObject.query_form),
        datasources: state.datasources,
        // query: state.queryObject.query,
        // table_name: state.queryObject.table_name,
        // query_name: state.queryObject.queryName,
        // query_form: JSON.stringify(state.queryObject.query_form),
        // datasources: state.datasources,
      };
      // ********* These Function is required when connected to Backend *********
      // ********* Open Commend  Line from 268 to 294 *********
      const requestBody = state.queryForm.query_id
        ? {
            query_str: query_data_config.query,
            query_data_config: JSON.stringify(query_data_config),
            query_form: JSON.stringify(query_data_config.queryForm),
            table_name: query_data_config.tableName,
          }
        : {
            query_str: query_data_config.query,
            query_data_config: JSON.stringify(query_data_config),
            query_form: JSON.stringify(query_data_config.queryForm),
            table_name: query_data_config.tableName,
            query_name: query_data_config.queryName,
          };

      let url = state.queryForm.query_id
        ? `${SAVE_QUERY}/Id/${state.queryForm.query_id}`
        : SAVE_QUERY;

      fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      })
        .then(() => {
          notifications.show({
            title: "Saved query!",
            message: "Query is saved!",
            autoClose: 2000,
            withCloseButton: false,
          });
        })
        .catch((error) => {
          notifications.show({
            title: `Error occured! Error Code: ${error.status}`,
            message: error.message || "Query is not saved!",
            autoClose: 2000,
            color: "red",
            withCloseButton: false,
          });
        });

      // ********* These Function is not required when connected to Backend *********
      // ********* Close Commend  Line from 212 to 236 *********
      // const getQueryList = localStorage.getItem("query");
      // // Convert json string to object
      // let queryList = JSON.parse(getQueryList);
      // // New query object
      // let newQueryObject = {
      //   query_Id: query ? query.query_Id : randomId(),
      //   query_name: tempQueryObject.query_name,
      //   query_str: tempQueryObject.query,
      //   query_form: JSON.stringify(tempQueryObject.query_form),
      //   table_name: tempQueryObject.table_name,
      //   query_data_config: JSON.stringify(query_data_config),
      //   created_by: "Admin",
      //   created_at: new Date().toString(),
      //   uploaded_by: "Admin",
      //   uploaded_at: new Date().toString(),
      // };
      // Add new query to query list
      // Check old query_Id have updated and if not updated add new query to query list
      // if (queryList) {
      //   const queryIndex = queryList.findIndex(
      //     (query) => query.query_Id === newQueryObject.query_Id
      //   );
      //   if (queryIndex === -1) {
      //     queryList.push(newQueryObject);
      //   } else {
      //     queryList[queryIndex] = newQueryObject;
      //   }
      // }
      // localStorage.setItem("query", JSON.stringify(queryList));
      // notifications.show({
      //   title: "Saved query!",
      //   message: "Query is saved!",
      //   autoClose: 2000,
      //   withCloseButton: false,
      // });
    } else {
      // Extract the dashboardList from the location's state
      pinnedDataProcessing(
        dashboardList,
        pinQueryState,
        transition,
        state.item
      );
    }
  };

  const handlePinQuery = () => {
    if (pinQueryState.pinDashboard === "" || pinQueryState.pinTab === "") {
      return notifications.show({
        title: "Error!",
        message: "Please select dashboard and tab!",
        autoClose: 2000,
        color: "red",
        withCloseButton: false,
      });
    }
    // A function to filter the dashboard by its id
    const filterDashboard = (dashboardId, dashboardList) =>
      dashboardList.find(({ id }) => id === dashboardId);

    // A function to filter the section by its value
    const filterSection = (sectionValue, sections) =>
      sections.find(({ sectionValue: value }) => value === sectionValue);

    // Extract the dashboardList from the location's state
    let { dashboardList, transition } = location.state;

    // Define a new item object
    let newItem = {
      i: randomId(),
      query: state.queryObject.query,
      tableName: state.queryObject.table_name,
      queryName: state.queryObject.query_name,
      queryForm: state.queryObject.query_form,
      datasources: state.datasources,
    };

    // Filter the current pinned dashboard
    let currentPinnedDashboard = filterDashboard(
      pinQueryState.pinDashboard,
      dashboardList
    );

    let sections = JSON.parse(
      JSON.parse(currentPinnedDashboard.dashboard_layout).json
    ).sections;

    // Filter the current pinned tab
    let currentPinnedTab = filterSection(pinQueryState.pinTab, sections);

    // Destructure the lg layout from currentPinnedTab
    let {
      layouts: { lg: layoutLg },
    } = currentPinnedTab || [];

    // Calculate the position for the new layout
    let layoutCalculateX = 0;
    let layoutCalculateY = 0;

    if (layoutLg.length > 0) {
      const lastLayout = layoutLg[layoutLg.length - 1];
      const calculatedX = lastLayout.x + 6 > 12 ? 0 : lastLayout.x + 6;

      layoutCalculateX = calculatedX > 12 ? 0 : calculatedX;
      layoutCalculateY = calculatedX > 12 ? lastLayout.y + 6 : 0;
    }

    // Define the new layout
    let newLayout = {
      i: newItem.i,
      w: 6,
      h: 6,
      x: layoutLg.length ? layoutCalculateX : 0,
      y: layoutLg.length ? layoutCalculateY : 0,
      moved: false,
      static: false,
    };

    // Push the new layout and new item to the current pinned tab
    currentPinnedTab.layouts.lg = layoutLg;
    currentPinnedTab.layouts.lg.push(newLayout);
    currentPinnedTab.items.push(newItem);

    // Update the section in the sections array
    sections[
      sections.findIndex(
        ({ sectionValue }) => sectionValue === pinQueryState.pinTab
      )
    ] = currentPinnedTab;
    pinnedDataProcessing(
      dashboardList,
      pinQueryState,
      transition,
      state.item,
      currentPinnedTab
    );
  };

  // Add Widget Open
  const handleAddWidget = (widget) => {
    console.log(state);
    const { transition } = location.state;
    let newItem = {
      i: transition === "before" ? randomId() : state.item.i,
      query: state.queryObject.query,
      tableName: state.queryObject.table_name,
      queryName: state.queryObject.queryName,
      queryForm: state.queryObject.query_form,
      datasources: state.datasources,
    };

    setState((prev) => ({
      ...prev,
      selectedWidget: widget,
      item: newItem,
      targetId: 0,
    }));

    setControlsState((prev) => ({
      ...prev,
      isWidgetSettingDrawer: true,
    }));
  };

  const handleWidgetSettingClose = (newDatasource) => {
    const item = {
      ...state.item,
      datasources: newDatasource,
    };

    setState((prev) => {
      ({
        ...prev,
        item: item,
        datasources: newDatasource,
      });
    });

    setControlsState((prev) => ({
      ...prev,
      isEditorDrawer: false,
      isWidgetSettingDrawer: false,
    }));
  };

  useEffect(() => {
    // Destrucutre location state
    const { query, dashboardList, schema } = location.state;
    let pinDashboardList = [];
    let pinTabList = [];
    // Get Current Query Pin Dashboard's tab
    // Looping and pushing pin dashboard list and tab list to convert data props object

    dashboardList.forEach((dashboard) => {
      let pinDashboardObj = {
        value: dashboard.id,
        label: dashboard.dashboard_name,
        description: dashboard.dashboard_name,
      };
      if (dashboard.dashboard_layout.json) {
        JSON.parse(dashboard.dashboard_layout?.json).sections.forEach(
          (section) => {
            let pinTabObj = {
              value: section.sectionValue,
              label: section.sectionName,
            };
            pinTabList.push(pinTabObj);
          }
        );
      } else {
        JSON.parse(
          JSON.parse(dashboard.dashboard_layout).json
        ).sections?.forEach((section) => {
          let pinTabObj = {
            value: section.sectionValue,
            label: section.sectionName,
          };
          pinTabList.push(pinTabObj);
        });
      }
      pinDashboardObj.description = pinTabList;
      pinDashboardList.push(pinDashboardObj);
    });

    let currentPinDashboard = "";
    let currentPinTab = "";
    let currentPinList = [];
    if (query) {
      const currentDashboard = findDashboardByI(
        dashboardList,
        query.query_data_config.i
      );

      if (currentDashboard) {
        currentPinDashboard = currentDashboard.dashboard_Id;
        currentPinTab = currentDashboard.dashboard_layout.activeSection;
        currentPinList = pinDashboardList.find(
          (dashboard) => dashboard.value === currentDashboard.dashboard_Id
        )?.description;
      }
    }

    setPinQueryState((prev) => ({
      ...prev,
      pinDashboard: currentPinDashboard,
      pinDashboardData: pinDashboardList,
      pinTab: currentPinTab,
      pinTabData: currentPinList,
    }));

    if (query) {
      // SID002 Get Data by queries
      let queryLine = JSON.parse(query.query_data_config).query;
      let request = {
        query: queryLine,
      };
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(request),
      };
      fetch(GET_DATA_BY_QUERY, config)
        .then((res) => res.json())
        .then((response) => {
          const data = response;
          const item = JSON.parse(query.query_data_config);
          let newDatasource = {
            title: "",
            header: null,
            type: "simple_table",
            configure: {
              columns: data.metadata[0].column_names,
            },
            settings: {
              count: data.metadata.available_data_row_count,
              frozenRows: 0,
              frozenColumns: 0,
              sortingBy: null,
              allowSorting: "None",
            },
          };
          item.datasources = newDatasource;
          let oldQuery = {
            query: query.query_data_config.query,
            table_name: query.query_data_config.tableName,
            query_name: query.query_data_config.queryName,
            query_form: query.query_data_config.queryForm,
          };
          setState((prev) => ({
            ...prev,
            queryForm: query,
            queryObject: oldQuery,
            item: item,
            schema: schema,
            metadata: data.metadata,
            datasources: query.query_data_config.datasources,
            count: data.metadata.available_data_row_count,
          }));
          setControlsState((prev) => ({
            ...prev,
            isRunQuery: true,
          }));
          loadingHandlers.close();
        })
        .catch((error) => {
          loadingHandlers.close();
          notifications.show({
            title: `Error occured! Error Code: ${error.status}`,
            message: error.message || "Query is not fetched!",
            autoClose: 2000,
            color: "red",
            withCloseButton: false,
          });
        });
    } else {
      loadingHandlers.close();
      setState((prev) => ({
        ...prev,
        item: null,
        queryForm: null,
        schema: schema,
      }));
    }
  }, []);

  return (
    <div style={{ padding: 7 }}>
      {isLoading ? (
        <LoadingComponents />
      ) : (
        <React.Fragment>
          <Flex
            mih={30}
            gap="xs"
            justify="space-between"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Flex
              mih={"auto"}
              gap="xs"
              justify="flex-start"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <ThemeIcon size="sm" color="indigo" style={{ cursor: "pointer" }}>
                <IconCode size="1rem" />
              </ThemeIcon>

              <Title order={5} c="dimmed">
                Query Dashboard
              </Title>
            </Flex>

            <Box>
              <Group spacing={5} align="right">
                <Tooltip withArrow position="bottom" color="teal" label="Back">
                  <ActionIcon
                    variant="filled"
                    aria-label="back"
                    color="teal"
                    onClick={() => navigate("/dashboard/query/")}
                  >
                    <IconArrowBack size="1rem" />
                  </ActionIcon>
                </Tooltip>

                <PinQuery
                  handlePinChange={handlePinChange}
                  planceholder="Pin to Dashboard"
                  pinData={pinQueryState.pinDashboardData}
                  pinValue={pinQueryState.pinDashboard}
                  pinTarget={Object.keys(pinQueryState)[0]}
                />
                {pinQueryState.pinDashboard && (
                  <PinQuery
                    handlePinChange={handlePinChange}
                    planceholder="Pin to Tab"
                    pinData={pinQueryState.pinTabData}
                    pinValue={pinQueryState.pinTab}
                    pinTarget={Object.keys(pinQueryState)[1]}
                  />
                )}
                <Tooltip
                  withArrow
                  position="bottom"
                  color="teal"
                  label="Pin Query"
                >
                  <ActionIcon
                    disabled={Object.keys(state.metadata).length === 0}
                    variant="filled"
                    aria-label="back"
                    color="teal"
                    onClick={handlePinQuery}
                  >
                    <IconPinned size="1rem" />
                  </ActionIcon>
                </Tooltip>

                <Tooltip
                  label="Widget Config"
                  position="bottom"
                  color="teal"
                  withArrow
                  arrowPosition="center"
                >
                  <ActionIcon
                    disabled={Object.keys(state.metadata).length === 0}
                    variant="outline"
                    onClick={handleDrawer}
                    color="teal"
                  >
                    <IconAppsFilled size="1rem" />
                  </ActionIcon>
                </Tooltip>

                <Tooltip
                  label={
                    location.state?.transition === "before"
                      ? "Save Query"
                      : "Update Widget"
                  }
                  position="bottom"
                  color="teal"
                  withArrow
                  arrowPosition="center"
                >
                  <ActionIcon
                    disabled={!controlsState.isRunQuery}
                    variant="outline"
                    onClick={handleSaveQuery}
                    color="teal"
                  >
                    <IconDeviceFloppy size="1rem" />
                  </ActionIcon>
                </Tooltip>

                <Tooltip
                  position="bottom"
                  color="teal"
                  withArrow
                  arrowPosition="center"
                  label="Create Query"
                >
                  <ActionIcon
                    variant="outline"
                    aria-label="create new query"
                    color="teal"
                    onClick={() =>
                      setControlsState((prev) => ({
                        ...prev,
                        isSearchQuery: !prev.isSearchQuery,
                      }))
                    }
                  >
                    {controlsState.isSearchQuery ? (
                      <IconEyeCheck
                        size="1rem"
                        variant="outline"
                        color="teal"
                      />
                    ) : (
                      <IconEyeOff size="1rem" variant="outline" color="teal" />
                    )}
                  </ActionIcon>
                </Tooltip>
              </Group>
            </Box>
          </Flex>

          <Drawer.Root
            opened={controlsState.isEditorDrawer}
            onClose={() =>
              setControlsState((prev) => ({ ...prev, isEditorDrawer: false }))
            }
            size="35%"
            position="right"
          >
            <Drawer.Overlay />
            <Drawer.Content>
              <Drawer.Header style={{ padding: "7px", minHeight: "40px" }}>
                <Title order={5} color="indigo">
                  {controlsState.isWidgetSettingDrawer
                    ? `${state.selectedWidget.title} Setting`
                    : "Widgets"}
                </Title>
                {controlsState.isWidgetSettingDrawer && (
                  <ActionIcon
                    color="indigo"
                    ml="xs"
                    onClick={() =>
                      setControlsState((prev) => ({
                        ...prev,
                        isWidgetSettingDrawer: false,
                      }))
                    }
                  >
                    <IconArrowBack size="1.125rem" />
                  </ActionIcon>
                )}
                <Drawer.CloseButton />
              </Drawer.Header>
              <Drawer.Body style={{ padding: "0 7px" }}>
                <Divider mb={7} />
                {console.log(state.metadata)}
                {controlsState.isWidgetSettingDrawer === true ? (
                  <WidgetEditor
                    metacolumn={state.metadata[0]}
                    widget={state.selectedWidget}
                    oldDatasource={state.item?.datasources || {}}
                    targetId={state.targetId}
                    activeTab={state.activeTab}
                    handleWidgetSettingClose={handleWidgetSettingClose}
                  />
                ) : (
                  <Grid gutter={10}>
                    {widgets.map((widget, index) => (
                      <Grid.Col span={6} key={index}>
                        <WidgetContent
                          key={index}
                          widget={widget}
                          handleAddWidget={handleAddWidget}
                        />
                      </Grid.Col>
                    ))}
                  </Grid>
                )}
              </Drawer.Body>
            </Drawer.Content>
          </Drawer.Root>

          <Container size={"100%"} px={0} py={5}>
            <SearchQueryDashboard
              show={controlsState.isSearchQuery}
              handleQueryData={handleQueryData}
              queryForm={state.queryForm}
              schemaList={state.schema}
            />
          </Container>
          <Container size={"100%"} p={0}>
            {console.log(state.item)}
            {state.item ? (
              <PreviewWidget item={state.item} count={state.count} />
            ) : (
              <Alert
                icon={<IconAlertTriangle size="1rem" />}
                title="Warning!"
                color="red"
                variant="outline"
              >
                There are not preview data. Please create query and run !!!.
              </Alert>
            )}
          </Container>
        </React.Fragment>
      )}
    </div>
  );
};

export default QueryDashboardPage;
