import "bootstrap/dist/css/bootstrap.css";
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjcCore from "@grapecity/wijmo";
import * as React from "react";
import PropTypes from "prop-types";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import {
  FISCAL_YEAR_LIST_URL,
  SGA_CREATE_UPDATE_URL,
  SGA_LIST_URL,
  TOKEN,
} from "../../constants/api.path";
import "../../assets/css/sgaInDirectCosts.css";
import SuccessModal from "../../modalPopups/successModal";
import InfoModal from "../../modalPopups/infoModal";
import { BiSave, BiSearchAlt2 } from "react-icons/bi";
import { Tooltip } from "react-tooltip";
import cacheUpdate from "../cacheUpdate/cacheUpdate";
import { classChangeMonth } from "../../utilities/utilities";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

class SGAInDirectCosts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flex: null,
      fiscalYearList: [],
      currentFyId: "",
      error: false,
      selectedArray: {},
      sgaIds: [],
      showSuccessModal: false,
      showInfoModal: false,
      resultMessage: "",
      initialState: true,
    };

    this.handleChange = this.handleChange.bind(this);
    this.sgaDataBinding = this.sgaDataBinding.bind(this);
  }

  componentDidMount() {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState(
          {
            fiscalYearList: result.data.fiscalYearList,
            currentFyId: result.data.currentYearId,
            selectedArray: {
              salesPlanYear: result.data.currentYearId,
              salesPlanMonth: new Date().getMonth() + 1,
            },
          },
          () => [this.sgaList()]
        );
      });
  }

  sgaList(searchData, sgaDataBinding, flex) {
    let sgaYear = searchData?.salesPlanYear || this.state.currentFyId;
    // this.state.selectedArray.fiscalYearId === undefined
    //   ? this.state.currentFyId
    //   : this.state.selectedArray.fiscalYearId;
    let sgaMonth =
      searchData?.salesPlanMonth ||
      this.state.selectedArray.salesPlanMonth ||
      new Date().getMonth() + 1;
    // this.state.selectedArray.sgaMonth === undefined
    //   ? new Date().getMonth() + 1
    //   : this.state.selectedArray.sgaMonth;

    fetch(SGA_LIST_URL + "/sgaYear/" + sgaYear + "/sgaMonth/" + sgaMonth, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        console.log(result.result, "result");
        if (result.result) {
          if (sgaDataBinding) {
            sgaDataBinding(result.data.sgaList, flex);
          } else this.sgaDataBinding(result.data.sgaList);
        } else {
          console.log(this.state.initialState, "initial state");
          if (this.state.initialState) {
            console.log("sga year: ", sgaYear, "sga month: ", sgaMonth);
            let data = classChangeMonth({
              data: { salesPlanMonth: sgaMonth, salesPlanYear: sgaYear },
              func: this.sgaList,
              subfunc: this.sgaDataBinding,
              flex: this.state.flex,
            });
            this.setState({
              selectedArray: {
                salesPlanYear: data.salesPlanYear,
                salesPlanMonth: data.salesPlanMonth,
              },
              initialState: false,
            });
          }
          if (!this.state.initialState)
            this.setState({
              resultMessage: result.message,
              showInfoModal: true,
            });
        }
      });
  }

  sgaDataBinding(sgaList, flexSheet) {
    let flex = flexSheet || this.state.flex;

    // data binding from response data
    let r = 2;
    let sgaIds = [];
    for (let row = 0; row < sgaList.length; row++) {
      sgaIds.push(sgaList[row].sgaId);
      flex.setCellData(r, 0, sgaList[row].description);
      flex.setCellData(r, 1, sgaList[row].month1);
      flex.setCellData(r, 2, sgaList[row].month2);
      flex.setCellData(r, 3, sgaList[row].month3);
      flex.setCellData(r, 5, sgaList[row].month4);
      flex.setCellData(r, 6, sgaList[row].month5);
      flex.setCellData(r, 7, sgaList[row].month6);
      flex.setCellData(r, 10, sgaList[row].month7);
      flex.setCellData(r, 11, sgaList[row].month8);
      flex.setCellData(r, 12, sgaList[row].month9);
      flex.setCellData(r, 14, sgaList[row].month10);
      flex.setCellData(r, 15, sgaList[row].month11);
      flex.setCellData(r, 16, sgaList[row].month12);
      r++;
    }
    this.setState({ sgaIds: sgaIds });
  }

  handleChange(e) {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = parseInt(e.target.value);

    const selectedValue = { ...this.state.selectedArray };
    selectedValue[fieldName] = fieldValue;

    this.setState({ selectedArray: selectedValue }, () => {});
  }

  render() {
    return (
      <div className="container-fluid SGAInDirectCosts">
        <div className="row" style={{ margin: "0", paddingLeft: "5px" }}>
          {/* <div className="col-md-10 justify-content-end"></div> */}
          <div
            className="col-auto"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <select
              name="salesPlanYear"
              className="form-select"
              onChange={this.handleChange}
              value={this.state.selectedArray.salesPlanYear}
            >
              <option key={0} value="" hidden>
                Fiscal Year
              </option>
              {this.state.fiscalYearList.map((item) => (
                <option key={item.fiscalYearId} value={item.fiscalYearId}>
                  {item.fiscalYear}
                </option>
              ))}
            </select>
          </div>
          <div className="col-auto">
            <select
              name="salesPlanMonth"
              className="form-select"
              onChange={this.handleChange}
              value={this.state.selectedArray.salesPlanMonth}
            >
              <option key={0} value="" hidden>
                Month
              </option>
              <option key={1} value="4">
                Apr
              </option>
              <option key={2} value="5">
                May
              </option>
              <option key={3} value="6">
                Jun
              </option>
              <option key={4} value="7">
                Jul
              </option>
              <option key={5} value="8">
                Aug
              </option>
              <option key={6} value="9">
                Sep
              </option>
              <option key={7} value="10">
                Oct
              </option>
              <option key={8} value="11">
                Nov
              </option>
              <option key={9} value="12">
                Dec
              </option>
              <option key={10} value="1">
                Jan
              </option>
              <option key={11} value="2">
                Feb
              </option>
              <option key={12} value="3">
                Mar
              </option>
            </select>
          </div>
          <div
            className="col-auto"
            style={{ marginRight: "5px", padding: "0" }}
          >
            <button
              id="search"
              data-tooltip-content="Search"
              type="button"
              style={{ margin: "0", padding: "0px" }}
              className="actionIconOutline search bg-blue  "
              onClick={() => {
                this.sgaList(this.state.selectedArray);
              }}
            >
              <BiSearchAlt2 />
              <Tooltip place="left" anchorId="search" />
            </button>
          </div>
          <div
            className="col-auto"
            style={{ padding: "0", marginRight: "5px" }}
          >
            {/* <button
              type="button"
              className="btn btn-primary w-75"
              value="Save"
              onClick={() => this.getValue(this.state.fiscalYearId)}
            >
              Save
            </button> */}
            <button
              id="save"
              data-tooltip-content="Save"
              type="button"
              style={{ margin: "0", padding: "0" }}
              className="actionIconOutline save bg-blue"
              value="Save"
              onClick={() => {
                this.setState({ showConfirmModal: true }, () => {});
                this.getValue(this.state.selectedArray);
                console.log(this.state.selectedArray, "selected array");
              }}
            >
              <BiSave id="Save" />
              <Tooltip place="left" anchorId="save" />
            </button>
          </div>
          {this.state.error && (
            <span className="error">{this.errorMessage}</span>
          )}
          <SuccessModal
            show={this.state.showSuccessModal}
            title={this.state.resultMessage}
            onSubmit={() => {
              this.setState({ showSuccessModal: false }, () => {
                window.location.reload(true);
              });
            }}
            onHide={() => {
              this.setState({ showSuccessModal: false }, () => {});
            }}
          />
          <InfoModal
            show={this.state.showInfoModal}
            title={this.state.resultMessage}
            onSubmit={() => {
              this.setState({ showInfoModal: false }, () => {});
            }}
            onHide={() => {
              this.setState({ showInfoModal: false }, () => {});
              this.setState({ resConfirm: false }, () => {});
            }}
          />
        </div>

        <wjGridSheet.FlexSheet
          initialized={this.initializeFlexSheet.bind(this)}
          style={{
            height: "75vh",
            marginTop: "5px",
          }}
        >
          <wjGridSheet.Sheet name="SGA And In Direct Costs" columnCount={20} />
        </wjGridSheet.FlexSheet>
      </div>
    );
  }

  initializeFlexSheet(flex) {
    this.setState({ flex: flex });
    var totalCols = [4, 8, 13, 17];
    let h = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
    ];
    var totalUsingCols = [
      ["B", "C", "D"],
      ["F", "G", "H"],
      ["K", "L", "M"],
      ["O", "P", "Q"],
    ];
    let totalUsingColi = 0;
    var halfCols = [9, 18];
    var halfUsingCols = [
      ["E", "I"],
      ["N", "R"],
    ];
    var halfUsingColi = 0;
    flex.deferUpdate(() => {
      this._generateBasicOperatorsSheet(flex);
      flex.selectedSheetIndex = 0;

      // binding default description name
      flex.setCellData(2, 0, "HO Sales Expense");
      flex.setCellData(3, 0, "HO GA Expense");
      flex.setCellData(4, 0, "HO SGA");
      flex.setCellData(5, 0, "Branch Sales Expense");
      flex.setCellData(6, 0, "Branch GA Expense");
      flex.setCellData(7, 0, "Branch SGA");
      flex.setCellData(8, 0, "Non Operating Income");
      flex.setCellData(9, 0, "Non Operating Expense");
      for (let i = 0; i < totalCols.length; i++) {
        for (let j = 2; j < 10; j++) {
          flex.setCellData(
            j,
            totalCols[i],
            this.getFormula(totalUsingCols, totalUsingColi, j)
          );
        }
        totalUsingColi++;
      }
      for (let i = 0; i < halfCols.length; i++) {
        for (let j = 2; j < 10; j++) {
          flex.setCellData(
            j,
            halfCols[i],
            this.getFormula(halfUsingCols, halfUsingColi, j)
          );
        }
        halfUsingColi++;
      }
      for (let i = 2; i < 10; i++) {
        flex.setCellData(i, 19, "=J" + (i + 1) + "+S" + (i + 1));
      }
    });

    flex.cellEditEnding.addHandler((s, e) => {
      let col = s.columns[e.col];
      let row = s.rows[e.row];

      // HO SGA
      if (
        col.index >= 1 &&
        col.index <= 19 &&
        (row.index === 2 || row.index === 3)
      ) {
        flex.setCellData(4, e.col, "=" + h[e.col] + "3+" + h[e.col] + "4");
      }

      // Branch SGA
      if (
        col.index >= 1 &&
        col.index <= 19 &&
        (row.index === 5 || row.index === 6)
      ) {
        flex.setCellData(7, e.col, "=" + h[e.col] + "6+" + h[e.col] + "7");
      }
    });
  }

  _generateBasicOperatorsSheet(flexSheet) {
    flexSheet.setCellData(0, 0, "Description");
    flexSheet.setCellData(0, 1, "1Q");
    flexSheet.setCellData(1, 1, "Apr");
    flexSheet.setCellData(1, 2, "May");
    flexSheet.setCellData(1, 3, "Jun");
    flexSheet.setCellData(1, 4, "Total");
    flexSheet.setCellData(0, 5, "2Q");
    flexSheet.setCellData(1, 5, "July");
    flexSheet.setCellData(1, 6, "Aug");
    flexSheet.setCellData(1, 7, "Sep");
    flexSheet.setCellData(1, 8, "Total");
    flexSheet.setCellData(0, 9, "1H");
    flexSheet.setCellData(0, 10, "3Q");
    flexSheet.setCellData(1, 10, "Oct");
    flexSheet.setCellData(1, 11, "Nov");
    flexSheet.setCellData(1, 12, "Dec");
    flexSheet.setCellData(1, 13, "Total");
    flexSheet.setCellData(0, 14, "4Q");
    flexSheet.setCellData(1, 14, "Jan");
    flexSheet.setCellData(1, 15, "Feb");
    flexSheet.setCellData(1, 16, "Mar");
    flexSheet.setCellData(1, 17, "Total");
    flexSheet.setCellData(0, 18, "2H");
    flexSheet.setCellData(0, 19, "Annual");

    flexSheet.applyCellsStyle(
      {
        background: "aqua",
      },
      [new wjcGrid.CellRange(0, 0, 1, 0), new wjcGrid.CellRange(0, 1, 0, 19)]
    );

    flexSheet.applyCellsStyle(
      {
        fontWeight: "bold",
      },
      [new wjcGrid.CellRange(0, 0, 1, 19)]
    );

    // readOnly cell for rows HO SGA and Branch SGA
    flexSheet.rows[4].isReadOnly = true;
    flexSheet.rows[7].isReadOnly = true;
    flexSheet.columns[4].isReadOnly = true;
    flexSheet.columns[8].isReadOnly = true;
    flexSheet.columns[9].isReadOnly = true;
    flexSheet.columns[13].isReadOnly = true;
    flexSheet.columns[17].isReadOnly = true;
    flexSheet.columns[18].isReadOnly = true;
    flexSheet.columns[19].isReadOnly = true;
    flexSheet.applyCellsStyle(
      {
        background: "#bbb",
      },
      [new wjcGrid.CellRange(4, 0, 4, 19), new wjcGrid.CellRange(7, 0, 7, 19)]
    );

    flexSheet.mergeRange(new wjcGrid.CellRange(0, 0, 1, 0));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 1, 0, 4));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 5, 0, 8));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 9, 1, 9));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 10, 0, 13));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 14, 0, 17));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 18, 1, 18));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 19, 1, 19));

    flexSheet.columns[0].width = 170;
  }

  getValue(yearMonthArray) {
    this.error = false;
    this.setState({ error: false });
    let sgaList = [];
    var description,
      month1,
      month2,
      month3,
      month4,
      month5,
      month6,
      month7,
      month8,
      month9,
      month10,
      month11,
      month12,
      sgaId;
    let index = 0;
    if (
      (yearMonthArray.salesPlanYear === null ||
        yearMonthArray.salesPlanYear === undefined) &&
      (yearMonthArray.salesPlanMonth === null ||
        yearMonthArray.salesPlanMonth === undefined)
    ) {
      this.setState({ error: true });
      this.errorMessage = "Please select both fiscal year and month!";
    } else if (
      (yearMonthArray.salesPlanYear === null ||
        yearMonthArray.salesPlanYear === undefined) &&
      (yearMonthArray.salesPlanMonth !== null ||
        yearMonthArray.salesPlanMonth !== undefined)
    ) {
      this.setState({ error: true });
      this.errorMessage = "Please select fiscal year!";
    } else if (
      (yearMonthArray.salesPlanYear !== null ||
        yearMonthArray.salesPlanYear !== undefined) &&
      (yearMonthArray.salesPlanMonth === null ||
        yearMonthArray.salesPlanMonth === undefined)
    ) {
      this.setState({ error: true });
      this.errorMessage = "Please select month!";
    } else {
      for (let r = 2; r < this.state.flex.rows.length; r++) {
        if (this.state.flex.getCellValue(r, 0) !== "") {
          sgaId = this.state.sgaIds[index];
          description = this.state.flex.getCellValue(r, 0);
          month1 = this.state.flex.getCellValue(r, 1);
          month2 = this.state.flex.getCellValue(r, 2);
          month3 = this.state.flex.getCellValue(r, 3);
          month4 = this.state.flex.getCellValue(r, 5);
          month5 = this.state.flex.getCellValue(r, 6);
          month6 = this.state.flex.getCellValue(r, 7);
          month7 = this.state.flex.getCellValue(r, 10);
          month8 = this.state.flex.getCellValue(r, 11);
          month9 = this.state.flex.getCellValue(r, 12);
          month10 = this.state.flex.getCellValue(r, 14);
          month11 = this.state.flex.getCellValue(r, 15);
          month12 = this.state.flex.getCellValue(r, 16);

          if (sgaId === null || sgaId === undefined) {
            sgaId = "";
          }
          var sga = {
            sgaId: sgaId,
            description: description,
            month1: month1,
            month2: month2,
            month3: month3,
            month4: month4,
            month5: month5,
            month6: month6,
            month7: month7,
            month8: month8,
            month9: month9,
            month10: month10,
            month11: month11,
            month12: month12,
            fiscalYear: yearMonthArray.salesPlanYear,
            sgaMonth: yearMonthArray.salesPlanMonth,
          };
          sgaList.push(sga);
          index++;
        } else if (this.state.flex.getCellValue(r, 0) === "" && r < 8) {
          this.error = true;
          this.setState({ error: true });
          this.errorMessage =
            "Please fill description name for row " + (r + 1) + "!";
        } else {
          break;
        }
      }

      if (!this.error) {
        let saveData = { sgaList: [...sgaList] };
        this.savetoDB(saveData);
      }
    }
  }

  savetoDB(added) {
    fetch(SGA_CREATE_UPDATE_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify(added),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.result) {
          this.setState({ resultMessage: response.message }, () => {
            this.setState({ showSuccessModal: true });
            cacheUpdate(
              this.state.selectedArray.salesPlanYear,
              this.state.selectedArray.salesPlanMonth
            );
          });
        } else {
          this.setState({
            resultMessage: response.message,
            showInfoModal: true,
          });
        }
      });
  }
  getFormula(cols, colindex, rowindex) {
    var formula = "=";
    for (let i = 0; i < cols[colindex].length; i++) {
      if (cols[colindex].length === i + 1) {
        formula += cols[colindex][i] + (rowindex + 1);
      } else {
        formula += cols[colindex][i] + (rowindex + 1) + "+";
      }
    }
    return formula;
  }
}
export default SGAInDirectCosts;

SGAInDirectCosts.propTypes = {
  changeLocation: PropTypes.func.isRequired,
};
