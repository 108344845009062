import React from "react";
import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";
import { changeLabelPosition } from "./changeLablePosition";
import PropTypes from "prop-types";

export default class SalesChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sales: props.salesTotal,
      aNb: props.salesAandB,
      cNd: props.salesCandD,
      ranksTotal: props.salesRanks,
      palette: [
        "#ff5349",
        "#808080",
        "#FEF250",
        "#88BDE6",
        "#32CD32",
        "#00FFFF",
      ],
      stacked: props.stacked,
    };
  }
  componentDidMount() {
    this.state.stacked &&
      setTimeout(() => {
        changeLabelPosition(this.state.sales, "initial");
        changeLabelPosition(this.state.aNb, "initial");
        changeLabelPosition(this.state.cNd, "initial");
      }, 50);
  }
  componentDidUpdate() {
    this.state.stacked &&
      setTimeout(() => {
        changeLabelPosition(this.state.sales, "initial");
        changeLabelPosition(this.state.aNb, "initial");
        changeLabelPosition(this.state.cNd, "initial");
      }, 50);
  }
  render() {
    return (
      <div className="container-fluid">
        {this.state.stacked ? (
          <div className="row">
            <div className="col-md-4">
              <wjChart.FlexChart
                header="売上 (セクター別)"
                bindingX="name"
                itemsSource={this.state.sales}
                chartType="Column"
                stacking={this.state.stacked && "Stacked"}
                selectionMode="Series"
                legendToggle={true}
                palette={this.state.palette}
                rendered={() =>
                  this.state.stacked &&
                  changeLabelPosition(this.state.sales, "render")
                }
              >
                <wjChart.FlexChartLegend position="Right"></wjChart.FlexChartLegend>
                <wjChart.FlexChartSeries
                  name="ICT"
                  binding="ICT"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="Facilities"
                  binding="Facility"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="Fiber"
                  binding="Fiber"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="DX/BPO"
                  binding="Software"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="日本支店"
                  binding="JP Business"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="Total"
                  binding="total"
                  chartType={this.state.stacked ? "LineSymbols" : "Column"}
                  startPoint="Right"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartDataLabel
                  content="{y}"
                  position="Bottom"
                ></wjChart.FlexChartDataLabel>
                <wjChartAnimate.FlexChartAnimation />
              </wjChart.FlexChart>
            </div>
            <div className="col-md-4">
              <wjChart.FlexChart
                header="A&B ランク"
                bindingX="name"
                itemsSource={this.state.aNb}
                chartType="Column"
                stacking="Stacked"
                selectionMode="Series"
                legendToggle={true}
                palette={this.state.palette}
                rendered={() =>
                  this.state.stacked &&
                  changeLabelPosition(this.state.aNb, "render")
                }
              >
                <wjChart.FlexChartLegend position="Right"></wjChart.FlexChartLegend>
                <wjChart.FlexChartSeries
                  name="ICT"
                  binding="ICT"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="Facilities"
                  binding="Facility"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="Fiber"
                  binding="Fiber"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="DX/BPO"
                  binding="Software"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="日本支店"
                  binding="JP Business"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartDataLabel
                  content="{y}"
                  position="Bottom"
                ></wjChart.FlexChartDataLabel>
                <wjChartAnimate.FlexChartAnimation />
              </wjChart.FlexChart>
            </div>
            <div className="col-md-4 pieAndChart">
              <wjChart.FlexPie
                header="売上ランク"
                bindingName="name"
                binding="count"
                itemsSource={this.state.ranksTotal}
                selectionMode="Point"
                selectedItemPosition="Top"
                selectedItemOffset={0.1}
                isAnimated={true}
                startAngle={90}
                style={{ height: "250px", marginBottom: "0" }}
              >
                <wjChart.FlexChartDataLabel
                  content={(ht) => {
                    return `${ht.name}, ${ht.value}, ${(
                      (ht.value /
                        this.state.ranksTotal
                          .map((rank) => rank.count)
                          .reduce((sum, cur) => sum + cur)) *
                      100
                    ).toFixed(0)}%`;
                  }}
                ></wjChart.FlexChartDataLabel>
                <wjChartAnimate.FlexChartAnimation />
              </wjChart.FlexPie>
              <wjChart.FlexChart
                header="C&D ランク"
                bindingX="name"
                itemsSource={this.state.cNd}
                chartType="Column"
                selectionMode="Series"
                legendToggle={true}
                id="cNd"
                palette={this.state.palette}
                style={{ height: "250px" }}
              >
                <wjChart.FlexChartLegend position="Right"></wjChart.FlexChartLegend>
                <wjChart.FlexChartSeries
                  name="ICT"
                  binding="ICT"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="Facilities"
                  binding="Facility"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="Fiber"
                  binding="Fiber"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="DX/BPO"
                  binding="Software"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="日本支店"
                  binding="JP Branch"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="Total"
                  binding="total"
                  chartType="LineSymbols"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartDataLabel
                  content="{y}"
                  position="Bottom"
                ></wjChart.FlexChartDataLabel>
                <wjChartAnimate.FlexChartAnimation />
              </wjChart.FlexChart>
            </div>
          </div>
        ) : (
          <div className="row">
            <div className="col-md-4">
              <wjChart.FlexPie
                header="売上ランク"
                bindingName="name"
                binding="count"
                itemsSource={this.state.ranksTotal}
                selectionMode="Point"
                selectedItemPosition="Top"
                isAnimated={true}
                startAngle={90}
              >
                <wjChart.FlexChartLegend position="Bottom" />
                <wjChart.FlexPieDataLabel
                  content={(ht) => {
                    return `${ht.name}, ${ht.value}, ${(
                      (ht.value /
                        this.state.ranksTotal
                          .map((rank) => rank.count)
                          .reduce((sum, cur) => sum + cur)) *
                      100
                    ).toFixed(0)}%`;
                  }}
                ></wjChart.FlexPieDataLabel>
                <wjChartAnimate.FlexChartAnimation />
              </wjChart.FlexPie>
            </div>
            <div className="col-md-4">
              <wjChart.FlexChart
                header="A&B ランク"
                bindingX="name"
                itemsSource={this.state.aNb}
                chartType="Column"
                selectionMode="Series"
                legendToggle={true}
                palette={this.state.palette}
              >
                <wjChart.FlexChartLegend position="Right"></wjChart.FlexChartLegend>
                <wjChart.FlexChartSeries
                  name="ICT"
                  binding="ICT"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="Facilities"
                  binding="Facility"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="Fiber"
                  binding="Fiber"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="DX/BPO"
                  binding="Software"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartSeries
                  name="日本支店"
                  binding="JP Business"
                ></wjChart.FlexChartSeries>
                <wjChart.FlexChartDataLabel
                  content="{y}"
                  position="Bottom"
                ></wjChart.FlexChartDataLabel>
                <wjChartAnimate.FlexChartAnimation />
              </wjChart.FlexChart>
            </div>
            <div className="col-md-4">
              <div
                style={{ height: "fit-content", margin: "0" }}
                className="abNcd"
              >
                <wjChart.FlexPie
                  header="売上 (セクター別)"
                  bindingName="name"
                  binding="count"
                  itemsSource={this.state.sales}
                  selectionMode="Point"
                  selectedItemPosition="Top"
                  isAnimated={true}
                  startAngle={90}
                  style={{ height: "250px" }}
                  innerRadius={0.5}
                >
                  <wjChart.FlexPieDataLabel
                    content={(ht) => {
                      return `${ht.value}, ${(
                        (ht.value /
                          this.state.ranksTotal
                            .map((rank) => rank.count)
                            .reduce((sum, cur) => sum + cur)) *
                        100
                      ).toFixed(0)}%`;
                    }}
                    position="Outside"
                  ></wjChart.FlexPieDataLabel>
                  <wjChartAnimate.FlexChartAnimation />
                </wjChart.FlexPie>
              </div>
              <div>
                <wjChart.FlexChart
                  header="C&D ランク"
                  bindingX="name"
                  itemsSource={this.state.cNd}
                  chartType="Column"
                  selectionMode="Series"
                  legendToggle={true}
                  palette={this.state.palette}
                  style={{ height: "250px" }}
                >
                  <wjChart.FlexChartLegend position="Right"></wjChart.FlexChartLegend>
                  <wjChart.FlexChartSeries
                    name="ICT"
                    binding="ICT"
                  ></wjChart.FlexChartSeries>
                  <wjChart.FlexChartSeries
                    name="Facilities"
                    binding="Facility"
                  ></wjChart.FlexChartSeries>
                  <wjChart.FlexChartSeries
                    name="Fiber"
                    binding="Fiber"
                  ></wjChart.FlexChartSeries>
                  <wjChart.FlexChartSeries
                    name="DX/BPO"
                    binding="Software"
                  ></wjChart.FlexChartSeries>
                  <wjChart.FlexChartSeries
                    name="日本支店"
                    binding="JP Business"
                  ></wjChart.FlexChartSeries>
                  <wjChart.FlexChartDataLabel
                    content="{y}"
                    position="Bottom"
                  ></wjChart.FlexChartDataLabel>
                  <wjChartAnimate.FlexChartAnimation />
                </wjChart.FlexChart>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

SalesChart.propTypes = {
  salesTotal: PropTypes.isRequired,
  salesAandB: PropTypes.isRequired,
  salesCandD: PropTypes.isRequired,
  salesRanks: PropTypes.isRequired,
  stacked: PropTypes.isRequired,
};
