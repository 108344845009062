// need to add Radial Gauge, linear gauge, line chart, bubble chart

import React from "react";

// css
import "./dashboard-components/dynamic-dashboard.css";

// Components
import Tile from "./dashboard-components/tile";

// icons
import { AiOutlineMenu } from "react-icons/ai";

import { palette } from "./dashboard-components/icons";
import PropTypes from "prop-types";

export default function DynamicDashboard({
  tileCatalog,
  setTiles,
  tiles,
  state,
  dynamicMenuExpend,
  setDynamicMenuExpend,
}) {
  function getTileContent(name) {
    const { tileCatalog } = state;
    const arr = tileCatalog.items;
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].name === name) {
        return React.createElement(arr[i].tile, {
          data: arr[i].data,
          palette: palette,
        });
      }
    }
    throw "*** tile not found: " + name;
  }

  function removeTile(tileIndex) {
    const tile = tiles.filter((item, index) => index !== tileIndex);
    setTiles(tile);
  }

  function addTile(name, data) {
    const { key: stateKey } = state;
    const key = stateKey + 1;
    setTiles([{ name, key, data }, ...tiles]);
  }

  React.useEffect(() => {
    const panel = document.querySelector(".dyanmic-dashboard");
    if (panel) {
      enableItemReorder(panel);
    }
  });

  function enableItemReorder(panel) {
    let dragSource = null;
    let dropTarget = null;
    panel.addEventListener("dragstart", (e) => {
      let target = e.target.closest(".tile-container");
      if (target && target.parentElement.parentElement) {
        dragSource = target;
        dragSource.classList.add("drag-source");
        const dt = e.dataTransfer;
        dt.effectAllowed = "move";
        dt.setData("text", dragSource.innerHTML);
      }
    });
    panel.addEventListener("dragover", (e) => {
      if (dragSource) {
        let tile = e.target.closest(".tile-container");
        if (tile === dragSource) {
          tile = null;
        }
        if (dragSource && tile && tile !== dragSource) {
          e.preventDefault();
          e.dataTransfer.dropEffect = "move";
        }
        if (dropTarget !== tile && tile) {
          if (dropTarget !== null) {
            dropTarget.classList.remove("drag-over");
          }
          dropTarget = tile;
          dropTarget.classList.add("drag-over");
        }
      }
    });
    panel.addEventListener("drop", (e) => {
      if (dragSource && dropTarget) {
        e.stopPropagation();
        e.stopImmediatePropagation();
        e.preventDefault();
        const srcIndex = getIndex(dragSource);
        const dstIndex = getIndex(dropTarget);
        const refChild =
          srcIndex > dstIndex ? dropTarget : dropTarget.nextElementSibling;
        dragSource.parentElement.insertBefore(dragSource, refChild);
        dragSource.focus();
      }
    });
    panel.addEventListener("dragend", () => {
      dragSource.classList.remove("drag-source");
      dropTarget.classList.remove("drag-over");
      dragSource = null;
      dropTarget = null;
    });
    function getIndex(e) {
      const p = e.parentElement;
      for (let i = 0; i < p.children.length; i++) {
        if (p.children[i] === e) return i;
      }
    }
  }
  const renderMenu = (
    <div
      className="dynamic-dashboard-menu"
      style={dynamicMenuExpend ? { width: "250px" } : { width: "45px" }}
    >
      <div onClick={() => setDynamicMenuExpend(!dynamicMenuExpend)}>
        <AiOutlineMenu className="expend-icon" />
      </div>
      {tileCatalog.map((item) => (
        <div
          className="dynamic-dashboard-menu-item"
          key={`Menu ${item.name}`}
          title={item.name}
          onClick={() => addTile(item.name, item.data)}
        >
          <svg width="24" viewBox="0 0 64 64">
            {item.icon.map((entity, key) => (
              <React.Fragment key={`Menu Item ${key}`}>{entity}</React.Fragment>
            ))}
          </svg>
          <div className="dynamic-dashboard-menu-name">{item.name}</div>
        </div>
      ))}
    </div>
  );

  const renderTiles = (
    <div
      className="row dynamic-dashboard-body"
      style={
        dynamicMenuExpend
          ? { width: "calc(100% - 100px)" }
          : { width: "calc(100% - 45px)" }
      }
    >
      {tiles.map((tile, index) => {
        return (
          <Tile
            header={tile.name}
            content={getTileContent(tile.name)}
            index={index}
            key={tile.key}
            onRemove={removeTile.bind(this)}
          />
        );
      })}
    </div>
  );

  return (
    <div className="dyanmic-dashboard">
      {renderMenu}
      {renderTiles}
    </div>
  );
}

DynamicDashboard.propTypes = {
  tileCatalog: PropTypes.array,
  setTiles: PropTypes.func,
  state: PropTypes.object,
  tiles: PropTypes.array,
  dynamicMenuExpend: PropTypes.bool,
  setDynamicMenuExpend: PropTypes.func,
};
