export const changeMonth = ({ setMonth, func, year, month }) => {
  setMonth((prev) => {
    if (month > 4) return { ...prev, month: month - 1 };
    else return prev;
  });
  if (month > 4) func(year, month - 1);
};

export const classChangeMonth = ({ data, func, subfunc, flex }) => {
  let searchData = {
    salesPlanMonth:
      data.salesPlanMonth > 4 ? data.salesPlanMonth - 1 : data.salesPlanMonth,
    salesPlanYear: data.salesPlanYear,
  };
  if (data.salesPlanMonth > 4) {
    func(searchData, subfunc, flex);
  }
  return searchData;
};
