import { ActionIcon, Flex, Group, Select, TextInput } from "@mantine/core";
import { IconPlus, IconSquareRoundedX } from "@tabler/icons-react";
import React from "react";
import { AGGREGATION } from "../../../../utilities/constants";
import {
  SELECTITEMCOMPONENT,
  SELECTITEMLIST,
} from "../../../../utilities/utilities";
import PropTypes from "prop-types";

const SearchQueryColumnsList = (props) => {
  const {
    column,
    filteredColumnList,
    index,
    handleRemoveColumn,
    handleColumsChange,
  } = props;
  return (
    <Group>
      <Select
        label="Column"
        placeholder="Choose"
        searchable
        itemComponent={SELECTITEMCOMPONENT}
        maxDropdownHeight={200}
        data={[...filteredColumnList, { label: "*", value: "*", type: "text" }]}
        value={column.columnName}
        onChange={(value) => handleColumsChange(index, "columnName", value)}
        filter={(value, item) =>
          item.label.toLowerCase().includes(value.toLowerCase().trim())
        }
      />
      <Select
        searchable
        label="Aggregation (Optional)"
        placeholder="Choose"
        data={AGGREGATION}
        value={column.aggregation}
        onChange={(value) => handleColumsChange(index, "aggregation", value)}
      />
      <TextInput
        label="Alias (Optional)"
        placeholder="Enter Alias"
        value={column.alias}
        onChange={(event) =>
          handleColumsChange(index, "alias", event.currentTarget.value)
        }
      />
      <ActionIcon
        variant="filled"
        color="red"
        sx={{ marginTop: 25 }}
        onClick={() => handleRemoveColumn(index, "columns", index)}
      >
        <IconSquareRoundedX size="1rem" />
      </ActionIcon>
    </Group>
  );
};

const SearchQueryColumns = (props) => {
  const {
    tableName,
    columns,
    tableList,
    handleRemoveColumn,
    handleColumsChange,
    handleAddColumn,
  } = props;

  let filteredColumnList = [];

  if (tableName) {
    filteredColumnList = SELECTITEMLIST(tableList, tableName);
  }

  return (
    <Flex
      mih={50}
      bg="#eee"
      p={5}
      justify="center"
      align="flex-start"
      direction="column"
      wrap="wrap"
    >
      {columns?.map((column, i) => (
        <SearchQueryColumnsList
          key={i}
          column={column}
          index={i}
          tableName={tableName}
          filteredColumnList={filteredColumnList}
          handleRemoveColumn={handleRemoveColumn}
          handleColumsChange={handleColumsChange}
        />
      ))}
      <ActionIcon
        variant="filled"
        color="indigo"
        onClick={handleAddColumn}
        mt={"xs"}
      >
        <IconPlus size="1rem" />
      </ActionIcon>
    </Flex>
  );
};

export default SearchQueryColumns;

SearchQueryColumnsList.propTypes = {
  column: PropTypes.any,
  filteredColumnList: PropTypes.any,
  index: PropTypes.any,
  handleRemoveColumn: PropTypes.any,
  handleColumsChange: PropTypes.any,
};

SearchQueryColumns.propTypes = {
  tableName: PropTypes.any,
  columns: PropTypes.any,
  tableList: PropTypes.any,
  handleRemoveColumn: PropTypes.any,
  handleColumsChange: PropTypes.any,
  handleAddColumn: PropTypes.any,
};
