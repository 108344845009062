import React, { useState } from "react";

import "@grapecity/wijmo.chart.render";
import {
  FlexChart,
  FlexChartAxis,
  FlexChartDataLabel,
  FlexChartLegend,
  FlexChartSeries,
} from "@grapecity/wijmo.react.chart";
import { FlexChartAnimation } from "@grapecity/wijmo.react.chart.animation";
import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";

import { useAtom } from "jotai";
import { metadataAtom } from "../state/chart-item-atom";

import PropTypes from "prop-types";
import { DataConveterBy } from "../utilities/utilities";

const BarChartLine = (props) => {
  const { datasources, chartInitalized } = props;
  // Provider Items
  const [metadataState] = useAtom(metadataAtom);
  const [palette] = useState([
    "rgba(70,107,176,1)",
    "rgba(200,180,34,1)",
    "rgba(162, 4, 4, 0.53)",
    "rgba(162, 4, 4, 0.53)",
  ]);

  const serverData = DataConveterBy(
    metadataState,
    datasources.configure.bindingX,
    [...datasources.configure.bindingY, datasources.configure.bindingLine]
  );

  return (
    <div>
      <FlexChart
        initialized={chartInitalized}
        header={datasources.header}
        bindingX={datasources.configure.bindingX}
        itemsSource={serverData}
        stacking={datasources.settings.stacked}
        palette={palette}
        isAnimated={true}
        legendToggle={datasources.settings.legendToggle}
      >
        {datasources.configure.bindingY.map((item) => (
          <FlexChartSeries
            name={item}
            binding={item}
            key={`bar-chart-line-${item}`}
          />
        ))}
        <FlexChartSeries
          name={datasources.configure.bindingLine}
          binding={datasources.configure.bindingLine}
          chartType="LineSymbols"
          style={{ stroke: "orange", strokeWidth: 2 }}
          symbolStyle={{ fill: "orange", stroke: "orange", strokeWidth: 1 }}
        >
          <FlexChartAxis
            title={datasources.settings.rightAxisTitle}
            wjProperty="axisY"
            position="Right"
            axisLine={true}
            min={0}
          />
        </FlexChartSeries>
        <FlexChartAxis
          title={datasources.settings.leftAxisTitle}
          wjProperty="axisY"
          axisLine={true}
          origin={-10}
        />
        <FlexChartLegend position={datasources.settings.legendPosition} />
        <FlexChartDataLabel
          position={datasources.settings.dataLabelPosition}
          content="{y}"
        />
        <FlexChartAnimation />
      </FlexChart>
    </div>
  );
};

export default BarChartLine;

BarChartLine.propTypes = {
  datasources: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  chartInitalized: PropTypes.func,
};
