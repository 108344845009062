import {
  Box,
  Button,
  ColorInput,
  Divider,
  Flex,
  Grid,
  NumberInput,
  Select,
  Tabs,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { IconBinaryTree2, IconSettings } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { CURRENCY, UNIT, UNITCATEGORY } from "../../../utilities/constants";
import PropTypes from "prop-types";

function FindCategoryData(column_names, data_rows, searchable) {
  const findAttributeIndex = column_names.indexOf(searchable);
  let result = data_rows.map((row) => row[findAttributeIndex]);
  // Removing null values
  result = result.filter((item) => item !== null);
  // Removing duplicates
  result = [...new Set(result)];
  return result;
}

const ProgressCardEditor = (props) => {
  const { widget, metacolumn, oldDatasource, handleWidgetSettingClose } = props;
  // Provider Items
  const { data_rows, attributes_names, measures_names, column_names } =
    metacolumn;
  // Searchable state
  const [searchable, setSearchable] = useState(null);
  const [categoryData, setCategoryData] = useState([]);

  useEffect(() => {
    let oldSearchable =
      oldDatasource?.configure.searchable || attributes_names[0];
    setSearchable(oldSearchable);
    let SearchableData = FindCategoryData(
      column_names,
      data_rows,
      oldSearchable
    );
    setCategoryData(SearchableData);
  }, []);

  const form = useForm({
    initialValues: {
      title: oldDatasource?.title || "",
      header: oldDatasource?.header || null,
      category: oldDatasource?.configure?.category || "",
      actual: oldDatasource?.configure?.actual || "",
      plan: oldDatasource?.configure?.plan || "",
      unitCategory: oldDatasource?.settings?.unitCategory || "number",
      unit: oldDatasource?.settings?.unit || UNIT[0].value,
      currency: oldDatasource?.settings?.currency || CURRENCY[0].value,
      decimal: oldDatasource?.settings?.decimal || 0,
      bgColor: oldDatasource?.settings?.bgColor || "#ffffff",
      textColor: oldDatasource?.settings?.textColor || "#000000",
      progressColor: oldDatasource?.settings?.progressColor || "#F76707",
    },

    // functions will be used to validate values at corresponding key
    validate: {
      title: isNotEmpty("Widget title cannot be empty"),
      category: isNotEmpty("Category cannot be empty"),
      bgColor: isNotEmpty("Background color cannot be empty"),
      textColor: isNotEmpty("Text color cannot be empty"),
      progressColor: isNotEmpty("Progress color cannot be empty"),
    },

    transformValues: (values) => ({
      title: values.title,
      header: values.header,
      configure: {
        category: values.category,
        actual: values.actual,
        plan: values.plan,
      },
      settings: {
        unitCategory: values.unitCategory,
        unit: values.unit,
        currency: values.currency,
        decimal: values.decimal,
        bgColor: values.bgColor,
        textColor: values.textColor,
        progressColor: values.progressColor,
      },
    }),
  });

  let transformValues = oldDatasource?.settings?.unitCategory || "number";

  if (form.isDirty("unitCategory")) {
    let fromValues = form.getTransformedValues();
    transformValues = fromValues.settings.unitCategory;
  }

  const handleSearchableChange = (value) => {
    setSearchable(value);
    form.setFieldValue("category", "");
    let SearchableData = FindCategoryData(column_names, data_rows, value);
    setCategoryData(SearchableData);
  };

  // Handle Submit
  const handleOnSubmit = (values) => {
    const newDatasource = {
      title: values.title,
      header: null,
      type: widget.type,
      configure: {
        category: values.configure.category,
        actual: values.configure.actual,
        plan: values.configure.plan,
        searchable: searchable,
      },
      settings: {
        unitCategory: values.settings.unitCategory,
        unit: values.settings.unit,
        currency: values.settings.currency,
        decimal: values.settings.decimal,
        bgColor: values.settings.bgColor,
        textColor: values.settings.textColor,
        progressColor: values.settings.progressColor,
      },
    };
    form.reset();
    handleWidgetSettingClose(newDatasource);
  };

  return (
    <Box miw={220} mx="auto">
      <form
        onSubmit={form.onSubmit((values) => {
          handleOnSubmit(values);
        })}
      >
        <Grid align="center" gutter="xs">
          <Grid.Col span={4}>
            <Text weight={400} size="sm">
              Widget Title
            </Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              withAsterisk
              placeholder="Widget Title"
              {...form.getInputProps("title")}
            />
          </Grid.Col>
        </Grid>
        <Tabs radius="xs" defaultValue="configure">
          <Tabs.List>
            <Tabs.Tab
              value="configure"
              icon={<IconBinaryTree2 size="0.8rem" />}
            >
              Configure
            </Tabs.Tab>
            <Tabs.Tab value="settings" icon={<IconSettings size="0.8rem" />}>
              Settings
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel
            value="configure"
            pt="xs"
            h={"calc(70vh - 40px)"}
            style={{ overflow: "hidden" }}
          >
            <Grid align="center" gutter="xs">
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Category
                </Text>
              </Grid.Col>
              <Grid.Col span={4}>
                <Select
                  searchable
                  withAsterisk
                  nothingFound="No options"
                  placeholder="Choose Searchable..."
                  maxDropdownHeight={120}
                  data={attributes_names}
                  value={searchable}
                  onChange={handleSearchableChange}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <Select
                  searchable
                  withAsterisk
                  nothingFound="No options"
                  placeholder="Choose Category..."
                  maxDropdownHeight={120}
                  data={categoryData}
                  {...form.getInputProps("category")}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Plan
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  searchable
                  withAsterisk
                  nothingFound="No options"
                  placeholder="Choose Plan..."
                  maxDropdownHeight={120}
                  data={measures_names}
                  {...form.getInputProps("plan")}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Actual
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  searchable
                  withAsterisk
                  nothingFound="No options"
                  placeholder="Choose Actual..."
                  maxDropdownHeight={120}
                  data={measures_names}
                  {...form.getInputProps("actual")}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>

          <Tabs.Panel value="settings" pt="xs" h={"calc(70vh - 40px)"}>
            <Grid align="center" gutter="xs">
              <Grid.Col span={12}>
                <Title order={6}>Display</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Background Color
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <ColorInput {...form.getInputProps("bgColor")} />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Text Color
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <ColorInput {...form.getInputProps("textColor")} />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Progress Color
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <ColorInput {...form.getInputProps("progressColor")} />
              </Grid.Col>
              <Grid.Col span={12}>
                <Title order={6}>Format</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Category
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  searchable
                  nothingFound="No options"
                  placeholder="Choose Unit Category..."
                  data={UNITCATEGORY}
                  {...form.getInputProps("unitCategory")}
                />
              </Grid.Col>
              {transformValues === "number" ? (
                <React.Fragment>
                  <Grid.Col span={4}>
                    <Text weight={400} size="sm">
                      Unit
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <Select
                      searchable
                      nothingFound="No options"
                      placeholder="Choose Unit..."
                      data={UNIT}
                      {...form.getInputProps("unit")}
                    />
                  </Grid.Col>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid.Col span={4}>
                    <Text weight={400} size="sm">
                      Currency
                    </Text>
                  </Grid.Col>
                  <Grid.Col span={8}>
                    <Select
                      searchable
                      nothingFound="No options"
                      placeholder="Choose Currency..."
                      data={CURRENCY}
                      {...form.getInputProps("currency")}
                    />
                  </Grid.Col>
                </React.Fragment>
              )}
              <React.Fragment>
                <Grid.Col span={4}>
                  <Text weight={400} size="sm">
                    Decimal
                  </Text>
                </Grid.Col>
                <Grid.Col span={8}>
                  <NumberInput {...form.getInputProps("decimal")} />
                </Grid.Col>
              </React.Fragment>
            </Grid>
          </Tabs.Panel>
        </Tabs>
        <Divider my={5} variant="dotted" />
        <Flex
          mih={"auto"}
          gap="md"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button type="submit" color="indigo">
            {oldDatasource ? "Update" : "Add"}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default ProgressCardEditor;

ProgressCardEditor.propTypes = {
  widget: PropTypes.any,
  metacolumn: PropTypes.any,
  oldDatasource: PropTypes.any,
  handleWidgetSettingClose: PropTypes.func,
};
