import React from "react";
import { Center } from "@mantine/core";
import {
  IconCalendarTime,
  IconClock,
  IconDecimal,
  IconMathSymbols,
  IconSortAscending,
  IconSortDescending,
} from "@tabler/icons-react";
import { environment } from "../../../constants/environment";

export const BASEURL = "/json";
// export const BASEURL = environment.apiUrl;

export const SCHEMA_API = `${BASEURL}/schema-list.json`;
// export const SCHEMA_API = `${environment.apiUrl}/dashboard-service/schemeData`;
export const QUERY_LIST_API = `${BASEURL}/query-list.json`;

export const METADATA_API = `${BASEURL}/request`;
export const METADATA_DATA_API = `${BASEURL}/request/data`;
export const LAYOUT_API = `${BASEURL}/dashboard-list.json`;
export const DELETE_QUERY = `${environment.apiUrl}/dashboard-service/queryData`;
export const DELETE_DASHBOARD = `${environment.apiUrl}/dashboard-service/dashboardAnalysis`;
export const GET_DATA_BY_QUERY = `${environment.apiUrl}/dashboard-service/queryParamData`;
// export const get_data_query = `${environment.apiUrl}/dashboard-service/queryParamData`;
// export const SAVE_QUERY = `${BASEURL}/queries`;
export const SAVE_QUERY = `${environment.apiUrl}/dashboard-service/queryData`;
// export const SAVE_DASHBOARD = `${BASEURL}/dashboard`;
export const SAVE_DASHBOARD = `${environment.apiUrl}/dashboard-service/dashboardAnalysis`;
// export const GET_DASHBOARDLIST = `${BASEURL}/dashboard-list.json`;
export const GET_DASHBOARDLIST = `${environment.apiUrl}/dashboard-service/dashboardAnalysis`;
// export const GET_QUERYLIST = `${BASEURL}/query-list.json`;
export const GET_QUERYLIST = `${environment.apiUrl}/dashboard-service/queryData`;
export const CREATE_DASHBOARD = `${BASEURL}/dashboard`;

export const ITEMS_PER_PAGE = 10;

export const INITIAL_LAYOUT = {
  cols: 12,
  activeSection: "main",
  breakpoint: "lg",
  sections: [
    {
      sectionValue: "main",
      sectionName: "Main",
      items: [],
      layouts: { lg: [] },
    },
  ],
};

export const LOCALHOST = "http://localhost:3000/";

export const DASHBOARDLIST_API = "/json/dashboard-list.json";

export const QUERYLIST_API = "/json/query-list.json";

export const SEARCH_QUERY_FORMAT = [{ value: "table", label: "Table" }];

export const AGGREGATION = ["AVG", "COUNT", "MAX", "MIN", "SUM"];

export const ALIAS = ["time", "value"];

export const FILTERTYPE = ["AND", "OR"];

export const ORDERLIST = [
  {
    value: "ASC",
    label: (
      <Center>
        <IconSortAscending size="1rem" />
      </Center>
    ),
  },
  {
    value: "DESC",
    label: (
      <Center>
        <IconSortDescending size="1rem" />
      </Center>
    ),
  },
];

export const FILTEROPERATOR = [
  { label: "==", value: "#name = '#string'" },
  { label: "!=", value: "#name <> '#string'" },
  { label: "Contains", value: "#name LIKE '%#string%'" },
  { label: "Not Contains", value: "#name NOT LIKE '%#string%'" },
  { label: "Starts With", value: "#name LIKE '#string%'" },
  { label: "Ends With", value: "#name LIKE '%#string'" },
  {
    label: "Proximity Search",
    value: "CONTAINS(#name, 'NEAR((#firstword, #secondword), #number)')",
  },
  { label: "Is Empty", value: "COALESCE(#name, '') = '' " },
  { label: "Is Not Empty", value: "COALESCE(#name, '') <> '' " },
  { label: "Is Null", value: "#name IS NULL" },
  { label: "Is Not Null", value: "#name IS NOT NULL" },
  { label: "Any In", value: "#name IN ('')" },
  { label: "Not In", value: "#name NOT IN ('oouoiuo')" },
];

export const FILTEROPERATORNUMBER = [
  { label: "==", value: "#name = #number" },
  { label: "!=", value: "#name <> #number" },
  { label: ">", value: "#name > #number" },
  { label: ">=", value: "#name >= #number" },
  { label: "<", value: "#name < #number" },
  { label: "<=", value: "#name <= #number" },
  { label: "Between", value: "#name BETWEEN #firstnumber AND #secondnumber" },
  {
    label: "Not Between",
    value: "#name NOt BETWEEN #firstnumber AND #secondnumber",
  },
];

export const FILTEROPERATORDATE = [
  { label: "==", value: "#name = '#date'" },
  { label: "!=", value: "#name <> '#date'" },
  { label: ">", value: "#name > '#date'" },
  { label: ">=", value: "#name >= '#date'" },
  { label: "<", value: "#name < '#date'" },
  { label: "<=", value: "#name <= '#date'" },
  { label: "Between", value: "#name BETWEEN '#firstdate' AND '#seconddate'" },
  {
    label: "Not Between",
    value: "#name NOt BETWEEN '#firstdate' AND '#seconddate'",
  },
];

export const FILTEROPERATORDATETIME = [
  { label: "==", value: "#name = '#datetime'" },
  { label: "!=", value: "#name <> '#datetime'" },
  { label: ">", value: "#name > '#datetime'" },
  { label: ">=", value: "#name >= '#datetime'" },
  { label: "<", value: "#name < '#datetime'" },
  { label: "<=", value: "#name <= '#datetime'" },
  {
    label: "Between",
    value: "#name BETWEEN '#firstdatetime' AND '#seconddatetime'",
  },
  {
    label: "Not Between",
    value: "#name NOt BETWEEN '#firstdatetime' AND '#seconddatetime'",
  },
];

export const NEAR = ["2", "3", "4", "5", "6", "7", "8", "9", "10"];

export const UNITCATEGORY = [
  { label: "Number", value: "number" },
  { label: "Currency", value: "currency" },
];

export const UNIT = [
  { label: "Auto", value: " " },
  { label: "Thousand (K)", value: "K" },
  { label: "Million (M)", value: "M" },
  { label: "Billion (B)", value: "B" },
  { label: "Trillion (T)", value: "T" },
];

export const CURRENCY = [
  { label: "USD", value: "$" },
  { label: "YEN", value: "¥" },
  { label: "MMK", value: "MMK" },
];

export const ANSWERDATA = [
  {
    label: "Dashboard",
    value: "dashboardMetadata",
  },
  {
    label: "Financial",
    value: "financialMetadata",
  },
];

export const SELECTITEMICONS = {
  number: <IconMathSymbols />,
  decimal: <IconDecimal />,
  date: <IconClock />,
  datetime: <IconCalendarTime />,
};

export const SELECTITEMCOLORS = {
  number: "gray",
  decimal: "blue",
  date: "teal",
  datetime: "orange",
};
