import * as wjcGrid from "@grapecity/wijmo.grid";

export function hideRowDefault(bcAnnualList, sectorList, flex) {
  const rowNums = [9, 16, 23, 30, 37, 44];
  let curRow = 0;
  flex.formatItem.addHandler(function (s, e) {
    for (let i = 0; i < rowNums.length; i++) {
      curRow = rowNums[i];
      if (e.row === curRow) {
        e.cell.classList.add("sectorStart");
      }
    }
  });

  // add cell color when value is minus
  for (let col = 4; col < 67; col++) {
    if (flex.getCellData(1, col) === "ボトム") {
      flex.applyCellsStyle(
        {
          fontWeight: "bold",
        },
        [new wjcGrid.CellRange(1, col, 1, col)]
      );
    }
    if (
      flex.getCellData(1, col) === "前回差" ||
      flex.getCellData(1, col) === "予算差"
    ) {
      for (let row = 2; row < 160; row++) {
        if (
          flex.getCellData(row, col) < 0 ||
          (isNaN(flex.getCellData(row, col)) &&
            parseFloat(flex.getCellData(row, col).split("%")[0]) < 0)
        ) {
          flex.applyCellsStyle(
            {
              color: "red",
            },
            [new wjcGrid.CellRange(row, col, row, col)]
          );
        } else {
          flex.applyCellsStyle(
            {
              color: "blue",
            },
            [
              new wjcGrid.CellRange(row, col, row, col),
              new wjcGrid.CellRange(row, col, row, col),
            ]
          );
        }
      }
    }
  }

  var initialHideRows = [];
  for (let row = 0; row < bcAnnualList.length + 20; row++) {
    let row1 = flex.getCellData(row, 1);
    if (row1 === "costs") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }
  }
  let row = 9;
  for (let r = 0; r < sectorList.length + 45; r++) {
    var row0 = flex.getCellData(row, 0);
    var row1 = flex.getCellData(row, 1);

    if (flex.getCellData(row, 0) === "SGA") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (row0 !== "JP Business") {
      if (flex.getCellData(row, 1) === "原価") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "costs") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "営業外収益") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "営業外費用") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "経常利益") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "経常利益率") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "販管費") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "営業利益") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }

      if (row1 === "営業利益率") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }
    }

    if (row0 === "JP Business") {
      if (row1 === "costs") {
        flex.rows[row].visible = false;
        initialHideRows = [...initialHideRows, flex.rows[row]];
      }
    }
    row++;
  }
  return initialHideRows;
}
