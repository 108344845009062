import "@grapecity/wijmo.touch";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import * as wijmo from "@grapecity/wijmo";
import * as wjcCore from "@grapecity/wijmo";
import {
  closest,
  contains,
  createElement,
  getElement,
  hasClass,
  hidePopup,
  removeChild,
  showPopup,
} from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid";
import { AllowSorting, DataMap } from "@grapecity/wijmo.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import * as wjFlexGrid from "@grapecity/wijmo.react.grid";
import { ListBox } from "@grapecity/wijmo.react.input";
import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";
import moment from "moment/moment";
import * as React from "react";
import PropTypes from "prop-types";
import { AiFillLock, AiOutlineUpload } from "react-icons/ai";
import { BiSave, BiSearchAlt2 } from "react-icons/bi";
import { FaRegClone } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { Spinner } from "reactstrap";
import "../../assets/css/salesPlan.css";
import {
  BUSINESS_SECTOR_LIST_URL,
  FISCAL_YEAR_LIST_URL,
  QUARTER_LIST,
  SALES_PLAN_CREATE_UPDATE_URL,
  SALES_PLAN_DELETE_URL,
  SALES_PLAN_DRAG_URL,
  SALES_PLAN_FINALLOCK_CONFIRM,
  SALES_PLAN_LOCK_CONFIRM,
  SALES_PLAN_LOCK_URL,
  SALES_PLAN_SEARCH_URL,
  EXCHANGE_RATE_LIST_URL,
  TOKEN,
} from "../../constants/api.path";
import CloneModal from "../../modalPopups/cloneModal";
import ConfirmModal from "../../modalPopups/confirmModal";
import InfoModal from "../../modalPopups/infoModal";
import SuccessModal from "../../modalPopups/successModal";
import cacheUpdate from "../cacheUpdate/cacheUpdate";
import {
  calAmountCell,
  salesAndGPHeaderCell,
  calGPPercent,
} from "./autoCalSalesPlan";
import { classChangeMonth } from "../../utilities/utilities";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

var deleteMessage = "Are you sure to want to delete?";
var updateMessage = "Are you sure to want to update and save?";
var deleteWarning = "Please select at least one row!";
var blankMessage = "There is no sales plan for this month!";
var LockMessage;

// top-left cells, merge
var CustomMergeManager = function () {
  wjcGrid.MergeManager.apply(this, arguments);
};
CustomMergeManager.prototype = new wjcGrid.MergeManager();
CustomMergeManager.prototype.getMergedRange = function (panel) {
  if (panel.cellType === wjcGrid.CellType.TopLeft) {
    var range = new wjcGrid.CellRange(
      0,
      0,
      panel.rows.length - 1,
      panel.columns.length - 1
    );
    return range;
  } else {
    return wjcGrid.MergeManager.prototype.getMergedRange.apply(this, arguments);
  }
};

var sectors = [];
var sectorSerial = [];

class SalesPlan extends React.Component {
  constructor(props) {
    super(props);
    this.isDragEnabled = false;
    this.dragSrc = null;
    this.dragDst = null;
    this.state = {
      salesPlanList: [],
      sectorList: [],
      customerGroup: [
        { id: 1, name: "NESIC" },
        { id: 2, name: "Others" },
      ],
      fiscalList: [],
      quarters: [],
      months: [],
      state: [
        { id: 1, name: "Forecasting" },
        { id: 2, name: "Ongoing" },
        { id: 3, name: "Completed" },
      ],
      rank: [
        { id: 0, name: "None" },
        { id: 1, name: "A" },
        { id: 2, name: "B" },
        { id: 3, name: "C" },
        { id: 4, name: "D" },
        { id: 5, name: "E" },
      ],
      poMonths: [],
      salesMonths: [],
      selectedArray: { salesPlanMonth: new Date().getMonth() + 1 },
      view: new wijmo.CollectionView(),
      showConfirmModal: "",
      showSuccessModal: "",
      showInfoModal: "",
      resConfirm: "",
      resultMessage: "",
      value: "",
      colGrid: null,
      isLoading: false,
      fileName: "",
      allowSorting: AllowSorting.MultiColumn,
      filteringColmns: [
        "salesPlanKey",
        "status",
        "orderNumber",
        "projectName",
        "customerGroup",
        "sectorId",
        "state",
        "poCurrencyAmount",
        "poExchangeRate",
        "poAmount",
        "salesCurrencyAmount",
        "salesExchangeRate",
        "salesAmount",
        "diffPoAmount",
        "costPercent",
        "planCost",
        "actualCost",
        "poQuarterId",
        "poMonth",
        "poRank",
        "salesQuarterId",
        "salesMonth",
        "salesRank",
      ],
      tally: { cnt: 0, cntAll: 0, sum: 0 },
      dragRow: [],
      changeLocation: props.changeLocation,
      salesAmountTitle: [],
      exchangeRateList: [],
      exchangeRate: "",
      cardsColor: [
        "#2f5597",
        "#c55a11",
        "#7c7c7c",
        "#247282",
        "#2e75b6",
        "#267a44",
      ],
      selectedFiscalYear: 0,
      selectedMonth: new Date().getMonth() + 1,
      salesPlanMonthList: [],
      showModal: false,
      cloneTitle: "Clone For",
      updateSalesPlanId: null,
      addRowSectorId: null,
      open: true,
      salesPlanYearName: [],
      salesPlanMonthName: [],
      expand: true,
      initialState: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.getSearchData = this.getSearchData.bind(this);
    this.handleDraggingRow = (s, e) => {
      this.dragIndex = e.row;
      s.collectionView.moveCurrentToPosition(this.dragIndex);
      let draggingRow = [
        {
          salesPlanId: s.getCellData(e.row, 48),
          sectorId: s.getCellData(e.row, 6),
          sortingId: s.getCellData(e.row, 47),
        },
      ];
      this.setState({ dragRow: draggingRow }, () => {});
    };
    this.handleDraggedRow = (s, e) => {
      this.dropIndex = e.row;
      let arr = s.collectionView.sourceCollection;
      s.collectionView.deferUpdate(() => {
        let item = arr[this.dragIndex];
        arr.splice(this.dragIndex, 1);
        arr.splice(this.dropIndex, 0, item);
        s.collectionView.moveCurrentToPosition(this.dropIndex);
      });

      let draggingUpRow = [
        {
          salesPlanId: s.getCellData(e.row - 1, 48),
          sectorId: s.getCellData(e.row - 1, 6),
          sortingId: s.getCellData(e.row - 1, 47),
        },
      ];
      let draggingDownRow = [
        {
          salesPlanId: s.getCellData(e.row + 1, 48),
          sectorId: s.getCellData(e.row + 1, 6),
          sortingId: s.getCellData(e.row + 1, 47),
        },
      ];

      this.dragRow(this.state.dragRow, draggingUpRow, draggingDownRow);
    };
  }

  componentDidMount() {
    fetch(BUSINESS_SECTOR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ sectorList: result.data.sectorList }, () => {
          sectors = this.state.sectorList;
          for (let i = 0; i < sectors.length; i++) {
            sectorSerial = [
              ...sectorSerial,
              {
                id: i + 1,
                name: sectors[i].sectorName,
                serial: i < 10 ? "0" + (i + 1) + "." : i + 1 + ".",
              },
            ];
          }
        });
      });

    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState(
          {
            fiscalList: result.data.fiscalYearList,
            selectedArray: {
              ...this.state.selectedArray,
              salesPlanYear: result.data.currentYearId,
            },
          },
          () => {
            this.salesPlanMonthUpdate(result.data.currentYearId);
            this.salesPlanList();
            this.getYearMonthName();
          }
        );
      });

    fetch(QUARTER_LIST, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        const quarterList = [
          {
            fyId: 0,
            quarterId: 0,
            quarterName: "None",
            qStartDate: " ",
            qEndDate: " ",
          },
          ...result.data.quarterList,
        ];
        this.setState({ quarters: quarterList });
        this.calculateMonths(quarterList);
      });

    if (this.flex) {
      this.columnPicker.itemsSource = this.flex.columns;
      this.columnPicker.checkedMemberPath = "visible";
      this.columnPicker.displayMemberPath = "header";
      this.columnPicker.lostFocus.addHandler(() => {
        hidePopup(this.columnPicker.hostElement);
      });
      this.columnPicker.formatItem.addHandler((s, e) => {
        this.enableDragItem(e.item, this.isDragEnabled);
      });
    }
  }

  getYearMonthName() {
    this.state.fiscalList.forEach((fiscalList) => {
      if (this.state.selectedArray.salesPlanYear == fiscalList.fiscalYearId) {
        this.setState({
          salesPlanYearName: fiscalList.fiscalYear,
          salesPlanMonthName: moment()
            .month(this.state.selectedMonth - 1)
            .format("MMM"),
        });
      }
    });
  }

  exchangeRateUpdate(selectedArray) {
    let currentID = selectedArray.salesPlanYear;
    this.state.exchangeRateList.forEach((exchangeList) => {
      if (exchangeList.fiscalYearId == currentID) {
        this.setState({ exchangeRate: exchangeList.exchangeRate }, () => {});
      }
    });
  }

  salesPlanMonthUpdate(id) {
    let key = 0;
    let data = [];
    let currentID = id ? parseInt(id) : this.state.selectedFiscalYear;
    this.state.fiscalList.forEach((fiscalList) => {
      if (fiscalList.fiscalYearId === currentID) {
        for (
          let i = new Date(fiscalList.fyStartDate).getMonth();
          i < new Date(fiscalList.fyStartDate).getMonth() + 12;
          i++
        ) {
          data.push({
            id: key + 1,
            name: moment()
              .month(i < 12 ? i : i - 12)
              .format("MMM"),
            value: i + 1 < 13 ? i + 1 : i - 11,
          });
          key++;
        }
        this.setState({ salesPlanMonthList: data });
      }
    });
  }

  lockConfirm(selectedMonth) {
    fetch(SALES_PLAN_LOCK_CONFIRM + "/salesPlanMonth/" + selectedMonth, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState(
          {
            fileName: result.fileName,
          },
          () => {
            LockMessage =
              "Are you sure want to save '" + this.state.fileName + "'?";
            this.setState({ showConfirmModal: true }, () => {});
          }
        );
      });
  }

  saveBC(searchData) {
    this.setState({ isLoading: true });
    if (searchData.status === "") {
      searchData.status = undefined;
    }
    fetch(
      SALES_PLAN_LOCK_URL +
        "/keyword/lock/sectorId/" +
        searchData.sectorId +
        "/poMonth/" +
        searchData.poMonth +
        "/salesMonth/" +
        searchData.salesMonth +
        "/poRank/" +
        searchData.poRank +
        "/salesRank/" +
        searchData.salesRank +
        "/status/" +
        searchData.status +
        "/salesPlanYear/" +
        searchData.salesPlanYear +
        "/salesPlanMonth/" +
        searchData.salesPlanMonth,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    )
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ isLoading: false }, () => {
          if (result.result) {
            this.setState(
              {
                resultMessage: result.message,
                showSuccessModal: true,
              },
              () => {
                cacheUpdate(
                  this.state.selectedArray.salesPlanYear,
                  this.state.selectedArray.salesPlanMonth
                );
              }
            );
            this.setState({ resConfirm: false }, () => {});
          } else {
            this.setState({
              resultMessage: result.message,
              showInfoModal: true,
            });
          }
        });
      });
  }

  finalLockConfirm(selectedMonth) {
    fetch(SALES_PLAN_FINALLOCK_CONFIRM + "/salesPlanMonth/" + selectedMonth, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState(
          {
            fileName: result.fileName,
          },
          () => {
            LockMessage =
              "Are you sure want to save '" + this.state.fileName + "'?";
            this.setState({ showConfirmModal: true }, () => {});
          }
        );
      });
  }

  finalSaveBC(searchData) {
    this.setState({ isLoading: true });
    if (searchData.status === "") {
      searchData.status = undefined;
    }
    fetch(
      SALES_PLAN_LOCK_URL +
        "/keyword/finalLock/sectorId/" +
        searchData.sectorId +
        "/poMonth/" +
        searchData.poMonth +
        "/salesMonth/" +
        searchData.salesMonth +
        "/poRank/" +
        searchData.poRank +
        "/salesRank/" +
        searchData.salesRank +
        "/status/" +
        searchData.status +
        "/salesPlanYear/" +
        searchData.salesPlanYear +
        "/salesPlanMonth/" +
        searchData.salesPlanMonth,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    )
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ isLoading: false }, () => {
          if (result.result) {
            this.setState(
              {
                resultMessage: result.message,
                showSuccessModal: true,
              },
              () => {
                cacheUpdate(
                  this.state.selectedArray.salesPlanYear,
                  this.state.selectedArray.salesPlanMonth
                );
              }
            );
            this.setState({ resConfirm: false }, () => {});
          }
        });
      });
  }

  calculateMonths(quarters) {
    let monthsList = [];
    for (let i = 0; i < quarters.length; i++) {
      if (quarters[i].quarterId === 0) {
        monthsList.push({
          id: "0",
          quarter: quarters[i].quarterId,
          name: "None",
        });
      } else {
        let startDate = new Date(quarters[i].qStartDate).getMonth() + 1;
        let endDate = new Date(quarters[i].qEndDate).getMonth() + 1;
        for (let j = startDate; j < endDate + 1; j++) {
          let monthNumber = j;
          monthsList.push({
            id: monthNumber.toString(),
            quarter: quarters[i].quarterId,
            name: monthNumber.toString(),
          });
        }
      }
    }
    let poMonths = new DataMap(monthsList, "id", "name");
    poMonths.getDisplayValues = (dataItem) => {
      // if (dataItem.poQuarterId) {
      let validMonths = monthsList.filter(
        (month) =>
          month.quarter.toString() === dataItem?.poQuarterId?.toString() ||
          month.quarter === 0
      );
      return validMonths.map((month) => month.name);
      // }
    };
    this.setState({ poMonths: poMonths });
    let salesMonths = new DataMap(monthsList, "id", "name");
    salesMonths.getDisplayValues = (dataItem) => {
      // if (dataItem.salesQuarterId) {
      let validMonths = monthsList.filter(
        (month) =>
          month.quarter.toString() === dataItem?.salesQuarterId?.toString() ||
          month.quarter === 0
      );
      return validMonths.map((month) => month.name);
      // }
    };
    this.setState({ salesMonths: salesMonths });
  }

  handleChange(e) {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;

    const selectedValue = { ...this.state.selectedArray };
    selectedValue[fieldName] = fieldValue;

    this.setState({ selectedArray: selectedValue }, () => {});
  }

  dragRow(dragRow, dragUpRow, dragDownRow) {
    let draggedData = [
      { dragRow: dragRow, dragUpRow: dragUpRow, dragDownRow: dragDownRow },
    ];
    fetch(SALES_PLAN_DRAG_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify(draggedData),
    }).then(async (response) => {
      if (response.ok) {
        const res = await response.json();
        if (res.result) {
          this.salesPlanList();
        } else {
          alert("Move row is not successed!");
        }
      }
    });
  }

  getSearchData(searchData) {
    if (searchData.status === "") {
      searchData.status = undefined;
    }
    fetch(
      SALES_PLAN_SEARCH_URL +
        "/sectorId/" +
        searchData.sectorId +
        "/poMonth/" +
        searchData.poMonth +
        "/salesMonth/" +
        searchData.salesMonth +
        "/poRank/" +
        searchData.poRank +
        "/salesRank/" +
        searchData.salesRank +
        "/status/" +
        searchData.status +
        "/salesPlanYear/" +
        searchData.salesPlanYear +
        "/salesPlanMonth/" +
        searchData.salesPlanMonth,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    )
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        this.dataSet(result);
        this.setState(
          {
            salesAmountTitle: this.calculateSalesAmount(
              result.data.salesPlanList
            ),
          },
          () => {
            this.sumAfterFilterChanged();
          }
        );
      });
  }

  salesPlanList() {
    let searchData = this.state.selectedArray;
    if (searchData.status === "") {
      searchData.status = undefined;
    }
    fetch(
      SALES_PLAN_SEARCH_URL +
        "/sectorId/" +
        searchData.sectorId +
        "/poMonth/" +
        searchData.poMonth +
        "/salesMonth/" +
        searchData.salesMonth +
        "/poRank/" +
        searchData.poRank +
        "/salesRank/" +
        searchData.salesRank +
        "/status/" +
        searchData.status +
        "/salesPlanYear/" +
        searchData.salesPlanYear +
        "/salesPlanMonth/" +
        this.state.selectedMonth,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    )
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        if (result.totalCount === 0) {
          if (this.state.initialState) {
            let data = classChangeMonth({
              data: searchData,
              func: this.getSearchData,
            });
            this.setState({
              selectedFiscalYear: data.salesPlanYear,
              selectedMonth: data.salesPlanMonth,
              initialState: !this.state.initialState,
            });
          }
        } else this.dataSet(result);

        this.setState(
          {
            salesAmountTitle: this.calculateSalesAmount(
              result.data.salesPlanList
            ),
            selectedArray: {
              salesPlanYear: result.data.fiscalYearId,
              ...this.state.selectedArray,
            },
          },
          () => {
            let id = 0;
            result.data.salesPlanList.forEach((salesPlan, index) => {
              if (salesPlan.salesPlanId === this.state.updateSalesPlanId) {
                id = index;
              }
              if (
                this.state.updateSalesPlanId === null &&
                salesPlan.sectorId === this.state.addRowSectorId
              ) {
                id = index;
              }
            });
            this.flex.scrollIntoView(id, 3);
            this.flex.select(id, 3);

            this.setState({
              selectedFiscalYear: this.state.selectedArray.salesPlanYear,
            });
            fetch(EXCHANGE_RATE_LIST_URL, {
              method: "GET",
              headers: {
                Authorization: `Bearer ${TOKEN}`,
              },
            })
              .then(async (response) => {
                return response.json();
              })
              .then((result) => {
                this.setState(
                  {
                    exchangeRateList: result.data.exchangeList,
                  },
                  () => {
                    this.exchangeRateUpdate(this.state.selectedArray);
                  }
                );
              });
          }
        );
      });
  }

  calculateSalesAmount(dataList) {
    this.flex.scrollIntoView(20, 4);
    this.flex.select(20, 3);

    let sectorList = this.state.sectorList;
    let sectorSalesAmount = [];
    let total = {
      totalSales: 0,
      totalOrder: dataList.length,
    };
    sectorList.forEach((sector) => {
      sectorSalesAmount.push({
        sectorName: sector.sectorName,
        binding: sector.sectorName,
      });
      total[sector.sectorName] = 0;
    });

    if (dataList.length > 0) {
      dataList.forEach(function (data) {
        total.totalSales += data.totalRowSales;
        for (let i = 0; i < sectorList.length; i++) {
          if (sectorList[i].sectorId === data.sectorId) {
            total[sectorSalesAmount[i].binding] += data.totalRowSales;
          }
        }
      });
    }
    let sectorSalesAmountTitle = [
      {
        id: 0,
        name: "Total Order",
        salesAmount: total.totalOrder,
      },
      {
        id: 1,
        name: "All",
        salesAmount: total.totalSales.toFixed(0),
      },
    ];
    for (let i = 0; i < sectorSalesAmount.length; i++) {
      sectorSalesAmountTitle.push({
        id: i + 1,
        name:
          sectorSalesAmount[i].sectorName === "Software"
            ? "DX/BPO"
            : sectorSalesAmount[i].sectorName === "HR"
            ? "JP"
            : sectorSalesAmount[i].sectorName,
        salesAmount: total[sectorSalesAmount[i].binding].toFixed(0),
      });
    }
    return sectorSalesAmountTitle;
  }

  dataSet(result) {
    var salesPlan = result.data.salesPlanList;
    // start generate sales plan key
    var series = 0;
    var seriedSalesPlan = [];
    var sectorLength = this.state.sectorList.length;
    var lastSector = this.state.sectorList[sectorLength - 1].sectorId;

    for (let i = 0; i < sectorLength; i++) {
      var oneSectorSalePlan = [];
      for (let j = 0; j < salesPlan.length; j++) {
        var currentSector = this.state.sectorList[i].sectorId;
        if (currentSector !== lastSector) {
          lastSector = currentSector;
          series = 0;
        }
        if (this.state.sectorList[i].sectorId === salesPlan[j].sectorId) {
          series++;
          if (series < 10) {
            salesPlan[j].key = "0" + series;
          } else {
            salesPlan[j].key = series.toString();
          }
          for (let k = 0; k < sectorSerial.length; k++) {
            if (sectorSerial[k].name === this.state.sectorList[i].sectorName) {
              salesPlan[j].salesPlanKey =
                sectorSerial[k].serial + salesPlan[j].key;
            }
          }
          oneSectorSalePlan.push(salesPlan[j]);
        }
      }
      seriedSalesPlan = [...seriedSalesPlan, ...oneSectorSalePlan];
    }
    salesPlan = seriedSalesPlan;
    // end generate sales plan key

    if (result.result) {
      if (result.data.salesPlanList.length > 0) {
        this.setState((state) => {
          for (let i = 0; i < result.data.salesPlanList.length; i++) {
            result.data.salesPlanList[i].active = false;
          }
          state.view.sourceCollection = result.data.salesPlanList;
          state.view.trackChanges = true;
        });
      } else {
        this.setState(
          {
            resultMessage: blankMessage,
            showInfoModal: true,
          },
          () => {}
        );
        this.setState((state) => {
          state.view.sourceCollection = result.data.salesPlanList;
          state.view.trackChanges = true;
        });
      }
      this.bindingHeaders(result, result.data.fiscalYearName);
    } else {
      this.bindingHeaders(result, null);
      this.setState(
        {
          resultMessage: result.message,
          showInfoModal: true,
        },
        () => {}
      );
    }
  }

  bindingHeaders(result, fyYear) {
    var panel = this.state.colGrid.columnHeaders;
    if (fyYear != null) {
      for (let colIndex = 24; colIndex <= 26; colIndex++) {
        panel.setCellData(1, colIndex, fyYear + " Total(MMK)");
      }
      salesAndGPHeaderCell(
        panel,
        fyYear,
        result.data.Quarter1,
        result.data.Quarter2,
        result.data.Quarter3,
        result.data.Quarter4,
        result.data.salesPlanList
      );
    } else {
      let currentYear = new Date().getFullYear();
      for (let colIndex = 24; colIndex <= 27; colIndex++) {
        panel.setCellData(
          1,
          colIndex,
          "FY" + currentYear.toString().slice(-2) + " Total(MMK)"
        );
      }

      let quarter1List = [
        {
          month1: "Apr",
          month2: "May",
          month3: "Jun",
        },
      ];

      let quarter2List = [
        {
          month1: "Jul",
          month2: "Aug",
          month3: "Sept",
        },
      ];

      let quarter3List = [
        {
          month1: "Oct",
          month2: "Nov",
          month3: "Dec",
        },
      ];

      let quarter4List = [
        {
          month1: "Jan",
          month2: "Feb",
          month3: "Mar",
        },
      ];

      salesAndGPHeaderCell(
        panel,
        currentYear.toString(),
        quarter1List,
        quarter2List,
        quarter3List,
        quarter4List,
        0
      );
    }
  }

  getFiscalYear() {
    return this.state.fiscalList.map((data) => {
      return { id: data.fiscalYearId, name: data.fiscalYear };
    });
  }

  getSector() {
    return this.state.sectorList.map((data) => {
      return { id: data.sectorId, name: data.sectorName };
    });
  }

  getCustomerGroup() {
    return this.state.customerGroup.map((data) => {
      return { id: data.id, name: data.name };
    });
  }

  getState() {
    return this.state.state.map((data) => {
      return { id: data.id, name: data.name };
    });
  }

  getRank() {
    return this.state.rank.map((data) => {
      return { id: data.id, name: data.name };
    });
  }

  requiredInputs() {
    // no need to podifferent
  }

  updateRow() {
    let added = { salesPlanList: [...this.state.view.itemsAdded] };
    let updated = {
      salesPlanList: [...this.state.view.itemsEdited],
    };
    if (added.salesPlanList.length > 0) {
      for (let i = 0; i < this.state.view.itemsAdded.length; i++) {
        if (this.state.view.itemsAdded[i].salesPlanId === undefined) {
          added.salesPlanList[i].salesPlanId = "";
        }
        if (this.state.view.itemsAdded[i].salesPlanYear === undefined) {
          added.salesPlanList[i].salesPlanYear =
            this.state.selectedArray.salesPlanYear;
        }
        if (this.state.view.itemsAdded[i].salesPlanMonth === undefined) {
          added.salesPlanList[i].salesPlanMonth =
            this.state.selectedArray.salesPlanMonth;
        }
        if (this.state.view.itemsAdded[i].status === undefined) {
          added.salesPlanList[i].status = null;
        }
        if (this.state.view.itemsAdded[i].orderNumber === undefined) {
          added.salesPlanList[i].orderNumber = null;
        }
        if (this.state.view.itemsAdded[i].customerGroup === undefined) {
          added.salesPlanList[i].customerGroup = null;
        }
        if (this.state.view.itemsAdded[i].sectorId === undefined) {
          added.salesPlanList[i].sectorId = null;
        }
        if (this.state.view.itemsAdded[i].state === undefined) {
          added.salesPlanList[i].state = null;
        }
        if (this.state.view.itemsAdded[i].poCurrencyAmount === undefined) {
          added.salesPlanList[i].poCurrencyAmount = null;
        }
        if (this.state.view.itemsAdded[i].poExchangeRate === undefined) {
          added.salesPlanList[i].poExchangeRate = null;
        }
        if (this.state.view.itemsAdded[i].poAmount === undefined) {
          added.salesPlanList[i].poAmount = null;
        }
        if (this.state.view.itemsAdded[i].salesCurrencyAmount === undefined) {
          added.salesPlanList[i].salesCurrencyAmount = null;
        }
        if (this.state.view.itemsAdded[i].salesExchangeRate === undefined) {
          added.salesPlanList[i].salesExchangeRate = null;
        }
        if (this.state.view.itemsAdded[i].salesAmount === undefined) {
          added.salesPlanList[i].salesAmount = null;
        }
        if (this.state.view.itemsAdded[i].diffPoAmount === undefined) {
          added.salesPlanList[i].diffPoAmount = null;
        }
        if (this.state.view.itemsAdded[i].costPercent === undefined) {
          added.salesPlanList[i].costPercent = null;
        } else {
          added.salesPlanList[i].costPercent =
            added.salesPlanList[i].costPercent * 100;
        }
        if (this.state.view.itemsAdded[i].planCost === undefined) {
          added.salesPlanList[i].planCost = null;
        }
        if (this.state.view.itemsAdded[i].actualCost === undefined) {
          added.salesPlanList[i].actualCost = null;
        }
        if (
          this.state.view.itemsAdded[i].poQuarterId === undefined ||
          this.state.view.itemsAdded[i].poQuarterId === 0
        ) {
          added.salesPlanList[i].poQuarterId = null;
        }
        if (
          this.state.view.itemsAdded[i].poMonth === undefined ||
          this.state.view.itemsAdded[i].poMonth === "0"
        ) {
          added.salesPlanList[i].poMonth = null;
        }
        if (
          this.state.view.itemsAdded[i].poRank === undefined ||
          this.state.view.itemsAdded[i].poRank === 0
        ) {
          added.salesPlanList[i].poRank = null;
        }
        if (
          this.state.view.itemsAdded[i].salesQuarterId === undefined ||
          this.state.view.itemsAdded[i].salesQuarterId === 0
        ) {
          added.salesPlanList[i].salesQuarterId = null;
        }
        if (
          this.state.view.itemsAdded[i].salesMonth === undefined ||
          this.state.view.itemsAdded[i].salesMonth === "0"
        ) {
          added.salesPlanList[i].salesMonth = null;
        }
        if (
          this.state.view.itemsAdded[i].salesRank === undefined ||
          this.state.view.itemsAdded[i].salesRank === 0
        ) {
          added.salesPlanList[i].salesRank = null;
        }
        if (this.state.view.itemsAdded[i].month1 === undefined) {
          added.salesPlanList[i].month1 = null;
        }
        if (this.state.view.itemsAdded[i].month2 === undefined) {
          added.salesPlanList[i].month2 = null;
        }
        if (this.state.view.itemsAdded[i].month3 === undefined) {
          added.salesPlanList[i].month3 = null;
        }
        if (this.state.view.itemsAdded[i].gpPercent1 === undefined) {
          added.salesPlanList[i].gpPercent1 = null;
        } else {
          added.salesPlanList[i].gpPercent1 =
            added.salesPlanList[i].gpPercent1 * 100;
        }
        if (this.state.view.itemsAdded[i].month4 === undefined) {
          added.salesPlanList[i].month4 = null;
        }
        if (this.state.view.itemsAdded[i].month5 === undefined) {
          added.salesPlanList[i].month5 = null;
        }
        if (this.state.view.itemsAdded[i].month6 === undefined) {
          added.salesPlanList[i].month6 = null;
        }
        if (this.state.view.itemsAdded[i].gpPercent2 === undefined) {
          added.salesPlanList[i].gpPercent2 = null;
        } else {
          added.salesPlanList[i].gpPercent2 =
            added.salesPlanList[i].gpPercent2 * 100;
        }
        if (this.state.view.itemsAdded[i].month7 === undefined) {
          added.salesPlanList[i].month7 = null;
        }
        if (this.state.view.itemsAdded[i].month8 === undefined) {
          added.salesPlanList[i].month8 = null;
        }
        if (this.state.view.itemsAdded[i].month9 === undefined) {
          added.salesPlanList[i].month9 = null;
        }
        if (this.state.view.itemsAdded[i].gpPercent3 === undefined) {
          added.salesPlanList[i].gpPercent3 = null;
        } else {
          added.salesPlanList[i].gpPercent3 =
            added.salesPlanList[i].gpPercent3 * 100;
        }
        if (this.state.view.itemsAdded[i].month10 === undefined) {
          added.salesPlanList[i].month10 = null;
        }
        if (this.state.view.itemsAdded[i].month11 === undefined) {
          added.salesPlanList[i].month11 = null;
        }
        if (this.state.view.itemsAdded[i].month12 === undefined) {
          added.salesPlanList[i].month12 = null;
        }
        if (this.state.view.itemsAdded[i].gpPercent4 === undefined) {
          added.salesPlanList[i].gpPercent4 = null;
        } else {
          added.salesPlanList[i].gpPercent4 =
            added.salesPlanList[i].gpPercent4 * 100;
        }
        if (this.state.view.itemsAdded[i].sortingId === undefined) {
          added.salesPlanList[i].sortingId = null;
        }
      }

      fetch(SALES_PLAN_CREATE_UPDATE_URL + "/keyword/grid", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
        body: JSON.stringify(added),
      }).then(async (response) => {
        if (response.ok) {
          const res = await response.json();
          if (res.result) {
            this.salesPlanList();
            this.setState({ resultMessage: res.message }, () => {
              this.setState({ showSuccessModal: true }, () => {});
              cacheUpdate(
                this.state.selectedArray.salesPlanYear,
                this.state.selectedArray.salesPlanMonth
              );
            });
          } else {
            this.setState({ resultMessage: res.message }, () => {
              this.setState({ showInfoModal: true }, () => {});
            });
          }
        }
      });
    }
    if (updated.salesPlanList.length > 0) {
      for (let i = 0; i < this.state.view.itemsEdited.length; i++) {
        if (this.state.view.itemsEdited[i].costPercent !== undefined) {
          updated.salesPlanList[i].costPercent =
            updated.salesPlanList[i].costPercent * 100;
        }
        if (this.state.view.itemsEdited[i].gpPercent1 !== undefined) {
          updated.salesPlanList[i].gpPercent1 =
            updated.salesPlanList[i].gpPercent1 * 100;
        }
        if (this.state.view.itemsEdited[i].gpPercent2 !== undefined) {
          updated.salesPlanList[i].gpPercent2 =
            updated.salesPlanList[i].gpPercent2 * 100;
        }
        if (this.state.view.itemsEdited[i].gpPercent3 !== undefined) {
          updated.salesPlanList[i].gpPercent3 =
            updated.salesPlanList[i].gpPercent3 * 100;
        }
        if (this.state.view.itemsEdited[i].gpPercent4 !== undefined) {
          updated.salesPlanList[i].gpPercent4 =
            updated.salesPlanList[i].gpPercent4 * 100;
        }
        if (
          this.state.view.itemsEdited[i].poQuarterId === undefined ||
          this.state.view.itemsEdited[i].poQuarterId === 0
        ) {
          updated.salesPlanList[i].poQuarterId = null;
        }
        if (
          this.state.view.itemsEdited[i].poMonth === undefined ||
          this.state.view.itemsEdited[i].poMonth === "0"
        ) {
          updated.salesPlanList[i].poMonth = null;
        }
        if (
          this.state.view.itemsEdited[i].poRank === undefined ||
          this.state.view.itemsEdited[i].poRank === "0"
        ) {
          updated.salesPlanList[i].poRank = null;
        }

        if (
          this.state.view.itemsEdited[i].salesQuarterId === undefined ||
          this.state.view.itemsEdited[i].salesQuarterId === 0
        ) {
          updated.salesPlanList[i].salesQuarterId = null;
        }
        if (
          this.state.view.itemsEdited[i].salesMonth === undefined ||
          this.state.view.itemsEdited[i].salesMonth === "0"
        ) {
          updated.salesPlanList[i].salesMonth = null;
        }
        if (
          this.state.view.itemsEdited[i].salesRank === undefined ||
          this.state.view.itemsEdited[i].salesRank === 0
        ) {
          updated.salesPlanList[i].salesRank = null;
        }
      }

      fetch(SALES_PLAN_CREATE_UPDATE_URL + "/keyword/grid", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
        body: JSON.stringify(updated),
      }).then(async (response) => {
        if (response.ok) {
          const res = await response.json();
          if (res.result) {
            this.salesPlanList();
            this.setState({ resultMessage: res.message }, () => {
              this.setState({ showSuccessModal: true }, () => {});
              cacheUpdate(
                this.state.selectedArray.salesPlanYear,
                this.state.selectedArray.salesPlanMonth
              );
            });
          } else {
            this.setState({ resultMessage: res.message }, () => {
              this.setState({ showInfoModal: true }, () => {});
            });
          }
        }
      });
    }
  }

  deleteRow() {
    let toDeleteIds = [];
    for (let i = 0; i < this.state.view.items.length; i++) {
      if (this.state.view.items[i].active) {
        toDeleteIds = [...toDeleteIds, this.state.view.items[i].salesPlanId];
      }
    }
    if (toDeleteIds.length > 0) {
      this.setState({ resConfirm: true }, () => {
        if (this.state.resConfirm) {
          fetch(SALES_PLAN_DELETE_URL, {
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${TOKEN}`,
            },
            body: JSON.stringify({ salePlanIds: toDeleteIds }),
          }).then(async (response) => {
            if (response.ok) {
              const res = await response.json();
              this.setState({ showConfirmModal: false }, () => {
                if (res.result) {
                  this.salesPlanList();
                  this.setState({ resultMessage: res.message }, () => {
                    this.setState({ showSuccessModal: true }, () => {});
                  });
                } else {
                  this.setState({ resultMessage: res.message }, () => {
                    this.setState({ showInfoModal: true }, () => {});
                  });
                }
              });
              this.setState({ resConfirm: false }, () => {});
            }
          });
        }
      });
    } else {
      this.setState({ resultMessage: deleteWarning }, () => {
        this.setState({ showInfoModal: true }, () => {});
      });
    }
  }

  getValue(e) {
    if (e.target.id === "Lock") {
      this.lockConfirm(this.state.selectedArray.salesPlanMonth);
    }
    if (e.target.id === "FinalLock") {
      this.finalLockConfirm(this.state.selectedArray.salesPlanMonth);
    }
    this.setState({ value: e.target.id }, () => {});
  }

  catchFunction() {
    if (this.state.value === "Save") {
      this.updateRow();
    } else if (this.state.value === "Lock") {
      this.saveBC(this.state.selectedArray);
    } else if (this.state.value === "FinalLock") {
      this.finalSaveBC(this.state.selectedArray);
    } else {
      this.deleteRow();
    }
  }

  onSalesPlanYearChange(e) {
    this.setState({ selectedFiscalYear: e.target.value });
    this.salesPlanMonthUpdate(e.target.value);
  }

  onSalesPlanMonthChange(e) {
    this.setState({ selectedMonth: e.target.value });
  }

  showCloneModal() {
    this.setState({ showModal: true });
  }

  hideCloneModal() {
    this.setState({ showModal: false });
  }

  sumAfterFilterChanged() {
    var colHeader = this.state.colGrid.columnHeaders;
    var grid = this.state.colGrid;
    let month1Sales = 0,
      month2Sales = 0,
      month3Sales = 0;
    let month4Sales = 0,
      month5Sales = 0,
      month6Sales = 0;
    let month7Sales = 0,
      month8Sales = 0,
      month9Sales = 0;
    let month10Sales = 0,
      month11Sales = 0,
      month12Sales = 0;
    let colGPTotal1 = 0,
      colGPTotal2 = 0,
      colGPTotal3 = 0,
      colGPTotal4 = 0;
    let totalSales = 0,
      totalGP = 0;

    for (let col = 0; col < grid.columns.length; col++) {
      for (let row = 0; row < grid.rows.length - 1; row++) {
        let item = grid.rows[row].dataItem;
        if (grid.columns[col].binding === "totalRowSales") {
          if (item.totalRowSales != 0) {
            totalSales += item.totalRowSales;
          }
        }

        if (grid.columns[col].binding === "totalRowGP") {
          if (item.totalRowGP != 0) {
            totalGP += item.totalRowGP;
          }
        }

        if (grid.columns[col].binding === "month1") {
          if (item.month1 != null && item.month1 !== "") {
            month1Sales += item.month1;
          }
        }

        if (grid.columns[col].binding === "month2") {
          if (item.month2 != null && item.month2 !== "") {
            month2Sales += item.month2;
          }
        }

        if (grid.columns[col].binding === "month3") {
          if (item.month3 != null && item.month3 !== "") {
            month3Sales += item.month3;
          }
        }

        if (grid.columns[col].binding === "month4") {
          if (item.month4 != null && item.month4 !== "") {
            month4Sales += item.month4;
          }
        }
        if (grid.columns[col].binding === "month5") {
          if (item.month5 != null && item.month5 !== "") {
            month5Sales += item.month5;
          }
        }

        if (grid.columns[col].binding === "month6") {
          if (item.month6 != null && item.month6 !== "") {
            month6Sales += item.month6;
          }
        }

        if (grid.columns[col].binding === "month7") {
          if (item.month7 != null && item.month7 !== "") {
            month7Sales += item.month7;
          }
        }

        if (grid.columns[col].binding === "month8") {
          if (item.month8 != null && item.month8 !== "") {
            month8Sales += item.month8;
          }
        }
        if (grid.columns[col].binding === "month9") {
          if (item.month9 != null && item.month9 !== "") {
            month9Sales += item.month9;
          }
        }

        if (grid.columns[col].binding === "month10") {
          if (item.month10 != null && item.month10 !== "") {
            month10Sales += item.month10;
          }
        }

        if (grid.columns[col].binding === "month11") {
          if (item.month11 != null && item.month11 !== "") {
            month11Sales += item.month11;
          }
        }

        if (grid.columns[col].binding === "month12") {
          if (item.month12 != null && item.month12 !== "") {
            month12Sales += item.month12;
          }
        }

        if (grid.columns[col].binding === "gp1") {
          if (item.gp1 != null && item.gp1 !== "") {
            colGPTotal1 += item.gp1;
          }
        }

        if (grid.columns[col].binding === "gp2") {
          if (item.gp2 != null && item.gp2 !== "") {
            colGPTotal2 += item.gp2;
          }
        }
        if (grid.columns[col].binding === "gp1") {
          if (item.gp3 != null && item.gp3 !== "") {
            colGPTotal3 += item.gp3;
          }
        }
        if (grid.columns[col].binding === "gp4") {
          if (item.gp4 != null && item.gp4 !== "") {
            colGPTotal4 += item.gp4;
          }
        }
      }
    }

    colHeader.setCellData(3, 24, totalSales.toFixed(2));
    colHeader.setCellData(3, 26, totalGP.toFixed(2));
    colHeader.setCellData(3, 27, month1Sales.toFixed(2));
    colHeader.setCellData(3, 28, month2Sales.toFixed(2));
    colHeader.setCellData(3, 29, month3Sales.toFixed(2));
    colHeader.setCellData(3, 31, colGPTotal1.toFixed(2));
    colHeader.setCellData(3, 32, month4Sales.toFixed(2));
    colHeader.setCellData(3, 33, month5Sales.toFixed(2));
    colHeader.setCellData(3, 34, month6Sales.toFixed(2));
    colHeader.setCellData(3, 36, colGPTotal2.toFixed(2));
    colHeader.setCellData(3, 37, month7Sales.toFixed(2));
    colHeader.setCellData(3, 38, month8Sales.toFixed(2));
    colHeader.setCellData(3, 39, month9Sales.toFixed(2));
    colHeader.setCellData(3, 41, colGPTotal3.toFixed(2));
    colHeader.setCellData(3, 42, month10Sales.toFixed(2));
    colHeader.setCellData(3, 43, month11Sales.toFixed(2));
    colHeader.setCellData(3, 44, month12Sales.toFixed(2));
    colHeader.setCellData(3, 46, colGPTotal4.toFixed(2));

    let totalGPPer = (parseFloat(totalGP) / parseFloat(totalSales)) * 100;
    if (!isNaN(totalGPPer)) {
      colHeader.setCellData(3, 25, parseFloat(totalGPPer).toFixed(2) + "%");
    } else {
      colHeader.setCellData(3, 25, 0 + "%");
    }
    calGPPercent(
      colHeader,
      30,
      colGPTotal1,
      month1Sales,
      month2Sales,
      month3Sales
    );

    calGPPercent(
      colHeader,
      35,
      colGPTotal2,
      month4Sales,
      month5Sales,
      month6Sales
    );

    calGPPercent(
      colHeader,
      40,
      colGPTotal3,
      month7Sales,
      month8Sales,
      month9Sales
    );
    calGPPercent(
      colHeader,
      45,
      colGPTotal4,
      month10Sales,
      month11Sales,
      month12Sales
    );
  }

  render() {
    return (
      <div className="main--body">
        {this.state.isLoading && (
          <div className="loader">
            <Spinner color="white" />
          </div>
        )}{" "}
        <Accordion allowZeroExpanded preExpanded={["a"]}>
          <AccordionItem uuid="a">
            <AccordionItemHeading
              onClick={() => {
                this.setState({ expand: !this.state.expand });
              }}
            >
              <AccordionItemButton>
                <span className="accordion_title">
                  {this.state.salesPlanYearName} {this.state.salesPlanMonthName}
                </span>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div
                className="row justify-content-center"
                style={{ margin: "0" }}
              >
                <div className="col p-1">
                  <select
                    name="salesPlanYear"
                    id="salesPlanYear"
                    data-tooltip-content="Sales Plan Year"
                    className="form-select"
                    onChange={(e) => {
                      this.onSalesPlanYearChange(e);
                    }}
                    value={this.state.selectedFiscalYear}
                  >
                    <option key={0} value="" hidden>
                      Sales Plan Year
                    </option>
                    {this.state.fiscalList.map((item) => (
                      <option key={item.fiscalYearId} value={item.fiscalYearId}>
                        {item.fiscalYear}
                      </option>
                    ))}
                  </select>
                  <ReactTooltip place="bottom" anchorId="salesPlanYear" />
                </div>
                <div className="col p-1">
                  <select
                    name="salesPlanMonth"
                    id="salesPlanMonth"
                    data-tooltip-content="Sales Plan Month"
                    className="form-select"
                    onChange={(e) => {
                      this.onSalesPlanMonthChange(e);
                    }}
                    value={this.state.selectedMonth}
                  >
                    <option value="" hidden>
                      Sales Plan Month
                    </option>
                    {this.state.salesPlanMonthList.map((month) => (
                      <option key={month.id} value={month.value}>
                        {month.name}
                      </option>
                    ))}
                  </select>
                  <ReactTooltip place="bottom" anchorId="salesPlanMonth" />
                </div>
                <div className="col p-1">
                  <select
                    name="sectorId"
                    id="businessSector"
                    data-tooltip-content="Business Sector"
                    className="form-select"
                    onChange={this.handleChange}
                  >
                    <option key={0} value="" hidden>
                      Business Sector
                    </option>
                    <option key={"all"} value="all">
                      All
                    </option>
                    {this.state.sectorList.map((item) => (
                      <option key={item.sectorId} value={item.sectorId}>
                        {item.sectorName}
                      </option>
                    ))}
                  </select>
                  <ReactTooltip place="bottom" anchorId="businessSector" />
                </div>
                <div className="col p-1">
                  <select
                    name="poMonth"
                    id="poMonth"
                    data-tooltip-content="PO Month"
                    className="form-select"
                    required
                    onChange={this.handleChange}
                  >
                    <option key={0} value="" hidden>
                      PO Month
                    </option>
                    <option key={"all"} value="all">
                      All
                    </option>
                    <option key={1} value="1">
                      1
                    </option>
                    <option key={2} value="2">
                      2
                    </option>
                    <option key={3} value="3">
                      3
                    </option>
                    <option key={4} value="4">
                      4
                    </option>
                    <option key={5} value="5">
                      5
                    </option>
                    <option key={6} value="6">
                      6
                    </option>
                    <option key={7} value="7">
                      7
                    </option>
                    <option key={8} value="8">
                      8
                    </option>
                    <option key={9} value="9">
                      9
                    </option>
                    <option key={10} value="10">
                      10
                    </option>
                    <option key={11} value="11">
                      11
                    </option>
                    <option key={12} value="10">
                      12
                    </option>
                  </select>
                  <ReactTooltip place="bottom" anchorId="poMonth" />
                </div>
                <div className="col p-1">
                  <select
                    name="salesMonth"
                    id="salesMonth"
                    data-tooltip-content="Sales Month"
                    className="form-select"
                    required
                    onChange={this.handleChange}
                  >
                    <option key={0} value="" hidden>
                      Sales Month
                    </option>
                    <option key={"all"} value="all">
                      All
                    </option>
                    <option key={1} value="1">
                      1
                    </option>
                    <option key={2} value="2">
                      2
                    </option>
                    <option key={3} value="3">
                      3
                    </option>
                    <option key={4} value="4">
                      4
                    </option>
                    <option key={5} value="5">
                      5
                    </option>
                    <option key={6} value="6">
                      6
                    </option>
                    <option key={7} value="7">
                      7
                    </option>
                    <option key={8} value="8">
                      8
                    </option>
                    <option key={9} value="9">
                      9
                    </option>
                    <option key={10} value="10">
                      10
                    </option>
                    <option key={11} value="11">
                      11
                    </option>
                    <option key={12} value="12">
                      12
                    </option>
                  </select>
                  <ReactTooltip place="bottom" anchorId="salesMonth" />
                </div>
                <div className="col p-1">
                  <select
                    name="poRank"
                    id="poRank"
                    data-tooltip-content="PO Rank"
                    className="form-select"
                    required
                    onChange={this.handleChange}
                  >
                    <option key={0} value="" hidden>
                      PO Rank
                    </option>
                    <option key={"all"} value="all">
                      All
                    </option>
                    <option key={1} value="1">
                      A
                    </option>
                    <option key={2} value="2">
                      B
                    </option>
                    <option key={3} value="3">
                      C
                    </option>
                    <option key={4} value="4">
                      D
                    </option>
                    <option key={5} value="5">
                      E
                    </option>
                  </select>
                  <ReactTooltip place="bottom" anchorId="poRank" />
                </div>
                <div className="col p-1">
                  <select
                    name="salesRank"
                    id="salesRank"
                    data-tooltip-content="Sales Rank"
                    className="form-select"
                    required
                    onChange={this.handleChange}
                  >
                    <option key={0} value="" hidden>
                      Sales Rank
                    </option>
                    <option key={"all"} value="all">
                      All
                    </option>
                    <option key={1} value="1">
                      A
                    </option>
                    <option key={2} value="2">
                      B
                    </option>
                    <option key={3} value="3">
                      C
                    </option>
                    <option key={4} value="4">
                      D
                    </option>
                    <option key={5} value="5">
                      E
                    </option>
                  </select>
                  <ReactTooltip place="bottom" anchorId="salesRank" />
                </div>
                <div className="col p-1">
                  <div className="form-outline">
                    <input
                      type="search"
                      id="status"
                      data-tooltip-content="Status"
                      className="form-control"
                      name="status"
                      placeholder="Status"
                      onChange={this.handleChange}
                    />
                    <ReactTooltip place="bottom" anchorId="status" />
                  </div>
                </div>
                <div
                  className="flex col-auto col-md-auto p-1 justify-content-end "
                  style={{ paddingLeft: "8px", paddingRight: "0" }}
                >
                  <button
                    id="search"
                    data-tooltip-content="Search"
                    type="button"
                    style={{ margin: "0", padding: "0px" }}
                    className="actionIconOutline search bg-blue  "
                    onClick={() => {
                      this.setState(
                        {
                          selectedArray: {
                            ...this.state.selectedArray,
                            salesPlanYear: this.state.selectedFiscalYear,
                            salesPlanMonth: this.state.selectedMonth,
                          },
                        },
                        () => {
                          this.getSearchData(this.state.selectedArray);
                          this.exchangeRateUpdate(this.state.selectedArray);
                          this.getYearMonthName();
                        }
                      );
                    }}
                  >
                    <BiSearchAlt2 />
                    <ReactTooltip place="bottom" anchorId="search" />
                  </button>
                </div>
              </div>
              <div
                className="row justify-content-start"
                style={{ margin: "0" }}
              >
                {/* <div className="col" style={{ paddingRight: "3px" }}>
            <div
              className="row justify-content-around"
              style={{ padding: "0" }}
            > */}
                {this.state.salesAmountTitle.map(
                  (title, index) =>
                    title.name !== "SGA" && (
                      <div
                        className="col"
                        key={title.id}
                        style={{
                          padding: "0 0.3vw",
                          fontSize: "11px",
                        }}
                      >
                        <div
                          className="rowCount"
                          style={{
                            backgroundColor: this.state.cardsColor[index],
                            height: "100%",
                            margin: "0",
                          }}
                        >
                          {title.name === "Total Order" ? (
                            <>
                              <p
                                className="rowCountText"
                                style={{ float: "left" }}
                              >
                                {title.name}:{" "}
                              </p>
                              <p
                                style={{
                                  margin: "0px",
                                  float: "right",
                                  width: "fit-content",
                                  textAlign: "right",
                                }}
                              >
                                {title.salesAmount}
                              </p>
                            </>
                          ) : (
                            <>
                              <p
                                className="rowCountText"
                                style={{ float: "left" }}
                              >
                                {title.name}:
                              </p>
                              <p
                                style={{
                                  margin: "0px",
                                  float: "right",
                                  minWidth: "60%",
                                  width: "fit-content",
                                  textAlign: "right",
                                }}
                              >
                                M MMK{" "}
                                {title.name !== "OR"
                                  ? title.salesAmount
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : "11,650"}
                              </p>
                              <p
                                style={{
                                  margin: "0px",
                                  float: "right",
                                  minWidth: "60%",
                                  width: "fit-content",
                                  textAlign: "right",
                                }}
                              >
                                M JPY{" "}
                                {title.name !== "OR"
                                  ? (
                                      title.salesAmount *
                                      this.state.exchangeRate
                                    )
                                      .toFixed(0)
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : "699"}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    )
                )}
                {/* </div> */}
                {/* </div> */}
                <div
                  className="col-auto"
                  style={{
                    margin: "0.3vw",
                    padding: "0",
                    marginTop: "auto",
                    marginBottom: "0px",
                  }}
                >
                  <button
                    data-tooltip-content="Clone"
                    type="button"
                    style={{ margin: "0", padding: "0" }}
                    className="actionIconOutline clone bg-blue"
                    id="Clone"
                    onClick={() => {
                      this.setState({ showModal: true });
                    }}
                  >
                    <FaRegClone id="Clone" />
                    <ReactTooltip anchorId="Clone" />
                  </button>
                </div>
                <div
                  className="col-auto"
                  style={{
                    margin: "0.3vw",
                    padding: "0",
                    marginTop: "auto",
                    marginBottom: "0px",
                  }}
                >
                  <button
                    data-tooltip-content="Save"
                    type="button"
                    style={{ margin: "0", padding: "0" }}
                    className="actionIconOutline save bg-blue"
                    id="Save"
                    onClick={(e) => {
                      this.setState({ showConfirmModal: true }, () => {});
                      this.getValue(e);
                    }}
                  >
                    <BiSave id="Save" />
                    <ReactTooltip anchorId="Save" />
                  </button>
                </div>
                <div
                  className="col-auto"
                  style={{
                    margin: "0.3vw",
                    padding: "0",
                    marginTop: "auto",
                    marginBottom: "0px",
                  }}
                >
                  <button
                    data-tooltip-content="Delete"
                    type="button"
                    style={{ margin: "0", padding: "0" }}
                    className="actionIconOutline delete bg-red"
                    id="Delete"
                    onClick={(e) => {
                      this.setState({ showConfirmModal: true }, () => {});
                      this.getValue(e);
                    }}
                  >
                    <MdDeleteOutline id="Delete" />
                    <ReactTooltip anchorId="Delete" />
                  </button>
                </div>

                <div
                  className="col-auto"
                  style={{
                    margin: "0.3vw",
                    padding: "0",
                    marginTop: "auto",
                    marginBottom: "0px",
                  }}
                >
                  <button
                    data-tooltip-content="Lock"
                    type="button"
                    style={{ margin: "0", padding: "0" }}
                    className="actionIconOutline lock bg-blue"
                    id="Lock"
                    onClick={(e) => {
                      this.getValue(e);
                    }}
                  >
                    <AiFillLock id="Lock" />
                    <ReactTooltip anchorId="Lock" />
                  </button>
                </div>

                <div
                  className="col-auto"
                  style={{
                    margin: "0.3vw",
                    padding: "0",
                    marginTop: "auto",
                    marginBottom: "0px",
                  }}
                >
                  <button
                    data-tooltip-content="Final Lock"
                    type="button"
                    style={{ margin: "0", padding: "0" }}
                    className="actionIconOutline final-lock bg-red"
                    id="FinalLock"
                    onClick={(e) => {
                      this.getValue(e);
                    }}
                  >
                    <AiFillLock id="FinalLock" />
                    <ReactTooltip anchorId="FinalLock" />
                  </button>
                </div>

                <div
                  className="col-auto"
                  style={{
                    margin: "0.3vw",
                    padding: "0",
                    marginTop: "auto",
                    marginBottom: "0px",
                  }}
                >
                  <Link
                    to="/salesplan/import"
                    onClick={() => {
                      this.state.changeLocation("Sales Plan | Import");
                    }}
                  >
                    <button
                      id="import"
                      data-tooltip-content="Import"
                      type="button"
                      style={{ margin: "0", padding: "0" }}
                      className="actionIconOutline import bg-blue"
                    >
                      <AiOutlineUpload />
                      <ReactTooltip anchorId="import" />
                    </button>
                  </Link>
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
        <wjFlexGrid.FlexGrid
          isReadOnly={false}
          allowMerging="ColumnHeaders"
          alternatingRowStep={0}
          initialized={this.onInitialized.bind(this)}
          allowAddNew={true}
          allowDelete={true}
          autoGenerateColumns={false}
          frozenColumns={8}
          itemsSource={this.state.view}
          allowDragging="Both"
          draggingRow={this.handleDraggingRow}
          draggedRow={this.handleDraggedRow}
          allowSorting={false}
          data-state={this.state.expand ? "expand" : "collapse"}
          id="sales-plan-grid"
        >
          <wjFlexGrid.FlexGridColumn
            binding="active"
            header=" "
            allowMerging={true}
            width={50}
          />
          <wjFlexGrid.FlexGridColumn
            binding="salesPlanKey"
            header="No."
            allowMerging={true}
            width={60}
            isReadOnly={true}
            cssClass={"cell-inactive"}
          />
          <wjFlexGrid.FlexGridColumn
            binding="status"
            header="Status"
            allowMerging={true}
            width={80}
          />
          <wjFlexGrid.FlexGridColumn
            binding="orderNumber"
            header="Order Number"
            allowMerging={true}
            width={115}
          />
          <wjFlexGrid.FlexGridColumn
            binding="projectName"
            header="Project Name"
            allowMerging={true}
            width={120}
          />
          <wjFlexGrid.FlexGridColumn
            binding="customerGroup"
            header="Customer Group"
            allowMerging={true}
            width={70}
            dataMap={new DataMap(this.getCustomerGroup(), "id", "name")}
          />
          <wjFlexGrid.FlexGridColumn
            binding="sectorId"
            header="Sector"
            allowMerging={true}
            width={80}
            dataMap={new DataMap(this.getSector(), "id", "name")}
          />
          <wjFlexGrid.FlexGridColumn
            binding="state"
            header="State"
            allowMerging={true}
            width={80}
            dataMap={new DataMap(this.getState(), "id", "name")}
          />
          <wjFlexGrid.FlexGridColumn
            binding="poCurrencyAmount"
            header="Currency Amount"
            allowMerging={true}
            inputType="number"
            width={70}
            cssClass="alignRight"
          />
          <wjFlexGrid.FlexGridColumn
            binding="poExchangeRate"
            header="Exchange Rate"
            allowMerging={true}
            inputType="number"
            width={70}
            cssClass="alignRight"
          />
          <wjFlexGrid.FlexGridColumn
            binding="poAmount"
            header="Amount"
            allowMerging={true}
            inputType="number"
            width={70}
          />
          <wjFlexGrid.FlexGridColumn
            binding="salesCurrencyAmount"
            header="Currency Amount"
            allowMerging={true}
            inputType="number"
            width={70}
            cssClass="alignRight"
          />
          <wjFlexGrid.FlexGridColumn
            binding="salesExchangeRate"
            header="Exchange Rate"
            allowMerging={true}
            inputType="number"
            width={70}
            cssClass="alignRight"
          />
          <wjFlexGrid.FlexGridColumn
            binding="salesAmount"
            header="Amount"
            allowMerging={true}
            inputType="number"
            width={70}
          />
          <wjFlexGrid.FlexGridColumn
            binding="diffPoAmount"
            header="PO Different"
            allowMerging={true}
            width={70}
            cssClass="alignRight"
          />
          <wjFlexGrid.FlexGridColumn
            binding="costPercent"
            header="Cost%"
            width={70}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="planCost"
            header="Plan Cost"
            width={90}
            isReadOnly={true}
            cssClass={"cell-inactive"}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="actualCost"
            header="Actual Cost"
            width={100}
            isReadOnly={true}
            cssClass={"cell-inactive"}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="poQuarterId"
            header="Quarter"
            width={70}
            dataMap={
              new DataMap(this.state.quarters, "quarterId", "quarterName")
            }
          />
          <wjFlexGrid.FlexGridColumn
            binding="poMonth"
            header="Month"
            width={50}
            dataMap={this.state.poMonths}
          />
          <wjFlexGrid.FlexGridColumn
            binding="poRank"
            header="Rank"
            width={50}
            dataMap={new DataMap(this.getRank(), "id", "name")}
          />
          <wjFlexGrid.FlexGridColumn
            binding="salesQuarterId"
            header="Quarter"
            width={70}
            dataMap={
              new DataMap(this.state.quarters, "quarterId", "quarterName")
            }
          />
          <wjFlexGrid.FlexGridColumn
            binding="salesMonth"
            header="Month"
            width={50}
            dataMap={this.state.salesMonths}
          />
          <wjFlexGrid.FlexGridColumn
            binding="salesRank"
            header="Rank"
            width={50}
            dataMap={new DataMap(this.getRank(), "id", "name")}
          />
          <wjFlexGrid.FlexGridColumn
            binding="totalRowSales"
            header="TotalSales"
            width={60}
            isReadOnly={true}
            cssClass={"cell-inactive"}
          />
          <wjFlexGrid.FlexGridColumn
            binding="totalRowGPPercent"
            header="TotalGPPercent"
            width={50}
            isReadOnly={true}
            cssClass={"cell-inactive"}
            // inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="totalRowGP"
            header="TotalGP"
            width={50}
            isReadOnly={true}
            cssClass={"cell-inactive"}
          />
          <wjFlexGrid.FlexGridColumn
            binding="month1"
            header="Sales1"
            width={50}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="month2"
            header="Sales2"
            width={50}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="month3"
            header="Sales3"
            width={50}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="gpPercent1"
            header="GP Percent1"
            width={50}
            isReadOnly={true}
            cssClass={"cell-inactive"}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="gp1"
            header="GP1"
            format="n2"
            width={50}
            isReadOnly={true}
            cssClass={"cell-inactive"}
          />
          <wjFlexGrid.FlexGridColumn
            binding="month4"
            header="Sales4"
            width={50}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="month5"
            header="Sales5"
            width={50}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="month6"
            header="Sales6"
            width={50}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="gpPercent2"
            header="GP Percent2"
            width={50}
            isReadOnly={true}
            cssClass={"cell-inactive"}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="gp2"
            header="GP2"
            format="n2"
            width={50}
            isReadOnly={true}
            cssClass={"cell-inactive"}
          />
          <wjFlexGrid.FlexGridColumn
            binding="month7"
            header="Sales7"
            width={50}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="month8"
            header="Sales8"
            width={50}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="month9"
            header="Sales9"
            width={50}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="gpPercent3"
            header="GP Percent3"
            width={50}
            isReadOnly={true}
            cssClass={"cell-inactive"}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="gp3"
            header="GP3"
            format="n2"
            width={50}
            isReadOnly={true}
            cssClass={"cell-inactive"}
          />
          <wjFlexGrid.FlexGridColumn
            binding="month10"
            header="Sales10"
            width={50}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="month11"
            header="Sales11"
            width={50}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="month12"
            header="Sales12"
            width={50}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="gpPercent4"
            header="GP Percent4"
            width={50}
            isReadOnly={true}
            cssClass={"cell-inactive"}
            inputType="number"
          />
          <wjFlexGrid.FlexGridColumn
            binding="gp4"
            header="GP4"
            format="n2"
            width={50}
            isReadOnly={true}
            cssClass={"cell-inactive"}
          />
          <wjFlexGrid.FlexGridColumn
            binding="sortingId"
            header="Sorting ID"
            allowMerging={true}
            width={50}
          />
          <wjFlexGrid.FlexGridColumn
            binding="salesPlanId"
            header="Salesplan ID"
            allowMerging={true}
            width={50}
          />
          <FlexGridFilter
            filterColumns={this.state.filteringColmns}
            filterApplied={() => {
              this.sumAfterFilterChanged();
            }}
          ></FlexGridFilter>
        </wjFlexGrid.FlexGrid>
        <div className="rowCount col-2" style={{ float: "right" }}>
          <p
            className="rowCountText"
            style={{ textAlign: "center", width: "100%" }}
          >
            Sum: {this.state.tally.sum.toFixed(2)} | Count:{" "}
            {this.state.tally.cntAll}
          </p>
        </div>
        <div
          className="column-picker-div"
          onDragStart={this.handleDragStart.bind(this)}
          onDragOver={this.handleDragOver.bind(this)}
          onDrop={this.handleDrop.bind(this)}
          onDragEnd={this.handleDragEnd.bind(this)}
        >
          <ListBox
            className="column-picker"
            initialized={this.initializedPicker.bind(this)}
          />
        </div>
        <ConfirmModal
          show={this.state.showConfirmModal}
          title={
            this.state.value === "Save"
              ? updateMessage
              : this.state.value === "Delete"
              ? deleteMessage
              : LockMessage
          }
          onSubmit={() => {
            this.setState({ showConfirmModal: false }, () => {
              this.catchFunction();
            });
          }}
          onCancel={() => {
            this.setState({ showConfirmModal: false }, () => {});
            this.setState({ resConfirm: false }, () => {});
          }}
          onHide={() => {
            this.setState({ showConfirmModal: false }, () => {});
            this.setState({ resConfirm: false }, () => {});
          }}
        />
        <SuccessModal
          show={this.state.showSuccessModal}
          title={this.state.resultMessage}
          onSubmit={() => {
            this.setState({ showSuccessModal: false }, () => {
              // window.location.reload(true);
            });
          }}
          onHide={() => {
            this.setState({ showSuccessModal: false }, () => {});
            this.setState({ resConfirm: false }, () => {});
          }}
        />
        <InfoModal
          show={this.state.showInfoModal}
          title={this.state.resultMessage}
          onSubmit={() => {
            this.setState({ showInfoModal: false }, () => {});
          }}
          onHide={() => {
            this.setState({ showInfoModal: false }, () => {});
            this.setState({ resConfirm: false }, () => {});
          }}
        />
        <CloneModal
          show={this.state.showModal}
          onHide={() => {
            this.setState({ showModal: false });
          }}
          title={this.state.cloneTitle}
          onSubmit={() => {
            this.hideCloneModal();
          }}
        />
      </div>
    );
  }

  onInitialized(grid) {
    this.setState({ colGrid: grid });
    grid.mergeManager = new CustomMergeManager();
    this.flex = grid;

    grid.columns[15].format = "p0";
    grid.columns[25].format = "p0";
    grid.columns[30].format = "p0";
    grid.columns[35].format = "p0";
    grid.columns[40].format = "p0";
    grid.columns[45].format = "p0";

    grid.columns.getColumn("sortingId").visible = false;
    grid.columns.getColumn("salesPlanId").visible = false;
    grid.columns.getColumn("poCurrencyAmount").visible = false;
    grid.columns.getColumn("poExchangeRate").visible = false;
    grid.columns.getColumn("salesCurrencyAmount").visible = false;
    grid.columns.getColumn("salesExchangeRate").visible = false;

    // show the column picker when the user clicks the top-left cell
    let ref = this.flex.hostElement.querySelector(".wj-topleft");
    ref.addEventListener("mousedown", (e) => {
      if (hasClass(e.target, "column-picker-icon")) {
        let host = this.columnPicker.hostElement;
        if (!host.offsetHeight) {
          showPopup(host, ref, false, true, false);
          this.columnPicker.focus();
        } else {
          hidePopup(host, true, true);
          this.flex.focus();
        }
        this.columnPicker.focus();
        e.preventDefault();
      }
    });
    // work around Safari/IOS bug (TFS 321525, 361500, 402670)
    // https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
    window.addEventListener("touchstart", (e) => {
      let host = this.columnPicker.hostElement;
      if (!contains(host, e.target) && !closest(e.target, ".wj-flexgrid")) {
        hidePopup(host, true, true);
      }
    });

    var extraRow = new wjcGrid.Row();
    var extraRow1 = new wjcGrid.Row();
    var extraRow2 = new wjcGrid.Row();
    extraRow.allowMerging = true;
    extraRow1.allowMerging = true;
    extraRow2.allowMerging = true;

    var panel = grid.columnHeaders;
    panel.rows.splice(0, 0, extraRow);
    panel.rows.splice(1, 0, extraRow1);
    panel.rows.splice(2, 0, extraRow2);

    let sLetter = "A";
    for (let colIndex = 0; colIndex <= 48; colIndex++) {
      panel.setCellData(0, colIndex, sLetter);
      sLetter = this.getNextStringId(sLetter);
    }

    for (let colIndex = 8; colIndex <= 10; colIndex++) {
      panel.setCellData(1, colIndex, "PO");
    }

    for (let colIndex = 11; colIndex <= 13; colIndex++) {
      panel.setCellData(1, colIndex, "Sales");
    }

    for (let colIndex = 18; colIndex <= 20; colIndex++) {
      panel.setCellData(1, colIndex, "PO");
    }

    for (let colIndex = 21; colIndex <= 24; colIndex++) {
      panel.setCellData(1, colIndex, "Sales");
    }

    // merge headers vertically
    [
      "active",
      "salesPlanKey",
      "status",
      "orderNumber",
      "projectName",
      "customerGroup",
      "sectorId",
      "state",
      "diffPoAmount",
      "costPercent",
      "planCost",
      "actualCost",
    ].forEach(function (binding) {
      let col = grid.getColumn(binding);
      col.allowMerging = true;
      panel.setCellData(1, col.index, col.header);
      panel.setCellData(2, col.index, col.header);
    });

    [
      "poCurrencyAmount",
      "poExchangeRate",
      "salesCurrencyAmount",
      "salesExchangeRate",
      "poAmount",
      "salesAmount",
      "poQuarterId",
      "poMonth",
      "poRank",
      "salesQuarterId",
      "salesMonth",
      "salesRank",
      "sortingId",
      "salesPlanId",
    ].forEach(function (binding) {
      let col = grid.getColumn(binding);
      col.allowMerging = true;
      panel.setCellData(2, col.index, col.header);
    });

    // center-align merged header cells
    grid.formatItem.addHandler(function (s, e) {
      if (e.panel === s.columnHeaders && e.range.rowSpan > 1) {
        var html = e.cell.innerHTML;
        e.cell.innerHTML = '<div class="v-center">' + html + "</div>";
      }

      if (e.panel === s.topLeftCells) {
        e.cell.innerHTML =
          '<span class ="wj-glyph-drag column-picker-icon v-center"></span>';
      }
    });

    // Sum of selected cells
    this.flex.selectionChanged.addHandler(() => {
      let tally = { cnt: 0, cntAll: 0, sum: 0 },
        ranges = this.flex.selectedRanges;
      var colNotSum = [
        "customerGroup",
        "sectorId",
        "state",
        "poQuarterId",
        "poMonth",
        "poRank",
        "salesQuarterId",
        "salesMonth",
        "salesRank",
      ];
      for (let i = 0; i < ranges.length; i++) {
        let rng = ranges[i];
        for (let r = rng.topRow; r <= rng.bottomRow; r++) {
          for (let c = rng.leftCol; c <= rng.rightCol; c++) {
            let overlapped = false;
            for (let j = 0; j < i && !overlapped; i++) {
              let rng = ranges[j];
              if (rng.contains(r, c)) {
                overlapped = true;
              }
            }
            tally.cntAll++;
            if (colNotSum.includes(grid.columns[c]?.binding)) {
              if (c <= rng.rightCol) {
                continue;
              }
            }
            if (!overlapped) {
              let data = grid.getCellData(r, c, false);
              if (wijmo.isNumber(data)) {
                tally.sum += data;
              }
            }
          }
        }
      }
      this.setState({ tally: tally });
    });

    //calculate po amount, sales amount and po diff amount
    this.flex.cellEditEnded.addHandler((s, e) => {
      let col = s.columns[e.col];

      if (col.binding === "poCurrencyAmount") {
        let item = s.rows[e.row].dataItem;
        let amount = item.poCurrencyAmount * item.poExchangeRate;
        if (!isNaN(amount)) {
          s.rows[e.row].dataItem.poAmount = amount;
        }
      }

      if (col.binding === "poExchangeRate") {
        let item = s.rows[e.row].dataItem;
        let amount = item.poCurrencyAmount * item.poExchangeRate;
        if (!isNaN(amount)) {
          s.rows[e.row].dataItem.poAmount = amount;
        }
      }

      if (col.binding === "salesCurrencyAmount") {
        let item = s.rows[e.row].dataItem;
        let amount = item.salesCurrencyAmount * item.salesExchangeRate;
        if (!isNaN(amount)) {
          s.rows[e.row].dataItem.salesAmount = amount;
        }
      }

      if (col.binding === "salesExchangeRate") {
        let item = s.rows[e.row].dataItem;
        let amount = item.salesCurrencyAmount * item.salesExchangeRate;
        if (!isNaN(amount)) {
          s.rows[e.row].dataItem.salesAmount = amount;
        }
      }

      if (
        col.binding === "poAmount" ||
        col.binding === "poCurrencyAmount" ||
        col.binding === "poExchangeRate"
      ) {
        let item = s.rows[e.row].dataItem;
        let diff = item.salesAmount - item.poAmount;
        if (!isNaN(diff)) {
          s.rows[e.row].dataItem.diffPoAmount = diff;
        }
      }

      if (
        col.binding === "salesAmount" ||
        col.binding === "salesCurrencyAmount" ||
        col.binding === "salesExchangeRate"
      ) {
        let item = s.rows[e.row].dataItem;
        let diff = item.salesAmount - item.poAmount;
        if (!isNaN(diff)) {
          s.rows[e.row].dataItem.diffPoAmount = diff;
        }
      }

      if (col.binding === "costPercent") {
        let item = s.rows[e.row].dataItem[col.binding] / 100;
        this.flex.setCellData(s.rows[e.row].index, col.index, item);
      }

      if (
        col.binding === "state" ||
        col.binding === "costPercent" ||
        col.binding === "salesMonth" ||
        col.binding === "salesCurrencyAmount" ||
        col.binding === "salesExchangeRate" ||
        col.binding === "salesAmount" ||
        col.binding === "month1" ||
        col.binding === "month2" ||
        col.binding === "month3" ||
        col.binding === "month4" ||
        col.binding === "month5" ||
        col.binding === "month6" ||
        col.binding === "month7" ||
        col.binding === "month8" ||
        col.binding === "month9" ||
        col.binding === "month10" ||
        col.binding === "month11" ||
        col.binding === "month12"
      ) {
        let panel = this.state.colGrid.columnHeaders;
        calAmountCell(col.binding, panel, s, e);
      }

      if (
        col.binding === "state" ||
        col.binding === "salesMonth" ||
        col.binding === "costPercent"
      ) {
        let item = s.rows[e.row].dataItem;
        var months = [
          "month1",
          "month2",
          "month3",
          "month4",
          "month5",
          "month6",
          "month7",
          "month8",
          "month9",
          "month10",
          "month11",
          "month12",
        ];
        let theValue = undefined;
        for (let i = 0; i < months.length; i++) {
          if (
            item[months[i]].length === undefined ||
            item[months[i]].length > 0
          ) {
            theValue = item[months[i]];
            item[months[i]] = "";
            if (item.salesMonth > 3 && item.salesMonth < 13) {
              item[months[item.salesMonth - 4]] = theValue;
            } else if (item.salesMonth > 0 && item.salesMonth < 4) {
              item[months[parseInt(item.salesMonth) + 8]] = theValue;
            }
            break;
          }
        }

        if (item.salesMonth === null) {
          s.rows[e.row].dataItem.gp1 = 0;
          s.rows[e.row].dataItem.gp2 = 0;
          s.rows[e.row].dataItem.gp3 = 0;
          s.rows[e.row].dataItem.gp4 = 0;
          s.rows[e.row].dataItem.gpPercent1 = 0;
          s.rows[e.row].dataItem.gpPercent2 = 0;
          s.rows[e.row].dataItem.gpPercent3 = 0;
          s.rows[e.row].dataItem.gpPercent4 = 0;
        } else {
          calAmountCell(col.binding, panel, s, e);
        }
      }
    });

    this.flex.rowEditEnded.addHandler((s, e) => {
      let updateRowId = s.rows[e.row].dataItem.salesPlanId;
      if (updateRowId) {
        this.setState({ updateSalesPlanId: updateRowId });
      } else {
        this.setState({
          addRowSectorId: s.rows[e.row].dataItem.sectorId,
          updateSalesPlanId: null,
        });
      }
    });

    this.flex.copying.addHandler((s) => {
      let selection = s.selectedRanges;
      let sel = {
        row: null,
        col: null,
      };
      for (let i = 0; i < selection.length; i++) {
        let rng = selection[i];
        for (let r = rng.topRow; r <= rng.bottomRow; r++) {
          for (let c = rng.leftCol; c <= rng.rightCol; c++) {
            sel.row = r;
            sel.col = c;

            let col = s.columns[sel.col];
            if (col.binding === "poAmount") {
              let item = s.rows[sel.row].dataItem;
              if (!item.poAmount) {
                item.poAmount = 0;
              }
            }

            if (col.binding === "salesAmount") {
              let item = s.rows[sel.row].dataItem;
              if (!item.salesAmount) {
                item.salesAmount = 0;
              }
            }
          }
        }
      }
    });

    this.flex.pasted.addHandler((s) => {
      let selection = s.selectedRanges;
      let sel = {
        row: null,
        col: null,
      };
      for (let i = 0; i < selection.length; i++) {
        let rng = selection[i];
        for (let r = rng.topRow; r <= rng.bottomRow; r++) {
          for (let c = rng.leftCol; c <= rng.rightCol; c++) {
            sel.row = r;
            sel.col = c;

            let col = s.columns[sel.col];

            if (col.binding === "poCurrencyAmount") {
              let item = s.rows[sel.row].dataItem;
              let amount = item.poCurrencyAmount * item.poExchangeRate;
              if (!isNaN(amount)) {
                s.rows[sel.row].dataItem.poAmount = amount;
              }
            }

            if (col.binding === "poExchangeRate") {
              let item = s.rows[sel.row].dataItem;
              let amount = item.poCurrencyAmount * item.poExchangeRate;
              if (!isNaN(amount)) {
                s.rows[sel.row].dataItem.poAmount = amount;
              }
            }

            if (col.binding === "salesCurrencyAmount") {
              let item = s.rows[sel.row].dataItem;
              let amount = item.salesCurrencyAmount * item.salesExchangeRate;
              if (!isNaN(amount)) {
                s.rows[sel.row].dataItem.salesAmount = amount;
              }
            }

            if (col.binding === "salesExchangeRate") {
              let item = s.rows[sel.row].dataItem;
              let amount = item.salesCurrencyAmount * item.salesExchangeRate;
              if (!isNaN(amount)) {
                s.rows[sel.row].dataItem.salesAmount = amount;
              }
            }

            if (
              col.binding === "poAmount" ||
              col.binding === "poCurrencyAmount" ||
              col.binding === "poExchangeRate"
            ) {
              let item = s.rows[sel.row].dataItem;
              let diff = item.salesAmount - item.poAmount;
              if (!isNaN(diff)) {
                s.rows[sel.row].dataItem.diffPoAmount = diff;
              }
            }

            if (
              col.binding === "salesAmount" ||
              col.binding === "salesCurrencyAmount" ||
              col.binding === "salesExchangeRate"
            ) {
              let item = s.rows[sel.row].dataItem;
              let diff = item.salesAmount - item.poAmount;
              if (!isNaN(diff)) {
                s.rows[sel.row].dataItem.diffPoAmount = diff;
              }
            }

            if (
              col.binding === "state" ||
              col.binding === "costPercent" ||
              col.binding === "salesMonth" ||
              col.binding === "salesCurrencyAmount" ||
              col.binding === "salesExchangeRate" ||
              col.binding === "salesAmount" ||
              col.binding === "month1" ||
              col.binding === "month2" ||
              col.binding === "month3" ||
              col.binding === "month4" ||
              col.binding === "month5" ||
              col.binding === "month6" ||
              col.binding === "month7" ||
              col.binding === "month8" ||
              col.binding === "month9" ||
              col.binding === "month10" ||
              col.binding === "month11" ||
              col.binding === "month12"
            ) {
              let panel = this.state.colGrid.columnHeaders;
              calAmountCell(col.binding, panel, s, sel);
            }

            if (
              col.binding === "state" ||
              col.binding === "salesMonth" ||
              col.binding === "costPercent"
            ) {
              let item = s.rows[sel.row].dataItem;
              var months = [
                "month1",
                "month2",
                "month3",
                "month4",
                "month5",
                "month6",
                "month7",
                "month8",
                "month9",
                "month10",
                "month11",
                "month12",
              ];
              let theValue = undefined;
              for (let i = 0; i < months.length; i++) {
                if (
                  item[months[i]].length === undefined ||
                  item[months[i]].length > 0
                ) {
                  theValue = item[months[i]];
                  item[months[i]] = "";
                  if (item.salesMonth > 3 && item.salesMonth < 13) {
                    item[months[item.salesMonth - 4]] = theValue;
                  } else if (item.salesMonth > 0 && item.salesMonth < 4) {
                    item[months[parseInt(item.salesMonth) + 8]] = theValue;
                  }
                  break;
                }
              }

              if (item.salesMonth === null) {
                s.rows[sel.row].dataItem.gp1 = 0;
                s.rows[sel.row].dataItem.gp2 = 0;
                s.rows[sel.row].dataItem.gp3 = 0;
                s.rows[sel.row].dataItem.gp4 = 0;
                s.rows[sel.row].dataItem.gpPercent1 = 0;
                s.rows[sel.row].dataItem.gpPercent2 = 0;
                s.rows[sel.row].dataItem.gpPercent3 = 0;
                s.rows[sel.row].dataItem.gpPercent4 = 0;
              } else {
                calAmountCell(col.binding, panel, s, sel);
              }
            }
          }
        }
      }
    });
  }

  getNextStringId(str) {
    let index = str.length - 1;
    let baseCode = str.charCodeAt(index);
    do {
      baseCode = str.charCodeAt(index);
      let strArr = str.split("");
      if (strArr[index] === "Z") {
        strArr[index] = "A";
        if (index === 0) {
          strArr.unshift("A");
        }
      } else {
        strArr[index] = String.fromCharCode(baseCode + 1);
      }
      str = strArr.join("");
      index--;
    } while (baseCode === 90);
    return str;
  }

  initializedPicker(picker) {
    this.columnPicker = picker;
    if (this.flex) {
      this.columnPicker.itemsSource = this.flex.columns;
      this.columnPicker.checkedMemberPath = "visible";
      this.columnPicker.displayMemberPath = "header";
      this.columnPicker.lostFocus.addHandler(() => {
        hidePopup(this.columnPicker.hostElement);
      });
      this.columnPicker.formatItem.addHandler((s, e) => {
        this.enableDragItem(e.item, this.isDragEnabled);
      });
    }
  }

  handleDragStart(e) {
    this.dragSrc = closest(e.target, ".wj-listbox-item");
    if (this.dragSrc) {
      e.dataTransfer.setData("text", this.dragSrc.innerHTML);
      e.dataTransfer.effectAllowed = "move";
    }
  }

  handleDragOver(e) {
    const dragOver = closest(e.target, ".wj-listbox-item");
    if (this.dragDst && this.dragDst !== dragOver) {
      this.removeDropMarker();
    }
    if (dragOver && dragOver !== this.dragSrc) {
      e.preventDefault();
      e.dataTransfer.dropEffect = "move";
      this.dragDst = dragOver;
      const src = this.getElementIndex(this.dragSrc);
      const dst = this.getElementIndex(this.dragDst);
      this.removeDropMarker();
      this.addDropMarker(dst > src);
    } else {
      this.dragDst = null;
    }
  }

  handleDrop(e) {
    if (this.dragSrc && this.dragDst) {
      e.preventDefault();
      const src = this.getElementIndex(this.dragSrc);
      const dst = this.getElementIndex(this.dragDst);
      this.flex.columns.moveElement(src, dst);
    }
  }

  handleDragEnd() {
    this.dragSrc = null;
    this.dragDst = null;
    this.removeDropMarker();
  }

  enableDrag(e) {
    const element = e.target;
    this.isDragEnabled = element.checked;
    const host = this.columnPicker.hostElement;
    const items = host.getElementsByClassName("wj-listbox-item");
    for (let i = 0; i < items.length; i++) {
      this.enableDragItem(items[i], this.isDragEnabled);
    }
  }

  enableDragItem(item, enabled) {
    item.setAttribute("draggable", enabled.toString());
  }

  getElementIndex(element) {
    const parent = element.parentElement;
    const siblings = Array.prototype.slice.call(parent.children);
    return siblings.indexOf(element);
  }

  removeDropMarker() {
    removeChild(getElement(".drop-marker"));
  }

  addDropMarker(isAfterPos) {
    const itemsGap = 10;
    const width = 6;
    const margin = itemsGap / width;
    const height = this.dragDst.clientHeight;
    const topPos = this.dragDst.offsetTop;
    const leftPos = isAfterPos
      ? this.dragDst.offsetLeft + this.dragDst.clientWidth + margin
      : this.dragDst.offsetLeft - itemsGap + margin;
    const css = `top:${topPos}px;left:${leftPos}px;height:${height}px;width:${width}px`;
    const html = `<div className="drop-marker" style="${css}">&nbsp</div>`;
    createElement(html, this.columnPicker.hostElement);
  }
}

export default SalesPlan;

SalesPlan.propTypes = {
  changeLocation: PropTypes.func,
};
