import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  MultiSelect,
  Select,
  Tabs,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { TbBinaryTree2 } from "react-icons/tb";
import { GoSettings } from "react-icons/go";
import { useAtom } from "jotai";
import React, { useState } from "react";
import { chartItemsAtom } from "../../state/chart-item-atom";
import PropTypes from "prop-types";

export default function LineChartEditor({
  widget,
  metacolumn,
  oldDatasource,
  targetId,
  activeTab,
  handleEditorClose,
  tempLayouts,
}) {
  // Provider Items
  const [itemsState, setItemState] = useAtom(chartItemsAtom);
  const [data] = useState(metacolumn);

  const form = useForm({
    initialValues: {
      title: oldDatasource?.title || "",
      header: oldDatasource?.header || "",
      bindingX: oldDatasource?.configure.bindingX || "country",
      bindingY: oldDatasource?.configure.bindingY || "",
      isLegend: oldDatasource?.isLegend || false,
      type: oldDatasource?.type || "",
      xAxisTitle: oldDatasource?.settings?.xAxisTitle || "",
      yAxisTitle: oldDatasource?.settings?.yAxisTitle || "",
      legendPosition: oldDatasource?.settings.legendPosition || "Bottom",
      legendToggle: oldDatasource?.settings.legendToggle || false,
      lineChartType: oldDatasource?.settings.lineChartType || "line",
      dataLabelPosition: oldDatasource?.settings.dataLabelPosition || "None",
    },

    // functions will be used to validate values at corresponding key
    validate: {
      title: isNotEmpty("Widget title cannot be empty"),
      header: isNotEmpty("Header cannot be empty"),
      bindingX: isNotEmpty("X-axis cannot be empty"),
      bindingY: isNotEmpty("Y-axis cannot be empty"),
    },
  });

  const handleOnSubmit = (values) => {
    if (oldDatasource) {
      handleEdit(values);
    } else {
      handleNew(values);
    }
  };

  // Add New Widgets
  const handleNew = (value) => {
    // Creating new datasource
    // Get data from user input

    const newDatasource = {
      title: value.title,
      header: value.header,
      type: widget.type,
      configure: {
        bindingX: value.bindingX,
        bindingY: value.bindingY,
      },
      settings: {
        xAxisTitle: value.xAxisTitle,
        yAxisTitle: value.yAxisTitle,
        legendPosition: value.legendPosition,
        lineChartType: value.lineChartType,
        dataLabelPosition: value.dataLabelPosition,
        legendToggle: value.legendToggle,
      },
    };

    // calculate new item position
    let x = 0,
      y = 0,
      defaultWidth = 4,
      defaultHeight = 8,
      highestX = 0,
      highestY = 0,
      currentX = 0,
      currentY = 0,
      whitespaceYStart = 0;
    let cols = [
      { count: 12, name: "md" },
      { count: 6, name: "sm" },
      { count: 4, name: "xs" },
      { count: 2, name: "xxs" },
    ];
    let breakpoint = "";
    for (let i = 0; i < cols.length; i++) {
      if (cols[i].count === itemsState.cols) {
        breakpoint = cols[i].name;
        break;
      }
    }
    let layout = tempLayouts[breakpoint] || [];
    if (layout.length)
      for (let i = 0; i < layout.length; i++) {
        currentX = layout[i].x + layout[i].w;
        currentY = layout[i].y + layout[i].h;
        if (currentX + defaultWidth > itemsState.cols) {
          highestX = 0;
        } else if (
          currentX > highestX ||
          currentX + defaultWidth <= itemsState.cols
        ) {
          highestX = currentX;
        }
        if (currentY >= highestY) {
          highestY = currentY;
          whitespaceYStart = highestY;
        } else if (currentY < highestY) {
          whitespaceYStart = currentY;
          highestX -= layout[i].w;
        }
      }
    y = whitespaceYStart;
    x = highestX;
    // create new item
    const newItems = {
      i: self.crypto.randomUUID(),
      x: x,
      y: y,
      w: defaultWidth,
      h: defaultHeight,
      export: "export",
      datasources: newDatasource,
    };
    // find active section
    let activeSection = itemsState.sections.filter(
      (state) => state.sectionValue === activeTab
    );

    // get items from active section
    let localStorageItems = activeSection[0].items || [];
    // push new item to items
    localStorageItems.push(newItems);
    // update section
    const updatedSections = itemsState.sections.map((section) => {
      if (section.sectionValue === activeTab) {
        return {
          ...section,
          items: localStorageItems,
        };
      }
      return section;
    });
    // update state
    setItemState({
      ...itemsState,
      sections: updatedSections,
    });

    setItemState((prev) => ({ ...prev, items: localStorageItems }));
    form.reset();
    handleEditorClose();
  };

  const handleEdit = (value) => {
    // Update datasource
    // Get data from user input
    const newDatasource = {
      title: value.title,
      header: value.header,
      type: widget.type,
      configure: { bindingX: value.bindingX, bindingY: value.bindingY },
      settings: {
        xAxisTitle: value.xAxisTitle,
        yAxisTitle: value.yAxisTitle,
        legendPosition: value.legendPosition,
        lineChartType: value.lineChartType,
        dataLabelPosition: value.dataLabelPosition,
        legendToggle: value.legendToggle,
      },
    };

    // find active section
    let activeSection = itemsState.sections.filter(
      (state) => state.sectionValue === activeTab
    );
    // get items from active section
    let localStorageItems = activeSection[0].items || [];
    // find item index
    let itemIndex = localStorageItems.findIndex((item) => item.i === targetId);
    // update item
    localStorageItems[itemIndex].datasources = newDatasource;
    // update section
    const updatedSections = itemsState.sections.map((section) => {
      if (section.sectionValue === activeTab) {
        return {
          ...section,
          items: localStorageItems,
        };
      }
      return section;
    });
    // update state
    setItemState({
      ...itemsState,
      sections: updatedSections,
    });
    form.reset();
    handleEditorClose();
  };

  return (
    <Box miw={220} mx="auto">
      <form
        onSubmit={form.onSubmit((values) => {
          handleOnSubmit(values);
        })}
      >
        <Grid align="center" gutter="xs">
          <Grid.Col span={4}>
            <Text weight={500} size="sm">
              Widget Title
            </Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              placeholder="Widget Title"
              {...form.getInputProps("title")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Text weight={500} size="sm">
              Chart Title
            </Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              placeholder="Chart Title"
              {...form.getInputProps("header")}
            />
          </Grid.Col>
        </Grid>

        <Tabs radius="xs" defaultValue="binding">
          <Tabs.List>
            <Tabs.Tab value="binding" icon={<TbBinaryTree2 size="0.8rem" />}>
              Binding
            </Tabs.Tab>
            <Tabs.Tab value="settings" icon={<GoSettings size="0.8rem" />}>
              Settings
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel
            value="binding"
            pt="xs"
            h={"calc(70vh - 40px)"}
            style={{ overflow: "hidden" }}
          >
            <Grid align="center" gutter="xs">
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  X-axis
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  searchable
                  nothingFound="Nothing found"
                  placeholder="Choose X-axis"
                  data={data}
                  {...form.getInputProps("bindingX")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  Y-axis
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <MultiSelect
                  searchable
                  withAsterisk
                  nothingFound="Nothing found"
                  placeholder="Choose Y-axis"
                  data={data}
                  {...form.getInputProps("bindingY")}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>

          <Tabs.Panel value="settings" pt="xs" h={"calc(70vh - 40px)"}>
            <Grid align="center" gutter="xs">
              <Grid.Col span={12}>
                <Title order={6}>Display</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  X-axis Title
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  withAsterisk
                  placeholder="Enter X-axis Title"
                  {...form.getInputProps("xAxisTitle")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Y-axis Title
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  withAsterisk
                  placeholder="Enter Y-axis Title"
                  {...form.getInputProps("yAxisTitle")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Line Chart Type
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  placeholder="Choose Data Label Position"
                  searchable
                  nothingFound="No Options"
                  data={[
                    { label: "Line", value: "line" },
                    { label: "Spline", value: "spline" },
                  ]}
                  {...form.getInputProps("lineChartType")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Title order={6}>Legend</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Toggle
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Checkbox
                  checked={form.values.legendToggle}
                  {...form.getInputProps("legendToggle")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Position
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  placeholder="Choose Legend Position"
                  searchable
                  nothingFound="No Options"
                  data={[
                    { label: "None", value: "None" },
                    { label: "Top", value: "Top" },
                    { label: "Bottom", value: "Bottom" },
                    { label: "Left", value: "Left" },
                    { label: "Right", value: "Right" },
                  ]}
                  {...form.getInputProps("legendPosition")}
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <Title order={6}>Data Label</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  Position
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  placeholder="Choose Data Label Position"
                  searchable
                  nothingFound="No Options"
                  data={[
                    { label: "None", value: "None" },
                    { label: "Top", value: "Top" },
                    { label: "Bottom", value: "Bottom" },
                  ]}
                  {...form.getInputProps("dataLabelPosition")}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>
        </Tabs>
        <Divider my={5} variant="dotted" />
        <Flex
          mih={"auto"}
          gap="md"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button type="submit" color="indigo">
            {oldDatasource ? "Update" : "Add"}
          </Button>
        </Flex>
      </form>
    </Box>
  );
}

LineChartEditor.propTypes = {
  widget: PropTypes.object,
  metacolumn: PropTypes.array,
  oldDatasource: PropTypes.object,
  targetId: PropTypes.number,
  activeTab: PropTypes.string,
  handleEditorClose: PropTypes.func,
  tempLayouts: PropTypes.array,
};
