import { Box, Textarea } from "@mantine/core";
import React from "react";
import { QueryPreview } from "../../../../utilities/utilities";
import PropTypes from "prop-types";

export default function Preview(props) {
  const { state, controls } = props;

  let preview = QueryPreview(state, controls);
  return (
    <Box bg="#eee" mih={50} p={5}>
      {/* <Highlight
        align="left"
        highlight={["SELECT", "FROM","LIMIT"]}
        highlightStyles={(theme) => ({
          backgroundImage: theme.fn.linearGradient(
            45,
            theme.colors.cyan[5],
            theme.colors.indigo[5]
          ),
          fontWeight: 500,
          WebkitBackgroundClip: "text",
          WebkitTextFillColor: "transparent",
        })}
      >
        {preview}
      </Highlight> */}
      <Textarea
        id="preview"
        label="Preview"
        value={preview}
        autosize
        minRows={4}
        maxRows={5}
        readOnly
      />
    </Box>
  );
}

Preview.propTypes = { state: PropTypes.any, controls: PropTypes.any };
