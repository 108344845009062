import { ActionIcon, Group, Table, Text, Tooltip } from "@mantine/core";
import { IconEyeCheck } from "@tabler/icons-react";
import { IconTrash } from "@tabler/icons-react";
import moment from "moment/moment";
import PropTypes from "prop-types";
import { modals } from "@mantine/modals";

import React from "react";

export default function DashboardTable(props) {
  const { headerStyle, items, header, onDelete, onUpdate } = props;

  if (items.length === 0) return null;

  const openDeleteModal = (item) =>
    modals.openConfirmModal({
      title: "Delete dashboard",
      centered: true,
      children: (
        <Text size="sm">
          Are you sure you want to delete {item.dashboard_name}?
        </Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onConfirm: () => onDelete(item.id),
    });

  return (
    <Table border="1px solid black">
      <thead>
        <tr style={{ backgroundColor: "#2aafab" }}>
          <th style={headerStyle}>{header}</th>
          <th style={headerStyle}>Created By</th>
          <th style={headerStyle}>Craeted At</th>
          <th style={headerStyle}>Updated By</th>
          <th style={headerStyle}>Updated At</th>
          <th style={headerStyle}>Actions</th>
        </tr>
      </thead>
      <tbody style={{ textAlign: "center" }}>
        {items?.map((item) => (
          <tr key={crypto.randomUUID()}>
            <td>{item.dashboard_name}</td>
            <td>{item.created_by}</td>
            <td>{moment(item.created_at).format("MMMM/DD/YYYY h:mm:ss A")}</td>
            <td>{item.updated_by}</td>
            <td>{moment(item.updated_at).format("MMMM/DD/YYYY h:mm:ss A")}</td>
            <td
              style={{
                width: 90,
                borderRight: "1px solid black",
                textAlign: "center",
              }}
            >
              <Group spacing="xs">
                <Tooltip label="View">
                  <ActionIcon
                    variant="filled"
                    color="teal"
                    onClick={() => onUpdate(item.id)}
                  >
                    <IconEyeCheck size="1rem" />
                  </ActionIcon>
                </Tooltip>
                <Tooltip label="Delete">
                  <ActionIcon
                    variant="filled"
                    color="pink"
                    // onClick={() => onDelete(item.id)}
                    onClick={() => openDeleteModal(item)}
                  >
                    <IconTrash size="1rem" />
                  </ActionIcon>
                </Tooltip>
              </Group>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}
DashboardTable.propTypes = {
  headerStyle: PropTypes.any,
  items: PropTypes.any,
  header: PropTypes.string,
  onDelete: PropTypes.func,
  onUpdate: PropTypes.func,
};
