import React from "react";
import {
  BUSINESS_SECTOR_LIST_URL,
  FISCAL_YEAR_LIST_URL,
  BUDGET_LIST_URL,
  LY_BUDGET_LIST_URL,
  TOKEN,
} from "../../constants/api.path";
import * as wjcCore from "@grapecity/wijmo";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import SuccessModal from "../../modalPopups/successModal";
import { BiSave } from "react-icons/bi";
import { AiOutlineReload } from "react-icons/ai";
import { Tooltip as ReactTooltip } from "react-tooltip";
import cacheUpdate from "../cacheUpdate/cacheUpdate";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

export default class BudgetImport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      flex: null,
      error: false,
      fiscalList: [],
      salesPlanYear: null,
      sheetName: null,
      sectName: [],
      showSuccessModal: "",
    };
    this.sheets = [
      { id: 1, name: "Budget" },
      { id: 2, name: "Last year" },
    ];
    this.months = [
      { id: 1, name: "Jan" },
      { id: 2, name: "Feb" },
      { id: 3, name: "Mar" },
      { id: 4, name: "Apr" },
      { id: 5, name: "May" },
      { id: 6, name: "Jun" },
      { id: 7, name: "Jul" },
      { id: 8, name: "Aug" },
      { id: 9, name: "Sep" },
      { id: 10, name: "Oct" },
      { id: 11, name: "Nov" },
      { id: 12, name: "Dec" },
    ];
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ fiscalList: result.data.fiscalYearList });
        let yearName = [];
        result.data.fiscalYearList.forEach(function (fYear) {
          yearName.push(fYear.fiscalYear);
        });
        this.setState({ fYName: yearName });
      });

    fetch(BUSINESS_SECTOR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ sectorList: result.data.sectorList });
        let sectorName = [];
        result.data.sectorList.forEach(function (sector) {
          sectorName.push(sector.sectorName);
        });
        this.setState({ sectName: sectorName });
      });
  }

  handleChange(e) {
    const fieldValue = e.target.value;
    this.setState({ salesPlanYear: fieldValue });
  }

  getFileName() {
    let fileName = document.getElementById("budgetImport").files[0].name;
    this.setState({
      fileName: fileName,
    });
  }

  getSheetName() {
    let sheetName = document.getElementById("sheetName").value;
    this.setState({ sheetName: sheetName });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row justify-content-between" style={{ margin: "0" }}>
          <div className="col-md-8">
            <div className="row">
              <div
                className="form-inline col-md-4"
                style={{ paddingLeft: "0.3vw", paddingRight: "0" }}
              >
                <input
                  type="file"
                  className="form-control"
                  id="budgetImport"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={this.getFileName.bind(this)}
                />
              </div>
              <div
                className="form-inline col-md-1"
                style={{ paddingLeft: "5px" }}
              >
                <button
                  className="actionIconOutline load bg-blue"
                  onClick={this.load.bind(this)}
                  id="loadButton"
                  data-tooltip-content="Load"
                >
                  <AiOutlineReload />
                  <ReactTooltip place="right" anchorId="loadButton" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="row justify-content-end">
              <div className="col-md-5" style={{ paddingRight: "4px" }}>
                <select
                  name="fiscalYear"
                  className="form-select"
                  onChange={this.handleChange}
                >
                  <option value="" hidden>
                    Sales Plan Year
                  </option>
                  {this.state.fiscalList.map((item, index) => (
                    <option key={index + 1} value={item.fiscalYearId}>
                      {item.fiscalYear}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="col-md-4"
                style={{ paddingLeft: "4px", paddingRight: "0" }}
              >
                <select
                  name="sheetname"
                  className="form-select"
                  id="sheetName"
                  onChange={() => this.getSheetName()}
                >
                  <option value="" hidden>
                    Sheet Name
                  </option>
                  {this.sheets.map((sheet) => (
                    <option key={sheet.id} value={sheet.name}>
                      {sheet.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-1" style={{ paddingLeft: "5px" }}>
                <button
                  className="actionIconOutline save bg-blue"
                  id="saveButton"
                  data-tooltip-content="Save"
                  onClick={() => this.getFileData(this.state.salesPlanYear)}
                >
                  <BiSave />
                  <ReactTooltip place="right" anchorId="saveButton" />
                </button>
              </div>
              <SuccessModal
                show={this.state.showSuccessModal}
                title={this.state.resultMessage}
                onSubmit={() => {
                  this.setState({ showSuccessModal: false }, () => {});
                }}
                onHide={() => {
                  this.setState({ showSuccessModal: false }, () => {});
                }}
              />
            </div>
          </div>
        </div>
        <div style={{ marginLeft: "10px" }}>
          {this.state.error && (
            <span className="error">{this.errorMessage}</span>
          )}
        </div>

        <wjGridSheet.FlexSheet
          initialized={this.initializeFlexSheet.bind(this)}
          style={{
            height: "75vh",
            marginTop: "5px",
            marginLeft: "3px",
          }}
          id="budgetSheet"
        >
          <wjGridSheet.Sheet name="New Sheet" />
        </wjGridSheet.FlexSheet>
      </div>
    );
  }

  initializeFlexSheet(flex) {
    this.setState({
      flex: flex,
    });
  }

  load() {
    this.setState({ error: false });
    let file = document.getElementById("budgetImport");
    if (this.state.flex && file.files[0]) {
      this.state.flex.loadAsync(file.files[0]);
    } else {
      this.setState({ error: true });
      this.errorMessage = "There is no upload file, please choose a file!";
    }
  }

  getFileData(salesPlanYear) {
    let budgetList = [];
    const sheet = this.state.flex.selectedSheet;
    this.setState({ error: false });
    let monthNo = 0;
    if (salesPlanYear !== null && this.state.sheetName === sheet.name) {
      for (let c = 5; c < this.state.flex.columns.length; c++) {
        let monthName;
        monthNo++;
        if (this.state.flex.getCellValue(3, c) === "Total") {
          break;
        }
        this.months.map(
          (month) =>
            this.state.flex.getCellValue(3, c) === month.name &&
            (monthName = month.id)
        );
        var nesicOR,
          nesicSales,
          nesicCost,
          otherOR,
          otherSales,
          otherCost,
          salesExpense,
          gaExpense,
          totalOR,
          totalSales,
          totalCost,
          sga,
          gp,
          costRatio,
          op,
          opRatio,
          nonOPIncome,
          nonOPExpense,
          netProfit,
          netProfitRatio,
          purchaseToNesic,
          consolidatedOR,
          consolidatedSales,
          sectorId;
        let rowCount = 1;
        for (let r = 6; r < this.state.flex.rows.length; r++) {
          if (this.state.flex.getCellValue(r, 3) !== "") {
            if (this.state.flex.getCellValue(r, 3) === "NESIC Tokyo") {
              if (this.state.flex.getCellValue(r + 2, 2) === "Order Received") {
                nesicOR = this.state.flex.getCellValue(r, c) || 0;
              }
              if (this.state.flex.getCellValue(r + 2, 2) === "Sales") {
                nesicSales = this.state.flex.getCellValue(r, c) || 0;
              }
              if (this.state.flex.getCellValue(r + 2, 2) === "Costs") {
                nesicCost = this.state.flex.getCellValue(r, c) || 0;
              }
            }

            if (this.state.flex.getCellValue(r, 3) === "Other") {
              if (this.state.flex.getCellValue(r + 1, 2) === "Order Received") {
                otherOR = this.state.flex.getCellValue(r, c) || 0;
              }
              if (this.state.flex.getCellValue(r + 1, 2) === "Sales") {
                otherSales = this.state.flex.getCellValue(r, c) || 0;
              }
              if (this.state.flex.getCellValue(r + 1, 2) === "Costs") {
                otherCost = this.state.flex.getCellValue(r, c) || 0;
              }
            }

            if (this.state.flex.getCellValue(r, 3) === "Sales Expenses") {
              salesExpense = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 3) === "GA Expenses") {
              gaExpense = this.state.flex.getCellValue(r, c) || 0;
            }
          } else if (
            this.state.flex.getCellValue(r, 3) === "" &&
            this.state.flex.getCellValue(r, 2) !== ""
          ) {
            if (this.state.flex.getCellValue(r, 2) === "Order Received") {
              totalOR = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "Sales") {
              totalSales = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "Costs") {
              totalCost = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "SGA") {
              sga = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "GP") {
              gp = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "Cost Ratio") {
              costRatio = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "OP") {
              op = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "OP Ratio") {
              opRatio = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "Non-Operating Income") {
              nonOPIncome = this.state.flex.getCellValue(r, c) || 0;
            }
            if (
              this.state.flex.getCellValue(r, 2) === "Non-Operating Expense"
            ) {
              nonOPExpense = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "NP") {
              netProfit = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "NP Ratio") {
              netProfitRatio = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "Purchase to NESIC-G") {
              purchaseToNesic = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "Consolidated OR") {
              consolidatedOR = this.state.flex.getCellValue(r, c) || 0;
            }
            if (this.state.flex.getCellValue(r, 2) === "Consolidated Sales") {
              consolidatedSales = this.state.flex.getCellValue(r, c) || 0;
            }
          }

          if (this.state.flex.getCellValue(r, 1)) {
            let sectorName = this.state.flex.getCellValue(r, 1).split(" ")[0];
            if (!this.state.sectName.includes(sectorName)) {
              this.sectFlag = true;
            }

            let sector = "";
            this.state.sectorList.map(
              (sect) =>
                sect.sectorName.includes(sectorName) && (sector = sect.sectorId)
            );
            sectorId = sector;
          }

          var budget = {
            monthNo: monthNo,
            monthName: monthName,
            nesicOR: nesicOR || 0,
            otherOR: otherOR,
            totalOR: totalOR,
            nesicSales: nesicSales,
            otherSales: otherSales,
            totalSales: totalSales,
            nesicCost: nesicCost,
            otherCost: otherCost,
            totalCost: totalCost,
            gp: gp,
            costRatio: costRatio,
            salesExpense: salesExpense,
            gaExpense: gaExpense,
            sga: sga,
            op: op,
            opRatio: opRatio,
            nonOPIncome: nonOPIncome,
            nonOPExpense: nonOPExpense,
            netProfit: netProfit,
            netProfitRatio: netProfitRatio,
            purchaseToNesic: purchaseToNesic,
            consolidatedOR: consolidatedOR,
            consolidatedSales: consolidatedSales,
            fiscalYearId: salesPlanYear,
            sectorId: sectorId,
          };
          if (
            this.state.flex.getCellValue(r, 2) === "" &&
            this.state.flex.getCellValue(r, 3) === ""
          ) {
            break;
          }
          if (rowCount < 23) {
            rowCount++;
          } else {
            budgetList.push(budget);
            rowCount = 1;
          }
        }
      }
      if (!this.error) {
        let saveData = { budgetList: [...budgetList] };
        this.savetoDB(saveData, this.state.sheetName);
      }
    } else if (salesPlanYear === null) {
      this.setState({ error: true });
      this.errorMessage = "Please select fiscal year!";
    } else if (this.state.sheetName === null) {
      this.setState({ error: true });
      this.errorMessage = "Please select sheet name!";
    } else if (this.state.sheetName !== sheet.name) {
      this.setState({ error: true });
      this.errorMessage = "Please select " + this.state.sheetName + " sheet!";
    }
  }

  savetoDB(added, sheetName) {
    let url;
    if (sheetName === "Budget") {
      url = BUDGET_LIST_URL;
    } else {
      url = LY_BUDGET_LIST_URL;
    }
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify(added),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.result) {
          this.setState(
            {
              resultMessage: response.message,
              showSuccessModal: true,
            },
            () => {
              cacheUpdate(
                added.budgetList[0].fiscalYearId,
                new Date().getMonth() + 1
              );
            }
          );
        }
      });
  }
}
