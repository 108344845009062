import React from "react";

// Wijmo
import * as wjcCore from "@grapecity/wijmo";
import * as wjGauge from "@grapecity/wijmo.react.gauge";

export default function DashboardRadialGauge() {
  return (
    <div className="radial-gauge">
      <React.Fragment>
        <wjGauge.RadialGauge min={0} max={50} value={27} />
        <p style={{ width: "100%", textAlign: "center" }}>
          <b>Profit for {wjcCore.Globalize.format(new Date(), "MMM'yy")}</b>
        </p>
      </React.Fragment>
    </div>
  );
}
