import { Box, Button, Flex, Paper } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { IconPlayerPlayFilled } from "@tabler/icons-react";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { TOKEN } from "../../../../../../constants/api.path";
import {
  FILTEROPERATOR,
  GET_DATA_BY_QUERY,
  SEARCH_QUERY_FORMAT,
} from "../../../../utilities/constants";
import {
  JsonToJSTableConverter,
  QueryPreview,
} from "../../../../utilities/utilities";
import FilterBy from "./filter-by";
import GroupBy from "./group-by";
import OrderBy from "./order-by";
import Preview from "./preview";
import SearchQueryColumns from "./search-query-columns";
import SearchQueryTable from "./search-query-table";
import SearchQueryToolbar from "./search-query-toolbar";

//JS PULL BRANCH
export default function SearchQueryForm({
  schemaList,
  queryForm,
  handleQueryData,
}) {
  // Switch Control
  const [controls, setControls] = useState({
    isFilter: false,
    isGroup: false,
    isOrder: false,
    isPreview: false,
  });
  const [isLoadingQuery, setIsLoadingQuery] = useState(false);
  // const [tableData, setTableData] = useState([]);
  const [state, setState] = useState({
    queryName: "",
    format: SEARCH_QUERY_FORMAT[0].value,
    tableName: "",
    columns: [{ columnName: "", aggregation: "", alias: "" }],
    filters: {
      filterType: "AND",
      filterList: [
        {
          filterByName: "",
          filterByType: FILTEROPERATOR[0].value,
          filterByString: "",
          filterByFirstWord: "",
          filterBySecondWord: "",
          filterByNear: "2",
          filterByDate: "",
          filterByFirstDate: "",
          filterBySecondDate: "",
          filterByDateTime: "",
          filterByFirstDateTime: "",
          filterBySecondDateTime: "",
          filterByNumber: 0,
          filterByFirstNumber: 0,
          filterBySecondNumber: 0,
        },
      ],
    },
    groups: [],
    orders: {
      orderBy: "",
      ordering: "",
      limit: 50,
    },
  });

  // transform json data into js
  const transformedTableData = JsonToJSTableConverter(schemaList);
  const tableColumns = transformedTableData.map((table) => {
    return table.label;
  });

  useEffect(() => {
    if (queryForm) {
      const query_form = queryForm.query_form.state
        ? queryForm.query_form
        : JSON.parse(JSON.parse(queryForm.query_form));
      console.log(query_form);
      setState({
        ...state,
        queryName: query_form.state.queryName,
        format: query_form.state.format,
        tableName: query_form.state.tableName,
        columns: query_form.state.columns,
        filters: query_form.state.filters,
        groups: query_form.state.groups,
        orders: query_form.state.orders,
      });
      setControls({
        ...controls,
        isFilter: query_form.controls.isFilter,
        isGroup: query_form.controls.isGroup,
        isOrder: query_form.controls.isOrder,
      });
    }
  }, []);

  useEffect(() => {}, [isLoadingQuery]);

  const handleControlChange = (e) => {
    setControls({
      ...controls,
      [e.currentTarget.name]: e.currentTarget.checked,
    });
  };

  const handleTableChange = (name, e) => {
    // Reset State
    setState({
      ...state,
      [name]: e,
      columns: [{ columnName: "", aggregation: "", alias: "" }],
      filters: {
        filterType: "AND",
        filterList: [
          {
            filterByName: "",
            filterByType: FILTEROPERATOR[0].value,
            filterByString: "",
            filterByFirstWord: "",
            filterBySecondWord: "",
            filterByNear: "2",
            filterByDate: "",
            filterByFirstDate: "",
            filterBySecondDate: "",
            filterByDateTime: "",
            filterByFirstDateTime: "",
            filterBySecondDateTime: "",
            filterByNumber: 0,
            filterByFirstNumber: 0,
            filterBySecondNumber: 0,
          },
        ],
      },
      groups: [],
      orders: {
        orderBy: "",
        ordering: "",
        limit: 50,
      },
    });
  };

  const handleInputChange = (name, value) => {
    setState({
      ...state,
      [name]: value,
    });
  };

  const handleColumsChange = (index, name, e) => {
    setState((prevState) => {
      const columns = [...prevState.columns];
      columns[index] = { ...columns[index], [name]: e };
      return { ...prevState, columns };
    });
  };

  const handleAddColumn = () => {
    setState({
      ...state,
      columns: [
        ...state.columns,
        { columnName: "", aggregation: "", alias: "" },
      ],
    });
  };

  const handleRemoveColumn = (index) => {
    let oldColumns = [...state.columns];
    oldColumns.splice(index, 1);
    setState({
      ...state,
      columns: oldColumns,
    });
  };

  const handleFiltersChange = (name, value) => {
    setState((prevState) => {
      return { ...prevState, filters: { ...prevState.filters, [name]: value } };
    });
  };

  const handleFilterByChange = (index, name, e) => {
    if (name === "filterByName") {
      setState((prevState) => {
        const filterList = [...prevState.filters.filterList];
        filterList[index] = {
          ...filterList[index],
          filterByName: e,
          filterByType: FILTEROPERATOR[0].value,
          filterByString: "",
          filterByFirstWord: "",
          filterBySecondWord: "",
          filterByNear: "2",
          filterByDate: "",
          filterByFirstDate: "",
          filterBySecondDate: "",
          filterByDateTime: "",
          filterByFirstDateTime: "",
          filterBySecondDateTime: "",
          filterByNumber: 0,
          filterByFirstNumber: 0,
          filterBySecondNumber: 0,
        };
        return {
          ...prevState,
          filters: { ...prevState.filters, filterList: filterList },
        };
      });
    } else {
      setState((prevState) => {
        const filterList = [...prevState.filters.filterList];
        filterList[index] = { ...filterList[index], [name]: e };
        return {
          ...prevState,
          filters: { ...prevState.filters, filterList: filterList },
        };
      });
    }
  };

  const handleAddFilter = () => {
    setState({
      ...state,
      filters: {
        ...state.filters,
        filterList: [
          ...state.filters.filterList,
          {
            filterByName: "",
            filterByType: FILTEROPERATOR[0].value,
            filterByString: "",
            filterByFirstWord: "",
            filterBySecondWord: "",
            filterByNear: "2",
          },
        ],
      },
    });
  };

  const handleRemoveFilter = (index) => {
    let oldFilterList = [...state.filters.filterList];
    oldFilterList.splice(index, 1);
    setState({
      ...state,
      filters: {
        ...state.filters,
        filterList: oldFilterList,
      },
    });
  };

  const handleGroupChange = (index, value) => {
    setState((prevState) => {
      const groups = [...prevState.groups];
      groups[index] = value;
      return { ...prevState, groups };
    });
  };

  const handleAddGroup = () => {
    setState({
      ...state,
      groups: [...state.groups, ""],
    });
  };

  const handleRemoveGroup = (index) => {
    let oldGroups = [...state.groups];
    oldGroups.splice(index, 1);
    setState({
      ...state,
      groups: oldGroups,
    });
  };

  const handleOrdersChange = (name, value) => {
    setState((prevState) => {
      return { ...prevState, orders: { ...prevState.orders, [name]: value } };
    });
  };

  const queryFetchApis = async (apiRequests) => {
    try {
      const responses = await Promise.all(apiRequests);
      return responses;
    } catch (error) {
      notifications.show({
        title: `Error occured! Error Code: ${error.status}`,
        message: error.message || "Query is not fetched!",
        autoClose: 2000,
        color: "red",
        withCloseButton: false,
      });
      setIsLoadingQuery(false);
    } finally {
      setIsLoadingQuery(false);
    }
  };

  const handleRunQuery = async () => {
    setIsLoadingQuery(true);
    let query = QueryPreview(state, controls)
      .replace(/\n/g, "")
      .replace(/\t/g, "");

    let requestObj = {
      query: query,
      table_name: state.tableName,
      query_name: state.queryName,
      query_form: {
        state: state,
        controls: controls,
      },
    };

    // let apiRequests = [
    //   axios.post(GET_DATA_BY_QUERY, {
    //     headers: {
    //       "Content-Type": "application/json",
    //       "Access-Control-Allow-Origin": "*",
    //       Authorization: `Bearer ${TOKEN}`,
    //     },
    //     data: JSON.stringify({ query: query }),
    //   }),
    // ];
    let apiRequests = [
      fetch(GET_DATA_BY_QUERY, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
        body: JSON.stringify({ query }),
      }),
    ];
    let responses = await queryFetchApis(apiRequests);
    let data = await responses[0].json();
    handleQueryData(data, requestObj);
  };

  return (
    <Box>
      <Flex
        mih={state.queryName ? 45 : 65}
        bg="#eee"
        gap="md"
        justify="space-between"
        align="center"
        direction="row"
        wrap="wrap"
        color="white"
      >
        <SearchQueryToolbar
          handleInputChange={handleInputChange}
          handleControlChange={handleControlChange}
          state={state}
          controls={controls}
        />

        <Button
          miw={118}
          size="xs"
          // loading={isLoadingQuery}
          leftIcon={<IconPlayerPlayFilled size="0.75rem" />}
          onClick={handleRunQuery}
          disabled={state.queryName === "" ? true : false}
        >
          Run Query
        </Button>
      </Flex>

      <Paper pt={7}>
        <SearchQueryTable
          data={state}
          tableColumns={tableColumns}
          handleTableChange={handleTableChange}
        />
      </Paper>
      <Paper pt={7}>
        <SearchQueryColumns
          tableName={state.tableName}
          columns={state.columns}
          tableList={transformedTableData}
          handleColumsChange={handleColumsChange}
          handleControlChange={handleControlChange}
          handleRemoveColumn={handleRemoveColumn}
          handleAddColumn={handleAddColumn}
        />
      </Paper>

      {!!controls.isFilter && (
        <Paper pt={7}>
          <FilterBy
            filters={state.filters}
            tableName={state.tableName}
            tableList={transformedTableData}
            handleFiltersChange={handleFiltersChange}
            handleRemoveFilter={handleRemoveFilter}
            handleAddFilter={handleAddFilter}
            handleFilterByChange={handleFilterByChange}
          />
        </Paper>
      )}

      {!!controls.isGroup && (
        <Paper pt={7}>
          <GroupBy
            groups={state.groups}
            tableName={state.tableName}
            tableList={transformedTableData}
            handleGroupChange={handleGroupChange}
            handleRemoveGroup={handleRemoveGroup}
            handleAddGroup={handleAddGroup}
          />
        </Paper>
      )}
      {!!controls.isOrder && (
        <Paper pt={7}>
          <OrderBy
            orders={state.orders}
            tableName={state.tableName}
            tableList={transformedTableData}
            handleOrdersChange={handleOrdersChange}
          />
        </Paper>
      )}

      {!!controls.isPreview && (
        <Paper pt={7}>
          <Preview state={state} controls={controls} />
        </Paper>
      )}
    </Box>
  );
}

SearchQueryForm.propTypes = {
  schemaList: PropTypes.any,
  queryForm: PropTypes.any,
  handleQueryData: PropTypes.any,
};
