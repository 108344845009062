import React, { useState, useEffect } from "react";
import "../../assets/css/styles.css";
import { BiEditAlt } from "react-icons/bi";
import {
  COMPANY_LIST_URL,
  COMPANY_UPDATE_URL,
  TOKEN,
} from "../../constants/api.path";
import ConfirmModal from "../../modalPopups/confirmModal";
import CompanyEditModal from "../../modalPopups/companyEditModal";

function Company() {
  const [show, setShow] = useState(false);
  const [showAddEditModal, setAddEditModal] = useState(false);
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState([]);
  const [editingCompany, setEditingCompany] = useState(false);
  const [activeId, setActiveId] = useState({ activeId: "" });
  const [showConfirmModal, setConfirmModal] = useState(false);
  const [resConfirm, setResConfirm] = useState(false);

  var updateMessage = "Are you sure to want to update?";

  function getCompanyList() {
    fetch(COMPANY_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCompany(data.data.companyList);
      });
    setActiveId("");
  }

  useEffect(() => {
    getCompanyList();
  }, []);

  function getSelectedRow(comp) {
    setConfirmModal(false);
    setShow(!show);
    setEditingCompany({ ...comp });
    onRowEditClick(comp.companyId);
  }

  // change bg color of selected row edit icon
  function onRowEditClick(index) {
    setActiveId({ activeId: index });
    var element = document.getElementById(index);
    if (element.classList.contains("bg-tr")) {
      element.classList.remove("bg-tr");
      setShow(false);
    } else {
      element.classList.add("bg-tr");
    }
  }

  function editCompany(e) {
    e.preventDefault();
    if (
      editingCompany.companyName !== "" &&
      editingCompany.companyRegNo !== "" &&
      editingCompany.companyBranchLoc !== "" &&
      editingCompany.companyMainCurrency !== ""
    ) {
      setConfirmModal(true);
      let editComp = {
        companyName: editingCompany.companyName,
        companyFullName: editingCompany.companyFullName,
        companyRegNo: editingCompany.companyRegNo,
        companyBranchLoc: editingCompany.companyBranchLoc,
        companyMainCurrency: editingCompany.companyMainCurrency,
      };
      setResConfirm(true);
      if (resConfirm) {
        fetch(COMPANY_UPDATE_URL + `${editingCompany.companyId}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${TOKEN}`,
          },
          body: JSON.stringify(editComp),
        })
          .then((response) => {
            return response.json();
          })
          .then((data) => {
            if (data.result) {
              getCompanyList();
              setShow(!show);
              setResConfirm(false);
            }
          });
      }
    }
  }

  const handleFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const updateComp = { ...editingCompany };
    updateComp[fieldName] = fieldValue;

    setEditingCompany(updateComp);
  };

  return (
    <div className="row justify-content-center main--body">
      <div className="col-md-12 mt-3">
        <table className="table table-bordered table-hover">
          <thead>
            <tr>
              <th className="text-center">No.</th>
              <th className="text-center">Company Name</th>
              <th className="text-center">Company Full Name</th>
              <th className="text-center">Registration No</th>
              <th className="text-center">Location</th>
              <th className="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            {company.map((comp, index) => (
              <tr
                key={index + 1}
                id={comp.companyId}
                className={comp.companyId === activeId.activeId ? "bg-tr" : ""}
              >
                <td align="right">{index + 1}</td>
                <td className="text-center">{comp.companyName}</td>
                <td className="text-center">{comp.companyFullName}</td>
                <td align="right">{comp.companyRegNo}</td>
                <td className="text-center">{comp.companyBranchLoc}</td>
                <td className="text-center">
                  <button
                    onClick={() => {
                      setAddEditModal(true);
                      getSelectedRow(comp);
                      setTitle("Update Company");
                    }}
                    className="actionIcon edit"
                  >
                    <BiEditAlt />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ConfirmModal
        show={showConfirmModal}
        title={updateMessage}
        onSubmit={(e) => {
          editCompany(e);
          setConfirmModal(false);
        }}
        onCancel={() => {
          setConfirmModal(false);
          setResConfirm(false);
        }}
        onHide={() => {
          setConfirmModal(false);
          setResConfirm(false);
        }}
      />
      <CompanyEditModal
        show={showAddEditModal}
        title={title}
        onSubmit={() => setAddEditModal(false)}
        onHide={() => {
          setAddEditModal(false);
          setEditingCompany(false);
        }}
        editCompany={editCompany}
        editingCompany={editingCompany}
        handleFormChange={handleFormChange}
        setShow={setAddEditModal}
      />
    </div>
  );
}
export default Company;
