import React from "react";
import PropTypes from "prop-types";

// wijmo
import * as wjChart from "@grapecity/wijmo.react.chart";

// component
import { changeLabelPosition } from "../../dashboard-charts/changeLablePosition";

export default function QuarterlyYearlyChart({
  quarterChartData,
  filterRange,
  fiscalYears,
}) {
  var blocks = [
    "ict_mng",
    "facilities",
    "fiber",
    "ict_biz",
    "software&bpo",
    "jp_biz",
    "sales",
    "operating_income",
  ];
  let quarters = ["1Q", "2Q", "3Q", "4Q"];
  const [quarterData, setQuarterData] = React.useState([]);
  const [displayData, setDisplayData] = React.useState({});

  React.useEffect(() => {
    let start = filterRange.from,
      target = filterRange.to;
    let keys = quicksortQuarterly(Object.keys(quarterChartData));
    let yearList = fiscalYears;
    let resultData = {};
    for (let i = 0; i < yearList.length; i++) {
      for (let j = 0; j < keys.length; j++) {
        if (
          yearList[i].fiscalYearId >= start &&
          yearList[i].fiscalYearId <= target
        ) {
          yearList[i].fiscalYear === keys[j] &&
            (resultData[keys[j]] = quarterChartData[keys[j]]);
        }
      }
    }
    setDisplayData(resultData);
  }, [filterRange]);

  React.useEffect(() => {
    let data = [];
    let keys = Object.keys(displayData);
    for (let i = 0; i < keys.length; i++) {
      for (let j = 0; j < quarters.length; j++) {
        if (displayData[keys[i]] === undefined) {
          break;
        }
        data.push({
          id: quarterData.length + 1,
          ...displayData[keys[i]][j],
          name: `${quarters[j]}'${keys[i].slice(2)}`,
        });
      }
    }
    setQuarterData(data);
  }, [displayData]);

  function quicksortQuarterly(arr) {
    if (arr.length <= 1) {
      return arr;
    } else {
      const pivot = arr[0];
      const less = [];
      const greater = [];
      for (let i = 1; i < arr.length; i++) {
        if (arr[i] <= pivot) {
          less.push(arr[i]);
        } else {
          greater.push(arr[i]);
        }
      }
      return [...quicksort(less), pivot, ...quicksort(greater)];
    }
  }
  function quicksort(arr) {
    if (arr.length <= 1) {
      return arr;
    } else {
      const pivot = arr[0];
      const less = [];
      const greater = [];
      for (let i = 1; i < arr.length; i++) {
        if (arr[i].name <= pivot.name) {
          less.push(arr[i]);
        } else {
          greater.push(arr[i]);
        }
      }
      return [...quicksort(less), pivot, ...quicksort(greater)];
    }
  }

  function getMaxVal() {
    let topVal = 0;
    for (let i = 0; i < quarterData.length; i++) {
      if (quarterData[i].sales > topVal) {
        topVal = quarterData[i].sales - (quarterData[i].sales % 10) + 20;
      }
    }
    return topVal;
  }
  
  function render(chart) {
    changeLabelPosition(chart.itemsSource, blocks);
  }

  return (
    <div className="financial-data-summmary">
      <wjChart.FlexChart
        itemsSource={quarterData}
        bindingX="name"
        stacking={true}
        rendered={render.bind(this)}
        header="iSGM Business Trends Quarterly"
        palette={[
          "rgb(68,144,196)",
          "rgb(143,170,220)",
          "rgb(219,219,219)",
          "rgb(91,155,213)",
          "rgb(112,173,71)",
          "rgb(0,176,80)",
          "rgb(68,114,196)",
          "rgb(158,72,14)",
        ]}
        style={{ height: "545px" }}
      >
        <wjChart.FlexChartLegend position="Bottom" />
        <wjChart.FlexChartSeries name="ICT-Mng" binding="ict_mng" />
        <wjChart.FlexChartSeries name="Facilities" binding="facilities" />
        <wjChart.FlexChartSeries name="Fiber" binding="fiber" />
        <wjChart.FlexChartSeries name="ICT Biz." binding="ict_biz" />
        <wjChart.FlexChartSeries name="BPO" binding="software&bpo" />
        <wjChart.FlexChartSeries name="JP Biz." binding="jp_biz" />
        <wjChart.FlexChartSeries
          name="Sales"
          binding="sales"
          chartType="Bubble"
        />
        <wjChart.FlexChartSeries
          name="Operating Income"
          binding="operating_income"
          chartType="LineSymbols"
        >
          <wjChart.FlexChartAxis
            wjProperty="axisY"
            min={-200}
            max={getMaxVal()}
            position="Right"
          />
        </wjChart.FlexChartSeries>
        <wjChart.FlexChartAxis
          wjProperty="axisY"
          max={getMaxVal()}
        ></wjChart.FlexChartAxis>
        <wjChart.FlexChartDataLabel content="{y}" position="Bottom" />
      </wjChart.FlexChart>
    </div>
  );
}

QuarterlyYearlyChart.propTypes = {
  quarterChartData: PropTypes.object,
  filterRange: PropTypes.object,
  fiscalYears: PropTypes.array,
};
