import React from "react";
import PropTypes from "prop-types";

// icons
import { AiOutlineClose } from "react-icons/ai";
export default function Tile({ header, content, index, onRemove }) {
  return (
    <div className="tile-container" draggable={true}>
      <div className="tile-header">
        <b>{header}</b>
        <div
          style={{ width: "24px", height: "24px" }}
          className="close-icon"
          onClick={() => onRemove(index)}
          title="Remove"
        >
          <AiOutlineClose style={{ width: "20px", height: "20px" }} />
        </div>
      </div>
      <div className="tile-body">{content}</div>
    </div>
  );
}

Tile.propTypes = {
  header: PropTypes,
  content: PropTypes,
  index: PropTypes,
  onRemove: PropTypes,
};
