import { environment } from "./environment";

// Login User Token
export const TOKEN = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : sessionStorage.getItem("token");

// Login
export const LOGIN_URL = `${environment.apiUrl}/login`;
export const REGISTER_URL = `${environment.apiUrl}/register`;
export const LOGOUT_URL = `${environment.apiUrl}/logout`;

// Configuration.Company
export const COMPANY_LIST_URL = `${environment.apiUrl}/configuration-service/company`;
export const COMPANY_UPDATE_URL = `${environment.apiUrl}/configuration-service/company/companyId/`;

// Configuration.BusinessSector
export const BUSINESS_SECTOR_LIST_URL = `${environment.apiUrl}/configuration-service/sectors`;
export const BUSINESS_SECTOR_CREATE_URL = `${environment.apiUrl}/configuration-service/sector`;
export const BUSINESS_SECTOR_UPDATE_URL = `${environment.apiUrl}/configuration-service/sectors/sectorId/`;
export const BUSINESS_SECTOR_DELETE_URL = `${environment.apiUrl}/configuration-service/sectors/sectorId/`;

// Configuration.FiscalYear
export const FISCAL_YEAR_LIST_URL = `${environment.apiUrl}/configuration-service/fiscalYears`;
export const FISCAL_YEAR_CREATE_URL = `${environment.apiUrl}/configuration-service/fiscalYear`;
export const FISCAL_YEAR_UPDATE_URL = `${environment.apiUrl}/configuration-service/fiscalYears/fiscalYearId/`;
export const FISCAL_YEAR_DELETE_URL = `${environment.apiUrl}/configuration-service/fiscalYears/fiscalYearId/`;
export const FISCAL_YEAR_BY_ID_URL = `${environment.apiUrl}/configuration-service/fiscalYears/fiscalYearId/`;

// Quarter
export const QUARTER_LIST = `${environment.apiUrl}/configuration-service/quarters`;

// Configuration.ExchangeRate
export const EXCHANGE_RATE_LIST_URL = `${environment.apiUrl}/configuration-service/exchangeRates`;
export const EXCHANGE_RATE_CREATE_URL = `${environment.apiUrl}/configuration-service/exchangeRate`;
export const EXCHANGE_RATE_UPDATE_URL = `${environment.apiUrl}/configuration-service/exchangeRates/exchangeRateId/`;
export const EXCHANGE_RATE_DELETE_URL = `${environment.apiUrl}/configuration-service/exchangeRates/exchangeRateId/`;

// Configuration.Monthly ExchangeRate
export const MONTHLY_EXCHANGE_RATE_LIST_URL = `${environment.apiUrl}/configuration-service/monthExchangeRates`;
export const MONTHLY_EXCHANGE_RATE_CREATE_URL = `${environment.apiUrl}/configuration-service/monthExchangeRate`;
export const MONTHLY_EXCHANGE_RATE_UPDATE_URL = `${environment.apiUrl}/configuration-service/monthExchangeRates/exchangeRateId/`;
export const MONTHLY_EXCHANGE_RATE_DELETE_URL = `${environment.apiUrl}/configuration-service/monthExchangeRates/exchangeRateId/`;

// SalesPlan.SalesPlan
// export const SALES_PLAN_LIST_URL = `${environment.apiUrl}/salesplan-service/salesPlans`;
export const SALES_PLAN_CREATE_UPDATE_URL = `${environment.apiUrl}/salesplan-service/salesPlans`;
export const SALES_PLAN_DELETE_URL = `${environment.apiUrl}/salesplan-service/salesPlans`;
export const SALES_PLAN_SEARCH_URL = `${environment.apiUrl}/salesplan-service/salesPlans/search`;
export const SALES_PLAN_LOCK_CONFIRM = `${environment.apiUrl}/salesplan-service/lock`;
export const SALES_PLAN_FINALLOCK_CONFIRM = `${environment.apiUrl}/salesplan-service/finalLock`;
export const SALES_PLAN_LOCK_URL = `${environment.apiUrl}/salesplan-service/saveBudget`;
export const SALES_PLAN_DRAG_URL = `${environment.apiUrl}/salesplan-service/dragRow`;
export const SALES_PLAN_CLONE_URL = `${environment.apiUrl}/salesplan-service/cloneSalesPlan`;
export const SALES_PLAN_BULK_UPDATE_URL = `${environment.apiUrl}/salesplan-service/bulkUpdate`;

// SGA
export const SGA_LIST_URL = `${environment.apiUrl}/sga-service/sgas`;
export const SGA_CREATE_UPDATE_URL = `${environment.apiUrl}/sga-service/sga`;

// Dashboard
export const DASHBOARD_DATA = `${environment.apiUrl}/dashboard-service/dashboardData`;
export const DYNAMIC_DASHBOARD_DATA = `${environment.apiUrl}/dashboard-service/dynamicData`;

// Dynamic Dashboard
export const GET_DYNAMIC_DASHBOARD_LIST = `${environment.apiUrl}/dashboard-service/dynamicDashboard`;
export const GET_DYNAMIC_DASHBOARD_ONE = `${environment.apiUrl}/dashboard-service/dynamicDashboard/Id`;
export const CREATE_DYNAMIC_DASHBOARD = `${environment.apiUrl}/dashboard-service/dynamicDashboard`;
export const UPDATE_DYNAMIC_DASHBOARD = `${environment.apiUrl}/dashboard-service/dynamicDashboard/Id`;
export const DELETE_DYNAMIC_DASHBOARD = `${environment.apiUrl}/dashboard-service/dynamicDashboard`;

// Report.BudgetImport
export const BUDGET_LIST_URL = `${environment.apiUrl}/report-service/budgetImport`;
export const LY_BUDGET_LIST_URL = `${environment.apiUrl}/report-service/lastYearBudgetImport`;

//Report.Costs
export const REPORTCOST_IMPORT_URL = `${environment.apiUrl}/report-service/reportCostImport`;
export const REPORTCOST_LIST = `${environment.apiUrl}/report-service/reportCosts`;
export const REPORTCOST_CREATE_URL = `${environment.apiUrl}/report-service/reportCost`;
export const REPORTCOST_UPDATE_URL = `${environment.apiUrl}/report-service/reportCost/reportCostId`;

// Report.BCQuarter1
export const BC_ANNUAL_LIST = `${environment.apiUrl}/report-service/bcAnnual`;
export const BC_ANNUAL_YEN_LIST = `${environment.apiUrl}/report-service/bcAnnualYen`;
export const BC_QUARTER1_LIST = `${environment.apiUrl}/report-service/bcQuarter1`;
export const BC_QUARTER1_YEN_LIST = `${environment.apiUrl}/report-service/bcQuarter1Yen`;
export const BC_QUARTER2_LIST = `${environment.apiUrl}/report-service/bcQuarter2`;
export const BC_QUARTER2_YEN_LIST = `${environment.apiUrl}/report-service/bcQuarter2Yen`;
export const BC_QUARTER3_LIST = `${environment.apiUrl}/report-service/bcQuarter3`;
export const BC_QUARTER3_YEN_LIST = `${environment.apiUrl}/report-service/bcQuarter3Yen`;
export const BC_QUARTER4_LIST = `${environment.apiUrl}/report-service/bcQuarter4`;
export const BC_QUARTER4_YEN_LIST = `${environment.apiUrl}/report-service/bcQuarter4Yen`;

// Report.ReportFilter
export const BC_REPORT_Ver_LIST = `${environment.apiUrl}/report-service/lockVersions`;
export const BC_REPORT_FinalVer_LIST = `${environment.apiUrl}/report-service/finalVersions`;
export const BC_REPORT_Lock_File_Filter = `${environment.apiUrl}/report-service/fileFilter`;
export const BC_REPORT_FINAL_File_Filter = `${environment.apiUrl}/report-service/finalFileFilter`;

// QuarterReport.AutoHideColumn
export const ANNUAL_HIDE_COLUMN = `${environment.apiUrl}/configuration-service/annualQuarter`;
export const QUARTER_HIDE_COLUMN = `${environment.apiUrl}/configuration-service/bcQuarter/name/`;

// Cache
export const CACHE_UPDATE = `${environment.apiUrl}/cache-service/cache-update`;

// Forget Password
export const FORGET_PASSWORD_URL = `${environment.apiUrl}/login/forget-password`;
export const RESET_PASSWORD_URL = `${environment.apiUrl}/reset-password`;

// Financial Data
export const FINANCIAL_DATA_CREATE = `${environment.apiUrl}/dashboard-service/financialDataImport`;
export const FINANCIAL_DATA_LIST = `${environment.apiUrl}/dashboard-service/financialDatas`;
