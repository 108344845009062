import React, { useState } from "react";

import {
  FlexChartLegend,
  FlexPie,
  FlexPieDataLabel,
} from "@grapecity/wijmo.react.chart";
import { FlexChartAnimation } from "@grapecity/wijmo.react.chart.animation";

import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";

import { useAtom } from "jotai";
import { metadataAtom } from "../state/chart-item-atom";
import PropTypes from "prop-types";
import { DataConveterBy } from "../utilities/utilities";

export default function PieChart(props) {
  const { datasources, width, height, chartInitalized } = props;
  const [metadataState] = useAtom(metadataAtom);
  const [palette] = useState([
    "rgba(42,159,214,1)",
    "rgba(119,179,0,1)",
    "rgba(153,51,204,1)",
    "rgba(255,136,0,1)",
    "rgba(204,0,0,1)",
    "rgba(0,204,163,1)",
    "rgba(61,109,204,1)",
    "rgba(82,82,82,1)",
    "rgba(0,0,0,1)",
  ]);

  const serverData = DataConveterBy(
    metadataState,
    datasources.configure.category,
    [datasources.configure.size]
  );

  return (
    <div>
      <FlexPie
        initialized={chartInitalized}
        style={{ width: width, height: height }}
        header={datasources.header}
        bindingName={datasources.configure.category}
        binding={datasources.configure.size}
        innerRadius={datasources.settings.innerRadius}
        itemsSource={serverData}
        palette={palette}
        isAnimated={true}
        selectionMode={datasources.settings.selectionMode ? "Point" : "None"}
        selectedItemPosition="Top"
        selectedItemOffset={
          datasources.settings.selectionMode === true ? 0.2 : 0
        }
        tooltipContent={"{name} {value}"}
      >
        <FlexChartLegend position={datasources.settings.legendPosition} />
        <FlexPieDataLabel
          position={datasources.settings.dataLabelPosition}
          content={"{name} {value}"}
        />
        <FlexChartAnimation animationMode="Point" />
      </FlexPie>
    </div>
  );
}

PieChart.propTypes = {
  datasources: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.number,
  chartInitalized: PropTypes.func,
};
