import React from "react";
import * as chart from "@grapecity/wijmo.chart";
import * as wjGrid from "@grapecity/wijmo.grid";
import * as gridXlsx from "@grapecity/wijmo.grid.xlsx";
import * as pdf from "@grapecity/wijmo.pdf";
import {
  ActionIcon,
  Card,
  Group,
  Menu,
  Text,
  ThemeIcon,
  rem,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import {
  IconCsv,
  IconDots,
  IconDownload,
  IconEdit,
  IconFileSpreadsheet,
  IconPdf,
  IconPhoto,
  IconPng,
  IconTrash,
} from "@tabler/icons-react";
import { useState } from "react";
import WidgtesMock from "../../mocks/widgets-mock.json";
import {
  ExportChartImage,
  ExportChartPDF,
  ExportGridCSV,
  ExportGridExcel,
  ExportGridPDF,
  RetriveCSVFormat,
} from "../../utilities/utilities";
import ProgressCard from "../widgets/cards/progress-card";
import AreaChart from "../widgets/charts/area-chart";
import BarChart from "../widgets/charts/bar-chart";
import BarChartLine from "../widgets/charts/bar-chart-line";
import BubbleChart from "../widgets/charts/bubble-chart";
import LineChart from "../widgets/charts/line-chart";
import PieChart from "../widgets/charts/pie-chart";
import ScatterChart from "../widgets/charts/scatter-chart";
import FlexSheet from "../widgets/tables/flex-sheet";
import SimpleTable from "../widgets/tables/simple-table";

import * as gridPdf from "@grapecity/wijmo.grid.pdf";
import { modals } from "@mantine/modals";
import PropTypes from "prop-types";

// Pre-define Dynamic Components
const components = {
  pie_chart: PieChart,
  line_chart: LineChart,
  bar_chart: BarChart,
  bar_chart_line: BarChartLine,
  progress_card: ProgressCard,
  simple_table: SimpleTable,
  area_chart: AreaChart,
  bubble_chart: BubbleChart,
  scatter_chart: ScatterChart,
  flex_sheet: FlexSheet,
};

const icons = {
  PDF: (
    <ThemeIcon color="pink" variant="filled" size={rem(16)}>
      <IconPdf />
    </ThemeIcon>
  ),
  JPEG: (
    <ThemeIcon color="yellow" variant="filled" size={rem(16)}>
      <IconPhoto />
    </ThemeIcon>
  ),
  PNG: (
    <ThemeIcon color="teal" variant="filled" size={rem(16)}>
      <IconPng />
    </ThemeIcon>
  ),
  CSV: (
    <ThemeIcon color="violet" variant="filled" size={rem(16)}>
      <IconCsv />
    </ThemeIcon>
  ),
  XLSX: (
    <ThemeIcon color="teal" variant="filled" size={rem(16)}>
      <IconFileSpreadsheet />
    </ThemeIcon>
  ),
};

function getWidgetType(type) {
  return WidgtesMock.find((widget) => widget.type === type);
}

const WidgetsFrame = (props) => {
  const { index, isEdit, item, activeSection, editWidget, setItemState } =
    props;
  const { ref, width, height } = useElementSize();
  const [flex, setFlex] = useState({});

  // Provider Items

  // Dynamic Compments Create
  const DynamicComponent = components[item.datasources.type];
  // Wiget Mock
  const widgetMock = getWidgetType(item.datasources.type);

  if (index === "") return;
  // Remove Widgets
  const handleRemoveChart = (i) => {
    setItemState((prevState) => ({
      ...prevState,
      sections: prevState.sections.map((section) => {
        if (section.sectionValue === activeSection) {
          return {
            ...section,
            items: section.items.filter((item) => item.i !== i),
          };
        }
        return section;
      }),
    }));
  };

  // Edit Widgets
  const handeEditChart = () => {
    editWidget(item, index);
  };
  // SID 006 --> DELETE DASHBOARD
  const openDeleteModal = (i) =>
    modals.openConfirmModal({
      title: "Delete Widget",
      centered: true,
      children: (
        <Text size="sm">Are you sure you want to delete this widget.</Text>
      ),
      labels: { confirm: "Delete", cancel: "Cancel" },
      confirmProps: { color: "red" },
      onCancel: () => null,
      onConfirm: () => handleRemoveChart(i),
    });
  // Download Widgets
  const handleDownload = (format) => {
    if (item.datasources.type === "progress_card")
      return console.warn(
        "PROGRESS CARD EXPORT CAN NOT BE FULLFILLED RIGHT NOW"
      );
    // Export Charts into image & pdf formats
    if (item.datasources.type !== "simple_table") {
      if (format === "JPEG" || format === "PNG")
        return ExportChartImage(flex, item.datasources.title, format);
      return ExportChartPDF(
        pdf,
        flex,
        chart,
        item.datasources.title,
        item.datasources.header
      );
    } else {
      // Export Grid into csv, excel & pdf formats
      if (format === "CSV") {
        const csv = RetriveCSVFormat(wjGrid, flex);
        return ExportGridCSV(
          csv,
          `${item.datasources.title || "CSV FILE"}.csv`
        );
      } else if (format === "PDF") {
        return ExportGridPDF(gridPdf, flex, item.datasources.title);
      }
      return ExportGridExcel(gridXlsx, item.datasources.title, flex, format);
    }
  };

  function chartInitalized(flex) {
    setFlex(flex);
  }

  return (
    <Card
      withBorder
      shadow="sm"
      radius="md"
      style={{ height: "100%" }}
      ref={ref}
    >
      <Card.Section withBorder inheritPadding p={5}>
        <Group position="apart">
          <Text weight={500}>{item.datasources.title}</Text>
          <Menu withinPortal position="bottom-end" shadow="sm">
            <Menu.Target>
              <ActionIcon disabled={!isEdit}>
                <IconDots size="0.7rem" />
              </ActionIcon>
            </Menu.Target>

            <Menu.Dropdown>
              <Menu.Label>Settings</Menu.Label>

              <Menu.Item
                icon={
                  <ThemeIcon color="yellow" variant="filled" size={rem(18)}>
                    <IconEdit />
                  </ThemeIcon>
                }
                onClick={handeEditChart}
              >
                Edit
              </Menu.Item>
              <Menu
                trigger="hover"
                openDelay={100}
                closeDelay={400}
                position="left-start"
                arrowPosition="center"
              >
                <Menu.Target>
                  <Menu.Item
                    icon={
                      <ThemeIcon color="green" variant="filled" size={rem(18)}>
                        <IconDownload />
                      </ThemeIcon>
                    }
                  >
                    Download
                  </Menu.Item>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Label>Format</Menu.Label>
                  {widgetMock.format.map((format, index) => (
                    <Menu.Item
                      icon={icons[format]}
                      key={index}
                      onClick={() => handleDownload(format)}
                    >
                      {format}
                    </Menu.Item>
                  ))}
                </Menu.Dropdown>
              </Menu>

              <Menu.Item
                icon={
                  <ThemeIcon color="red" variant="filled" size={rem(18)}>
                    <IconTrash />
                  </ThemeIcon>
                }
                onClick={() => openDeleteModal(index)}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Card.Section>

      <Card.Section p={5}>
        <DynamicComponent
          {...item}
          height={height - 25}
          width={width}
          chartInitalized={chartInitalized}
        />
      </Card.Section>
    </Card>
  );
};

export default WidgetsFrame;

WidgetsFrame.propTypes = {
  index: PropTypes.any,
  isEdit: PropTypes.any,
  item: PropTypes.any,
  activeSection: PropTypes.any,
  editWidget: PropTypes.any,
  setItemState: PropTypes.any,
};
