import React, { useState, useEffect } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BiPlusCircle, BiEditAlt } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import "../../assets/css/styles.css";
import "../../assets/css/businessSector.css";
import {
  BUSINESS_SECTOR_LIST_URL,
  BUSINESS_SECTOR_CREATE_URL,
  BUSINESS_SECTOR_UPDATE_URL,
  BUSINESS_SECTOR_DELETE_URL,
  TOKEN,
} from "../../constants/api.path";
import ConfirmModal from "../../modalPopups/confirmModal";
import InfoModal from "../../modalPopups/infoModal";
import SuccessModal from "../../modalPopups/successModal";
import BusinessSectorAddEditModal from "../../modalPopups/businessSectorAddEditmodal";

function BusinessSector() {
  const [title, setTitle] = React.useState("");
  const [showAddEditModal, setAddEditModal] = useState(false);
  const [display, setDisplay] = useState([]);
  const [editing, setEditing] = React.useState(false);
  const [activeId, setActiveId] = useState({
    activeId: "",
  });

  const [sector, setSector] = useState({
    sectorName: "",
    sectorFullName: "",
  });

  const [showConfirmModal, setConfirmModal] = useState(false);
  const [showInfoModal, setInfoModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [resConfirm, setResConfirm] = useState(false);
  const [resultMessage, setResultMessage] = useState();
  const [value, setValue] = useState();
  const [sectorId, setSectorId] = useState();

  var updateMessage = "Are you sure to want to update?";
  var deleteMessage = "Are you sure to want to delete?";

  let header = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${TOKEN}`,
  };

  function getSectorList() {
    fetch(BUSINESS_SECTOR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setDisplay(data.data.sectorList);
      });

    // clear all text
    setSector({
      sectorName: "",
      sectorFullName: "",
    });
    setEditing(false);
    setActiveId("");
  }

  useEffect(() => {
    getSectorList();
  }, []);

  const handleChange = (event) => {
    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newSector = { ...sector };
    newSector[fieldName] = fieldValue;

    setSector(newSector);
  };

  async function handleSubmit(e) {
    e.preventDefault();

    if (sector.sectorName !== "") {
      if (!editing) {
        await fetch(BUSINESS_SECTOR_CREATE_URL, {
          method: "POST",
          headers: header,
          body: JSON.stringify(sector),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.result) {
              getSectorList();
            } else {
              setResultMessage(res.message);
              setInfoModal(true);
            }
          });
      } else {
        let editSector = {
          sectorName: sector.sectorName,
          sectorFullName: sector.sectorFullName,
        };
        setConfirmModal(true);
        setResConfirm(true);
        if (resConfirm) {
          fetch(BUSINESS_SECTOR_UPDATE_URL + `${sector.sectorId}`, {
            method: "PUT",
            headers: header,
            body: JSON.stringify(editSector),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              setConfirmModal(false);
              if (res.result) {
                getSectorList();
              } else {
                setResultMessage(res.message);
                setInfoModal(true);
              }
              setResConfirm(false);
            });
        }
      }
    }
  }

  function editSectorName(sect) {
    setEditing(true);
    setSector({ ...sect });
  }

  function deleteSectorName(delSectorId) {
    if (resConfirm) {
      fetch(BUSINESS_SECTOR_DELETE_URL + `${delSectorId}`, {
        method: "DELETE",
        headers: header,
      })
        .then((response) => {
          return response.json();
        })
        .then((res) => {
          setConfirmModal(false);
          if (res.result) {
            setResultMessage(res.message);
            setSuccessModal(true);
            getSectorList();
          } else {
            setResultMessage(res.message);
            setInfoModal(true);
          }
          setResConfirm(false);
        });
    }
  }

  function catchFunction(e) {
    if (value === "Update") {
      handleSubmit(e);
    } else {
      deleteSectorName(sectorId);
    }
  }

  const getValue = (e) => {
    setValue(e.target.id);
  };

  return (
    <div className="main--body">
      <div className="row justify-content-center">
        <div className="col-md-12 mt-3 justify-content-end d-flex">
          <button
            className="actionIconOutline save bg-blue"
            value="Add"
            id="Add"
            data-tooltip-content="Add"
            onClick={(e) => {
              setAddEditModal(true);
              getValue(e);
              setTitle("Register Business Sector");
              setSector({
                sectorName: "",
                sectorFullName: "",
              });
            }}
          >
            <BiPlusCircle />
            <ReactTooltip place="bottom" anchorId="Add" />
          </button>
        </div>
        <div className="col-md-12 mt-2">
          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th className="no text-center">No.</th>
                <th className="sectorName text-center">Sector Name</th>
                <th className="sectorFullName text-center">Sector Full Name</th>
                <th className="action text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              {display.map((sect, index) => (
                <tr
                  key={index + 1}
                  id={sect.sectorId}
                  className={sect.sectorId === activeId.activeId ? "bg-tr" : ""}
                >
                  <td align="right">{index + 1}</td>
                  <td className="text-center">{sect.sectorName}</td>
                  <td className="text-center">{sect.sectorFullName}</td>
                  <td className="text-center">
                    <button
                      onClick={(e) => {
                        setAddEditModal(true);
                        editSectorName(sect);
                        getValue(e);
                        setTitle("Update Business Sector");
                      }}
                      className="actionIcon edit"
                    >
                      <BiEditAlt />
                    </button>
                    <button
                      className="actionIcon delete"
                      onClick={(e) => {
                        setSectorId(sect.sectorId);
                        setConfirmModal(true);
                        setResConfirm(true);
                        getValue(e);
                      }}
                    >
                      <MdDeleteOutline />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <ConfirmModal
          show={showConfirmModal}
          title={value === "Update" ? updateMessage : deleteMessage}
          onSubmit={(e) => {
            setConfirmModal(false);
            catchFunction(e);
          }}
          onCancel={() => {
            setConfirmModal(false);
            setResConfirm(false);
          }}
          onHide={() => {
            setConfirmModal(false);
            setResConfirm(false);
          }}
        />
        <InfoModal
          show={showInfoModal}
          title={resultMessage}
          onSubmit={() => setInfoModal(false)}
          onHide={() => {
            setInfoModal(false);
            setResConfirm(false);
          }}
        />
        <SuccessModal
          show={showSuccessModal}
          title={resultMessage}
          onSubmit={() => setSuccessModal(false)}
          onHide={() => {
            setSuccessModal(false);
            setResConfirm(false);
          }}
        />
        <BusinessSectorAddEditModal
          show={showAddEditModal}
          title={title}
          onSubmit={() => setAddEditModal(false)}
          onHide={() => {
            setAddEditModal(false);
            setEditing(false);
          }}
          handleSubmit={handleSubmit}
          handleChange={handleChange}
          sector={sector}
          editing={editing}
          getValue={getValue}
          setAddEditModal={setAddEditModal}
        />
      </div>
    </div>
  );
}

export default BusinessSector;
