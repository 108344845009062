import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  MultiSelect,
  Select,
  Tabs,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { IconBinaryTree2, IconSettings } from "@tabler/icons-react";
import React from "react";
import PropTypes from "prop-types";

const BubbleChartEditor = (props) => {
  const { widget, oldDatasource, metacolumn, handleWidgetSettingClose } = props;

  const { attributes_names, measures_names } = metacolumn;

  const form = useForm({
    initialValues: {
      // title: oldDatasource ? oldDatasource.title : "",
      // header: oldDatasource ? oldDatasource.header : "",
      // bindingX: oldDatasource ? oldDatasource.configure.bindingX : "",
      // bindingY: oldDatasource ? oldDatasource.configure.bindingY : "",
      // leftAxisTitle: !!oldDatasource
      //   ? oldDatasource.settings.leftAxisTitle
      //   : "",
      // bottomAxisTitle: !!oldDatasource
      //   ? oldDatasource.settings.bottomAxisTitle
      //   : "",
      //   legendToggle: !!oldDatasource
      //     ? oldDatasource.settings.legendToggle
      //     : false,
      dataLabel: oldDatasource ? oldDatasource.settings.dataLabel : "None",

      title: oldDatasource?.title || "",
      header: oldDatasource?.header || "",
      bindingX: oldDatasource?.configure.bindingX || "",
      bindingY: oldDatasource?.configure.bindingY || "",
      leftAxisTitle: oldDatasource?.settings.leftAxisTitle || "",
      bottomAxisTitle: oldDatasource?.settings.bottomAxisTitle || "",
    },

    // functions will be used to validate values at corresponding key
    validate: {
      title: isNotEmpty("Widget title cannot be empty"),
      header: isNotEmpty("Header cannot be empty"),
      bindingX: isNotEmpty("X-axis cannot be empty"),
      bindingY: isNotEmpty("Y-axis cannot be empty"),
    },
  });

  const handleOnSubmit = (values) => {
    const newDatasource = {
      title: values.title,
      header: values.header,
      type: widget.type,
      configure: {
        bindingX: values.bindingX,
        bindingY: values.bindingY,
      },
      settings: {
        leftAxisTitle: values.leftAxisTitle,
        bottomAxisTitle: values.bottomAxisTitle,
        dataLabel: values.dataLabel,
      },
    };
    form.reset();
    handleWidgetSettingClose(newDatasource);
  };

  return (
    <Box miw={220} mx="auto">
      <form
        onSubmit={form.onSubmit((values) => {
          handleOnSubmit(values);
        })}
      >
        <Grid align="center" gutter="xs">
          <Grid.Col span={4}>
            <Text weight={500} size="sm">
              Widget Title
            </Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              placeholder="Widget Title"
              {...form.getInputProps("title")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Text weight={500} size="sm">
              Chart Title
            </Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              placeholder="Chart Title"
              {...form.getInputProps("header")}
            />
          </Grid.Col>
        </Grid>

        {/* //     <Tabs radius="xs" defaultValue="configure"> */}

        <Tabs radius="xs" defaultValue="configure">
          <Tabs.List>
            <Tabs.Tab
              value="configure"
              icon={<IconBinaryTree2 size="0.8rem" />}
            >
              Configure
            </Tabs.Tab>
            <Tabs.Tab value="settings" icon={<IconSettings size="0.8rem" />}>
              Settings
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel
            value="configure"
            pt="xs"
            h={"calc(70vh - 40px)"}
            style={{ overflow: "hidden" }}
          >
            <Grid align="center" gutter="xs">
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  X-axis
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  searchable
                  placeholder="Choose X-axis"
                  data={attributes_names}
                  {...form.getInputProps("bindingX")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  Y-axis
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <MultiSelect
                  searchable
                  withAsterisk
                  nothingFound="Nothing found"
                  placeholder="Choose Y-axis"
                  data={measures_names}
                  {...form.getInputProps("bindingY")}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>

          <Tabs.Panel value="settings" pt="xs" h={"calc(70vh - 40px)"}>
            <Grid align="center" gutter="xs">
              <Grid.Col span={12}>
                <Title order={6}>Display</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Left Axis Title
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  withAsterisk
                  placeholder="Enter Left Axis Title"
                  {...form.getInputProps("leftAxisTitle")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Bottom Axis Title
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <TextInput
                  withAsterisk
                  placeholder="Enter Bottom Axis Title"
                  {...form.getInputProps("bottomAxisTitle")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Title order={6}>Legend</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Data Label
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  placeholder="Choose Data Label Position"
                  searchable
                  nothingFound="No Options"
                  data={[
                    { label: "None", value: "None" },
                    { label: "Top", value: "Top" },
                  ]}
                  {...form.getInputProps("dataLabel")}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>
        </Tabs>
        <Divider my={5} variant="dotted" />
        <Flex
          mih={"auto"}
          gap="md"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button type="submit" color="indigo">
            {oldDatasource ? "Update" : "Add"}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default BubbleChartEditor;

BubbleChartEditor.propTypes = {
  widget: PropTypes.any,
  metacolumn: PropTypes.any,
  oldDatasource: PropTypes.any,
  handleWidgetSettingClose: PropTypes.any,
};
