import {
  ActionIcon,
  Card,
  Group,
  Menu,
  Text,
  ThemeIcon,
  rem,
} from "@mantine/core";
import { useElementSize } from "@mantine/hooks";
import React from "react";
import PropTypes from "prop-types";

// icons
import { HiOutlineDotsVertical } from "react-icons/hi";
import { FiDownload } from "react-icons/fi";
import { BiEdit } from "react-icons/bi";
import { FaTrash } from "react-icons/fa";
import {
  PieChart,
  LineChart,
  BarChart,
  BarChartLine,
  ProgressCard,
  SimpleTable,
  AreaChart,
  ScatterChart,
  FlexSheetTable,
  BubbleChart,
} from "../widgets/widgets";

import widgetsList from "../assets/widgets.json";
import { useAtom } from "jotai";
import { chartItemsAtom } from "../state/chart-item-atom";

const components = {
  pie_chart: PieChart,
  line_chart: LineChart,
  bar_chart: BarChart,
  bar_chart_line: BarChartLine,
  progress_card: ProgressCard,
  simple_table: SimpleTable,
  area_chart: AreaChart,
  bubble_chart: BubbleChart,
  scatter_chart: ScatterChart,
  flex_sheet_table: FlexSheetTable,
};

const WidgetsFrame = ({
  index,
  item,
  isEdit,
  isEditable,
  editWidget,
  activeSection,
}) => {
  const DynamicComponent = components[item.datasources.type];
  const { ref, height } = useElementSize();

  const [itemState, setItemState] = useAtom(chartItemsAtom);
  // Wiget Mock
  const widgetMock = getWidgetType(item.datasources.type);

  // functions
  const handeEditChart = (widget) => {
    editWidget(widget, item.datasources, index);
  };

  function getWidgetType(type) {
    return widgetsList.find((widget) => widget.type === type);
  }
  // Remove Widgets
  const handleRemoveChart = (i) => {
    setItemState((prevState) => ({
      ...prevState,
      sections: prevState.sections.map((section) => {
        if (section.sectionValue === activeSection) {
          return {
            ...section,
            items: section.items.filter((item) => item.i !== i),
          };
        }
        return section;
      }),
    }));
  };

  React.useEffect(() => {}, [itemState]);

  return (
    <Card
      withBorder
      shadow="sm"
      radius="md"
      style={{ height: "100%" }}
      ref={ref}
    >
      <Card.Section withBorder inheritPadding p={5}>
        <Group position="apart">
          <Text weight={500}>{item.datasources.title}</Text>
          {isEditable && (
            <Menu withinPortal position="bottom-end" shadow="sm">
              <Menu.Target>
                <ActionIcon disabled={isEdit}>
                  <HiOutlineDotsVertical />
                </ActionIcon>
              </Menu.Target>
              {/* card dropdown */}
              <Menu.Dropdown>
                <Menu.Label>Settings</Menu.Label>
                <Menu.Item
                  icon={
                    <ThemeIcon variant="filled" color="yellow" size={rem(18)}>
                      <BiEdit />
                    </ThemeIcon>
                  }
                  onClick={() => handeEditChart(widgetMock)}
                >
                  Edit
                </Menu.Item>
                <Menu
                  trigger="hover"
                  openDelay={100}
                  closeDelay={400}
                  position="left-start"
                  arrowPosition="center"
                >
                  <Menu.Target>
                    <Menu.Item
                      icon={
                        <ThemeIcon
                          variant="filled"
                          color="green"
                          size={rem(18)}
                        >
                          <FiDownload />
                        </ThemeIcon>
                      }
                    >
                      Download
                    </Menu.Item>
                  </Menu.Target>
                  <Menu.Dropdown>
                    <Menu.Label>Format</Menu.Label>
                  </Menu.Dropdown>
                </Menu>
                <Menu.Item
                  icon={
                    <ThemeIcon variant="filled" color="red" size={rem(18)}>
                      <FaTrash />
                    </ThemeIcon>
                  }
                  onClick={() => handleRemoveChart(index)}
                >
                  Delete
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          )}
        </Group>
      </Card.Section>
      <Card.Section p={5}>
        <DynamicComponent
          {...item}
          height={height - 25}
          width="100%"
          //   chartInitialized={chartInitialized}
        />
      </Card.Section>
    </Card>
  );
};

export default WidgetsFrame;

WidgetsFrame.propTypes = {
  index: PropTypes.any,
  item: PropTypes.object,
  isEdit: PropTypes.bool,
  isEditable: PropTypes.bool,
  editWidget: PropTypes.func,
  activeSection: PropTypes.string,
};
