import React from "react";
import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";
import PropTypes from "prop-types";

export default class SGAChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sga: props.sga,
      palette: ["#ff5349", "#32CD32", "#808080"],
      stacked: props.stacked,
    };
  }
  render() {
    return (
      <div
        className="container-fluid"
        style={{
          marginleft: "auto",
          marginRight: "auto",
          width: "fit-content",
        }}
      >
        <wjChart.FlexChart
          header="SGA"
          bindingX="name"
          itemsSource={this.state.sga}
          chartType="Column"
          stacking={this.state.stacked && "Stacked"}
          selectionMode="Series"
          legendToggle={true}
          style={{ maxWidth: "300px" }}
        >
          <wjChart.FlexChartLegend position="Bottom"></wjChart.FlexChartLegend>
          <wjChart.FlexChartSeries name="Head Office" binding="headOffice" />
          <wjChart.FlexChartSeries name="Branch" binding="branch" />
          <wjChartAnimate.FlexChartAnimation />
          <wjChart.FlexChartDataLabel content="{y}" position="Bottom" />
        </wjChart.FlexChart>
      </div>
    );
  }
}

SGAChart.propTypes = {
  sga: PropTypes.isRequired,
  stacked: PropTypes.isRequired,
};
