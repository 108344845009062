import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
function SuccessModal(props) {
  return (
    <div>
      <Modal {...props} size="md" onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{props.title}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onSubmit}>OK</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default SuccessModal;

SuccessModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};
