import {
  ActionIcon,
  Box,
  Flex,
  Group,
  NumberInput,
  Select,
  TextInput,
} from "@mantine/core";
import { DateInput, DateTimePicker } from "@mantine/dates";
import { IconPlus, IconSquareRoundedX } from "@tabler/icons-react";
import React from "react";
import {
  FILTEROPERATOR,
  FILTEROPERATORDATE,
  FILTEROPERATORDATETIME,
  FILTEROPERATORNUMBER,
  FILTERTYPE,
  NEAR,
} from "../../../../utilities/constants";
import {
  SELECTITEMCOMPONENT,
  SELECTITEMLIST,
} from "../../../../utilities/utilities";
import PropTypes from "prop-types";

const FilterByList = (props) => {
  const {
    index,
    filter,
    filteredColumnList,
    handleFilterByChange,
    handleRemoveFilter,
  } = props;

  const isString = [
    FILTEROPERATOR[6].value,
    FILTEROPERATOR[7].value,
    FILTEROPERATOR[8].value,
    FILTEROPERATOR[9].value,
    FILTEROPERATOR[10].value,
  ].includes(filter.filterByType);

  let selectedType = filteredColumnList.find(
    (item) => item.value === filter.filterByName
  )?.type;
  let operatorList = [];
  if (selectedType === "number" || selectedType === "decimal") {
    operatorList = FILTEROPERATORNUMBER;
  } else if (selectedType === "date") {
    operatorList = FILTEROPERATORDATE;
  } else if (selectedType === "datetime") {
    operatorList = FILTEROPERATORDATETIME;
  } else {
    operatorList = FILTEROPERATOR;
  }

  return (
    <Group mt={"xs"}>
      <Select
        placeholder="Choose"
        searchable
        itemComponent={SELECTITEMCOMPONENT}
        maxDropdownHeight={210}
        data={filteredColumnList}
        value={filter.filterByName}
        onChange={(value) => handleFilterByChange(index, "filterByName", value)}
        filter={(value, item) =>
          item.label.toLowerCase().includes(value.toLowerCase().trim())
        }
      />
      {filter.filterByName && (
        <React.Fragment>
          <Select
            data={operatorList}
            value={filter.filterByType}
            onChange={(value) =>
              handleFilterByChange(index, "filterByType", value)
            }
          />
          {isString === false && selectedType === "text" && (
            <TextInput
              miw={195}
              placeholder="Enter String"
              value={filter.filterByString}
              onChange={(e) =>
                handleFilterByChange(
                  index,
                  "filterByString",
                  e.currentTarget.value
                )
              }
            />
          )}
          {filter.filterByType === FILTEROPERATOR[6].value &&
            selectedType ===
              "text"(
                <React.Fragment>
                  <TextInput
                    miw={195}
                    placeholder="Enter first word"
                    value={filter.filterByFirstWord}
                    onChange={(e) =>
                      handleFilterByChange(
                        index,
                        "filterByFirstWord",
                        e.currentTarget.value
                      )
                    }
                  />
                  <TextInput
                    miw={195}
                    placeholder="Enter second word"
                    value={filter.filterBySecondWord}
                    onChange={(e) =>
                      handleFilterByChange(
                        index,
                        "filterBySecondWord",
                        e.currentTarget.value
                      )
                    }
                  />
                  <Select
                    miw={60}
                    data={NEAR}
                    value={filter.filterByNear}
                    onChange={(value) =>
                      handleFilterByChange(index, "filterByNear", value)
                    }
                  />
                </React.Fragment>
              )}

          {/* NOT BETWEEN NUMBER */}
          {![
            FILTEROPERATORNUMBER[6].value,
            FILTEROPERATORNUMBER[7].value,
          ].includes(filter.filterByType) &&
            selectedType === "number" && (
              <NumberInput
                miw={208}
                placeholder="Enter Number"
                value={filter.filterByNumber}
                onChange={(value) =>
                  handleFilterByChange(index, "filterByNumber", value)
                }
              />
            )}
          {/* NOT BETWEEN DECIMAL */}

          {![
            FILTEROPERATORNUMBER[6].value,
            FILTEROPERATORNUMBER[7].value,
          ].includes(filter.filterByType) &&
            selectedType === "decimal" && (
              <TextInput
                type="number"
                miw={208}
                placeholder="Enter Number"
                value={filter.filterByNumber}
                onChange={(e) =>
                  handleFilterByChange(
                    index,
                    "filterByNumber",
                    e.currentTarget.value
                  )
                }
              />
            )}

          {/* BETWEEN NUMBER */}
          {[
            FILTEROPERATORNUMBER[6].value,
            FILTEROPERATORNUMBER[7].value,
          ].includes(filter.filterByType) &&
            selectedType === "number" && (
              <React.Fragment>
                <NumberInput
                  miw={208}
                  placeholder="Enter From Number"
                  value={filter.filterByFirstNumber}
                  onChange={(value) =>
                    handleFilterByChange(index, "filterByFirstNumber", value)
                  }
                />
                <NumberInput
                  miw={208}
                  placeholder="Enter To Number"
                  value={filter.filterBySecondNumber}
                  onChange={(value) =>
                    handleFilterByChange(index, "filterBySecondNumber", value)
                  }
                />
              </React.Fragment>
            )}
          {/* BETWEEN DECIMAL */}

          {[
            FILTEROPERATORNUMBER[6].value,
            FILTEROPERATORNUMBER[7].value,
          ].includes(filter.filterByType) &&
            selectedType === "decimal" && (
              <React.Fragment>
                <TextInput
                  miw={208}
                  placeholder="Enter From Number"
                  step="0.5"
                  value={filter.filterByFirstNumber}
                  onChange={(e) =>
                    handleFilterByChange(
                      index,
                      "filterByFirstNumber",
                      e.currentTarget.value
                    )
                  }
                />
                <TextInput
                  miw={208}
                  placeholder="Enter To Number"
                  step="0.5"
                  value={filter.filterBySecondNumber}
                  onChange={(e) =>
                    handleFilterByChange(
                      index,
                      "filterBySecondNumber",
                      e.currentTarget.value
                    )
                  }
                />
              </React.Fragment>
            )}

          {/* NOT BETWEEN DATE */}
          {[
            FILTEROPERATORDATE[0].value,
            FILTEROPERATORDATE[1].value,
            FILTEROPERATORDATE[2].value,
            FILTEROPERATORDATE[3].value,
            FILTEROPERATORDATE[4].value,
            FILTEROPERATORDATE[5].value,
          ].includes(filter.filterByType) && (
            <DateInput
              valueFormat="YYYY-MM-DD"
              placeholder="Enter Date"
              miw={210}
              mx="auto"
              value={filter.filterByDate}
              onChange={(value) =>
                handleFilterByChange(index, "filterByDate", value)
              }
            />
          )}

          {/* BETWEEN DATE */}
          {[FILTEROPERATORDATE[6].value, FILTEROPERATORDATE[7].value].includes(
            filter.filterByType
          ) && (
            <React.Fragment>
              <DateInput
                miw={210}
                mx="auto"
                valueFormat="YYYY-MM-DD"
                placeholder="Enter From Date"
                value={filter.filterByFirstDate}
                onChange={(value) =>
                  handleFilterByChange(index, "filterByFirstDate", value)
                }
              />

              <DateInput
                miw={210}
                mx="auto"
                valueFormat="YYYY-MM-DD"
                placeholder="Enter To Date"
                value={filter.filterBySecondDate}
                onChange={(value) =>
                  handleFilterByChange(index, "filterBySecondDate", value)
                }
              />
            </React.Fragment>
          )}

          {/* NOT BETWEEN DATETIME */}
          {[
            FILTEROPERATORDATETIME[0].value,
            FILTEROPERATORDATETIME[1].value,
            FILTEROPERATORDATETIME[2].value,
            FILTEROPERATORDATETIME[3].value,
            FILTEROPERATORDATETIME[4].value,
            FILTEROPERATORDATETIME[5].value,
          ].includes(filter.filterByType) && (
            <DateTimePicker
              miw={210}
              mx="auto"
              placeholder="Enter Date Time"
              valueFormat="YYYY-MM-DD HH:mm:ss"
              value={filter.filterByDateTime}
              onChange={(value) =>
                handleFilterByChange(index, "filterByDateTime", value)
              }
            />
          )}

          {/* BETWEEN DATETIME */}
          {[
            FILTEROPERATORDATETIME[6].value,
            FILTEROPERATORDATETIME[7].value,
          ].includes(filter.filterByType) && (
            <React.Fragment>
              <DateTimePicker
                miw={210}
                mx="auto"
                valueFormat="YYYY-MM-DD HH:mm:ss"
                placeholder="Enter From Date"
                value={filter.filterByFirstDateTime}
                onChange={(value) =>
                  handleFilterByChange(index, "filterByFirstDateTime", value)
                }
              />

              <DateTimePicker
                valueFormat="YYYY-MM-DD HH:mm:ss"
                placeholder="Enter To Date"
                miw={210}
                mx="auto"
                value={filter.filterBySecondDateTime}
                onChange={(value) =>
                  handleFilterByChange(index, "filterBySecondDateTime", value)
                }
              />
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      <ActionIcon
        variant="filled"
        color="red"
        onClick={() => handleRemoveFilter(index, "filters", index)}
      >
        <IconSquareRoundedX size="1rem" />
      </ActionIcon>
    </Group>
  );
};

const FilterBy = (props) => {
  const {
    filters,
    tableName,
    tableList,
    handleFiltersChange,
    handleFilterByChange,
    handleRemoveFilter,
    handleAddFilter,
  } = props;

  let filteredColumnList = [];

  if (tableName) {
    filteredColumnList = SELECTITEMLIST(tableList, tableName);
  }

  return (
    <Flex
      mih={50}
      p={5}
      bg="#eee"
      justify="flex-start"
      align="flex-start"
      direction="column"
      wrap="wrap"
    >
      <Group>
        {filters.filterList.length > 0 && (
          <Select
            label="Filter by column value - optional"
            data={FILTERTYPE}
            value={filters.filterType}
            onChange={(value) => handleFiltersChange("filterType", value)}
          />
        )}
        <ActionIcon
          variant="filled"
          color="indigo"
          onClick={handleAddFilter}
          sx={{ marginTop: filters.filterList.length > 0 ? "25px" : 0 }}
        >
          <IconPlus size="1rem" />
        </ActionIcon>
      </Group>
      <Box>
        {filters?.filterList?.map((filter, i) => (
          <FilterByList
            key={i}
            filter={filter}
            index={i}
            tableName={tableName}
            filteredColumnList={filteredColumnList}
            handleRemoveFilter={handleRemoveFilter}
            handleFilterByChange={handleFilterByChange}
          />
        ))}
      </Box>
    </Flex>
  );
};

export default FilterBy;

FilterByList.propTypes = {
  index: PropTypes.any,
  filter: PropTypes.any,
  filteredColumnList: PropTypes.any,
  handleFilterByChange: PropTypes.any,
  handleRemoveFilter: PropTypes.any,
};

FilterBy.propTypes = {
  filters: PropTypes.any,
  tableName: PropTypes.any,
  tableList: PropTypes.any,
  handleFiltersChange: PropTypes.any,
  handleFilterByChange: PropTypes.any,
  handleRemoveFilter: PropTypes.any,
  handleAddFilter: PropTypes.any,
};
