// Mantine Icons
import {
  IconDeviceFloppy,
  IconLayoutCollage,
  IconTableOptions,
} from "@tabler/icons-react";

// Mantine core
import {
  ActionIcon,
  Container,
  Divider,
  Drawer,
  Flex,
  Tabs,
  ThemeIcon,
  Title,
  Tooltip,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

// React
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
// Mantine

// Css
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
//

// Core

// Router Dom
import { useLocation } from "react-router-dom";

// Axios
import axios from "axios";
import {
  GET_DASHBOARDLIST,
  SAVE_DASHBOARD,
  SCHEMA_API,
} from "../../../utilities/constants";

// Components
import { notifications } from "@mantine/notifications";
import { IconEdit } from "@tabler/icons-react";
import { Responsive, WidthProvider } from "react-grid-layout";
import LoadingComponents from "../../common/loading";
import TabEditator from "../../common/tab-editor";
import WidgetsFrame from "../../common/widget-frame";
import SettingsMenu from "../../common/settings-menu";
import { TOKEN } from "../../../../../constants/api.path";

const ResponsiveGridLayout = WidthProvider(Responsive);

const DashboardPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [itemsState, setItemState] = useState({});
  const [dashboard, setDashboard] = useState({});
  const [controlsState, setControlsState] = useState({
    isSearchQuery: false,
  });
  const [schemaList, setSchemaList] = useState([]);
  const [loading, loadingHandlers] = useDisclosure(true);
  const [isEditorDrawer, setIsEditorDrawer] = useState(false);
  // const [tempLayouts, setTempLayouts] = useState([]);

  // SID005 --> GET DASHBOARD BY ID
  const fetchApis = async () => {
    try {
      loadingHandlers.open();
      const [schemaResponse] = await Promise.all([
        axios.get(SCHEMA_API, {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${TOKEN}`,
          },
        }),
      ]);
      const dashboard = location.state.dashboard;
      setDashboard(dashboard);
      setItemState(JSON.parse(JSON.parse(dashboard.dashboard_layout).json));

      setSchemaList(schemaResponse.data);
      loadingHandlers.close();
    } catch (error) {
      notifications.show({
        title: `Error occured! Error Code: ${error.status}`,
        message: error.message || "Pinned Dashboard is not saved!",
        autoClose: 2000,
        color: "red",
        withCloseButton: false,
      });
      loadingHandlers.close();
    }
  };

  useEffect(() => {
    fetchApis();
  }, []);

  const onBreakpointChange = (breakpoint, cols) => {
    setItemState((prev) => ({
      ...prev,
      breakpoint: breakpoint,
      cols: cols,
    }));
  };

  // const onLayoutChange = (layout, layouts, section) => {
  //   setTempLayouts(layouts);
  // };

  const handleDashboardName = (newDashboardName) => {
    setDashboard({ ...dashboard, dashboard_name: newDashboardName });
    setIsEditorDrawer(false);
  };

  const handleEditWidget = async (item) => {
    const { data: data } = await axios.get(GET_DASHBOARDLIST, {
      headers: { Authorization: `Bearer ${TOKEN}` },
    });
    const query = {
      query_Id: null,
      query_data_config: JSON.stringify({
        query: item.query,
        sortingBy: item?.sortingBy,
        allowSorting: "None",
        datasources: item.datasources,
      }),
      query_form: item.queryForm,
    };

    const dashboardList = data.data.dashboardList.map((dashboard) => ({
      dashboard_Id: dashboard.dashboard_Id,
      dashboard_name: dashboard.dashboard_name,
      dashboard_layout: JSON.parse(dashboard.dashboard_layout),
      created_by: dashboard.created_by,
      created_at: dashboard.created_at,
      uploaded_by: dashboard.uploaded_by,
      uploaded_at: dashboard.uploaded_at,
    }));

    const navigateState = {
      query: query,
      transition: "after",
      schema: schemaList,
      dashboardList: dashboardList,
    };

    navigate("/dashboard/query/query-dashboard", {
      state: navigateState,
    });
  };

  // SID008 --> SAVE QUERY
  const handleSaveLayout = () => {
    setControlsState((prev) => ({
      ...prev,
      isSearchQuery: !prev.isSearchQuery,
    }));
    if (!controlsState.isSearchQuery) return null;

    // setDashboardList(
    //   dashboardList.map((list) => {
    //     if (list.dashboard_Id === dashboard.dashboard_Id) {
    //       return dashboard;
    //     }
    //     return list;
    //   })
    // );
    // console.log(dashboardList);
    //localStorage.setItem("dashboardList", JSON.stringify(dashboardList));
    // localStorage.setItem("dashboardLayout", JSON.stringify(itemsState));
    // localStorage.setItem("dashboard", JSON.stringify(dashboard));
    axios
      .post(
        SAVE_DASHBOARD,
        {
          dashboard_Id: dashboard.id,
          dashboard_layout: JSON.stringify(itemsState),
          dashboard_name: dashboard.dashboard_name,
        },
        {
          headers: { Authorization: `Bearer ${TOKEN}` },
        }
      )
      .then((res) => {
        if (res.data.result)
          notifications.show({
            title: "Success",
            message: "Dashboard Saved Successfully!",
            autoClose: 2000,
            color: "teal",
            withCloseButton: false,
          });
      });
  };

  const handleMenu = (path) => {
    navigate(path);
  };

  return (
    <div style={{ padding: 7 }}>
      {loading ? (
        <LoadingComponents />
      ) : (
        <React.Fragment>
          <Flex
            mih={30}
            gap="xs"
            justify="space-between"
            align="center"
            direction="row"
            wrap="wrap"
          >
            <Flex
              mih={"auto"}
              gap="xs"
              justify="flex-start"
              align="center"
              direction="row"
              wrap="wrap"
            >
              <SettingsMenu handleMenu={handleMenu}>
                <ThemeIcon
                  size="sm"
                  color="indigo"
                  style={{ cursor: "pointer" }}
                >
                  <IconLayoutCollage size="1rem" />
                </ThemeIcon>
              </SettingsMenu>
              <Title order={5} c="dimmed">
                {dashboard?.dashboard_name}
              </Title>
            </Flex>

            <Flex
              mih={"auto"}
              gap={5}
              justify="flex-end"
              align="center"
              direction="row"
              wrap="wrap"
            >
              {controlsState.isSearchQuery && (
                <Tooltip
                  label="Setting Tab"
                  position="bottom"
                  color="teal"
                  withArrow
                  arrowPosition="center"
                >
                  <ActionIcon
                    variant={"outline"}
                    onClick={() => {
                      setIsEditorDrawer((prev) => !prev);
                    }}
                    color="cyan"
                  >
                    <IconTableOptions size="1rem" />
                  </ActionIcon>
                </Tooltip>
              )}
              <Tooltip
                withArrow
                position="bottom"
                color="teal"
                label={controlsState.isSearchQuery ? "Save" : "Edit"}
              >
                <ActionIcon
                  variant="filled"
                  color="teal"
                  onClick={handleSaveLayout}
                >
                  {controlsState.isSearchQuery ? (
                    <IconDeviceFloppy size="1rem" />
                  ) : (
                    <IconEdit size={"1rem"} />
                  )}
                </ActionIcon>
              </Tooltip>
            </Flex>
          </Flex>

          <Drawer.Root
            opened={isEditorDrawer}
            onClose={() => setIsEditorDrawer(false)}
            position="right"
            size={"23%"}
          >
            <Drawer.Overlay />
            <Drawer.Content>
              <Drawer.Header style={{ padding: "7px" }}>
                <Title order={5} color="indigo">
                  Edit Tab
                </Title>
                <Drawer.CloseButton />
              </Drawer.Header>
              <Drawer.Body style={{ padding: "0 7px" }}>
                <Divider />
                <TabEditator
                  itemsState={itemsState}
                  setItemState={setItemState}
                  dashboard={dashboard}
                  onChangeDashboardName={handleDashboardName}
                />
              </Drawer.Body>
            </Drawer.Content>
          </Drawer.Root>

          <Container size={"100%"} p={5}>
            <Tabs
              value={itemsState.activeSection}
              variant="outline"
              onTabChange={(value) =>
                setItemState((prev) => ({ ...prev, activeSection: value }))
              }
            >
              <Tabs.List>
                {itemsState.sections?.map((section) => (
                  <Tabs.Tab
                    value={section.sectionValue}
                    key={`tabs-${section.sectionValue}`}
                  >
                    {section.sectionName}
                  </Tabs.Tab>
                ))}
              </Tabs.List>

              {itemsState.sections?.map((section) => (
                <Tabs.Panel
                  value={section.sectionValue}
                  key={`tab-panel-${section.sectionValue}`}
                >
                  <ResponsiveGridLayout
                    className="layout"
                    style={{
                      marginTop: 0,
                      maxHeight: "85vh",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                    breakpoints={{
                      lg: 1200,
                      md: 996,
                      sm: 768,
                      xs: 480,
                      xxs: 0,
                    }}
                    cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
                    layouts={section.layouts}
                    rowHeight={50}
                    isResizable={controlsState.isSearchQuery}
                    isDraggable={controlsState.isSearchQuery}
                    // onLayoutChange={(layout, layouts) =>
                    //   onLayoutChange(layout, layouts, section.sectionValue)
                    // }
                    onBreakpointChange={onBreakpointChange}
                  >
                    {section.items?.map(({ i, x, y, w, h }, index) => (
                      <div key={i} data-grid={{ x, y, w, h }}>
                        <WidgetsFrame
                          setItemState={setItemState}
                          isEdit={true}
                          key={`Widget-${i}`}
                          index={i}
                          activeSection={section.sectionValue}
                          item={section.items[index]}
                          editWidget={handleEditWidget}
                        />
                      </div>
                    ))}
                  </ResponsiveGridLayout>
                </Tabs.Panel>
              ))}
            </Tabs>
          </Container>
        </React.Fragment>
      )}
    </div>
  );
};

export default DashboardPage;
