import * as React from "react";
import { useEffect, useState } from "react";

import { SortDescription } from "@grapecity/wijmo";
import { FlexGrid, FlexGridColumn } from "@grapecity/wijmo.react.grid";

import "@grapecity/wijmo.styles/wijmo.css";
import { useDisclosure } from "@mantine/hooks";
import "bootstrap/dist/css/bootstrap.css";
import DataService from "../../../service/data-service";
import { DataConveter } from "../../../utilities/utilities";
import LoadingComponents from "../../common/loading";
import ErrorWidget from "../error/error-widget";
import { GET_DATA_BY_QUERY } from "../../../utilities/constants";
import PropTypes from "prop-types";

const SimpleTable = (props) => {
  const {
    // queryForm,
    query,
    tableName,
    datasources,
    width,
    height,
    chartInitalized,
  } = props;
  const [serverData, setServerData] = useState([]);
  const [datasource, setDatasource] = useState([]);
  const [isError, setIsError] = useState(false);
  // const [flex, setFlex] = useState(null);
  // Loading State handlers
  const [isLoading, loadingHandlers] = useDisclosure(true);

  const memoizedDatasources = React.useMemo(() => datasources, [datasources]);

  useEffect(() => {
    const path = GET_DATA_BY_QUERY;
    const requestObject = {
      query: query,
      table_name: tableName,
    };
    // SID002 Get Data by queries
    DataService(path, requestObject).then(({ data, error }) => {
      if (error) {
        setIsError(true);
        loadingHandlers.close();
      } else {
        setIsError(false);
        // fixed array index
        setServerData(DataConveter(data.metadata[0]));
        setDatasource(datasources);
        loadingHandlers.close();
      }
    });
    return () => {
      setServerData([]);
      setDatasource([]);
      loadingHandlers.open();
    };
  }, [memoizedDatasources]);

  function flexInitialized(flexgrid) {
    if (datasources.settings.sortingBy) {
      let sd = new SortDescription(datasources.settings.sortingBy, true);
      flexgrid.collectionView.sortDescriptions.push(sd);
    }
    // setFlex(flexgrid);
    chartInitalized(flexgrid);
  }

  if (isLoading)
    return (
      <div className="container-fluid">
        <LoadingComponents />
      </div>
    );

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      {isError ? (
        <ErrorWidget />
      ) : (
        <FlexGrid
          initialized={flexInitialized}
          stickyHeaders={true}
          itemsSource={serverData}
          isReadOnly={true}
          allowSorting={datasource.settings.allowSorting}
          frozenRows={datasource.settings.frozenRows}
          frozenColumns={datasource.settings.frozenColumns}
          style={{ width: width, height: height }}
        >
          {datasource.configure.columns.map((item) => (
            <FlexGridColumn
              header={item}
              binding={item}
              key={`table-row-${item}`}
            />
          ))}
        </FlexGrid>
      )}
    </div>
  );
};

export default SimpleTable;

SimpleTable.propTypes = {
  i: PropTypes.any,
  datasources: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  chartInitalized: PropTypes.any,
  query: PropTypes.any,
  tableName: PropTypes.any,
  queryForm: PropTypes.any,
};
