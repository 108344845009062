import { Card, Progress, Stack, Text } from "@mantine/core";
import React, { useEffect, useState } from "react";

import {
  CalculateProgress,
  ConvertUnits,
  DataConveter,
  Financial,
} from "../../../utilities/utilities";
import PropTypes from "prop-types";
import axios from "axios";
import { useMemo } from "react";
import { useDisclosure } from "@mantine/hooks";
import LoadingComponents from "../../common/loading";
import ErrorWidget from "../error/error-widget";

const ProgressCard = (props) => {
  const { i, datasources, height } = props;
  const [metadataState] = useState({});
  // const [datasource, setDatasource] = useState([]);
  const [isLoading, loadingHandlers] = useDisclosure(true);
  const [isError, setIsError] = useState(false);
  const [serverData, setServerData] = useState({
    plan: 0,
    actual: 0,
    total: 0,
  });
  const [categoryUnit, setCategoryUnit] = useState("");
  const fetchData = async (path) => {
    try {
      const response = await axios.get(`json/${path}.json`);
      const dataConverted = DataConveter(response.data.metadata);
      const filteredData = dataConverted.filter(
        (item) =>
          item[datasources.configure.searchable] ===
          datasources.configure.category
      );
      setServerData({
        plan: ConvertUnits(
          filteredData[0][datasources.configure.plan],
          datasources.settings.unit
        ),
        actual: ConvertUnits(
          filteredData[0][datasources.configure.actual],
          datasources.settings.unit
        ),
        total:
          (filteredData[0][datasources.configure.actual] /
            filteredData[0][datasources.configure.plan]) *
          100,
      });
      if (datasources.settings.unitCategory === "currency") {
        setCategoryUnit(datasources.settings.currency);
      } else if (datasources.settings.unitCategory === "number") {
        setCategoryUnit(datasources.settings.unit);
      }
      loadingHandlers.close();
    } catch (error) {
      setIsError(true);
      loadingHandlers.close();
    }
  };
  const memoizedDatasources = useMemo(() => datasources, [datasources]);
  useEffect(() => {
    fetchData(i);
  }, [datasources, metadataState, memoizedDatasources]);

  let planFormatted = Financial(
    serverData.plan,
    datasources.settings.decimal,
    categoryUnit
  );

  let actualFormatted = Financial(
    serverData.actual,
    datasources.settings.decimal,
    categoryUnit
  );

  let totalFormatted = Financial(
    serverData.total,
    datasources.settings.decimal,
    ""
  );

  let totalProgressBar = CalculateProgress(totalFormatted);

  if (isLoading)
    return (
      <div className="container-fluid">
        <LoadingComponents />
      </div>
    );

  if (isError) {
    return <ErrorWidget />;
  }

  return (
    <Card
      withBorder
      radius="md"
      padding="xs"
      sx={{
        height: height,
        backgroundColor: datasources.settings.bgColor,
      }}
    >
      <Stack h={"auto"} spacing={5}>
        <Text fz="md" fw={500} c={datasources.settings.textColor}>
          Plan: {planFormatted}
        </Text>
        <Text fz="md" fw={500} c={datasources.settings.textColor}>
          Actual: {actualFormatted}
        </Text>
        <Progress
          sections={[
            {
              value: totalProgressBar,
              color: datasources.settings.progressColor,
              label: `${totalFormatted}%`,
              tooltip: totalFormatted,
            },
          ]}
          size="lg"
          radius="lg"
        />
      </Stack>
    </Card>
  );
};

export default ProgressCard;

ProgressCard.propTypes = {
  i: PropTypes.any,
  datasources: PropTypes.any,
  height: PropTypes.any,
};
