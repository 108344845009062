import React from "react";
import { Navigate, Outlet } from "react-router-dom";

function ProtectedRoute() {
  const isLoggedIn = localStorage.getItem("isLoggedIn")
    ? localStorage.getItem("isLoggedIn")
    : sessionStorage.getItem("isLoggedIn");

  if (isLoggedIn === false || isLoggedIn === null) {
    return <Navigate to="/login" />;
  } else {
    return <Outlet />;
  }
}

export default ProtectedRoute;
