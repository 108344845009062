export const UNITCATEGORY = [
  { label: "Number", value: "number" },
  { label: "Currency", value: "currency" },
];

export const UNIT = [
  { label: "Auto", value: " " },
  { label: "Thousand (K)", value: "K" },
  { label: "Million (M)", value: "M" },
  { label: "Billion (B)", value: "B" },
  { label: "Trillion (T)", value: "T" },
];

export const CURRENCY = [
  { label: "USD", value: "$" },
  { label: "YEN", value: "¥" },
  { label: "MMK", value: "MMK" },
];
