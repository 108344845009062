import * as wjcCore from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";
import * as React from "react";
import "../../assets/css/dashboard.css";
import dashboardSheet from "./dashboardHideCol.json";
import { BiSearchAlt2 } from "react-icons/bi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import moment from "moment/moment";

import { Spinner } from "reactstrap";
import {
  DASHBOARD_DATA,
  FISCAL_YEAR_LIST_URL,
  TOKEN,
} from "../../constants/api.path";
import { hideRowDefault } from "./budgetStatusHideRow";
import { changeLabelPosition } from "./dashboard-charts/changeLablePosition";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

class BudgetStatusSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flex: null,
      isFrozen: false,
      isLoading: true,
      hideRows: [],
      data: [],
      active: "",
      sheetName: "",
      compName: "",
      currentYear: new Date().getFullYear(),
      budgetStatus: [],
      summaryPalette: ["#ff9c96", "#32CD32", "yellow"],
      sectorList: [],
      fiscalList: [],
      selectedArray: { salesPlanMonth: new Date().getMonth() + 1 },
      monthList: [],
    };
  }

  componentDidMount() {
    this.setState({
      data: dashboardSheet.budgetStatus,
      sheetName: "Budget Status",
    });

    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: { Authorization: `Bearer ${TOKEN}` },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState(
          {
            selectedArray: {
              salesPlanYear: result.data.currentYearId,
              ...this.state.selectedArray,
            },
            fiscalList: result.data.fiscalYearList,
          },
          () => {
            this.getBudgetData();
            this.salesPlanMonthUpdate(this.state.selectedArray.salesPlanYear);
          }
        );
      });
  }
  getBudgetData() {
    fetch(
      DASHBOARD_DATA +
        "/salesPlanYear/" +
        this.state.selectedArray.salesPlanYear +
        "/salesPlanMonth/" +
        this.state.selectedArray.salesPlanMonth,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    )
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ isLoading: false });

        if (result.result) {
          this.setState({
            compName: result.data.dashboardTotalData[0].companyName,
            sectorList: result.data.dashboardSectorData,
          });
          this.bcAnnualDataBinding(
            result.data.dashboardTotalData,
            result.data.dashboardSectorData
          );
          this.seperateSecotorData(result.data.dashboardSectorData);
        }
      });
    this.mergeSectorCell();
  }

  componentDidUpdate() { }

  seperateSecotorData(datas) {
    let budgetstatus = [
      { id: 1, name: "受注​", bottom: 0, stretch: 0, total: 0 },
      { id: 2, name: "売上​", bottom: 0, stretch: 0, total: 0 },
      { id: 3, name: "売上総利益​", bottom: 0, stretch: 0, total: 0 },
      { id: 4, name: "販管費​", bottom: 0, stretch: 0, total: 0 },
      { id: 5, name: "営業利益​", bottom: 0, stretch: 0, total: 0 },
    ];
    let keysList = ["orderReceived", "sales", "gp", "sga", "op"];
    for (let i = 0; i < datas.length; i++) {
      for (let j = 0; j < datas[i].sectorData.length; j++) {
        let keys = Object.keys(datas[i].sectorData[j]);
        for (let k = 0; k < keysList.length; k++) {
          let bottom = budgetstatus[k].bottom,
            stretch = budgetstatus[k].stretch,
            total = bottom + stretch;
          if (keysList[k] === keys[0]) {
            bottom += datas[i].sectorData[j][keys[0]].bottom;
            stretch += datas[i].sectorData[j][keys[0]].stretch;
            total = bottom + stretch;
            budgetstatus[k] = {
              ...budgetstatus[k],
              bottom: Math.round(bottom),
              stretch: Math.round(stretch),
              total: Math.round(total),
            };
          } else {
            continue;
          }
        }
      }
    }
    this.setState({ budgetStatusData: budgetstatus });
  }

  mergeSectorCell() {
    let contentStartRow = 9;
    for (let row = 0; row < this.state.sectorList.length; row++) {
      this.setBCContent(this.state.flex, contentStartRow);
      contentStartRow += 7;
    }
  }

  bcAnnualDataBinding(bcAnnualList, sectorList) {
    let flex = this.state.flex;
    flex.selectedSheetIndex = 0;

    flex.setCellData(0, 0, "Budget Status Figure over Planned Budget");
    for (let r = 0; r < bcAnnualList[0].totalData.length; r++) {
      flex.setCellData(r + 2, 0, "Total of " + bcAnnualList[0].companyName);
      let key = Object.keys(bcAnnualList[0].totalData[r]);
      if (r === 4) {
        flex.setCellData(
          r + 2,
          3,
          (bcAnnualList[0].totalData[r][key[0]].bottom * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r + 2,
          4,
          (bcAnnualList[0].totalData[r][key[0]].stretch * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r + 2,
          5,
          (bcAnnualList[0].totalData[r][key[0]].total * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r + 2,
          6,
          (bcAnnualList[0].totalData[r][key[0]].budget * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r + 2,
          7,
          (bcAnnualList[0].totalData[r][key[0]].variance * 100).toFixed(1) + "%"
        );
      } else {
        flex.setCellData(
          r + 2,
          3,
          bcAnnualList[0].totalData[r][key[0]].bottom.toFixed(0)
        );
        flex.setCellData(
          r + 2,
          4,
          bcAnnualList[0].totalData[r][key[0]].stretch.toFixed(0)
        );
        flex.setCellData(
          r + 2,
          5,
          bcAnnualList[0].totalData[r][key[0]].total.toFixed(0)
        );
        flex.setCellData(
          r + 2,
          6,
          bcAnnualList[0].totalData[r][key[0]].budget.toFixed(0)
        );
        flex.setCellData(
          r + 2,
          7,
          bcAnnualList[0].totalData[r][key[0]].variance.toFixed(0)
        );
      }
    }

    let contentStartRow = 9;
    for (let row = 0; row < sectorList.length; row++) {
      this.setBCContent(flex, contentStartRow);
      contentStartRow += 7;
    }
    let b = 9;

    for (let r = 0; r < sectorList.length; r++) {
      for (let row = 0; row < sectorList[r].sectorData.length; row++) {
        let key = Object.keys(sectorList[0].sectorData[row]);
        flex.setCellData(b, 0, sectorList[r].sectorName);
        if (row === 4) {
          flex.setCellData(
            b,
            3,
            (sectorList[r].sectorData[row][key[0]].bottom * 100).toFixed(1) +
              "%"
          );
          flex.setCellData(
            b,
            4,
            (sectorList[r].sectorData[row][key[0]].stretch * 100).toFixed(1) +
              "%"
          );
          flex.setCellData(
            b,
            5,
            (sectorList[r].sectorData[row][key[0]].total * 100).toFixed(1) + "%"
          );
          flex.setCellData(
            b,
            6,
            (sectorList[r].sectorData[row][key[0]].budget * 100).toFixed(1) +
              "%"
          );
          flex.setCellData(
            b,
            7,
            (sectorList[r].sectorData[row][key[0]].variance * 100).toFixed(1) +
              "%"
          );
        } else {
          flex.setCellData(
            b,
            3,
            sectorList[r].sectorData[row][key[0]].bottom.toFixed(0)
          );
          flex.setCellData(
            b,
            4,
            sectorList[r].sectorData[row][key[0]].stretch.toFixed(0)
          );
          flex.setCellData(
            b,
            5,
            sectorList[r].sectorData[row][key[0]].total.toFixed(0)
          );
          flex.setCellData(
            b,
            6,
            sectorList[r].sectorData[row][key[0]].budget.toFixed(0)
          );
          flex.setCellData(
            b,
            7,
            sectorList[r].sectorData[row][key[0]].variance
          );
        }
        b++;
      }
    }
    // hide rows by default
    let initialHideRows = hideRowDefault(bcAnnualList, sectorList, flex);
    this.setState({
      hideRows: [...this.state.hideRows, ...initialHideRows],
    });
  }

  salesPlanMonthUpdate(id) {
    let key = 0;
    let data = [];
    let currentID = id ? parseInt(id) : this.state.selectedArray.salesPlanYear;
    this.state.fiscalList.forEach((fiscalList) => {
      if (fiscalList.fiscalYearId === currentID) {
        for (
          let i = new Date(fiscalList.fyStartDate).getMonth();
          i < new Date(fiscalList.fyStartDate).getMonth() + 12;
          i++
        ) {
          data.push({
            id: key + 1,
            name: moment()
              .month(i < 12 ? i : i - 12)
              .format("MMM"),
            value: i + 1 < 13 ? i + 1 : i - 11,
          });
          key++;
        }
        this.setState({ monthList: data });
      }
    });
  }

  handleChange(e) {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;

    const selectedValue = { ...this.state.selectedArray };
    selectedValue[fieldName] = fieldValue;

    this.setState({ selectedArray: selectedValue }, () => {
      this.salesPlanMonthUpdate(this.state.selectedArray.salesPlanYear);
    });
  }

  render() {
    return (
      <div className="dashboard-sheet-container">
        {this.state.isLoading && (
          <div className="loader">
            <Spinner color="white" />
          </div>
        )}

        <div
          className="row justify-content-between"
          style={{ marginLeft: "12px" }}
        >
          <div className="col " style={{ padding: "1.5px" }}>
            <div className="row">
              <div className="col-auto" style={{ padding: "1.5px" }}>
                <select
                  name="salesPlanYear"
                  id="salesPlanYear"
                  data-tooltip-content="Fiscal Year"
                  className="form-select"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={this.state.selectedArray.salesPlanYear}
                >
                  <option key={0} value="" hidden>
                    Fiscal Year
                  </option>
                  {this.state.fiscalList.map((item) => (
                    <option key={item.fiscalYearId} value={item.fiscalYearId}>
                      {item.fiscalYear}
                    </option>
                  ))}
                </select>
                <ReactTooltip place="top" anchorId="salesPlanYear" />
              </div>
              <div className="col-auto" style={{ padding: "1.5px" }}>
                <select
                  name="salesPlanMonth"
                  id="salesPlanMonth"
                  data-tooltip-content="Month"
                  className="form-select"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={this.state.selectedArray.salesPlanMonth}
                >
                  <option value="" hidden>
                    Month
                  </option>
                  {this.state.monthList.map((month) => (
                    <option key={month.id} value={month.value}>
                      {month.name}
                    </option>
                  ))}
                </select>
                <ReactTooltip place="top" anchorId="salesPlanMonth" />
              </div>
              <div className=" col " style={{ padding: "1.5px" }}>
                <button
                  id="search"
                  data-tooltip-content="Search"
                  type="button"
                  style={{ margin: "0", padding: "0px" }}
                  className="actionIconOutline search bg-blue  "
                  onClick={() => {
                    this.setState({ isLoading: true });
                    this.getBudgetData();
                  }}
                >
                  <BiSearchAlt2 />
                  <ReactTooltip place="top" anchorId="search" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{ maxHeight: "calc( 100vh - 75px )", overflow: "scroll" }}
        >
          <div className="col-md-6 ">
            <wjGridSheet.FlexSheet
              initialized={this.initializeFlexSheet.bind(this)}
              itemsSourceChanged={this.initializeFlexSheet.bind(this)}
              isReadOnly={true}
              style={{
                marginLeft: "0px",
                maxWidth: "100%",
              }}
              id="budgetStatusSheet"
            >
              <wjGridSheet.Sheet
                name={this.state.sheetName}
                columnCount={8}
                rowCount={51}
              ></wjGridSheet.Sheet>
            </wjGridSheet.FlexSheet>
          </div>
          <div className={`col-md-6 mt-2 budgetStatusChart `}>
            <wjChart.FlexChart
              header="Current Budget Status"
              bindingX="name"
              itemsSource={this.state.budgetStatusData}
              chartType="Column"
              selectionMode="Series"
              legendToggle={true}
              palette={this.state.summaryPalette}
              rendered={() => {
                this.state.stacked &&
                  changeLabelPosition(this.state.summary, "render");
              }}
              style={{ height: "600px" }}
            >
              <wjChart.FlexChartLegend position="Bottom" />
              <wjChart.FlexChartSeries
                name="改善​"
                binding="stretch"
              ></wjChart.FlexChartSeries>
              <wjChart.FlexChartSeries
                name="ボトム​"
                binding="bottom"
              ></wjChart.FlexChartSeries>
              <wjChart.FlexChartSeries name="見込​" binding="total">
                <wjChart.FlexChartDataLabel content="{y}" position="Top" />
              </wjChart.FlexChartSeries>
              <wjChartAnimate.FlexChartAnimation />
              <wjChart.FlexChartDataLabel
                content={"{y}"}
                position="Bottom"
              ></wjChart.FlexChartDataLabel>
            </wjChart.FlexChart>
          </div>
        </div>
      </div>
    );
  }
  initializeFlexSheet(flex) {
    flex.deferUpdate(() => {
      this._generateBasicOperatorsSheet(flex);
      let colCnt = flex.rowHeaders.columns.length;
      flex.rowHeaders.columns.removeAt(colCnt - 1);

      let rowCnt = flex.columnHeaders.rows.length;
      flex.columnHeaders.rows.removeAt(rowCnt - 1);
    });
  }

  _generateBasicOperatorsSheet(flexSheet) {
    flexSheet.setCellData(0, 0, " ");

    flexSheet.setCellData(0, 3, "年間 (4月ー3月)");
    flexSheet.setCellData(1, 3, "ボトム");
    flexSheet.setCellData(1, 4, "改善");
    flexSheet.setCellData(1, 5, "実績");
    flexSheet.setCellData(1, 6, "予算");
    flexSheet.setCellData(1, 7, "予算差");

    this.setBCContent(flexSheet, 2);

    flexSheet.applyCellsStyle(
      {
        background: "#ffc000",
      },
      [new wjcGrid.CellRange(0, 3, 0, 7)]
    );
    flexSheet.applyCellsStyle({ minWidth: "fit-content" }, [
      new wjcGrid.CellRange(1, 6),
    ]);

    flexSheet.mergeRange(new wjcGrid.CellRange(0, 0, 1, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 3, 0, 7));

    flexSheet.selectedSheetChanged.addHandler(() => {
      if (flexSheet.frozenColumns > 0 || flexSheet.frozenRows > 0) {
        this.setState({
          isFrozen: true,
        });
      } else {
        this.setState({
          isFrozen: false,
        });
      }
    });
    this.setState({
      flex: flexSheet,
    });

    for (let col = 0; col < 8; col++) {
      flexSheet.columns[col].width = "*";
      flexSheet.columns[col].minWidth = 65;
    }
    for (let row = 0; row < 51; row++) {
      flexSheet.rows[row].height = 20;
    }
  }
  setBCContent(flexSheet, r) {
    flexSheet.mergeRange(new wjcGrid.CellRange(r, 0, r + 6, 0));
    flexSheet.mergeRange(new wjcGrid.CellRange(r, 1, r, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 1, 1, r + 1, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 2, 1, r + 2, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 3, 1, r + 3, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 4, 1, r + 4, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 5, 1, r + 5, 2));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 6, 1, r + 6, 2));
    flexSheet.setCellData(r, 1, "受注");
    flexSheet.setCellData(r + 1, 1, "売上");
    flexSheet.setCellData(r + 2, 1, "costs");
    flexSheet.setCellData(r + 3, 1, "売上総利益");
    flexSheet.setCellData(r + 4, 1, "原価率");
    flexSheet.setCellData(r + 5, 1, "販管費");
    flexSheet.setCellData(r + 6, 1, "営業利益");

    flexSheet.applyCellsStyle(
      {
        fontSize: "12px",
        padding: "0px",
        textAlign: "center",
      },
      [new wjcGrid.CellRange(0, 0, 1, 8), new wjcGrid.CellRange(r, 0, r + 8, 2)]
    );

    flexSheet.applyCellsStyle(
      {
        fontSize: "11px",
        textAlign: "right",
        padding: "0px",
        paddingRight: "3px",
        paddingTop: "2px",
      },
      [new wjcGrid.CellRange(2, 3, 51, 8)]
    );

    flexSheet.applyCellsStyle(
      {
        textAlign: "center",
      },
      [new wjcGrid.CellRange(0, 0, 200, 0)]
    );
    flexSheet.applyCellsStyle(
      {
        borderTop: "solid 3px black !important",
      },
      [new wjcGrid.CellRange(9, 0, 9, 8)]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#daeef3",
      },
      [new wjcGrid.CellRange(1, 3, 51, 3)]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#dbdbdb",
      },
      [new wjcGrid.CellRange(1, 4, 51, 4)]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#99ff99",
      },
      [new wjcGrid.CellRange(1, 5, 51, 5)]
    );
  }
}
export default BudgetStatusSheet;
