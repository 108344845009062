import "bootstrap/dist/css/bootstrap.css";
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjcCore from "@grapecity/wijmo";
import * as React from "react";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import "../../../../assets/css/dashboard.css";
import PropTypes from "prop-types";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

export default class QuarterYearlyTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flex: null,
      isFrozen: false,
      isLoading: true,
      hideRows: [],
      hideColumns: [],
      data: {},
      columns: [
        { id: 0, name: "FY16" },
        { id: 1, name: "FY17" },
        { id: 2, name: "FY18" },
        { id: 3, name: "FY19" },
        { id: 4, name: "FY20" },
        { id: 5, name: "FY21" },
        { id: 6, name: "FY22" },
        { id: 7, name: "FY23" },
        { id: 8, name: "FY24" },
      ],
      displayColumns: [],
      subColumnsHeaders: ["Q1", "Q2", "Q3", "Q4"],
      rows: [
        { id: 0, name: "Order Received" },
        { id: 1, name: "Sales" },
        { id: 2, name: "Myanmar" },
        { id: 3, name: "Japan" },
        { id: 4, name: "ICT-Mng" },
        { id: 5, name: "Facilities" },
        { id: 6, name: "Fiber" },
        { id: 7, name: "ICT Biz." },
        { id: 8, name: "Software&BPO" },
        { id: 9, name: "JP Biz." },
        { id: 10, name: "Cost of sales" },
        { id: 11, name: "GP" },
        { id: 12, name: "GP %" },
        { id: 13, name: "SGA" },
        { id: 14, name: "SGA %" },
        { id: 15, name: "Operating income" },
        { id: 16, name: "Operating income %" },
        { id: 17, name: "Non-operating income" },
        { id: 18, name: "Non-operating expenses" },
        { id: 19, name: "Ordinary Profit" },
        { id: 20, name: "Ordinary Profit %" },
        { id: 21, name: "Net income before tax" },
        { id: 22, name: "CT" },
        { id: 23, name: "Net income" },
      ],
      filterRange: this.props.filterRange,
      fiscalYears: this.props.fiscalYears,
    };
  }

  componentDidMount() {
    this.setState({ displayColumns: this.changeDisplayColumns() }, () => {
      this._generateBasicOperatorsSheet(this.state.flex);
    });
  }

  componentDidUpdate() {
    let displayRange = this.changeDisplayColumns();
    if (this.state.displayColumns.length !== displayRange.length) {
      this.setState({ displayColumns: displayRange });
    }
    if (this.state.data !== this.props.data) {
      this.setState({ data: this.props.data });
    }
    if (this.state.filterRange !== this.props.filterRange) {
      this.changeDisplayColumns();
      this.setState({ filterRange: this.props.filterRange });
    }
    this._generateBasicOperatorsSheet(this.state.flex);
  }

  changeDisplayColumns() {
    let start = this.props.filterRange.from,
      target = this.props.filterRange.to;
    this.unMergeCells(this.state.displayColumns);
    let result = [];
    for (let i = 0; i < this.state.fiscalYears.length; i++) {
      if (
        this.state.fiscalYears[i].fiscalYearId >= start &&
        this.state.fiscalYears[i].fiscalYearId <= target
      ) {
        for (let j = 0; j < this.state.columns.length; j++) {
          if (
            this.state.fiscalYears[i].fiscalYear === this.state.columns[j].name
          ) {
            result.push(this.state.columns[j]);
          }
        }
      }
    }
    return result;
  }

  unMergeCells(columns) {
    for (
      let col = 3;
      col < columns.length * this.state.subColumnsHeaders.length + 3;
      col += this.state.subColumnsHeaders.length
    ) {
      this.state.flex?.mergeRange(new wjcGrid.CellRange(0, col, 0, col + 3));
    }
  }

  render() {
    return (
      <div>
        <div
          className="row justify-content-start"
          style={{ marginLeft: "0px" }}
        ></div>

        <wjGridSheet.FlexSheet
          initialized={this.initializeFlexSheet.bind(this)}
          itemsSourceChanged={this.initializeFlexSheet.bind(this)}
          isReadOnly={true}
          style={{
            maxWidth: "100%",
            height: `${(this.state.rows.length + 3.6) * 20}px`,
          }}
          id="finicialDataSheet"
          className="financial_quarter_data"
        >
          <wjGridSheet.Sheet
            name="Financial Quarterly Report"
            columnCount={
              this.state.displayColumns.length *
                this.state.subColumnsHeaders.length +
              3
            }
            rowCount={this.state.rows.length + 2}
          ></wjGridSheet.Sheet>
        </wjGridSheet.FlexSheet>
      </div>
    );
  }
  initializeFlexSheet(flex) {
    this.setState({ flex: flex });
    flex.deferUpdate(() => {
      this._generateBasicOperatorsSheet(flex);
      let colCnt = flex.rowHeaders.columns.length;
      flex.rowHeaders.columns.removeAt(colCnt - 1);

      let rowCnt = flex.columnHeaders.rows.length;
      flex.columnHeaders.rows.removeAt(rowCnt - 1);
    });
    flex.frozenColumns = 3;
    flex.frozenRows = 2;
    flex.setCellData(0, 0, "Unit: M JPY");
    flex.mergeRange(new wjcGrid.CellRange(0, 0, 1, 2));

    let rows = 0;
    //rows
    for (let r = 2; r < this.state.rows.length + 2; r++) {
      flex.setCellData(r, 0, this.state.rows[rows].name);
      rows++;
      flex.mergeRange(new wjcGrid.CellRange(r, 0, r, 2));
    }
  }

  _generateBasicOperatorsSheet(flexSheet) {
    let columns = 0;
    //columns
    for (
      let col = 3;
      col <
      this.state.displayColumns.length * this.state.subColumnsHeaders.length +
        3;
      col += this.state.subColumnsHeaders.length
    ) {
      flexSheet?.mergeRange(new wjcGrid.CellRange(0, col, 0, col + 3));
      flexSheet?.setCellData(0, col, this.state.displayColumns[columns].name);
      for (
        let subCol = columns * this.state.subColumnsHeaders.length + 3;
        subCol <
        this.state.subColumnsHeaders.length +
          3 +
          columns * this.state.subColumnsHeaders.length;
        subCol += 1
      ) {
        flexSheet?.setCellData(
          1,
          subCol,
          this.state.subColumnsHeaders[
            subCol - 3 - columns * this.state.subColumnsHeaders.length
          ]
        );
      }
      columns++;
    }
    if (this.state.data.totalData)
      for (let i = 0; i < this.state.data.totalData.length; i++) {
        let key = Object.keys(this.state.data.totalData[i]);
        for (let j = 0; j < this.state.displayColumns.length; j++) {
          let subKeys = Object.keys(
            this.state.data.totalData[i][key[0]][
              this.state.displayColumns[j].name
            ]
              ? this.state.data.totalData[i][key[0]][
                  this.state.displayColumns[j].name
                ]
              : 0
          );
          for (let k = 0; k < subKeys.length; k++) {
            let row = j;
            let val = 0;
            if (key[0].includes("percent")) {
              val =
                this.state.data.totalData[i][key[0]][
                  this.state.displayColumns[j].name
                ][subKeys[k]].toFixed(1) + "%";
            } else {
              val =
                this.state.data.totalData[i][key[0]][
                  this.state.displayColumns[j].name
                ][subKeys[k]].toFixed(0);
            }
            flexSheet?.setCellData(
              i + 2,
              3 + k + row * this.state.subColumnsHeaders.length,
              val
            );
            row = j;
          }
        }
      }

    flexSheet?.applyCellsStyle(
      {
        textAlign: "right",
      },
      [
        new wjcGrid.CellRange(
          2,
          2,
          flexSheet.rows.length,
          flexSheet.columns.length
        ),
      ]
    );
    flexSheet?.applyCellsStyle(
      {
        textAlign: "center",
      },
      [new wjcGrid.CellRange(0, 0, flexSheet.rows.length, 1)]
    );
    flexSheet?.applyCellsStyle(
      {
        textAlign: "center",
      },
      [new wjcGrid.CellRange(0, 3, 1, flexSheet.columns.length)]
    );
    flexSheet?.applyCellsStyle(
      {
        backgroundColor: "#ddebf7",
      },
      [new wjcGrid.CellRange(0, 3, 0, flexSheet.columns.length)]
    );
    flexSheet?.applyCellsStyle(
      {
        backgroundColor: "#ffffcc",
      },
      [new wjcGrid.CellRange(13, 0, 14, flexSheet.columns.length)]
    );
    flexSheet?.applyCellsStyle(
      {
        backgroundColor: "#ffffcc",
      },
      [new wjcGrid.CellRange(17, 0, 18, flexSheet.columns.length)]
    );
    flexSheet?.applyCellsStyle(
      {
        backgroundColor: "#ffffcc",
      },
      [new wjcGrid.CellRange(21, 0, 23, flexSheet.columns.length)]
    );

    flexSheet?.applyCellsStyle(
      {
        backgroundColor: "#ffccff",
      },
      [new wjcGrid.CellRange(25, 0, 25, flexSheet.columns.length)]
    );

    flexSheet?.applyCellsStyle(
      {
        backgroundColor: "#ededed",
      },
      [new wjcGrid.CellRange(5, 0, 10, flexSheet.columns.length)]
    );

    flexSheet?.applyCellsStyle(
      {
        fontSize: "10px",
        padding: "0px",
        paddingTop: "3px",
        paddingLeft: "6px",
        paddingRight: "6px",
        paddingBottom: "3px",
      },
      [
        new wjcGrid.CellRange(
          0,
          0,
          this.state.rows.length,
          this.state.displayColumns.length *
            this.state.subColumnsHeaders.length +
            3
        ),
      ]
    );

    for (
      let col = 0;
      col <
      this.state.displayColumns.length * this.state.subColumnsHeaders.length +
        3;
      col++
    ) {
      if (flexSheet) {
        flexSheet.columns[col].width = "*";
        flexSheet.columns[col].minWidth = 46;
      }
    }
    for (let row = 0; row < this.state.rows.length + 1; row++) {
      if (flexSheet) flexSheet.rows[row].height = 20;
    }
  }
}

QuarterYearlyTable.propTypes = {
  data: PropTypes.object,
  filterRange: PropTypes.object,
  fiscalYears: PropTypes.array,
};
