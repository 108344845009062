import React from "react";
import PropTypes from "prop-types";
import { AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import { LOGOUT_URL } from "../constants/api.path";

export default function TopBar(props) {
  let { collapsed, setCollapsed, curLocation, changeLocation, userName } =
    props;
  const isLoggedIn = localStorage.getItem("isLoggedIn")
    ? localStorage.getItem("isLoggedIn")
    : sessionStorage.getItem("isLoggedIn");
  const [styles, setStyles] = React.useState({
    topBar: {
      width: "100vw",
      display: "flex",
      height: "36px",
      backgroundColor: "#0097B1",
      float: "right",
      position: "fixed",
      top: "0px",
      zIndex: "101",
      transition: "300ms",
    },
    brand: collapsed
      ? {
          fontSize: "16px",
          fontWeight: "600",
          width: "0px",
          lineHeight: "36px",
          wrap: "no-wrap",
          backgroundColor: "white",
          overflow: "hidden",
          transition: "300ms",
          color: "#0097B1",
          paddingLeft: "0px",
          paddingRight: "0px",
          marginRight: "0px",
          minWidth: "0px",
          cursor: "pointer",
        }
      : {
          fontSize: "16px",
          fontWeight: "600",
          width: "220px",
          lineHeight: "36px",
          wrap: "no-wrap",
          backgroundColor: "white",
          overflow: "hidden",
          transition: "300ms",
          color: "#0097B1",
          paddingLeft: "5px",
          paddingRight: "5px",
          marginRight: "5px",
          minWidth: "220px",
          cursor: "pointer",
        },
    icon: { height: "36px", width: "50px", padding: "8px" },
    link: {
      height: "36px",
      color: "white",
      textDecoration: "none",
      lineHeight: "36px",
      fontSize: "14px",
      paddingLeft: "10px",
      paddingRight: "20px",
      whiteSpace: "nowrap",
    },
  });
  async function userLogout() {
    await fetch(LOGOUT_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }).then(async () => {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login";
    });
  }
  function onClickMenuIcon() {
    setCollapsed(!collapsed);
    if (collapsed) {
      setStyles((styles) => ({
        ...styles,
        brand: {
          ...styles.brand,
          width: "220px",
          paddingLeft: "5px",
          paddingRight: "5px",
          marginRight: "5px",
          minWidth: "220px",
        },
      }));
    } else {
      setStyles((styles) => ({
        ...styles,
        brand: {
          ...styles.brand,
          width: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
          marginRight: "0px",
          minWidth: "0px",
        },
      }));
    }
  }
  return (
    <div style={styles.topBar}>
      <div className="brand" style={styles.brand}>
        Budget Management System
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex" }}>
          {isLoggedIn && (
            <div onClick={onClickMenuIcon}>
              <AiOutlineMenu style={styles.icon} />
            </div>
          )}
          <div
            style={{
              marginLeft: "5px",
              marginRigth: "5px",
              lineHeight: "36px",
              width: "fit-content",
              color: "white",
              fontSize: "14px",
              fontWeight: "bold",
              whiteSpace: "nowrap",
            }}
          >
            {curLocation}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          {/* {isLoggedIn && (
            <a href="/register" style={styles.link}>
              Register New User
            </a>
          )} */}
          {isLoggedIn && (
            <Link
              to="/register"
              style={styles.link}
              onClick={() => changeLocation("Register")}
            >
              Register New User
            </Link>
          )}
          {isLoggedIn && (
            <UncontrolledDropdown setActiveFromChild>
              <DropdownToggle tag="a" caret style={styles.link}>
                {userName}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem active onClick={userLogout}>
                  Logout
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          )}
        </div>
      </div>
    </div>
  );
}

TopBar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  setCollapsed: PropTypes.func.isRequired,
  curLocation: PropTypes.string.isRequired,
  changeLocation: PropTypes.func.isRequired,
  userName: PropTypes.string.isRequired,
  remember: PropTypes.bool.isRequired,
};
