import React from "react";
import { TOKEN } from "../../constants/api.path";

import { DASHBOARD_DATA, FISCAL_YEAR_LIST_URL } from "../../constants/api.path";

import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";
import "./dashboard-chart.css";

import { Spinner } from "reactstrap";
import moment from "moment/moment";
import { BiSearchAlt2 } from "react-icons/bi";
import { Tooltip as ReactTooltip } from "react-tooltip";

import CostsChart from "./dashboard-charts/costs-chart";
import SalesChart from "./dashboard-charts/sales-chart";
import SGAChart from "./dashboard-charts/sga-chart";
import SummaryChart from "./dashboard-charts/summary-chart";
import PropTypes from "prop-types";

export default class DashboardChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: [
        { id: 1, name: "Summary" },
        { id: 2, name: "Sales" },
        { id: 3, name: "Costs" },
        { id: 4, name: "SGA" },
      ],
      active: props.selectedSummary,
      months: [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
      ],
      isLoading: true,
      setSelectedSummary: props.setSelectedSummary,
      summary: [],
      salesRanks: [],
      salesAandB: [],
      salesCandD: [],
      salesTotal: [],
      salesBySector: [],
      costsRanks: [],
      costsAandB: [],
      costsCandD: [],
      costsTotal: [],
      costsBySector: [],
      sga: [],
      fiscalList: [],
      selectedArray: { salesPlanMonth: new Date().getMonth() + 1 },
      monthList: [],
      salesPlanYearName: [],
      salesPlanMonthName: [],
    };
  }
  seperateSecotorData(datas) {
    let budgetstatus = [
      { id: 1, name: "受注​", bottom: 0, stretch: 0, total: 0 },
      { id: 2, name: "売上​", bottom: 0, stretch: 0, total: 0 },
      { id: 3, name: "売上総利益​", bottom: 0, stretch: 0, total: 0 },
      { id: 4, name: "販管費​", bottom: 0, stretch: 0, total: 0 },
      { id: 5, name: "営業利益​", bottom: 0, stretch: 0, total: 0 },
    ];
    let keysList = ["orderReceived", "sales", "gp", "sga", "op"];
    for (let i = 0; i < datas.length; i++) {
      for (let j = 0; j < datas[i].sectorData.length; j++) {
        let keys = Object.keys(datas[i].sectorData[j]);
        for (let k = 0; k < keysList.length; k++) {
          let bottom = budgetstatus[k].bottom,
            stretch = budgetstatus[k].stretch,
            total = bottom + stretch;
          if (keysList[k] === keys[0]) {
            bottom += datas[i].sectorData[j][keys[0]].bottom;
            stretch += datas[i].sectorData[j][keys[0]].stretch;
            total = bottom + stretch;
            budgetstatus[k] = {
              ...budgetstatus[k],
              bottom: Math.round(bottom),
              stretch: Math.round(stretch),
              total: Math.round(total),
            };
          } else {
            continue;
          }
        }
      }
    }
    this.setState({ summary: budgetstatus });
  }

  seperateSalesRanks(datas) {
    let data = [
      { name: "A&B", count: Math.round(datas[0].totalData[1].sales.bottom) },
      { name: "C&D", count: Math.round(datas[0].totalData[1].sales.stretch) },
    ];
    this.setState({ salesRanks: data });
  }

  getAandB(datas) {
    let data = [{ name: "積上" }];
    let total = 0;
    for (let i = 0; i < datas.length; i++) {
      data[0][datas[i].sectorName] = Math.round(
        datas[i].sectorData[1].sales.bottom
      );
      total += Math.round(datas[i].sectorData[1].sales.bottom);
    }
    data[0].total = total;
    this.setState({ salesAandB: data });
  }
  getCandD(datas) {
    let data = [{ name: "改善​" }];
    for (let i = 0; i < datas.length; i++) {
      data[0][datas[i].sectorName] = Math.round(
        datas[i].sectorData[1].sales.stretch
      );
    }
    this.setState({ salesCandD: data });
  }

  getTotalRank(datas) {
    let data = [];
    for (let i = 0; i < datas.length; i++) {
      if (datas[i].sectorName !== "SGA") {
        data.push({
          name:
            datas[i].sectorName === "JP Business"
              ? "日本支店"
              : datas[i].sectorName,
          count: Math.round(datas[i].sectorData[1].sales.total),
        });
      }
    }
    this.setState({ salesTotal: data });
  }

  getSalesBySector(datas) {
    let data = [{ name: "見込" }];
    let total = 0;
    for (let i = 0; i < datas.length; i++) {
      if (datas[i].sectorName !== "SGA") {
        data[0][datas[i].sectorName] = Math.round(
          datas[i].sectorData[1].sales.total
        );
        total += Math.round(datas[i].sectorData[1].sales.total);
      }
    }
    data[0].total = total;
    this.setState({ salesBySector: data });
  }

  seperateCostsRanks(datas) {
    let data = [
      { name: "A&B", count: Math.round(datas[0].totalData[2].costs.bottom) },
      { name: "C&D", count: Math.round(datas[0].totalData[2].costs.stretch) },
    ];
    this.setState({ costsRanks: data });
  }

  getCostsAandB(datas) {
    let data = [{ name: "積上" }];
    let total = 0;
    for (let i = 0; i < datas.length; i++) {
      data[0][datas[i].sectorName] = Math.round(
        datas[i].sectorData[2].costs.bottom
      );
      total += Math.round(datas[i].sectorData[2].costs.bottom);
    }
    data[0].total = total;
    this.setState({ costsAandB: data });
  }
  getCostsCandD(datas) {
    let data = [{ name: "改善​" }];
    for (let i = 0; i < datas.length; i++) {
      data[0][datas[i].sectorName] = Math.round(
        datas[i].sectorData[2].costs.stretch
      );
    }
    this.setState({ costsCandD: data });
  }

  getCostsTotalRank(datas) {
    let data = [];
    for (let i = 0; i < datas.length; i++) {
      if (datas[i].sectorName !== "SGA") {
        data.push({
          name:
            datas[i].sectorName === "JP Business"
              ? "日本支店"
              : datas[i].sectorName,
          count: Math.round(datas[i].sectorData[2].costs.total),
        });
      }
    }
    this.setState({ costsTotal: data });
  }

  getCostsBySector(datas) {
    let data = [{ name: "見込" }];
    let total = 0;
    for (let i = 0; i < datas.length; i++) {
      if (datas[i].sectorName !== "SGA") {
        data[0][datas[i].sectorName] = Math.round(
          datas[i].sectorData[2].costs.total
        );
        total += Math.round(datas[i].sectorData[2].costs.total);
      }
    }
    data[0].total = total;
    this.setState({ costsBySector: data });
  }

  getSGA(datas) {
    let data = [{ name: "SGA" }];
    let sectors = [
      { sectorName: "JP Business", binding: "sga", name: "branch" },
      { sectorName: "SGA", binding: "sga", name: "headOffice" },
    ];
    for (let i = 0; i < datas.length; i++) {
      for (let j = 0; j < sectors.length; j++) {
        if (datas[i].sectorName === sectors[j].sectorName) {
          for (let k = 0; k < datas[i].sectorData.length; k++) {
            let key = Object.keys(datas[i].sectorData[k]);
            if (key[0] === sectors[j].binding) {
              data[0][sectors[j].name] = datas[i].sectorData[k][key[0]].total;
            }
          }
        }
      }
    }
    this.setState({ sga: data });
  }

  componentDidMount() {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: { Authorization: `Bearer ${TOKEN}` },
    })
      .then((res) => res.json())
      .then((result) => {
        this.setState(
          {
            selectedArray: {
              salesPlanYear: result.data.currentYearId,
              ...this.state.selectedArray,
            },
            fiscalList: result.data.fiscalYearList,
          },
          () => {
            this.getSummaryData();
            this.salesPlanMonthUpdate(this.state.selectedArray.salesPlanYear);
          }
        );
      });
  }

  getSummaryData() {
    fetch(
      DASHBOARD_DATA +
      "/salesPlanYear/" +
      this.state.selectedArray.salesPlanYear +
      "/salesPlanMonth/" +
      this.state.selectedArray.salesPlanMonth,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${TOKEN}` },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.seperateSecotorData(data.data.dashboardSectorData);
        this.seperateSalesRanks(data.data.dashboardTotalData);
        this.getAandB(data.data.dashboardSectorData);
        this.getCandD(data.data.dashboardSectorData);
        this.getTotalRank(data.data.dashboardSectorData);
        this.getSalesBySector(data.data.dashboardSectorData);
        this.seperateCostsRanks(data.data.dashboardTotalData);
        this.getCostsAandB(data.data.dashboardSectorData);
        this.getCostsCandD(data.data.dashboardSectorData);
        this.getCostsTotalRank(data.data.dashboardSectorData);
        this.getCostsBySector(data.data.dashboardSectorData);
        this.getSGA(data.data.dashboardSectorData);
        this.setState({ isLoading: false });
      });

    this.getYearMonthName();

    let page_number = new URL(window.location).searchParams.get("page") - 1;
    this.setState(
      {
        active:
          page_number && this.state.tabs[page_number]
            ? this.state.tabs[page_number].name
            : this.props.selectedSummary,
      },
      () => { }
    );
  }

  salesPlanMonthUpdate(id) {
    let key = 0;
    let data = [];
    let currentID = id ? parseInt(id) : this.state.selectedArray.salesPlanYear;
    this.state.fiscalList.forEach((fiscalList) => {
      if (fiscalList.fiscalYearId === currentID) {
        for (
          let i = new Date(fiscalList.fyStartDate).getMonth();
          i < new Date(fiscalList.fyStartDate).getMonth() + 12;
          i++
        ) {
          data.push({
            id: key + 1,
            name: moment()
              .month(i < 12 ? i : i - 12)
              .format("MMM"),
            value: i + 1 < 13 ? i + 1 : i - 11,
          });
          key++;
        }
        this.setState({ monthList: data });
      }
    });
  }

  handleChange(e) {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;

    const selectedValue = { ...this.state.selectedArray };
    selectedValue[fieldName] = fieldValue;

    this.setState({ selectedArray: selectedValue }, () => {
      this.salesPlanMonthUpdate(this.state.selectedArray.salesPlanYear);
    });
  }

  getYearMonthName() {
    this.state.fiscalList.forEach((fiscalList) => {
      if (this.state.selectedArray.salesPlanYear == fiscalList.fiscalYearId) {
        this.setState(
          {
            salesPlanYearName: fiscalList.fyStartDate.split("-")[0],
            salesPlanMonthName: moment()
              .month(this.state.selectedArray.salesPlanMonth - 1)
              .format("MMM")
              .toUpperCase(),
          },
          () => { }
        );
      }
    });
  }

  render() {
    return (
      <div
        style={{
          backgroundColor: "transparent",
          padding: "0px",
          minHeight: "calc( 100vh - 38px )",
          maxHeight: "calc( 100vh - 38px )",
        }}
      >
        {this.state.isLoading && (
          <div className="loader">
            <Spinner color="white" />
          </div>
        )}
        <div
          className="row justify-content-between"
          style={{ marginLeft: "12px" }}
        >
          <div className="col-10">
            <div className="row">
              {this.state.tabs.map((tab) => (
                <div
                  className="col-1 actionTextBtn2-parent"
                  style={{ padding: "0.5px" }}
                  key={tab.id}
                >
                  <button
                    type="button"
                    className={`actionTextBtn2 ${this.state.active === tab.name && "dashboardActiveBtn"
                      }`}
                    onClick={() => {
                      this.state.active !== tab.name &&
                        this.setState({ active: tab.name });
                      this.state.setSelectedSummary(tab.name);
                    }}
                  >
                    {tab.name}
                  </button>
                </div>
              ))}

              <div
                className="col "
                style={{ padding: "1.5px", paddingLeft: "35px" }}
              >
                <div className="row">
                  <div className="col-auto" style={{ padding: "1.5px" }}>
                    <select
                      name="salesPlanYear"
                      id="salesPlanYear"
                      data-tooltip-content="Fiscal Year"
                      className="form-select"
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      value={this.state.selectedArray.salesPlanYear}
                    >
                      <option key={0} value="" hidden>
                        Fiscal Year
                      </option>
                      {this.state.fiscalList.map((item) => (
                        <option
                          key={item.fiscalYearId}
                          value={item.fiscalYearId}
                        >
                          {item.fiscalYear}
                        </option>
                      ))}
                    </select>
                    <ReactTooltip place="top" anchorId="salesPlanYear" />
                  </div>
                  <div className="col-auto" style={{ padding: "1.5px" }}>
                    <select
                      name="salesPlanMonth"
                      id="salesPlanMonth"
                      data-tooltip-content="Month"
                      className="form-select"
                      onChange={(e) => {
                        this.handleChange(e);
                      }}
                      value={this.state.selectedArray.salesPlanMonth}
                    >
                      <option value="" hidden>
                        Month
                      </option>
                      {this.state.monthList.map((month) => (
                        <option key={month.id} value={month.value}>
                          {month.name}
                        </option>
                      ))}
                    </select>
                    <ReactTooltip place="top" anchorId="salesPlanMonth" />
                  </div>
                  <div className=" col " style={{ padding: "1.5px" }}>
                    <button
                      id="search"
                      data-tooltip-content="Search"
                      type="button"
                      style={{ margin: "0", padding: "0px" }}
                      className="actionIconOutline search bg-blue  "
                      onClick={() => {
                        this.setState({ isLoading: true });
                        this.getSummaryData();
                      }}
                    >
                      <BiSearchAlt2 />
                      <ReactTooltip place="top" anchorId="search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col-2"
            style={{ minWidth: "150px", textAlign: "center" }}
          >
            <p style={{ fontWeight: "bold", margin: "0px" }}>Unit: M JPY</p>
            <p style={{ margin: "0px" }}>
              As of: {new Date().getDate()}
              {this.state.salesPlanMonthName}
              {this.state.salesPlanYearName}
              {/* {this.state.months[new Date().getMonth()]}
              {new Date().getFullYear()} */}
            </p>
          </div>
        </div>
        <br />
        <div style={{ height: "calc( 100vh - 100px )", overflow: "auto" }}>
          {!this.state.isLoading &&
            (this.state.active === "Summary" ? (
              <div className="chart">
                <SummaryChart stacked={true} summary={this.state.summary} />
                <SummaryChart summary={this.state.summary} />
              </div>
            ) : this.state.active === "Sales" ? (
              <div className="chart">
                <SalesChart
                  stacked={true}
                  salesRanks={this.state.salesRanks}
                  salesAandB={this.state.salesAandB}
                  salesCandD={this.state.salesCandD}
                  salesTotal={this.state.salesBySector}
                />
                <SalesChart
                  salesRanks={this.state.salesRanks}
                  salesAandB={this.state.salesAandB}
                  salesCandD={this.state.salesCandD}
                  salesTotal={this.state.salesTotal}
                />
              </div>
            ) : this.state.active === "Costs" ? (
              <div className="chart">
                <CostsChart
                  stacked={true}
                  costsRanks={this.state.salesRanks}
                  costsAandB={this.state.costsAandB}
                  costsCandD={this.state.costsCandD}
                  costsTotal={this.state.costsBySector}
                />
                <CostsChart
                  costsRanks={this.state.salesRanks}
                  costsAandB={this.state.costsAandB}
                  costsCandD={this.state.costsCandD}
                  costsTotal={this.state.costsTotal}
                />
              </div>
            ) : (
              this.state.active === "SGA" && (
                <div className="chart">
                  <SGAChart stacked={true} sga={this.state.sga} />
                  <SGAChart sga={this.state.sga} />
                </div>
              )
            ))}
        </div>
      </div>
    );
  }
}
DashboardChart.propTypes = {
  selectedSummary: PropTypes.string,
  setSelectedSummary: PropTypes.func,
};
