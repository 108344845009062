import React from "react";
import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";
import { changeLabelPosition } from "./changeLablePosition";
import PropTypes from "prop-types";

export default class SummaryChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      summary: props.summary,
      palette: ["#ff5349", "#32CD32", !props.stacked ? "#00FFFF" : "#0000FF"],
      stacked: props.stacked,
    };
  }
  getLabelContent(ht) {
    return ht.value;
  }
  componentDidMount() {
    this.state.stacked &&
      setTimeout(() => {
        changeLabelPosition(this.state.summary, "initial");
      }, 500);
  }
  componentDidUpdate() {
    this.state.stacked &&
      setTimeout(() => {
        changeLabelPosition(this.state.summary, "initial", "update");
      }, 500);
  }
  render() {
    return (
      <div className="container-fluid">
        <wjChart.FlexChart
          header="Budget Status"
          bindingX="name"
          itemsSource={this.state.summary}
          chartType="Column"
          stacking={this.state.stacked && "Stacked"}
          selectionMode="Series"
          legendToggle={true}
          palette={this.state.palette}
          rendered={() => {
            this.state.stacked &&
              changeLabelPosition(this.state.summary, "render");
          }}
        >
          <wjChart.FlexChartLegend position="Bottom" />
          <wjChart.FlexChartSeries
            name="改善​"
            binding="stretch"
          ></wjChart.FlexChartSeries>
          <wjChart.FlexChartSeries
            name="ボトム​"
            binding="bottom"
          ></wjChart.FlexChartSeries>
          <wjChart.FlexChartSeries
            name="見込​"
            binding="total"
            chartType={this.state.stacked ? "LineSymbols" : "Column"}
          >
            <wjChart.FlexChartDataLabel content="{y}" position="Top" />
          </wjChart.FlexChartSeries>
          <wjChartAnimate.FlexChartAnimation />
          <wjChart.FlexChartDataLabel
            content={"{y}"}
            position="Bottom"
          ></wjChart.FlexChartDataLabel>
        </wjChart.FlexChart>
      </div>
    );
  }
}

SummaryChart.propTypes = {
  summary: PropTypes,
  stacked: PropTypes,
};
