import React from "react";
import { useEffect, useState } from "react";
import { DataConveterBy } from "../utilities/utilities";

import * as wijmo from "@grapecity/wijmo";
import "@grapecity/wijmo.chart.render";
import {
  FlexChart,
  FlexChartAxis,
  FlexChartDataLabel,
  FlexChartLegend,
  FlexChartSeries,
} from "@grapecity/wijmo.react.chart";
import { FlexChartAnimation } from "@grapecity/wijmo.react.chart.animation";
import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";

import { useAtom } from "jotai";

import PropTypes from "prop-types";
import { metadataAtom } from "../state/chart-item-atom";

const BarChart = (props) => {
  const { datasources, width, height, chartInitalized } = props;

  const [labelShow, setLabelShow] = useState(datasources.settings.dataLabel);
  const [metadataState] = useAtom(metadataAtom);
  const [palette] = useState([
    "rgba(42,159,214,1)",
    "rgba(119,179,0,1)",
    "rgba(153,51,204,1)",
    "rgba(255,136,0,1)",
    "rgba(204,0,0,1)",
    "rgba(0,204,163,1)",
    "rgba(61,109,204,1)",
    "rgba(82,82,82,1)",
    "rgba(0,0,0,1)",
  ]);

  const serverData = DataConveterBy(
    metadataState,
    datasources.configure.bindingX,
    datasources.configure.bindingY
  );

  useEffect(() => {
    setLabelShow(datasources.settings.dataLabel);
  }, [datasources.settings]);

  useEffect(() => {
    window.setTimeout(() => {
      wijmo.Control.invalidateAll();
      setLabelShow(datasources.settings.dataLabel);
    }, 200);
  }, [datasources.settings.dataLabel]);

  return (
    <div>
      <FlexChart
        initialized={chartInitalized}
        style={{ width: width, height: height }}
        // header={datasources.header}
        bindingX={datasources.configure.bindingX}
        stacking={datasources.settings.stacked}
        selectionMode="Point"
        chartType={
          datasources.settings.chartType === "barChart" ? "Bar" : "Column"
        }
        legendToggle={datasources.settings.legendToggle}
        itemsSource={serverData}
        palette={palette}
      >
        <FlexChartDataLabel position={labelShow} content="{y}" />
        <FlexChartLegend position={datasources.settings.legendPosition} />
        {datasources.configure.bindingY?.map((item) => (
          <FlexChartSeries
            name={item}
            binding={item}
            key={`bar-chart-${item}`}
          ></FlexChartSeries>
        ))}
        <FlexChartAxis
          title={datasources.settings.chartAxis?.leftAxisTitle}
          wjProperty="axisY"
          axisLine={true}
          origin={-10}
        />
        <FlexChartAnimation />
      </FlexChart>
    </div>
  );
};

export default BarChart;

BarChart.propTypes = {
  datasources: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.number,
  chartInitalized: PropTypes.func,
};
