import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

function ConfirmModal(props) {
  return (
    <div>
      <Modal {...props} size="md" onHide={props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.title}</Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onSubmit}>Confirm</Button>
          <Button onClick={props.onCancel}>Cancel</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfirmModal;

ConfirmModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};
