import React from "react";

// wijmo
// import * as wjcCore from "@grapecity/wijmo";
import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";

// css
import "@grapecity/wijmo.styles/wijmo.css";
import "./dashboard.css";

// api
import {
  DASHBOARD_DATA,
  FISCAL_YEAR_LIST_URL,
  TOKEN,
} from "../../../constants/api.path";

// Library
import { Tooltip } from "react-tooltip";
import { Spinner } from "reactstrap";

// icons
import { TbListDetails } from "react-icons/tb";

// components
import { Link } from "react-router-dom";
import DashboardBarChart from "../dynamic-dashboard/dashboard-components/dashboardBarChart";
import DashboardColumnChart from "../dynamic-dashboard/dashboard-components/dashboardChart";
import DashboardSalesAandB from "../dynamic-dashboard/dashboard-components/dashboardSalesAandB";
import DashboardSalesByRanks from "../dynamic-dashboard/dashboard-components/dashboardSalesByRanks";
import DashboardSalesCandD from "../dynamic-dashboard/dashboard-components/dashboardSalesCandD";
import DashboardSalesRank from "../dynamic-dashboard/dashboard-components/dashboardSalesRanks";
import DashboardTable from "../dynamic-dashboard/dashboard-components/dashboardTable";
import { icons } from "../dynamic-dashboard/dashboard-components/icons";
// import DynamicDashboard from "../dynamic-dashboard/dynamicDashboard";
import DashboardLinearGauge from "../dynamic-dashboard/dashboard-components/dashboardLinearGauge";
import DashboardRadialGauge from "../dynamic-dashboard/dashboard-components/dashboardRadialGauge";
import FinancialData from "./financial-data-summary/financialData";

import moment from "moment/moment";
import { BiSearchAlt2 } from "react-icons/bi";
import PropTypes from "prop-types";
// import DashboardList from "../iliveboard/dashboard_list";

export default function DashboardFinal({ changeLocation }) {
  const [isLoading, setIsLoading] = React.useState(true);
  // const [isLoadedData, setIsLoadedData] = React.useState(false);
  const [totalData, setTotalData] = React.useState([]);
  const [salesRanks, setSalesRanks] = React.useState([]);
  const [budgetStatusData, setBudgetStatusData] = React.useState([]);
  const [salesAandB, setSalesAandB] = React.useState([]);
  const [salesCandD, setSalesCandD] = React.useState([]);
  const [salesTotal, setSalesTotal] = React.useState([]);
  // const [tileCatalog, setTileCatalog] = React.useState([]);
  // const [dynamicMenuExpend, setDynamicMenuExpend] = React.useState(false);
  let key = 0;
  const [tiles, setTiles] = React.useState([]);
  // const [state, setState] = React.useState({
  //   tileCatalog: new wjcCore.CollectionView(tileCatalog),
  //   key: key,
  // });
  const months = getMonth();
  const [tabs, setTabs] = React.useState([
    {
      id: 1,
      name: `${
        months[
          new Date().getMonth() + 1 < 4
            ? new Date().getMonth() + 9
            : new Date().getMonth() - 3
        ].name
      }'${new Date().getFullYear() - (new Date().getMonth() < 2 ? 1999 : 2000)}
       Report Summary`,
    },
    { id: 2, name: "Financial Data Summary" },
    // { id: 3, name: "Dynamic Report" },
  ]);
  const [activeTabId] = React.useState(
    parseInt(new URL(window.location).searchParams.get("tab"))
  );
  const [activeTab, setActiveTab] = React.useState(
    activeTabId && activeTabId <= tabs.length
      ? tabs[activeTabId - 1].name
      : tabs[0].name
  );
  const [fiscalYear, setFiscalYear] = React.useState([]);
  const [filterValue, setFilterValue] = React.useState({
    year: 0,
    month: new Date().getMonth() + 1,
  });
  const cardsColor = [
    "#2f5597",
    "#c55a11",
    "#7c7c7c",
    "#247282",
    "#2e75b6",
    "#267a44",
  ];
  const budgetChartPalette = ["#ff5349", "#32CD32", "#00FFFF"];

  function seperateSecotorData(datas) {
    let budgetstatus = [
      { id: 1, name: "受注​", bottom: 0, stretch: 0, total: 0 },
      { id: 2, name: "売上​", bottom: 0, stretch: 0, total: 0 },
      { id: 3, name: "売上総利益​", bottom: 0, stretch: 0, total: 0 },
      { id: 4, name: "販管費​", bottom: 0, stretch: 0, total: 0 },
      { id: 5, name: "営業利益​", bottom: 0, stretch: 0, total: 0 },
    ];
    let keysList = ["orderReceived", "sales", "gp", "sga", "op"];
    for (let i = 0; i < datas.length; i++) {
      for (let j = 0; j < datas[i].sectorData.length; j++) {
        let keys = Object.keys(datas[i].sectorData[j]);
        for (let k = 0; k < keysList.length; k++) {
          let bottom = budgetstatus[k].bottom,
            stretch = budgetstatus[k].stretch,
            total = bottom + stretch;
          if (keysList[k] === keys[0]) {
            bottom += datas[i].sectorData[j][keys[0]].bottom;
            stretch += datas[i].sectorData[j][keys[0]].stretch;
            total = bottom + stretch;
            budgetstatus[k] = {
              ...budgetstatus[k],
              bottom: Math.round(bottom),
              stretch: Math.round(stretch),
              total: Math.round(total),
            };
          } else {
            continue;
          }
        }
      }
    }
    setBudgetStatusData(budgetstatus);
    return budgetstatus;
  }

  function seperateTotalData(datas) {
    let totalData = [];
    let keysList = ["orderReceived", "sales", "costs", "gp", "sga", "op"];
    for (let i = 0; i < datas[0].totalData.length; i++) {
      let keys = Object.keys(datas[0].totalData[i]);
      for (let j = 0; j < keysList.length; j++) {
        if (keysList[j] === keys[0]) {
          let data = {
            id: i + 1,
            name:
              keys[0] === "orderReceived"
                ? "O.R"
                : keys[0].length < 4
                ? keys[0].toUpperCase()
                : keys[0].charAt(0).toUpperCase() + keys[0].slice(1),
            plan: Math.round(datas[0].totalData[i][keys[0]].budget),
            actual: Math.round(datas[0].totalData[i][keys[0]].total),
            total: Math.round(
              datas[0].totalData[i][keys[0]].total -
                datas[0].totalData[i][keys[0]].budget
            ),
          };
          totalData.push(data);
        } else {
          continue;
        }
      }
    }
    setTotalData(totalData);
    return totalData;
  }

  function seperateSalesRanks(datas) {
    let data = [
      { name: "A&B", count: Math.round(datas[0].totalData[1].sales.bottom) },
      { name: "C&D", count: Math.round(datas[0].totalData[1].sales.stretch) },
    ];
    setSalesRanks(data);
    return data;
  }

  function getAandB(datas) {
    let data = [{ name: "積上" }];
    for (let i = 0; i < datas.length; i++) {
      data[0][datas[i].sectorName] = Math.round(
        datas[i].sectorData[1]?.sales.bottom
      );
    }
    setSalesAandB(data);
    return data;
  }
  function getCandD(datas) {
    let data = [{ name: "改善​" }];
    for (let i = 0; i < datas.length; i++) {
      data[0][datas[i].sectorName] = Math.round(
        datas[i].sectorData[1]?.sales.stretch
      );
    }
    setSalesCandD(data);
    return data;
  }

  function getTotalRank(datas) {
    let data = [];
    for (let i = 0; i < datas.length; i++) {
      if (datas[i].sectorName !== "SGA") {
        data.push({
          name:
            datas[i].sectorName === "JP Business"
              ? "日本支店"
              : datas[i].sectorName,
          count: Math.round(datas[i].sectorData[1].sales.total),
        });
      }
    }
    setSalesTotal(data);
    return data;
  }

  function updateDynamicDashboardData(data) {
    let totalData = seperateTotalData(data.dashboardTotalData);
    let salesRank = seperateSalesRanks(data.dashboardTotalData);
    let budgetStatusData = seperateSecotorData(data.dashboardSectorData);
    let salesAandB = getAandB(data.dashboardSectorData);
    let salesCandD = getCandD(data.dashboardSectorData);
    let salesTotal = getTotalRank(data.dashboardSectorData);
    let tileList = [
      {
        name: "Budget Variance Status",
        tile: DashboardTable,
        icon: icons.grid,
        data: totalData,
      },
      {
        name: "Budget Variance Status Chart",
        tile: DashboardBarChart,
        icon: icons.barChart,
        data: totalData,
      },
      {
        name: "Actual Budget Trend by Rank",
        tile: DashboardColumnChart,
        icon: icons.columnChart,
        data: budgetStatusData,
      },
      {
        name: "売上ランク",
        tile: DashboardSalesRank,
        icon: icons.radialGauge,
        data: salesRank,
      },
      {
        name: "A&B ランク",
        tile: DashboardSalesAandB,
        icon: icons.columnChart,
        data: salesAandB,
      },
      {
        name: "C&D ランク",
        tile: DashboardSalesCandD,
        icon: icons.columnChart,
        data: salesCandD,
      },
      {
        name: "売上 (セクター別)",
        tile: DashboardSalesByRanks,
        icon: icons.radialGauge,
        data: salesTotal,
      },
      {
        name: "Sales, Costs & OP",
        tile: DashboardLinearGauge,
        icon: icons.linearGauge,
        data: totalData,
      },
      {
        name: "Profit",
        tile: DashboardRadialGauge,
        icon: icons.radialGauge,
        data: [],
      },
    ];
    // setTileCatalog(tileList);
    setTiles([
      {
        name: tileList[0].name,
        key: (key += 1),
        data: tileList[0].data,
      },
      {
        name: tileList[1].name,
        key: (key += 1),
        data: tileList[1].data,
      },
    ]);
    // setState({
    //   tileCatalog: new wjcCore.CollectionView(tileList),
    //   key: key,
    // });
    // setIsLoadedData(true);
  }

  React.useEffect(() => {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: { Authorization: `Bearer ${TOKEN}` },
    })
      .then((res) => res.json())
      .then((result) => {
        setFiscalYear(result.data.fiscalYearList);
        setFilterValue((filterValue) => ({
          ...filterValue,
          year: result.data.currentYearId,
        }));
        getDashboardData(result.data.currentYearId);
      });
  }, []);

  function getDashboardData(
    year = filterValue.year,
    month = filterValue.month
  ) {
    updateTabs(year, month);
    fetch(
      DASHBOARD_DATA + "/salesPlanYear/" + year + "/salesPlanMonth/" + month,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${TOKEN}` },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.result) {
          seperateSecotorData(data.data.dashboardSectorData);
          seperateTotalData(data.data.dashboardTotalData);
          seperateSalesRanks(data.data.dashboardTotalData);
          getAandB(data.data.dashboardSectorData);
          getCandD(data.data.dashboardSectorData);
          getTotalRank(data.data.dashboardSectorData);
          updateDynamicDashboardData(data.data);
          setIsLoading(false);
        }
      });
  }

  const updateTabs = () => {
    let monthIndex =
      parseInt(filterValue.month) >= 4
        ? parseInt(filterValue.month) - 4
        : parseInt(filterValue.month) + 8;
    let year;

    let yearIndex = parseInt(filterValue.year);
    for (let i in fiscalYear) {
      fiscalYear[i].fiscalYearId === yearIndex &&
        (year = parseInt(fiscalYear[i].fiscalYear.slice(2)) + 2000);
    }
    let value = [
      {
        id: 1,
        name: `${months[monthIndex].name}'${
          (year ? year : new Date().getFullYear()) -
          (parseInt(filterValue.month) < 2 ? 1999 : 2000)
        }
         Report Summary`,
      },
      { id: 2, name: "Financial Data Summary" },
      // { id: 3, name: "Dynamic Report" },
    ];
    setTabs(value);
  };

  React.useEffect(() => {
    // setState({
    //   tileCatalog: new wjcCore.CollectionView(tileCatalog),
    //   key: key,
    // });
  }, [tiles]);

  function getMonth() {
    let data = [];
    for (let i = 3; i < 15; i++) {
      data.push({
        name: moment()
          .month(i < 12 ? i : i - 12)
          .format("MMM"),
        value: i < 12 ? i + 1 : i - 11,
      });
    }
    return data;
  }

  function handleChange(e) {
    setFilterValue((filterValue) => ({
      ...filterValue,
      [e.target.name]: e.target.value,
    }));
  }

  React.useEffect(() => {
    setActiveTab(
      activeTabId && activeTabId <= tabs.length
        ? tabs[activeTabId - 1].name
        : tabs[0].name
    );
  }, [tabs]);

  return (
    <div className="dashboard-container">
      {isLoading && (
        <div className="loader">
          <Spinner color="white" />
        </div>
      )}
      <div style={{ height: "35px" }}>
        <div
          className="row justify-content-between"
          style={{ marginLeft: "0px" }}
        >
          <div className="col-6 row">
            {tabs.map((tab) => (
              <div
                className="col-3 dashboardTab-parent"
                style={{ padding: "0.5px" }}
                key={tab.id}
              >
                <Link to={`/dashboard?tab=${tab.id}`}>
                  <button
                    type="button"
                    className={`dashboardTab ${
                      activeTab === tab.name && "dashboardActiveTab"
                    }`}
                    onClick={() => {
                      activeTab !== tab.name && setActiveTab(tab.name);
                    }}
                  >
                    {tab.name}
                  </button>
                </Link>
              </div>
            ))}
          </div>
          {/* <div className="col-2 row filter-container p-0"> */}
          <div className="col-5 row">
            {activeTab === tabs[0].name && (
              <div className="col-auto">
                <select
                  className="form-select"
                  value={filterValue.year}
                  onChange={(e) => handleChange(e)}
                  name="year"
                >
                  <option value="" hidden>
                    Year
                  </option>
                  {fiscalYear.map((fiscalYear, index) => (
                    <option value={fiscalYear.fiscalYearId} key={index}>
                      {fiscalYear.fiscalYear}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {activeTab === tabs[0].name && (
              <div className="col-auto">
                <select
                  className="form-select"
                  value={filterValue.month}
                  name="month"
                  onChange={(e) => handleChange(e)}
                >
                  <option value="" hidden>
                    Month
                  </option>
                  {months.map((month, index) => (
                    <option key={index} value={month.value}>
                      {month.name}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {activeTab === tabs[0].name && (
              <button
                id="search"
                data-tooltip-content="Search"
                type="button"
                style={{ margin: "0", padding: "0px" }}
                className="actionIconOutline search bg-blue"
                onClick={() => {
                  getDashboardData();
                }}
              >
                <BiSearchAlt2 />
              </button>
            )}
          </div>
          {/* </div> */}
          <div
            className="col-1"
            style={{ display: "flex", alignItems: "center" }}
          >
            <p
              style={{
                width: "100%",
                textAlign: "right",
                margin: "0",
                whiteSpace: "nowrap",
              }}
            >
              <b>Unit: M JPY</b>
            </p>
          </div>
        </div>
      </div>
      {activeTab === tabs[0].name && (
        <div className="dashboard-body">
          <Tooltip
            place="left"
            anchorId="bcannual-detail"
            className="tooltip"
          />
          <Tooltip
            place="left"
            anchorId="budgetStatus-detail"
            className="tooltip"
          />
          <Tooltip
            place="left"
            anchorId="salesRank-detail"
            className="tooltip"
          />
          <Tooltip
            place="left"
            anchorId="analysis-summary"
            className="tooltip"
          />
          <div className="row dashboard" style={{ justifyContent: "center" }}>
            {totalData.map((card, index) => (
              <div
                key={card.id}
                className="col-lg-2 col-md-4 col-9 dashboard-card"
              >
                <div className="dashboard-card-title">
                  <p>{card.name === "O.R" ? "Order Recieved" : card.name}</p>
                </div>
                <div
                  className="dashboard-card-body"
                  style={{ backgroundColor: cardsColor[index] }}
                >
                  <div className="row">
                    <div className="col-12">
                      <p style={{ textAlign: "right" }}>
                        Plan: ¥{card.plan.toFixed(0)}M
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <p style={{ textAlign: "right" }}>
                        Actual: ¥{card.actual.toFixed(0)}M
                      </p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-3 percent">
                      <p>{Math.round((card.actual / card.plan) * 100)}%</p>
                    </div>
                    <div className="col-9 card-progress">
                      <div className="card-progress-container">
                        <div
                          className="card-progress-value"
                          style={{
                            backgroundColor:
                              card.actual / card.plan > 1
                                ? "#32CD32"
                                : "#FEF250",
                            width:
                              (((card.actual / card.plan) * 100) /
                                ((card.actual / card.plan) * 100 -
                                  (((card.actual / card.plan) * 100) % 50) +
                                  50)) *
                                100 +
                              "%",
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div style={{ height: "calc(100vh - 250px)" }}>
            <div className="row mt-1 dashboard-first-row">
              <div
                className="col-lg-2 col-md-3"
                style={{
                  overflow: "auto",
                  paddingRight: "6px",
                  height: "100%",
                }}
              >
                <div className="title">
                  <p>
                    <b>Budget Variance Status</b>
                  </p>
                </div>
                <table className="dashboard-table table table-bordered table-striped m-0">
                  <thead>
                    <tr>
                      <td></td>
                      <td className="table-title-number">予算</td>
                      <td className="table-title-number">実績</td>
                      <td className="table-title-number">予算差</td>
                    </tr>
                  </thead>
                  <tbody className="table-bordered">
                    {totalData.map((data) => (
                      <tr key={data.id}>
                        <td className="table-name">
                          <b>{data.name === "O.R" ? data.name : data.name}</b>
                        </td>
                        <td className="table-number">
                          <b>{Math.round(data.plan)}</b>
                        </td>
                        <td className="table-number">
                          <b>{Math.round(data.actual)}</b>
                        </td>
                        <td
                          className="table-number"
                          style={{
                            color: data.actual - data.plan > 0 ? "blue" : "red",
                          }}
                        >
                          <b>{Math.round(data.actual - data.plan)}</b>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="col-lg-6 col-md-5 h-100">
                <div className="dashboard-chart-container-budget">
                  <div className="row justify-content-between title">
                    <div className="col-10">
                      <p>
                        <b>Budget Variance Status Chart</b>
                      </p>
                    </div>
                    <div
                      className="col-2"
                      style={{
                        padding: "0",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Link
                        to="/report/bcannual/yen"
                        onClick={() =>
                          this.state.changeLocation("Report | BC Annual | YEN")
                        }
                      >
                        <TbListDetails
                          data-tooltip-content="BC Annual"
                          id="bcannual-detail"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="dashboard-chart-body-budget">
                    <wjChart.FlexChart
                      bindingX="name"
                      itemsSource={totalData}
                      chartType="Bar"
                      selectionMode="Series"
                      palette={["#32CD32", "#FFFF00"]}
                    >
                      <wjChart.FlexChartLegend
                        position="Bottom"
                        reversed={true}
                      />
                      <wjChart.FlexChartSeries name="実績" binding="actual" />
                      <wjChart.FlexChartSeries name="予算" binding="plan" />
                      <wjChart.FlexChartAxis
                        wjProperty="axisY"
                        reversed={true}
                      ></wjChart.FlexChartAxis>
                      <wjChart.FlexChartDataLabel
                        content="{y}"
                        position="Left"
                      />
                      <wjChartAnimate.FlexChartAnimation />
                    </wjChart.FlexChart>
                  </div>
                </div>
              </div>
              <div className="col-md-4 h-100" id="dashboard-chart">
                <div className="dashboard-chart-container-budget">
                  <div className="row justify-content-between title">
                    <div className="col-10">
                      <p>
                        <b>売上 (セクター別)</b>
                      </p>
                    </div>
                    <div
                      className="col-2"
                      style={{
                        padding: "0",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Link
                        to="/analysis/summary?page=2"
                        onClick={() =>
                          this.state.changeLocation("Analysis | Summary")
                        }
                      >
                        <TbListDetails
                          data-tooltip-content="Analysis Summary"
                          id="analysis-summary"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="dashboard-chart-body">
                    <wjChart.FlexPie
                      bindingName="name"
                      binding="count"
                      itemsSource={salesTotal}
                      selectionMode="Point"
                      selectedItemPosition="Top"
                      selectedItemOffset={0.1}
                      isAnimated={true}
                      startAngle={90}
                      innerRadius={0.3}
                      palette={[
                        "rgb(68,144,196)",
                        "rgb(143,170,220)",
                        "rgb(91,155,213)",
                        "rgb(112,173,71)",
                        "rgb(219,219,219)",
                      ]}
                      style={{ height: "95%" }}
                    >
                      <wjChart.FlexChartLegend position="Right"></wjChart.FlexChartLegend>
                      <wjChart.FlexPieDataLabel
                        content={(ht) => {
                          return `${Math.round(ht.value)}, ${(
                            (ht.value /
                              salesTotal
                                .map((rank) => rank.count)
                                .reduce((sum, cur) => sum + cur)) *
                            100
                          ).toFixed(0)}%`;
                        }}
                        position="Outside"
                      ></wjChart.FlexPieDataLabel>
                      <wjChartAnimate.FlexChartAnimation />
                    </wjChart.FlexPie>
                  </div>
                </div>
              </div>
            </div>
            <div className="sales-charts mt-1">
              <div className="row" style={{ height: "100%" }}>
                <div className="col-lg-3 col-md-12" style={{ height: "100%" }}>
                  <div className="row justify-content-between dashboard-chart-header">
                    <div className="col-10">
                      <p
                        style={{
                          margin: "0",
                          padding: "0",
                          lineHeight: "30px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        <b>Actual Budget Trend by Rank</b>
                      </p>
                    </div>
                    <div
                      className="col-2"
                      style={{
                        padding: "0px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Link
                        to="/analysis/budgetstatus"
                        onClick={() =>
                          this.state.changeLocation("Analysis | Budget Status")
                        }
                      >
                        <TbListDetails
                          data-tooltip-content="Budget Status"
                          id="budgetStatus-detail"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="dashboard-chart-container h-100">
                    <div className="dashboard-chart-body-budget h-100">
                      <wjChart.FlexChart
                        bindingX="name"
                        itemsSource={budgetStatusData}
                        chartType="Column"
                        selectionMode="Series"
                        legendToggle={true}
                        palette={budgetChartPalette}
                      >
                        <wjChart.FlexChartLegend position="Right" />
                        <wjChart.FlexChartSeries
                          name="改善​"
                          binding="stretch"
                        ></wjChart.FlexChartSeries>
                        <wjChart.FlexChartSeries
                          name="ボトム​"
                          binding="bottom"
                        ></wjChart.FlexChartSeries>
                        <wjChart.FlexChartSeries name="見込​" binding="total">
                          <wjChart.FlexChartDataLabel
                            content="{y}"
                            position="Top"
                          />
                        </wjChart.FlexChartSeries>
                        <wjChartAnimate.FlexChartAnimation />
                        <wjChart.FlexChartDataLabel
                          content={"{y}"}
                          position="Bottom"
                        ></wjChart.FlexChartDataLabel>
                      </wjChart.FlexChart>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-md-12" style={{ height: "100%" }}>
                  <div className="row justify-content-between">
                    <div className="col-6" style={{ whiteSpace: "nowrap" }}>
                      <p
                        style={{
                          margin: "0",
                          padding: "0",
                          lineHeight: "30px",
                        }}
                      >
                        <b>Sales Figure by Rank</b>
                      </p>
                    </div>
                    <div className="col-1">
                      <Link
                        to="/analysis/summary?page=1"
                        onClick={() => {
                          this.state.changeLocation("Analysis | Summary");
                          this.state.setSelectedSummary("Sales");
                        }}
                      >
                        <TbListDetails
                          data-tooltip-content="Sales Rank Details"
                          id="salesRank-detail"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="row" style={{ height: "100%" }}>
                    <div
                      className="col-lg-4 col-md-6"
                      style={{ height: "100%" }}
                    >
                      <div
                        className="dashboard-chart-container"
                        style={{ height: "100%" }}
                      >
                        <div className="dashboard-chart-header">
                          <p>
                            <b>売上ランク</b>
                          </p>
                        </div>
                        <div className="dashboard-chart-body h-100">
                          <wjChart.FlexPie
                            bindingName="name"
                            binding="count"
                            itemsSource={salesRanks}
                            selectionMode="Point"
                            selectedItemPosition="Top"
                            isAnimated={true}
                            startAngle={90}
                            palette={["#32CD32", "#FEF250"]}
                            selectedItemOffset={0.1}
                            style={{ height: "95%" }}
                          >
                            <wjChart.FlexChartLegend position="Right" />
                            <wjChart.FlexPieDataLabel
                              content={(ht) => {
                                return `${ht.name}, ${Math.round(ht.value)}, ${(
                                  (ht.value /
                                    salesRanks
                                      .map((rank) => rank.count)
                                      .reduce((sum, cur) => sum + cur)) *
                                  100
                                ).toFixed(0)}%`;
                              }}
                              position="Outside"
                            ></wjChart.FlexPieDataLabel>
                            <wjChartAnimate.FlexChartAnimation />
                          </wjChart.FlexPie>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 col-md-6"
                      style={{ height: "100%" }}
                    >
                      <div
                        className="dashboard-chart-container"
                        style={{ height: "100%" }}
                      >
                        <div className="dashboard-chart-header">
                          <p>
                            <b>A&B ランク</b>
                          </p>
                        </div>
                        <div className="dashboard-chart-body">
                          <wjChart.FlexChart
                            bindingX="name"
                            itemsSource={salesAandB}
                            chartType="Column"
                            stacking="Stacked"
                            selectionMode="Series"
                            legendToggle={true}
                            palette={[
                              "rgb(68,144,196)",
                              "rgb(143,170,220)",
                              "rgb(219,219,219)",
                              "rgb(91,155,213)",
                              "rgb(112,173,71)",
                              "rgb(0,176,80)",
                              "rgb(68,114,196)",
                              "rgb(158,72,14)",
                            ]}
                          >
                            <wjChart.FlexChartLegend position="Right"></wjChart.FlexChartLegend>
                            <wjChart.FlexChartSeries
                              name="ICT"
                              binding="ICT"
                            ></wjChart.FlexChartSeries>
                            <wjChart.FlexChartSeries
                              name="Facilities"
                              binding="Facility"
                            ></wjChart.FlexChartSeries>
                            <wjChart.FlexChartSeries
                              name="Fiber"
                              binding="Fiber"
                            ></wjChart.FlexChartSeries>
                            <wjChart.FlexChartSeries
                              name="DX/BPO"
                              binding="Software"
                            ></wjChart.FlexChartSeries>
                            <wjChart.FlexChartSeries
                              name="日本支店"
                              binding="JP Business"
                            ></wjChart.FlexChartSeries>
                            <wjChart.FlexChartDataLabel
                              content="{y}"
                              position="Bottom"
                            ></wjChart.FlexChartDataLabel>
                            <wjChartAnimate.FlexChartAnimation />
                          </wjChart.FlexChart>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 col-md-6"
                      style={{ height: "100%" }}
                    >
                      <div
                        className="dashboard-chart-container"
                        style={{ height: "100%" }}
                      >
                        <div className="dashboard-chart-header">
                          <p>
                            <b>C&D ランク</b>
                          </p>
                        </div>
                        <div className="dashboard-chart-body">
                          <wjChart.FlexChart
                            bindingX="name"
                            itemsSource={salesCandD}
                            chartType="Column"
                            selectionMode="Series"
                            legendToggle={true}
                            palette={[
                              "rgb(68,144,196)",
                              "rgb(143,170,220)",
                              "rgb(219,219,219)",
                              "rgb(91,155,213)",
                              "rgb(112,173,71)",
                              "rgb(0,176,80)",
                              "rgb(68,114,196)",
                              "rgb(158,72,14)",
                            ]}
                          >
                            <wjChart.FlexChartLegend position="Right"></wjChart.FlexChartLegend>
                            <wjChart.FlexChartSeries
                              name="ICT"
                              binding="ICT"
                            ></wjChart.FlexChartSeries>
                            <wjChart.FlexChartSeries
                              name="Facilities"
                              binding="Facility"
                            ></wjChart.FlexChartSeries>
                            <wjChart.FlexChartSeries
                              name="Fiber"
                              binding="Fiber"
                            ></wjChart.FlexChartSeries>
                            <wjChart.FlexChartSeries
                              name="DX/BPO"
                              binding="Software"
                            ></wjChart.FlexChartSeries>
                            <wjChart.FlexChartSeries
                              name="日本支店"
                              binding="JP Business"
                            ></wjChart.FlexChartSeries>
                            <wjChart.FlexChartDataLabel
                              content="{y}"
                              position="Bottom"
                            ></wjChart.FlexChartDataLabel>
                            <wjChartAnimate.FlexChartAnimation />
                          </wjChart.FlexChart>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {activeTab === tabs[1].name &&
        (fiscalYear.length > 0 ? (
          <FinancialData
            fiscalYearList={fiscalYear}
            changeLocation={changeLocation}
          />
        ) : (
          <div></div>
        ))}
      {/* <DynamicDashboard
          tileCatalog={tileCatalog}
          tiles={tiles}
          setTiles={setTiles}
          state={state}
          dynamicMenuExpend={dynamicMenuExpend}
          setDynamicMenuExpend={setDynamicMenuExpend}
        /> */}
      {/* {isLoadedData && activeTab === tabs[2].name && (
        <DashboardList changeLocation={changeLocation} />
      )} */}
    </div>
  );
}

DashboardFinal.propTypes = {
  changeLocation: PropTypes.func,
};
