import React from "react";
import { BiSearchAlt2 } from "react-icons/bi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import * as wijmo from "@grapecity/wijmo";
import { FlexGrid, FlexGridColumn } from "@grapecity/wijmo.react.grid";
import { FlexGridFilter } from "@grapecity/wijmo.react.grid.filter";
import { DataMap } from "@grapecity/wijmo.grid";
import {
  FISCAL_YEAR_LIST_URL,
  BUSINESS_SECTOR_LIST_URL,
  REPORTCOST_LIST,
  TOKEN,
  REPORTCOST_CREATE_URL,
  REPORTCOST_UPDATE_URL,
} from "../../constants/api.path";
import moment from "moment";
import { Link } from "react-router-dom";
import { AiOutlineUpload } from "react-icons/ai";
import PropTypes from "prop-types";
import InfoModal from "../../modalPopups/infoModal";
import NotiModal from "../../modalPopups/notificationModal";
import { changeMonth } from "../../utilities/utilities";

export default function Costs({ changeLocation }) {
  const tabs = [
    { id: 1, name: "HO", tabKeyword: "HO" },
    { id: 2, name: "JP Branch", tabKeyword: "JP" },
  ];
  const [activeTab, setActiveTab] = React.useState(tabs[0].name);
  const [fiscalYears, setFiscalYears] = React.useState([]);
  const [sectorList, setSectorList] = React.useState([]);
  const [months, setMonths] = React.useState([]);
  const [costsList, setCostsList] = React.useState([]);
  const [displayCostsList, setDisplayCostsList] = React.useState(
    new wijmo.CollectionView([], {
      trackChanges: true,
    })
  );
  const [totalData, setTotalData] = React.useState([]);
  const [showInfo, setShowInfo] = React.useState(false);
  const [infoTitle, setInfoTitle] = React.useState("");
  const [errorMessge, setErrorMessage] = React.useState("");
  const [error, setError] = React.useState(false);
  const [currentFilter, setCurrentFilter] = React.useState({
    year: 0,
    month: 0,
  });
  const [status, setStatus] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [showNoti, setShowNoti] = React.useState(false);
  const [keyword, setKeyword] = React.useState("HO");
  const [initialState, setInitialState] = React.useState(true);

  React.useEffect(() => {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (res) => res.json())
      .then((result) => {
        setFiscalYears(result.data.fiscalYearList);
        setMonths(getMonths());
      });

    fetch(BUSINESS_SECTOR_LIST_URL, {
      method: "GET",
      headers: { Authorization: `Bearer ${TOKEN}` },
    })
      .then(async (res) => res.json())
      .then((result) => setSectorList(result.data.sectorList));
  }, []);

  React.useEffect(() => {
    setCurrentFilter(getCurrentFilter(fiscalYears));
    getData();
  }, [fiscalYears]);

  React.useEffect(() => {
    getData();
  }, [keyword]);

  React.useEffect(() => {}, [currentFilter]);

  function getData(year, month) {
    year = year || currentFilter.year;
    month = month || currentFilter.month;
    if (year === 0) {
      fiscalYears.forEach((fiscalYear) => {
        if (
          new Date().getFullYear() -
            (new Date().getMonth() + 1 > 3 ? 2000 : 2001) ===
          parseInt(fiscalYear.fiscalYear.split("FY")[1])
        )
          year = fiscalYear.fiscalYearId;
      });
    }
    fetch(
      REPORTCOST_LIST +
        "/salesPlanYear/" +
        year +
        "/salesPlanMonth/" +
        month +
        "/keyword/" +
        keyword,
      {
        method: "GET",
        headers: { Authorization: `Bearer ${TOKEN}` },
      }
    )
      .then(async (res) => res.json())
      .then((res) => {
        if (res.data.reportCostList.length > 0) {
          setCostsList(res.data.reportCostList);
          search(res.data.reportCostList);
        } else {
          if (initialState) {
            changeMonth({
              setMonth: setCurrentFilter,
              func: getData,
              year: year,
              month: month,
            });
            setInitialState(false);
          } else getMessage();
        }
        setDisplayCostsList(
          new wijmo.CollectionView(res.data.reportCostList, {
            trackChanges: true,
          })
        );
      });
  }

  function getMessage() {
    let year =
        currentFilter.year === 0
          ? parseInt(getCurrentFilter().year)
          : parseInt(currentFilter.year),
      month =
        currentFilter.month === 0
          ? parseInt(getCurrentFilter().month)
          : parseInt(currentFilter.month);
    let filterList = [
      {
        name: "year",
        value: year,
        state: !isNaN(year),
        valueList: fiscalYears,
        valueListName: "fiscalYearId",
        valueListItem: "fiscalYear",
      },
      {
        name: "month",
        value: month,
        state: !isNaN(month),
        valueList: months,
        valueListName: "value",
        valueListItem: "name",
      },
    ];
    let filtered = [];
    let message = "There is no costs data for ";
    for (let i = 0; i < filterList.length; i++) {
      if (filterList[i].state) {
        if (filterList[i].valueList) {
          for (let j = 0; j < filterList[i].valueList.length; j++) {
            if (
              parseInt(filterList[i].value) ===
              filterList[i].valueList[j][filterList[i].valueListName]
            ) {
              filtered.push(
                filterList[i].valueList[j][filterList[i].valueListItem]
              );
            }
          }
        } else {
          filtered.push(filterList[i].value);
        }
      }
    }
    for (let i = 0; i < filtered.length; i++) {
      if (filtered.length > 1 && filtered.length - i > 2) {
        message += filtered[i] + ", ";
      } else if (filtered.length > 1 && filtered.length - i === 2) {
        message += filtered[i] + " and ";
      } else if (
        (filtered.length > 1 && filtered.length - i === 1) ||
        filtered.length === 1
      ) {
        message += filtered[i] + ".";
      }
    }
    if (filtered.length > 0) setShowInfo(true);
    setInfoTitle(message);
  }

  function getMonths() {
    let data = [];
    for (let i = 4; i < 16; i++) {
      data.push({
        id: i - 3,
        name: moment(i <= 12 ? i : i - 12, "MM").format("MMM"),
        value: i <= 12 ? i : i - 12,
      });
    }
    return data;
  }

  function search(reportCostList) {
    let year =
        currentFilter.year === 0
          ? parseInt(getCurrentFilter().year)
          : parseInt(currentFilter.year),
      month =
        currentFilter.month === 0
          ? parseInt(getCurrentFilter().month)
          : parseInt(currentFilter.month),
      debit = 0,
      credit = 0,
      balance = 0;
    let costs = reportCostList || costsList;
    let data = [];
    if (isNaN(year)) {
      setError(true);
      setErrorMessage("Select Fiscal Year!");
    } else if (isNaN(month)) {
      setError(true);
      setErrorMessage("Select Month!");
    } else {
      setError(false);
      for (let i = 0; i < costs.length; i++) {
        if (
          parseInt(year) === parseInt(costs[i].salesPlanYear) &&
          parseInt(month) === parseInt(costs[i].salesPlanMonth)
        ) {
          data.push(costs[i]);
          debit += parseInt(costs[i].debitCost);
          credit += parseInt(costs[i].creditCost);
          balance += parseInt(costs[i].balance);
        }
      }
      setDisplayCostsList(new wijmo.CollectionView(data), {
        trackChanges: true,
      });
      setTotalData([
        { name: "Total", debit: debit, credit: credit, balance: balance },
      ]);
    }
  }

  function getSector() {
    return sectorList.map((data) => {
      return { id: data.sectorId, name: data.sectorName };
    });
  }

  function initialized(grid) {
    let originalTotal = {
      debitCost: 0,
      creditCost: 0,
      balance: 0,
    };
    let columns = [
      {
        id: 1,
        binding: "debitCost",
        name: "debit",
        originalValue: 0,
      },
      { id: 2, binding: "creditCost", name: "credit", originalValue: 0 },
      { id: 3, binding: "balance", name: "balance", originalValue: 0 },
    ];
    grid.rowEditStarted.addHandler(() => {
      grid.itemsSource.trackChanges = true;
    });
    grid.cellEditEnded.addHandler((s, e) => {
      if (
        s.rows[e.row].dataItem[columns[0].binding] ||
        s.rows[e.row].dataItem[columns[1].binding] ||
        s.rows[e.row].dataItem[columns[2].binding]
      ) {
        originalTotal = {
          debitCost: 0,
          creditCost: 0,
          balance: 0,
        };
        for (let i = 0; i < grid.itemsSource.items.length; i++) {
          originalTotal.debitCost += grid.itemsSource.items[i].debitCost
            ? parseInt(grid.itemsSource.items[i].debitCost.split(",").join(""))
            : 0;
          originalTotal.creditCost += grid.itemsSource.items[i].creditCost
            ? parseInt(grid.itemsSource.items[i].creditCost.split(",").join(""))
            : 0;
          originalTotal.balance += grid.itemsSource.items[i].balance
            ? parseInt(grid.itemsSource.items[i].balance.split(",").join(""))
            : 0;
        }
        for (let i = 0; i < columns.length; i++) {
          columns[i].originalValue = parseInt(
            originalTotal[columns[i].binding]
          );
        }
        setTotalData([
          {
            name: "Total",
            debit: columns[0].originalValue,
            credit: columns[1].originalValue,
            balance: columns[2].originalValue,
          },
        ]);
      }
    });
    grid.rowEditEnded.addHandler(() => {
      // use with grid.itemsSource.itemsAdded ot grid.itemsSource.itemsEdited
      (grid.itemsSource.itemsAdded.length > 0 ||
        grid.itemsSource.itemsEdited.length > 0) &&
        sendCosts({
          added: grid.itemsSource.itemsAdded,
          edited: grid.itemsSource.itemsEdited,
          grid: grid,
        });
      // go back to updated row
      // setUpdatedId(s.rows[e.row].dataItem.reportCostId);
    });
    grid.loadedRows.addHandler((s) => {
      let total = []; // [{name: "Total", debit: totalDebit, credit: totalCredit, balance: totalBalance}]
      let totalDebit = 0,
        totalCredit = 0,
        totalBalance = 0;
      s.rows.forEach((row) => {
        if (row.dataItem) {
          totalDebit += parseFloat(row.dataItem.debitCost);
          totalCredit += parseFloat(row.dataItem.creditCost);
          totalBalance += parseFloat(row.dataItem.balance);
        }
      });
      total.push({
        name: "Total",
        debit: totalDebit,
        credit: totalCredit,
        balance: totalBalance,
      });
      setTotalData(total);
    });

    let costGrid = document.getElementById("cost-grid");
    if (costGrid.scrollHeight > costGrid.clientHeight) {
      costGrid.classList.add("move-scrollbar");
    } else {
      costGrid.classList.remove("move-scrollbar");
    }
  }

  function totalInitialize(grid) {
    grid.select(-1, -1);
    grid.selectionChanging.addHandler((s, e) => {
      e.cancel = true;
    });
  }

  function getCurrentFilter() {
    let filterYear = 0,
      filterMonth = new Date().getMonth() + 1;
    fiscalYears.forEach((fiscalYear) => {
      if (
        new Date().getFullYear() -
          (new Date().getMonth() + 1 > 3 ? 2000 : 2001) ===
        parseInt(fiscalYear.fiscalYear.split("FY")[1])
      )
        filterYear = fiscalYear.fiscalYearId;
    });
    return { year: parseInt(filterYear), month: parseInt(filterMonth) };
  }

  function sendCosts({ added, edited, grid }) {
    let requireKeys = [
      "orderNumber",
      "projectName",
      "sectorId",
      "debitCost",
      "creditCost",
      "balance",
    ];
    let filter = {
      year: document.getElementById("fiscal_year").value,
      month: document.getElementById("month").value,
    };
    let items = [
      {
        id: 1,
        url: REPORTCOST_CREATE_URL,
        value: added,
        method: "POST",
      },
      {
        id: 2,
        url: REPORTCOST_UPDATE_URL + `/{id}`,
        value: edited,
        method: "PUT",
      },
    ];
    let debit = 0,
      credit = 0,
      balance = 0,
      body = {},
      url = "",
      valueList = {},
      proceed = true;
    items.forEach((item) => {
      let data = [];
      item.value.forEach((value) => {
        data.push({
          ...value,
          debitCost: parseFloat(value.debitCost),
          creditCost: parseFloat(value.creditCost),
          balance: parseFloat(value.creditCost),
          companyId: parseInt(value.companyId),
        });
      });
      item.value = data;
    });
    items.forEach((value) => {
      value.value.forEach((item) => {
        proceed = true;
        for (let i in requireKeys) {
          if (item[requireKeys[i]] === undefined) {
            proceed = false;
            break;
          }
        }
        if (proceed) {
          valueList = value;
          if (value.id === 1) {
            url = value.url;
          } else if (value.id === 2) {
            url = value.url.replace("{id}", item.reportCostId);
          }
          debit = value.value[value.value.length - 1].debitCost * 1000000;
          credit = value.value[value.value.length - 1].creditCost * 1000000;
          balance = value.value[value.value.length - 1].balance * 1000000;
          body = {
            orderNumber: value.value[value.value.length - 1].orderNumber,
            projectName: value.value[value.value.length - 1].projectName,
            sectorId: value.value[value.value.length - 1].sectorId,
            debitCost: debit,
            creditCost: credit,
            balance: balance,
          };
        }
      });
    });
    if (proceed) {
      apiCall(url, valueList, body, filter);
      setDisplayCostsList(new wijmo.CollectionView(grid.itemsSource.items));
    }
  }

  function apiCall(url, { method, id }, body, filter) {
    fetch(url, {
      method: method,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
      body:
        id === 2
          ? JSON.stringify(body)
          : JSON.stringify({
              ...body,
              salesPlanMonth: filter.month,
              salesPlanYear: filter.year,
            }),
    })
      .then(async (response) => {
        return response.json();
      })
      .then((data) => {
        data.result ? setStatus("Success") : setStatus("Failed");
        setMessage(data.message);
        toggleNoti();
      });
  }

  function toggleNoti() {
    setShowNoti(!showNoti);
  }

  return (
    <div>
      {showNoti && (
        <NotiModal
          status={status}
          message={message}
          show={showNoti}
          onClose={toggleNoti}
        />
      )}

      <div className="row">
        <div className="col-md-2" style={{ paddingRight: "0px" }}>
          <select
            className="form-select"
            id="fiscal_year"
            value={currentFilter.year}
            onChange={(e) => {
              setCurrentFilter({
                ...currentFilter,
                year: parseInt(e.target.value),
              });
            }}
          >
            <option value="" hidden>
              Sales Plan Year
            </option>
            {fiscalYears.map((fiscalYear) => (
              <option
                key={fiscalYear.fiscalYearId}
                value={fiscalYear.fiscalYearId}
              >
                {fiscalYear.fiscalYear}
              </option>
            ))}
          </select>
        </div>
        <div className="col-md-2" style={{ paddingRight: "0px" }}>
          <select
            className="form-select"
            id="month"
            value={currentFilter.month}
            onChange={(e) => {
              setCurrentFilter({
                ...currentFilter,
                month: parseInt(e.target.value),
              });
            }}
          >
            <option value="" hidden>
              Sales Plan Month
            </option>
            {months.map((month) => (
              <option key={month.id} value={month.value}>
                {month.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-1">
          <button
            id="search"
            data-tooltip-content="Search"
            type="button"
            style={{ margin: "0", padding: "0px" }}
            className="actionIconOutline search bg-blue  "
            onClick={() => {
              getData();
            }}
          >
            <BiSearchAlt2 />
            <ReactTooltip place="bottom" anchorId="search" />
          </button>
        </div>
        <div
          className="col"
          style={{
            textAlign: "right",
            paddingRight: 0,
          }}
        >
          <Link
            to="/costs/import/reportcost"
            onClick={() => {
              changeLocation("Costs | Report Cost");
            }}
          >
            <button
              id="import"
              data-tooltip-content="Import"
              type="button"
              style={{ margin: "0", padding: "0" }}
              className="actionIconOutline import bg-blue"
            >
              <AiOutlineUpload />
              <ReactTooltip anchorId="import" />
            </button>
          </Link>
        </div>
      </div>
      <p className="text-danger m-0">{error && errorMessge}</p>
      <div
        className="row"
        style={{ marginLeft: "0px", marginBottom: "5px", minHeight: "35px" }}
      >
        {tabs.map((tab) => (
          <div
            className="col-2 dashboardTab-parent"
            style={{
              padding: "0.5px",
              minWidth: "fit-content",
            }}
            key={tab.id}
          >
            <button
              type="button"
              className={`dashboardTab ${
                activeTab === tab.name && "dashboardActiveTab"
              }`}
              style={{ paddingLeft: "6px", paddingRight: "6px" }}
              onClick={() => {
                activeTab !== tab.name && setActiveTab(tab.name);
                setKeyword(tab.tabKeyword);
              }}
            >
              {tab.name}
            </button>
          </div>
        ))}
      </div>
      <div className="costs-grid">
        <FlexGrid
          initialized={initialized.bind(this)}
          itemsSource={displayCostsList}
          allowAddNew={true}
          allowDelete={true}
          autoGenerateColumns={false}
          refreshOnEdit={true}
          allowMerging="ColumnHeaders"
          allowSorting={false}
          headersVisibility="Column"
          className="cost-grid"
          id="cost-grid"
        >
          <FlexGridFilter />
          <FlexGridColumn
            header="Order Number"
            width="*"
            binding="orderNumber"
          />
          <FlexGridColumn
            header="Project Name"
            width="4*"
            binding="projectName"
          />
          <FlexGridColumn
            header="Sector"
            width="*"
            binding="sectorId"
            dataMap={new DataMap(getSector(), "id", "name")}
          />
          <FlexGridColumn
            header="Sales Month"
            width="*"
            binding="salesMonth"
            cssClass="alignRight"
          />
          <FlexGridColumn
            header="Sales Rank"
            width="*"
            binding="salesRank"
            cssClass="alignRight"
          />
          <FlexGridColumn
            header="Customer Group"
            width="*"
            binding="customerGroup"
          />
          <FlexGridColumn
            header="Debit Cost"
            width="*"
            binding="debitCost"
            cssClass="alignRight"
            format="f2"
          />
          <FlexGridColumn
            header="Credit Cost"
            width="*"
            binding="creditCost"
            cssClass="alignRight"
            format="n2"
          />
          <FlexGridColumn
            header="Cost Balance"
            width="*"
            binding="balance"
            cssClass="alignRight"
            format="n2"
          />
          <FlexGridColumn header="Currency" width="*" binding="currency" />
        </FlexGrid>
        <FlexGrid
          headersVisibility="None"
          itemsSource={totalData}
          isReadOnly={true}
          initialized={totalInitialize.bind(this)}
          className="cost-total"
        >
          <FlexGridColumn width="9*" binding="name" cssClass="alignCenter" />
          <FlexGridColumn width="*" binding="debit" cssClass="alignRight" />
          <FlexGridColumn width="*" binding="credit" cssClass="alignRight" />
          <FlexGridColumn width="*" binding="balance" cssClass="alignRight" />
          <FlexGridColumn width="*" binding="" />
        </FlexGrid>
      </div>

      <InfoModal
        show={showInfo}
        title={infoTitle}
        onSubmit={() => setShowInfo(false)}
        onHide={() => setShowInfo(false)}
      />
    </div>
  );
}

Costs.propTypes = {
  changeLocation: PropTypes.func.isRequired,
};
