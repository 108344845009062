import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  MultiSelect,
  Select,
  Tabs,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { IconBinaryTree2, IconSettings } from "@tabler/icons-react";
import React from "react";
import PropTypes from "prop-types";

const BarChartEditor = (props) => {
  const { widget, metacolumn, oldDatasource, handleWidgetSettingClose } = props;

  // Provider Items
  const { attributes_names, measures_names } = metacolumn;

  const form = useForm({
    initialValues: {
      title: oldDatasource?.title || "",
      header: oldDatasource?.header || "",
      bindingX: oldDatasource?.configure?.bindingX || "",
      bindingY: oldDatasource?.configure?.bindingY || "",
      chartType: oldDatasource?.settings?.chartType || "barChart",
      legendPosition: oldDatasource?.settings?.legendPosition || "Bottom",
      legendToggle: oldDatasource?.settings?.legendToggle || false,
      dataLabel: oldDatasource?.settings?.dataLabel || "None",
      stacked: oldDatasource?.settings?.stacked || "None",
    },

    // functions will be used to validate values at corresponding key
    validate: {
      title: isNotEmpty("Widget title cannot be empty"),
      header: isNotEmpty("Header cannot be empty"),
      bindingX: isNotEmpty("X-axis cannot be empty"),
      bindingY: isNotEmpty("Y-axis cannot be empty"),
      chartType: isNotEmpty("Chart Type cannot be empty"),
    },
  });

  const handleOnSubmit = (values) => {
    const newDatasource = {
      title: values.title,
      header: values.header,
      type: widget.type,
      configure: {
        bindingX: values.bindingX,
        bindingY: values.bindingY,
      },
      settings: {
        chartType: values.chartType,
        legendPosition: values.legendPosition,
        dataLabel: values.dataLabel,
        legendToggle: values.legendToggle,
        stacked: values.stacked,
        chartAxis: "",
      },
    };
    form.reset();
    handleWidgetSettingClose(newDatasource);
  };

  return (
    <Box miw={220} mx="auto">
      <form
        onSubmit={form.onSubmit((values) => {
          handleOnSubmit(values);
        })}
      >
        <Grid align="center" gutter="xs">
          <Grid.Col span={4}>
            <Text weight={500} size="sm">
              Widget Title
            </Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              placeholder="Widget Title"
              {...form.getInputProps("title")}
            />
          </Grid.Col>
          <Grid.Col span={4}>
            <Text weight={500} size="sm">
              Chart Title
            </Text>
          </Grid.Col>
          <Grid.Col span={8}>
            <TextInput
              placeholder="Chart Title"
              {...form.getInputProps("header")}
            />
          </Grid.Col>
        </Grid>

        <Tabs radius="xs" defaultValue="binding">
          <Tabs.List>
            <Tabs.Tab value="binding" icon={<IconBinaryTree2 size="0.8rem" />}>
              Binding
            </Tabs.Tab>
            <Tabs.Tab
              value="configuration"
              icon={<IconSettings size="0.8rem" />}
            >
              Configuration
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel
            value="binding"
            pt="xs"
            h={"calc(70vh - 40px)"}
            style={{ overflow: "hidden" }}
          >
            <Grid align="center" gutter="xs">
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  X-axis
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  searchable
                  nothingFound="Nothing found"
                  placeholder="Choose X-axis"
                  data={attributes_names}
                  {...form.getInputProps("bindingX")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  Y-axis
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <MultiSelect
                  searchable
                  withAsterisk
                  nothingFound="Nothing found"
                  placeholder="Choose Y-axis"
                  data={measures_names}
                  {...form.getInputProps("bindingY")}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>

          <Tabs.Panel value="configuration" pt="xs" h={"calc(70vh - 40px)"}>
            <Grid align="center" gutter="xs">
              <Grid.Col span={12}>
                <Title order={6}>Display</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  Chart Type
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  placeholder="Chart Type"
                  searchable
                  nothingFound="No Options"
                  data={[
                    { label: "Horizontal", value: "barChart" },
                    { label: "Vertical", value: "barChartVertical" },
                  ]}
                  {...form.getInputProps("chartType")}
                />
              </Grid.Col>

              <Grid.Col span={4}>
                <Text weight={400} size="sm">
                  Stacked
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  placeholder="Choose Stacked"
                  searchable
                  nothingFound="No Options"
                  data={[
                    { label: "None", value: "None" },
                    { label: "Stacked", value: "Stacked" },
                    { label: "100%", value: "Stacked100pc" },
                  ]}
                  {...form.getInputProps("stacked")}
                />
              </Grid.Col>
              <Grid.Col span={12}>
                <Title order={6}>Legend</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  Toggle
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Checkbox
                  checked={form.values.legendToggle}
                  {...form.getInputProps("legendToggle")}
                />
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  Position
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  placeholder="Choose Legend Position"
                  searchable
                  nothingFound="No Options"
                  data={[
                    { label: "None", value: "None" },
                    { label: "Top", value: "Top" },
                    { label: "Bottom", value: "Bottom" },
                    { label: "Left", value: "Left" },
                    { label: "Right", value: "Right" },
                  ]}
                  {...form.getInputProps("legendPosition")}
                />
              </Grid.Col>

              <Grid.Col span={12}>
                <Title order={6}>Data Label</Title>
              </Grid.Col>
              <Grid.Col span={4}>
                <Text weight={500} size="sm">
                  Position
                </Text>
              </Grid.Col>
              <Grid.Col span={8}>
                <Select
                  placeholder="Choose Data Label Position"
                  searchable
                  nothingFound="No Options"
                  data={[
                    { label: "None", value: "None" },
                    { label: "Top", value: "Top" },
                  ]}
                  {...form.getInputProps("dataLabel")}
                />
              </Grid.Col>
            </Grid>
          </Tabs.Panel>
        </Tabs>
        <Divider my={5} variant="dotted" />
        <Flex
          mih={"auto"}
          gap="md"
          justify="flex-end"
          align="center"
          direction="row"
          wrap="wrap"
        >
          <Button type="submit" color="indigo">
            {oldDatasource ? "Update" : "Add"}
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default BarChartEditor;

BarChartEditor.propTypes = {
  widget: PropTypes.any,
  metacolumn: PropTypes.any,
  oldDatasource: PropTypes.any,
  handleWidgetSettingClose: PropTypes.any,
};
