import "bootstrap/dist/css/bootstrap.css";
import "@grapecity/wijmo.styles/wijmo.css";
import { BiZoomIn, BiZoomOut } from "react-icons/bi";
import * as wjcCore from "@grapecity/wijmo";
import * as React from "react";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wijmo from "@grapecity/wijmo";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import "../../assets/css/bcAnnual.css";
import moment from "moment/moment";
import {
  BC_ANNUAL_LIST,
  FISCAL_YEAR_LIST_URL,
  TOKEN,
  ANNUAL_HIDE_COLUMN,
} from "../../constants/api.path";
import { Spinner } from "reactstrap";
import {
  hideRowDefault,
  hideColumnDefault,
  showColumnDefault,
} from "./hideDefaultRowColumn";
import { BiSearchAlt2 } from "react-icons/bi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import cacheUpdate from "../cacheUpdate/cacheUpdate";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

class BCAnnualMMK extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flex: null,
      isFrozen: false,
      isLoading: true,
      hideRows: [],
      hideColumns: [],
      data: [],
      compName: "",
      lastDate: "",
      sectorList: [],
      selectedArray: { salesPlanMonth: new Date().getMonth() + 1 },
      currentYear: new Date().getFullYear(),
      fiscalList: [],
      monthList: [],
      salesPlanYearName: [],
      salesPlanMonthName: [],
      decimalPlaces: 0,
    };
  }

  getBCAnnual() {
    fetch(
      BC_ANNUAL_LIST +
        "/salesPlanYear/" +
        this.state.selectedArray.salesPlanYear +
        "/salesPlanMonth/" +
        this.state.selectedArray.salesPlanMonth,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    )
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ isLoading: false });
        if (result.result) {
          this.setState({
            lastDate: result.lastDate,
            compName: result.data.bcAnnualList[0].companyName,
            sectorList: result.data.businessSector,
          },() => {
            cacheUpdate(
              this.state.selectedArray.salesPlanYear,
              this.state.selectedArray.salesPlanMonth
            );
          }
          );
          this.mergeSectorCell();
          this.bcAnnualDataBinding(
            result.lastDate,
            result.data.bcAnnualList,
            result.data.businessSector
          );
        }
      });
  }

  handleChange(e) {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;
    const selectedValue = { ...this.state.selectedArray };
    selectedValue[fieldName] = fieldValue;

    this.setState({ selectedArray: selectedValue }, () => {
      this.salesPlanMonthUpdate(this.state.selectedArray.salesPlanYear);
    });
  }

  componentDidMount() {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState(
          {
            selectedArray: {
              salesPlanYear: result.data.currentYearId,
              ...this.state.selectedArray,
            },
            fiscalList: result.data.fiscalYearList,
          },
          () => {
            this.getBCAnnual();
            this.salesPlanMonthUpdate(this.state.selectedArray.salesPlanYear);
            this.getYearMonthName();
          }
        );
      });

    fetch(ANNUAL_HIDE_COLUMN, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        if (result.result) {
          this.setState({ data: result.data.annualQuarter });
        }
      });
  }

  salesPlanMonthUpdate(id) {
    let key = 0;
    let data = [];
    let currentID = id ? parseInt(id) : this.state.selectedArray.salesPlanYear;
    this.state.fiscalList.forEach((fiscalList) => {
      if (fiscalList.fiscalYearId === currentID) {
        for (
          let i = new Date(fiscalList.fyStartDate).getMonth();
          i < new Date(fiscalList.fyStartDate).getMonth() + 12;
          i++
        ) {
          data.push({
            id: key + 1,
            name: moment()
              .month(i < 12 ? i : i - 12)
              .format("MMM"),
            value: i + 1 < 13 ? i + 1 : i - 11,
          });
          key++;
        }
        this.setState({ monthList: data });
      }
    });
  }

  getYearMonthName() {
    this.state.fiscalList.forEach((fiscalList) => {
      if (this.state.selectedArray.salesPlanYear == fiscalList.fiscalYearId) {
        this.setState({
          salesPlanYearName: fiscalList.fiscalYear,
          salesPlanMonthName: moment()
            .month(this.state.selectedArray.salesPlanMonth - 1)
            .format("MMM"),
        });
      }
    });
  }

  mergeSectorCell() {
    let contentStartRow = 26;
    for (let row = 0; row < this.state.sectorList.length / 23; row++) {
      this.setBCContent(this.state.flex, contentStartRow);
      contentStartRow += 23;
    }
  }

  bcAnnualDataBinding(lastDate, bcAnnualList, sectorList) {
    let flex = this.state.flex;
    flex.selectedSheetIndex = 0;

    flex.setCellData(1, 9, "vs (" + lastDate + ")");
    flex.setCellData(1, 18, "vs (" + lastDate + ")");
    flex.setCellData(1, 27, "vs (" + lastDate + ")");
    flex.setCellData(1, 36, "vs (" + lastDate + ")");
    flex.setCellData(1, 45, "vs (" + lastDate + ")");
    flex.setCellData(1, 54, "vs (" + lastDate + ")");
    flex.setCellData(1, 63, "vs (" + lastDate + ")");

    let r = 3;
    for (let row = 0; row < bcAnnualList.length; row++) {
      if (
        (row % 10 === 0 && row / 10 === 1) ||
        (row % 15 === 0 && row / 15 === 1) ||
        (row % 19 === 0 && row / 19 === 1)
      ) {
        flex.setCellData(r, 0, "Total of " + bcAnnualList[row].companyName);
        flex.setCellData(
          r,
          4,
          (bcAnnualList[row].draftBottom1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          5,
          (bcAnnualList[row].draftStretch1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          6,
          (bcAnnualList[row].draftTotal1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          7,
          (bcAnnualList[row].budBudget1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          8,
          (bcAnnualList[row].budVariance1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          9,
          (bcAnnualList[row].preBudget1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          10,
          (bcAnnualList[row].preVariance1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          11,
          (bcAnnualList[row].lastYearActual1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          12,
          (bcAnnualList[row].lastYearVariance1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          13,
          (bcAnnualList[row].draftBottom2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          14,
          (bcAnnualList[row].draftStretch2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          15,
          (bcAnnualList[row].draftTotal2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          16,
          (bcAnnualList[row].budBudget2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          17,
          (bcAnnualList[row].budVariance2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          18,
          (bcAnnualList[row].preBudget2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          19,
          (bcAnnualList[row].preVariance2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          20,
          (bcAnnualList[row].lastYearActual2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          21,
          (bcAnnualList[row].lastYearVariance2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          22,
          (bcAnnualList[row].draftBottom1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          23,
          (bcAnnualList[row].draftStretch1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          24,
          (bcAnnualList[row].draftTotal1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          25,
          (bcAnnualList[row].budBudget1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          26,
          (bcAnnualList[row].budVariance1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          27,
          (bcAnnualList[row].preBudget1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          28,
          (bcAnnualList[row].preVariance1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          29,
          (bcAnnualList[row].lastYearActual1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          30,
          (bcAnnualList[row].lastYearVariance1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          31,
          (bcAnnualList[row].draftBottom3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          32,
          (bcAnnualList[row].draftStretch3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          33,
          (bcAnnualList[row].draftTotal3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          34,
          (bcAnnualList[row].budBudget3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          35,
          (bcAnnualList[row].budVariance3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          36,
          (bcAnnualList[row].preBudget3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          37,
          (bcAnnualList[row].preVariance3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          38,
          (bcAnnualList[row].lastYearActual3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          39,
          (bcAnnualList[row].lastYearVariance3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          40,
          (bcAnnualList[row].draftBottom4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          41,
          (bcAnnualList[row].draftStretch4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          42,
          (bcAnnualList[row].draftTotal4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          43,
          (bcAnnualList[row].budBudget4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          44,
          (bcAnnualList[row].budVariance4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          45,
          (bcAnnualList[row].preBudget4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          46,
          (bcAnnualList[row].preVariance4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          47,
          (bcAnnualList[row].lastYearActual4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          48,
          (bcAnnualList[row].lastYearVariance4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          49,
          (bcAnnualList[row].draftBottom2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          50,
          (bcAnnualList[row].draftStretch2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          51,
          (bcAnnualList[row].draftTotal2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          52,
          (bcAnnualList[row].budBudget2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          53,
          (bcAnnualList[row].budVariance2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          54,
          (bcAnnualList[row].preBudget2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          55,
          (bcAnnualList[row].preVariance2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          56,
          (bcAnnualList[row].lastYearActual2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          57,
          (bcAnnualList[row].lastYearVariance2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          58,
          (bcAnnualList[row].draftBottomAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          59,
          (bcAnnualList[row].draftStretchAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          60,
          (bcAnnualList[row].draftTotalAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          61,
          (bcAnnualList[row].budBudgetAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          62,
          (bcAnnualList[row].budVarianceAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          63,
          (bcAnnualList[row].preBudgetAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          64,
          (bcAnnualList[row].preVarianceAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          65,
          (bcAnnualList[row].lastYearActualAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          66,
          (bcAnnualList[row].lastYearVarianceAnnual * 100).toFixed(1) + "%"
        );
      } else {
        flex.setCellData(r, 0, "Total of " + bcAnnualList[row].companyName);
        flex.setCellData(
          r,
          4,
          bcAnnualList[row].draftBottom1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          5,
          bcAnnualList[row].draftStretch1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          6,
          bcAnnualList[row].draftTotal1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          7,
          bcAnnualList[row].budBudget1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          8,
          bcAnnualList[row].budVariance1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          9,
          bcAnnualList[row].preBudget1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          10,
          bcAnnualList[row].preVariance1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          11,
          bcAnnualList[row].lastYearActual1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          12,
          bcAnnualList[row].lastYearVariance1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          13,
          bcAnnualList[row].draftBottom2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          14,
          bcAnnualList[row].draftStretch2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          15,
          bcAnnualList[row].draftTotal2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          16,
          bcAnnualList[row].budBudget2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          17,
          bcAnnualList[row].budVariance2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          18,
          bcAnnualList[row].preBudget2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          19,
          bcAnnualList[row].preVariance2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          20,
          bcAnnualList[row].lastYearActual2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          21,
          bcAnnualList[row].lastYearVariance2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          22,
          bcAnnualList[row].draftBottom1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          23,
          bcAnnualList[row].draftStretch1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          24,
          bcAnnualList[row].draftTotal1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          25,
          bcAnnualList[row].budBudget1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          26,
          bcAnnualList[row].budVariance1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          27,
          bcAnnualList[row].preBudget1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          28,
          bcAnnualList[row].preVariance1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          29,
          bcAnnualList[row].lastYearActual1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          30,
          bcAnnualList[row].lastYearVariance1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          31,
          bcAnnualList[row].draftBottom3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          32,
          bcAnnualList[row].draftStretch3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          33,
          bcAnnualList[row].draftTotal3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          34,
          bcAnnualList[row].budBudget3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          35,
          bcAnnualList[row].budVariance3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          36,
          bcAnnualList[row].preBudget3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          37,
          bcAnnualList[row].preVariance3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          38,
          bcAnnualList[row].lastYearActual3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          39,
          bcAnnualList[row].lastYearVariance3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          40,
          bcAnnualList[row].draftBottom4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          41,
          bcAnnualList[row].draftStretch4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          42,
          bcAnnualList[row].draftTotal4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          43,
          bcAnnualList[row].budBudget4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          44,
          bcAnnualList[row].budVariance4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          45,
          bcAnnualList[row].preBudget4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          46,
          bcAnnualList[row].preVariance4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          47,
          bcAnnualList[row].lastYearActual4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          48,
          bcAnnualList[row].lastYearVariance4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          49,
          bcAnnualList[row].draftBottom2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          50,
          bcAnnualList[row].draftStretch2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          51,
          bcAnnualList[row].draftTotal2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          52,
          bcAnnualList[row].budBudget2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          53,
          bcAnnualList[row].budVariance2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          54,
          bcAnnualList[row].preBudget2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          55,
          bcAnnualList[row].preVariance2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          56,
          bcAnnualList[row].lastYearActual2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          57,
          bcAnnualList[row].lastYearVariance2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          58,
          bcAnnualList[row].draftBottomAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          59,
          bcAnnualList[row].draftStretchAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          60,
          bcAnnualList[row].draftTotalAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          61,
          bcAnnualList[row].budBudgetAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          62,
          bcAnnualList[row].budVarianceAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          63,
          bcAnnualList[row].preBudgetAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          64,
          bcAnnualList[row].preVarianceAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          65,
          bcAnnualList[row].lastYearActualAnnual.toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          r,
          66,
          bcAnnualList[row].lastYearVarianceAnnual.toFixed(
            this.state.decimalPlaces
          )
        );
      }
      r++;
    }
    let b = 26;
    let contentStartRow = 26;
    for (let row = 0; row < sectorList.length / 23; row++) {
      this.setBCContent(flex, contentStartRow);
      contentStartRow += 23;
    }
    for (let row = 0; row < sectorList.length; row++) {
      if (
        ((row % 23) % 10 === 0 && (row % 23) / 10 === 1) ||
        ((row % 23) % 15 === 0 && (row % 23) / 15 === 1) ||
        ((row % 23) % 19 === 0 && (row % 23) / 19 === 1)
      ) {
        flex.setCellData(b, 0, sectorList[row].sectorName);
        flex.setCellData(
          b,
          4,
          (sectorList[row].draftBottom1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          5,
          (sectorList[row].draftStretch1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          6,
          (sectorList[row].draftTotal1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          7,
          (sectorList[row].budBudget1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          8,
          (sectorList[row].budVariance1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          9,
          (sectorList[row].preBudget1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          10,
          (sectorList[row].preVariance1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          11,
          (sectorList[row].lastYearActual1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          12,
          (sectorList[row].lastYearVariance1Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          13,
          (sectorList[row].draftBottom2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          14,
          (sectorList[row].draftStretch2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          15,
          (sectorList[row].draftTotal2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          16,
          (sectorList[row].budBudget2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          17,
          (sectorList[row].budVariance2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          18,
          (sectorList[row].preBudget2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          19,
          (sectorList[row].preVariance2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          20,
          (sectorList[row].lastYearActual2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          21,
          (sectorList[row].lastYearVariance2Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          22,
          (sectorList[row].draftBottom1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          23,
          (sectorList[row].draftStretch1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          24,
          (sectorList[row].draftTotal1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          25,
          (sectorList[row].budBudget1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          26,
          (sectorList[row].budVariance1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          27,
          (sectorList[row].preBudget1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          28,
          (sectorList[row].preVariance1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          29,
          (sectorList[row].lastYearActual1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          30,
          (sectorList[row].lastYearVariance1H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          31,
          (sectorList[row].draftBottom3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          32,
          (sectorList[row].draftStretch3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          33,
          (sectorList[row].draftTotal3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          34,
          (sectorList[row].budBudget3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          35,
          (sectorList[row].budVariance3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          36,
          (sectorList[row].preBudget3Q8100 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          37,
          (sectorList[row].preVariance3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          38,
          (sectorList[row].lastYearActual3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          39,
          (sectorList[row].lastYearVariance3Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          40,
          (sectorList[row].draftBottom4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          41,
          (sectorList[row].draftStretch4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          42,
          (sectorList[row].draftTotal4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          43,
          (sectorList[row].budBudget4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          44,
          (sectorList[row].budVariance4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          45,
          (sectorList[row].preBudget4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          46,
          (sectorList[row].preVariance4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          47,
          (sectorList[row].lastYearActual4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          48,
          (sectorList[row].lastYearVariance4Q * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          49,
          (sectorList[row].draftBottom2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          50,
          (sectorList[row].draftStretch2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          51,
          (sectorList[row].draftTotal2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          52,
          (sectorList[row].budBudget2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          53,
          (sectorList[row].budVariance2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          54,
          (sectorList[row].preBudget2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          55,
          (sectorList[row].preVariance2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          56,
          (sectorList[row].lastYearActual2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          57,
          (sectorList[row].lastYearVariance2H * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          58,
          (sectorList[row].draftBottomAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          59,
          (sectorList[row].draftStretchAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          60,
          (sectorList[row].draftTotalAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          61,
          (sectorList[row].budBudgetAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          62,
          (sectorList[row].budVarianceAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          63,
          (sectorList[row].preBudgetAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          64,
          (sectorList[row].preVarianceAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          65,
          (sectorList[row].lastYearActualAnnual * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          b,
          66,
          (sectorList[row].lastYearVarianceAnnual * 100).toFixed(1) + "%"
        );
      } else {
        flex.setCellData(b, 0, sectorList[row].sectorName);
        flex.setCellData(
          b,
          4,
          sectorList[row].draftBottom1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          5,
          sectorList[row].draftStretch1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          6,
          sectorList[row].draftTotal1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          7,
          sectorList[row].budBudget1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          8,
          sectorList[row].budVariance1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          9,
          sectorList[row].preBudget1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          10,
          sectorList[row].preVariance1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          11,
          sectorList[row].lastYearActual1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          12,
          sectorList[row].lastYearVariance1Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          13,
          sectorList[row].draftBottom2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          14,
          sectorList[row].draftStretch2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          15,
          sectorList[row].draftTotal2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          16,
          sectorList[row].budBudget2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          17,
          sectorList[row].budVariance2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          18,
          sectorList[row].preBudget2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          19,
          sectorList[row].preVariance2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          20,
          sectorList[row].lastYearActual2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          21,
          sectorList[row].lastYearVariance2Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          22,
          sectorList[row].draftBottom1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          23,
          sectorList[row].draftStretch1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          24,
          sectorList[row].draftTotal1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          25,
          sectorList[row].budBudget1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          26,
          sectorList[row].budVariance1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          27,
          sectorList[row].preBudget1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          28,
          sectorList[row].preVariance1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          29,
          sectorList[row].lastYearActual1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          30,
          sectorList[row].lastYearVariance1H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          31,
          sectorList[row].draftBottom3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          32,
          sectorList[row].draftStretch3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          33,
          sectorList[row].draftTotal3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          34,
          sectorList[row].budBudget3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          35,
          sectorList[row].budVariance3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          36,
          sectorList[row].preBudget3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          37,
          sectorList[row].preVariance3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          38,
          sectorList[row].lastYearActual3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          39,
          sectorList[row].lastYearVariance3Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          40,
          sectorList[row].draftBottom4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          41,
          sectorList[row].draftStretch4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          42,
          sectorList[row].draftTotal4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          43,
          sectorList[row].budBudget4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          44,
          sectorList[row].budVariance4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          45,
          sectorList[row].preBudget4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          46,
          sectorList[row].preVariance4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          47,
          sectorList[row].lastYearActual4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          48,
          sectorList[row].lastYearVariance4Q.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          49,
          sectorList[row].draftBottom2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          50,
          sectorList[row].draftStretch2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          51,
          sectorList[row].draftTotal2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          52,
          sectorList[row].budBudget2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          53,
          sectorList[row].budVariance2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          54,
          sectorList[row].preBudget2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          55,
          sectorList[row].preVariance2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          56,
          sectorList[row].lastYearActual2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          57,
          sectorList[row].lastYearVariance2H.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          58,
          sectorList[row].draftBottomAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          59,
          sectorList[row].draftStretchAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          60,
          sectorList[row].draftTotalAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          61,
          sectorList[row].budBudgetAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          62,
          sectorList[row].budVarianceAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          63,
          sectorList[row].preBudgetAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          64,
          sectorList[row].preVarianceAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          65,
          sectorList[row].lastYearActualAnnual.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          b,
          66,
          sectorList[row].lastYearVarianceAnnual.toFixed(
            this.state.decimalPlaces
          )
        );
      }
      b++;
    }

    // hide rows by default
    let initialHideRows = hideRowDefault(bcAnnualList, sectorList, flex);
    this.setState({
      hideRows: [...this.state.hideRows, ...initialHideRows],
    });
  }

  render() {
    return (
      <div
        className="container-fluid bcAnnual"
        style={{ maxHeight: "calc(100vh - 38px)", overflow: "hidden" }}
      >
        {this.state.isLoading && (
          <div className="loader">
            <Spinner color="white" />
          </div>
        )}
        <div className="row" style={{ margin: "0" }}>
          <div className="col-md-8 button">
            <p>
              <b>
                <u>
                  {this.state.currentYear} Budget for APR-MAR <br></br>
                  {this.state.compName} Company <br></br>
                  Last Year {this.state.lastDate}
                </u>
              </b>
            </p>
          </div>
        </div>
        <div
          className="row justify-content-between"
          style={{ marginLeft: "12px" }}
        >
          <div className="col " style={{ padding: "1.5px" }}>
            <div className="row">
              <div className="col-auto" style={{ padding: "1.5px" }}>
                <select
                  name="salesPlanYear"
                  id="salesPlanYear"
                  data-tooltip-content="Fiscal Year"
                  className="form-select"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={this.state.selectedArray.salesPlanYear}
                >
                  <option key={0} value="" hidden>
                    Fiscal Year
                  </option>
                  {this.state.fiscalList.map((item) => (
                    <option key={item.fiscalYearId} value={item.fiscalYearId}>
                      {item.fiscalYear}
                    </option>
                  ))}
                </select>
                <ReactTooltip place="top" anchorId="salesPlanYear" />
              </div>
              <div className="col-auto" style={{ padding: "1.5px" }}>
                <select
                  name="salesPlanMonth"
                  id="salesPlanMonth"
                  data-tooltip-content="Month"
                  className="form-select"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={this.state.selectedArray.salesPlanMonth}
                >
                  <option value="" hidden>
                    Month
                  </option>
                  {this.state.monthList.map((month) => (
                    <option key={month.id} value={month.value}>
                      {month.name}
                    </option>
                  ))}
                </select>
                <ReactTooltip place="top" anchorId="salesPlanMonth" />
              </div>
              <div className=" col-auto " style={{ padding: "1.5px" }}>
                <button
                  id="search"
                  data-tooltip-content="Search"
                  type="button"
                  style={{ margin: "0", padding: "0px" }}
                  className="actionIconOutline search bg-blue  "
                  onClick={() => {
                    this.setState({ isLoading: true });
                    this.getYearMonthName();
                    this.getBCAnnual();
                  }}
                >
                  <BiSearchAlt2 />
                  <ReactTooltip place="top" anchorId="search" />
                </button>
              </div>
              <div className="col-auto" style={{ padding: "1.5px" }}>
                <select
                  name="decimalPlaces"
                  id="decimalPlaces"
                  className="form-select"
                  onChange={(e) => {
                    this.setState({ decimalPlaces: e.target.value }, () => {
                      this.getBCAnnual();
                    });
                  }}
                  value={this.state.decimalPlaces}
                >
                  <option key={0} value={0}>
                    0
                  </option>
                  <option key={1} value={1}>
                    1
                  </option>
                  <option key={2} value={2}>
                    2
                  </option>
                  <option key={3} value={3}>
                    3
                  </option>
                  <option key={4} value={4}>
                    4
                  </option>
                  <option key={5} value={5}>
                    5
                  </option>
                  <option key={6} value={6}>
                    6
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="col" style={{ padding: "1.5px" }}>
            <div className="row justify-content-end">
              <div className="col" style={{ padding: "1.5px" }}>
                <button
                  type="button"
                  className="actionTextBtn"
                  value="Show"
                  onClick={() => this.showRows(this.state.flex)}
                >
                  Show Rows
                </button>
              </div>
              <div className="col" style={{ padding: "1.5px" }}>
                <button
                  type="button"
                  className="actionTextBtn"
                  value="Hide"
                  onClick={() => this.hideRows(this.state.flex)}
                >
                  Hide Rows
                </button>
              </div>
              <div className="col" style={{ padding: "1.5px" }}>
                <button
                  type="button"
                  className="actionTextBtn"
                  value="Show"
                  onClick={() => this.showColumns(this.state.flex)}
                >
                  Show Columns
                </button>
              </div>
              <div className="col" style={{ padding: "1.5px" }}>
                <button
                  type="button"
                  className="actionTextBtn w-200"
                  value="Hide"
                  onClick={() => this.hideColumns(this.state.flex)}
                >
                  Hide Columns
                </button>
              </div>
              <div className="col" style={{ padding: "1.5px" }}>
                <button
                  type="button"
                  className="actionTextBtn"
                  onClick={this.freezeCells.bind(this)}
                >
                  {this.state.isFrozen ? "UnFreeze" : "Freeze"}
                </button>
              </div>
              <div className="col-auto" style={{ padding: "1.5px" }}>
                <button
                  id="ZoomIn"
                  data-tooltip-content="ZoomIn"
                  type="button"
                  className=" actionIconOutline2 bg-grey "
                  onClick={() => this.zoomIn()}
                  style={{
                    width: "30px",
                    height: "30px",
                    border: "none",
                    borderRadius: "5px",
                  }}
                >
                  <BiZoomIn className="icon" />
                  <ReactTooltip anchorId="ZoomIn" />
                </button>
              </div>
              <div className="col-auto" style={{ padding: "1.5px" }}>
                <button
                  id="ZoomOut"
                  data-tooltip-content="ZoomOut"
                  type="button"
                  className="actionIconOutline2 bg-grey "
                  onClick={() => this.zoomOut()}
                  style={{
                    width: "30px",
                    height: "30px",
                    border: "none",
                    borderRadius: "5px",
                  }}
                >
                  <BiZoomOut className="icon" />
                  <ReactTooltip anchorId="ZoomOut" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <wjGridSheet.FlexSheet
          initialized={this.initializeFlexSheet.bind(this)}
          isReadOnly={true}
          id="bcAnnualSheet"
          style={{
            height: "500px",
          }}
        >
          <wjGridSheet.Sheet
            name="BC Annual"
            columnCount={67}
          ></wjGridSheet.Sheet>
        </wjGridSheet.FlexSheet>
      </div>
    );
  }
  initializeFlexSheet(flex) {
    flex.deferUpdate(() => {
      this._generateBasicOperatorsSheet(flex);
    });
  }

  _generateBasicOperatorsSheet(flexSheet) {
    flexSheet.setCellData(0, 0, " ");

    flexSheet.setCellData(0, 4, "1Q( APR-JUN )");
    flexSheet.setCellData(1, 4, "Draft Budget");
    flexSheet.setCellData(1, 7, "vs Budget");
    flexSheet.setCellData(1, 11, "vs Last Year");
    flexSheet.setCellData(2, 4, "Bottom");
    flexSheet.setCellData(2, 5, "Stretch");
    flexSheet.setCellData(2, 6, "Total");
    flexSheet.setCellData(2, 7, "Budget");
    flexSheet.setCellData(2, 8, "Variance");
    flexSheet.setCellData(2, 9, "Previous");
    flexSheet.setCellData(2, 10, "Variance");
    flexSheet.setCellData(2, 11, "Actual");
    flexSheet.setCellData(2, 12, "Variance");

    flexSheet.setCellData(0, 13, "2Q( JUL-SEP )");
    flexSheet.setCellData(1, 13, "Draft Budget");
    flexSheet.setCellData(1, 16, "vs Budget");
    flexSheet.setCellData(1, 20, "vs Last Year");
    flexSheet.setCellData(2, 13, "Bottom");
    flexSheet.setCellData(2, 14, "Stretch");
    flexSheet.setCellData(2, 15, "Total");
    flexSheet.setCellData(2, 16, "Budget");
    flexSheet.setCellData(2, 17, "Variance");
    flexSheet.setCellData(2, 18, "Previous");
    flexSheet.setCellData(2, 19, "Variance");
    flexSheet.setCellData(2, 20, "Actual");
    flexSheet.setCellData(2, 21, "Variance");

    flexSheet.setCellData(0, 22, "1H( APR-SEP )");
    flexSheet.setCellData(1, 22, "Draft Budget");
    flexSheet.setCellData(1, 25, "vs Budget");
    flexSheet.setCellData(1, 29, "vs Last Year");
    flexSheet.setCellData(2, 22, "Bottom");
    flexSheet.setCellData(2, 23, "Stretch");
    flexSheet.setCellData(2, 24, "Total");
    flexSheet.setCellData(2, 25, "Budget");
    flexSheet.setCellData(2, 26, "Variance");
    flexSheet.setCellData(2, 27, "Previous");
    flexSheet.setCellData(2, 28, "Variance");
    flexSheet.setCellData(2, 29, "Actual");
    flexSheet.setCellData(2, 30, "Variance");

    flexSheet.setCellData(0, 31, "3Q( OCT-DEC )");
    flexSheet.setCellData(1, 31, "Draft Budget");
    flexSheet.setCellData(1, 34, "vs Budget");
    flexSheet.setCellData(1, 38, "vs Last Year");
    flexSheet.setCellData(2, 31, "Bottom");
    flexSheet.setCellData(2, 32, "Stretch");
    flexSheet.setCellData(2, 33, "Total");
    flexSheet.setCellData(2, 34, "Budget");
    flexSheet.setCellData(2, 35, "Variance");
    flexSheet.setCellData(2, 36, "Previous");
    flexSheet.setCellData(2, 37, "Variance");
    flexSheet.setCellData(2, 38, "Actual");
    flexSheet.setCellData(2, 39, "Variance");

    flexSheet.setCellData(0, 40, "4Q( JAN-MAR )");
    flexSheet.setCellData(1, 40, "Draft Budget");
    flexSheet.setCellData(1, 43, "vs Budget");
    flexSheet.setCellData(1, 47, "vs Last Year");
    flexSheet.setCellData(2, 40, "Bottom");
    flexSheet.setCellData(2, 41, "Stretch");
    flexSheet.setCellData(2, 42, "Total");
    flexSheet.setCellData(2, 43, "Budget");
    flexSheet.setCellData(2, 44, "Variance");
    flexSheet.setCellData(2, 45, "Previous");
    flexSheet.setCellData(2, 46, "Variance");
    flexSheet.setCellData(2, 47, "Actual");
    flexSheet.setCellData(2, 48, "Variance");

    flexSheet.setCellData(0, 49, "2H( OCT-MAR )");
    flexSheet.setCellData(1, 49, "Draft Budget");
    flexSheet.setCellData(1, 52, "vs Budget");
    flexSheet.setCellData(1, 56, "vs Last Year");
    flexSheet.setCellData(2, 49, "Bottom");
    flexSheet.setCellData(2, 50, "Stretch");
    flexSheet.setCellData(2, 51, "Total");
    flexSheet.setCellData(2, 52, "Budget");
    flexSheet.setCellData(2, 53, "Variance");
    flexSheet.setCellData(2, 54, "Previous");
    flexSheet.setCellData(2, 55, "Variance");
    flexSheet.setCellData(2, 56, "Actual");
    flexSheet.setCellData(2, 57, "Variance");

    flexSheet.setCellData(0, 58, "Annual( APR-MAR )");
    flexSheet.setCellData(1, 58, "Draft Budget");
    flexSheet.setCellData(1, 61, "vs Budget");
    flexSheet.setCellData(1, 65, "vs Last Year");
    flexSheet.setCellData(2, 58, "Bottom");
    flexSheet.setCellData(2, 59, "Stretch");
    flexSheet.setCellData(2, 60, "Total");
    flexSheet.setCellData(2, 61, "Budget");
    flexSheet.setCellData(2, 62, "Variance");
    flexSheet.setCellData(2, 63, "Previous");
    flexSheet.setCellData(2, 64, "Variance");
    flexSheet.setCellData(2, 65, "Actual");
    flexSheet.setCellData(2, 66, "Variance");

    this.setBCContent(flexSheet, 3);

    flexSheet.applyCellsStyle(
      {
        background: "#00ff00",
      },
      [new wjcGrid.CellRange(0, 4, 0, 12), new wjcGrid.CellRange(0, 31, 0, 39)]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#ff99cc",
      },
      [new wjcGrid.CellRange(0, 13, 0, 21), new wjcGrid.CellRange(0, 40, 0, 48)]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#0000ff",
        color: "white",
      },
      [new wjcGrid.CellRange(0, 22, 0, 30), new wjcGrid.CellRange(0, 49, 0, 57)]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#ffc000",
      },
      [new wjcGrid.CellRange(0, 58, 0, 66)]
    );
    flexSheet.applyCellsStyle({ minWidth: "fit-content" }, [
      new wjcGrid.CellRange(1, 6),
    ]);

    flexSheet.mergeRange(new wjcGrid.CellRange(0, 0, 2, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 4, 0, 12));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 13, 0, 21));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 22, 0, 30));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 31, 0, 39));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 40, 0, 48));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 49, 0, 57));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 58, 0, 66));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 4, 1, 6));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 7, 1, 8));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 9, 1, 10));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 11, 1, 12));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 13, 1, 15));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 16, 1, 17));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 18, 1, 19));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 20, 1, 21));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 22, 1, 24));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 25, 1, 26));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 27, 1, 28));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 29, 1, 30));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 31, 1, 33));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 34, 1, 35));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 36, 1, 37));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 38, 1, 39));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 40, 1, 42));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 43, 1, 44));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 45, 1, 46));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 47, 1, 48));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 49, 1, 51));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 52, 1, 53));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 54, 1, 55));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 56, 1, 57));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 58, 1, 60));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 61, 1, 62));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 63, 1, 64));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 65, 1, 66));

    flexSheet.selectedSheetChanged.addHandler(() => {
      if (flexSheet.frozenColumns > 0 || flexSheet.frozenRows > 0) {
        this.setState({
          isFrozen: true,
        });
      } else {
        this.setState({
          isFrozen: false,
        });
      }
    });
    this.setState({
      flex: flexSheet,
    });

    for (let col = 0; col < 67; col++) {
      flexSheet.columns[col].width = "*";
      flexSheet.columns[col].minWidth = 52;
    }
  }
  setBCContent(flexSheet, r) {
    flexSheet.mergeRange(new wjcGrid.CellRange(r, 0, r + 22, 0));
    flexSheet.mergeRange(new wjcGrid.CellRange(r, 2, r, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 1, 2, r + 1, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 2, 1, r + 2, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 3, 2, r + 3, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 4, 2, r + 4, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 5, 1, r + 5, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 6, 2, r + 6, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 7, 2, r + 7, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 8, 1, r + 8, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 9, 1, r + 9, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 10, 1, r + 10, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 11, 2, r + 11, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 12, 2, r + 12, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 13, 1, r + 13, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 14, 1, r + 14, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 15, 1, r + 15, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 16, 1, r + 16, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 17, 1, r + 17, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 18, 1, r + 18, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 19, 1, r + 19, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 20, 1, r + 20, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 21, 1, r + 21, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 22, 1, r + 22, 3));

    flexSheet.setCellData(r, 2, "NESIC Tokyo");
    flexSheet.setCellData(r + 1, 2, "Other");
    flexSheet.setCellData(r + 2, 1, "Order Received");
    flexSheet.setCellData(r + 3, 2, "NESIC Tokyo");
    flexSheet.setCellData(r + 4, 2, "Other");
    flexSheet.setCellData(r + 5, 1, "Sales");
    flexSheet.setCellData(r + 6, 2, "NESIC Tokyo");
    flexSheet.setCellData(r + 7, 2, "Other");
    flexSheet.setCellData(r + 8, 1, "Costs");
    flexSheet.setCellData(r + 9, 1, "GP");
    flexSheet.setCellData(r + 10, 1, "Cost Ratio");
    flexSheet.setCellData(r + 11, 2, "Sales Expenses");
    flexSheet.setCellData(r + 12, 2, "GA Expenses");
    flexSheet.setCellData(r + 13, 1, "SGA");
    flexSheet.setCellData(r + 14, 1, "OP");
    flexSheet.setCellData(r + 15, 1, "OP Ratio");
    flexSheet.setCellData(r + 16, 1, "Non-Operating Income");
    flexSheet.setCellData(r + 17, 1, "Non-Operating Expense");
    flexSheet.setCellData(r + 18, 1, "NP");
    flexSheet.setCellData(r + 19, 1, "NP Ratio");
    flexSheet.setCellData(r + 20, 1, "Purchase to NESIC-G");
    flexSheet.setCellData(r + 21, 1, "Consolidated OR");
    flexSheet.setCellData(r + 22, 1, "Consolidated Sales");

    // hide columns by default
    hideColumnDefault(flexSheet, this.state.data);

    flexSheet.applyCellsStyle(
      {
        fontWeight: "bold",
      },
      [
        new wjcGrid.CellRange(0, 0, 2, 66),
        new wjcGrid.CellRange(r, 0, r + 22, 3),
      ]
    );

    flexSheet.applyCellsStyle(
      {
        fontSize: "10px",
        textAlign: "right",
        padding: "0px",
        paddingRight: "3px",
        paddingTop: "2px",
      },
      [new wjcGrid.CellRange(r, 4, r + 22, 66)]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#ccffcc",
      },
      [
        new wjcGrid.CellRange(1, 4, 1, 6),
        new wjcGrid.CellRange(1, 13, 1, 15),
        new wjcGrid.CellRange(1, 22, 1, 24),
        new wjcGrid.CellRange(1, 31, 1, 33),
        new wjcGrid.CellRange(1, 40, 1, 42),
        new wjcGrid.CellRange(1, 49, 1, 51),
        new wjcGrid.CellRange(1, 58, 1, 60),
      ]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#daeef3",
      },
      [
        new wjcGrid.CellRange(2, 4, r + 22, 4),
        new wjcGrid.CellRange(2, 13, r + 22, 13),
        new wjcGrid.CellRange(2, 22, r + 22, 22),
        new wjcGrid.CellRange(2, 31, r + 22, 31),
        new wjcGrid.CellRange(2, 40, r + 22, 40),
        new wjcGrid.CellRange(2, 49, r + 22, 49),
        new wjcGrid.CellRange(2, 58, r + 22, 58),
      ]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#e4dfec",
      },
      [
        new wjcGrid.CellRange(2, 5, r + 22, 5),
        new wjcGrid.CellRange(2, 14, r + 22, 14),
        new wjcGrid.CellRange(2, 23, r + 22, 23),
        new wjcGrid.CellRange(2, 32, r + 22, 32),
        new wjcGrid.CellRange(2, 41, r + 22, 41),
        new wjcGrid.CellRange(2, 50, r + 22, 50),
        new wjcGrid.CellRange(2, 59, r + 22, 59),
      ]
    );

    flexSheet.applyCellsStyle(
      {
        background: "#99ff99",
      },
      [
        new wjcGrid.CellRange(2, 6, r + 22, 6),
        new wjcGrid.CellRange(2, 15, r + 22, 15),
        new wjcGrid.CellRange(2, 24, r + 22, 24),
        new wjcGrid.CellRange(2, 33, r + 22, 33),
        new wjcGrid.CellRange(2, 42, r + 22, 42),
        new wjcGrid.CellRange(2, 51, r + 22, 51),
        new wjcGrid.CellRange(2, 60, r + 22, 60),
      ]
    );
  }

  zoomOut() {
    var gridContainer = document.getElementById("bcAnnualSheet");
    if (gridContainer.classList.contains("zoomClass")) {
      wijmo.removeClass(gridContainer, "zoomClass");
    }
    if (gridContainer.classList.contains("zoomClass1")) {
      wijmo.removeClass(gridContainer, "zoomClass1");
      wijmo.addClass(gridContainer, "zoomClass");
    }
  }

  zoomIn() {
    var gridContainer = document.getElementById("bcAnnualSheet");
    if (gridContainer.classList.contains("zoomClass")) {
      wijmo.addClass(gridContainer, "zoomClass1");
    } else {
      wijmo.addClass(gridContainer, "zoomClass");
    }
  }

  freezeCells() {
    this.state.flex.freezeAtCursor();
    if (this.state.flex.frozenColumns > 0 || this.state.flex.frozenRows > 0) {
      this.setState({
        isFrozen: true,
      });
    } else {
      this.setState({
        isFrozen: false,
      });
    }
  }

  // show selected row by button
  showRows(flex) {
    var selectionStart = flex.selection.row2;
    if (selectionStart === 0) {
      this.state.hideRows.map((row) => (flex.rows[row.index].visible = true));
      this.setState({ hideRows: [] });
    } else {
      var selectionEnd = flex.selection.row;
      for (let i = selectionStart; i <= selectionEnd; i++) {
        flex.rows[i].visible = true;
      }
    }
  }

  // hide selected row by button
  hideRows(flex) {
    this.setState({
      hideRows: [...this.state.hideRows, ...flex.selectedRows],
    });
    flex.selectedRows.map(
      (selectedRow) => (flex.rows[selectedRow.index].visible = false)
    );
  }

  // show selected column by button
  showColumns(flex) {
    var selectionStart = flex.selection.col2;
    if (selectionStart === 0) {
      this.state.hideColumns.map(
        (column) => (flex.columns[column.index].visible = true)
      );
      this.setState({ hideColumns: [] });
      showColumnDefault(flex, this.state.data);
    } else {
      var selectionEnd = flex.selection.col;
      for (let i = selectionStart; i <= selectionEnd; i++) {
        flex.columns[i].visible = true;
      }
    }
  }

  // hide selected column by button
  hideColumns(flex) {
    var selectionStart = flex.selection.col2;
    var selectionEnd = flex.selection.col;
    var hideColumns = [];
    for (let i = selectionStart; i <= selectionEnd; i++) {
      flex.columns[i].visible = false;
      hideColumns = [...hideColumns, flex.columns[i]];
    }
    this.setState({ hideColumns: [...this.state.hideColumns, ...hideColumns] });
  }
}
export default BCAnnualMMK;
