export function hideRowDefault(dataList, sectorList, flex) {
  var initialHideRows = [];
  for (let row = 0; row < dataList.length; row++) {
    flex.rows[row].height = 18;

    if (flex.getCellData(row, 2) === "NESIC Tokyo") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (flex.getCellData(row, 2) === "Other") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (flex.getCellData(row, 2) === "Sales Expenses") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (flex.getCellData(row, 2) === "GA Expenses") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }
  }

  for (let row = 0; row < sectorList.length + 23; row++) {
    if (flex.getCellData(row, 2) === "NESIC Tokyo") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }

    if (flex.getCellData(row, 2) === "Other") {
      flex.rows[row].visible = false;
      initialHideRows = [...initialHideRows, flex.rows[row]];
    }
  }
  //add icon instead of minus
  for (let col = 4; col < 67; col++) {
    for (let row = 3; row < dataList.length + 3; row++) {
      let value = flex.getCellData(row, col);
      let value1 = parseFloat(flex.getCellData(row, col).split("%")[0]);

      if (isNaN(value) && value1 < 0) {
        flex.setCellData(row, col, "▲" + value1 * -1 + "%");
      } else if (value < 0 || value === `-0`) {
        flex.setCellData(row, col, "▲" + value * -1);
      }
    }

    for (let row = 26; row < sectorList.length + 26; row++) {
      let value = flex.getCellData(row, col);
      let value1 = parseFloat(flex.getCellData(row, col).split("%")[0]);
      if (isNaN(value) && value1 < 0) {
        flex.setCellData(row, col, "▲" + value1 * -1 + "%");
      } else if (value < 0 || value === `-0`) {
        flex.setCellData(row, col, "▲" + value * -1);
      }
    }
  }

  return initialHideRows;
}

export function hideColumnDefault(flex, data) {
  data.map((data) => {
    new Date() > new Date(data.endDate) &&
      data.toHideColumn.map((column) => {
        flex.columns[column].visible = false;
        return 0;
      });
    return 0;
  });
}

export function showColumnDefault(flex, data) {
  data.map((data) => {
    new Date() > new Date(data.endDate) &&
      data.toHideColumn.map((column) => (flex.columns[column].visible = true));
    return 0;
  });
}
