import React from "react";
import { Tabs } from "@mantine/core";
import { useAtom } from "jotai";
import {
  chartItemsAtom,
  metacolumnAtom,
  metadataAtom,
} from "./state/chart-item-atom";
import { Responsive, WidthProvider } from "react-grid-layout";
import WidgetsFrame from "./components/widget-frame";

import { BiArrowBack } from "react-icons/bi";
import PropTypes from "prop-types";

// api
import {
  DYNAMIC_DASHBOARD_DATA,
  GET_DYNAMIC_DASHBOARD_ONE,
  TOKEN,
} from "../../../constants/api.path";
import { DataConveter } from "./utilities/utilities";
import { useNavigate } from "react-router-dom";

// components
const ResponsiveGridLayout = WidthProvider(Responsive);

// css
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const getInitialData = async (id) => {
  const request = await fetch(GET_DYNAMIC_DASHBOARD_ONE + "/" + id, {
    method: "GET",
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
  const response = await request.json();
  return response.data;
};

const ILiveBoard = () => {
  // variables

  const [itemState, setItemState] = useAtom(chartItemsAtom);
  const [state, setState] = React.useState({
    activeTab: null,
  });

  const [metadataState, setMetadata] = useAtom(metadataAtom);

  const [metacolumnState, setmetaColumnState] = useAtom(metacolumnAtom);

  const navigate = useNavigate();

  // functions
  const onTabChange = (value) => {
    setState((prev) => ({ ...prev, activeTab: value }));
  };

  // get data
  const getMetaData = async () => {
    const req = await fetch(
      DYNAMIC_DASHBOARD_DATA + "/salesPlanYear/8/salesPlanMonth/4",
      { method: "GET", headers: { Authorization: `Bearer ${TOKEN}` } }
    );
    const result = await req.json();
    let data = await result.metadata[0];
    return data;
  };

  // useEffect
  React.useEffect(() => {
    getMetaData().then((data) => {
      setMetadata(DataConveter(data));
      setmetaColumnState(data.column_names);
    });
    let dashboardId = new URL(window.location).searchParams.get("dashboard");
    getInitialData(dashboardId).then((res) => {
      setItemState(res.json);
      setState({ activeTab: res.json.sections[0].sectionValue });
    });
  }, []);

  React.useEffect(() => {}, [metadataState, metacolumnState]);

  return (
    <div style={{ position: "relative" }}>
      <BiArrowBack
        style={{
          fontSize: "20px",
          width: "34px",
          paddingLeft: "5px",
          paddingRight: "5px",
          height: "35px",
          position: "absolute",
          zIndex: 300,
          cursor: "pointer",
        }}
        onClick={() => {
          navigate(-1);
        }}
      />
      {/* Tab menus */}
      <Tabs
        value={state.activeTab}
        variant="outline"
        onTabChange={(value) => onTabChange(value)}
      >
        {/* tabs list */}
        <Tabs.List>
          <Tabs.Tab value="holder" disabled>
            {" "}
          </Tabs.Tab>
          {itemState?.sections?.map((section) => (
            <Tabs.Tab
              value={section.sectionValue}
              key={`tabs-${section.sectionValue}`}
            >
              {section.sectionName}
            </Tabs.Tab>
          ))}
        </Tabs.List>
        {/* tabs contents */}
        {itemState.sections?.map((section) => (
          <Tabs.Panel
            value={section.sectionValue}
            key={`tabs-panel-${section.sectionValue}`}
          >
            <ResponsiveGridLayout
              className="layout"
              style={{
                marginTop: 0,
                maxHeight: "85vh",
                overflowY: "auto",
                overflowX: "hidden",
              }}
              breakpoints={{ md: 996, sm: 768, xs: 480, xxs: 0 }}
              cols={{ md: 12, sm: 8, xs: 4, xxs: 2 }}
              layouts={section.layouts}
              rowHeight={50}
              isResizable={false}
              isDraggable={false}
            >
              {section.items?.map(({ i, x, y, w, h }, index) => (
                <div key={i} data-grid={{ x, y, w, h }}>
                  <WidgetsFrame
                    isEdit={state.isEditable}
                    key={`Widget-${i}`}
                    index={i}
                    activeSection={section.sectionValue}
                    item={section.items[index]}
                  />
                </div>
              ))}
            </ResponsiveGridLayout>
          </Tabs.Panel>
        ))}
      </Tabs>
    </div>
  );
};

export default ILiveBoard;

ILiveBoard.propTypes = {
  selectedCanvas: PropTypes.string,
  mode: PropTypes.string,
};
