import React from "react";
import QuarterlyYearlyChart from "./quarterYearlyChart";
import PropTypes from "prop-types";
import QuarterYearlyTable from "./quarterYearlyTable";

export default function QuarterYearly({
  chartData,
  filterRange,
  fiscalYears,
  tableData,
}) {
  return (
    <div className="mt-2">
      <QuarterlyYearlyChart
        quarterChartData={chartData}
        filterRange={filterRange}
        fiscalYears={fiscalYears}
      />
      <QuarterYearlyTable
        data={tableData}
        filterRange={filterRange}
        fiscalYears={fiscalYears}
      />
    </div>
  );
}

QuarterYearly.propTypes = {
  tableData: PropTypes.object,
  chartData: PropTypes.object,
  filterRange: PropTypes.object,
  fiscalYears: PropTypes.array,
};
