import "bootstrap/dist/css/bootstrap.css";
import "@grapecity/wijmo.styles/wijmo.css";

import React from "react";
import * as wjcCore from "@grapecity/wijmo";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import {
  BUSINESS_SECTOR_LIST_URL,
  SALES_PLAN_CREATE_UPDATE_URL,
  FISCAL_YEAR_LIST_URL,
  QUARTER_LIST,
  TOKEN,
} from "../../constants/api.path";
import SuccessModal from "../../modalPopups/successModal";
import { AiOutlineReload } from "react-icons/ai";
import { BiSave } from "react-icons/bi";
import { MdDownload } from "react-icons/md";
import { Tooltip as ReactTooltip } from "react-tooltip";
import cacheUpdate from "../cacheUpdate/cacheUpdate";
import SalesPlanUpdate from "./salesPlanUpdate";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

export default class Import extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileName: "",
      flex: null,
      error: false,
      fiscalList: [],
      showSuccessModal: "",
      resultMessage: "",
      selectedArray: [],
      tabs: [
        { id: 1, name: "Sales Plan New Upload" },
        {
          id: 2,
          name: "Sales Plan Bulk Update",
        },
      ],
      active: "Sales Plan New Upload",
    };
    this.style = {
      display: "none",
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;

    const selectedValue = { ...this.state.selectedArray };
    selectedValue[fieldName] = fieldValue;

    this.setState({ selectedArray: selectedValue }, () => {});
  }

  componentDidMount() {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ fiscalList: result.data.fiscalYearList });
        let yearName = [];
        result.data.fiscalYearList.forEach(function (fYear) {
          yearName.push(fYear.fiscalYear);
        });
        this.setState({ fYName: yearName });
      });

    fetch(BUSINESS_SECTOR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ sectorList: result.data.sectorList });
        let sectorName = [];
        result.data.sectorList.forEach(function (sector) {
          sectorName.push(sector.sectorName);
        });
        this.setState({ sectName: sectorName });
      });

    fetch(QUARTER_LIST, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ quarterList: result.data.quarterList });
        let quarterName = [];
        result.data.quarterList.forEach(function (quarter) {
          quarterName.push(quarter.quarterName);
        });
        this.setState({ quartName: quarterName });
      });
  }

  componentDidUpdate() {}

  render() {
    return (
      <div className="container-fluid">
        <div
          className="row col-5 justify_content_start"
          style={{ paddingLeft: "16px", paddingBottom: "5px", height: "36px" }}
        >
          {this.state.tabs.map((tab) => (
            <div
              key={tab.id}
              className="col actionTextBtn2-parent"
              style={{ padding: "0.5px" }}
            >
              <button
                className={`actionTextBtn2 ${
                  this.state.active === tab.name && "dashboardActiveBtn"
                }`}
                onClick={() => {
                  this.setState({
                    active: tab.name,
                  });
                }}
              >
                {tab.name}
              </button>
            </div>
          ))}
        </div>
        {this.state.active === this.state.tabs[0].name && (
          <div className="upload">
            <div
              className="row justify-content-between"
              style={{ margin: "0" }}
            >
              <div className="col-auto">
                <div className="row">
                  <div
                    className="form-inline col-md-auto"
                    style={{ paddingLeft: "0.4vw", paddingRight: "0" }}
                  >
                    <input
                      type="file"
                      className="form-control"
                      id="selectFile"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={this.getFileName.bind(this)}
                    />
                  </div>
                  <div
                    className="form-inline col-md-auto"
                    style={{ paddingLeft: "0.35vw" }}
                  >
                    <button
                      className="actionIconOutline load bg-blue"
                      onClick={this.load.bind(this)}
                      id="loadButton"
                      data-tooltip-content="Load"
                    >
                      <AiOutlineReload />
                      <ReactTooltip place="right" anchorId="loadButton" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-auto">
                <div className="row">
                  <div className="col-md-auto" style={{ padding: "0" }}>
                    <select
                      name="fiscalYear"
                      className="form-select"
                      onChange={this.handleChange}
                    >
                      <option value="" hidden>
                        Sales Plan Year
                      </option>
                      {this.state.fiscalList.map((item, index) => (
                        <option key={index + 1} value={item.fiscalYearId}>
                          {item.fiscalYear}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div
                    className="col-md-auto"
                    style={{ paddingLeft: "0.4vw", paddingRight: "0" }}
                  >
                    <select
                      name="salesPlanMonth"
                      className="form-select"
                      onChange={this.handleChange}
                    >
                      <option value="" hidden>
                        Sales Plan Month
                      </option>
                      <option key={1} value={4}>
                        Apr
                      </option>
                      <option key={2} value={5}>
                        May
                      </option>
                      <option key={3} value={6}>
                        Jun
                      </option>
                      <option key={4} value={7}>
                        Jul
                      </option>
                      <option key={5} value={8}>
                        Aug
                      </option>
                      <option key={6} value={9}>
                        Sep
                      </option>
                      <option key={7} value={10}>
                        Oct
                      </option>
                      <option key={8} value={11}>
                        Nov
                      </option>
                      <option key={9} value={12}>
                        Dec
                      </option>
                      <option key={10} value={1}>
                        Jan
                      </option>
                      <option key={11} value={2}>
                        Feb
                      </option>
                      <option key={12} value={3}>
                        Mar
                      </option>
                    </select>
                  </div>
                  <div
                    className="col-md-auto"
                    style={{ paddingLeft: "0.4vw", paddingRight: "0" }}
                  >
                    <button
                      className="actionIconOutline save bg-blue"
                      id="saveButton"
                      data-tooltip-content="Save"
                      onClick={() => this.getFileData(this.state.selectedArray)}
                    >
                      <BiSave />
                      <ReactTooltip place="left" anchorId="saveButton" />
                    </button>
                  </div>
                  <div className="col-md-auto" style={{ padding: "0" }}>
                    <button
                      className="actionIconOutline download bg-blue"
                      onClick={this.download.bind(this)}
                      id="downloadButton"
                      data-tooltip-content="Download"
                    >
                      <MdDownload />
                      <ReactTooltip place="left" anchorId="downloadButton" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div style={{ marginLeft: "10px" }}>
              {this.state.error && (
                <span className="error">{this.state.errorMessage}</span>
              )}
            </div>
            <wjGridSheet.FlexSheet
              initialized={this.initializeFlexSheet.bind(this)}
              style={{
                height: "540px",
                marginLeft: "5px",
              }}
              id="salesPlanSheet"
            >
              <wjGridSheet.Sheet name="New Sheet" />
            </wjGridSheet.FlexSheet>
            <SuccessModal
              show={this.state.showSuccessModal}
              title={this.state.resultMessage}
              onSubmit={() => {
                this.setState({ showSuccessModal: false }, () => {});
              }}
              onHide={() => {
                this.setState({ showSuccessModal: false }, () => {});
              }}
            />
          </div>
        )}

        {this.state.active === this.state.tabs[1].name && <SalesPlanUpdate />}
      </div>
    );
  }

  initializeFlexSheet(flex) {
    this.setState({
      flex: flex,
    });
  }

  getFileName() {
    let fileInput = document.getElementById("selectFile");
    this.setState({
      fileName: fileInput.files[0].name,
    });
  }

  load() {
    this.setState({ error: false });
    let fileInput = document.getElementById("selectFile");
    if (this.state.flex && fileInput.files[0]) {
      this.state.flex.loadAsync(fileInput.files[0]);
    } else {
      this.setState({ error: true });
      this.setState({
        errorMessage: "There is no upload file, please choose a file!",
      });
    }
  }

  download() {
    let fileName;
    if (this.state.fileName) {
      fileName = this.state.fileName;
    } else {
      fileName = "FlexSheet.xlsx";
    }
    this.state.flex.saveAsync(fileName);
  }

  getFileData(selectedYearMonth) {
    const sheet = this.state.flex.selectedSheet;
    this.setState({ error: false });
    this.error = false;
    this.sectFlag = false;
    this.qFlag = false;
    let finalData = [];
    let col1, col2, col3, col4, col5, col6, col7, col8, col9, col10;

    if (
      sheet.name !== "Sales Plan" &&
      selectedYearMonth.fiscalYear === undefined &&
      selectedYearMonth.salesPlanMonth === undefined
    ) {
      this.setState({ error: true });
      this.setState({
        errorMessage: "Please choose file and load file on flex sheet!",
      });
    } else if (
      sheet.name !== "Sales Plan" &&
      selectedYearMonth.fiscalYear === undefined &&
      selectedYearMonth.salesPlanMonth !== undefined
    ) {
      this.setState({ error: true });
      this.setState({ errorMessage: "Please select sales plan sheet!" });
    } else if (
      sheet.name !== "Sales Plan" &&
      selectedYearMonth.fiscalYear !== undefined &&
      selectedYearMonth.salesPlanMonth === undefined
    ) {
      this.setState({ error: true });
      this.setState({ errorMessage: "Please select sales plan sheet!" });
    } else if (
      sheet.name !== "Sales Plan" &&
      selectedYearMonth.fiscalYear !== undefined &&
      selectedYearMonth.salesPlanMonth !== undefined
    ) {
      this.setState({ error: true });
      this.setState({ errorMessage: "Please select sales plan sheet!" });
    } else if (
      sheet.name === "Sales Plan" &&
      selectedYearMonth.fiscalYear === undefined &&
      selectedYearMonth.salesPlanMonth === undefined
    ) {
      this.setState({ error: true });
      this.setState({
        errorMessage: "Please select both sales plan year and month!",
      });
    } else if (
      sheet.name === "Sales Plan" &&
      selectedYearMonth.fiscalYear === undefined &&
      selectedYearMonth.salesPlanMonth !== undefined
    ) {
      this.setState({ error: true });
      this.setState({ errorMessage: "Please select sales plan year!" });
    } else if (
      sheet.name === "Sales Plan" &&
      selectedYearMonth.fiscalYear !== undefined &&
      selectedYearMonth.salesPlanMonth === undefined
    ) {
      this.setState({ error: true });
      this.setState({ errorMessage: "Please select sales plan month!" });
    } else {
      let breakRow = null;
      let header1 = "P.O Received Amount";
      // loop and get column index...
      for (let c = 0; c < this.state.flex.columns.length; c++) {
        if (this.state.flex.getCellValue(2, c) === "No.") {
          col1 = c;
        }
        if (this.state.flex.getCellValue(2, c) === "Remarks#1") {
          col2 = c;
        }
        if (this.state.flex.getCellValue(2, c) === "PJ Name") {
          col3 = c;
        }
        if (this.state.flex.getCellValue(2, c) === "NESIC/Others") {
          col4 = c;
        }
        if (this.state.flex.getCellValue(2, c) === "Bz Sector") {
          col5 = c;
        }
        if (this.state.flex.getCellValue(2, c).toString().includes(header1)) {
          col6 = c;
        }
        if (this.state.flex.getCellValue(2, c) === "Actual Sale Amount") {
          col7 = c;
        }
        if (this.state.flex.getCellValue(2, c) === "Order No.") {
          col8 = c;
        }
        if (
          this.state.flex.getCellValue(2, c) === "State" ||
          this.state.flex.getCellValue(2, c) === "Status"
        ) {
          col9 = c;
        }
        if (this.state.flex.getCellValue(2, c) === "Cost%") {
          col10 = c;
        }
      }
      for (var r = 0; r < this.state.flex.rows.length; r++) {
        if (
          this.state.flex.getCellValue(r, col3) !== "SGA and In Direct Costs"
        ) {
          let rowData = this.renderRow(
            this.state.flex,
            r,
            selectedYearMonth.fiscalYear,
            selectedYearMonth.salesPlanMonth,
            breakRow,
            col1,
            col2,
            col3,
            col4,
            col5,
            col6,
            col7,
            col8,
            col9,
            col10
          );
          if (rowData?.length > 0) {
            finalData.push(rowData[0]);
          }
        } else {
          breakRow = r;
          this.renderRow(
            this.state.flex,
            r,
            selectedYearMonth.fiscalYear,
            selectedYearMonth.salesPlanMonth,
            breakRow,
            col1,
            col2,
            col3,
            col4,
            col5,
            col6,
            col7,
            col8,
            col9,
            col10
          );
          break;
        }
      }
      if (!this.sectFlag && !this.qFlag) {
        let saveData = { salesPlanList: [...finalData] };
        this.savetoDB(saveData);
      }
    }
  }

  renderRow(
    panel,
    r,
    salesPlanYear,
    salesPlanMonth,
    breakRow,
    c1,
    c2,
    c3,
    c4,
    c5,
    c6,
    c7,
    c8,
    c9,
    c10
  ) {
    var rowData = [];
    let row = panel.rows[r];
    let header = [
      "salesPlanId",
      "status",
      "projectName",
      "customerGroup",
      "sectorId",
      "poCurrencyAmount",
      "poExchangeRate",
      "poAmount",
      "salesCurrencyAmount",
      "salesExchangeRate",
      "salesAmount",
      "orderNumber",
      "diffPoAmount",
      "costPercent",
      "planCost",
      "poQuarterId",
      "poMonth",
      "poRank",
      "salesQuarterId",
      "salesMonth",
      "salesRank",
      "state",
      "salesPlanYear",
      "salesPlanMonth",
      "month1",
      "month2",
      "month3",
      "gpPercent1",
      "month4",
      "month5",
      "month6",
      "gpPercent2",
      "month7",
      "month8",
      "month9",
      "gpPercent3",
      "month10",
      "month11",
      "month12",
      "gpPercent4",
      "sortingId",
    ];

    let customerList = [
      { id: 1, name: "NESIC" },
      { id: 2, name: "Others" },
    ];

    let rankList = [
      { id: 0, name: "None" },
      { id: 1, name: "A" },
      { id: 2, name: "B" },
      { id: 3, name: "C" },
      { id: 4, name: "D" },
      { id: 5, name: "E" },
    ];

    if (
      row.renderSize > 0 &&
      r >= 7 &&
      panel.getCellValue(r, c1) &&
      panel.getCellValue(r, c1) % 1 !== 0 &&
      // panel.getCellValue(r, c2) &&
      panel.getCellValue(r, c3) &&
      panel.getCellValue(r, c3) !== "SGA and In Direct Costs"
    ) {
      let sectorId,
        poQuarterId,
        salesQuarterId,
        customerId,
        poRankId,
        salesRankId;
      // check Customer Group Id
      if (panel.getCellValue(r, c4)) {
        customerList.forEach(function (customer) {
          if (customer.name === panel.getCellValue(r, c4).trim()) {
            customerId = customer.id;
          }
        });
      } else customerId = "";

      // check Business Sector Id
      if (panel.getCellValue(r, c5)) {
        // check cell sector name includes or not in list
        if (
          !this.state.sectName.includes(panel.getCellValue(r, c5).split(" ")[0])
        ) {
          this.sectFlag = true;
        }

        this.state.sectorList.forEach(function (sector) {
          if (panel.getCellValue(r, c5).includes(sector.sectorName)) {
            sectorId = sector.sectorId;
          }
        });
      } else sectorId = "";

      // check PO Quarter Id
      if (panel.getCellValue(r, c8 + 5)) {
        this.state.quarterList.forEach(function (quarter) {
          if (quarter.quarterName === panel.getCellValue(r, c8 + 5)) {
            poQuarterId = quarter.quarterId;
          }
        });

        if (!this.state.quartName.includes(panel.getCellValue(r, c8 + 5))) {
          this.qFlag = true;
        }
      } else poQuarterId = "";

      // check Sales Quarter Id
      if (panel.getCellValue(r, c8 + 8)) {
        this.state.quarterList.forEach(function (quarter) {
          if (quarter.quarterName === panel.getCellValue(r, c8 + 8)) {
            salesQuarterId = quarter.quarterId;
          }
        });

        if (!this.state.quartName.includes(panel.getCellValue(r, c8 + 8))) {
          this.qFlag = true;
        }
      } else salesQuarterId = "";

      // check PO Rank Id
      if (panel.getCellValue(r, c8 + 7)) {
        rankList.forEach(function (rank) {
          if (rank.name === panel.getCellValue(r, c8 + 7).trim()) {
            poRankId = rank.id;
          }
        });
      } else poRankId = "";

      // check Sales Rank Id
      if (panel.getCellValue(r, c8 + 10)) {
        rankList.forEach(function (rank) {
          if (rank.name === panel.getCellValue(r, c8 + 10).trim()) {
            salesRankId = rank.id;
          }
        });
      } else salesRankId = "";

      // check project status
      let remark = "";
      if (c2 !== undefined) {
        remark = panel.getCellValue(r, c2); // projectStatus
      }

      // check project state
      let state = 1;
      if (c9 !== undefined || c9 !== "") {
        if (panel.getCellValue(r, c9) === "Forecasting") {
          state = 1; // state
        } else if (panel.getCellValue(r, c9) === "Ongoing") {
          state = 2; // state
        } else {
          state = 3; // state
        }
      }

      // check cost%
      let costPercent = "";
      if (c10 !== undefined) {
        costPercent = panel.getCellValue(r, c10); // costPercent
      }

      // check plan cost
      let planCost = parseFloat(panel.getCellValue(r, c7)) * costPercent;

      rowData.push({
        [header[0]]: "", // id
        [header[1]]: remark, // projectStatus
        [header[2]]: panel.getCellValue(r, c3), // projectName
        [header[3]]: customerId,
        [header[4]]: sectorId,
        [header[5]]: parseFloat(panel.getCellValue(r, c6 - 2)), // poCurrencyAmount
        [header[6]]: parseFloat(panel.getCellValue(r, c6 - 1)), // poExchangeRate
        [header[7]]: parseFloat(panel.getCellValue(r, c6)), // poAmount
        [header[8]]: parseFloat(panel.getCellValue(r, c7 - 2)), // salesCurrencyAmount
        [header[9]]: parseFloat(panel.getCellValue(r, c7 - 1)), // salesExchangeRate
        [header[10]]: parseFloat(panel.getCellValue(r, c7)), // salesAmount
        [header[11]]: panel.getCellValue(r, c8), // orderNumber
        [header[12]]: parseFloat(panel.getCellValue(r, c8 + 1)), // diffPoAmount
        [header[13]]: costPercent * 100, // costPercent
        [header[14]]: planCost, // planCost
        [header[15]]: poQuarterId,
        [header[16]]: panel.getCellValue(r, c8 + 6), // poMonth
        [header[17]]: poRankId,
        [header[18]]: salesQuarterId,
        [header[19]]: panel.getCellValue(r, c8 + 9), // salesMonth
        [header[20]]: salesRankId,
        [header[21]]: state, // state
        [header[22]]: parseInt(salesPlanYear), // salesPlanYear
        [header[23]]: parseInt(salesPlanMonth), // salesPlanMonth
        [header[24]]: panel.getCellValue(r, c8 + 14), // month1
        [header[25]]: panel.getCellValue(r, c8 + 15), // month2
        [header[26]]: panel.getCellValue(r, c8 + 16), // month3
        [header[27]]:
          state !== 3
            ? parseInt(panel.getCellValue(r, c8 + 14)) +
              parseInt(panel.getCellValue(r, c8 + 15)) +
              parseInt(panel.getCellValue(r, c8 + 16)) -
              planCost
            : "", // gpPercent1
        [header[28]]: panel.getCellValue(r, c8 + 19), // month4
        [header[29]]: panel.getCellValue(r, c8 + 20), // month5
        [header[30]]: panel.getCellValue(r, c8 + 21), // month6
        [header[31]]:
          state !== 3
            ? parseInt(panel.getCellValue(r, c8 + 19)) +
              parseInt(panel.getCellValue(r, c8 + 20)) +
              parseInt(panel.getCellValue(r, c8 + 21)) -
              planCost
            : "", // gpPercent2
        [header[32]]: panel.getCellValue(r, c8 + 24), // month7
        [header[33]]: panel.getCellValue(r, c8 + 25), // month8
        [header[34]]: panel.getCellValue(r, c8 + 26), // month9
        [header[35]]:
          state !== 3
            ? parseInt(panel.getCellValue(r, c8 + 24)) +
              parseInt(panel.getCellValue(r, c8 + 25)) +
              parseInt(panel.getCellValue(r, c8 + 26)) -
              planCost
            : "", // gpPercent3
        [header[36]]: panel.getCellValue(r, c8 + 29), // month10
        [header[37]]: panel.getCellValue(r, c8 + 30), // month11
        [header[38]]: panel.getCellValue(r, c8 + 31), // month12
        [header[39]]:
          state !== 3
            ? parseInt(panel.getCellValue(r, c8 + 29)) +
              parseInt(panel.getCellValue(r, c8 + 30)) +
              parseInt(panel.getCellValue(r, c8 + 31)) -
              planCost
            : "", // gpPercent4
        [header[40]]: "", // sorting_id
      });
    }
    if (r === breakRow) {
      if (this.sectFlag || this.qFlag) {
        this.setState({ error: true });
        this.error = true;
        if (this.sectFlag && !this.qFlag) {
          this.setState({
            errorMessage:
              "Input Sector Name is not registered in Config Module!",
          });
        } else if (!this.sectFlag && this.qFlag) {
          this.setState({
            errorMessage: "Input Quarter is not registered in Config Module!",
          });
        } else if (this.sectFlag && this.qFlag) {
          this.setState({
            errorMessage:
              "Input Sector Name and Quarter are not registered in Config Module!",
          });
        }
      }
    }
    return rowData;
  }

  savetoDB(added) {
    fetch(SALES_PLAN_CREATE_UPDATE_URL + "/keyword/import", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify(added),
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        if (response.result) {
          this.setState({ resultMessage: "Data successful Save!" }, () => {
            this.setState({ showSuccessModal: true }, () => {});
          });
          cacheUpdate(
            this.state.selectedArray.fiscalYear,
            this.state.selectedArray.salesPlanMonth
          );
        }
      });
  }

  //update

  initializeFlexSheetUpdate(flex) {
    flex.deferUpdate(() => {
      this._generateBasicOperatorsSheet(flex);
    });
  }

  _generateBasicOperatorsSheet(flexSheet) {
    flexSheet.setCellData(0, 0, "Order Number");
    flexSheet.setCellData(0, 1, "Sales Month");
    flexSheet.setCellData(0, 2, "PO Amount");
    flexSheet.setCellData(0, 3, "Sales Amount");
    flexSheet.setCellData(0, 4, "PO Different");

    flexSheet.applyCellsStyle(
      {
        background: "#0097b1",
        color: "white",
      },
      [new wjcGrid.CellRange(0, 0, 0, 12)]
    );
  }
}
