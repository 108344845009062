import React from "react";
import "./sideBar.css";

import { AiOutlineRight } from "react-icons/ai";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

export default function SideBar(props) {
  let {
    collapsed,
    changeLocation,
    curLocation,
    menuList,
    styles,
    mainClass,
    mainId,
  } = props;
  function handleExpand(id) {
    let target = document.querySelector(`#sub-${id}`);
    let targetArrow = document.querySelector(`#dropdown-${id}`);
    if (target.style.visibility === "visible") {
      target.style.visibility = "hidden";
    } else {
      target.style.visibility = "visible";
    }
    if (target.style.height === "fit-content") {
      target.style.height = "0px";
    } else {
      target.style.height = "fit-content";
    }
    if (targetArrow.style.transform === "") {
      targetArrow.style.transform = "rotate(90deg)";
    } else {
      targetArrow.style.transform = "";
    }
  }
  function handleChange(name) {
    changeLocation(name);
  }
  React.useEffect(() => {
    let dropdownArrows = document.querySelectorAll(".dropdown");
    dropdownArrows.forEach((arrow) => {
      arrow.style.transform = "";
    });
  }, []);
  return (
    <div style={{ position: "relative" }}>
      <div
        className={mainClass}
        id={mainId}
        style={
          mainId === undefined
            ? window.innerWidth > 768
              ? !collapsed
                ? { width: "220px" }
                : { width: "50px" }
              : !collapsed
              ? { width: "220px" }
              : { width: "0px" }
            : {}
        }
      >
        <div className="h-auto">
          {menuList.map((menu) => (
            <div key={menu.id} className="menu-container">
              {!menu.hasSub ? (
                <Link
                  id={`menu-${menu.id}`}
                  to={menu.link}
                  style={styles.menu}
                  className={`menu-item ${
                    curLocation.includes(menu.location) && "activeMenu"
                  }`}
                  onClick={() => {
                    handleChange(menu.location);
                  }}
                >
                  {menu.icon && menu.icon}
                  {menu.name}
                </Link>
              ) : (
                <div
                  key={menu.id}
                  id={`menu-${menu.id}`}
                  style={styles.menu}
                  className={`menu-item ${
                    curLocation.includes(menu.location) && "activeMenu"
                  }`}
                  onClick={() => {
                    if (!collapsed) {
                      handleExpand(menu.id);
                    }
                  }}
                  onMouseEnter={
                    collapsed
                      ? () => {
                          document.getElementById(
                            `collapsed-menu-${menu.id}`
                          ).style.visibility = "visible";
                        }
                      : () => {}
                  }
                  onMouseLeave={
                    collapsed
                      ? () => {
                          document.getElementById(
                            `collapsed-menu-${menu.id}`
                          ).style.visibility = "";
                        }
                      : () => {}
                  }
                >
                  {menu.icon}
                  {menu.name}
                  <AiOutlineRight
                    style={styles.expendIcon}
                    className="dropdown"
                    id={`dropdown-${menu.id}`}
                  />
                </div>
              )}
              {menu.hasSub && !collapsed && (
                <SideBar
                  collapsed={collapsed}
                  changeLocation={changeLocation}
                  curLocation={curLocation}
                  menuList={menu.subList}
                  styles={styles}
                  mainClass={!isNaN(menu.id) ? `sub` : `inner-sub`}
                  mainId={`sub-${menu.id}`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      {menuList.map(
        (menu) =>
          menu.hasSub &&
          collapsed && (
            <CollapsedSubMenu
              id={menu.id}
              collapsed={collapsed}
              changeLocation={changeLocation}
              curLocation={curLocation}
              menuList={menu.subList}
              styles={styles}
              mainClass={!isNaN(menu.id) ? `sub` : `inner-sub`}
              mainId={`sub-${menu.id}`}
              key={menu.id}
            />
          )
      )}
    </div>
  );
}

function CollapsedSubMenu(props) {
  let {
    collapsed,
    changeLocation,
    curLocation,
    menuList,
    styles,
    mainClass,
    id,
  } = props;
  function handleChange(name) {
    changeLocation(name);
  }
  return (
    <div
      className={
        mainClass ? `collapsed-submenu-${mainClass}` : "collapsed-submenu"
      }
      style={
        !isNaN(id) ? { top: `calc(${id * 36}px + 1px)`, left: "50px" } : {}
      }
      id={`collapsed-menu-${id}`}
    >
      <div style={{ position: "" }}>
        {menuList.map((menu) =>
          menu.hasSub ? (
            <div
              key={menu.id}
              className={`collapsed-sub ${
                curLocation.includes(menu.location) && "activeMenu"
              }`}
              id={`collapsed-sub-${menu.id}`}
              onMouseEnter={() => {
                document.getElementById(`inner-${menu.id}`).style.visibility =
                  "visible";
              }}
              onMouseLeave={() =>
                (document.getElementById(`inner-${menu.id}`).style.visibility =
                  "")
              }
            >
              <div
                className={`collapsed-sub-name ${
                  curLocation.includes(menu.location) && "activeMenu"
                }`}
              >
                {menu.name}
              </div>
              <AiOutlineRight
                style={styles.expendIcon}
                className="dropdown"
                id={`dropdown-${menu.id}`}
              />
              <div className="inner" id={`inner-${menu.id}`}>
                <CollapsedSubMenu
                  id={menu.id}
                  collapsed={collapsed}
                  changeLocation={changeLocation}
                  curLocation={curLocation}
                  menuList={menu.subList}
                  styles={styles}
                  mainClass="inner-sub"
                  mainId={`sub-${menu.id}`}
                />
              </div>
            </div>
          ) : (
            <div
              key={menu.id}
              className={`collapsed-sub ${
                curLocation.includes(menu.location) && "activeMenu"
              }`}
            >
              <Link
                to={menu.link}
                id={`collapsed-sub-${menu.id}`}
                style={{ width: "100%", display: "block" }}
                onClick={() => handleChange(menu.location)}
              >
                {menu.name}
              </Link>
            </div>
          )
        )}
      </div>
    </div>
  );
}

SideBar.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  changeLocation: PropTypes.func,
  curLocation: PropTypes.string.isRequired,
  menuList: PropTypes.array.isRequired,
  styles: PropTypes.object,
  mainClass: PropTypes.string.isRequired,
  mainId: PropTypes.string,
};

CollapsedSubMenu.propTypes = {
  collapsed: PropTypes.bool.isRequired,
  changeLocation: PropTypes.func,
  curLocation: PropTypes.string.isRequired,
  menuList: PropTypes.array.isRequired,
  styles: PropTypes.object,
  mainClass: PropTypes.string.isRequired,
  id: PropTypes.string,
};
