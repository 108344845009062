import React from "react";
import PropTypes from "prop-types";

// wijmo
import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";

export default function DashboardColumnChart({ data }) {
  return (
    <div>
      <wjChart.FlexChart
        bindingX="name"
        itemsSource={data}
        chartType="Column"
        selectionMode="Series"
        legendToggle={true}
        palette={["#ff5349", "#32CD32", "#00FFFF"]}
      >
        <wjChart.FlexChartLegend position="Bottom" />
        <wjChart.FlexChartSeries
          name="改善​"
          binding="stretch"
        ></wjChart.FlexChartSeries>
        <wjChart.FlexChartSeries
          name="ボトム​"
          binding="bottom"
        ></wjChart.FlexChartSeries>
        <wjChart.FlexChartSeries name="見込​" binding="total">
          <wjChart.FlexChartDataLabel content="{y}" position="Top" />
        </wjChart.FlexChartSeries>
        <wjChartAnimate.FlexChartAnimation />
        <wjChart.FlexChartDataLabel
          content={"{y}"}
          position="Bottom"
        ></wjChart.FlexChartDataLabel>
      </wjChart.FlexChart>
    </div>
  );
}

DashboardColumnChart.propTypes = {
  data: PropTypes.isRequired,
};
