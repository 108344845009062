import LineChartWidget from "./line-chart";
import PieChartWidget from "./pie-chart";
import BarChartWidget from "./bar-chart";
import BarChartLineWidget from "./bar-chart-line";
import ProgressCardWidget from "./progress-card";
import SimpleTableWidget from "./simple-table";
import AreaChartWidget from "./area-chart";
import BubbleChartWidget from "./bubble-chart";
import ScatterChartWidget from "./scatter-chart";
import FlexSheetTableWidget from "./flex-sheet-table";

export const PieChart = PieChartWidget;
export const LineChart = LineChartWidget;
export const BarChart = BarChartWidget;
export const BarChartLine = BarChartLineWidget;
export const ProgressCard = ProgressCardWidget;
export const SimpleTable = SimpleTableWidget;
export const AreaChart = AreaChartWidget;
export const BubbleChart = BubbleChartWidget;
export const ScatterChart = ScatterChartWidget;
export const FlexSheetTable = FlexSheetTableWidget;
