import "bootstrap/dist/css/bootstrap.css";
import "@grapecity/wijmo.styles/wijmo.css";
import * as wjcCore from "@grapecity/wijmo";
import * as React from "react";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import "../../assets/css/dashboard.css";

import PropTypes from "prop-types";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

class FinancialDataSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flex: null,
      isFrozen: false,
      isLoading: true,
      hideRows: [],
      hideColumns: [],
      data: {},
      columns: [
        { id: 0, name: "FY16" },
        { id: 1, name: "FY17" },
        { id: 2, name: "FY18" },
        { id: 3, name: "FY19" },
        { id: 4, name: "FY20" },
        { id: 5, name: "FY21" },
        { id: 6, name: "FY22" },
        { id: 7, name: "FY23" },
        { id: 8, name: "FY24" },
      ],
      rows: [
        { id: 0, name: "Order Received" },
        { id: 1, name: "Sales" },
        { id: 2, name: "Myanmar" },
        { id: 3, name: "Japan" },
        { id: 4, name: "ICT-Mng" },
        { id: 5, name: "Facilities" },
        { id: 6, name: "Fiber" },
        { id: 7, name: "ICT Biz." },
        { id: 8, name: "Software&BPO" },
        { id: 9, name: "JP Biz." },
        { id: 10, name: "Cost of sales" },
        { id: 11, name: "GP" },
        { id: 12, name: "GP %" },
        { id: 13, name: "SGA" },
        { id: 14, name: "SGA %" },
        { id: 15, name: "Operating income" },
        { id: 16, name: "Operating income %" },
        { id: 17, name: "Non-operating income" },
        { id: 18, name: "Non-operating expenses" },
        { id: 19, name: "Ordinary Profit" },
        { id: 20, name: "Ordinary Profit %" },
        { id: 21, name: "Net income before tax" },
        { id: 22, name: "CT" },
        { id: 23, name: "Net income" },
      ],
      displayColumns: [],
      fiscalYearList: this.props.fiscalYearList,
    };
  }
  componentDidMount() {
    this.setState({
      displayColumns: this.updateDisplayColumns({
        from: this.props.filterFrom,
        to: this.props.filterTo,
      }),
    });
  }

  componentDidUpdate() {
    let filterRange = this.updateDisplayColumns({
      from: this.props.filterFrom,
      to: this.props.filterTo,
    });
    if (this.state.displayColumns.length !== filterRange.length) {
      this.setState({ displayColumns: filterRange });
    }
    if (this.state.data !== this.props.data)
      this.setState({ data: this.props.data });
    if (this.state.flex !== null)
      this._generateBasicOperatorsSheet(this.state.flex);
  }

  updateDisplayColumns() {
    let from = this.props.filterFrom,
      to = this.props.filterTo;
    let yearList = this.quicksort(this.state.fiscalYearList);
    let itemList = this.quicksort(this.state.columns);
    let start, target;
    yearList.forEach((year, index) => {
      year.fiscalYearId === from && (start = index);
      year.fiscalYearId === to && (target = index);
    });
    let columns = [];
    for (let i = start; i < target + 1; i++) {
      for (let j = 0; j < itemList.length; j++) {
        yearList[i].fiscalYear === itemList[j].name.slice(0, 4) &&
          columns.push(itemList[j]);
      }
    }
    return columns;
  }
  quicksort(arr) {
    if (arr.length <= 1) {
      return arr;
    } else {
      const pivot = arr[0];
      const less = [];
      const greater = [];
      for (let i = 1; i < arr.length; i++) {
        if (arr[i].name <= pivot.name) {
          less.push(arr[i]);
        } else {
          greater.push(arr[i]);
        }
      }
      return [...this.quicksort(less), pivot, ...this.quicksort(greater)];
    }
  }

  render() {
    return (
      <div>
        <div
          className="row justify-content-start"
          style={{ marginLeft: "0px" }}
        ></div>
        <wjGridSheet.FlexSheet
          initialized={this.initializeFlexSheet.bind(this)}
          itemsSourceChanged={this.initializeFlexSheet.bind(this)}
          isReadOnly={true}
          style={{
            maxWidth: "100%",
          }}
          id="finicialDataSheet"
        >
          <wjGridSheet.Sheet
            name="Financial Report"
            columnCount={this.updateDisplayColumns().length + 3}
            rowCount={this.state.rows.length + 1}
          ></wjGridSheet.Sheet>
        </wjGridSheet.FlexSheet>
      </div>
    );
  }
  initializeFlexSheet(flex) {
    this.setState({ flex: flex });
    flex.deferUpdate(() => {
      this._generateBasicOperatorsSheet(flex);
      let colCnt = flex.rowHeaders.columns.length;
      flex.rowHeaders.columns.removeAt(colCnt - 1);

      let rowCnt = flex.columnHeaders.rows.length;
      flex.columnHeaders.rows.removeAt(rowCnt - 1);
    });

    flex.mergeRange(new wjcGrid.CellRange(0, 0, 0, 2));
    let rows = 0;
    //rows
    for (let r = 1; r < this.state.rows.length + 1; r++) {
      flex.setCellData(r, 0, this.state.rows[rows].name);
      rows++;
      flex.mergeRange(new wjcGrid.CellRange(r, 0, r, 2));
    }
  }

  _generateBasicOperatorsSheet(flexSheet) {
    flexSheet.setCellData(0, 0, "Unit: M JPY");

    let columns = 0;
    //columns
    for (let col = 3; col < this.updateDisplayColumns().length + 3; col++) {
      flexSheet.setCellData(0, col, this.updateDisplayColumns()[columns].name);
      columns++;
    }
    if (this.state.data.totalData)
      for (let i = 0; i < this.state.data.totalData.length; i++) {
        for (let j = 0; j < 8; j++) {
          let key = Object.keys(this.state.data.totalData[i]);
          if (i === 12 || i === 14 || i === 16 || i === 20) {
            for (let j = 0; j < this.state.displayColumns.length; j++) {
              if (
                this.state.data.totalData[i][key[0]][
                  this.state.displayColumns[j].name
                ]
              ) {
                flexSheet.setCellData(
                  i + 1,
                  j + 3,
                  this.state.data.totalData[i][key[0]][
                    this.state.displayColumns[j].name
                  ].toFixed(1) + "%"
                );
              } else {
                flexSheet.setCellData(i + 1, j + 3, null);
              }
            }
          } else {
            for (let j = 0; j < this.state.displayColumns.length; j++) {
              flexSheet.setCellData(
                i + 1,
                j + 3,
                this.state.data.totalData[i][key[0]][
                  this.state.displayColumns[j].name
                ]?.toFixed(0)
              );
            }
          }
        }
      }

    flexSheet.applyCellsStyle(
      {
        textAlign: "right",
      },
      [new wjcGrid.CellRange(1, 2, 30, this.updateDisplayColumns().length + 2)]
    );

    flexSheet.applyCellsStyle(
      {
        textAlign: "center",
      },
      [new wjcGrid.CellRange(0, 0, 30, 1)]
    );
    flexSheet.applyCellsStyle(
      {
        textAlign: "center",
      },
      [new wjcGrid.CellRange(0, 3, 0, this.updateDisplayColumns().length + 2)]
    );
    flexSheet.applyCellsStyle(
      {
        backgroundColor: "#ddebf7",
      },
      [new wjcGrid.CellRange(0, 3, 0, this.updateDisplayColumns().length + 2)]
    );
    flexSheet.applyCellsStyle(
      {
        backgroundColor: "#ffffcc",
      },
      [new wjcGrid.CellRange(12, 0, 13, this.updateDisplayColumns().length + 2)]
    );
    flexSheet.applyCellsStyle(
      {
        backgroundColor: "#ffffcc",
      },
      [new wjcGrid.CellRange(16, 0, 17, this.updateDisplayColumns().length + 2)]
    );
    flexSheet.applyCellsStyle(
      {
        backgroundColor: "#ffffcc",
      },
      [new wjcGrid.CellRange(20, 0, 22, this.updateDisplayColumns().length + 2)]
    );

    flexSheet.applyCellsStyle(
      {
        backgroundColor: "#ffccff",
      },
      [new wjcGrid.CellRange(24, 0, 24, this.updateDisplayColumns().length + 2)]
    );

    flexSheet.applyCellsStyle(
      {
        backgroundColor: "#ededed",
      },
      [new wjcGrid.CellRange(5, 0, 10, this.updateDisplayColumns().length + 2)]
    );

    flexSheet.applyCellsStyle(
      {
        fontSize: "11px",
        padding: "0px",
        paddingTop: "3px",
        paddingLeft: "6px",
        paddingRight: "6px",
        paddingBottom: "3px",
      },
      [
        new wjcGrid.CellRange(
          0,
          0,
          this.state.rows.length,
          this.updateDisplayColumns().length + 3
        ),
      ]
    );

    for (let col = 0; col < this.updateDisplayColumns().length + 3; col++) {
      flexSheet.columns[col].width = "*";
      flexSheet.columns[col].minWidth = 48;
    }
    for (let row = 0; row < this.state.rows.length + 1; row++) {
      flexSheet.rows[row].height = 22.1;
    }
  }
}
export default FinancialDataSummary;

FinancialDataSummary.propTypes = {
  filterFrom: PropTypes.number.isRequired,
  filterTo: PropTypes.number.isRequired,
  fiscalYearList: PropTypes.array,
  data: PropTypes.object,
};
