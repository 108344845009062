import React, { useEffect, useState } from "react";
import {
  COMPANY_LIST_URL,
  REGISTER_URL,
  TOKEN,
} from "../../constants/api.path";
import "../../assets/css/styles.css";
import SuccessModal from "../../modalPopups/successModal";

function Register() {
  const [name, setName] = useState("");
  const [email, setEmailAddress] = useState("");
  const [password, setPassword] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [company_id, setCompanyId] = useState("");
  const [errors, setErrors] = useState("");
  const [companyList, setCompanyList] = useState([]);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [resultMessage, setResultMessage] = useState();

  function getCompanyList() {
    fetch(COMPANY_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setCompanyList(data.data.companyList);
      });
  }

  useEffect(() => {
    getCompanyList();
  }, []);

  async function userRegister(e) {
    e.preventDefault();
    let myData = {
      name,
      email,
      password,
      password_confirmation,
      // sector_id,
      company_id,
    };
    if (name !== "" && email !== "" &&
      password !== "" && password_confirmation !== "" &&
      // sector_id !== "" && 
      company_id !== "") {
      await fetch(REGISTER_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${TOKEN}`,
        },
        body: JSON.stringify(myData),
      }).then(async (response) => {
        const res = await response.json();
        if (!res.result) {
          setErrors(res.message);
        } else {
          setResultMessage(res.returnMessage);
          setSuccessModal(true);
        }
      });
    }
  }

  return (
    <div className="row justify-content-center main--body" id="mainbody">
      <div className="col-md-6">
        <div className="card">
          <div className="card-header">Register</div>
          <div className="card-body">
            <form onSubmit={userRegister}>
              <div className="row mb-3">
                <label className="col-md-4 col-form-label">Name</label>
                <div className="col-md-6">
                  <input
                    id="name"
                    type="text"
                    className="form-control"
                    value={name}
                    required
                    autoFocus
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <label className="col-md-4 col-form-label">Email Address</label>
                <div className="col-md-6">
                  <input
                    id="emailAddress"
                    type="email"
                    className="form-control"
                    value={email}
                    required
                    autoFocus
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <label className="col-md-4 col-form-label">Password</label>
                <div className="col-md-6">
                  <input
                    id="password"
                    type="password"
                    className="form-control"
                    value={password}
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <label className="col-md-4 col-form-label">
                  Password Confirmation
                </label>
                <div className="col-md-6">
                  <input
                    id="confirm_password"
                    type="password"
                    className="form-control"
                    value={password_confirmation}
                    required
                    onChange={(e) => setPasswordConfirmation(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <label className="col-md-4 col-form-label">Sector</label>
                <div className="col-md-6">
                  {/* <select
                    id="sector"
                    className="form-select"
                    onChange={(e) => setSectorId(e.target.value)}
                    required
                  >
                    <option key={0} value="" hidden>
                      Select sector
                    </option>
                    {sectorList.map((name) => (
                      <option key={name.sectorId} value={name.sectorId}>
                        {name.sectorName}
                      </option>
                    ))}
                  </select> */}
                </div>
              </div>

              <div className="row mb-3">
                <label className="col-md-4 col-form-label">Company</label>
                <div className="col-md-6">
                  <select
                    id="company"
                    className="form-select"
                    onChange={(e) => setCompanyId(e.target.value)}
                    required
                  >
                    <option key={0} value="" hidden>
                      Select company
                    </option>
                    {companyList.map((name) => (
                      <option key={name.companyId} value={name.companyId}>
                        {name.companyName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6 offset-md-4">
                  {errors && <span className="error">{errors}</span>}
                </div>
              </div>

              <div className="row mb-0">
                <div className="col-md-8 offset-md-4">
                  <button type="submit" className="btn btn-primary">
                    Register
                  </button>
                </div>
              </div>
              <SuccessModal
                show={showSuccessModal}
                title={resultMessage}
                onSubmit={() => {
                  setSuccessModal(false);
                  window.location.href = "/login";
                }}
                onHide={() => setSuccessModal(false)}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Register;
