import * as wijmo from "@grapecity/wijmo";
import * as wjChart from "@grapecity/wijmo.react.chart";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { DataConveter } from "../../../utilities/utilities";
import ErrorWidget from "../error/error-widget";
import { useMemo } from "react";
import PropTypes from "prop-types";
import { useDisclosure } from "@mantine/hooks";
import LoadingComponents from "../../common/loading";

// const mockData = [
//   {
//     country: "US",
//     sales: 1492.1379847057103,
//     expenses: 3580.272188859933,
//     downloads: 18086,
//   },
//   {
//     country: "Canada",
//     sales: 8043.360249853211,
//     expenses: 3884.2230586225323,
//     downloads: 13081,
//   },
//   {
//     country: "Mexico",
//     sales: 9419.45024100884,
//     expenses: 4342.459161057251,
//     downloads: 7795,
//   },
//   {
//     country: "UK",
//     sales: 1133.4975091416832,
//     expenses: 3247.6674591189803,
//     downloads: 14908,
//   },
//   {
//     country: "Germany",
//     sales: 7741.434419213134,
//     expenses: 206.98335578888893,
//     downloads: 9025,
//   },
//   {
//     country: "France",
//     sales: 9961.662916941521,
//     expenses: 1188.8591581010887,
//     downloads: 13950,
//   },
//   {
//     country: "Italy",
//     sales: 8329.637476473708,
//     expenses: 757.0101354186387,
//     downloads: 7188,
//   },
//   {
//     country: "Japan",
//     sales: 8081.5514369352395,
//     expenses: 677.4954132849609,
//     downloads: 4913,
//   },
//   {
//     country: "Korea",
//     sales: 9575.756683219735,
//     expenses: 4237.003371077162,
//     downloads: 9698,
//   },
//   {
//     country: "China",
//     sales: 3103.853369718419,
//     expenses: 574.4786147180947,
//     downloads: 8215,
//   },
//   {
//     country: "Autralia",
//     sales: 5031.602329131457,
//     expenses: 407.36615006440104,
//     downloads: 8409,
//   },
//   {
//     country: "New Zealand",
//     sales: 8240.633819018012,
//     expenses: 1850.1847421889272,
//     downloads: 2158,
//   },
// ];

export default function BubbleChart(props) {
  const { i, datasources, width, height, chartInitalized } = props;
  const [datasource, setDatasource] = useState([]);
  const [isLoading, loadingHandlers] = useDisclosure(true);
  const [isError, setIsError] = useState(false);
  const [serverData, setServerData] = useState([]);
  const [axis, setAxis] = useState({
    xAxis: datasources.configure.bindingX,
    yAxis: datasources.configure.bindingY,
  });
  // let chart = {};
  const initChart = (flex) => {
    flex.tooltip.content = customTooltip;
    // chart = flex;
    chartInitalized(flex);
  };
  const customTooltip = (ht) => {
    let item = ht.item;
    return (
      `<b>${item.country}</b>:<table className="chart-tip">` +
      `<tr><td>Sales</td><td>${wijmo.Globalize.format(
        item.sales,
        "c0"
      )}</td></tr>` +
      `<tr><td>Expenses</td><td>${wijmo.Globalize.format(
        item.expenses,
        "c0"
      )}</td></tr>` +
      `<tr><td>Downloads</td><td>${wijmo.Globalize.format(
        item.downloads,
        "n0"
      )}</td></tr>` +
      `</table>`
    );
  };
  const yAxis = datasources.configure.bindingY.join(",");
  const fetchData = async (path) => {
    try {
      const response = await axios.get(`json/${path}.json`);
      setServerData(DataConveter(response.data.metadata));
      setDatasource(datasources);
      loadingHandlers.close();
    } catch (error) {
      setIsError(true);
      loadingHandlers.close();
    }
  };
  const memoizedDatasources = useMemo(() => datasources, [datasources]);

  useEffect(() => {
    fetchData(i);
  }, [memoizedDatasources]);

  useEffect(() => {
    setAxis({
      xAxis: datasources.configure.bindingX,
      yAxis: datasources.configure.bindingY,
    });
  }, [datasources.configure]);

  if (isLoading)
    return (
      <div className="container-fluid">
        <LoadingComponents />
      </div>
    );

  if (isError) {
    return <ErrorWidget />;
  }

  return (
    <div className="container-fluid">
      <wjChart.FlexChart
        style={{ width: width, height: height }}
        chartType="Bubble"
        bindingX={datasource.configure.bindingX}
        header={datasource.header}
        itemsSource={serverData}
        initialized={initChart}
      >
        <wjChart.FlexChartDataLabel
          position={datasource.settings.dataLabel}
          content="{y}"
        />
        <wjChart.FlexChartLegend position="Bottom" />
        {axis.xAxis.length > 0 && (
          <wjChart.FlexChartAxis
            wjProperty="axisY"
            title={datasource.settings.leftAxisTitle}
            min={0}
          ></wjChart.FlexChartAxis>
        )}
        {axis.yAxis.length > 0 && (
          <wjChart.FlexChartAxis
            wjProperty="axisX"
            title={datasource.settings.bottomAxisTitle}
            axisLine={true}
            min={0}
          ></wjChart.FlexChartAxis>
        )}

        <wjChart.FlexChartSeries binding={yAxis} />
      </wjChart.FlexChart>
    </div>
  );
}

BubbleChart.propTypes = {
  i: PropTypes.any,
  datasources: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  chartInitalized: PropTypes.any,
};
