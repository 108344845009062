import "./pivotGrid.css";
import "@grapecity/wijmo.styles/wijmo.css";
//
import * as React from "react";
//
import "@grapecity/wijmo.touch"; // support drag/drop on touch devices
import * as Olap from "@grapecity/wijmo.react.olap";
import * as wjcOlap from "@grapecity/wijmo.olap";
import data from "../estimateByMonth.json";

class PivotGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sectors: [],
      ng: new wjcOlap.PivotEngine({
        itemsSource: data.data,
        valueFields: ["JP PO", "JP Sales", "JP GP"],
        rowFields: ["Sector Name", "Quarter"],
      }),
      collapsibleSubtotals: true,
    };
  }
  componentDidMount() { }
  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-5">
            <Olap.PivotPanel itemsSource={this.state.ng}></Olap.PivotPanel>
          </div>
          <div className="col-sm-7">
            <Olap.PivotGrid
              itemsSource={this.state.ng}
              initialized={this.initializePivotGrid.bind(this)}
            ></Olap.PivotGrid>
          </div>
        </div>
        {/* <p>
          You can configure the <b>PivotEngine</b> to show the subtotals before
          or after the data using the <b>totalsBeforeData</b>
          property:
        </p>
        <label>
          totalsBeforeData:
          <input
            id="totalsBeforeData"
            type="checkbox"
            onClick={this.onTotalsBeforeDataClick.bind(this)}
          />
        </label>
        <p>
          You can configure the <b>PivotGrid</b> to make row and column groups
          collapsible using the <b>collapsibleSubtotals</b>
          property:
        </p>
        <label>
          collapsibleSubtotals:
          <input
            id="collapsibleSubtotals"
            type="checkbox"
            checked={this.state.collapsibleSubtotals}
            onClick={this.onCollapsibleSubtotalsClick.bind(this)}
          />
        </label> */}
      </div>
    );
  }
  initializePivotGrid(pivotGrid) {
    this.pivotGrid = pivotGrid;
  }
  onTotalsBeforeDataClick(e) {
    this.pivotGrid.engine.totalsBeforeData = e.target.checked;
  }
  onCollapsibleSubtotalsClick(e) {
    this.setState({
      collapsibleSubtotals: !this.state.collapsibleSubtotals,
    });
    this.pivotGrid.collapsibleSubtotals = e.target.checked;
  }
}
export default PivotGrid;
