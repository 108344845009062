import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { FORGET_PASSWORD_URL } from "../constants/api.path";
import SuccessModal from "./successModal";
import InfoModal from "./infoModal";

export default function ForgetPasswordModal(props) {
  let { email, setEmail } = props;
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);
  const [showErrorModal, setShowErrorModal] = React.useState(false);
  const [message, setMessage] = React.useState("");

  function getResetPasswordLink() {
    if (email !== "") {
      fetch(FORGET_PASSWORD_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({ email: email }),
      })
        .then(async (response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error("Something went wrong.");
          }
        })
        .then((result) => {
          if (result.result) {
            props.onHide();
            setMessage(result.message);
            setShowSuccessModal(true);
          } else {
            setShowErrorModal(true);
            setMessage(result.message);
          }
        })
        .catch(() => {});
    }
  }

  return (
    <div>
      <Modal
        show={props.show}
        size="md"
        onHide={() => {
          props.onHide();
          setEmail("");
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Forget Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <label
              className="col-3 lh-lg"
              htmlFor="email-input"
            >
              Email Address
            </label>
            <div className="col-6">
              <input
                id="email-input"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                autoFocus
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={getResetPasswordLink}>Get reset Link</Button>
        </Modal.Footer>
      </Modal>
      <SuccessModal
        show={showSuccessModal}
        title={message}
        onSubmit={() => {
          setShowSuccessModal(false);
        }}
        onHide={() => {
          setShowSuccessModal(false);
        }}
      />
      <InfoModal
        show={showErrorModal}
        title={message}
        onSubmit={() => {
          setShowErrorModal(false);
        }}
        onHide={() => {
          setShowErrorModal(false);
        }}
      />
    </div>
  );
}

ForgetPasswordModal.propTypes = {
  email: PropTypes.string.isRequired,
  setEmail: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
};
