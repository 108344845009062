import { Group, Select, Switch, Text, TextInput } from "@mantine/core";
import { SEARCH_QUERY_FORMAT } from "../../../../utilities/constants";
import React from "react";
import PropTypes from "prop-types";

const SearchQueryToolbar = (props) => {
  const { controls, state, handleControlChange, handleInputChange } = props;

  return (
    <Group>
      <Text fz="sm">Query Name:</Text>
      <TextInput
        placeholder="Enter Query Name"
        value={state.queryName}
        error={state.queryName === "" ? "Query Name is required" : false}
        onChange={(event) =>
          handleInputChange("queryName", event.currentTarget.value)
        }
      />
      <Text fz="sm">Format:</Text>
      <Select
        placeholder="Pick one"
        name="format"
        data={SEARCH_QUERY_FORMAT}
        value={state.format}
        onChange={(event) => handleInputChange("format", event)}
      />
      <Text fz="sm">Filter</Text>
      <Switch
        name="isFilter"
        checked={controls.isFilter}
        onChange={(event) => handleControlChange(event)}
      />
      <Text fz="sm">Group</Text>
      <Switch
        name="isGroup"
        checked={controls.isGroup}
        onChange={(event) => handleControlChange(event)}
      />
      <Text fz="sm">Order</Text>
      <Switch
        name="isOrder"
        checked={controls.isOrder}
        onChange={(event) => handleControlChange(event)}
      />
      <Text fz="sm">Preview</Text>
      <Switch
        name="isPreview"
        checked={controls.isPreview}
        onChange={(event) => handleControlChange(event)}
      />
    </Group>
  );
};

export default SearchQueryToolbar;

SearchQueryToolbar.propTypes = {
  controls: PropTypes.any,
  state: PropTypes.any,
  handleControlChange: PropTypes.any,
  handleInputChange: PropTypes.any,
};
