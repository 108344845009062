import React, { useEffect, useState } from "react";
import "../../assets/css/styles.css";
import { BiEditAlt, BiPlusCircle } from "react-icons/bi";
import { MdDeleteOutline } from "react-icons/md";
import {
  FISCAL_YEAR_LIST_URL,
  EXCHANGE_RATE_CREATE_URL,
  EXCHANGE_RATE_LIST_URL,
  TOKEN,
  EXCHANGE_RATE_UPDATE_URL,
  EXCHANGE_RATE_DELETE_URL,
} from "../../constants/api.path";
import ConfirmModal from "../../modalPopups/confirmModal";
import InfoModal from "../../modalPopups/infoModal";
import SuccessModal from "../../modalPopups/successModal";
import AddEditModal from "../../modalPopups/exchangeRateAddEditModal";
import cacheUpdate from "../cacheUpdate/cacheUpdate";
import { Tooltip as ReactTooltip } from "react-tooltip";
import MonthlyExchangeRate from "./monthlyExchangeRate";

function ExchangeRate() {
  const tabs = [
    { id: 1, name: "Yearly" },
    { id: 2, name: "Monthly" },
  ];
  const [activeTab, setActiveTab] = React.useState(tabs[0].name);
  const [title, setTitle] = React.useState("");
  const [editing, setEditing] = React.useState(false);
  const [baseCurrency, setBaseCurrency] = React.useState("");
  const [activeId, setActiveId] = useState({
    activeId: "",
  });

  const [fiscalYearOption, setFiscalYearOption] = useState([{}]);
  const [exchangeRate, setExchangeRate] = useState([]);

  const [subExcRate, setSubExcRate] = useState({
    fiscalYearId: "",
    exchangeRate: "",
    currentCurrency: "",
  });

  const [showConfirmModal, setConfirmModal] = useState(false);
  const [showInfoModal, setInfoModal] = useState(false);
  const [showSuccessModal, setSuccessModal] = useState(false);
  const [showAddEditModal, setAddEditModal] = useState(false);
  const [resConfirm, setResConfirm] = useState(false);
  const [resultMessage, setResultMessage] = useState();
  const [value, setValue] = useState();
  const [exchangeRateId, setExchangeRateId] = useState();
  const [popUpType, setPopupType] = useState("");

  var updateMessage = "Are you sure to want to update?";
  var deleteMessage = "Are you sure to want to delete?";
  const [isDisabled, dropdownDisable] = useState(false);

  let header = {
    "content-type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${TOKEN}`,
  };

  const handleFormChange = (event) => {
    event.preventDefault();

    const fieldName = event.target.getAttribute("name");
    const fieldValue = event.target.value;

    const newErRate = { ...subExcRate };
    newErRate[fieldName] = fieldValue;

    setSubExcRate(newErRate);
  };

  async function handleFormSubmit(event) {
    event.preventDefault();
    if (
      subExcRate.fiscalYearId !== "" &&
      subExcRate.currentCurrency !== "" &&
      subExcRate.exchangeRate !== ""
    ) {
      if (!editing) {
        await fetch(EXCHANGE_RATE_CREATE_URL, {
          method: "POST",
          headers: header,
          body: JSON.stringify(subExcRate),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.result) {
              getExchangeRate();
              cacheUpdate(subExcRate.fiscalYearId, new Date().getMonth() + 1);
            } else {
              setResultMessage(res.message);
              setInfoModal(true);
            }
          });
      } else {
        let editExcRate = {
          fiscalYearId: subExcRate.fiscalYearId,
          currentCurrency: subExcRate.currentCurrency,
          exchangeRate: subExcRate.exchangeRate,
        };
        setConfirmModal(true);
        setResConfirm(true);
        if (resConfirm) {
          fetch(EXCHANGE_RATE_UPDATE_URL + `${subExcRate.exchangeId}`, {
            method: "PUT",
            headers: header,
            body: JSON.stringify(editExcRate),
          })
            .then((response) => {
              return response.json();
            })
            .then((res) => {
              setConfirmModal(false);
              if (res.result) {
                getExchangeRate();
                cacheUpdate(subExcRate.fiscalYearId, new Date().getMonth() + 1);
              } else {
                setResultMessage(res.message);
                setInfoModal(true);
              }
              setResConfirm(false);
            });
        }
      }
    }
  }

  function getExchangeRate() {
    fetch(EXCHANGE_RATE_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setExchangeRate(data.data.exchangeList);
        setBaseCurrency(data.data.companyMainCurrency);
      });

    // clear all input text
    setSubExcRate({
      fiscalYearId: "",
      exchangeRate: "",
      currentCurrency: "",
    });
    setEditing(false);
    setActiveId("");
    dropdownDisable(false);
  }

  useEffect(() => {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setFiscalYearOption(data.data.fiscalYearList);
      });

    getExchangeRate();
  }, []);

  function editExchangeRate(excRate) {
    setEditing(true);
    setSubExcRate({ ...excRate });
  }

  function deleteExchangeRate(delExcId) {
    fetch(EXCHANGE_RATE_DELETE_URL + `${delExcId}`, {
      method: "DELETE",
      headers: header,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        if (res.result) {
          setResultMessage(res.message);
          setSuccessModal(true);
          getExchangeRate();
        } else {
          setResultMessage(res.message);
          setInfoModal(true);
        }
      });
  }

  function catchFunction(e) {
    if (value === "Update") {
      handleFormSubmit(e);
    } else {
      deleteExchangeRate(exchangeRateId);
    }
  }

  const getValue = (e) => {
    setValue(e.target.id);
  };

  return (
    <div className="main--body">
      <div
        className="row"
        style={{ marginLeft: "0px", marginBottom: "5px", minHeight: "35px" }}
      >
        {tabs.map((tab) => (
          <div
            className="col-2 dashboardTab-parent"
            style={{
              padding: "0.5px",
              minWidth: "fit-content",
            }}
            key={tab.id}
          >
            <button
              type="button"
              className={`dashboardTab ${
                activeTab === tab.name && "dashboardActiveTab"
              }`}
              style={{ paddingLeft: "6px", paddingRight: "6px" }}
              onClick={() => {
                activeTab !== tab.name && setActiveTab(tab.name);
              }}
            >
              {tab.name}
            </button>
          </div>
        ))}
      </div>
      {activeTab === tabs[0].name && (
        <div className="row justify-content-center d-flex">
          <div className="col-md-12 mt-3 justify-content-end d-flex">
            <button
              className="actionIconOutline save bg-blue"
              data-bs-toggle="modal"
              data-bs-target="#staticBackdrop"
              value="Add"
              id="Add"
              data-tooltip-content="Add"
              onClick={(e) => {
                getValue(e);
                setAddEditModal(true);
                setPopupType("add");
                setTitle("Register Yearly Exchange Rate");
                setSubExcRate({
                  fiscalYearId: "",
                  exchangeRate: "",
                  currentCurrency: "",
                });
              }}
            >
              <BiPlusCircle style={{ pointerEvents: "none" }} />
              <ReactTooltip place="bottom" anchorId="Add" />
            </button>
          </div>
          <div className="col-md-12 mt-2 ">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th className="text-center">Fiscal Year</th>
                  <th className="text-center">Base Currency</th>
                  <th className="text-center">Exchange Rate</th>
                  <th className="text-center">Currency</th>
                  <th className="text-center">Updated Date</th>
                  <th className="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                {exchangeRate.map((erRate) => (
                  <tr
                    key={erRate.exchangeId}
                    id={erRate.exchangeId}
                    className={
                      erRate.exchangeId === activeId.activeId ? "bg-tr" : ""
                    }
                  >
                    {fiscalYearOption.map(
                      (fy) =>
                        fy.fiscalYearId === erRate.fiscalYearId && (
                          <td align="center" key={fy.fiscalYear}>
                            {fy.fiscalYear}
                          </td>
                        )
                    )}
                    <td align="center">{baseCurrency}</td>
                    <td align="right">{erRate.exchangeRate}</td>
                    <td align="center">{erRate.currentCurrency}</td>
                    <td align="right">{erRate.updateDate}</td>
                    <td className="text-center">
                      <button
                        onClick={(e) => {
                          setAddEditModal(true);
                          setPopupType("update");
                          editExchangeRate(erRate);
                          getValue(e);
                          setTitle("Update Yearly Exchange Rate");
                        }}
                        className="actionIcon edit"
                        id="Update"
                      >
                        <BiEditAlt style={{ pointerEvents: "none" }} />
                      </button>
                      <button
                        className="actionIcon delete"
                        onClick={(e) => {
                          setExchangeRateId(erRate.exchangeId);
                          setConfirmModal(true);
                          getValue(e);
                        }}
                      >
                        <MdDeleteOutline />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activeTab === tabs[1].name && (
        <MonthlyExchangeRate></MonthlyExchangeRate>
      )}

      <ConfirmModal
        show={showConfirmModal}
        title={value === "Update" ? updateMessage : deleteMessage}
        onSubmit={(e) => {
          setConfirmModal(false);
          catchFunction(e);
        }}
        onCancel={() => {
          setConfirmModal(false);
          setResConfirm(false);
        }}
        onHide={() => setConfirmModal(false)}
      />
      <InfoModal
        show={showInfoModal}
        title={resultMessage}
        onSubmit={() => setInfoModal(false)}
        onHide={() => setInfoModal(false)}
      />
      <SuccessModal
        show={showSuccessModal}
        title={resultMessage}
        onSubmit={() => setSuccessModal(false)}
        onHide={() => setSuccessModal(false)}
      />
      <AddEditModal
        show={showAddEditModal}
        title={title}
        onSubmit={() => setAddEditModal(false)}
        onHide={() => {
          setAddEditModal(false);
          setEditing(false);
        }}
        getValue={getValue}
        handleFormChange={handleFormChange}
        handleFormSubmit={handleFormSubmit}
        subExcRate={subExcRate}
        editing={editing}
        isDisabled={isDisabled}
        fiscalYearOption={fiscalYearOption}
        setAddEditModal={setAddEditModal}
        tab={tabs[0].name}
        popUpType={popUpType}
      ></AddEditModal>
    </div>
  );
}

export default ExchangeRate;
