import { Button, Container, Flex, ThemeIcon } from "@mantine/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Tabs } from "@mantine/core";

// Css
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";

import axios from "axios";

import {
  DELETE_DASHBOARD,
  DELETE_QUERY,
  GET_DASHBOARDLIST,
  GET_QUERYLIST,
  INITIAL_LAYOUT,
  SAVE_DASHBOARD,
  SCHEMA_API,
} from "../../../utilities/constants";

import { IconCode, IconLayoutCollage } from "@tabler/icons-react";
import LoadingComponents from "../../common/loading";
import DashboardCreateModal from "./dashboard-list/create-modal";
import DashboardTable from "./dashboard-list/dashboard-table";
import QueryListTable from "./query-list/querylist-table";
import { TOKEN } from "../../../../../constants/api.path";

const headerStyle = {
  textAlign: "center",
  fontWeight: 500,
  color: "#fff",
};

const Main = () => {
  const form = useForm({
    initialValues: {
      dashboardName: "",
    },
  });

  const navigate = useNavigate();

  let apiRequests = [];

  const [schemaList, setSchemaList] = useState([]);

  // Loading State handlers
  const [isLoading, loadingHandlers] = useDisclosure(true);
  const [selectedTab, setSelectedTab] = useState(() => "Dashboard");

  const [queryList, setQueryList] = useState([]);
  const [dashboardList, setDashboardList] = useState([]);

  // create query modal
  const [openModal, setOpenModal] = useState(false);

  // SID001 --> GET SCHEME DATA
  // SID002 --> GET DATA BY QUERY
  // SID003 --> GET QUERY LIST
  // SID009 --> GET DASHBOARD LIST
  const initialFetchApis = async (apiRequests) => {
    try {
      const responses = await Promise.all(apiRequests);
      setSchemaList(responses[0].data);
      if (apiRequests.length > 1) {
        let dashboardResponse = [];
        if (responses[1].data.result) {
          dashboardResponse = responses[1].data?.data.dashboardList;
        }
        let queryResponse = [];
        if (responses[2].data.result) {
          queryResponse = responses[2].data.data.queryList;
        }
        setDashboardList(dashboardResponse);
        setQueryList(queryResponse);

        // localStorage.setItem("dashboard", JSON.stringify(newDashboard));
        // localStorage.setItem("query", JSON.stringify(queryResponse.data));
      }
      loadingHandlers.close();
    } catch (error) {
      loadingHandlers.close();
    } finally {
      loadingHandlers.close();
    }
  };

  useEffect(() => {
    // *********** Localstorage feature is not required when connected with backend ********
    // const storedDashboard = JSON.parse(localStorage.getItem("dashboard"));
    // const storedQuery = JSON.parse(localStorage.getItem("query"));
    // if (storedDashboard && storedQuery) {
    //   setDashboardList(storedDashboard);
    //   setQueryList(storedQuery);
    //   loadingHandlers.close();
    // } else {
    apiRequests = [
      axios.get(SCHEMA_API, { headers: { Authorization: `Bearer ${TOKEN}` } }),
      axios.get(GET_DASHBOARDLIST, {
        headers: { Authorization: `Bearer ${TOKEN}` },
      }),
      axios.get(GET_QUERYLIST, {
        headers: { Authorization: `Bearer ${TOKEN}` },
      }),
    ];
    // }
    initialFetchApis(apiRequests);
  }, []);

  function handleCloseDashboardModal() {
    setOpenModal(false);
  }

  // SID008 --> SAVE QUERY
  function handleCreate() {
    if (selectedTab === "Dashboard") return setOpenModal(true);

    navigate("/dashboard/query/query-dashboard", {
      state: {
        query: null,
        transition: "before",
        schema: schemaList,
        dashboardList,
      },
    });
  }

  function handleSubmit(values) {
    const newDashboard = {
      // dashboard_Id: dashboardList.length + 1,
      dashboard_name: values.dashboardName,
      dashboard_layout: JSON.stringify(INITIAL_LAYOUT),
      // created_by: "Admin", // ***** These Fiels is not required when connected with backend *****
      // created_at: new Date().toString(), // ***** These Fiels is not required when connected with backend *****
      // uploaded_by: "Admin", // ***** These Fiels is not required when connected with backend *****
      // uploaded_at: new Date().toString(), // ***** These Fiels is not required when connected with backend *****
    };

    setOpenModal(false);
    // ***** These Function is  required when connected with backend *****
    // Open comment below code when connected with backend
    axios
      .post(SAVE_DASHBOARD, newDashboard, {
        headers: { Authorization: `Bearer ${TOKEN}` },
      })
      .then(
        () => {
          // setDashboardList((prev) => [...prev, newDashboard]);
          apiRequests = [
            axios.get(SCHEMA_API, {
              headers: { Authorization: `Bearer ${TOKEN}` },
            }),
            axios.get(GET_DASHBOARDLIST, {
              headers: { Authorization: `Bearer ${TOKEN}` },
            }),
            axios.get(GET_QUERYLIST, {
              headers: { Authorization: `Bearer ${TOKEN}` },
            }),
          ];
          initialFetchApis(apiRequests);
        },
        () => {}
      );
  }

  function handleDelete(id) {
    if (selectedTab === "Dashboard") {
      // localStorage.setItem("dashboard", JSON.stringify(dashboardList));
      setDashboardList((prev) => prev.filter((p) => p.dashboard_Id !== id));
      let delete_dashboard = { id: [id] };
      axios({
        method: "DELETE",
        url: DELETE_DASHBOARD,
        data: delete_dashboard,
        headers: { Authorization: `Bearer ${TOKEN}` },
      }).then((res) => {
        if (res.data.result) {
          apiRequests = [
            axios.get(SCHEMA_API, {
              headers: { Authorization: `Bearer ${TOKEN}` },
            }),
            axios.get(GET_DASHBOARDLIST, {
              headers: { Authorization: `Bearer ${TOKEN}` },
            }),
            axios.get(GET_QUERYLIST, {
              headers: { Authorization: `Bearer ${TOKEN}` },
            }),
          ];
          initialFetchApis(apiRequests);
        }
      });
    } else {
      setQueryList((prev) => prev.filter((p) => p.query_Id !== id));
      // localStorage.setItem("dashboard", JSON.stringify(queryList));
      let delete_query = { id: [id] };
      axios({
        method: "DELETE",
        url: DELETE_QUERY,
        data: delete_query,
        headers: { Authorization: `Bearer ${TOKEN}` },
      }).then((res) => {
        if (res.data.result) {
          apiRequests = [
            axios.get(SCHEMA_API, {
              headers: { Authorization: `Bearer ${TOKEN}` },
            }),
            axios.get(GET_DASHBOARDLIST, {
              headers: { Authorization: `Bearer ${TOKEN}` },
            }),
            axios.get(GET_QUERYLIST, {
              headers: { Authorization: `Bearer ${TOKEN}` },
            }),
          ];
          initialFetchApis(apiRequests);
        }
      });
    }
  }

  function handleUpdate(id) {
    if (selectedTab === "Dashboard") {
      const dashboard = dashboardList.find((d) => d.id === id);
      return navigate("/dashboard/query/dashboard-page", {
        state: {
          schema: schemaList,
          dashboard,
          dashboardList,
        },
      });
    } else {
      const selectedQuery = queryList.find((q) => q.query_id === id);
      const query = {
        query_id: selectedQuery.query_id,
        query_data_config: JSON.parse(selectedQuery.query_data_config),
        query_form: JSON.parse(selectedQuery.query_form),
      };
      navigate("/dashboard/query/query-dashboard", {
        state: {
          query: query,
          transition: "before",
          schema: schemaList,
          dashboardList,
        },
      });
    }
  }
  return (
    <Container size={"100%"} p={5}>
      {isLoading ? (
        <LoadingComponents />
      ) : (
        <React.Fragment>
          <Tabs defaultValue="dashboard_list" color="teal" variant="outline">
            <Tabs.List>
              <Tabs.Tab
                icon={
                  <ThemeIcon size="xs" color="teal">
                    <IconCode size="0.85rem" />
                  </ThemeIcon>
                }
                value="query_list"
                onClick={() => setSelectedTab(() => "Query")}
              >
                Query List
              </Tabs.Tab>
              <Tabs.Tab
                value="dashboard_list"
                icon={
                  <ThemeIcon size="xs" color="teal">
                    <IconLayoutCollage size="0.85rem" />
                  </ThemeIcon>
                }
                onClick={() => setSelectedTab(() => "Dashboard")}
              >
                Dashboard List
              </Tabs.Tab>
            </Tabs.List>
            <Flex justify="flex-end" align="flex-start" pt={5}>
              <Button color="teal" onClick={handleCreate} size="xs">
                Create {selectedTab}
              </Button>
            </Flex>

            <Tabs.Panel value="query_list" py={5}>
              <QueryListTable
                headerStyle={headerStyle}
                items={queryList}
                header="Query Name"
                onDelete={handleDelete}
                onUpdate={handleUpdate}
              />
            </Tabs.Panel>

            <Tabs.Panel value="dashboard_list" py={5}>
              <DashboardTable
                headerStyle={headerStyle}
                items={dashboardList}
                header="Dashboard Name"
                onDelete={handleDelete}
                onUpdate={handleUpdate}
              />
            </Tabs.Panel>
          </Tabs>
          <DashboardCreateModal
            open={openModal}
            form={form}
            close={handleCloseDashboardModal}
            onSubmit={handleSubmit}
          />
        </React.Fragment>
      )}
    </Container>
  );
};

export default Main;
