import React, { useEffect, useState } from "react";
import * as wjChart from "@grapecity/wijmo.react.chart";
import { getData } from "../../../mocks/scatter-chart-mock";
import PropTypes from "prop-types";

export default function ScatterChart(props) {
  const { datasources, width, height, chartInitalized } = props;

  // let chart = {};
  const [dataChart, setDataChart] = useState([]);
  // const [state, setState] = useState({
  //   series1: getData(50, 0, 3),
  //   series2: getData(30, -100, 24),
  //   series3: getData(50, 40, 214),
  // });
  const [dataLabel, setDataLabel] = useState(datasources.configure.dataLabel);
  const chartInitialized = (flex) => {
    // chart = flex;
    chartInitalized(flex);
  };

  useEffect(() => {
    setDataChart(
      datasources.data.map((element) => {
        return {
          title: element.title,
          data: getData(element.ctn, element.a, element.b),
        };
      })
    );
  }, []);

  useEffect(() => {
    setDataLabel(() => datasources.configure.dataLabel);
  }, [datasources.configure.dataLabel]);

  return (
    <div className="container-fluid">
      <wjChart.FlexChart
        style={{ width: width, height: height }}
        chartType="Scatter"
        header={datasources.header}
        bindingX="x"
        initialized={chartInitialized}
        legendToggle={datasources.configure.legendToggle}
      >
        <wjChart.FlexChartLegend
          position={datasources.configure.legendPosition}
        />

        {dataLabel === "Top" ? (
          <wjChart.FlexChartDataLabel position="Top" content="{y}" />
        ) : null}
        <wjChart.FlexChartAxis
          wjProperty="axisY"
          axisLine={true}
        ></wjChart.FlexChartAxis>
        {dataChart.map((d) => (
          <wjChart.FlexChartSeries
            key={`${d},crypto.getRandomValues()`}
            name={d.title}
            itemsSource={d.data}
            bindingX="x"
            binding="y"
          ></wjChart.FlexChartSeries>
        ))}
      </wjChart.FlexChart>
    </div>
  );
}

ScatterChart.propTypes = {
  datasources: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  chartInitalized: PropTypes.any,
};
