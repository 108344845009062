import * as React from "react";
import { useEffect, useState } from "react";

import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";

import { useAtom } from "jotai";

import PropTypes from "prop-types";
import { metadataAtom } from "../state/chart-item-atom";

function dataFiltering(rows, columns, by, rowKey, dataRows) {
  let result = [];
  let rowMap = {};
  for (let row of rows) {
    let rowObj = {};
    rowObj[rowKey] = row;
    for (let column of columns) {
      rowObj[column] = null;
    }
    rowMap[row] = rowObj;
  }
  for (let dataRow of dataRows) {
    let rowType = dataRow[0];
    let fiscalYear = dataRow[1];
    let quarter = dataRow[2];
    let value = dataRow[3];

    if (rowMap[rowType] !== undefined && quarter === by) {
      if (columns.includes(fiscalYear)) {
        rowMap[rowType][fiscalYear] = value;
      }
    }
  }

  // Add row objects to result array.
  for (let row of rows) {
    result.push(rowMap[row]);
  }

  return result;
}

function dataFilteringByMultiQuartar(rows, columns, by, rowKey, dataRows) {
  let result = [];
  let rowMap = {};

  // Create initial mapping of row types to row objects.
  for (let row of rows) {
    let rowObj = {};
    rowObj[rowKey] = row;
    for (let column of columns) {
      for (let b of by) {
        rowObj[column + " " + b] = null;
      }
    }
    rowMap[row] = rowObj;
  }

  // Populate row objects with values from data rows.
  for (let dataRow of dataRows) {
    let rowType = dataRow[0];
    let fiscalYear = dataRow[1];
    let quarter = dataRow[2];
    let value = dataRow[3];

    if (rowMap[rowType] !== undefined && by.includes(quarter)) {
      let key = fiscalYear + " " + quarter;
      if (Object.keys(rowMap[rowType]).includes(key)) {
        rowMap[rowType][key] = value;
      }
    }
  }

  // Add row objects to result array.
  for (let row of rows) {
    result.push(rowMap[row]);
  }

  return result;
}

const FlexSheet = (props) => {
  const { datasources, width, height } = props;
  const [metadataState] = useAtom(metadataAtom);
  const [serverData, setServerData] = useState([]);

  useEffect(() => {
    const answerData = metadataState["financialMetadata"];
    const attributes = datasources.configure.attributes;
    const columns = datasources.configure.columns;
    const quarters = datasources.configure.quarters;
    const rowKey = "sector-data";

    if (quarters.length === 1) {
      const dataFiltered = dataFiltering(
        attributes,
        columns,
        quarters[0],
        rowKey,
        answerData.data_rows
      );
      setServerData(dataFiltered);
    } else {
      const dataFiltered = dataFilteringByMultiQuartar(
        attributes,
        columns,
        quarters,
        rowKey,
        answerData.data_rows
      );
      setServerData(dataFiltered);
    }
  }, [datasources]);

  function initializeFlexSheet(flex) {
    if (datasources.configure.quarters.length > 1) {
      flex.rows.insert(1, new wjcGrid.Row());

      const quartersLength = datasources.configure.quarters.length;
      const columnsLength = datasources.configure.columns.length;
      let x = 0;
      let y = 1;
      let x1 = 0;
      let y1 = quartersLength;
      flex.mergeRange(new wjcGrid.CellRange(0, 0, 1, 0));
      flex.setCellData(0, 0, "Unit: M JPY");

      for (let i = 0; i < columnsLength; i++) {
        flex.mergeRange(new wjcGrid.CellRange(x, y, x1, y1));
        flex.setCellData(0, y, datasources.configure.columns[i]);
        y = y1 + 1;
        y1 = y1 + quartersLength;
      }

      let row = 1;
      // eslint-disable-next-line no-unused-vars
      for (let _column of datasources.configure.columns) {
        for (let quarter of datasources.configure.quarters) {
          flex.setCellData(1, row, quarter);
          row++;
        }
      }
    }
    flex.setCellData(0, 0, "Unit: M JPY");
  }

  return (
    <div className="container-fluid">
      <wjGridSheet.FlexSheet
        initialized={initializeFlexSheet}
        style={{ width: width, height: height }}
        headersVisibility={false}
      >
        <wjGridSheet.Sheet
          name={datasources.settings.sheetName}
          itemsSource={serverData}
        ></wjGridSheet.Sheet>
      </wjGridSheet.FlexSheet>
    </div>
  );
};

export default FlexSheet;
FlexSheet.propTypes = {
  datasources: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.number,
  chartInitalized: PropTypes.func,
};
