import React from "react";
import {
  TOKEN,
  GET_DYNAMIC_DASHBOARD_LIST,
  DELETE_DYNAMIC_DASHBOARD,
} from "../../../constants/api.path";
import { BiEditAlt } from "react-icons/bi";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { ActionIcon, Tooltip } from "@mantine/core";
import { Notifications, notifications } from "@mantine/notifications";
import ConfirmModal from "../../../modalPopups/confirmModal";
import PropTypes from "prop-types";
import moment from "moment/moment";

const getDashboardList = async () => {
  let url = GET_DYNAMIC_DASHBOARD_LIST;
  const response = await fetch(url, {
    method: "GET",
    headers: { Authorization: `Bearer ${TOKEN}` },
  });
  const result = await response.json();
  return result;
};

const DashboardList = () => {
  // variable
  const [dashboardList, setDashboardList] = React.useState([]);

  const [selectedDashboard, setSelectedDashboard] = React.useState("");

  const [showConfirm, setShowConfirm] = React.useState(false);

  const navigate = useNavigate();

  // use Effect
  React.useEffect(() => {
    getDashboardList().then((res) => {
      if (res.result === false) {
        setDashboardList([]);
        notifications.show({
          title: res.message,
          message: "No Dashboard Data",
          autoClose: 2000,
          withCloseButton: false,
        });
      } else setDashboardList(res.data);
    });
  }, []);

  // function
  const action = (e) => {
    let target = parseInt(e.target.id);
    let selectedDashboardName = dashboardList.map((dashboard) => {
      if (dashboard.id === target) {
        return dashboard;
      }
    });
    setSelectedDashboard(selectedDashboardName[0]);
    setShowConfirm((prev) => !prev);
  };

  const deleteDashboard = (id) => {
    let body = { id: [id] };
    const options = {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
      body: JSON.stringify(body),
    };

    fetch(DELETE_DYNAMIC_DASHBOARD, options)
      .then((response) => response.json())
      .then((response) => {
        notifications.show({
          title: response.message,
          message: response.result
            ? "Dashboard canvas has been deleted!"
            : "Unexpected error occur!",
          autoClose: 2000,
          withCloseButton: false,
        });
        getDashboardList().then((res) => {
          if (res.result === false) {
            setDashboardList([]);
            notifications.show({
              title: res.message,
              message: "No Dashboard Data",
              autoClose: 2000,
              withCloseButton: false,
            });
          } else setDashboardList(res.data);
        });
      })
      .catch((err) => console.error(err));
  };

  return (
    <div>
      <div
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "5px 0",
        }}
      >
        <h5>Dashboard List</h5>
        <Tooltip
          label="Create Dashboard"
          position="bottom"
          color="dark"
          withArrow
        >
          <ActionIcon
            onClick={() => {
              navigate(
                "/dashboard/dynamicdashboard/editcanvas?dashboard=null&edit=true"
              );
            }}
          >
            <AiOutlinePlusCircle
              style={{
                fontSize: "20px",
                background: "#e49b0f",
                fill: "white",
                width: "24px",
                height: "24px",
                borderRadius: "5px",
                pointerEvents: "none",
                cursor: "pointer",
              }}
            />
          </ActionIcon>
        </Tooltip>
      </div>
      <table className="table table-bordered table-hover">
        <thead className="dashboard-list">
          <tr>
            <th scope="col">Dashboard Name</th>
            <th scope="col">Create By</th>
            <th scope="col">Created At</th>
            <th scope="col">Updated By</th>
            <th scope="col">Updated At</th>
            <th scope="col" colSpan={2}>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {dashboardList.map((dashboard) => (
            <tr key={dashboard.id}>
              <th scope="col">
                <Link
                  to={`/dashboard/dynamicdashboard/canvas?dashboard=${dashboard.id}`}
                >
                  {dashboard.name}
                </Link>
              </th>
              <td>
                <Link
                  to={`/dashboard/dynamicdashboard/canvas?dashboard=${dashboard.id}`}
                >
                  {dashboard.created_by}
                </Link>
              </td>
              <td>
                <Link
                  to={`/dashboard/dynamicdashboard/canvas?dashboard=${dashboard.id}`}
                >
                  {moment(dashboard.created_at).format(
                    "MMMM/DD/YYYY h:mm:ss A"
                  )}
                </Link>
              </td>
              <td>
                <Link
                  to={`/dashboard/dynamicdashboard/canvas?dashboard=${dashboard.id}`}
                >
                  {dashboard.updated_by}
                </Link>
              </td>
              <td>
                <Link
                  to={`/dashboard/dynamicdashboard/canvas?dashboard=${dashboard.id}`}
                >
                  {moment(dashboard.updated_at).format(
                    "MMMM/DD/YYYY h:mm:ss A"
                  )}
                </Link>
              </td>
              <td id="edit">
                <Link
                  to={`/dashboard/dynamicdashboard/editcanvas?dashboard=${dashboard.id}&edit=true`}
                >
                  <BiEditAlt
                    style={{
                      fontSize: "20px",
                      background: "#e49b0f",
                      fill: "white",
                      width: "24px",
                      height: "24px",
                      borderRadius: "5px",
                      pointerEvents: "none",
                    }}
                  />
                </Link>
              </td>
              <td onClick={action} id={dashboard.id}>
                <MdOutlineDeleteOutline
                  style={{
                    fontSize: "20px",
                    background: "red",
                    fill: "white",
                    width: "24px",
                    height: "24px",
                    borderRadius: "5px",
                    pointerEvents: "none",
                  }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Notifications position="bottom-right" />
      <ConfirmModal
        show={showConfirm}
        onHide={() => {
          setShowConfirm((prev) => !prev);
        }}
        title={`Do you want to delete ${selectedDashboard.name}?`}
        onSubmit={() => {
          deleteDashboard(selectedDashboard.id);
          setShowConfirm((prev) => !prev);
        }}
        onCancel={() => {
          setShowConfirm((prev) => !prev);
        }}
      />
    </div>
  );
};

export default DashboardList;

DashboardList.propTypes = {
  changeLocation: PropTypes.func,
};
