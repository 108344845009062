import React from "react";
import { Toast } from "react-bootstrap";
import PropTypes from "prop-types";

export default function NotiModal({ status, message, show, onClose }) {
  React.useEffect(() => {
    let toast = document.getElementById("toast");
    let interval = setInterval(intervalWork, 5000);
    toast.onmouseover = clearInterval(interval);
    toast.onmouseout = () => {
      interval = setInterval(intervalWork, 5000);
    };
  }, [show]);

  function intervalWork() {
    if (show) {
      onClose();
    }
  }

  return (
    <Toast onClose={onClose} show={show} id="toast">
      <Toast.Header>
        <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
        <strong
          className="me-auto"
          style={{ color: status === "Success" ? "#4BB543" : "white" }}
        >
          {status}
        </strong>
      </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
}

NotiModal.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
  show: PropTypes.bool,
  onClose: PropTypes.func,
};
