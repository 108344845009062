import React from "react";
import { Card, Progress, Stack, Text } from "@mantine/core";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { metadataAtom } from "../state/chart-item-atom";
import PropTypes from "prop-types";
import {
  DataConveterBy,
  Financial,
  TotalProgressCalculate,
} from "../utilities/utilities";

const ProgressCard = (props) => {
  const { datasources, height } = props;
  const [serverData, setServerData] = useState({
    plan: 0,
    actual: 0,
    total: 0,
  });
  const [metadataState] = useAtom(metadataAtom);
  const [categoryUnit, setCategoryUnit] = useState("");

  useEffect(() => {
    const filteredSectordata = metadataState.filter(
      (obj) => obj["sector-data"] === datasources.configure.category
    );

    const DataConveter = DataConveterBy(
      filteredSectordata,
      datasources.configure.searchable,
      ["budget", "total"]
    );
    if (datasources.settings.unitCategory === "currency") {
      setCategoryUnit(datasources.settings.currency);
    } else {
      setCategoryUnit(datasources.settings.unit);
    }

    DataConveter.forEach((item) => {
      if (
        item[datasources.configure.searchable] ===
        datasources.configure.searchableBy
      ) {
        setServerData({
          plan: item["budget"],
          actual: item["total"],
          total: TotalProgressCalculate(item["budget"], item["total"]),
        });
      }
    });
  }, []);

  let planFormatted = Financial(
    serverData.plan,
    datasources.settings.decimal,
    categoryUnit
  );
  let actualFormatted = Financial(
    serverData.actual,
    datasources.settings.decimal,
    categoryUnit
  );

  return (
    <Card
      withBorder
      radius="md"
      padding="xs"
      sx={{
        height: height,
        backgroundColor: datasources.settings.bgColor,
      }}
    >
      <Stack h={"auto"} spacing={5}>
        <Text fz="md" fw={500} c={datasources.settings.textColor}>
          Plan: {planFormatted}
        </Text>
        <Text fz="md" fw={500} c={datasources.settings.textColor}>
          Actual: {actualFormatted}
        </Text>
        <Progress
          color={serverData.total >= 100 ? "teal" : "yellow"}
          value={serverData.total}
          label={`${serverData.total}%`}
          size="lg"
          radius="lg"
        />
      </Stack>
    </Card>
  );
};

export default ProgressCard;

ProgressCard.propTypes = {
  datasources: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.number,
  chartInitalized: PropTypes.func,
};
