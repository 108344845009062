// import axios from "axios";
import { TOKEN } from "../../../constants/api.path";

const DataService = async (path, requetObject) => {
  try {
    let response = {};
    await fetch(path, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requetObject),
    })
      .then((res) => res.json())
      .then((res) => (response = res));
    return { data: response, error: null };
  } catch (error) {
    return { data: null, error };
  }
};

export default DataService;
