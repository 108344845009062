import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

export default function DynamicDashboardAddName(props) {
  const { dashboardName, setDashboardName, onHide, onSubmit } = props;
  const [error, setError] = React.useState("");

  const submit = () => {
    let input = document.getElementById("dashboard-name-input");
    if (dashboardName.length > 0) {
      onHide();
      onSubmit();
      input.classList.remove("invalid");
      setError("");
    } else {
      setError("Please provide a name for dashboard.");
      document.getElementById("dashboard-name-input").classList.add("invalid");
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        size="md"
        onHide={() => {
          props.onHide();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Dashboard Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <label
              className="col-3 lh-lg form-label"
              htmlFor="dashboard-name-input"
            >
              Dashboard Name
            </label>
            <div className="col-6">
              <input
                id="dashboard-name-input"
                type="text"
                value={dashboardName}
                onChange={(e) => {
                  setDashboardName(e.target.value);
                }}
                className="form-control"
                autoFocus
                required
              />
              <div style={{ color: "red" }}>{error}</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" onClick={submit}>
            Set Name
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

DynamicDashboardAddName.propTypes = {
  dashboardName: PropTypes.string.isRequired,
  setDashboardName: PropTypes.func.isRequired,
  onHide: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
};
