import "bootstrap/dist/css/bootstrap.css";
import "@grapecity/wijmo.styles/wijmo.css";
import { BiZoomIn, BiZoomOut } from "react-icons/bi";
import * as wjcCore from "@grapecity/wijmo";
import * as React from "react";
import * as wjcGrid from "@grapecity/wijmo.grid";
import * as wjGridSheet from "@grapecity/wijmo.react.grid.sheet";
import * as wijmo from "@grapecity/wijmo";
import "../../assets/css/bcQuarter.css";
import moment from "moment/moment";
import {
  BC_QUARTER2_YEN_LIST,
  FISCAL_YEAR_LIST_URL,
  QUARTER_HIDE_COLUMN,
  TOKEN,
} from "../../constants/api.path";
import { Spinner } from "reactstrap";
import {
  hideRowDefault,
  hideColumnDefault,
  showColumnDefault,
} from "./hideDefaultRowColumn";
import { BiSearchAlt2 } from "react-icons/bi";
import { Tooltip as ReactTooltip } from "react-tooltip";
import cacheUpdate from "../cacheUpdate/cacheUpdate";

wjcCore.setLicenseKey(
  "bms.isgm.info,176516627692677#B0LLcNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRN5U83UZ8skeSZkWiFDezBTZYh4cQJHWHlHUUJUQMpXUY94b0tkdOR7cuRDV5JWbCl5LtJne8hHSrM5MR5WMzAlbJlnNhdFVzhESjRUNn9GU8AnQ5FTaV9Ed6EXevAXOrdXVoJEaht6RIZGR6MXa6k5UTVGcYJ6Vzx6SW3ma93USvIXTSV6M7E4UxhVaxATMpd6KUp7cVx4U4FEUs36c7YWUxoUaxoFZQBjUtBTMaxkdyI5N6B7VuRkTShXRPVTULZ4Kpdke0FUZ5syc6NGWFVmTlhTdFl5VrdUWLZkVHJjU7YWM0dTavhWWkdnamNFTzgTd05ke8JUZxU5LMBnRWVVbhZ4T7J4RMZkMY9kRBZDexgFUVFUdNJjVqV6Til5c5cWQmNDaTRlcVhjeFJWWxJVR6tmZTdmYNdDTwVzTaNUa7IkNthkbQRkNshVO9RlS6Zla8R4RiojITJCLiEDM4MzQ9QkMiojIIJCL9EDMxkDN7czN0IicfJye35XX3JSSwIjUiojIDJCLi86bpNnblRHeFBCI4VWZoNFelxmRg2Wbql6ViojIOJyes4nI5kkTRJiOiMkIsIibvl6cuVGd8VEIgIXZ7VWaWRncvBXZSBybtpWaXJiOi8kI1xSfis4N8gkI0IyQiwiIu3Waz9WZ4hXRgAydvJVa4xWdNBybtpWaXJiOi8kI1xSfiQjR6QkI0IyQiwiIu3Waz9WZ4hXRgACUBx4TgAybtpWaXJiOi8kI1xSfiMzQwIkI0IyQiwiIlJ7bDBybtpWaXJiOi8kI1xSfiUFO7EkI0IyQiwiIu3Waz9WZ4hXRgACdyFGaDxWYpNmbh9WaGBybtpWaXJiOi8kI1tlOiQmcQJCLiUTNyQTMwASMxATMyIDMyIiOiQncDJCLi2mZulmLtd6cp9yctJmI0IyctRkIsIiLkRHTgwiLvNEIyFWbuFWeNBCc53mcHBichR7UgQ5QJJiOiEmTDJCLiczN6ITO6cjM6YTM5YzNxIiOiQWSiwSfdtlOicGbmJCLiIjdyIDMyIiOiI3ZxJ"
);

class BCQuarter2YEN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flex: null,
      isFrozen: false,
      isLoading: true,
      hideRows: [],
      hideColumns: [],
      data: [],
      compName: "",
      lastDate: "",
      currentYear: new Date().getFullYear(),
      sectorList: [],
      fiscalList: [],
      selectedArray: { salesPlanMonth: new Date().getMonth() + 1 },
      monthList: [],
      salesPlanYearName: [],
      salesPlanMonthName: [],
      decimalPlaces: 0,
    };
  }

  getBCQuarter2Yen() {
    fetch(
      BC_QUARTER2_YEN_LIST +
        "/salesPlanYear/" +
        this.state.selectedArray.salesPlanYear +
        "/salesPlanMonth/" +
        this.state.selectedArray.salesPlanMonth,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    )
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState({ isLoading: false });
        if (result.result) {
          this.setState({
            lastDate: result.lastDate,
            sectorList: result.data.businessSector,
          },() => {
            cacheUpdate(
              this.state.selectedArray.salesPlanYear,
              this.state.selectedArray.salesPlanMonth
            );
          });
          this.mergeSectorCell();
          this.setState({
            compName: result.data.bcQuarter2List[0].companyName,
          });
          this.bcQuarter2DataBinding(
            result.lastDate,
            result.data.bcQuarter2List,
            result.data.businessSector
          );
        }
      });
  }

  componentDidMount() {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState(
          {
            selectedArray: {
              salesPlanYear: result.data.currentYearId,
              ...this.state.selectedArray,
            },
            fiscalList: result.data.fiscalYearList,
          },
          () => {
            this.getBCQuarter2Yen();
            this.salesPlanMonthUpdate(this.state.selectedArray.salesPlanYear);
            this.getYearMonthName();
          }
        );
      });

    fetch(QUARTER_HIDE_COLUMN + "Quarter2", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        if (result.result) {
          this.setState({ data: result.data.quarter });
        }
      });
  }

  getYearMonthName() {
    this.state.fiscalList.forEach((fiscalList) => {
      if (this.state.selectedArray.salesPlanYear == fiscalList.fiscalYearId) {
        this.setState({
          salesPlanYearName: fiscalList.fiscalYear,
          salesPlanMonthName: moment()
            .month(this.state.selectedArray.salesPlanMonth - 1)
            .format("MMM"),
        });
      }
    });
  }

  salesPlanMonthUpdate(id) {
    let key = 0;
    let data = [];
    let currentID = id ? parseInt(id) : this.state.selectedArray.salesPlanYear;
    this.state.fiscalList.forEach((fiscalList) => {
      if (fiscalList.fiscalYearId === currentID) {
        for (
          let i = new Date(fiscalList.fyStartDate).getMonth();
          i < new Date(fiscalList.fyStartDate).getMonth() + 12;
          i++
        ) {
          data.push({
            id: key + 1,
            name: moment()
              .month(i < 12 ? i : i - 12)
              .format("MMM"),
            value: i + 1 < 13 ? i + 1 : i - 11,
          });
          key++;
        }
        this.setState({ monthList: data });
      }
    });
  }

  mergeSectorCell() {
    let contentStartRow = 26;
    for (let row = 0; row < this.state.sectorList.length / 23; row++) {
      this.setBCContent(this.state.flex, contentStartRow);
      contentStartRow += 23;
    }
  }

  bcQuarter2DataBinding(lastDate, bcQ2List, sectorList) {
    let flex = this.state.flex;
    flex.selectedSheetIndex = 0;

    flex.setCellData(1, 9, "vs (" + lastDate + ")");
    flex.setCellData(1, 18, "vs (" + lastDate + ")");
    flex.setCellData(1, 27, "vs (" + lastDate + ")");
    flex.setCellData(1, 36, "vs (" + lastDate + ")");

    let r = 3;
    for (let row = 0; row < bcQ2List.length; row++) {
      if (
        (row % 10 === 0 && row / 10 === 1) ||
        (row % 15 === 0 && row / 15 === 1) ||
        (row % 19 === 0 && row / 19 === 1)
      ) {
        flex.setCellData(r, 0, "Total of " + bcQ2List[row].companyName);
        flex.setCellData(
          r,
          4,
          (bcQ2List[row].draftBottom1 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          5,
          (bcQ2List[row].draftStretch1 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          6,
          (bcQ2List[row].draftTotal1 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          7,
          (bcQ2List[row].budBudget1 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          8,
          (bcQ2List[row].budVariance1 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          9,
          (bcQ2List[row].preBudget1 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          10,
          (bcQ2List[row].preVariance1 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          11,
          (bcQ2List[row].lastYearActual1 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          12,
          (bcQ2List[row].lastYearVariance1 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          13,
          (bcQ2List[row].draftBottom2 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          14,
          (bcQ2List[row].draftStretch2 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          15,
          (bcQ2List[row].draftTotal2 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          16,
          (bcQ2List[row].budBudget2 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          17,
          (bcQ2List[row].budVariance2 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          18,
          (bcQ2List[row].preBudget2 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          19,
          (bcQ2List[row].preVariance2 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          20,
          (bcQ2List[row].lastYearActual2 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          21,
          (bcQ2List[row].lastYearVariance2 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          22,
          (bcQ2List[row].draftBottom3 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          23,
          (bcQ2List[row].draftStretch3 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          24,
          (bcQ2List[row].draftTotal3 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          25,
          (bcQ2List[row].budBudget3 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          26,
          (bcQ2List[row].budVariance3 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          27,
          (bcQ2List[row].preBudget3 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          28,
          (bcQ2List[row].preVariance3 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          29,
          (bcQ2List[row].lastYearActual3 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          30,
          (bcQ2List[row].lastYearVariance3 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          31,
          (bcQ2List[row].draftBottom4 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          32,
          (bcQ2List[row].draftStretch4 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          33,
          (bcQ2List[row].draftTotal4 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          34,
          (bcQ2List[row].budBudget4 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          35,
          (bcQ2List[row].budVariance4 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          36,
          (bcQ2List[row].preBudget4 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          37,
          (bcQ2List[row].preVariance4 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          38,
          (bcQ2List[row].lastYearActual4 * 100).toFixed(1) + "%"
        );
        flex.setCellData(
          r,
          39,
          (bcQ2List[row].lastYearVariance4 * 100).toFixed(1) + "%"
        );
      } else {
        flex.setCellData(r, 0, "Total of " + bcQ2List[row].companyName);
        flex.setCellData(
          r,
          4,
          bcQ2List[row].draftBottom1.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          5,
          bcQ2List[row].draftStretch1.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          6,
          bcQ2List[row].draftTotal1.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          7,
          bcQ2List[row].budBudget1.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          8,
          bcQ2List[row].budVariance1.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          9,
          bcQ2List[row].preBudget1.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          10,
          bcQ2List[row].preVariance1.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          11,
          bcQ2List[row].lastYearActual1.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          12,
          bcQ2List[row].lastYearVariance1.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          13,
          bcQ2List[row].draftBottom2.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          14,
          bcQ2List[row].draftStretch2.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          15,
          bcQ2List[row].draftTotal2.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          16,
          bcQ2List[row].budBudget2.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          17,
          bcQ2List[row].budVariance2.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          18,
          bcQ2List[row].preBudget2.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          19,
          bcQ2List[row].preVariance2.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          20,
          bcQ2List[row].lastYearActual2.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          21,
          bcQ2List[row].lastYearVariance2.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          22,
          bcQ2List[row].draftBottom3.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          23,
          bcQ2List[row].draftStretch3.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          24,
          bcQ2List[row].draftTotal3.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          25,
          bcQ2List[row].budBudget3.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          26,
          bcQ2List[row].budVariance3.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          27,
          bcQ2List[row].preBudget3.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          28,
          bcQ2List[row].preVariance3.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          29,
          bcQ2List[row].lastYearActual3.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          30,
          bcQ2List[row].lastYearVariance3.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          31,
          bcQ2List[row].draftBottom4.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          32,
          bcQ2List[row].draftStretch4.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          33,
          bcQ2List[row].draftTotal4.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          34,
          bcQ2List[row].budBudget4.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          35,
          bcQ2List[row].budVariance4.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          36,
          bcQ2List[row].preBudget4.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          37,
          bcQ2List[row].preVariance4.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          38,
          bcQ2List[row].lastYearActual4.toFixed(this.state.decimalPlaces)
        );
        flex.setCellData(
          r,
          39,
          bcQ2List[row].lastYearVariance4.toFixed(this.state.decimalPlaces)
        );
      }
      r++;
    }
    let b = 26;
    let contentStartRow = 26;
    for (let row = 0; row < sectorList.length / 23; row++) {
      this.setBCContent(flex, contentStartRow);
      contentStartRow += 23;
    }
    for (let row = 0; row < sectorList.length; row++) {
      if (
        ((row % 23) % 10 === 0 && (row % 23) / 10 === 1) ||
        ((row % 23) % 15 === 0 && (row % 23) / 15 === 1) ||
        ((row % 23) % 19 === 0 && (row % 23) / 19 === 1)
      ) {
        flex.setCellData(b, 0, sectorList[row].sectorName);
        if (sectorList[row].draftBottom1 === null) {
          flex.setCellData(b, 4, "0%");
        } else {
          flex.setCellData(
            b,
            4,
            parseFloat(sectorList[row].draftBottom1 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].draftStretch1 === null) {
          flex.setCellData(b, 5, "0%");
        } else {
          flex.setCellData(
            b,
            5,
            parseFloat(sectorList[row].draftStretch1 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].draftTotal1 === null) {
          flex.setCellData(b, 6, "0%");
        } else {
          flex.setCellData(
            b,
            6,
            parseFloat(sectorList[row].draftTotal1 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].budBudget1 === null) {
          flex.setCellData(b, 7, "0%");
        } else {
          flex.setCellData(
            b,
            7,
            parseFloat(sectorList[row].budBudget1 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].budVariance1 === null) {
          flex.setCellData(b, 8, "0%");
        } else {
          flex.setCellData(
            b,
            8,
            parseFloat(sectorList[row].budVariance1 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].preBudget1 === null) {
          flex.setCellData(b, 9, "0%");
        } else {
          flex.setCellData(
            b,
            9,
            parseFloat(sectorList[row].preBudget1 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].preVariance1 === null) {
          flex.setCellData(b, 10, "0%");
        } else {
          flex.setCellData(
            b,
            10,
            parseFloat(sectorList[row].preVariance1 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].lastYearActual1 === null) {
          flex.setCellData(b, 11, "0%");
        } else {
          flex.setCellData(
            b,
            11,
            parseFloat(sectorList[row].lastYearActual1 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].lastYearVariance1 === null) {
          flex.setCellData(b, 12, "0%");
        } else {
          flex.setCellData(
            b,
            12,
            parseFloat(sectorList[row].lastYearVariance1 * 100).toFixed(1) + "%"
          );
        }

        if (sectorList[row].draftBottom2 === null) {
          flex.setCellData(b, 13, "0%");
        } else {
          flex.setCellData(
            b,
            13,
            parseFloat(sectorList[row].draftBottom2 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].draftStretch2 === null) {
          flex.setCellData(b, 14, "0%");
        } else {
          flex.setCellData(
            b,
            14,
            parseFloat(sectorList[row].draftStretch2 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].draftTotal2 === null) {
          flex.setCellData(b, 15, "0%");
        } else {
          flex.setCellData(
            b,
            15,
            parseFloat(sectorList[row].draftTotal2 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].budBudget2 === null) {
          flex.setCellData(b, 16, "0%");
        } else {
          flex.setCellData(
            b,
            16,
            parseFloat(sectorList[row].budBudget2 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].budVariance2 === null) {
          flex.setCellData(b, 17, "0%");
        } else {
          flex.setCellData(
            b,
            17,
            parseFloat(sectorList[row].budVariance2 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].preBudget2 === null) {
          flex.setCellData(b, 18, "0%");
        } else {
          flex.setCellData(
            b,
            18,
            parseFloat(sectorList[row].preBudget2 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].preVariance2 === null) {
          flex.setCellData(b, 19, "0%");
        } else {
          flex.setCellData(
            b,
            19,
            parseFloat(sectorList[row].preVariance2 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].lastYearActual2 === null) {
          flex.setCellData(b, 20, "0%");
        } else {
          flex.setCellData(
            b,
            20,
            parseFloat(sectorList[row].lastYearActual2 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].lastYearVariance2 === null) {
          flex.setCellData(b, 21, "0%");
        } else {
          flex.setCellData(
            b,
            21,
            parseFloat(sectorList[row].lastYearVariance2 * 100).toFixed(1) + "%"
          );
        }

        if (sectorList[row].draftBottom3 === null) {
          flex.setCellData(b, 22, "0%");
        } else {
          flex.setCellData(
            b,
            22,
            parseFloat(sectorList[row].draftBottom3).toFixed(1) + "%"
          );
        }
        if (sectorList[row].draftStretch3 === null) {
          flex.setCellData(b, 23, "0%");
        } else {
          flex.setCellData(
            b,
            23,
            parseFloat(sectorList[row].draftStretch3 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].draftTotal3 === null) {
          flex.setCellData(b, 24, "0%");
        } else {
          flex.setCellData(
            b,
            24,
            parseFloat(sectorList[row].draftTotal3 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].budBudget3 === null) {
          flex.setCellData(b, 25, "0%");
        } else {
          flex.setCellData(
            b,
            25,
            parseFloat(sectorList[row].budBudget3 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].budVariance3 === null) {
          flex.setCellData(b, 26, "0%");
        } else {
          flex.setCellData(
            b,
            26,
            parseFloat(sectorList[row].budVariance3 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].preBudget3 === null) {
          flex.setCellData(b, 27, "0%");
        } else {
          flex.setCellData(
            b,
            27,
            parseFloat(sectorList[row].preBudget3 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].preVariance3 === null) {
          flex.setCellData(b, 28, "0%");
        } else {
          flex.setCellData(
            b,
            28,
            parseFloat(sectorList[row].preVariance3 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].lastYearActual3 === null) {
          flex.setCellData(b, 29, "0%");
        } else {
          flex.setCellData(
            b,
            29,
            parseFloat(sectorList[row].lastYearActual3 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].lastYearVariance3 === null) {
          flex.setCellData(b, 30, "0%");
        } else {
          flex.setCellData(
            b,
            30,
            parseFloat(sectorList[row].lastYearVariance3 * 100).toFixed(1) + "%"
          );
        }

        if (sectorList[row].draftBottom4 === null) {
          flex.setCellData(b, 31, "0%");
        } else {
          flex.setCellData(
            b,
            31,
            parseFloat(sectorList[row].draftBottom4 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].draftStretch4 === null) {
          flex.setCellData(b, 32, "0%");
        } else {
          flex.setCellData(
            b,
            32,
            parseFloat(sectorList[row].draftStretch4 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].draftTotal4 === null) {
          flex.setCellData(b, 33, "0%");
        } else {
          flex.setCellData(
            b,
            33,
            parseFloat(sectorList[row].draftTotal4 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].budBudget4 === null) {
          flex.setCellData(b, 34, "0%");
        } else {
          flex.setCellData(
            b,
            34,
            parseFloat(sectorList[row].budBudget4 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].budVariance4 === null) {
          flex.setCellData(b, 35, "0%");
        } else {
          flex.setCellData(
            b,
            35,
            parseFloat(sectorList[row].budVariance4 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].preBudget4 === null) {
          flex.setCellData(b, 36, "0%");
        } else {
          flex.setCellData(
            b,
            36,
            parseFloat(sectorList[row].preBudget4 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].preVariance4 === null) {
          flex.setCellData(b, 37, "0%");
        } else {
          flex.setCellData(
            b,
            37,
            parseFloat(sectorList[row].preVariance4 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].lastYearActual4 === null) {
          flex.setCellData(b, 38, "0%");
        } else {
          flex.setCellData(
            b,
            38,
            parseFloat(sectorList[row].lastYearActual4 * 100).toFixed(1) + "%"
          );
        }
        if (sectorList[row].lastYearVariance4 === null) {
          flex.setCellData(b, 39, "0%");
        } else {
          flex.setCellData(
            b,
            39,
            parseFloat(sectorList[row].lastYearVariance4 * 100).toFixed(1) + "%"
          );
        }
      } else {
        flex.setCellData(b, 0, sectorList[row].sectorName);
        flex.setCellData(
          b,
          4,
          parseFloat(sectorList[row].draftBottom1).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          5,
          parseFloat(sectorList[row].draftStretch1).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          6,
          parseFloat(sectorList[row].draftTotal1).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          7,
          parseFloat(sectorList[row].budBudget1).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          8,
          parseFloat(sectorList[row].budVariance1).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          9,
          parseFloat(sectorList[row].preBudget1).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          10,
          parseFloat(sectorList[row].preVariance1).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          11,
          parseFloat(sectorList[row].lastYearActual1).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          12,
          parseFloat(sectorList[row].lastYearVariance1).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          13,
          parseFloat(sectorList[row].draftBottom2).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          14,
          parseFloat(sectorList[row].draftStretch2).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          15,
          parseFloat(sectorList[row].draftTotal2).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          16,
          parseFloat(sectorList[row].budBudget2).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          17,
          parseFloat(sectorList[row].budVariance2).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          18,
          parseFloat(sectorList[row].preBudget2).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          19,
          parseFloat(sectorList[row].preVariance2).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          20,
          parseFloat(sectorList[row].lastYearActual2).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          21,
          parseFloat(sectorList[row].lastYearVariance2).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          22,
          parseFloat(sectorList[row].draftBottom3).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          23,
          parseFloat(sectorList[row].draftStretch3).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          24,
          parseFloat(sectorList[row].draftTotal3).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          25,
          parseFloat(sectorList[row].budBudget3).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          26,
          parseFloat(sectorList[row].budVariance3).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          27,
          parseFloat(sectorList[row].preBudget3).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          28,
          parseFloat(sectorList[row].preVariance3).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          29,
          parseFloat(sectorList[row].lastYearActual3).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          30,
          parseFloat(sectorList[row].lastYearVariance3).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          31,
          parseFloat(sectorList[row].draftBottom4).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          32,
          parseFloat(sectorList[row].draftStretch4).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          33,
          parseFloat(sectorList[row].draftTotal4).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          34,
          parseFloat(sectorList[row].budBudget4).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          35,
          parseFloat(sectorList[row].budVariance4).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          36,
          parseFloat(sectorList[row].preBudget4).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          37,
          parseFloat(sectorList[row].preVariance4).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          38,
          parseFloat(sectorList[row].lastYearActual4).toFixed(
            this.state.decimalPlaces
          )
        );
        flex.setCellData(
          b,
          39,
          parseFloat(sectorList[row].lastYearVariance4).toFixed(
            this.state.decimalPlaces
          )
        );
      }
      b++;
    }
    for (let row = 0; row < bcQ2List.length; row++) {
      flex.rows[row].height = 18;
    }

    // hide rows by default
    let initialHideRows = hideRowDefault(bcQ2List, sectorList, flex);
    this.setState({
      hideRows: [...this.state.hideRows, ...initialHideRows],
    });
  }
  handleChange(e) {
    const fieldName = e.target.getAttribute("name");
    const fieldValue = e.target.value;

    const selectedValue = { ...this.state.selectedArray };
    selectedValue[fieldName] = fieldValue;

    this.setState({ selectedArray: selectedValue }, () => {
      this.salesPlanMonthUpdate(this.state.selectedArray.salesPlanYear);
    });
  }

  render() {
    return (
      <div
        className="container-fluid bcQuarter"
        style={{ maxHeight: "calc(100vh - 38px)", overflow: "hidden" }}
      >
        {this.state.isLoading && (
          <div className="loader">
            <Spinner color="white" />
          </div>
        )}
        <div className="row" style={{ margin: "0" }}>
          <div className="col-md-8 button">
            <p>
              <b>
                <u>
                  {this.state.currentYear} Budget for JUL-SEP <br></br>
                  {this.state.compName} Company <br></br>
                  Last Year {this.state.lastDate}
                </u>
              </b>
            </p>
          </div>
        </div>

        <div
          className="row justify-content-between"
          style={{ marginLeft: "12px" }}
        >
          <div className="col " style={{ padding: "1.5px" }}>
            <div className="row">
              <div className="col-auto" style={{ padding: "1.5px" }}>
                <select
                  name="salesPlanYear"
                  id="salesPlanYear"
                  data-tooltip-content="Fiscal Year"
                  className="form-select"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={this.state.selectedArray.salesPlanYear}
                >
                  <option key={0} value="" hidden>
                    Fiscal Year
                  </option>
                  {this.state.fiscalList.map((item) => (
                    <option key={item.fiscalYearId} value={item.fiscalYearId}>
                      {item.fiscalYear}
                    </option>
                  ))}
                </select>
                <ReactTooltip place="top" anchorId="salesPlanYear" />
              </div>
              <div className="col-auto" style={{ padding: "1.5px" }}>
                <select
                  name="salesPlanMonth"
                  id="salesPlanMonth"
                  data-tooltip-content="Month"
                  className="form-select"
                  onChange={(e) => {
                    this.handleChange(e);
                  }}
                  value={this.state.selectedArray.salesPlanMonth}
                >
                  <option value="" hidden>
                    Month
                  </option>
                  {this.state.monthList.map((month) => (
                    <option key={month.id} value={month.value}>
                      {month.name}
                    </option>
                  ))}
                </select>
                <ReactTooltip place="top" anchorId="salesPlanMonth" />
              </div>
              <div className=" col-auto " style={{ padding: "1.5px" }}>
                <button
                  id="search"
                  data-tooltip-content="Search"
                  type="button"
                  style={{ margin: "0", padding: "0px" }}
                  className="actionIconOutline search bg-blue  "
                  onClick={() => {
                    this.setState({ isLoading: true });
                    this.getYearMonthName();
                    this.getBCQuarter2Yen();
                  }}
                >
                  <BiSearchAlt2 />
                  <ReactTooltip place="top" anchorId="search" />
                </button>
              </div>
              <div className="col-auto" style={{ padding: "1.5px" }}>
                <select
                  name="decimalPlaces"
                  id="decimalPlaces"
                  className="form-select"
                  onChange={(e) => {
                    this.setState({ decimalPlaces: e.target.value }, () => {
                      this.getBCQuarter2Yen();
                    });
                  }}
                  value={this.state.decimalPlaces}
                >
                  <option key={0} value={0}>
                    0
                  </option>
                  <option key={1} value={1}>
                    1
                  </option>
                  <option key={2} value={2}>
                    2
                  </option>
                  <option key={3} value={3}>
                    3
                  </option>
                  <option key={4} value={4}>
                    4
                  </option>
                  <option key={5} value={5}>
                    5
                  </option>
                  <option key={6} value={6}>
                    6
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div className="col" style={{ padding: "1.5px" }}>
            <div className="row justify-content-end">
              <div className="col" style={{ padding: "1.5px" }}>
                <button
                  type="button"
                  className="actionTextBtn"
                  value="Show"
                  onClick={() => this.showRows(this.state.flex)}
                >
                  Show Rows
                </button>
              </div>
              <div className="col" style={{ padding: "1.5px" }}>
                <button
                  type="button"
                  className="actionTextBtn"
                  value="Hide"
                  onClick={() => this.hideRows(this.state.flex)}
                >
                  Hide Rows
                </button>
              </div>
              <div className="col" style={{ padding: "1.5px" }}>
                <button
                  type="button"
                  className="actionTextBtn"
                  value="Show"
                  onClick={() => this.showColumns(this.state.flex)}
                >
                  Show Columns
                </button>
              </div>
              <div className="col" style={{ padding: "1.5px" }}>
                <button
                  type="button"
                  className="actionTextBtn w-200"
                  value="Hide"
                  onClick={() => this.hideColumns(this.state.flex)}
                >
                  Hide Columns
                </button>
              </div>
              <div className="col" style={{ padding: "1.5px" }}>
                <button
                  type="button"
                  className="actionTextBtn"
                  onClick={this.freezeCells.bind(this)}
                >
                  {this.state.isFrozen ? "UnFreeze" : "Freeze"}
                </button>
              </div>
              <div className="col-auto" style={{ padding: "1.5px" }}>
                <button
                  id="ZoomIn"
                  data-tooltip-content="ZoomIn"
                  type="button"
                  className=" actionIconOutline2 bg-grey "
                  onClick={() => this.zoomIn()}
                  style={{
                    width: "30px",
                    height: "30px",
                    border: "none",
                    borderRadius: "5px",
                  }}
                >
                  <BiZoomIn className="icon" />
                  <ReactTooltip anchorId="ZoomIn" />
                </button>
              </div>
              <div className="col-auto" style={{ padding: "1.5px" }}>
                <button
                  id="ZoomOut"
                  data-tooltip-content="ZoomOut"
                  type="button"
                  className="actionIconOutline2 bg-grey "
                  onClick={() => this.zoomOut()}
                  style={{
                    width: "30px",
                    height: "30px",
                    border: "none",
                    borderRadius: "5px",
                  }}
                >
                  <BiZoomOut className="icon" />
                  <ReactTooltip anchorId="ZoomOut" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <wjGridSheet.FlexSheet
          initialized={this.initializeFlexSheet.bind(this)}
          isReadOnly={true}
          id="bcQ2Sheet"
          style={{
            height: "500px",
          }}
        >
          <wjGridSheet.Sheet
            name="BC Q2 YEN"
            columnCount={40}
          ></wjGridSheet.Sheet>
        </wjGridSheet.FlexSheet>
      </div>
    );
  }

  initializeFlexSheet(flex) {
    flex.deferUpdate(() => {
      this._generateBasicOperatorsSheet(flex);
    });
  }

  _generateBasicOperatorsSheet(flexSheet) {
    flexSheet.setCellData(0, 0, " ");
    flexSheet.setCellData(0, 4, "July");
    flexSheet.setCellData(1, 4, "Draft Budget");
    flexSheet.setCellData(1, 7, "vs Budget");
    flexSheet.setCellData(1, 11, "vs Last Year");
    flexSheet.setCellData(2, 4, "Bottom");
    flexSheet.setCellData(2, 5, "Stretch");
    flexSheet.setCellData(2, 6, "Total");
    flexSheet.setCellData(2, 7, "Budget");
    flexSheet.setCellData(2, 8, "Variance");
    flexSheet.setCellData(2, 9, "Previous");
    flexSheet.setCellData(2, 10, "Variance");
    flexSheet.setCellData(2, 11, "Actual");
    flexSheet.setCellData(2, 12, "Variance");

    flexSheet.setCellData(0, 13, "August");
    flexSheet.setCellData(1, 13, "Draft Budget");
    flexSheet.setCellData(1, 16, "vs Budget");
    flexSheet.setCellData(1, 20, "vs Last Year");
    flexSheet.setCellData(2, 13, "Bottom");
    flexSheet.setCellData(2, 14, "Stretch");
    flexSheet.setCellData(2, 15, "Total");
    flexSheet.setCellData(2, 16, "Budget");
    flexSheet.setCellData(2, 17, "Variance");
    flexSheet.setCellData(2, 18, "Previous");
    flexSheet.setCellData(2, 19, "Variance");
    flexSheet.setCellData(2, 20, "Actual");
    flexSheet.setCellData(2, 21, "Variance");

    flexSheet.setCellData(0, 22, "September");
    flexSheet.setCellData(1, 22, "Draft Budget");
    flexSheet.setCellData(1, 25, "vs Budget");
    flexSheet.setCellData(1, 29, "vs Last Year");
    flexSheet.setCellData(2, 22, "Bottom");
    flexSheet.setCellData(2, 23, "Stretch");
    flexSheet.setCellData(2, 24, "Total");
    flexSheet.setCellData(2, 25, "Budget");
    flexSheet.setCellData(2, 26, "Variance");
    flexSheet.setCellData(2, 27, "Previous");
    flexSheet.setCellData(2, 28, "Variance");
    flexSheet.setCellData(2, 29, "Actual");
    flexSheet.setCellData(2, 30, "Variance");

    flexSheet.setCellData(0, 31, "( JUL-SEP )");
    flexSheet.setCellData(1, 31, "Draft Budget");
    flexSheet.setCellData(1, 34, "vs Budget");
    flexSheet.setCellData(1, 38, "vs Last Year");
    flexSheet.setCellData(2, 31, "Bottom");
    flexSheet.setCellData(2, 32, "Stretch");
    flexSheet.setCellData(2, 33, "Total");
    flexSheet.setCellData(2, 34, "Budget");
    flexSheet.setCellData(2, 35, "Variance");
    flexSheet.setCellData(2, 36, "Previous");
    flexSheet.setCellData(2, 37, "Variance");
    flexSheet.setCellData(2, 38, "Actual");
    flexSheet.setCellData(2, 39, "Variance");

    this.setBCContent(flexSheet, 3);

    flexSheet.applyCellsStyle(
      {
        background: "#ccffff",
      },
      [
        new wjcGrid.CellRange(0, 4, 0, 10),
        new wjcGrid.CellRange(0, 11, 0, 19),
        new wjcGrid.CellRange(0, 20, 0, 29),
        new wjcGrid.CellRange(0, 30, 0, 39),
      ]
    );

    flexSheet.mergeRange(new wjcGrid.CellRange(0, 0, 2, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 4, 0, 12));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 13, 0, 21));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 22, 0, 30));
    flexSheet.mergeRange(new wjcGrid.CellRange(0, 31, 0, 39));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 4, 1, 6));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 7, 1, 8));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 9, 1, 10));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 11, 1, 12));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 13, 1, 15));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 16, 1, 17));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 18, 1, 19));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 20, 1, 21));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 22, 1, 24));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 25, 1, 26));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 27, 1, 28));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 29, 1, 30));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 31, 1, 33));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 34, 1, 35));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 36, 1, 37));
    flexSheet.mergeRange(new wjcGrid.CellRange(1, 38, 1, 39));

    flexSheet.selectedSheetChanged.addHandler(() => {
      if (flexSheet.frozenColumns > 0 || flexSheet.frozenRows > 0) {
        this.setState({
          isFrozen: true,
        });
      } else {
        this.setState({
          isFrozen: false,
        });
      }
    });
    this.setState({
      flex: flexSheet,
    });
    for (let col = 0; col < 40; col++) {
      flexSheet.columns[col].width = "*";
      flexSheet.columns[col].minWidth = 52;
    }
  }
  setBCContent(flexSheet, r) {
    flexSheet.mergeRange(new wjcGrid.CellRange(r, 0, r + 22, 0));
    flexSheet.mergeRange(new wjcGrid.CellRange(r, 2, r, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 1, 2, r + 1, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 2, 1, r + 2, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 3, 2, r + 3, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 4, 2, r + 4, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 5, 1, r + 5, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 6, 2, r + 6, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 7, 2, r + 7, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 8, 1, r + 8, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 9, 1, r + 9, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 10, 1, r + 10, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 11, 2, r + 11, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 12, 2, r + 12, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 13, 1, r + 13, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 14, 1, r + 14, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 15, 1, r + 15, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 16, 1, r + 16, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 17, 1, r + 17, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 18, 1, r + 18, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 19, 1, r + 19, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 20, 1, r + 20, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 21, 1, r + 21, 3));
    flexSheet.mergeRange(new wjcGrid.CellRange(r + 22, 1, r + 22, 3));

    flexSheet.setCellData(r, 2, "NESIC Tokyo");
    flexSheet.setCellData(r + 1, 2, "Other");
    flexSheet.setCellData(r + 2, 1, "Order Received");
    flexSheet.setCellData(r + 3, 2, "NESIC Tokyo");
    flexSheet.setCellData(r + 4, 2, "Other");
    flexSheet.setCellData(r + 5, 1, "Sales");
    flexSheet.setCellData(r + 6, 2, "NESIC Tokyo");
    flexSheet.setCellData(r + 7, 2, "Other");
    flexSheet.setCellData(r + 8, 1, "Costs");
    flexSheet.setCellData(r + 9, 1, "GP");
    flexSheet.setCellData(r + 10, 1, "Cost Ratio");
    flexSheet.setCellData(r + 11, 2, "Sales Expenses");
    flexSheet.setCellData(r + 12, 2, "GA Expenses");
    flexSheet.setCellData(r + 13, 1, "SGA");
    flexSheet.setCellData(r + 14, 1, "OP");
    flexSheet.setCellData(r + 15, 1, "OP Ratio");
    flexSheet.setCellData(r + 16, 1, "Non-Operating Income");
    flexSheet.setCellData(r + 17, 1, "Non-Operating Expense");
    flexSheet.setCellData(r + 18, 1, "NP");
    flexSheet.setCellData(r + 19, 1, "NP Ratio");
    flexSheet.setCellData(r + 20, 1, "Purchase to NESIC-G");
    flexSheet.setCellData(r + 21, 1, "Consolidated OR");
    flexSheet.setCellData(r + 22, 1, "Consolidated Sales");

    // hide columns by default
    hideColumnDefault(flexSheet, this.state.data);

    flexSheet.applyCellsStyle(
      {
        fontWeight: "bold",
      },
      [
        new wjcGrid.CellRange(0, 0, 2, 39),
        new wjcGrid.CellRange(r, 0, r + 22, 3),
      ]
    );

    flexSheet.applyCellsStyle(
      {
        fontSize: "10px",
        textAlign: "right",
        padding: "0px",
        paddingRight: "3px",
        paddingTop: "2px",
      },
      [new wjcGrid.CellRange(r, 4, r + 22, 39)]
    );

    flexSheet.applyCellsStyle(
      {
        background: "yellow",
      },
      [
        new wjcGrid.CellRange(1, 4, 1, 6),
        new wjcGrid.CellRange(1, 13, 1, 15),
        new wjcGrid.CellRange(1, 22, 1, 24),
        new wjcGrid.CellRange(1, 31, 1, 33),
        new wjcGrid.CellRange(2, 6, r + 22, 6),
        new wjcGrid.CellRange(2, 15, r + 22, 15),
        new wjcGrid.CellRange(2, 24, r + 22, 24),
        new wjcGrid.CellRange(2, 33, r + 22, 33),
      ]
    );
  }

  freezeCells() {
    this.state.flex.freezeAtCursor();
    if (this.state.flex.frozenColumns > 0 || this.state.flex.frozenRows > 0) {
      this.setState({
        isFrozen: true,
      });
    } else {
      this.setState({
        isFrozen: false,
      });
    }
  }

  zoomOut() {
    var gridContainer = document.getElementById("bcQ2Sheet");
    if (gridContainer.classList.contains("zoomClass")) {
      wijmo.removeClass(gridContainer, "zoomClass");
    }
    if (gridContainer.classList.contains("zoomClass1")) {
      wijmo.removeClass(gridContainer, "zoomClass1");
      wijmo.addClass(gridContainer, "zoomClass");
    }
  }

  zoomIn() {
    var gridContainer = document.getElementById("bcQ2Sheet");
    if (gridContainer.classList.contains("zoomClass")) {
      wijmo.addClass(gridContainer, "zoomClass1");
    } else {
      wijmo.addClass(gridContainer, "zoomClass");
    }
  }

  // show selected row by button
  showRows(flex) {
    var selectionStart = flex.selection.row2;
    if (selectionStart === 0) {
      this.state.hideRows.map((row) => (flex.rows[row.index].visible = true));
      this.setState({ hideRows: [] });
    } else {
      var selectionEnd = flex.selection.row;
      for (let i = selectionStart; i <= selectionEnd; i++) {
        flex.rows[i].visible = true;
      }
    }
  }

  // hide selected row by button
  hideRows(flex) {
    this.setState({
      hideRows: [...this.state.hideRows, ...flex.selectedRows],
    });
    flex.selectedRows.map(
      (selectedRow) => (flex.rows[selectedRow.index].visible = false)
    );
  }

  // show selected column by button
  showColumns(flex) {
    var selectionStart = flex.selection.col2;
    if (selectionStart === 0) {
      this.state.hideColumns.map(
        (column) => (flex.columns[column.index].visible = true)
      );
      this.setState({ hideColumns: [] });
      showColumnDefault(flex, this.state.data);
    } else {
      var selectionEnd = flex.selection.col;
      for (let i = selectionStart; i <= selectionEnd; i++) {
        flex.columns[i].visible = true;
      }
    }
  }

  // hide selected column by button
  hideColumns(flex) {
    var selectionStart = flex.selection.col2;
    var selectionEnd = flex.selection.col;
    var hideColumns = [];
    for (let i = selectionStart; i <= selectionEnd; i++) {
      flex.columns[i].visible = false;
      hideColumns = [...hideColumns, flex.columns[i]];
    }
    this.setState({ hideColumns: [...this.state.hideColumns, ...hideColumns] });
  }
}
export default BCQuarter2YEN;
