import { Flex, Select } from "@mantine/core";
import React from "react";
import PropTypes from "prop-types";

export default function SearchQueryTable(props) {
  const { tableColumns, data, handleTableChange } = props;
  return (
    <Flex
      mih={50}
      bg="#eee"
      p={5}
      justify="flex-start"
      align="flex-start"
      direction="column"
      wrap="wrap"
    >
      <Select
        label="Table"
        placeholder="Select Table"
        data={tableColumns}
        value={data.tableName}
        onChange={(value) => handleTableChange("tableName", value)}
      />
    </Flex>
  );
}

SearchQueryTable.propTypes = {
  tableColumns: PropTypes.any,
  data: PropTypes.any,
  handleTableChange: PropTypes.any,
};
