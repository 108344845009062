import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { Tooltip as ReactTooltip } from "react-tooltip";

function AddEditModal(props) {
  return (
    <div>
      <Modal show={props.show} size="md" onHide={props.onHide} centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className=" col-md-auto  " onSubmit={props.handleFormSubmit}>
            <div className="row col-md-auto mt-1 justify-content-center">
              <label className="col-md-3 col-form-label">Fiscal Year</label>
              <div className="col-md-8 g-0 ">
                <select
                  onChange={props.handleFormChange}
                  className="form-select"
                  name="fiscalYearId"
                  value={props.subExcRate.fiscalYearId}
                  disabled={props.popUpType === "update" ? true : false}
                  required
                >
                  <option value="" hidden>
                    Select Fiscal Year
                  </option>
                  {props.fiscalYearOption.map((item, index) => (
                    <option key={index + 1} value={item.fiscalYearId}>
                      {item.fiscalYear}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {props.tab === "Monthly" && (
              <div className="row col-md-auto mt-1 justify-content-center">
                <label className="col-md-3 col-form-label">Sales Month</label>
                <div className="col-md-8 g-0 ">
                  <select
                    onChange={props.handleFormChange}
                    className="form-select"
                    name="salesMonth"
                    value={props.subExcRate.salesMonth}
                    disabled={props.popUpType === "update" ? true : false}
                    required
                  >
                    <option value="" hidden>
                      Select Sales Month
                    </option>
                    {props.months.map((month) => (
                      <option key={month.id} value={month.value}>
                        {month.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}

            <div className="row col-md-auto mt-1 justify-content-center">
              <label className="col-md-3 col-sm-4 col-form-label">
                Currency
              </label>
              <div className="col-md-8 col-sm-7 g-0">
                <select
                  name="currentCurrency"
                  className="form-select"
                  onChange={props.handleFormChange}
                  value={props.subExcRate.currentCurrency}
                  required
                >
                  <option value="" hidden>
                    Currency
                  </option>
                  <option key="1" value="JPY">
                    JPY
                  </option>
                  <option key="2" value="MMK">
                    MMK
                  </option>
                </select>
              </div>
            </div>

            <div
              className="row col-md-auto mt-1 justify-content-center"
              style={{ paddingRight: "0" }}
            >
              <label className=" col-md-3 col-form-label">Exchange Rate</label>
              <div className="col-md-8 g-0">
                <input
                  name="exchangeRate"
                  type="number"
                  step="any"
                  className="form-control"
                  onChange={props.handleFormChange}
                  value={props.subExcRate.exchangeRate}
                  required
                  placeholder="Exchange Rate"
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <form onSubmit={props.handleFormSubmit}>
            <button
              className="popUpBtn save bg-blue"
              id={props.editing ? "Update" : "Add"}
              data-tooltip-content={props.editing ? "Update" : "Add"}
              onClick={(e) => {
                props.getValue(e);
                props.setAddEditModal(false);
              }}
              style={{}}
            >
              {props.editing ? (
                <span id="Update">Update</span>
              ) : (
                <span id="Add">Save</span>
              )}

              <ReactTooltip
                place="bottom"
                anchorId={props.editing ? "Update" : "Add"}
              />
            </button>
          </form>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AddEditModal;

AddEditModal.propTypes = {
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  subExcRate: PropTypes.func.isRequired,
  editing: PropTypes.bool.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.func.isRequired,
  fiscalYearOption: PropTypes.func.isRequired,
  show: PropTypes.func.isRequired,
  setAddEditModal: PropTypes.func.isRequired,
  tab: PropTypes.string.isRequired,
  months: PropTypes.array.isRequired,
  popUpType: PropTypes.string.isRequired,
};
