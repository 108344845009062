import React from "react";
import { Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment/moment";
import {
  FISCAL_YEAR_LIST_URL,
  SALES_PLAN_CLONE_URL,
  TOKEN,
} from "../constants/api.path";
import CloneConfirmModal from "./cloneConfirmModal";
import SuccessModal from "./successModal";
import InfoModal from "./infoModal";

class CloneModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      onHide: props.onHide,
      show: props.show,
      title: props.title,
      onSubmit: props.onSubmit,
      salesPlanMonthList: [],
      fiscalList: [],
      currentYearId: 0,
      selectedFiscalYear: 0,
      selectedMonth: new Date().getMonth() + 1,
      resultMessage: "",
      showConfirmModal: false,
      showSuccessModal: "",
      showInfoModal: "",
      salesPlanYearName: [],
      salesPlanMonthName: [],
    };
  }
  componentDidMount() {
    fetch(FISCAL_YEAR_LIST_URL, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${TOKEN}`,
      },
    })
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        this.setState(
          {
            fiscalList: result.data.fiscalYearList,
            currentYearId: result.data.currentYearId,
            selectedFiscalYear: result.data.currentYearId,
          },
          () => {
            this.salesPlanMonthUpdate(result.data.currentYearId);
          }
        );
      });
    this.getYearMonthName();
  }

  cloneSalePlan(selectedYear, selectedMonth) {
    fetch(
      SALES_PLAN_CLONE_URL +
        "/salesPlanYear/" +
        selectedYear +
        "/salesPlanMonth/" +
        selectedMonth,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      }
    )
      .then(async (response) => {
        return response.json();
      })
      .then((result) => {
        if (result.result) {
          this.setState({
            resultMessage: result.message,
            showSuccessModal: true,
          });
        } else {
          this.setState({
            resultMessage: result.message,
            showInfoModal: true,
          });
        }
      });
  }

  salesPlanMonthUpdate(id) {
    let key = 0;
    let data = [];
    let currentID = id ? parseInt(id) : this.state.selectedFiscalYear;
    this.state.fiscalList.forEach((fiscalList) => {
      if (fiscalList.fiscalYearId === currentID) {
        for (
          let i = new Date(fiscalList.fyStartDate).getMonth();
          i < new Date(fiscalList.fyStartDate).getMonth() + 12;
          i++
        ) {
          data.push({
            id: key + 1,
            name: moment()
              .month(i < 12 ? i : i - 12)
              .format("MMM"),
            value: i + 1 < 13 ? i + 1 : i - 11,
          });
          key++;
        }
        this.setState({ salesPlanMonthList: data });
      }
    });
  }
  onSalesPlanYearChange(e) {
    this.setState({ selectedFiscalYear: e.target.value });
    this.salesPlanMonthUpdate(e.target.value);
  }

  onSalesPlanMonthChange(e) {
    this.setState({ selectedMonth: e.target.value });
  }

  getYearMonthName() {
    this.state.fiscalList.forEach((fiscalList) => {
      if (this.state.selectedFiscalYear == fiscalList.fiscalYearId) {
        this.setState({
          salesPlanYearName: fiscalList.fiscalYear,
          salesPlanMonthName: moment()
            .month(this.state.selectedMonth - 1)
            .format("MMM"),
        });
      }
    });
  }
  render() {
    return (
      <div>
        <Modal
          size="md"
          onHide={this.state.onHide}
          show={this.props.show}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Clone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row justify-content-center" style={{ margin: "0" }}>
              <div className="col-auto p-2">{this.state.title}</div>
              <div className="col-auto p-1">
                <select
                  name="salesPlanYear"
                  id="salesPlanYear"
                  data-tooltip-content="Sales Plan Year"
                  className="form-select"
                  onChange={(e) => {
                    this.onSalesPlanYearChange(e);
                  }}
                  value={this.state.selectedFiscalYear}
                >
                  <option key={0} value="" hidden>
                    Sales Plan Year
                  </option>
                  {this.state.fiscalList.map((item) => (
                    <option key={item.fiscalYearId} value={item.fiscalYearId}>
                      {item.fiscalYear}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-auto p-1">
                <select
                  name="salesPlanMonth"
                  id="salesPlanMonth"
                  data-tooltip-content="Sales Plan Month"
                  className="form-select"
                  onChange={(e) => {
                    this.onSalesPlanMonthChange(e);
                  }}
                  value={this.state.selectedMonth}
                >
                  <option value="" hidden>
                    Sales Plan Month
                  </option>
                  {this.state.salesPlanMonthList.map((month) => (
                    <option key={month.id} value={month.value}>
                      {month.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={() => {
                this.state.onSubmit();
                this.setState({ showConfirmModal: true }, () => {
                  this.getYearMonthName();
                });
              }}
            >
              Clone
            </Button>
          </Modal.Footer>
        </Modal>
        <CloneConfirmModal
          onHide={() => {
            this.setState({ showConfirmModal: false });
          }}
          show={this.state.showConfirmModal}
          onSubmit={() => {
            this.setState({ showConfirmModal: false }, () => {
              this.cloneSalePlan(
                this.state.selectedFiscalYear,
                this.state.selectedMonth
              );
            });
          }}
          onCancel={() => {
            this.setState({ showConfirmModal: false }, () => {
              this.setState({
                selectedFiscalYear: this.state.currentYearId,
                selectedMonth: new Date().getMonth() + 1,
              });
            });
          }}
          salesPlanYearName={this.state.salesPlanYearName}
          salesPlanMonthName={this.state.salesPlanMonthName}
        />
        <SuccessModal
          show={this.state.showSuccessModal}
          onHide={() => {
            this.setState(
              {
                showSuccessModal: false,
              },
              () => {
                this.setState({
                  selectedFiscalYear: this.state.currentYearId,
                  selectedMonth: new Date().getMonth() + 1,
                });
              }
            );
          }}
          title={this.state.resultMessage}
          onSubmit={() => {
            this.setState(
              {
                showSuccessModal: false,
              },
              () => {
                this.setState({
                  selectedFiscalYear: this.state.currentYearId,
                  selectedMonth: new Date().getMonth() + 1,
                });
              }
            );
          }}
        />
        <InfoModal
          show={this.state.showInfoModal}
          onHide={() => {
            this.setState(
              {
                showInfoModal: false,
              },
              () => {
                this.setState({
                  selectedFiscalYear: this.state.currentYearId,
                  selectedMonth: new Date().getMonth() + 1,
                });
              }
            );
          }}
          title={this.state.resultMessage}
          onSubmit={() => {
            this.setState(
              {
                showInfoModal: false,
              },
              () => {
                this.setState({
                  selectedFiscalYear: this.state.currentYearId,
                  selectedMonth: new Date().getMonth() + 1,
                });
              }
            );
          }}
        />
      </div>
    );
  }
}

export default CloneModal;

CloneModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
