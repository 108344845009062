import React from "react";
import PropTypes from "prop-types";

// wijmo
import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";

export default function DashboardSalesAandB({ data }) {
  return (
    <div>
      <wjChart.FlexChart
        bindingX="name"
        itemsSource={data}
        chartType="Column"
        stacking="Stacked"
        selectionMode="Series"
        legendToggle={true}
        palette={[
          "#ff5349",
          "#808080",
          "#FEF250",
          "#88BDE6",
          "#32CD32",
          "#00FFFF",
        ]}
      >
        <wjChart.FlexChartLegend position="Right"></wjChart.FlexChartLegend>
        <wjChart.FlexChartSeries
          name="ICT"
          binding="ICT"
        ></wjChart.FlexChartSeries>
        <wjChart.FlexChartSeries
          name="Facilities"
          binding="Facility"
        ></wjChart.FlexChartSeries>
        <wjChart.FlexChartSeries
          name="Fiber"
          binding="Fiber"
        ></wjChart.FlexChartSeries>
        <wjChart.FlexChartSeries
          name="DX/BPO"
          binding="Software"
        ></wjChart.FlexChartSeries>
        <wjChart.FlexChartSeries
          name="日本支店"
          binding="JP Business"
        ></wjChart.FlexChartSeries>
        <wjChart.FlexChartDataLabel
          content="{y}"
          position="Bottom"
        ></wjChart.FlexChartDataLabel>
        <wjChartAnimate.FlexChartAnimation />
      </wjChart.FlexChart>
    </div>
  );
}

DashboardSalesAandB.propTypes = {
  data: PropTypes.isRequired,
};
