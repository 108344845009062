import React, { useState } from "react";

import { isArray } from "@grapecity/wijmo";
import { Palettes } from "@grapecity/wijmo.chart";
import "@grapecity/wijmo.chart.render";
import {
  FlexChart,
  FlexChartAxis,
  FlexChartDataLabel,
  FlexChartLegend,
  FlexChartSeries,
} from "@grapecity/wijmo.react.chart";

import { FlexChartAnimation } from "@grapecity/wijmo.react.chart.animation";
import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";

import { useAtom } from "jotai";

import PropTypes from "prop-types";
import { metadataAtom } from "../state/chart-item-atom";
import { DataConveterBy } from "../utilities/utilities";

function GetRandomPalette() {
  let palettes = Object.keys(Palettes).filter((prop) =>
    isArray(Palettes[prop])
  );
  let rand = Math.floor(Math.random() * palettes.length);
  return Palettes[palettes[rand]];
}

export default function AreaChart(props) {
  const { datasources, width, height, chartInitalized } = props;
  const [palette] = useState(GetRandomPalette());
  const [metadataState] = useAtom(metadataAtom);

  const serverData = DataConveterBy(
    metadataState,
    datasources.configure.bindingX,
    datasources.configure.bindingY
  );

  return (
    <div>
      <FlexChart
        initialized={chartInitalized}
        chartType="Area"
        header={datasources.header}
        bindingX={datasources.configure.bindingX}
        itemsSource={serverData}
        palette={palette}
        isAnimated={true}
        legendToggle={datasources.settings.legendToggle}
        stacking={datasources.settings.stacked}
        style={{ width: width, height: height }}
      >
        {datasources.configure.bindingY.map((item, index) => (
          <FlexChartSeries
            name={item}
            binding={item}
            key={`area-chart-${item}-${index}`}
          ></FlexChartSeries>
        ))}
        <FlexChartAxis
          wjProperty="axisX"
          labelAngle={datasources.settings.labelAngle}
        ></FlexChartAxis>
        <FlexChartAxis
          title={datasources.settings.bindingYLabel}
          wjProperty="axisY"
        />
        <FlexChartLegend position={datasources.settings.legendPosition} />
        <FlexChartDataLabel
          position={datasources.settings.dataLabelPosition}
          content="{y}"
        />
        <FlexChartAnimation animationMode="Point" />
      </FlexChart>
    </div>
  );
}

AreaChart.propTypes = {
  datasources: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.number,
  chartInitalized: PropTypes.func,
};
