import React from "react";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";

function CompanyEditModal(props) {
  return (
    <div>
      <Modal size="md" onHide={props.onHide} show={props.show} centered>
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <form
            onSubmit={props.editCompany}
            className="row col-md-auto justify-content-center d-flex"
          >
            <div className="row col-md-12 mt-3 justify-content-center d-flex">
              <label className="col-md-4 col-sm-5 col-form-label">
                Company Name
              </label>
              <div className="col-md-7  g-0">
                <input
                  className="form-control col-md-7"
                  placeholder="Enter Company Name"
                  value={props.editingCompany.companyName}
                  name="companyName"
                  onChange={props.handleFormChange}
                  required
                />
              </div>
            </div>
            <div className="row col-md-12 mt-3  justify-content-center d-flex">
              <label className="col-md-4 col-sm-5 col-form-label">
                Company Full Name
              </label>
              <div className="col-md-7  g-0">
                <input
                  className="form-control"
                  placeholder="Enter Company Full Name"
                  value={props.editingCompany.companyFullName}
                  name="companyFullName"
                  onChange={props.handleFormChange}
                  required
                />
              </div>
            </div>
            <div className="row col-md-12 mt-3 justify-content-center d-flex">
              <label className="col-md-4 col-sm-5 col-form-label">
                Location
              </label>
              <div className="col-md-7  g-0">
                <input
                  className="form-control"
                  placeholder="Enter location"
                  value={props.editingCompany.companyBranchLoc}
                  name="companyBranchLoc"
                  onChange={props.handleFormChange}
                  required
                />
              </div>
            </div>
            <div className="row col-md-12 mt-3  justify-content-center d-flex">
              <label className="col-md-4 col-sm-5 col-form-label">
                Registration No.
              </label>
              <div className="col-md-7  g-0">
                <input
                  className="form-control"
                  placeholder="Enter Registration No."
                  value={props.editingCompany.companyRegNo}
                  name="companyRegNo"
                  onChange={props.handleFormChange}
                  required
                />
              </div>
            </div>
            <div className="row col-md-12 mt-3 mb-3 justify-content-center d-flex">
              <label className="col-md-4 col-sm-5 col-form-label">
                Main Currency
              </label>
              <div className="col-md-7  g-0">
                <select
                  className="form-select"
                  value={props.editingCompany.companyMainCurrency}
                  name="companyMainCurrency"
                  onChange={props.handleFormChange}
                  required
                >
                  <option value="" hidden>
                    Choose Main Currency
                  </option>
                  <option value="JPY">JPY</option>
                  <option value="MMK">MMK</option>
                </select>

                {/* {error && (<span className='error'>{error}</span>)} */}
                {/* <div className="row col-md-4 w-50 mt-4 justify-content-end">
                  <button type="submit" className="btn btn-primary config-btn">
                    Update
                  </button>
                </div> */}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <form onSubmit={props.editCompany}>
            <button
              className="popUpBtn save bg-blue"
              id="Update"
              data-tooltip-content="Update"
              onClick={() => props.setShow(false)}
            >
              <span id="Update">Update</span>
            </button>
          </form>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default CompanyEditModal;

CompanyEditModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  editCompany: PropTypes.func.isRequired,
  editingCompany: PropTypes.bool.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  setShow: PropTypes.func.isRequired,
};
