import React from "react";

// wijmo
import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";

// components
import { changeLabelPosition } from "../../dashboard-charts/changeLablePosition";
import FinancialDataSummary from "../../financialDataSummary";
import FinancialDataQuarterSummary from "../../financialDataQuarter";
import FinancialDataQuarterChart from "../../financialDataQuarterChart";
import QuarterYearly from "../quarter-yearly/quarterYearly";

import PropTypes from "prop-types";
import {
  BC_ANNUAL_YEN_LIST,
  FINANCIAL_DATA_LIST,
  TOKEN,
} from "../../../../constants/api.path";
import { Link } from "react-router-dom";
import { AiOutlineUpload } from "react-icons/ai";

export default function FinancialData({ fiscalYearList, changeLocation }) {
  let tabs = [
    { id: 1, name: "Annually Business Trends" },
    { id: 2, name: "Quarterly Business Trends" },
    { id: 3, name: "Quarterly Business Trends by Each Year" },
  ];
  const [activeTab, setActiveTab] = React.useState(tabs[0].name);

  const [isLoading, setIsLoading] = React.useState(true);

  const [financialData, setFinancialData] = React.useState([]);

  const [displayFinicalData, setDisplayFinicialData] = React.useState([]);

  const [financialQuarterData, setFinancialQuarterData] = React.useState({});

  const [financialQuarterDataTable, setFinancialDataQuarterTable] =
    React.useState({});

  const [financialDataSummary, setFinancialDataSummary] = React.useState({});

  const [selectedYear, setSelectedYear] = React.useState(0);
  const [filterRange, setFilterRange] = React.useState({
    from: fiscalYearList[0].fiscalYearId,
    to:
      fiscalYearList.length >= 5
        ? fiscalYearList[4].fiscalYearId
        : fiscalYearList[fiscalYearList.length - 1].fiscalYearId,
  });
  const [filterOptions, setFilterOptions] = React.useState({
    from: [],
    to: [],
  });
  const [maxVal, setMaxVal] = React.useState(0);
  const [filterValue, setFilterValue] = React.useState({ from: "", to: "" });

  let quarterKeys = Object.keys(financialQuarterData);

  function getFilterRangeValue() {
    fiscalYearList.forEach((fiscalYear) => {
      if (fiscalYear.fiscalYearId === filterRange.from) {
        setFilterValue((prev) => ({
          ...prev,
          from: `${fiscalYear.fiscalYear}`,
        }));
      }
      if (fiscalYear.fiscalYearId === filterRange.to) {
        setFilterValue((prev) => ({ ...prev, to: `${fiscalYear.fiscalYear}` }));
      }
    });
  }

  function changeFilterRange(e) {
    let name = e.target.name;
    let value = parseInt(e.target.value);
    setFilterRange((prev) => ({ ...prev, [name]: value }));
  }
  React.useEffect(() => {
    let yearList = quicksort(fiscalYearList);
    let itemList = quicksort(financialData);
    let start;
    let target;
    yearList.forEach((year) => {
      year.fiscalYearId === filterRange.from && (start = year.fiscalYearId);
      year.fiscalYearId === filterRange.to && (target = year.fiscalYearId);
    });
    let data = [];
    for (let i = 0; i < yearList.length; i++) {
      for (let j = 0; j < itemList.length; j++) {
        if (
          yearList[i].fiscalYearId >= start &&
          yearList[i].fiscalYearId <= target
        ) {
          yearList[i].fiscalYear === itemList[j].name.slice(0, 4) &&
            data.push(itemList[j]);
        }
      }
    }
    setDisplayFinicialData(data);
    console.log(data);
  }, [filterRange, financialData]);

  React.useEffect(() => {
    getMaxVal();
  }, [filterRange, displayFinicalData]);

  function getMaxVal() {
    let maxVal = 10;
    for (let i = 0; i < displayFinicalData.length; i++) {
      let total = 0;
      total +=
        displayFinicalData[i].sales + (displayFinicalData[0].sales % 10) + 10;
      if (maxVal < total) {
        maxVal = total;
      }
    }
    setMaxVal(maxVal);
  }

  function quicksort(arr) {
    if (arr.length <= 1) {
      return arr;
    } else {
      const pivot = arr[0];
      const less = [];
      const greater = [];
      for (let i = 1; i < arr.length; i++) {
        if (arr[i].name <= pivot.name) {
          less.push(arr[i]);
        } else {
          greater.push(arr[i]);
        }
      }
      return [...quicksort(less), pivot, ...quicksort(greater)];
    }
  }

  React.useEffect(() => {
    let filterFromList = fiscalYearList.filter(
      (year) => year.fiscalYearId <= filterRange.to
    );
    let filterToList = fiscalYearList.filter(
      (year) => year.fiscalYearId >= filterRange.from
    );
    let maxLength = 10;
    let returnFrom = [],
      returnTo = [];
    if (filterFromList.length < maxLength) {
      returnFrom = filterFromList;
    } else {
      for (let i = 0; i < maxLength; i++) {
        returnFrom.push(filterFromList[i]);
      }
    }
    if (filterToList.length < maxLength) {
      returnTo = filterToList;
    } else {
      for (let i = 0; i < maxLength; i++) {
        returnTo.push(filterToList[i]);
      }
    }
    setFilterOptions({ from: returnFrom, to: returnTo });
    getFilterRangeValue();
  }, [filterRange]);

  React.useEffect(() => {
    let currentYear, currentYearName;
    let year =
      new Date().getFullYear() - (new Date().getMonth() < 4 ? 2001 : 2000);
    fiscalYearList.forEach((fiscalYear) => {
      if (parseInt(fiscalYear.fiscalYear.slice(2)) === year) {
        currentYear = fiscalYear.fiscalYearId;
        currentYearName = fiscalYear.fiscalYear;
      }
    });
    let estimatedMonth =
      new Date().getMonth() === 0 ? 1 : new Date().getMonth();
    let url =
      BC_ANNUAL_YEN_LIST +
      "/salesPlanYear/" +
      currentYear +
      "/salesPlanMonth/" +
      estimatedMonth;
    fetch(url, {
      method: "GET",
      headers: { Authorization: `Bearer ${TOKEN}` },
    })
      .then(async (response) => response.json())
      .then((result) => {
        if (result.result) {
          let esitmatedValue = getEstimatedYearData(
            result.data.bcAnnualList,
            result.data.businessSector,
            currentYearName
          );
          url = FINANCIAL_DATA_LIST;
          fetch(url, {
            method: "GET",
            headers: { Authorization: `Bearer ${TOKEN}` },
          })
            .then(async (response) => response.json())
            .then((result) => {
              let value = combineDatas(
                esitmatedValue,
                JSON.parse(result.data.financialDataList[0].data).data
              );
              setFinancialDataQuarterTable(value);
              setFinancialQuarterData(
                changeDataForFinancialQuarterChart(value.totalData)
              );
              setFinancialData(
                changeDataForFinancialDataChart(value.totalData)
              );
              setFinancialDataSummary(
                changeDataForFinancialDataSummaryTable(value.totalData)
              );
              setIsLoading(false);
            });
        }
      });
  }, []);

  function combineDatas(esitmatedData, datas) {
    let totalData = [];
    datas.totalData.forEach((data) => {
      let key = Object.keys(data)[0];
      // totalData.push({ [key]: { ...data[key], ...esitmatedData[key] } });
      totalData.push({ [key]: { ...data[key] } });
    });
    return { totalData: totalData };
  }

  function getEstimatedYearData(data, sectorData, yearName) {
    let quarters = ["Q1", "Q2", "Q3", "Q4"];
    let quarterKeys = ["1Q", "2Q", "3Q", "4Q"];
    let result = {};
    let rows = [2, 5, 8, 9, 13, 14, 16, 17];
    let index = -1;
    let targetKey = "draftTotal";
    let value = [
      {
        name: "order_received",
        itemValue: data[rows[(index += 1)]],
      },
      {
        name: "sales",
        itemValue: data[rows[(index += 1)]],
      },
      {
        name: "cost_of_sales",
        itemValue: data[rows[(index += 1)]],
      },
      {
        name: "gp",
        itemValue: data[rows[(index += 1)]],
      },
      {
        name: "sga",
        itemValue: data[rows[(index += 1)]],
      },
      {
        name: "operating_income",
        itemValue: data[rows[(index += 1)]],
      },
      {
        name: "non_operating_income",
        itemValue: data[rows[(index += 1)]],
      },
      {
        name: "non_operating_expenses",
        itemValue: data[rows[(index += 1)]],
      },
    ];
    for (let i = 0; i < value.length; i++) {
      result[value[i].name] = { [yearName]: {} };
      for (let j = 0; j < quarterKeys.length; j++) {
        let totalValue = 0;
        totalValue = value[i].itemValue[`${targetKey}${quarterKeys[j]}`];
        value[i].itemValue &&
          (result[value[i].name][yearName][quarters[j]] = totalValue);
      }
    }
    let addingValue = [
      {
        name: "ordinary_profit",
        valueNames: [
          "operating_income",
          "non_operating_income",
          "non_operating_expenses",
        ],
        action: "add",
      },
      { name: "net_income", valueNames: ["net_income_before_tax", "ct"] },
    ];
    function prepareAdd(value) {
      let object = {};
      value.valueNames.forEach((name) => {
        object[name] = result[name];
      });
      calcValues(value.name, object, value.action);
    }

    prepareAdd(addingValue[0]);

    let percentValue = [
      { name: "gp_percent", valueNames: ["gp", "sales"] },
      { name: "sga_percent", valueNames: ["sga", "sales"] },
      {
        name: "operating_income_percent",
        valueNames: ["operating_income", "sales"],
      },
      {
        name: "ordinary_profit_percent",
        valueNames: ["ordinary_profit", "sales"],
      },
    ];
    percentValue.forEach((value) => {
      let object = {};
      value.valueNames.forEach((name) => {
        object[name] = result[name];
      });
      calcPercent(value.name, object);
    });
    result.net_income_before_tax = result.ordinary_profit;
    result.ct = result.ordinary_profit_percent;
    prepareAdd(addingValue[1]);

    function calcValues(name, items, action) {
      result[name] = { [yearName]: {} };
      let keys = Object.keys(items);
      for (let i = 0; i < quarters.length; i++) {
        let value = 0;
        if (action === "add") {
          keys.forEach((key) => {
            value += items[key][yearName][quarters[i]];
          });
        } else {
          keys.forEach((key) => {
            value -= items[key][yearName][quarters[i]];
          });
        }
        result[name][yearName][quarters[i]] = value;
      }
    }

    function calcPercent(name, items) {
      result[name] = { [yearName]: {} };
      let keys = Object.keys(items);
      for (let i = 0; i < quarters.length; i++) {
        let value = 0;
        value =
          (100 * items[keys[0]][yearName][quarters[i]]) /
          items[keys[1]][yearName][quarters[i]];
        result[name][yearName][quarters[i]] = value;
      }
    }
    let sectorNames = ["Facility", "Fiber", "ICT", "Software", "JP Business"];
    let sectorKeys = [
      "facilities",
      "fiber",
      "ict_biz",
      "software&bpo",
      "jp_biz",
    ];
    let sectorValues = {};
    for (let i = 0; i < sectorData.length; i += 23) {
      if (sectorNames.includes(sectorData[i].sectorName)) {
        let index = sectorNames.indexOf(sectorData[i + 5].sectorName);
        sectorValues[sectorKeys[index]] = { [yearName]: {} };
        for (let j = 0; j < quarters.length; j++) {
          let totalValue = 0;
          for (let m = j; m >= 0; m--) {
            totalValue += sectorData[i + 5][`${targetKey}${quarterKeys[m]}`];
          }
          sectorValues[sectorKeys[index]][yearName][quarters[j]] = totalValue;
        }
      }
    }
    sectorValues.ict_mng = { [yearName]: { Q1: 0, Q2: 0, Q3: 0, Q4: 0 } };
    result = { ...result, ...sectorValues };
    prepareAdd({
      name: "myanmar",
      valueNames: ["ict_mng", "facilities", "fiber", "ict_biz"],
      action: "add",
    });
    prepareAdd({
      name: "japan",
      valueNames: ["software&bpo", "jp_biz"],
      action: "add",
    });
    return result;
  }

  function changeDataForFinancialQuarterChart(data) {
    let mainKey = [];
    let result = {};
    let names = [];
    let itemKeys = [
      "ict_mng",
      "facilities",
      "fiber",
      "ict_biz",
      "software&bpo",
      "jp_biz",
      "sales",
      "operating_income",
    ];
    for (let i = 0; i < data.length; i++) {
      let subKey = Object.keys(data[i])[0];
      if (mainKey.length === 0) mainKey = Object.keys(data[i][subKey]);
      mainKey.forEach((key) => {
        names = Object.keys(data[i][subKey][key]);
        result[key] === undefined && (result[key] = []);
      });
    }
    mainKey.forEach((key) => {
      names.forEach((name, index) =>
        result[key].push({ id: index + 1, name: name })
      );
    });
    for (let i = 0; i < data.length; i++) {
      let subKey = Object.keys(data[i])[0];
      mainKey.forEach((key) => {
        names.forEach((name, index) => {
          if (itemKeys.includes(subKey)) {
            if (result[key][index].name === "Q1") {
              result[key][index] = {
                ...result[key][index],
                [subKey]: data[i][subKey][key][name],
              };
            } else
              result[key][index] = {
                ...result[key][index],
                [subKey]:
                  data[i][subKey][key][name] + result[key][index - 1][subKey],
              };
          }
        });
      });
    }
    console.log(result);
    return result;
  }

  function changeDataForFinancialDataChart(data) {
    let mainKey = [];
    let result = [];
    let itemKeys = [
      "ict_mng",
      "facilities",
      "fiber",
      "ict_biz",
      "software&bpo",
      "jp_biz",
      "sales",
      "operating_income",
    ];
    for (let i = 0; i < data.length; i++) {
      let subKey = Object.keys(data[i])[0];
      if (itemKeys.includes(subKey)) {
        if (mainKey.length === 0) mainKey = Object.keys(data[i][subKey]);
        mainKey.forEach((key, index) => {
          let value = 0;
          let quarterKeys = Object.keys(data[i][subKey][key]);
          quarterKeys.forEach(
            (quarterKey) =>
              (value += Math.round(data[i][subKey][key][quarterKey]))
          );
          result[index] = {
            ...result[index],
            id: index + 1,
            name: key,
            [subKey]: value,
          };
        });
      }
    }
    return result;
  }

  function changeDataForFinancialDataSummaryTable(data) {
    let totalData = [];
    for (let i = 0; i < data.length; i++) {
      let mainKey = Object.keys(data[i])[0];
      let subKey = Object.keys(data[i][mainKey]);
      subKey.forEach((key) => {
        let value = 0;
        let quarterKeys = Object.keys(data[i][mainKey][key]);
        quarterKeys.forEach(
          (quarterKey) => (value += data[i][mainKey][key][quarterKey])
        );
        // hard code fix for mismatched data
        if (key === "FY20") {
          if (mainKey === "myanmar") {
            value += -6;
          } else if (mainKey === "japan") {
            value += 5;
          } else if (mainKey === "ict_biz") {
            value += -5;
          } else if (mainKey === "software&bpo") {
            value += 6;
          }
        }
        totalData[i] = totalData[i]
          ? {
              [mainKey]: {
                ...totalData[i][mainKey],
                [key]: value,
              },
            }
          : {
              [mainKey]: {
                [key]: value,
              },
            };
      });
    }
    return { totalData };
  }

  return (
    <div>
      <div style={{ height: "auto", minHeight: "30px" }} className="row">
        <div
          className="row col-9"
          style={{ marginLeft: "0px", marginBottom: "3px" }}
        >
          {tabs.map((tab) => (
            <div
              className="col-2 dashboardTab-parent"
              style={{
                padding: "0.5px",
                minWidth: "fit-content",
              }}
              key={tab.id}
            >
              <button
                type="button"
                className={`dashboardTab ${
                  activeTab === tab.name && "dashboardActiveTab"
                }`}
                style={{ paddingLeft: "6px", paddingRight: "6px" }}
                onClick={() => {
                  activeTab !== tab.name && setActiveTab(tab.name);
                }}
                disabled={isLoading}
              >
                {tab.name}
              </button>
            </div>
          ))}
        </div>
        {(activeTab === tabs[0].name || activeTab === tabs[1].name) && (
          <div className="col-3 row justify-content-end">
            <div className="col-5">
              <select
                className="form-select"
                value={filterRange.from}
                name="from"
                onChange={(e) => changeFilterRange(e)}
                required
              >
                {filterOptions.from.map((fiscalYear) => (
                  <option
                    key={fiscalYear.fiscalYearId}
                    value={fiscalYear.fiscalYearId}
                  >
                    {fiscalYear.fiscalYear}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-5">
              <select
                className="form-select"
                value={filterRange.to}
                name="to"
                onChange={(e) => changeFilterRange(e)}
                required
              >
                {filterOptions.to.map((fiscalYear) => (
                  <option
                    key={fiscalYear.fiscalYearId}
                    value={fiscalYear.fiscalYearId}
                  >
                    {fiscalYear.fiscalYear}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-1">
              <Link
                to="/dashboard/financialdata/import"
                onClick={() => {
                  changeLocation("Dashboard | Import");
                }}
              >
                <button
                  id="import"
                  data-tooltip-content="Import"
                  type="button"
                  style={{ margin: "0", padding: "0" }}
                  className="actionIconOutline import bg-blue"
                >
                  <AiOutlineUpload />
                </button>
              </Link>
            </div>
          </div>
        )}
        {/* {(activeTab === tabs[0].name || activeTab === tabs[1].name) && (
          <div className="col-1">
            <Link
              to="/dashboard/financialdata/import"
              onClick={() => {
                changeLocation("Sales Plan | Import");
              }}
            >
              <button
                id="import"
                data-tooltip-content="Import"
                type="button"
                style={{ margin: "0", padding: "0" }}
                className="actionIconOutline import bg-blue"
              >
                <AiOutlineUpload />
              </button>
            </Link>
          </div>
        )} */}
      </div>
      {activeTab === tabs[0].name && (
        <div>
          <div
            className="row financial-data-summmary"
            style={{ paddingTop: "10px", paddingLeft: "12px" }}
          >
            <wjChart.FlexChart
              bindingX="name"
              itemsSource={displayFinicalData}
              chartType="Column"
              selectionMode="Series"
              stacking={true}
              legendToggle={true}
              palette={[
                "rgb(68,144,196)",
                "rgb(143,170,220)",
                "rgb(219,219,219)",
                "rgb(91,155,213)",
                "rgb(112,173,71)",
                "rgb(0,176,80)",
                "rgb(68,114,196)",
                "rgb(158,72,14)",
              ]}
              header={`iSGM業績(${filterValue.from}~${filterValue.to}) Unit in M JPY`}
              rendered={() => {
                changeLabelPosition(financialData);
              }}
              style={{ height: "575px" }}
            >
              <wjChart.FlexChartDataLabel content="{y}" position="Bottom" />
              <wjChart.FlexChartLegend position="Bottom"></wjChart.FlexChartLegend>
              <wjChart.FlexChartSeries name="ICT-Mng" binding="ict_mng" />
              <wjChart.FlexChartSeries name="Facilities" binding="facilities" />
              <wjChart.FlexChartSeries name="Fiber" binding="fiber" />
              <wjChart.FlexChartSeries name="ICT Biz." binding="ict_biz" />
              <wjChart.FlexChartSeries
                name="Software&BPO"
                binding="software&bpo"
              />
              <wjChart.FlexChartSeries name="JP Biz." binding="jp_biz" />
              <wjChart.FlexChartSeries
                name="Sales"
                binding="sales"
                chartType="Bubble"
              ></wjChart.FlexChartSeries>
              <wjChart.FlexChartAxis
                wjProperty="axisY"
                max={maxVal || 1000}
              ></wjChart.FlexChartAxis>
              <wjChart.FlexChartSeries
                name="Operating Income"
                binding="operating_income"
                chartType="LineSymbols"
              ></wjChart.FlexChartSeries>
              <wjChartAnimate.FlexChartAnimation />
            </wjChart.FlexChart>
          </div>
          <div className="row financial-data-summmary">
            <FinancialDataSummary
              data={financialDataSummary}
              filterFrom={filterRange.from}
              filterTo={filterRange.to}
              fiscalYearList={fiscalYearList}
            />
          </div>
        </div>
      )}
      {activeTab === tabs[2].name && (
        <div>
          <select
            className="form-select"
            style={{
              width: "10%",
              marginLeft: "auto",
              marginTop: "-30px",
              marginRight: "12px",
            }}
            value={selectedYear}
            name="companyMainCurrency"
            onChange={(e) => setSelectedYear(e.target.value)}
            required
          >
            {quarterKeys.map((key, index) => (
              <option key={index} value={index}>
                {key}
              </option>
            ))}
          </select>
          <div className="row">
            <div className="col-6 financial-data-summmary">
              <FinancialDataQuarterSummary
                year={quarterKeys[selectedYear]}
                // data={financialQuarterData[quarterKeys[selectedYear]]}
                data={financialQuarterDataTable}
              />
            </div>
            <div
              className="col-6 financial-data-summmary"
              style={{ paddingTop: "10px" }}
            >
              <FinancialDataQuarterChart
                data={financialQuarterData[quarterKeys[selectedYear]]}
                year={quarterKeys[selectedYear]}
              />
            </div>
          </div>
        </div>
      )}
      {activeTab === tabs[1].name && (
        <QuarterYearly
          chartData={financialQuarterData}
          tableData={financialQuarterDataTable}
          filterRange={filterRange}
          fiscalYears={quicksort(fiscalYearList)}
        />
      )}
    </div>
  );
}

FinancialData.propTypes = {
  fiscalYearList: PropTypes.array.isRequired,
  changeLocation: PropTypes.func,
};
