import { Collapse } from "@mantine/core";
import React from "react";
import SearchQueryForm from "./search-query-form";
import PropTypes from "prop-types";

export default function SearchQueryDashboard(props) {
  const { show, schemaList, queryForm, handleQueryData } = props;

  return (
    <Collapse in={show}>
      <SearchQueryForm
        transitionDuration={800}
        handleQueryData={handleQueryData}
        queryForm={queryForm}
        schemaList={schemaList}
      />
    </Collapse>
  );
}

SearchQueryDashboard.propTypes = {
  show: PropTypes.any,
  schemaList: PropTypes.any,
  queryForm: PropTypes.any,
  handleQueryData: PropTypes.any,
};
