import { Avatar, Group, Text } from "@mantine/core";
import { forwardRef } from "react";
import {
  FILTEROPERATOR,
  FILTEROPERATORDATE,
  FILTEROPERATORDATETIME,
  FILTEROPERATORNUMBER,
  SELECTITEMCOLORS,
  SELECTITEMICONS,
} from "./constants";
import dayjs from "dayjs";
import React from "react";
import PropTypes from "prop-types";

// Converts array of data rows into array of objects.
export function DataConveter(data) {
  // For each row...
  const transformedData = data.data_rows.map((row) => {
    let result = {};
    // Map each value to a key from column_names.
    row.forEach((value, index) => {
      result[data.column_names[index]] = value;
    });
    return result; // Return object a row.
  });
  return transformedData; // Return array of objects.
}

export function DataConveterBy(data, key1, key2) {
  let combinedData = {};
  data.forEach((item) => {
    if (combinedData[item[key1]]) {
      key2.forEach((k) => (combinedData[item[key1]][k] += item[k]));
    } else {
      let newEntry = {};
      newEntry[key1] = item[key1];
      key2.forEach((k) => (newEntry[k] = item[k]));
      combinedData[item[key1]] = newEntry;
    }
  });
  return Object.values(combinedData);
}

export function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function TotalProgressCalculate(total, budget) {
  if (total === 0 || budget === 0) return 0;
  return ((total / budget) * 100).toFixed(0);
}

export function ConvertUnits(number, unit) {
  switch (unit) {
    case "K":
      return number / 1_000;
    case "M":
      return number / 1_000_000;
    case "B":
      return number / 1_000_000_000;
    case "T":
      return number / 1_000_000_000_000;
    default:
      return number;
  }
}

export function Financial(value, decimal, categoryUnit) {
  let result = `${Number.parseFloat(value).toFixed(decimal)} ${categoryUnit}`;
  return result;
}

export function ExportChartImage(flex, title, format) {
  flex.saveImageToFile(`${title}.${format}`);
}

export function CalculateProgress(currentProgress) {
  let roundedProgress = Math.round(currentProgress);
  let maxProgress = Math.pow(10, Math.ceil(Math.log10(roundedProgress + 1)));
  return (currentProgress / maxProgress) * 100;
}

export function ExportChartPDF(pdf, flex, chart, wTitle, header) {
  let doc = new pdf.PdfDocument({
    ended: (sender, args) => pdf.saveBlob(args.blob, `${wTitle}.pdf`),
  });

  //
  flex.saveImageToDataUrl(chart.ImageFormat.Png, (url) => {
    doc.drawText(header);
    doc.drawImage(url);
    doc.end();
  });
}

export function RetriveCSVFormat(wjGrid, flex) {
  return flex.getClipString(
    new wjGrid.CellRange(0, 0, flex.rows.length - 1, flex.columns.length - 1),
    true,
    true
  );
}
export function ExportGridExcel(gridXlsx, title, flex) {
  gridXlsx.FlexGridXlsxConverter.saveAsync(
    flex,
    {},
    `${title || "EXCEL FILE"}.xlsx`,
    null,
    null,
    true
  );
}
export function ExportGridCSV(csv, fileName) {
  let fileType = "txt/csv;charset=utf-8";
  if (navigator.msSaveBlob) {
    // IE
    navigator.msSaveBlob(
      new Blob([csv], {
        type: fileType,
      }),
      fileName
    );
  } else {
    let e = document.createElement("a");
    e.setAttribute("href", "data:" + fileType + "," + encodeURIComponent(csv));
    e.setAttribute("download", fileName);
    e.style.display = "none";
    document.body.appendChild(e);
    e.click();
    document.body.removeChild(e);
  }
}

export function ExportGridPDF(gridPdf, flex, title) {
  gridPdf.FlexGridPdfConverter.export(flex, `${title || "PDF FILE"}.pdf`, {
    maxPages: 20,
    scaleMode: gridPdf.ScaleMode.PageWidth,
    documentOptions: {
      compress: true,
      header: { declarative: { text: "\t&[Page] of &[Pages]" } },
      footer: { declarative: { text: "\t&[Page] of &[Pages]" } },
      info: { author: "iLiveBoard Team", title: title },
    },
    styles: {
      cellStyle: { backgroundColor: "#ffffff", borderColor: "#c6c6c6" },
      altCellStyle: { backgroundColor: "#f9f9f9" },
      groupCellStyle: { backgroundColor: "#dddddd" },
      headerCellStyle: { backgroundColor: "#eaeaea" },
    },
  });
}

/**
 * Filters array by attributes,
 * Remove duplicate attribute.
 *
 * @param {Array} data - The array of objects to be filtered.
 * @param {Array} attributes - The attributes to filter by.
 * @returns {Object} - Object with attribute key and unique array values.
 */
export function DataColumnsFiltering(data, attributes) {
  if (data.length === 0) return [];

  let result = {};
  attributes.forEach((attribute) => {
    result[attribute] = [];
    data.forEach((item) => {
      if (
        item[attribute] !== undefined &&
        !result[attribute].includes(item[attribute])
      ) {
        result[attribute].push(item[attribute]);
      }
    });
  });
  return result;
}

export function AttributeFiltering(data) {
  if (data.length === 0) return [];
  const obj = data[0];
  let keysArray = [];
  for (var key in obj) {
    if (typeof obj[key] === "string") {
      keysArray.push(key);
    }
  }
  return keysArray;
}

export function DataColumnsFilteringBy(data, attributes) {
  if (data.length === 0) return [];

  let result = {};
  attributes.forEach((attribute, index) => {
    result[attribute] = [];
    data.forEach((item) => {
      if (!result[attribute].includes(item[index])) {
        result[attribute].push(item[index]);
      }
    });
  });
  return result;
}

export function AttributeFilteringBy(data, columns) {
  if (data.length === 0) return [];
  let keysArray = [];
  let dataArray = data[0];
  columns.forEach((names, index) => {
    if (typeof dataArray[index] === "string") keysArray.push(names);
  });
  return keysArray;
}

export function FinancialDataFiltering(data_rows, years, quarters, bindingY) {
  const result = [];

  if (years.length > 1 && quarters.length > 1) {
    years.forEach((year) => {
      quarters.forEach((quarter) => {
        const obj = { bindingX: year.slice(2) + " " + quarter };
        bindingY.forEach((binding) => {
          const row = data_rows.find(
            (row) => row[0] === binding && row[1] === year && row[2] === quarter
          );
          obj[binding] = row ? row[3] : 0;
        });
        result.push(obj);
      });
    });
  } else if (years.length > 1 && quarters.length === 1) {
    years.forEach((year) => {
      const obj = { bindingX: year };
      bindingY.forEach((binding) => {
        const row = data_rows.find(
          (row) =>
            row[0] === binding && row[1] === year && row[2] === quarters[0]
        );
        obj[binding] = row ? row[3] : 0;
      });
      result.push(obj);
    });
  } else if (quarters.length > 1 && years.length === 1) {
    quarters.forEach((quarters) => {
      const obj = { bindingX: quarters };
      bindingY.forEach((binding) => {
        const row = data_rows.find(
          (row) =>
            row[0] === binding && row[1] === years[0] && row[2] === quarters
        );
        obj[binding] = row ? row[3] : 0;
      });
      result.push(obj);
    });
  }

  return result;
}

// export function DatasourceColumnObject(answer, metadata) {
//   let resultObj = {};
//   answer.forEach((item) => {
//     const { label, value } = item;
//     if (!resultObj[value]) {
//       const getMetadata = metadata[value];
//       resultObj[value] = ColumnsNameByGroup(
//         getMetadata.data_rows,
//         getMetadata.column_names
//       );
//     }
//   });

//   return resultObj;
// }

// Columns key and value formatting by group
export function ColumnsNameByGroup(data_rows, column_names) {
  let results = [];
  let uniqueValues = {};

  column_names.forEach((col, index) => {
    uniqueValues[col] = new Set();
    data_rows.forEach((row) => {
      if (typeof row[index] === "string") {
        uniqueValues[col].add(row[index]);
      }
    });
  });

  Object.keys(uniqueValues).forEach((col) => {
    uniqueValues[col].forEach((value) => {
      results.push({
        value: value,
        label: value,
        group: col,
      });
    });
  });
  return results;
}

export function FindFirstIndex(data_rows, target) {
  for (let i = 0; i < data_rows.length; i++) {
    if (data_rows[i].includes(target)) {
      return i;
    }
  }
  return -1;
}
export function JsonToJSTableConverter(customField) {
  return customField.map((element) => {
    return {
      label: Object.keys(element).join(""),
      value: Object.values(element),
    };
  });
}

export function QueryPreview(state, controls) {
  // Initial Formatting
  let select = "SELECT ";
  // Column Formatting
  let columnsString = state.columns?.map((column) => {
    return `\n\t${column.aggregation ? `${column.aggregation}(` : ""}${
      column.columnName
    }${column.aggregation ? ")" : ""}${
      column.alias ? ` AS ${column.alias}` : ""
    }`;
  });
  let columns = columnsString.filter((str) => str.trim() !== "").join(",");

  // Table Formatting
  let from = state.tableName ? ` FROM \n\t${state.tableName}` : "";

  // Limit  Formatting
  let limit =
    controls.isOrder || state.columns.length > 0
      ? ` \nLIMIT \n\t${state.orders.limit}`
      : "";

  // Group By Formatting
  let groupBy = "";

  if (state.groups.length > 0) {
    let validGroups = state.groups.filter((group) => group.trim() !== "");
    if (validGroups.length > 0) {
      groupBy = ` \nGROUP BY \n\t${validGroups.join(", ")}`;
    }
  }

  let orderBy = state.orders.orderBy
    ? ` \nORDER BY \n\t${state.orders.orderBy} ${state.orders.ordering}`
    : "";

  let where = "";

  if (state.filters.filterList.length > 0) {
    let filters = state.filters.filterList
      .filter((filter) => filter.filterByName.trim() !== "")
      .map((filter) => {
        let filterByType = filter.filterByType;

        if (filterByType === FILTEROPERATOR[6].value) {
          return filterByType
            .replace(/#name/g, filter.filterByName)
            .replace(/#firstword/g, filter.filterByFirstWord)
            .replace(/#secondword/g, filter.filterBySecondWord)
            .replace(/#number/g, filter.filterByNear);
        }

        if (
          [
            FILTEROPERATOR[7].value,
            FILTEROPERATOR[8].value,
            FILTEROPERATOR[9].value,
            FILTEROPERATOR[10].value,
          ].includes(filterByType)
        ) {
          return filter.filterByType.replace(/#name/g, filter.filterByName);
        }

        if (
          [
            FILTEROPERATORNUMBER[0].value,
            FILTEROPERATORNUMBER[1].value,
            FILTEROPERATORNUMBER[2].value,
            FILTEROPERATORNUMBER[3].value,
            FILTEROPERATORNUMBER[4].value,
            FILTEROPERATORNUMBER[5].value,
          ].includes(filterByType)
        ) {
          return filter.filterByType
            .replace(/#name/g, filter.filterByName)
            .replace(/#number/g, filter.filterByNumber);
        }

        if (
          [
            FILTEROPERATORNUMBER[6].value,
            FILTEROPERATORNUMBER[7].value,
          ].includes(filterByType)
        ) {
          return filter.filterByType
            .replace(/#name/g, filter.filterByName)
            .replace(/#firstnumber/g, filter.filterByFirstNumber)
            .replace(/#secondnumber/g, filter.filterBySecondNumber);
        }

        if (
          [
            FILTEROPERATORDATE[0].value,
            FILTEROPERATORDATE[1].value,
            FILTEROPERATORDATE[2].value,
            FILTEROPERATORDATE[3].value,
            FILTEROPERATORDATE[4].value,
            FILTEROPERATORDATE[5].value,
          ].includes(filterByType)
        ) {
          return filter.filterByType
            .replace(/#name/g, filter.filterByName)
            .replace(
              /#date/g,
              filter.filterByDate
                ? `${dayjs(filter.filterByDate).format("YYYY-MM-DD")}`
                : ""
            );
        }

        if (
          [FILTEROPERATORDATE[6].value, FILTEROPERATORDATE[7].value].includes(
            filterByType
          )
        ) {
          return filter.filterByType
            .replace(/#name/g, filter.filterByName)
            .replace(
              /#firstdate/g,
              filter.filterByFirstDate
                ? `${dayjs(filter.filterByFirstDate).format("YYYY-MM-DD")}`
                : ""
            )
            .replace(
              /#seconddate/g,
              filter.filterBySecondDate
                ? `${dayjs(filter.filterBySecondDate).format("YYYY-MM-DD")}`
                : ""
            );
        }

        if (
          [
            FILTEROPERATORDATETIME[0].value,
            FILTEROPERATORDATETIME[1].value,
            FILTEROPERATORDATETIME[2].value,
            FILTEROPERATORDATETIME[3].value,
            FILTEROPERATORDATETIME[4].value,
            FILTEROPERATORDATETIME[5].value,
          ].includes(filterByType)
        ) {
          return filter.filterByType
            .replace(/#name/g, filter.filterByName)
            .replace(
              /#datetime/g,
              filter.filterByDateTime
                ? `${dayjs(filter.filterByDateTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}`
                : ""
            );
        }

        if (
          [
            FILTEROPERATORDATETIME[6].value,
            FILTEROPERATORDATETIME[7].value,
          ].includes(filterByType)
        ) {
          return filter.filterByType
            .replace(/#name/g, filter.filterByName)
            .replace(
              /#firstdatetime/g,
              filter.filterByFirstDateTime
                ? `${dayjs(filter.filterByFirstDateTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}`
                : ""
            )
            .replace(
              /#seconddatetime/g,
              filter.filterBySecondDateTime
                ? `${dayjs(filter.filterBySecondDateTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}`
                : ""
            );
        }

        return filterByType
          .replace(/#name/g, filter.filterByName)
          .replace(/#string/g, filter.filterByString);
      });
    if (filters.length > 0) {
      where = ` \nWHERE #replaceStart \n\t${filters.join(
        ` \n\t${state.filters.filterType} `
      )}#replaceEnd`;
    }
    if (filters.length === 1) {
      where = where.replace(/#replaceStart /g, "").replace(/#replaceEnd/g, "");
    } else if (filters.length > 1) {
      where = where
        .replace(/#replaceStart/g, "\n(")
        .replace(/#replaceEnd/g, "\n)");
    }
  }
  return `${select}${columns}\n${from}${where}${groupBy}${orderBy}${limit}`;
}

export const SELECTITEMCOMPONENT = forwardRef((props, ref) => {
  const { type, label, ...others } = props;
  return (
    <div ref={ref} {...others}>
      <Group noWrap>
        {type !== "text" && (
          <Avatar
            radius="sm"
            size={"xs"}
            variant="filled"
            color={SELECTITEMCOLORS[type]}
          >
            {SELECTITEMICONS[type]}
          </Avatar>
        )}
        <Text size="sm">{label}</Text>
      </Group>
    </div>
  );
});
SELECTITEMCOMPONENT.displayName = "selectItemComponent";
SELECTITEMCOMPONENT.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
};

export function SELECTITEMLIST(tableList, tableName) {
  let result = [];
  const obj = tableList.find((item) => item.label === tableName);

  if (obj && obj.value && obj.value[0]) {
    let valueList = obj.value[0];
    result = valueList.map((item) => {
      return { label: item.label, value: item.label, type: item.type };
    });
  }
  return result;
}

export function findDashboardByI(dashboards, i) {
  if (dashboards.length === 0) return null;
  return dashboards.find((dashboard) => {
    return dashboard.dashboard_layout.sections?.some((section) => {
      return section.items.some((item) => item.i === i);
    });
  });
}

export function RemoveDashboardItemsByQueryId(obj, i) {
  for (let k in obj) {
    if (typeof obj[k] === "object" && obj[k] !== null) {
      if (k === "items") {
        if (Array.isArray(obj[k])) {
          obj[k] = obj[k].filter((item) => item.i !== i);
        }
      }
    }
  }
  return obj;
}

export function removeItemByQueryId(dashboard, i) {
  dashboard.dashboard_layout.sections = dashboard.dashboard_layout.sections.map(
    (section) => {
      section.items = section.items.filter((item) => item.i !== i);
      section.layouts.lg = section.layouts.lg.filter(
        (layout) => layout.i !== i
      );
      return section;
    }
  );

  return dashboard;
}

export function updateItemByQueryId(dashboard, i, newItem) {
  dashboard.dashboard_layout.sections = dashboard.dashboard_layout.sections.map(
    (section) => {
      section.items = section.items.map((item) =>
        item.i === i ? newItem : item
      );
      return section;
    }
  );

  return dashboard;
}
