import React from "react";
import PropTypes from "prop-types";
import PieChartEditor from "./editors/pie-chart-editor";
import BarChartEditor from "./editors/bar-chart-editor";
import ProgressCardEditor from "./editors/progress-card-editor";
import AreaChartEditor from "./editors/area-chart-editor";
import BarChartEditorLine from "./editors/bar-chart-line-editor";
import LineChartEditor from "./editors/line-chart-editor";
import ScatterChartEditor from "./editors/scatter-chart-editor";
import SimpleTableEditor from "./editors/simple-table-editor";
import BubbleChartEditor from "./editors/bubble-chart-editor";
import FlexSheetEditor from "./editors/flex-sheet-table-editor";

// Pre-define Dynamic Editor Components

const components = {
  pie_chart: PieChartEditor,
  line_chart: LineChartEditor,
  bar_chart: BarChartEditor,
  bar_chart_line: BarChartEditorLine,
  progress_card: ProgressCardEditor,
  simple_table: SimpleTableEditor,
  area_chart: AreaChartEditor,
  bubble_chart: BubbleChartEditor,
  scatter_chart: ScatterChartEditor,
  flex_sheet_table: FlexSheetEditor,
};

const WidgetEditor = (props) => {
  const {
    widget,
    metacolumn,
    oldDatasource,
    targetId,
    activeTab,
    handleEditorClose,
    tempLayouts,
  } = props;
  // Dynamic Compments Create
  const DynamicComponent = components[widget.type];
  return (
    <DynamicComponent
      widget={widget}
      metacolumn={metacolumn}
      oldDatasource={oldDatasource}
      targetId={targetId}
      activeTab={activeTab}
      handleEditorClose={handleEditorClose}
      tempLayouts={tempLayouts}
    />
  );
};

export default WidgetEditor;

WidgetEditor.propTypes = {
  widget: PropTypes.object.isRequired,
  metacolumn: PropTypes.array.isRequired,
  oldDatasource: PropTypes.object,
  targetId: PropTypes.string.isRequired,
  activeTab: PropTypes.string.isRequired,
  handleEditorClose: PropTypes.func,
  tempLayouts: PropTypes.array,
};
