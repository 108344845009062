import React from "react";
import PropTypes from "prop-types";

// wijmo
import * as wjGauge from "@grapecity/wijmo.react.gauge";

export default function DashboardLinearGauge({ palette }) {
  return (
    <div className="linear-gauge">
      <div className="linear-gauge-item">
        <p style={{ minWidth: "100px", textAlign: "center" }}>
          <b>Sales: ¥587M</b>
        </p>
        <wjGauge.LinearGauge
          min={0}
          max={1000}
          thickness={0.15}
          thumbSize={9}
          value={587}
          pointer={{ color: palette[0] }}
        />
      </div>
      <div className="linear-gauge-item">
        <p style={{ minWidth: "100px", textAlign: "center" }}>
          <b>Costs: ¥482M</b>
        </p>
        <wjGauge.LinearGauge
          min={0}
          max={1000}
          thickness={0.15}
          thumbSize={9}
          value={482}
          pointer={{ color: palette[1] }}
        />
      </div>
      <div className="linear-gauge-item">
        <p style={{ minWidth: "100px", textAlign: "center" }}>
          <b>OP: ¥27M</b>
        </p>
        <wjGauge.LinearGauge
          min={0}
          max={1000}
          thickness={0.15}
          thumbSize={9}
          value={27}
          pointer={{ color: palette[0] }}
        />
      </div>
    </div>
  );
}

DashboardLinearGauge.propTypes = {
  palette: PropTypes.array.isRequired,
};
