import React, { useEffect, useMemo, useState } from "react";
import { DataConveter } from "../../../utilities/utilities";

import "@grapecity/wijmo.chart.render";
import {
  FlexChart,
  FlexChartAxis,
  FlexChartDataLabel,
  FlexChartLegend,
  FlexChartSeries,
} from "@grapecity/wijmo.react.chart";
import { FlexChartAnimation } from "@grapecity/wijmo.react.chart.animation";
import "@grapecity/wijmo.styles/wijmo.css";
import "bootstrap/dist/css/bootstrap.css";

import { useDisclosure } from "@mantine/hooks";
import DataService from "../../../service/data-service";
import { GET_DATA_BY_QUERY } from "../../../utilities/constants";
import LoadingComponents from "../../common/loading";
import ErrorWidget from "../error/error-widget";
import PropTypes from "prop-types";

const BarChart = (props) => {
  const { i, datasources, width, height, chartInitalized } = props;

  // const [labelShow, setLabelShow] = useState(datasources.settings.dataLabel);
  const [serverData, setServerData] = useState([]);
  const [palette] = useState([
    "rgba(42,159,214,1)",
    "rgba(119,179,0,1)",
    "rgba(153,51,204,1)",
    "rgba(255,136,0,1)",
    "rgba(204,0,0,1)",
    "rgba(0,204,163,1)",
    "rgba(61,109,204,1)",
    "rgba(82,82,82,1)",
    "rgba(0,0,0,1)",
  ]);
  const [isLoading, loadingHandlers] = useDisclosure(true);
  const [datasource, setDatasource] = useState([]);
  const [isError, setIsError] = useState(false);

  const memoizedDatasources = useMemo(() => datasources, [datasources]);

  useEffect(() => {
    const path = GET_DATA_BY_QUERY;
    const requestObject = {
      query: i.query,
      table_name: i.tableName,
    };
    // SID002 Get Data by queries
    DataService(path, requestObject).then(({ data, error }) => {
      if (error) {
        setIsError(true);
        loadingHandlers.close();
      } else {
        setIsError(false);
        setServerData(DataConveter(data.metadata));
        setDatasource(datasources);
        loadingHandlers.close();
      }
    });
    return () => {
      setServerData([]);
      setDatasource([]);
      loadingHandlers.open();
    };
  }, [memoizedDatasources]);

  if (isLoading)
    return (
      <div className="container-fluid">
        <LoadingComponents />
      </div>
    );

  if (isError) {
    return <ErrorWidget />;
  }

  return (
    <div className="container-fluid" style={{ padding: 0 }}>
      <FlexChart
        initialized={chartInitalized}
        style={{ width: width, height: height }}
        header={datasource.header}
        bindingX={datasource.configure.bindingX}
        stacking={datasource.settings.stacked}
        selectionMode="Point"
        chartType={
          datasource.settings.chartType === "barChart" ? "Bar" : "Column"
        }
        legendToggle={datasource.settings.legendToggle}
        itemsSource={serverData}
        palette={palette}
      >
        <FlexChartDataLabel
          position={datasource.settings.dataLabel}
          content="{y}"
        />

        <FlexChartLegend position={datasource.settings.legendPosition} />
        {datasource.configure.bindingY.map((item) => (
          <FlexChartSeries
            name={item}
            binding={item}
            key={`bar-chart-${item}`}
          ></FlexChartSeries>
        ))}

        <FlexChartAxis
          title={datasource.settings.chartAxis.leftAxisTitle}
          wjProperty="axisY"
          axisLine={true}
          origin={-10}
        />

        <FlexChartAnimation />
      </FlexChart>
    </div>
  );
};

export default BarChart;

BarChart.propTypes = {
  i: PropTypes.any,
  datasources: PropTypes.any,
  chartType: PropTypes.any,
  width: PropTypes.any,
  height: PropTypes.any,
  chartInitalized: PropTypes.any,
};
