import React from "react";
import PropTypes from "prop-types";

// wijmo
import * as wjChart from "@grapecity/wijmo.react.chart";
import * as wjChartAnimate from "@grapecity/wijmo.react.chart.animation";

// component
import { changeLabelPosition } from "./dashboard-charts/changeLablePosition";

export default function FinancialDataQuarterChart({ data, year }) {
  var blocks = [
    "ict_mng",
    "facilities",
    "fiber",
    "ict_biz",
    "software&bpo",
    "jp_biz",
    "sales",
    "operating_income",
  ];
  const [maxVal, setMaxVal] = React.useState(0);
  function render(chart) {
    changeLabelPosition(chart.itemsSource, blocks);
  }

  function getMaxVal() {
    let maxVal = 10;
    for (let i = 0; i < data.length; i++) {
      if (maxVal < data[i].sales) {
        maxVal = data[i].sales - (data[0].sales % 10) + 10;
      }
    }
    setMaxVal(maxVal);
  }
  React.useEffect(() => {
    getMaxVal();
  }, [year]);

  return (
    <div>
      <wjChart.FlexChart
        header={`iSGM業績 ${year} Unit in M JPY`}
        itemsSource={data}
        bindingX="name"
        chartType="Column"
        selectionMode="Series"
        stacking={true}
        palette={[
          "rgb(68,144,196)",
          "rgb(143,170,220)",
          "rgb(219,219,219)",
          "rgb(91,155,213)",
          "rgb(112,173,71)",
          "rgb(0,176,80)",
          "rgb(68,114,196)",
          "rgb(158,72,14)",
        ]}
        rendered={render.bind(this)}
        style={{ height: "545px" }}
      >
        <wjChart.FlexChartLegend position="Bottom" />
        <wjChart.FlexChartSeries name="ICT-Mng" binding="ict_mng" />
        <wjChart.FlexChartSeries name="Facilities" binding="facilities" />
        <wjChart.FlexChartSeries name="Fiber" binding="fiber" />
        <wjChart.FlexChartSeries name="ICT Biz." binding="ict_biz" />
        <wjChart.FlexChartSeries name="BPO" binding="software&bpo" />
        <wjChart.FlexChartSeries name="JP Biz." binding="jp_biz" />
        <wjChart.FlexChartSeries
          name="Sales"
          binding="sales"
          chartType="Bubble"
        />
        <wjChart.FlexChartSeries
          name="Operating Income"
          binding="operating_income"
          chartType="LineSymbols"
        />
        <wjChartAnimate.FlexChartAnimation />
        <wjChart.FlexChartDataLabel content="{y}" position="Bottom" />
        <wjChart.FlexChartAxis
          wjProperty="axisY"
          max={maxVal || data[0].sales - (data[0].sales % 10) + 10}
        />
      </wjChart.FlexChart>
    </div>
  );
}

FinancialDataQuarterChart.propTypes = {
  data: PropTypes.array.isRequired,
  year: PropTypes.string.isRequired,
};
